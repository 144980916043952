import PropTypes from 'prop-types';

/**
 * Core
 */
export const urlGenerator = PropTypes.shape({
    route: PropTypes.func.isRequired,
});

export const history = PropTypes.shape({
    listen: PropTypes.func.isRequired,
});

export const location = PropTypes.shape({
    pathname: PropTypes.string,
});

export const intl = PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
});

export const message = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

export const text = PropTypes.oneOfType([message, PropTypes.string]);

export const label = PropTypes.oneOfType([message, PropTypes.node]);

export const statusCode = PropTypes.oneOf([401, 403, 404, 500]);

/**
 * Site
 */
export const user = PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    birthdate: PropTypes.string,
});

export const menuItem = PropTypes.shape({
    label,
    url: PropTypes.string,
    external: PropTypes.bool,
    active: PropTypes.bool,
});
export const menuItems = PropTypes.arrayOf(menuItem);

/**
 * Forms
 */
export const errors = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]);

export const show = PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    video: PropTypes.string,
});
export const shows = PropTypes.objectOf(show);

export const answer = PropTypes.shape({
    index: PropTypes.number,
    show,
    symbol: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
});
export const answers = PropTypes.arrayOf(answer);

export const profile = PropTypes.shape({
    body: PropTypes.string,
});
export const profiles = PropTypes.arrayOf(profile);

/**
 * Scenes
 */
export const scene = PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
});

export const scenes = PropTypes.arrayOf(scene);

/**
 * Participants
 */
export const participantType = PropTypes.oneOf(['display', 'guest', 'participant']);

export const participant = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: participantType.isRequired,
});

export const participants = PropTypes.arrayOf(participant);
