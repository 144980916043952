/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/media-has-caption */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import createDebug from 'debug';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import * as AppPropTypes from '../../lib/PropTypes';
import useJitsiConference from '../../hooks/useJitsiConference';
import useJitsiLocalTracks from '../../hooks/useJitsiLocalTracks';
import Devices from './Devices';
import Participant from './Participant';
import Button from '../buttons/Button';
import SettingsButton from '../buttons/Settings';

import styles from '../../styles/partials/player.module.scss';

const debug = createDebug('app:player');

const propTypes = {
    participant: AppPropTypes.participant.isRequired,
    room: PropTypes.string,
    layout: PropTypes.oneOf(['full', 'tile_other', 'tile_participants']),
    muted: PropTypes.bool,
    withPreview: PropTypes.bool,
    withHeader: PropTypes.bool,
    withSettings: PropTypes.bool,
    renderParticipantHeader: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    room: process.env.REACT_APP_JITSI_ROOM || 'treizelunes',
    layout: 'full',
    muted: false,
    withPreview: false,
    withHeader: false,
    withSettings: true,
    renderParticipantHeader: null,
    className: null,
};

// eslint-disable-next-line
const Player = ({
    room: roomName,
    participant,
    layout,
    muted,
    withPreview,
    withHeader,
    withSettings,
    renderParticipantHeader,
    className,
}) => {
    const isParticipant = participant.type === 'participant';
    const layoutIsTile = layout === 'tile_other' || layout === 'tile_participants';

    const { joined, participants, room } = useJitsiConference(roomName, participant);

    const [devicesOverlay, setDevicesOverlay] = useState(false);
    const [videoDevice, setVideoDevice] = useState(null);
    const [audioDevice, setAudioDevice] = useState(null);

    const onVideoDeviceChange = useCallback((newDevice) => setVideoDevice(newDevice), [
        setVideoDevice,
    ]);
    const onAudioDeviceChange = useCallback((newDevice) => setAudioDevice(newDevice), [
        setAudioDevice,
    ]);

    const onToggleOpen = useCallback(() => setDevicesOverlay(!devicesOverlay), [
        devicesOverlay,
        setDevicesOverlay,
    ]);
    const onClickClose = useCallback(() => setDevicesOverlay(false), [setDevicesOverlay]);

    const localTracks = useJitsiLocalTracks(joined ? room : null, {
        disabled: !isParticipant,
        videoDeviceId: videoDevice,
        audioDeviceId: audioDevice,
    });

    const selectedParticipants = useMemo(
        () =>
            participants
                .filter((it) => {
                    if (layout === 'tile_other') {
                        return it.type === 'presenter' || it.type === 'participant';
                    }
                    if (layout === 'tile_participants') {
                        return it.type === 'participant';
                    }
                    return it.type === 'presenter';
                })
                .sort(
                    (
                        { type: aType, participant: aParticipant },
                        { type: bType, participant: bParticipant },
                    ) => {
                        if (aType === 'presenter') {
                            return -1;
                        }
                        if (bType === 'presenter') {
                            return 1;
                        }
                        return aParticipant.getDisplayName() < bParticipant.getDisplayName();
                    },
                ),
        [participants, layout],
    );

    useEffect(() => {
        const { audio = null } = localTracks;
        if (audio === null) {
            return;
        }
        const method = muted ? 'mute' : 'unmute';
        if (audio.isMuted() !== muted) {
            debug('%s audio %s', method, audio.getId());
            audio[method]();
        }
    }, [localTracks, muted]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.full]: layout === 'full',
                    [styles.tile]: layoutIsTile,
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.participants}>
                {selectedParticipants.map((it) => {
                    const {
                        id,
                        video = null,
                        audio = null,
                        participant: otherParticipant,
                        muted: participantMuted = false,
                    } = it;
                    return (
                        <div className={styles.participant} key={`participant-${id}`}>
                            {layoutIsTile && withHeader ? (
                                <div className={styles.header}>
                                    <div className={styles.name}>
                                        {otherParticipant.getDisplayName()}
                                    </div>
                                    <div className={styles.participation}>
                                        {renderParticipantHeader(it)}
                                    </div>
                                </div>
                            ) : null}
                            <Participant
                                id={id}
                                audio={audio}
                                video={video}
                                muted={participantMuted}
                                className={styles.video}
                            />
                        </div>
                    );
                })}
            </div>
            {isParticipant && devicesOverlay ? (
                <div className={styles.overlay}>
                    <Button className={styles.closeButton} onClick={onClickClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Devices
                        className={styles.devices}
                        onVideoChange={onVideoDeviceChange}
                        onAudioChange={onAudioDeviceChange}
                    />
                </div>
            ) : null}
            {localTracks !== null && withPreview ? (
                <div className={styles.preview}>
                    <Participant
                        audio={localTracks.audio}
                        video={localTracks.video}
                        isLocal
                        className={styles.video}
                    />
                </div>
            ) : null}
            {withPreview && withSettings ? (
                <SettingsButton className={styles.settings} onClick={onToggleOpen} />
            ) : null}
        </div>
    );
};

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

export default Player;
