/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import Button from './Button';

import styles from '../../styles/buttons/settings.module.scss';

const propTypes = {
    className: PropTypes.string,
    small: PropTypes.bool,
};

const defaultProps = {
    className: null,
    small: false,
};

const SettingsButton = ({ className, small, ...props }) => (
    <Button
        className={classNames([
            styles.container,
            { [styles.small]: small, [className]: className !== null },
        ])}
        icon={<FontAwesomeIcon icon={faCog} />}
        {...props}
    />
);

SettingsButton.propTypes = propTypes;
SettingsButton.defaultProps = defaultProps;

export default SettingsButton;
