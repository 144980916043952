/* globals JitsiMeetJS */
import { useMemo, useEffect, useState } from 'react';

const useJitsiMediaDevices = () => {
    const [granted, setGranted] = useState(false);
    const [devices, setDevices] = useState([]);

    const supported = useMemo(
        () =>
            JitsiMeetJS.mediaDevices.isDeviceListAvailable() &&
            JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(),
        [],
    );

    useEffect(() => {
        if (!supported) {
            return () => {};
        }

        let canceled = false;

        const updateDevices = () => {
            JitsiMeetJS.mediaDevices.enumerateDevices((newDevices) => {
                if (!canceled) {
                    setDevices(newDevices);
                }
            });
        };

        JitsiMeetJS.mediaDevices.isDevicePermissionGranted().then((newGranted) => {
            if (!canceled) {
                setGranted(newGranted);
            }
        });

        JitsiMeetJS.mediaDevices.addEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            updateDevices,
        );

        updateDevices();

        return () => {
            canceled = true;
            JitsiMeetJS.mediaDevices.removeEventListener(
                JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
                updateDevices,
            );
        };
    }, [supported, setDevices, setGranted]);

    return {
        granted,
        supported,
        devices,
    };
};

export default useJitsiMediaDevices;
