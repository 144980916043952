/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import createDebug from 'debug';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import styles from '../../styles/partials/participant.module.scss';

const debug = createDebug('app:participant');

const propTypes = {
    id: PropTypes.string,
    audio: PropTypes.shape({
        getId: PropTypes.func.isRequired,
        attach: PropTypes.func.isRequired,
        detach: PropTypes.func.isRequired,
        isMuted: PropTypes.func.isRequired,
    }),
    video: PropTypes.shape({
        getId: PropTypes.func.isRequired,
        attach: PropTypes.func.isRequired,
        detach: PropTypes.func.isRequired,
    }),
    muted: PropTypes.bool,
    isLocal: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    audio: null,
    video: null,
    muted: false,
    isLocal: false,
    className: null
};

// eslint-disable-next-line
const Participant = ({ id: participantId, audio, video, muted, isLocal, className }) => {
    const id = isLocal ? 'local' : participantId;
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const [videoDidPlay, setVideoDidPlay] = useState(false);
    const [audioDidPlay, setAudioDidPlay] = useState(false);

    useEffect(() => {
        if (video === null) {
            return () => {};
        }
        const { current: element } = videoRef;
        debug('Attach video %s for participant %s', video.getId(), id);
        video.attach(element);
        return () => {
            debug('Detach video %s for participant %s', video.getId(), id);
            video.detach(element);
        };
    }, [id, video]);

    useEffect(() => {
        if (audio === null || isLocal) {
            return () => {};
        }
        const { current: element } = audioRef;
        debug('Attach audio %s for participant %s', audio.getId(), id);
        audio.attach(element);
        return () => {
            debug('Detach audio %s for participant %s', audio.getId(), id);
            audio.detach(element);
        };
    }, [id, audio]);

    useEffect(() => {
        if (audio === null || isLocal) {
            return;
        }
        const method = muted ? 'mute' : 'unmute';
        if (audio.isMuted() !== muted) {
            debug('%s audio %s for participant %s', method, audio.getId(), id);
            audio[method]();
        }
    }, [id, audio, isLocal, muted]);

    const onVideoPlay = useCallback(() => {
        if (!videoDidPlay) {
            setVideoDidPlay(true);
        }
    }, [videoDidPlay]);

    const onAudioPlay = useCallback(() => {
        if (!audioDidPlay) {
            setAudioDidPlay(true);
        }
    }, [audioDidPlay]);

    const onClickPlay = useCallback(() => {
        const { current: videoElement } = videoRef;
        const { current: audioElement } = audioRef;
        if (videoElement !== null) {
            videoElement.play();
        }
        if (audioElement !== null) {
            audioElement.play();
        }
    }, []);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {(video !== null && !videoDidPlay) || (audio !== null && !audioDidPlay && !isLocal) ? (
                <button type="button" className={styles.button} onClick={onClickPlay}>
                    <FontAwesomeIcon icon={faPlay} className={styles.icon} />
                </button>
            ) : null}
            {video !== null ? (
                <video
                    key={`video-track-${video.getId()}`}
                    autoPlay
                    className={styles.video}
                    ref={videoRef}
                    onPlay={onVideoPlay}
                />
            ) : null}
            {audio !== null && !isLocal ? (
                <audio
                    key={`audio-track-${audio.getId()}`}
                    autoPlay
                    className={styles.audio}
                    ref={audioRef}
                    onPlay={onAudioPlay}
                />
            ) : null}
        </div>
    );
};

Participant.propTypes = propTypes;
Participant.defaultProps = defaultProps;

export default Participant;
