import mariage from '../img/questions/mariage.png';
import halloween from '../img/questions/halloween.png';
import powwow from '../img/questions/powwow.png';
import brochet from '../img/questions/brochet.png';
import crapeau from '../img/questions/crapeau.png';
import dindon from '../img/questions/dindon-sauvage.png';
import maringouin from '../img/questions/maringouin.png';
import orignal from '../img/questions/orignal.png';
import ours from '../img/questions/ours.png';
import tortue from '../img/questions/tortue.png';

import schools from './schools';

const {
    REACT_APP_PUBNUB_PUBLISH_KEY,
    REACT_APP_PUBNUB_SUBSCRIBE_KEY,
    REACT_APP_PUBNUB_NAMESPACE,
} = process.env;

export default {
    locale: 'fr',
    messages: {
        fr: {
            'modes.display': 'Écran',
            'modes.guest': 'Spectateur',
            'modes.presenter': 'Présentateur',
            'modes.room-1': 'Classe 1',
            'modes.room-2': 'Classe 2',
            'display.full': 'Plein écran',
            'display.tile_other': 'Grille',
            'display.muted': 'Son coupé',
            'display.unmuted': 'Son activé',
            'display.preview': 'Vignette',
            'display.nopreview': 'Pas de vignette',
            'room.start': 'Commencer',
            'room.listen': 'Écouter la pièce',
            'room.unmuted': 'Votre son est activé',
        },
    },
    routes: {
        home: '/',
        schools: '/ecoles',
        player: '/:participant',
    },
    pages: {
        home: {
            title: 'Les Treize lunes',
            body: '<p>Description</p>',
        },
    },
    participants: [
        {
            id: 'ecran',
            type: 'display',
            name: 'Écran',
        },
        {
            id: 'guest',
            type: 'guest',
            name: 'Invité',
        },
        ...schools,
    ],
    scenes: [
        {
            id: 'autochtones',
            type: 'question',
            question: 'Connaissez-vous une autre chose que les autochtones nous ont apprise?',
            answers: [
                // { label: 'Canot', value: 'canot' },
                // { label: 'Kayak', value: 'kayak' },
                // { label: 'Raquettes', value: 'raquettes' },
                // { label: 'Igloo', value: 'igloo' },
                // { label: 'Tipi', value: 'tipi' },
                // { label: 'Viande Fumée', value: 'viande' },
            ],
        },
        {
            id: 'powwow',
            type: 'question',
            question: 'Un pow-wow c’est ?',
            answers: [
                { label: 'Un mariage', value: 'mariage', image: mariage },
                { label: 'L’Halloween', value: 'halloween', image: halloween },
                { label: 'Une grande fête', value: 'fete', image: powwow },
            ],
        },
        {
            id: 'animaux',
            type: 'question',
            question: 'Quels animaux ne sont pas dans l’histoire?',
            multiple: true,
            multipleCount: 2,
            answers: [
                { label: 'Orignal', value: 'orignal', image: orignal },
                { label: 'Crapaud', value: 'crapaud', image: crapeau },
                { label: 'Tortue', value: 'tortue', image: tortue },
                { label: 'Dindon sauvage', value: 'dindon-sauvage', image: dindon },
                { label: 'Maringouin', value: 'maringouin', image: maringouin },
                { label: 'Ours', value: 'ours', image: ours },
                { label: 'Brochet', value: 'brochet', image: brochet },
            ],
        },
        {
            id: 'discrimination',
            type: 'question',
            question: 'La discrimination c’est ?',
            answers: [],
        },
    ],
    pubnub: {
        publishKey: REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        namespace: REACT_APP_PUBNUB_NAMESPACE || 'cuecue',
    },
    // jitsi: {
    //     server: 'jitsi.flklr.ca',
    //     roomName: 'TreizeLunes',
    // },
};
