/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TrackingContainer } from '@folklore/tracking';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { SocketContainer } from '@folklore/socket';

import * as AppPropTypes from '../lib/PropTypes';
import { RoutesProvider } from './contexts/RoutesContext';
import { SecretsProvider } from './contexts/SecretsContext';
import { PagesProvider } from './contexts/PagesContext';
import { ResultsProvider } from './contexts/ResultsContext';
import { SiteProvider } from './contexts/SiteContext';

import App from './App';

const propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    routes: PropTypes.objectOf(PropTypes.string),
    pages: PropTypes.objectOf(PropTypes.object),
    participants: AppPropTypes.participants,
    pubnub: PropTypes.shape({
        publishKey: PropTypes.string,
        subscribeKey: PropTypes.string,
    }),
    jitsi: PropTypes.shape({
        server: PropTypes.string,
        roomName: PropTypes.string,
    }),
};

const defaultProps = {
    locale: 'fr',
    messages: {},
    routes: {},
    pages: {},
    participants: [],
    pubnub: {
        publishKey: null,
        subscribeKey: null,
        channels: ['cuecue'],
    },
    jitsi: {
        server: 'jitsi.flklr.ca',
        roomName: 'folklore',
    },
};

const Root = ({ locale, messages, routes, pages, participants, pubnub, jitsi }) => {
    const keys = useMemo(
        () => ({
            jitsi,
        }),
        [jitsi],
    );

    return (
        <IntlProvider locale={locale} messages={messages[locale] || messages}>
            <TrackingContainer>
                <BrowserRouter>
                    <RoutesProvider routes={routes}>
                        <SecretsProvider {...keys}>
                            <SocketContainer {...pubnub}>
                                <PagesProvider pages={{ ...pages }}>
                                    <SiteProvider>
                                        <ResultsProvider>
                                            <App participants={participants} />
                                        </ResultsProvider>
                                    </SiteProvider>
                                </PagesProvider>
                            </SocketContainer>
                        </SecretsProvider>
                    </RoutesProvider>
                </BrowserRouter>
            </TrackingContainer>
        </IntlProvider>
    );
};

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
