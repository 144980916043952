/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useSocket } from '@folklore/socket';
import createDebug from 'debug';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';

import * as AppPropTypes from '../../lib/PropTypes';

import PageMeta from '../partials/PageMeta';
import Player from '../partials/Player';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Logo from '../partials/Logo';
import Button from '../buttons/Normal';
import Scene from '../scenes/Scene';

import styles from '../../styles/pages/room.module.scss';

import allData from '../../data/root';

const debug = createDebug('app:room');

const propTypes = {
    participant: AppPropTypes.participant.isRequired,
    scenes: AppPropTypes.scenes,
};

const defaultProps = {
    scenes: allData.scenes,
};

const RoomPage = ({ participant, scenes }) => {
    const { id: participantId, type: participantType } = participant;
    const isDisplay = participantType === 'display';
    const isParticipant = participantType === 'participant';
    const isGuest = participantType === 'guest';

    // App states
    const [started, setStarted] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const [sceneId, setSceneId] = useState(null);
    const [layout, setLayout] = useState('full');
    const [muted, setMuted] = useState(true);
    const [answers, setAnswers] = useState(null);
    const [preview, setPreview] = useState(true);

    const timeoutRef = useRef(null);

    // Commands
    const onCommand = useCallback(
        (command, args) => {
            const [data, extraData = null] = args || [];
            const { type: commandType = null } = data || {};
            const finalCommand = command === 'cue' ? commandType : command;

            debug('Command %s %O', command, args);

            switch (finalCommand) {
                case 'question': {
                    if (isParticipant || isDisplay) {
                        setSceneId(extraData);
                    }
                    break;
                }
                case 'layout': {
                    const { layout: commandLayout = null } = data || {};
                    if (commandLayout === 'tile' || commandLayout === 'tile_other') {
                        setLayout('tile_other');
                    } else if (commandLayout === 'full') {
                        setLayout('full');
                    }
                    break;
                }
                case 'mute': {
                    setMuted(true);
                    break;
                }
                case 'unmute': {
                    setMuted(false);
                    break;
                }
                case 'show_preview': {
                    setPreview(true);
                    break;
                }
                case 'hide_preview': {
                    setPreview(false);
                    break;
                }
                case 'uncue': {
                    setSceneId(null);
                    setAnswers(null);
                    break;
                }
                case 'interaction': {
                    if (!isDisplay) {
                        return;
                    }
                    const { data: interactionValue, interactionId } = data || {};
                    setAnswers({ ...answers, [interactionId]: interactionValue });
                    break;
                }
                case 'end':
                    setSceneId(null);
                    break;
                default:
                    break;
            }
        },
        [
            setSceneId,
            setLayout,
            setMuted,
            setAnswers,
            setPreview,
            answers,
            isDisplay,
            isParticipant,
        ],
    );

    // Socket
    const onSocketMessage = useCallback(({ command, args }) => onCommand(command, args), [
        onCommand,
    ]);
    const { socket } = useSocket(['output'], {
        onMessage: onSocketMessage,
    });
    const postCommand = useCallback(
        (command, ...args) => {
            debug(command, args);
            socket.send(
                {
                    command,
                    args,
                },
                'input',
            );
        },
        [socket],
    );

    // Interactions
    const onAnswerChange = useCallback((value) => postCommand('interact', value, participantId), [
        participantId,
        postCommand,
    ]);

    // Start
    const onClickStart = useCallback(() => {
        setStarted(true);
    }, [setStarted]);

    // Fullscreen
    const onClickFullscreen = useCallback(() => {
        const elem = document.querySelector('body');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        }
    }, [setFullScreen]);

    const onFullScreenChange = useCallback(() => {
        const isFullScreen =
            document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        setFullScreen(isFullScreen);
    }, [setFullScreen]);

    useEffect(() => {
        document.addEventListener('fullscreenchange', onFullScreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', onFullScreenChange);
        };
    }, [onFullScreenChange]);

    // Scene
    const currentScene = sceneId !== null ? scenes.find((it) => it.id === sceneId) || null : null;
    const { question = null, answers: sceneAnswers = [], multiple: multipleAnswers } =
        currentScene || {};

    const renderParticipantHeader = useCallback(
        ({ handle: id }) => {
            const answerId = answers !== null ? answers[id] || null : null;
            const participantAnswers =
                answerId !== null
                    ? sceneAnswers.filter((it) =>
                          multipleAnswers
                              ? answerId.indexOf(it.value) !== -1
                              : answerId === it.value,
                      )
                    : null;
            return participantAnswers !== null && participantAnswers.length > 0 ? (
                <div className={styles.answers}>
                    {multipleAnswers ? 'Réponses: ' : 'Réponse: '}
                    {participantAnswers.map((it) => it.label).join(', ')}
                </div>
            ) : null;
        },
        [sceneAnswers, multipleAnswers, answers],
    );

    // Settings
    const onMouseMove = useCallback(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        if (!showControls) {
            setShowControls(true);
        }
        timeoutRef.current = setTimeout(() => {
            setShowControls(false);
        }, 3000);
    }, [showControls, setShowControls]);

    return (
        <div
            className={styles.container}
            onMouseMove={isParticipant || isGuest ? onMouseMove : null}
        >
            <PageMeta />
            {started && isDisplay ? <Header className={styles.header} question={question} /> : null}
            <div className={styles.middle}>
                {started ? (
                    <Player
                        participant={participant}
                        layout={isDisplay ? 'tile_participants' : layout}
                        muted={isParticipant ? muted : true}
                        withPreview={isParticipant && preview}
                        withHeader={isDisplay}
                        withFooter={isDisplay}
                        withSettings={showControls}
                        renderParticipantHeader={renderParticipantHeader}
                        className={styles.player}
                    />
                ) : (
                    <div className={styles.start}>
                        <Logo className={styles.logo} textColor="#FFF" moonColor="#FFF" />
                        <Button onClick={onClickStart} className={styles.button}>
                            {participantType === 'guest' ? (
                                <FormattedMessage
                                    id="room.listen"
                                    defaultMessage="Écouter la pièce"
                                />
                            ) : (
                                <FormattedMessage id="room.start" defaultMessage="Commencer" />
                            )}
                        </Button>
                    </div>
                )}
                {started && isParticipant && currentScene !== null ? (
                    <div className={styles.overlay}>
                        <Scene {...currentScene} onAnswerChange={onAnswerChange} />
                    </div>
                ) : null}
                {started && isParticipant && !muted ? (
                    <div className={styles.notification}>
                        <div className={styles.bubble}>
                            <FormattedMessage
                                id="room.unmuted"
                                defaultMessage="Votre son est activé"
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            {!fullScreen && showControls ? (
                <Button onClick={onClickFullscreen} className={styles.fullscreenButton}>
                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                </Button>
            ) : null}
            {started && isDisplay ? (
                <Footer className={styles.footer} layout={layout} muted={muted} preview={preview} />
            ) : null}
        </div>
    );
};

RoomPage.propTypes = propTypes;
RoomPage.defaultProps = defaultProps;

export default RoomPage;
