export default [
    {
        id: 'caroline',
        type: 'participant',
        name: 'Caroline',
    },
    {
        id: 'amishk',
        type: 'participant',
        name: 'École Amishk, Mashteuiatsh',
    },
    {
        id: 'amikobi',
        type: 'participant',
        name: 'École Amikobi, Lac-Simon',
    },
    {
        id: 'iguarsivik',
        type: 'participant',
        name: 'École Iguarsivik, Puvurnituq',
    },
    {
        id: 'escabelle',
        type: 'participant',
        name: 'École de l’Escabelle, Québec',
    },
    {
        id: 'mgr-milot',
        type: 'participant',
        name: 'École Mgr Milot, Victoriaville',
    },
    {
        id: 'montjoie',
        type: 'participant',
        name: 'École Notre-Dame-de-Montjoie, Racine',
    },
    {
        id: 'bois-francs',
        type: 'participant',
        name: 'École Notre-Dame-des-Bois-Francs, Victoriaville',
    },
    {
        id: 'chene',
        type: 'participant',
        name: 'École du Chêne, Saint-Édouard-de-Lotbinière',
    },
    {
        id: 'teueikan',
        type: 'participant',
        name: 'École Teueikan, Mingan',
    },
    {
        id: 'vincent-lemire',
        type: 'participant',
        name: 'École Vincent-Lemire, St-François-du-Lac',
    },
    {
        id: 'sacre-coeur',
        type: 'participant',
        name: 'École Sacré-Coeur, Princeville',
    },
    {
        id: 'wahat',
        type: 'participant',
        name: 'École Wahat, Wendake',
    },
    {
        id: 'migwan',
        type: 'participant',
        name: 'École Migwan, Pikogan',
    },
    {
        id: 'ste-cecile',
        type: 'participant',
        name: 'École Ste-Cécile, Jonquière',
    },
    {
        id: 'ste-ambroise',
        type: 'participant',
        name: 'École primaire Collège St-Ambroise, Jonquière',
    },
    {
        id: 'nussim',
        type: 'participant',
        name: 'École Nussim, Pessamit',
    },
    {
        id: 'notre-dame',
        type: 'participant',
        name: 'École Notre-Dame-de-l’Assomption, Jonquière',
    },
    {
        id: 'st-sauveur',
        type: 'participant',
        name: 'École St-Sauveur, Val-d’or',
    },
    {
        id: 'st-joseph',
        type: 'participant',
        name: 'École St-Joseph, Montréal',
    },
    {
        id: 'st-joseph-val-d-or',
        type: 'participant',
        name: 'École St-Joseph, Val-d’Or',
    },
];
