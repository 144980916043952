/* globals JitsiMeetJS */
import { useEffect, useState } from 'react';

const useJitsiLocalTracks = (
    room,
    {
        disabled = false,
        resolution = '720',
        videoDeviceId = undefined,
        audioDeviceId = undefined,
    } = {},
) => {
    const [localTracks, setLocalTracks] = useState([]);

    useEffect(() => {
        if (disabled) {
            return () => {};
        }

        let canceled = false;
        let currentLocalTracks = null;

        JitsiMeetJS.createLocalTracks({
            devices: ['video', 'audio'],
            resolution,
            cameraDeviceId: videoDeviceId,
            micDeviceId: audioDeviceId,
        }).then((newLocalTracks) => {
            if (!canceled) {
                currentLocalTracks = newLocalTracks;
                setLocalTracks(newLocalTracks);
            }
        });

        return () => {
            canceled = true;
            if (currentLocalTracks !== null) {
                currentLocalTracks.forEach((localTrack) => {
                    localTrack.dispose();
                });
            }
        };
    }, [disabled, resolution, videoDeviceId, audioDeviceId]);

    useEffect(() => {
        if (room === null) {
            return () => {};
        }

        localTracks.forEach((localTrack) => {
            room.addTrack(localTrack);
        });

        return () => {
            localTracks.forEach((localTrack) => {
                room.removeTrack(localTrack);
            });
        };
    }, [room, localTracks]);

    useEffect(() => {
        return () => {
            localTracks.forEach((localTrack) => {
                localTrack.dispose();
            });
        };
    }, [localTracks]);

    return {
        video:
            localTracks !== null
                ? localTracks.find((it) => it.getType() === 'video') || null
                : null,
        audio:
            localTracks !== null
                ? localTracks.find((it) => it.getType() === 'audio') || null
                : null,
    };
};

export default useJitsiLocalTracks;
