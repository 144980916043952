import React from 'react';
import { Route, Switch } from 'react-router';
import { useRoutes } from './contexts/RoutesContext';

import * as AppPropTypes from '../lib/PropTypes';
import MainLayout from './layouts/Main';
import HomePage from './pages/Home';
import RoomPage from './pages/Room';
import ErrorPage from './pages/Error';

const propTypes = {
    participants: AppPropTypes.participants.isRequired, // eslint-disable-line
};

const defaultProps = {};

const App = ({ participants }) => {
    const routes = useRoutes();
    const guest = participants.find(({ type }) => type === 'guest');
    return (
        <MainLayout>
            <Switch>
                <Route exact path={routes.home}>
                    <RoomPage participant={guest} />
                </Route>
                <Route exact path={routes.schools}>
                    <HomePage
                        participants={participants.filter(({ type }) => type === 'participant')}
                    />
                </Route>
                <Route
                    exact
                    path={routes.player}
                    render={({
                        match: {
                            params: { participant: participantId },
                        },
                    }) => {
                        const participant =
                            participants.find((it) => it.id === participantId) || null;
                        return participant !== null ? (
                            <RoomPage participant={participant} />
                        ) : (
                            <ErrorPage />
                        );
                    }}
                />
                <Route path="*" component={ErrorPage} />
            </Switch>
        </MainLayout>
    );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
