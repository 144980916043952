/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import { useUrlGenerator } from '../contexts/RoutesContext';
import Button from '../buttons/Normal';

import styles from '../../styles/pages/home.module.scss';

const propTypes = {
    participants: AppPropTypes.participants,
};

const defaultProps = {
    participants: [],
};

const HomePage = ({ participants }) => {
    const url = useUrlGenerator();
    return (
        <div className={classNames([styles.container])}>
            <div className={styles.inner}>
                {participants.map(({ id, name }) => (
                    <Button
                        href={url('player', {
                            participant: id,
                        })}
                        className={styles.button}
                    >
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
