import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useJitsiMediaDevices from '../../hooks/useJitsiMediaDevices';

import styles from '../../styles/partials/devices.module.scss';

const propTypes = {
    className: PropTypes.string,
    onVideoChange: PropTypes.func,
    onAudioChange: PropTypes.func,
};

const defaultProps = {
    className: null,
    onVideoChange: null,
    onAudioChange: null,
};

const Devices = ({ className, onVideoChange, onAudioChange }) => {
    const [videoDevice, setVideoDevice] = useState(null);
    const [audioDevice, setAudioDevice] = useState(null);
    const { supported, devices } = useJitsiMediaDevices();
    const onVideoSelectChange = useCallback(
        (e) => {
            const newValue = e.target.value !== '' ? e.target.value : null;
            setVideoDevice(newValue);
            if (onVideoChange !== null) {
                onVideoChange(newValue);
            }
        },
        [setVideoDevice, onVideoChange],
    );
    const onAudioSelectChange = useCallback(
        (e) => {
            const newValue = e.target.value !== '' ? e.target.value : null;
            setAudioDevice(newValue);
            if (onAudioChange !== null) {
                onAudioChange(newValue);
            }
        },
        [setAudioDevice, onAudioChange],
    );
    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            {supported ? (
                <div className={styles.select}>
                    <h4 className={styles.title}>Sélectionner la caméra et le microphone</h4>
                    <div className={styles.device}>
                        <div className={styles.label}>Caméra:</div>
                        <select
                            className={styles.select}
                            value={videoDevice || ''}
                            onChange={onVideoSelectChange}
                        >
                            <option value="">--</option>
                            {devices
                                .filter((it) => it.kind === 'videoinput')
                                .map(({ deviceId, label }) => (
                                    <option key={deviceId} value={deviceId}>
                                        {label}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className={styles.device}>
                        <div className={styles.label}>Microphone:</div>
                        <select
                            className={styles.select}
                            value={audioDevice || ''}
                            onChange={onAudioSelectChange}
                        >
                            <option value="">--</option>
                            {devices
                                .filter((it) => it.kind === 'audioinput')
                                .map(({ deviceId, label }) => (
                                    <option key={deviceId} value={deviceId}>
                                        {label}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
            ) : (
                <div className={styles.notSupported}>Vous ne pouvez pas changer de caméra</div>
            )}
        </div>
    );
};

Devices.propTypes = propTypes;
Devices.defaultProps = defaultProps;

export default Devices;
