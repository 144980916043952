/* globals JitsiMeetJS */
import { useEffect, useRef, useState } from 'react';
import createDebug from 'debug';

const debug = createDebug('app:jitsi:lib');

const useLibJitsi = ({ domain = 'jitsi.parminou.com', autoConnect = true, reconnectDelay = 2000 } = {}) => {
    const [connected, setConnected] = useState(false);
    const connectionRef = useRef(null);
    useEffect(() => {
        JitsiMeetJS.init({});
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.INFO);

        const onConnectionSuccess = () => {
            debug('connected');
            setConnected(true);
        };

        const onConnectionFailed = () => {
            debug('failed');
        };

        const onDisconnected = () => {
            debug('disconnected');
            setConnected(false);
        };

        const connection = new JitsiMeetJS.JitsiConnection(null, null, {
            // Connection
            hosts: {
                // XMPP domain.
                domain: 'jitsi.parminou.com',
                // When using authentication, domain for guest users.
                anonymousdomain: 'guest.jitsi.parminou.com',

                // Domain for authenticated users. Defaults to <domain>.
                // authdomain: 'jitsi.parminou.com',

                // Focus component domain. Defaults to focus.<domain>.
                // focus: 'focus.jitsi.parminou.com',

                // XMPP MUC domain. FIXME: use XEP-0030 to discover it.
                muc: 'conference.jitsi.parminou.com',
            },

            // BOSH URL. FIXME: use XEP-0156 to discover it.
            bosh: '//jitsi.parminou.com/http-bind',

            // Websocket URL
            // websocket: 'wss://jitsi.parminou.com/xmpp-websocket',

            // The name of client node advertised in XEP-0115 'c' stanza
            clientNode: 'http://jitsi.org/jitsimeet',
        });

        connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            onConnectionSuccess,
        );
        connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            onConnectionFailed,
        );
        connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            onDisconnected,
        );

        connectionRef.current = connection;

        if (autoConnect) {
            debug('connecting...');
            connection.connect();
        }

        return () => {
            connection.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
                onConnectionSuccess,
            );
            connection.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_FAILED,
                onConnectionFailed,
            );
            connection.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
                onDisconnected,
            );

            if (autoConnect) {
                debug('disconnecting...');
                connection.disconnect();
            }

            connectionRef.current = null;
        };
    }, [domain, autoConnect, setConnected, reconnectDelay]);

    return {
        connection: connectionRef.current,
        connected,
    };
};

export default useLibJitsi;
