import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    moonColor: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    moonColor: null,
    textColor: null,
    backgroundColor: null,
    className: null,
};

const Logo = ({ moonColor, textColor, backgroundColor, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="1007.27px"
        height="1026.28px"
        viewBox="0 0 1007.27 1026.28"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <polygon fill={moonColor} points="815.08,105.82 815.08,105.81 815.07,105.82 	" />
            <path
                fill={moonColor}
                d="M828.91,128.08c-0.41-1.95-0.7-3.93-1.14-5.88c-0.76-3.38-1.55-6.76-4.05-9.41c-0.06-0.07,0.1-0.31,0.1-0.47
		c0.05-1.64-1.65-4.41-3.28-5.13c0.83,2.02,3.12,3.5,1.67,6.34c-0.82-1.08-1.48-2.02-2.22-2.89c-1.36-1.61-2.76-3.2-4.17-4.77
		c-0.12-0.13-0.48-0.04-0.74-0.04c0.77,1.11,1.51,2.24,2.31,3.34c2.24,3.07,3.96,6.42,5.08,10.03c-4.74-2.33-8.34-8.11-7.9-12.3
		c-1.09-1.52-2.12-2.84-3.06-4.05c-0.04-0.12-0.09-0.23-0.13-0.34c0.12-0.08,0.25-0.17,0.37-0.25c1.11,1.19,2.22,2.39,3.33,3.58
		c0.05-0.68,0.11-1.36,0.16-2.04c-1.65,0.05-1.85-1.43-2.41-2.49c-1.25,1.03-1.5-0.11-1.59-0.72c-0.35-2.34-2.04-2.98-3.41-3.29
		c-0.07-0.07-0.13-0.13-0.19-0.2c-0.04-0.09-0.09-0.18-0.13-0.28c-3.45-7.56-6.18-9.73-13.6-9.14c-0.67-0.38-1.33-0.75-2-1.13
		c-0.09,0.11-0.18,0.21-0.28,0.32c0.13,0.33,0.25,0.66,0.39,1c-0.09,0.01-0.19,0.02-0.28,0.03c-1.25,0.16-2.65-0.17-3.87-0.6
		c-1.2-0.43-2.41-0.82-3.61-1.17c0.48,0.06,0.97,0.13,1.45,0.19c0.04,0.01,0.11-0.1,0.1-0.1c-0.29-0.27-0.86-0.64-0.81-0.79
		c0.52-1.53-0.76-1.57-1.56-1.84c-1.62-0.54-3.32-0.83-4.94-1.39c-3.11-1.07-6.28-1.5-9.56-1.25c-2.04,0.16-4.1,0.08-6.12,0.33
		c-2.21,0.28-4.4,0.81-6.59,1.23c0.04,0.16,0.07,0.32,0.11,0.48c1.2-0.19,2.4-0.52,3.6-0.54c2.37-0.04,4.77-0.98,7.11,0.1
		c2.4,1.12,4.9,1.38,7.51,1.19c1.16-0.08,2.35,0.22,3.52,0.34c0.54,0.05,1.08,0.09,1.77,0.15c0.04,0.4,0.19,0.68,0.41,0.9
		c-6.6-1.38-13.26-1.42-20.19,0.24c-10.87,2.61-20.42,7.81-28.9,14.59c-0.01-0.05-0.03-0.1-0.04-0.15c0.22-0.26,0.44-0.53,0.66-0.79
		c-0.58,0.11-0.88,0.45-1.21,0.76c-0.75,0.7-1.41,1.78-2.28,2c-1.43,0.35-2.09,1.4-2.99,2.26c-2.29,2.2-4.58,4.4-6.98,6.71
		c-0.23-0.49-0.36-0.75-0.48-1.02c-0.15,0-0.3-0.01-0.45-0.01c-0.26,1.12-0.51,2.24-0.78,3.41c-3.37,5.56-6.78,11.33-8.91,17.7
		c-2.14,6.4-2.11,13.1-1.71,19.68c0.43,0.36,0.68,0.79,0.8,1.25c0.01,0.05,0.02,0.11,0.04,0.16c-0.75,3.17-1.47,6.35-2.18,9.53
		c-1.03,4.65,5.9,11.54,8.63,15.45c5.38,7.68-5.09,5.95-1.58,8.29c0.57,0.38,1.03,0.94,1.6,1.3c1.26,0.77,2.53,1.54,3.79,2.31
		c0.23,0.36,0.46,0.72,0.68,1.08c0.16-0.05,0.33-0.1,0.49-0.15c0.01-0.06,0.02-0.13,0.03-0.19c0.28,0.17,0.57,0.35,0.85,0.52
		c-0.07-0.03-0.13-0.06-0.19-0.09c-0.07,0.16-0.14,0.33-0.21,0.49c2.88,5.6,16.41,15.8,23.33,17.32c-0.48-0.63-0.69-1.08-1.04-1.31
		c-1.83-1.2-3.72-2.3-5.54-3.5c-2.01-1.32-3.96-2.72-5.96-4.05c-0.43-0.29-0.97-0.4-1.46-0.61c-0.33-0.14-0.73-0.23-0.96-0.47
		c-2.17-2.28-4.29-4.59-6.46-6.86c3.47,2.1,6.94,4.2,10.42,6.28c0.51,0.3,1.46,0.51,1.84,0.22c0.01-0.01,0.02-0.01,0.03-0.02
		c0.14,0.11,0.28,0.23,0.42,0.34c1.44,1.19,2.95,2.43,4.65,3.15c4,1.69,7.66,4.22,12.19,4.63c0.9,0.08,1.8,0.21,2.69,0.35
		c0.95,0.28,1.92,0.45,2.89,0.51c1.71,0.31,3.43,0.6,5.15,0.69c3.98,0.2,7.99-0.07,11.97-0.38c5.01-0.39,9.57-2.46,14.18-4.27
		c0.94-0.37,1.88-0.81,2.75-1.31c2.6-1.51,5.22-2.99,7.74-4.64c1.2-0.79,2.33-1.6,3.65-2.27c1.95-0.99,3.66-2.63,5.17-4.28
		c4.01-4.4,7.88-8.93,11.75-13.45c0.56-0.65,0.77-1.59,1.26-2.31c0.67-1,1.51-1.89,2.17-2.89c0.81-1.24,1.56-2.53,2.25-3.84
		c0.72-1.36,1.33-2.78,2.11-4.42c-0.76,0.15-1.08,0.21-1.81,0.36c0.02-0.08,0.05-0.17,0.07-0.25c0.65-0.92,1.1-1.56,1.59-2.24
		c-0.1-0.17-0.5-0.56-0.42-0.79c0.89-2.51,1.85-4.99,2.79-7.48c0.03-0.09,0.15-0.15,0.23-0.23c0.72-0.7,1.17-1.38,1.3-2.56
		C830.48,142.53,830.44,135.29,828.91,128.08z M825.14,144.38c-0.14,0.71-0.21,1.26-0.37,1.79c-0.07,0.23-0.17,0.48-0.29,0.73
		c-0.22-0.5-0.44-1-0.65-1.49c-0.13-3.72-0.58-7.6-1.39-11.62c0.6-1.3,1.6-2.49,3.13-3.57c0.53,2.6,0.78,5.26,0.44,8.02
		c1.15,1.78,0.34,3.06-1.29,4.21C825.58,143.19,826.95,144.01,825.14,144.38z"
            />
            <path
                fill={moonColor}
                d="M783.11,207.61c-2.69-0.66-5.12,0.42-7.67,0.51c-4.6,0.17-8.95,2.24-13.65,1.94
		c-1.01-0.06-2.06,0.44-3.09,0.68c0.06,0.26,0.11,0.52,0.17,0.77c12.74-0.33,25.13-2.41,36.81-7.79c-2.85,0.83-5.64,1.91-8.45,2.89
		C785.88,207.08,784.33,207.91,783.11,207.61z"
            />
            <polygon fill={moonColor} points="815.08,105.81 815.07,105.81 815.07,105.82 	" />
            <path
                fill={moonColor}
                d="M743.87,89.36c-0.9,0.1-1.51,0.16-2.12,0.23c-0.01-0.1-0.03-0.21-0.04-0.31c0.29-0.24,0.58-0.48,0.87-0.71
		c-0.1-0.19-0.21-0.37-0.31-0.56c-2.39,1.15-4.77,2.29-7.3,3.5C738.58,93.09,741.14,91.43,743.87,89.36z"
            />
            <path
                fill={moonColor}
                d="M723.76,101.52c-0.43-0.33-0.96-0.53-1.01-0.82c-0.06-0.37,0.24-0.82,0.44-1.2c0.24-0.46,0.55-0.89,0.83-1.34
		c-0.2-0.11-0.4-0.23-0.61-0.34c-0.88,1.37-1.75,2.73-2.76,4.31c0.79,0,1.25,0,1.7,0c0.14,0.16,0.28,0.32,0.42,0.48
		c-3.03,1.23-4.34,3.67-5.19,6.44c1.93-1.94,3.75-3.95,5.5-6.03C723.48,102.56,723.6,101.9,723.76,101.52z"
            />
            <path
                fill={moonColor}
                d="M754.73,210.56c-4.03-0.75-7.7-2.94-12.17-3.28c0.27,0.48,0.44,1.2,0.76,1.27c3.9,0.96,7.82,1.82,11.74,2.69
		c0.07,0.01,0.16-0.1,0.31-0.2c-0.15-0.31-0.31-0.62-0.47-0.93C754.85,210.27,754.79,210.42,754.73,210.56z"
            />
            <path
                fill={moonColor}
                d="M791.69,82.05c-0.14-0.1-0.66,0.31-0.98,0.48c3.49,1.75,6.72,3.37,9.96,4.99
		C798.63,84.08,794.53,84.12,791.69,82.05z"
            />
            <path
                fill={moonColor}
                d="M748.32,84.31c-1.39,1.01-3.16,1.5-4.7,2.32c-0.38,0.2-0.56,0.79-0.97,1.41c3.32-1.34,6.17-2.49,9.02-3.64
		C750.58,84.42,749.62,83.37,748.32,84.31z"
            />
            <path
                fill={moonColor}
                d="M811.3,95.42c0.1-0.16,0.21-0.32,0.31-0.48c-1.57-0.95-3.14-1.9-4.7-2.85l-0.01,0.01c0,0,0.01-0.01,0.01-0.01
		c-0.35-2.17-1.7-3.2-4.04-2.89c1.4,1.04,2.69,2,3.97,2.95C808.21,93.42,809.17,95.28,811.3,95.42z"
            />
            <path
                fill={moonColor}
                d="M755.06,83.31c-1.33,0-2.66,0-4.27,0c0.45,0.49,0.71,0.78,0.97,1.06c1.13-0.28,2.25-0.56,3.38-0.84
		C755.12,83.45,755.09,83.38,755.06,83.31z"
            />
            <path
                fill={moonColor}
                d="M751.76,84.37C751.76,84.37,751.76,84.37,751.76,84.37L751.76,84.37c-0.04,0.01-0.07,0.03-0.1,0.04
		c0.04,0,0.08,0,0.13,0C751.78,84.39,751.77,84.38,751.76,84.37z"
            />
            <path
                fill={moonColor}
                d="M815.44,99.74c0.05-0.08,0.11-0.15,0.16-0.23c-1.05-1.17-2.09-2.34-3.36-3.75c0.25,1.15,0.41,1.9,0.54,2.5
		C813.73,98.79,814.58,99.27,815.44,99.74z"
            />
            <path
                fill={moonColor}
                d="M818.08,102.43c-0.62-0.7-1.25-1.41-1.88-2.12c-0.15,0.09-0.3,0.18-0.45,0.28c0.22,0.89,0.44,1.77,0.66,2.66
		C817.14,102.89,817.6,102.67,818.08,102.43z"
            />
            <path
                fill={moonColor}
                d="M725.21,95.77c0.45-0.07,1.08,0.01,1.33-0.25c0.66-0.72,1.16-1.59,1.72-2.39c2.15-1.44,4.29-2.87,6.46-4.33
		c-1.09-1.74-1.73-0.42-2.36,0.18c-0.75,0.72-1.36,1.59-2.1,2.32c-0.64,0.63-1.39,1.16-2.09,1.73v0c-1.04,0.76-2.08,1.52-3.12,2.27
		C725.1,95.46,725.15,95.61,725.21,95.77z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M970.29,317.07c2.29-4.16-5.29-7.3-3.05-11.49c3.77-7.05-0.27-8.9-2.69-12.09c-0.39-0.52,5.89-2.21,5.48-2.71
		c-0.89-1.08-1.78-2.17-2.67-3.25c-0.1-0.4-0.19-0.81-0.29-1.22c-0.16,0-0.32,0.01-0.48,0.01c-0.02,0.06-0.05,0.12-0.08,0.17
		c-0.2-0.24-0.4-0.49-0.6-0.73c0.05,0.04,0.1,0.09,0.14,0.14c0.11-0.14,0.22-0.27,0.33-0.41c-0.89-6.13-9.85-19.59-15.52-22.94
		c0.24,0.73,0.29,1.22,0.53,1.54c1.26,1.64,2.61,3.22,3.87,4.87c1.39,1.81,2.7,3.69,4.07,5.51c0.3,0.39,0.74,0.65,1.11,0.98
		c0.25,0.22,0.58,0.42,0.71,0.71c1.24,2.77,2.43,5.56,3.69,8.33c-2.45-2.96-4.9-5.92-7.37-8.85c-0.36-0.43-1.14-0.89-1.57-0.72
		c-0.01,0-0.02,0.01-0.03,0.01c-0.09-0.15-0.18-0.29-0.27-0.44c-0.92-1.53-1.89-3.13-3.18-4.28c-3.04-2.71-5.52-6.13-9.42-7.77
		c-0.77-0.32-1.53-0.69-2.28-1.08c-0.76-0.53-1.56-0.95-2.4-1.28c-1.42-0.77-2.86-1.51-4.36-2.07c-3.46-1.29-7.09-2.12-10.71-2.92
		c-4.55-1.01-9.19-0.28-13.81,0.19c-0.95,0.1-1.9,0.26-2.83,0.5c-0.21,0.05-0.41,0.11-0.62,0.16c-24.03-1.15-48.6,11.81-57.54,43.06
		c-2.54,12.5-1.67,23.63,1.62,33.15c-0.13,1.65,0.52,3.98,1.54,4.93c-0.04-0.59-0.18-1.17-0.33-1.75c1.74,4.14,3.95,7.95,6.55,11.41
		c-0.01,1.8,1.12,2.71,2.11,3.31c0.04,0.08,0.08,0.16,0.11,0.24c0.01,0.1,0.03,0.2,0.04,0.3c0.81,8.17,2.59,10.99,9.33,12.46
		c0.48,0.54,0.96,1.09,1.44,1.63c0.11-0.08,0.22-0.15,0.34-0.23c-0.01-0.35-0.03-0.7-0.05-1.07c0.09,0.01,0.17,0.03,0.26,0.04
		c1.16,0.19,2.3,0.89,3.25,1.64c0.94,0.74,1.89,1.45,2.85,2.11c-0.41-0.19-0.82-0.39-1.23-0.58c-0.04-0.02-0.12,0.07-0.12,0.07
		c0.18,0.34,0.57,0.84,0.48,0.97c-0.92,1.32,0.21,1.71,0.83,2.18c1.28,0.96,2.69,1.71,3.96,2.68c2.44,1.88,5.11,3.15,8.09,3.82
		c1.85,0.41,3.65,1.05,5.52,1.37c2.04,0.34,4.13,0.43,6.2,0.63c0.01-0.16,0.03-0.33,0.04-0.49c-1.12-0.15-2.28-0.16-3.34-0.47
		c-2.11-0.61-4.51-0.37-6.26-2.04c-1.8-1.73-3.92-2.66-6.3-3.19c-1.05-0.24-2.01-0.85-3.02-1.29c-0.46-0.2-0.93-0.38-1.52-0.63
		c0.08-0.39,0.03-0.71-0.1-0.97c5.43,3.13,11.31,4.99,17.94,5.3c10.39,0.49,20.38-1.86,29.89-6.01c0,0.05-0.01,0.1-0.01,0.16
		c-0.27,0.19-0.55,0.38-0.82,0.57c0.55,0.06,0.91-0.19,1.29-0.4c0.87-0.47,1.78-1.32,2.61-1.28c1.37,0.05,2.27-0.76,3.32-1.34
		c2.68-1.47,5.36-2.95,8.16-4.49c0.06,0.53,0.09,0.82,0.13,1.11c0.13,0.05,0.26,0.09,0.39,0.14c0.56-1,1.12-2,1.7-3.04
		c4.63-4.39,9.35-8.96,13.13-14.46c3.79-5.53,5.74-11.93,7.34-18.33c-0.27-0.47-0.37-0.94-0.33-1.42c0-0.05,0.01-0.11,0.01-0.16
		C967.14,322.75,968.73,319.91,970.29,317.07z"
            />
            <path
                fill={moonColor}
                d="M914.53,251.43c2.18,1.37,4.65,1.01,6.94,1.62c4.12,1.1,8.58,0.32,12.66,1.89c0.88,0.34,1.95,0.14,2.94,0.2
		c0.03-0.26,0.05-0.52,0.08-0.79c-11.37-3.18-22.95-4.59-34.88-2.65c2.77-0.01,5.55-0.28,8.33-0.44
		C911.93,251.17,913.54,250.8,914.53,251.43z"
            />
            <path
                fill={moonColor}
                d="M914.25,375.13c0.82,0.15,1.38,0.26,1.94,0.36c-0.02,0.1-0.04,0.21-0.06,0.31c-0.33,0.15-0.65,0.3-0.98,0.44
		c0.04,0.21,0.08,0.41,0.11,0.62c2.45-0.44,4.9-0.88,7.5-1.35C920.03,373.02,917.28,373.91,914.25,375.13z"
            />
            <path
                fill={moonColor}
                d="M935.64,369.03c0.28,0.43,0.69,0.77,0.65,1.06c-0.06,0.37-0.45,0.72-0.75,1.03c-0.35,0.38-0.75,0.7-1.13,1.05
		c0.14,0.17,0.29,0.33,0.43,0.5c1.18-1.07,2.36-2.13,3.72-3.36c-0.7-0.22-1.1-0.34-1.51-0.47c-0.08-0.19-0.15-0.38-0.23-0.57
		c3.04-0.35,4.92-2.32,6.5-4.73c-2.28,1.32-4.49,2.75-6.65,4.25C936.2,368.12,935.89,368.72,935.64,369.03z"
            />
            <path
                fill={moonColor}
                d="M940.52,256.39c3.34,1.82,5.94,4.91,9.8,6.47c-0.1-0.53-0.03-1.26-0.3-1.43c-3.17-1.98-6.38-3.88-9.59-5.79
		c-0.05-0.03-0.17,0.05-0.33,0.11c0.05,0.34,0.09,0.68,0.14,1.02C940.33,256.64,940.42,256.51,940.52,256.39z"
            />
            <path
                fill={moonColor}
                d="M869.77,369.01c0.09,0.14,0.67-0.12,1.01-0.19c-2.57-2.63-4.95-5.06-7.34-7.5
		C864.22,365.16,867.86,366.25,869.77,369.01z"
            />
            <path
                fill={moonColor}
                d="M908.82,378.74c1.53-0.58,3.24-0.57,4.85-0.93c0.4-0.09,0.73-0.6,1.28-1.08c-3.33,0.37-6.2,0.69-9.06,1
		C906.85,378.02,907.38,379.28,908.82,378.74z"
            />
            <path
                fill={moonColor}
                d="M856.37,350.86c-0.14,0.12-0.28,0.25-0.42,0.37c1.11,1.34,2.21,2.67,3.32,4.01l0.01,0c-0.01,0-0.01,0-0.01,0
		c-0.33,2.17,0.56,3.52,2.72,3.87c-0.93-1.38-1.79-2.64-2.64-3.91C858.51,353.62,858.21,351.57,856.37,350.86z"
            />
            <path
                fill={moonColor}
                d="M902.55,377.85c1.18,0.36,2.35,0.73,3.78,1.17c-0.25-0.59-0.4-0.94-0.55-1.28c-1.08-0.04-2.16-0.08-3.24-0.12
		C902.55,377.69,902.55,377.77,902.55,377.85z"
            />
            <path
                fill={moonColor}
                d="M905.78,377.74C905.79,377.74,905.79,377.74,905.78,377.74L905.78,377.74c0.04,0,0.07-0.01,0.1-0.01
		c-0.04-0.01-0.07-0.02-0.11-0.03C905.77,377.72,905.78,377.73,905.78,377.74z"
            />
            <path
                fill={moonColor}
                d="M853.78,345.77c0.58,1.4,1.16,2.81,1.86,4.5c0.12-1.16,0.2-1.92,0.26-2.54c-0.68-0.76-1.3-1.45-1.91-2.13
		C853.92,345.65,853.85,345.71,853.78,345.77z"
            />
            <path
                fill={moonColor}
                d="M854.17,341.98c-0.76,0.14-1.23,0.22-1.73,0.32c0.34,0.84,0.69,1.69,1.04,2.54c0.16-0.05,0.32-0.09,0.48-0.14
		C854.03,343.79,854.1,342.89,854.17,341.98z"
            />
            <path
                fill={moonColor}
                d="M931.41,374.01c-0.8,0.5-1.49,1.2-2.23,1.82c-2.32,0.78-4.65,1.57-7,2.36c0.45,1.96,1.41,0.88,2.14,0.48
		c0.88-0.48,1.67-1.15,2.55-1.64c0.75-0.43,1.57-0.72,2.36-1.08v0c1.14-0.44,2.29-0.88,3.43-1.32c0-0.16,0-0.32,0-0.49
		C932.24,374.08,931.7,373.82,931.41,374.01z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M1003.55,509.05c-7.81-4-14.76-10.27-20.06-17.67c-0.3-3.82-2.72-8.31-6.08-10.59c0,0.01,0,0.02,0.01,0.03
		c-1.37-3.07-2.47-6.24-3.24-9.43c-0.6-0.26-1.19-0.5-1.79-0.74c-0.13-1.67-0.18-3.34-0.17-5c-1.07-0.53-2.18-0.95-3.39-1.2
		c-0.82-0.17-1.63-0.39-2.44-0.62c-0.85-0.37-1.72-0.64-2.6-0.8c-1.54-0.48-3.09-0.94-4.67-1.2c-3.64-0.6-7.37-0.73-11.07-0.82
		c-4.66-0.12-9.08,1.49-13.52,2.83c-0.91,0.28-1.81,0.61-2.68,1.03c-2.56,1.24-5.13,2.45-7.63,3.84c-1.2,0.67-2.32,1.36-3.61,1.9
		c-1.9,0.79-3.66,2.25-5.22,3.74c-4.17,3.97-8.22,8.09-12.28,12.2c-0.58,0.59-0.88,1.5-1.41,2.17c-0.73,0.93-1.6,1.72-2.31,2.66
		c-0.88,1.15-1.71,2.36-2.48,3.59c-0.81,1.28-1.53,2.63-2.42,4.19c0.71-0.07,1.02-0.1,1.71-0.17c-0.03,0.08-0.06,0.16-0.09,0.25
		c-0.7,0.85-1.18,1.44-1.7,2.07c0.07,0.18,0.4,0.61,0.3,0.82c-1.09,2.41-2.24,4.78-3.38,7.16c-0.04,0.09-0.15,0.14-0.23,0.2
		c-0.74,0.63-1.22,1.26-1.47,2.41c-1.52,7.18-2.26,14.38-1.63,21.7c0.17,1.98,0.22,3.98,0.41,5.96c0.34,3.44,0.7,6.88,2.71,9.76
		c0.05,0.07-0.12,0.3-0.15,0.46c-0.22,1.63,1.05,4.55,2.47,5.43c-0.55-2.09-2.5-3.79-0.85-6.47c0.64,1.15,1.15,2.16,1.73,3.1
		c1.08,1.74,2.2,3.46,3.33,5.16c0.09,0.14,0.44,0.08,0.67,0.12c-0.59-1.18-1.15-2.38-1.76-3.55c-1.73-3.28-2.96-6.77-3.6-10.47
		c1.32,0.9,2.49,2.14,3.45,3.54c0.71,1.78,1.48,3.48,2.3,5.1c0.38,1.55,0.47,3.06,0.2,4.38c0.84,1.62,1.65,3.04,2.38,4.34
		c0.03,0.12,0.06,0.24,0.08,0.35c-0.12,0.07-0.25,0.14-0.37,0.21c-0.89-1.3-1.79-2.6-2.68-3.89c-0.12,0.67-0.25,1.34-0.37,2.02
		c1.52,0.12,1.55,1.6,1.95,2.71c1.27-0.9,1.37,0.26,1.39,0.87c0.07,2.36,1.56,3.17,2.79,3.62c0.05,0.08,0.11,0.14,0.16,0.22
		c0.03,0.1,0.06,0.19,0.09,0.29c2.36,7.86,4.65,10.29,11.54,10.45c0.57,0.44,1.15,0.88,1.72,1.32c0.1-0.1,0.19-0.19,0.29-0.29
		c-0.08-0.34-0.16-0.68-0.25-1.04c0.09,0,0.17,0,0.26-0.01c1.17-0.04,2.42,0.43,3.5,0.99c1.06,0.55,2.13,1.06,3.2,1.53
		c-0.44-0.11-0.88-0.22-1.32-0.34c-0.04-0.01-0.11,0.09-0.1,0.09c0.24,0.3,0.72,0.72,0.66,0.86c-0.65,1.47,0.53,1.64,1.24,1.98
		c1.44,0.7,2.97,1.16,4.4,1.88c2.75,1.38,5.62,2.12,8.67,2.2c1.89,0.05,3.78,0.33,5.68,0.29c2.07-0.05,4.14-0.37,6.2-0.57
		c-0.02-0.16-0.03-0.33-0.05-0.49c-1.13,0.07-2.26,0.28-3.37,0.18c-2.19-0.2-4.5,0.5-6.53-0.81c-2.09-1.35-4.36-1.86-6.79-1.93
		c-1.08-0.03-2.14-0.45-3.21-0.69c-0.49-0.11-0.99-0.2-1.62-0.32c0.01-0.4-0.1-0.7-0.28-0.94c5.93,2.03,12.06,2.73,18.62,1.77
		c5.43-0.8,10.6-2.35,15.53-4.51c7.03-1.73,13.78-4.57,19.83-8.36c-0.07,0.15-0.13,0.3-0.19,0.41c0.36,0.37,0.83,0.63,0.84,0.91
		c0.02,0.37-0.31,0.79-0.54,1.15c-0.28,0.44-0.6,0.83-0.91,1.25c0.17,0.13,0.35,0.27,0.52,0.4c0.95-1.27,1.91-2.54,3-4.01
		c-0.73-0.08-1.15-0.12-1.57-0.17c-0.11-0.16-0.21-0.33-0.32-0.49c0.04-0.03,0.08-0.05,0.12-0.08c1.46-0.49,2.55-1.33,3.41-2.39
		C1003.43,554.4,1013,533.61,1003.55,509.05z M892.54,517.96c-0.03,0.23-0.05,0.45-0.07,0.68
		C892.3,518.36,892.27,518.12,892.54,517.96z M891.29,531.81c-0.21-2.64-0.15-5.31,0.46-8.02c-0.65-1.4-0.32-2.41,0.55-3.25
		c-0.24,3.4-0.18,6.88,0.21,10.41C892.14,531.25,891.74,531.54,891.29,531.81z"
            />
            <path
                fill={moonColor}
                d="M900.69,567.83c-0.11,0.15-0.23,0.3-0.34,0.44c1.34,1.1,2.68,2.2,4.03,3.3l0,0c0.09,2.2,1.22,3.35,3.41,3.28
		c-1.18-1.18-2.26-2.25-3.34-3.33C903.32,570.13,902.64,568.18,900.69,567.83z"
            />
            <path
                fill={moonColor}
                d="M917.32,583.09c0.12,0.12,0.64-0.24,0.96-0.38c-3.02-2.09-5.83-4.02-8.64-5.96
		C911.14,580.37,914.92,580.74,917.32,583.09z"
            />
            <path
                fill={moonColor}
                d="M954.34,584.77C954.34,584.77,954.35,584.77,954.34,584.77L954.34,584.77c0.04-0.01,0.06-0.02,0.09-0.03
		c-0.04,0-0.08-0.01-0.12-0.01C954.32,584.74,954.33,584.75,954.34,584.77z"
            />
            <path
                fill={moonColor}
                d="M951.18,585.49c1.22,0.13,2.45,0.27,3.94,0.43c-0.36-0.53-0.57-0.84-0.78-1.15c-1.07,0.17-2.14,0.33-3.2,0.5
		C951.15,585.34,951.17,585.41,951.18,585.49z"
            />
            <path
                fill={moonColor}
                d="M957.51,585.16c1.39-0.87,3.07-1.18,4.58-1.84c0.37-0.16,0.6-0.73,1.05-1.3c-3.2,1-5.95,1.86-8.7,2.72
		C955.44,584.83,956.2,585.97,957.51,585.16z"
            />
            <path
                fill={moonColor}
                d="M975.91,463.03c-0.02,0.14-0.05,0.28-0.06,0.41c0.07,0.01,0.14,0.03,0.21,0.05
		C976,463.34,975.96,463.19,975.91,463.03z"
            />
            <path
                fill={moonColor}
                d="M938.75,459.11c2.4,0.93,4.76,0.1,7.12,0.26c4.25,0.29,8.48-1.33,12.79-0.56c0.93,0.16,1.94-0.23,2.92-0.37
		c-0.02-0.26-0.05-0.52-0.07-0.79c-11.77-0.94-23.4-0.11-34.74,4.07c2.72-0.54,5.4-1.33,8.1-2.03
		C936.15,459.36,937.66,458.69,938.75,459.11z"
            />
            <path
                fill={moonColor}
                d="M965.2,459.01c2.45,0.78,4.68,2.19,7.13,3.26c0.02-0.43,0.05-0.86,0.08-1.28c-2.48-0.92-4.96-1.8-7.44-2.69
		c-0.06-0.02-0.16,0.08-0.3,0.17c0.11,0.32,0.22,0.65,0.33,0.97C965.07,459.29,965.14,459.15,965.2,459.01z"
            />
            <path
                fill={moonColor}
                d="M976.99,468.62c-0.45-0.31-0.9-0.61-1.35-0.92c0.02,0.6,0.05,1.2,0.1,1.8
		C976.2,469.22,976.61,468.93,976.99,468.62z"
            />
            <path
                fill={moonColor}
                d="M895.2,560.19c0.5,0.76,1,1.53,1.51,2.29c0.15-0.08,0.3-0.15,0.45-0.23c-0.11-0.9-0.21-1.8-0.32-2.71
		C896.12,559.83,895.68,560,895.2,560.19z"
            />
            <path
                fill={moonColor}
                d="M979.15,466c0.22-0.43,0.4-0.88,0.54-1.34c-1.13-0.71-2.18-1.26-3.12-1.59c0.38,0.67,0.52,1.15,0.82,1.41
		C977.96,465,978.55,465.5,979.15,466z"
            />
            <path
                fill={moonColor}
                d="M962.15,580.59c0.84-0.01,1.41-0.01,1.98-0.02c0,0.1,0,0.21,0,0.31c-0.29,0.21-0.59,0.42-0.88,0.62
		c0.08,0.2,0.15,0.39,0.23,0.59c2.32-0.9,4.64-1.8,7.1-2.76C967.42,577.41,964.89,578.8,962.15,580.59z"
            />
            <path
                fill={moonColor}
                d="M897.35,563.13c-0.06,0.07-0.11,0.14-0.17,0.21c0.84,1.27,1.68,2.53,2.69,4.06c-0.11-1.17-0.17-1.93-0.23-2.54
		C898.82,564.24,898.08,563.68,897.35,563.13z"
            />
            <polygon fill={moonColor} points="898.33,557.12 898.34,557.13 898.34,557.12 	" />
            <path
                fill={moonColor}
                d="M978.78,576.2c-0.69,0.65-1.24,1.46-1.84,2.21c-2.13,1.21-4.26,2.43-6.42,3.66c0.81,1.84,1.55,0.59,2.19,0.06
		c0.77-0.64,1.42-1.45,2.18-2.1c0.66-0.56,1.4-1.01,2.11-1.51v0c1.04-0.65,2.08-1.3,3.12-1.95c-0.03-0.16-0.06-0.32-0.09-0.48
		C979.6,576.11,979.03,575.96,978.78,576.2z"
            />
            <polygon fill={moonColor} points="898.33,557.12 898.33,557.12 898.34,557.12 	" />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M934.82,736.56c-1.54-4.77-6.76-6.4-12.69-7.69c0.14,0.23,0.29,0.45,0.45,0.63c0.35,0.42,0.77,0.78,1.28,1.3
		c-0.66,1.59,0.75,1.01,1.79,0.78c0.03,0.54,0.1,1.04,0.24,1.48c-19.94-11.33-30.49-36.26-27.74-58.67
		c-0.85-0.1-1.69-0.19-2.53-0.26c0.04-0.83,0.08-1.66,0.11-2.49c0.04-0.96,0.18-1.91,0.37-2.85c0.22-1.07,0.42-2.14,0.64-3.2
		c-1.32-0.01-2.64,0.04-3.97,0.17c-0.05,0.26-0.11,0.52-0.17,0.77c-0.62,2.47-1.16,4.97-1.88,7.4c-0.03,0-0.06,0-0.09,0
		c0.43-2.78,1.02-5.49,1.76-8.14c-1.92,0.2-3.86,0.55-5.83,1.06c-10.07,2.61-18.91,7.81-26.77,14.59c-0.01-0.05-0.02-0.1-0.04-0.15
		c0.21-0.26,0.41-0.53,0.62-0.79c-0.54,0.11-0.81,0.45-1.12,0.76c-0.69,0.71-1.31,1.78-2.11,2c-1.32,0.35-1.94,1.4-2.77,2.26
		c-2.12,2.2-4.24,4.4-6.47,6.71c-0.22-0.49-0.33-0.75-0.45-1.02c-0.14,0-0.28-0.01-0.41-0.01c-0.24,1.12-0.48,2.24-0.72,3.41
		c-3.12,5.56-6.28,11.33-8.26,17.7c-0.79,2.56-1.26,5.16-1.52,7.79c-1.6,7.08-2,14.42-1.05,21.58c-0.09,0.42-0.17,0.83-0.26,1.25
		c-0.96,4.65-0.22,9.74-1.13,14.41c-1.52,7.85,4.41,7,7.66,9.33c0.53,0.38,0.95,0.94,1.49,1.3c1.17,0.77,2.34,1.54,3.51,2.31
		c0.21,0.36,0.43,0.72,0.63,1.08c0.15-0.05,0.31-0.1,0.46-0.15c0.01-0.06,0.01-0.13,0.02-0.19c0.26,0.17,0.53,0.35,0.79,0.52
		c-0.06-0.03-0.12-0.06-0.18-0.09c-0.07,0.16-0.13,0.33-0.2,0.49c2.67,5.6,15.21,15.8,21.61,17.32c-0.45-0.63-0.64-1.08-0.96-1.31
		c-1.69-1.2-3.44-2.3-5.14-3.5c-1.86-1.32-3.67-2.73-5.52-4.05c-0.4-0.29-0.9-0.4-1.35-0.61c-0.3-0.14-0.67-0.23-0.89-0.47
		c-2.01-2.28-3.97-4.59-5.99-6.86c0.36,0.23,0.72,0.46,1.07,0.7c3.21,3.1,6.92,5.92,11.17,8.37c8.27,8.17,21.29,9.51,34.66,6.83
		c0.51-0.04,1.01-0.08,1.52-0.12c4.64-0.39,8.87-2.46,13.13-4.27c0.32-0.14,0.63-0.29,0.94-0.44
		C939.95,772.1,961.18,747.64,934.82,736.56z"
            />
            <path
                fill={moonColor}
                d="M881.96,664.81c-1.23,0-2.46,0-3.96,0c0.42,0.49,0.66,0.78,0.9,1.06c1.04-0.28,2.09-0.56,3.13-0.84
		C882.01,664.96,881.99,664.89,881.96,664.81z"
            />
            <path
                fill={moonColor}
                d="M886.46,663.93c2.16-0.04,4.36-0.95,6.49,0.06c0.15-0.5,0.32-0.99,0.48-1.48c-1.44,0.06-2.88,0.06-4.3,0.25
		c-2.05,0.28-4.07,0.81-6.11,1.23c0.03,0.16,0.07,0.32,0.1,0.48C884.24,664.28,885.35,663.95,886.46,663.93z"
            />
            <path
                fill={moonColor}
                d="M875.71,665.82c-1.29,1.01-2.92,1.5-4.36,2.32c-0.35,0.2-0.52,0.79-0.9,1.41c3.07-1.34,5.72-2.49,8.36-3.64
		C877.8,665.93,876.92,664.87,875.71,665.82z"
            />
            <path
                fill={moonColor}
                d="M896.84,665.16c0.19-0.93,0.39-1.85,0.62-2.77c-0.88-0.04-1.77-0.04-2.66,0.04c-0.15,0.01-0.3,0.02-0.45,0.03
		c-0.12,0.13-0.25,0.25-0.39,0.37c-0.36,0.3-0.59,0.76-0.76,1.27C894.38,664.67,895.6,665,896.84,665.16z"
            />
            <path
                fill={moonColor}
                d="M947.42,742.47c-0.49,0.18-0.97,0.37-1.42,0.6c0.28,0.09,0.56,0.18,0.85,0.27
		C947.05,743.04,947.24,742.76,947.42,742.47z"
            />
            <path
                fill={moonColor}
                d="M854.3,677.27c0.42-0.07,1,0.01,1.23-0.25c0.61-0.72,1.07-1.59,1.59-2.39c1.99-1.44,3.98-2.87,5.99-4.33
		c-1.01-1.74-1.61-0.42-2.19,0.18c-0.7,0.72-1.26,1.59-1.95,2.32c-0.59,0.63-1.29,1.16-1.93,1.73l0,0
		c-0.96,0.76-1.92,1.52-2.89,2.27C854.2,676.96,854.25,677.12,854.3,677.27z"
            />
            <path
                fill={moonColor}
                d="M907.94,789.11c-2.49-0.66-4.74,0.42-7.11,0.51c-4.26,0.17-8.29,2.24-12.65,1.94
		c-0.94-0.06-1.91,0.44-2.86,0.68c0.05,0.26,0.1,0.52,0.15,0.77c11.81-0.33,23.28-2.41,34.1-7.79c-2.64,0.83-5.22,1.91-7.83,2.89
		C910.51,788.58,909.07,789.42,907.94,789.11z"
            />
            <path
                fill={moonColor}
                d="M881.66,792.07c-3.73-0.75-7.13-2.94-11.27-3.28c0.25,0.48,0.41,1.2,0.7,1.28c3.61,0.96,7.25,1.82,10.88,2.69
		c0.06,0.01,0.15-0.1,0.28-0.2c-0.14-0.31-0.29-0.62-0.43-0.93C881.76,791.77,881.71,791.92,881.66,792.07z"
            />
            <path
                fill={moonColor}
                d="M871.59,670.87c-0.83,0.1-1.4,0.16-1.97,0.23c-0.01-0.1-0.03-0.21-0.04-0.31c0.27-0.24,0.54-0.48,0.81-0.71
		c-0.1-0.19-0.19-0.37-0.29-0.56c-2.21,1.15-4.42,2.29-6.76,3.5C866.69,674.6,869.06,672.94,871.59,670.87z"
            />
            <path
                fill={moonColor}
                d="M878.9,665.87C878.9,665.87,878.9,665.87,878.9,665.87L878.9,665.87c-0.04,0.01-0.06,0.03-0.09,0.04
		c0.04,0,0.08,0,0.12,0C878.92,665.89,878.91,665.88,878.9,665.87z"
            />
            <path
                fill={moonColor}
                d="M852.96,683.03c-0.39-0.33-0.89-0.53-0.93-0.82c-0.06-0.37,0.22-0.82,0.41-1.2c0.23-0.46,0.51-0.89,0.77-1.34
		c-0.19-0.11-0.37-0.23-0.56-0.34c-0.81,1.37-1.62,2.73-2.56,4.31c0.73,0,1.16,0,1.58,0c0.13,0.16,0.26,0.32,0.39,0.48
		c-2.8,1.23-4.02,3.67-4.8,6.44c1.79-1.94,3.47-3.95,5.09-6.03C852.7,684.07,852.81,683.4,852.96,683.03z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M745.51,949.81c1.28,0.26,2.55,0.53,4.1,0.85c-0.33-0.57-0.53-0.9-0.72-1.23c-1.14,0.05-2.28,0.1-3.41,0.15
		C745.49,949.66,745.5,949.73,745.51,949.81z"
            />
            <path
                fill={moonColor}
                d="M752.18,950.16c1.54-0.71,3.33-0.85,4.98-1.34c0.41-0.12,0.7-0.66,1.22-1.19c-3.45,0.65-6.42,1.22-9.39,1.78
		C750.04,949.61,750.74,950.83,752.18,950.16z"
            />
            <path
                fill={moonColor}
                d="M775.35,943.51c-0.78,0.57-1.43,1.32-2.13,2c-2.35,0.98-4.7,1.96-7.08,2.96c0.69,1.92,1.58,0.75,2.3,0.29
		c0.87-0.56,1.62-1.29,2.48-1.86c0.74-0.49,1.56-0.86,2.35-1.28v0c1.15-0.54,2.3-1.07,3.45-1.61c-0.02-0.16-0.04-0.32-0.05-0.49
		C776.22,943.5,775.64,943.29,775.35,943.51z"
            />
            <path
                fill={moonColor}
                d="M748.89,949.43C748.89,949.43,748.89,949.43,748.89,949.43L748.89,949.43c0.04-0.01,0.07-0.01,0.1-0.02
		c-0.04-0.01-0.08-0.02-0.12-0.02C748.87,949.4,748.88,949.41,748.89,949.43z"
            />
            <path
                fill={moonColor}
                d="M710.11,943.79c0.11,0.13,0.69-0.17,1.04-0.27c-2.99-2.4-5.77-4.63-8.55-6.86
		C703.86,940.42,707.8,941.2,710.11,943.79z"
            />
            <path
                fill={moonColor}
                d="M757.47,946.1c0.88,0.08,1.48,0.14,2.08,0.2c-0.01,0.1-0.02,0.21-0.02,0.31c-0.33,0.18-0.65,0.35-0.98,0.53
		c0.06,0.2,0.13,0.41,0.19,0.61c2.52-0.65,5.04-1.3,7.71-1.99C763.3,943.49,760.51,944.61,757.47,946.1z"
            />
            <path
                fill={moonColor}
                d="M779.22,938.17c0.34,0.4,0.81,0.71,0.8,1c-0.02,0.37-0.39,0.75-0.67,1.09c-0.33,0.41-0.71,0.77-1.07,1.15
		c0.17,0.15,0.34,0.31,0.51,0.46c1.12-1.17,2.23-2.33,3.52-3.67c-0.76-0.16-1.2-0.25-1.63-0.34c-0.1-0.18-0.2-0.37-0.31-0.55
		c3.15-0.61,4.9-2.73,6.28-5.28c-2.24,1.51-4.39,3.13-6.49,4.82C779.7,937.21,779.45,937.84,779.22,938.17z"
            />
            <path
                fill={moonColor}
                d="M690.69,918.14c-0.78,0.2-1.26,0.33-1.77,0.46c0.46,0.81,0.92,1.63,1.38,2.45c0.16-0.06,0.33-0.12,0.49-0.18
		C690.75,919.95,690.72,919.04,690.69,918.14z"
            />
            <path
                fill={moonColor}
                d="M735.69,822.86c-1.6,0.27-3.2,0.6-4.79,0.98c1.57-0.13,3.14-0.34,4.71-0.57
		C735.63,823.13,735.66,822.99,735.69,822.86z"
            />
            <path
                fill={moonColor}
                d="M694,926.81c-0.13,0.13-0.27,0.27-0.4,0.4c1.31,1.24,2.62,2.48,3.94,3.72l0,0c-0.1,2.2,0.98,3.47,3.29,3.64
		c-1.14-1.3-2.18-2.49-3.22-3.68C696.56,929.38,696.01,927.37,694,926.81z"
            />
            <path
                fill={moonColor}
                d="M690.91,921.76c-0.07,0.06-0.13,0.13-0.2,0.19c0.77,1.35,1.54,2.71,2.46,4.34c-0.01-1.17-0.01-1.94-0.01-2.56
		C692.35,923.02,691.63,922.39,690.91,921.76z"
            />
            <path
                fill={moonColor}
                d="M743.25,822.36c-0.02-0.13-0.03-0.27-0.05-0.4c-0.72,0.05-1.44,0.11-2.16,0.19c-0.27,0.13-0.52,0.28-0.74,0.43
		C741.31,822.39,742.39,822.17,743.25,822.36z"
            />
            <path
                fill={moonColor}
                d="M743.38,825.96c-1.48,0.26-2.95,0.55-4.42,0.81c-0.67,2.34-0.91,4.69-0.93,7.04
		C741.78,832.15,743.16,829.36,743.38,825.96z"
            />
            <polygon fill={moonColor} points="692.48,915.88 692.48,915.88 692.48,915.88 	" />
            <polygon fill={moonColor} points="692.48,915.88 692.48,915.89 692.48,915.88 	" />
            <path
                fill={moonColor}
                d="M802.88,894.09c-0.66,0.3-1.16,0.22-1.61,0.01c0.03-0.36,0.08-0.72,0.1-1.08c1.39-0.26,2.81-0.53,4.29-0.81
		c0,0,0,0,0,0c0.61-1.32,1.2-2.63,1.8-3.95c-5.04,1.43-10.25,2.31-15.64,2.51c-1.46,0.05-2.9,0.03-4.32-0.05
		c-4.72-0.6-9.46-1.68-13.81-3.16c-0.43-0.17-0.86-0.35-1.29-0.54c0.03,0.04,0.05,0.08,0.07,0.12c-1.77-0.64-3.46-1.36-5.04-2.12
		c-0.04-0.06-0.09-0.11-0.12-0.16c0,0,0.08-0.09,0.12-0.08c0.45,0.16,0.9,0.32,1.36,0.48c-1.09-0.58-2.16-1.2-3.23-1.87
		c-1.08-0.67-2.36-1.27-3.59-1.36c-0.04,0-0.07-0.01-0.11-0.01c-7.42-4.74-12.32-11.32-16.01-18.1c0.07-0.03,0.14-0.06,0.21-0.09
		c-0.02-0.12-0.04-0.24-0.06-0.36c-0.66-1.38-1.38-2.87-2.12-4.58c1.27-4.02-1.02-10.4-5.09-13.61c0.17,1.87,0.5,3.7,0.97,5.5
		c-1.83-4.34-3.5-8.25-5.44-11.2c0.03-1.43,0.09-2.86,0.09-4.29c0-2.48,0.16-4.93,0.46-7.35c-2.44,0.83-4.88,1.67-7.27,2.64
		c-1.32,0.54-2.56,1.11-3.96,1.5c-2.07,0.59-4.05,1.85-5.83,3.17c-4.74,3.51-9.37,7.18-14,10.84c-0.67,0.53-1.06,1.4-1.68,2.01
		c-0.85,0.85-1.83,1.55-2.67,2.4c-1.03,1.05-2,2.17-2.93,3.32c-0.96,1.19-1.84,2.46-2.92,3.91c0.64,0,0.97,0.01,1.5,0.01
		c-0.21,0.34-0.42,0.68-0.62,1.02c-0.41,0.39-0.78,0.74-1.16,1.1c0.04,0.12,0.16,0.33,0.23,0.53c-0.45,0.81-0.88,1.63-1.28,2.45
		c-0.96,1.55-1.92,3.1-2.89,4.65c-0.05,0.08-0.17,0.12-0.26,0.18c-0.84,0.55-1.4,1.12-1.76,2.25c-2.24,6.99-3.67,14.09-3.66,21.45
		c0,1.99-0.12,3.99-0.1,5.98c0.05,3.46,0.12,6.93,1.98,10.02c0.05,0.08-0.16,0.28-0.19,0.44c-0.38,1.6,0.69,4.65,2.11,5.68
		c-0.39-2.14-2.29-4.05-0.32-6.54c0.57,1.22,1.01,2.27,1.54,3.27c0.98,1.85,2,3.68,3.04,5.5c0.09,0.15,0.46,0.13,0.7,0.19
		c-0.51-1.24-1-2.5-1.54-3.72c-1.27-2.87-2.15-5.86-2.61-8.94c1.43,5.29,3.58,10.33,6.36,15c0.21,0.45,0.42,0.88,0.62,1.3
		c0.02,0.12,0.04,0.24,0.06,0.36c-0.14,0.06-0.27,0.12-0.41,0.17c-0.82-1.39-1.65-2.78-2.47-4.17c-0.19,0.66-0.38,1.31-0.57,1.97
		c1.59,0.28,1.48,1.76,1.81,2.91c1.41-0.76,1.42,0.41,1.38,1.02c-0.14,2.36,1.36,3.33,2.61,3.9c0.05,0.08,0.1,0.16,0.15,0.23
		c0.02,0.1,0.05,0.2,0.07,0.3c1.78,8.09,3.96,10.75,11.2,11.65c0.56,0.5,1.13,1,1.69,1.5c0.11-0.09,0.22-0.17,0.33-0.26
		c-0.05-0.35-0.11-0.69-0.17-1.06c0.09,0.01,0.18,0.02,0.28,0.02c1.24,0.09,2.51,0.69,3.59,1.36c1.07,0.66,2.15,1.28,3.23,1.87
		c-0.45-0.16-0.9-0.32-1.36-0.48c-0.04-0.01-0.12,0.08-0.12,0.08c0.22,0.33,0.69,0.79,0.61,0.93c-0.81,1.4,0.41,1.69,1.12,2.11
		c1.45,0.85,3.02,1.48,4.46,2.34c2.77,1.67,5.72,2.71,8.92,3.12c1.99,0.25,3.95,0.74,5.94,0.89c2.18,0.17,4.38,0.08,6.57,0.1
		c0-0.16-0.01-0.33-0.01-0.49c-1.19-0.05-2.4,0.04-3.56-0.18c-2.28-0.43-4.78,0.02-6.8-1.51c-2.08-1.57-4.42-2.32-6.97-2.65
		c-1.13-0.15-2.21-0.68-3.31-1.03c-0.51-0.16-1.02-0.3-1.67-0.5c0.04-0.39-0.04-0.71-0.21-0.96c6.05,2.66,12.43,4.01,19.42,3.76
		c0.84-0.03,1.68-0.08,2.52-0.15c6.02-0.03,12.3-1.02,18.71-3.16c4.7-1.56,9.13-3.8,13.21-6.57c1.07-0.25,1.81-0.97,2.68-1.53
		c2.64-1.7,5.28-3.4,8.06-5.19c0.12,0.53,0.19,0.81,0.26,1.09c0.14,0.03,0.28,0.07,0.43,0.1c0.47-1.05,0.95-2.09,1.44-3.18
		c4.36-4.78,8.8-9.75,12.13-15.57c3.35-5.85,4.67-12.41,5.62-18.93c-0.13-0.17-0.23-0.35-0.31-0.53
		C804.84,893.43,803.81,893.65,802.88,894.09z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M590.84,982.21c0.1-0.14,0.21-0.28,0.34-0.38c1.18-0.92,1.36-2.15,1.84-3.32c0.11-0.27,0.22-0.54,0.33-0.8
		c-0.79,0.17-1.56,0.43-2.21,0.83c-0.08,0.05-0.16,0.1-0.25,0.15c0.27,0.26,0.52,0.51,0.77,0.75c-0.04,0.13-0.08,0.27-0.12,0.4
		c-0.51-0.04-1.01-0.08-1.52-0.12c-2.14-4.09-8.21-7.15-19.86-8.19c-51.01-31.09-14.55-71.84-37.92-69.45
		c0.68-2.22,1.37-4.44,2.08-6.65c-2.05,0.03-4.1,0.24-6.13,0.95c-0.87,0.3-1.75,0.56-2.64,0.8c-1,0.14-1.96,0.39-2.88,0.74
		c-1.71,0.43-3.42,0.89-5.05,1.53c-3.77,1.47-7.37,3.41-10.93,5.35c-4.48,2.45-7.85,6.24-11.37,9.82c-0.72,0.73-1.4,1.52-2.01,2.35
		c-1.79,2.46-3.61,4.91-5.26,7.46c-0.79,1.22-1.5,2.44-2.44,3.6c-1.4,1.72-2.31,3.93-3.03,6.06c-1.91,5.68-3.62,11.43-5.35,17.17
		c-0.25,0.83-0.06,1.77-0.22,2.63c-0.21,1.19-0.63,2.35-0.82,3.54c-0.24,1.47-0.4,2.96-0.5,4.44c-0.1,1.54-0.09,3.09-0.14,4.91
		c0.64-0.45,0.91-0.65,1.53-1.08c0.01,0.09,0.03,0.17,0.04,0.26c-0.23,1.11-0.38,1.88-0.55,2.71c0.16,0.12,0.69,0.31,0.71,0.54
		c0.21,2.66,0.34,5.32,0.49,7.99c0.01,0.1-0.07,0.2-0.12,0.3c-0.38,0.94-0.51,1.75-0.15,2.87c2.26,6.99,5.28,13.57,9.66,19.5
		c1.19,1.61,2.26,3.29,3.47,4.88c2.09,2.76,4.22,5.51,7.62,6.89c0.08,0.03,0.04,0.32,0.1,0.47c0.63,1.52,3.34,3.33,5.15,3.31
		c-1.6-1.49-4.33-1.89-4.15-5.08c1.21,0.64,2.2,1.22,3.25,1.71c1.93,0.9,3.87,1.76,5.83,2.6c0.16,0.07,0.46-0.17,0.7-0.27
		c-1.17-0.69-2.32-1.41-3.51-2.08c-3.34-1.87-6.31-4.19-8.83-7.01c5.34,0.14,11.06,3.9,12.38,7.91c1.64,0.93,3.13,1.7,4.5,2.42
		c0.09,0.09,0.18,0.17,0.26,0.26c-0.08,0.13-0.16,0.26-0.24,0.39c-1.52-0.62-3.04-1.25-4.56-1.87c0.23,0.64,0.46,1.29,0.69,1.93
		c1.51-0.73,2.3,0.53,3.26,1.26c0.74-1.46,1.44-0.52,1.77-0.01c1.28,1.98,3.12,1.87,4.51,1.58c0.09,0.03,0.17,0.07,0.26,0.1
		c0.08,0.07,0.16,0.13,0.24,0.2c6.3,5.45,9.73,6.28,16.36,2.65c0.77,0.06,1.55,0.13,2.32,0.19c0.04-0.13,0.08-0.27,0.12-0.4
		c-0.25-0.25-0.5-0.49-0.77-0.75c0.08-0.05,0.16-0.1,0.25-0.15c1.09-0.67,2.52-0.96,3.83-1.07c1.29-0.11,2.57-0.26,3.83-0.44
		c-0.48,0.14-0.95,0.29-1.42,0.43c-0.04,0.01-0.05,0.14-0.05,0.13c0.38,0.13,1.06,0.22,1.07,0.38c0.15,1.62,1.35,1.12,2.2,1.02
		c1.72-0.19,3.42-0.63,5.14-0.8c3.32-0.32,6.43-1.26,9.37-2.86c1.82-0.99,3.77-1.78,5.54-2.86c1.94-1.18,3.74-2.58,5.6-3.88
		c-0.1-0.13-0.2-0.26-0.3-0.39c-1.03,0.67-2,1.48-3.11,2c-2.18,1.03-4.01,2.89-6.62,2.88c-2.68-0.01-5.1,0.79-7.44,2.06
		c-1.04,0.56-2.26,0.78-3.4,1.16c-0.52,0.18-1.04,0.37-1.7,0.61c-0.2-0.34-0.46-0.54-0.75-0.65c6.68-1.5,12.86-4.25,18.59-8.66
		c2.46-1.89,4.75-3.93,6.88-6.08c2.9-2.1,5.56-4.33,7.82-6.6c-0.48,0.69-0.92,1.45-1.36,2.24c0.79-0.46,1.33-0.78,1.87-1.09
		c0.06,0.09,0.11,0.18,0.17,0.27c-0.17,0.34-0.34,0.68-0.51,1.01c0.17,0.13,0.35,0.25,0.52,0.38c1.74-2.04,3.48-4.09,5.32-6.24
		c-1.67,0.03-2.93,0.5-3.96,1.25C589.2,990.69,591.43,986.13,590.84,982.21z M489.39,996.54c-1.56-2.15-2.89-4.47-3.7-7.13
		c-1.8-1.14-1.57-2.65-0.54-4.38c-1.09-0.31-2.7-0.49-1.17-1.58c-0.16-0.71-0.32-1.24-0.4-1.79c-0.03-0.24-0.04-0.51-0.03-0.78
		C488.91,985.63,493.15,990.3,489.39,996.54z"
            />
            <path
                fill={moonColor}
                d="M511.32,1020.08c-0.02,0.09-0.04,0.18-0.05,0.27c1.45,0.63,2.9,1.26,4.65,2.01c-0.7-0.94-1.16-1.56-1.53-2.06
		C513.29,1020.23,512.31,1020.16,511.32,1020.08z"
            />
            <path
                fill={moonColor}
                d="M516.93,1022.29c-0.03,0.19-0.06,0.38-0.09,0.57c1.84,0.21,3.69,0.42,5.53,0.63l0.01-0.01
		c0,0.01-0.01,0.01,0,0.01c1.22,1.83,2.89,2.21,4.93,0.95c-1.73-0.36-3.31-0.7-4.89-1.03
		C520.62,1022.83,518.96,1021.53,516.93,1022.29z"
            />
            <path
                fill={moonColor}
                d="M579.82,1006.09c0.87-1.5,2.3-2.69,3.4-4.08c0.27-0.34,0.19-0.95,0.32-1.69c-2.52,2.6-4.69,4.85-6.86,7.09
		C577.69,1006.94,579.01,1007.5,579.82,1006.09z"
            />
            <path
                fill={moonColor}
                d="M573.99,1009.82c1.23-0.56,2.46-1.11,3.96-1.79c-0.62-0.26-0.98-0.41-1.34-0.56
		c-0.93,0.73-1.86,1.45-2.78,2.18C573.88,1009.71,573.93,1009.77,573.99,1009.82z"
            />
            <path
                fill={moonColor}
                d="M576.61,1007.48C576.61,1007.48,576.62,1007.48,576.61,1007.48L576.61,1007.48c0.03-0.03,0.05-0.05,0.07-0.07
		c-0.04,0.02-0.08,0.03-0.12,0.06C576.58,1007.47,576.6,1007.48,576.61,1007.48z"
            />
            <path
                fill={moonColor}
                d="M540.6,1026.28c0.17,0.03,0.48-0.56,0.71-0.85c-3.95-0.14-7.61-0.26-11.28-0.39
		C533.33,1027.33,537.11,1025.58,540.6,1026.28z"
            />
            <path
                fill={moonColor}
                d="M507.77,1018.74c0.86,0.38,1.74,0.76,2.62,1.14c0.1-0.15,0.2-0.29,0.3-0.44c-0.57-0.72-1.13-1.43-1.7-2.15
		C508.45,1017.92,508.12,1018.32,507.77,1018.74z"
            />
            <path
                fill={moonColor}
                d="M535.86,890.8c-0.36,0.05-0.72,0.1-1.04,0.17c0.33,0.17,0.59,0.34,0.82,0.47
		C535.71,891.23,535.79,891.02,535.86,890.8z"
            />
            <path
                fill={moonColor}
                d="M521.94,893.7c4.04-1,8.33-0.54,12.62-2.09c-0.45-0.32-0.9-0.91-1.23-0.84c-4,0.76-7.99,1.61-11.98,2.45
		c-0.07,0.01-0.11,0.16-0.2,0.31c0.27,0.21,0.54,0.43,0.82,0.65C521.96,894.02,521.95,893.86,521.94,893.7z"
            />
            <path
                fill={moonColor}
                d="M496.88,908.25c2.76-0.52,4.57-2.52,6.89-3.67c4.19-2.07,7.37-5.78,11.85-7.47c0.96-0.36,1.73-1.26,2.58-1.91
		c-0.16-0.21-0.32-0.42-0.47-0.64c-11.66,5.63-22.27,12.7-30.88,22.49c2.3-1.95,4.43-4.09,6.64-6.17
		C494.54,909.89,495.63,908.49,496.88,908.25z"
            />
            <path
                fill={moonColor}
                d="M596.51,985.99c-0.39,0.26-1.01,0.44-1.13,0.79c-0.32,0.93-0.42,1.93-0.61,2.9c-1.4,2.21-2.79,4.41-4.2,6.64
		c1.72,1.13,1.78-0.34,2.11-1.15c0.4-0.97,0.6-2.02,0.99-2.99c0.33-0.84,0.81-1.63,1.22-2.45h0c0.65-1.13,1.3-2.25,1.95-3.37
		C596.73,986.23,596.62,986.11,596.51,985.99z"
            />
            <polygon fill={moonColor} points="509.15,1014.4 509.15,1014.4 509.16,1014.39 	" />
            <polygon fill={moonColor} points="509.15,1014.4 509.17,1014.4 509.16,1014.39 	" />
            <path
                fill={moonColor}
                d="M596.52,979.01c-0.2-0.09-0.39-0.17-0.59-0.26c0.46-0.48,0.84-0.98,1.15-1.49c-0.42,0.05-0.84,0.09-1.27,0.13
		c-0.11,0.37-0.23,0.74-0.34,1.12c-0.17,0.57-0.01,1.22,0,1.63c0.53,0.12,1.11,0.08,1.27,0.33c0.21,0.31,0.12,0.85,0.09,1.28
		c-0.04,0.52-0.14,1.05-0.22,1.57c0.23,0.02,0.47,0.04,0.7,0.06c0.25-1.61,0.5-3.23,0.78-5.08
		C597.37,978.63,596.94,978.82,596.52,979.01z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M293.28,982.13c-1.31-2.7-3.81-4.08-5.63-6.19c-3.26-3.8-7.84-6.19-10.82-10.37c-0.64-0.9-1.75-1.47-2.64-2.2
		c-0.16,0.21-0.33,0.42-0.49,0.63c8.95,10.61,19.01,19.81,31.17,26.36c-2.59-1.91-5.33-3.59-8.01-5.36
		C295.57,984.15,293.87,983.35,293.28,982.13z"
            />
            <path
                fill={moonColor}
                d="M394.03,952.69c0.26-1.71,0.49-3.43,0.7-5.16c-0.28,0.03-0.57,0.09-0.86,0.14c-0.16,0.63-0.32,1.25-0.47,1.88
		c-0.38,1.67-0.88,3.29-1.47,4.85c0.62-0.21,1.25-0.41,1.89-0.54C393.89,953.47,393.97,953.08,394.03,952.69z"
            />
            <path
                fill={moonColor}
                d="M265.89,947.52c-0.19,0.53-0.63,1.12-0.48,1.44c1.91,3.91,3.92,7.78,5.9,11.65c0.03,0.06,0.19,0.07,0.37,0.13
		c0.13-0.32,0.27-0.65,0.4-0.98c-0.15,0.04-0.31,0.09-0.46,0.13C269.47,956.01,268.67,951.53,265.89,947.52z"
            />
            <path
                fill={moonColor}
                d="M397.09,951c-1.01,1.97-0.6,4.84-3.8,5.38c0.11-0.53,0.22-1.02,0.32-1.5c-0.61-0.02-1.23-0.04-1.84-0.06
		c-0.74,1.86-1.64,3.64-2.72,5.32c-0.44-1.71-0.55-3.53-0.37-5.3c-0.13,0.01-0.26,0.01-0.39,0.03c-1.08,0.12-2.13,0.44-3.21,0.54
		c-0.91,0.09-2.25,0.74-2.19-1.36c1.96,0.15,3.9,0.29,5.84,0.43c0.33-2.79,1.35-5.41,2.82-7.2c-11.12-0.02-22.25-1.91-33.04-6.59
		c-7.09-3.07-12.94-7.35-17.95-12.67c0.07,0.32,0.03,0.65-0.16,0.99c0.58,0.49,1.03,0.86,1.48,1.25c0.97,0.85,1.84,1.85,2.91,2.52
		c2.42,1.51,4.47,3.31,5.93,5.75c0.06,0.09,0.12,0.18,0.18,0.27c-5.74-4.11-10.66-9.28-14.57-15.12c-0.01-0.03-0.02-0.06-0.02-0.08
		c-0.01,0,0.11-0.05,0.15-0.02c0.39,0.36,0.78,0.72,1.17,1.08c-0.86-1.05-1.69-2.14-2.5-3.26c-0.29-0.4-0.6-0.8-0.94-1.18
		c-8.82-15.25-11.13-34.25-4.26-50.88c-3.76,0.24-7.31,0.62-10.67,1.14c0.33-0.96,0.71-1.9,1.09-2.85c0.15-0.36,0.3-0.72,0.46-1.07
		c-6.37,0.61-12.89,1.35-19.05,3.44c-6.45,2.19-11.64,6.42-16.51,10.88c0.01,0.59-0.15,1.07-0.43,1.46
		c-0.03,0.04-0.07,0.09-0.1,0.13c-2.98,1.35-5.95,2.73-8.91,4.12c-4.33,2.04-7.76,5.9-12.06,7.99c-7.24,3.53-2.21,8.42-1.64,12.86
		c0.09,0.72-0.04,1.46,0.08,2.17c0.26,1.56,0.52,3.11,0.78,4.66c-0.12,0.42-0.25,0.84-0.37,1.25c0.15,0.11,0.3,0.22,0.46,0.32
		c0.05-0.03,0.11-0.07,0.17-0.1c0.06,0.35,0.12,0.7,0.18,1.05c-0.02-0.07-0.04-0.15-0.06-0.22c-0.18,0.04-0.35,0.08-0.53,0.13
		c-2.4,5.95-1.13,23.82,2.4,30.64c0.16-0.8,0.38-1.26,0.32-1.71c-0.31-2.3-0.74-4.6-1.05-6.9c-0.34-2.53-0.57-5.07-0.9-7.6
		c-0.07-0.54-0.35-1.08-0.52-1.62c-0.11-0.37-0.32-0.76-0.29-1.11c0.3-3.26,0.66-6.52,0.94-9.78c0.72,4.26,1.46,8.52,2.21,12.78
		c0.11,0.62,0.6,1.56,1.08,1.71c0.01,0,0.02,0.01,0.04,0.01c0.01,0.19,0.01,0.38,0.02,0.57c0.05,1.97,0.12,4.03,0.71,5.91
		c1.41,4.45,1.93,9.14,4.7,13.25c0.55,0.82,1.06,1.66,1.56,2.51c0.43,0.98,0.96,1.9,1.57,2.77c0.93,1.65,1.87,3.28,2.98,4.8
		c2.55,3.5,5.51,6.74,8.46,9.92c3.72,4.01,8.44,6.58,12.99,9.35c0.93,0.57,1.91,1.09,2.9,1.51c2.95,1.26,5.89,2.56,8.89,3.66
		c1.44,0.53,2.84,0.97,4.26,1.67c2.1,1.03,4.55,1.46,6.86,1.71c6.16,0.65,12.33,1.1,18.5,1.55c0.89,0.07,1.76-0.34,2.66-0.38
		c1.24-0.06,2.5,0.1,3.74,0.03c1.52-0.09,3.03-0.26,4.53-0.5c1.55-0.25,3.08-0.61,4.89-0.98c-0.63-0.55-0.9-0.78-1.51-1.32
		c0.08-0.03,0.16-0.07,0.24-0.1c1.16-0.02,1.96-0.04,2.83-0.06c0.07-0.19,0.1-0.78,0.33-0.85c2.56-0.82,5.15-1.56,7.74-2.31
		c0.09-0.03,0.22,0.03,0.33,0.05c1.04,0.17,1.87,0.13,2.88-0.5c6.24-3.9,11.85-8.47,16.43-14.28c1.24-1.57,2.59-3.05,3.81-4.64
		c2.12-2.76,4.21-5.55,4.58-9.33c0.01-0.09,0.31-0.11,0.44-0.21C396.64,956.02,397.64,952.84,397.09,951z M376.6,975.1
		c-0.6,2.09-2.16,2.2-4.17,1.54c0.01,1.18,0.31,2.86-1.21,1.55c-0.65,0.33-1.13,0.61-1.65,0.81c-0.23,0.09-0.49,0.15-0.76,0.21
		c3.11-6.55,6.5-11.93,13.74-9.51C380.89,971.79,378.99,973.66,376.6,975.1z M378.7,950.51c0.3-0.51,0.6-1.01,0.89-1.53
		c-0.97-0.12-2.08-0.3-3.28-0.54c-0.34-0.2-0.72-0.42-1.18-0.69c3.62,0.06,6.85,0.34,9.11,3.94
		C382.35,951.29,380.52,950.9,378.7,950.51z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M108.95,849.5c-0.11,0.16-0.23,0.32-0.34,0.48c1.71,0.94,3.43,1.87,5.14,2.81l0.01-0.01
		c0,0-0.01,0.01-0.01,0.01c0.4,2.17,1.87,3.18,4.42,2.86c-1.54-1.03-2.94-1.97-4.35-2.92C112.33,851.48,111.27,849.62,108.95,849.5z
		"
            />
            <path
                fill={moonColor}
                d="M130.4,862.71c0.15,0.1,0.71-0.32,1.07-0.49c-3.81-1.72-7.34-3.31-10.88-4.91
		C122.83,860.74,127.29,860.66,130.4,862.71z"
            />
            <path
                fill={moonColor}
                d="M173.84,860.05c0,0,0.01,0,0.01,0v0c0.03-0.01,0.06-0.02,0.09-0.04c-0.05,0-0.09,0-0.14,0
		C173.82,860.03,173.83,860.04,173.84,860.05z"
            />
            <path
                fill={moonColor}
                d="M202.66,848.42c-0.49,0.08-1.18-0.01-1.45,0.26c-0.71,0.72-1.25,1.6-1.85,2.41c-2.32,1.45-4.65,2.91-7,4.38
		c1.2,1.73,1.89,0.41,2.57-0.2c0.81-0.73,1.46-1.6,2.27-2.34c0.69-0.64,1.5-1.17,2.26-1.75l0,0c1.12-0.77,2.25-1.53,3.37-2.3
		C202.77,848.73,202.72,848.58,202.66,848.42z"
            />
            <path
                fill={moonColor}
                d="M182.39,854.99c0.97-0.1,1.64-0.18,2.31-0.25c0.02,0.1,0.03,0.21,0.05,0.31c-0.31,0.24-0.63,0.48-0.94,0.72
		c0.12,0.19,0.23,0.37,0.35,0.56c2.59-1.17,5.18-2.33,7.92-3.57C188.12,851.21,185.35,852.89,182.39,854.99z"
            />
            <path
                fill={moonColor}
                d="M170.26,861.13c1.45-0.01,2.89-0.02,4.65-0.04c-0.49-0.48-0.78-0.77-1.07-1.05c-1.22,0.29-2.45,0.58-3.67,0.87
		C170.2,860.99,170.23,861.06,170.26,861.13z"
            />
            <path
                fill={moonColor}
                d="M132.75,789.34c0.33,2.77,1.27,5.27,2.49,7.64c5.17-5.63,1.64-10.91-3.07-16.43c-0.06,0.27-0.1,0.54-0.12,0.78
		c-0.03,0.55,0.04,1.1,0.07,1.83c-1.82,0.85-0.16,1.27,0.92,1.74C131.62,786.47,131.09,787.94,132.75,789.34z"
            />
            <path
                fill={moonColor}
                d="M215.6,822.34c-6.88,3.52-13.9,5.32-21.2,5.8c0.29,0.15,0.52,0.39,0.66,0.76c0.74-0.14,1.32-0.25,1.9-0.35
		c1.27-0.21,2.6-0.24,3.79-0.64c2.71-0.91,5.4-1.34,8.21-0.92c2.32,0.35,4.32-0.74,6.39-1.49c0.87-1.43,1.71-2.89,2.51-4.37
		C217.13,821.54,216.37,821.95,215.6,822.34z"
            />
            <path
                fill={moonColor}
                d="M138.54,737.05c0.12-0.21,0.24-0.42,0.36-0.64c-4.68,1.18-9.29,2.71-13.79,4.66c3.1-0.85,6.12-1.96,9.18-2.96
		C135.68,737.66,137.25,736.89,138.54,737.05z"
            />
            <path
                fill={moonColor}
                d="M101.51,842.55c0.68,0.69,1.37,1.4,2.07,2.1c0.16-0.09,0.33-0.19,0.49-0.28c-0.25-0.88-0.49-1.77-0.74-2.65
		C102.54,842.08,102.04,842.31,101.51,842.55z"
            />
            <path
                fill={moonColor}
                d="M104.41,845.22c-0.06,0.08-0.11,0.15-0.17,0.23c1.15,1.16,2.3,2.32,3.68,3.72c-0.28-1.15-0.46-1.89-0.61-2.5
		C106.28,846.15,105.35,845.68,104.41,845.22z"
            />
            <path
                fill={moonColor}
                d="M177.59,860.07c1.51-1.02,3.42-1.53,5.1-2.36c0.41-0.2,0.6-0.79,1.05-1.42c-3.6,1.37-6.7,2.54-9.79,3.72
		C175.13,859.99,176.18,861.03,177.59,860.07z"
            />
            <polygon fill={moonColor} points="104.75,839.14 104.76,839.15 104.76,839.14 	" />
            <path
                fill={moonColor}
                d="M207.38,829.06c-2.16,0.73-4.35,1.18-6.56,1.36c0.04-0.06,0.08-0.12,0.12-0.18c-0.27,0.08-0.54,0.15-0.81,0.23
		c-1.04,0.06-2.08,0.07-3.13,0.02c-1.84-0.09-3.71,0.09-5.55,0.02c-0.91-0.04-2.27,0.28-2.1-1.35c0.02-0.16-0.67-0.35-1.05-0.54
		c0,0,0.03-0.12,0.08-0.13c0.53-0.07,1.05-0.14,1.58-0.21c-1.36-0.01-2.73-0.05-4.1-0.14c-1.4-0.09-2.95-0.02-4.23,0.48
		c-0.1,0.04-0.19,0.07-0.29,0.11c0.23,0.3,0.45,0.58,0.66,0.87c-0.07,0.13-0.14,0.25-0.21,0.38c-0.8-0.18-1.6-0.36-2.39-0.54
		c-7.67,2.61-11.09,1.27-16.64-5.1c-0.07-0.08-0.14-0.15-0.21-0.23c-0.08-0.05-0.17-0.09-0.25-0.14c-1.52,0.08-3.47-0.08-4.43-2.25
		c-0.25-0.56-0.8-1.6-1.86-0.26c-0.86-0.87-1.45-2.24-3.17-1.74c-0.12-0.67-0.23-1.35-0.35-2.02c0,0-0.01,0-0.01-0.01c0,0,0,0,0,0
		c-0.27,0.06-0.63,0.26-0.79,0.16c-1.89-1.13-3.76-2.27-5.6-3.46c-1-0.64-1.93-1.37-3.07-2.19c-0.81,3.14,1.97,3.95,3.36,5.67
		c-1.9-0.25-4.39-2.46-4.75-4.06c-0.04-0.16,0.07-0.44-0.01-0.49c-3.29-1.88-4.99-4.93-6.64-7.99c-0.95-1.76-1.75-3.6-2.68-5.37
		c-3.43-6.55-5.31-13.54-6.32-20.83c-0.16-1.17,0.13-1.95,0.72-2.83c0.06-0.09,0.17-0.18,0.18-0.28c0.37-2.67,0.75-5.33,1.05-8.01
		c0.03-0.24-0.49-0.5-0.64-0.65c0.34-0.8,0.65-1.53,1.1-2.61c0-0.09,0-0.18,0.01-0.26c-0.73,0.34-1.06,0.49-1.82,0.84
		c0.41-1.81,0.7-3.35,1.11-4.86c0.39-1.46,0.85-2.91,1.39-4.33c0.44-1.16,1.1-2.24,1.56-3.4c0.33-0.84,0.32-1.8,0.74-2.58
		c1.52-2.82,3.04-5.64,4.58-8.45c-0.49,0.18-0.98,0.37-1.47,0.55c-1.02,0.38-2.03,0.82-2.99,1.34c-2.82,1.53-5.66,3.04-8.38,4.71
		c-1.31,0.8-2.53,1.62-3.95,2.3c-2.12,1.01-3.97,2.66-5.59,4.33c-4.33,4.43-8.51,8.99-12.69,13.55c-0.6,0.66-0.83,1.59-1.35,2.32
		c-0.72,1-1.63,1.9-2.34,2.91c-0.87,1.25-1.67,2.54-2.41,3.86c-0.77,1.37-1.43,2.79-2.27,4.44c0.82-0.16,1.18-0.22,1.97-0.37
		c-0.02,0.08-0.05,0.17-0.07,0.25c-0.7,0.93-1.19,1.57-1.71,2.26c0.11,0.17,0.55,0.56,0.46,0.78c-0.95,2.52-1.97,5.01-2.98,7.5
		c-0.04,0.09-0.16,0.15-0.24,0.23c-0.78,0.71-1.26,1.39-1.39,2.57c-0.81,7.31-0.71,14.55,1.01,21.74c0.47,1.95,0.79,3.92,1.28,5.87
		c0.85,3.38,1.74,6.75,4.48,9.38c0.07,0.07-0.1,0.31-0.11,0.47c-0.04,1.64,1.83,4.4,3.61,5.11c-0.92-2.01-3.42-3.47-1.86-6.32
		c0.9,1.07,1.63,2.01,2.44,2.87c1.49,1.6,3.03,3.18,4.58,4.73c0.13,0.13,0.53,0.03,0.8,0.04c-0.84-1.11-1.66-2.23-2.54-3.32
		c-2.46-3.06-4.36-6.38-5.61-9.98c5.17,2.29,9.14,8.03,8.7,12.24c1.2,1.52,2.33,2.82,3.37,4.03c0.05,0.12,0.1,0.23,0.14,0.34
		c-0.13,0.09-0.27,0.17-0.4,0.26c-1.22-1.18-2.43-2.37-3.65-3.55c-0.05,0.68-0.11,1.36-0.16,2.05c1.79-0.06,2.02,1.41,2.64,2.47
		c1.36-1.04,1.64,0.1,1.74,0.71c0.4,2.33,2.25,2.97,3.74,3.27c0.07,0.07,0.14,0.13,0.21,0.2c0.05,0.09,0.1,0.18,0.15,0.28
		c3.81,7.53,6.8,9.68,14.88,9.03c0.73,0.37,1.46,0.74,2.19,1.11c0.1-0.11,0.2-0.21,0.3-0.32c-0.14-0.33-0.28-0.65-0.43-1
		c0.1-0.01,0.2-0.02,0.31-0.04c1.36-0.17,2.89,0.14,4.22,0.57c1.32,0.42,2.63,0.8,3.94,1.14c-0.53-0.06-1.06-0.12-1.58-0.18
		c-0.05-0.01-0.11,0.11-0.11,0.1c0.32,0.27,0.94,0.63,0.88,0.78c-0.56,1.54,0.84,1.57,1.71,1.82c1.77,0.52,3.62,0.81,5.39,1.35
		c3.4,1.05,6.85,1.45,10.42,1.17c2.22-0.17,4.46-0.11,6.66-0.38c2.41-0.29,4.78-0.85,7.17-1.29c-0.04-0.16-0.08-0.32-0.13-0.48
		c-1.3,0.2-2.6,0.54-3.91,0.57c-2.58,0.06-5.19,1.03-7.74-0.04c-2.62-1.1-5.34-1.34-8.19-1.12c-1.26,0.09-2.56-0.2-3.84-0.31
		c-0.59-0.05-1.18-0.08-1.93-0.13c-0.04-0.4-0.21-0.68-0.46-0.9c7.19,1.32,14.44,1.31,21.97-0.42c11.81-2.7,22.16-7.98,31.34-14.84
		c0.01,0.05,0.03,0.1,0.04,0.15c-0.24,0.27-0.48,0.53-0.72,0.8c0.63-0.11,0.95-0.46,1.31-0.77c0.81-0.71,1.52-1.8,2.47-2.02
		c1.55-0.37,2.27-1.42,3.24-2.29c2.48-2.22,4.95-4.44,7.55-6.77c0.26,0.49,0.4,0.75,0.53,1.02c0.16,0,0.32,0.01,0.49,0.01
		c0.27-1.12,0.54-2.24,0.82-3.42c0.93-1.43,1.86-2.87,2.76-4.34c-0.3,0.12-0.59,0.25-0.89,0.37
		C211.68,827.85,209.49,828.35,207.38,829.06z M180.31,832.85c-2.21-0.04-4.41-0.19-6.6-0.44c-0.49-0.26-0.97-0.57-1.41-0.98
		C174.93,831.89,177.57,832.36,180.31,832.85z M159.09,826.71c2.28-0.45,3.76,1.09,5.52,1.93c1.6,0.57,3.19,1.14,4.93,1.76
		c-2.39,0.94-4.06,0.32-4.99-1.69c0,0,0,0,0.01-0.01l-0.01,0.01c-1.89-0.49-3.78-0.97-5.68-1.46
		C158.95,827.08,159.02,826.89,159.09,826.71z M153.64,823.67c1.02,0.22,2.04,0.45,3.17,0.69c0.29,0.55,0.65,1.23,1.21,2.27
		c-1.69-1.02-3.08-1.86-4.48-2.7C153.57,823.85,153.6,823.76,153.64,823.67z M151.74,820.55c0.45,0.79,0.91,1.59,1.36,2.39
		c-0.14,0.13-0.27,0.26-0.41,0.39c-0.84-0.51-1.69-1.03-2.52-1.53C150.63,821.43,151.05,821.09,151.74,820.55z M93.13,814.82
		c-0.6-2.6-0.89-5.25-0.54-8.02c-1.27-1.77-0.39-3.06,1.37-4.23c-0.93-0.72-2.44-1.54-0.46-1.91c0.15-0.72,0.22-1.27,0.38-1.79
		c0.07-0.23,0.18-0.48,0.3-0.73c0.01,0.01,0.01,0.02,0.02,0.03c0.06,4.63,0.77,9.17,2.04,13.54
		C95.59,812.83,94.58,813.87,93.13,814.82z"
            />
            <polygon fill={moonColor} points="104.75,839.14 104.75,839.14 104.76,839.14 	" />
            <path
                fill={moonColor}
                d="M152.48,817.7C152.48,817.7,152.49,817.69,152.48,817.7c0.01,0,0.01,0,0.01,0.01c1.47,0.85,2.94,1.7,4.41,2.55
		c0.11-0.12,0.22-0.23,0.33-0.35c-0.07-0.1-0.15-0.19-0.22-0.29c-1.3-0.91-2.72-1.9-4.25-3.08c-0.6-4.18-5.86-8.78-11.44-9.72
		c2.09,3.18,4.75,5.94,7.89,8.3C150.32,815.94,151.39,816.83,152.48,817.7C152.48,817.69,152.48,817.69,152.48,817.7L152.48,817.7z"
            />
            <path
                fill={moonColor}
                d="M204.89,841.16c-0.41,0.45-0.54,1.12-0.71,1.49c0.47,0.32,1.05,0.52,1.1,0.81c0.07,0.37-0.25,0.82-0.47,1.21
		c-0.26,0.47-0.59,0.9-0.89,1.35c0.22,0.11,0.44,0.23,0.66,0.34c0.94-1.37,1.89-2.75,2.97-4.33c-0.86,0.01-1.36,0.01-1.85,0.01
		c-0.15-0.16-0.31-0.32-0.46-0.47c3.28-1.26,4.69-3.71,5.59-6.48C208.75,837.04,206.78,839.07,204.89,841.16z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M100.2,644.12c-15.45,6.65-34.71,5.28-49.77-1.77c-67.63-43.98,2.73-103.86-26.57-84.43
		C3.78,573.03-2.04,592.58,1.16,610.69c-0.06-0.08-0.12-0.16-0.18-0.24c-0.06,0.28-0.12,0.55-0.14,0.8c-0.05,0.57,0,1.14,0,1.9
		c-1.85,0.79-0.21,1.3,0.86,1.85c-1.48,1.54-2.07,3.03-0.45,4.56c0.24,2.87,1.08,5.5,2.22,8.02c0.93-0.96,1.6-1.93,2.04-2.9
		C20.6,658.22,65.71,679.96,100.2,644.12z"
            />
            <polygon fill={moonColor} points="19.98,649.85 19.98,649.85 19.99,649.85 	" />
            <path
                fill={moonColor}
                d="M31.71,539.49c3.01,0.05,5.36-1.6,8.05-2.28c4.85-1.23,9.03-4.32,14.1-5.1c1.09-0.17,2.09-0.92,3.14-1.4
		c-0.12-0.25-0.23-0.5-0.35-0.75c-13.49,3.27-26.2,8.22-37.46,16.36c2.86-1.49,5.58-3.23,8.36-4.87
		C28.89,540.67,30.35,539.47,31.71,539.49z"
            />
            <polygon fill={moonColor} points="19.99,649.85 19.98,649.85 19.99,649.86 	" />
            <path
                fill={moonColor}
                d="M99.34,650.12c0.93-0.3,1.57-0.51,2.21-0.72c0.04,0.1,0.07,0.2,0.11,0.31c-0.26,0.31-0.51,0.62-0.77,0.92
		c0.15,0.16,0.3,0.33,0.46,0.49c2.29-1.71,4.58-3.42,7-5.23C104.15,645.12,101.79,647.39,99.34,650.12z"
            />
            <path
                fill={moonColor}
                d="M118.87,631.87c2.95-1.94,3.81-4.71,4.11-7.71c-1.63,2.4-3.12,4.86-4.53,7.37c-0.3,0.54-0.29,1.24-0.38,1.66
		c0.52,0.23,1.14,0.32,1.25,0.6c0.14,0.36-0.07,0.88-0.21,1.32c-0.16,0.53-0.39,1.03-0.59,1.55c0.24,0.07,0.48,0.14,0.72,0.21
		c0.63-1.59,1.27-3.17,1.99-4.99c-0.84,0.18-1.33,0.29-1.81,0.39C119.24,632.13,119.05,632,118.87,631.87z"
            />
            <path
                fill={moonColor}
                d="M61.26,529.98c4.45-0.17,8.83,1.2,13.66,0.52c-0.39-0.42-0.73-1.11-1.09-1.12c-4.36-0.07-8.72-0.04-13.08-0.02
		c-0.07,0-0.15,0.14-0.28,0.27c0.23,0.27,0.47,0.55,0.7,0.83C61.2,530.3,61.23,530.14,61.26,529.98z"
            />
            <path
                fill={moonColor}
                d="M50.07,668.52c0.17,0.07,0.63-0.47,0.94-0.71c-4.09-0.97-7.89-1.86-11.69-2.76
		C42.23,668.06,46.59,667.08,50.07,668.52z"
            />
            <path
                fill={moonColor}
                d="M95.71,656.25c1.26-1.34,3.03-2.25,4.5-3.43c0.36-0.29,0.42-0.93,0.72-1.65c-3.23,2.12-6.02,3.94-8.8,5.76
		C93.29,656.66,94.53,657.51,95.71,656.25z"
            />
            <path
                fill={moonColor}
                d="M26.27,659.51c-0.08,0.18-0.15,0.37-0.23,0.55c1.88,0.6,3.75,1.2,5.63,1.8l0.01-0.01c0,0-0.01,0.01-0.01,0.01
		c0.85,2.12,2.51,2.85,4.93,2c-1.72-0.73-3.3-1.4-4.87-2.07C30,660.82,28.57,659.15,26.27,659.51z"
            />
            <path
                fill={moonColor}
                d="M88.76,658.82c1.41-0.31,2.83-0.61,4.55-0.98c-0.58-0.39-0.93-0.62-1.27-0.85c-1.14,0.54-2.27,1.09-3.41,1.63
		C88.67,658.68,88.71,658.75,88.76,658.82z"
            />
            <path
                fill={moonColor}
                d="M92.04,656.99C92.04,656.99,92.04,656.98,92.04,656.99l0.01,0c0.03-0.02,0.06-0.04,0.08-0.05
		c-0.04,0.01-0.09,0.02-0.13,0.03C92.01,656.97,92.02,656.98,92.04,656.99z"
            />
            <path
                fill={moonColor}
                d="M20.93,656.08c-0.04,0.09-0.08,0.18-0.12,0.27c1.37,0.94,2.74,1.89,4.39,3.02c-0.52-1.1-0.85-1.82-1.13-2.41
		C22.95,656.65,21.94,656.37,20.93,656.08z"
            />
            <path
                fill={moonColor}
                d="M17.53,653.97c0.81,0.56,1.64,1.14,2.47,1.71c0.14-0.13,0.28-0.26,0.42-0.38c-0.43-0.85-0.85-1.69-1.28-2.54
		C18.43,653.29,17.99,653.62,17.53,653.97z"
            />
            <path
                fill={moonColor}
                d="M117.8,639.34c-0.47,0.18-1.16,0.23-1.36,0.56c-0.55,0.88-0.88,1.87-1.3,2.82c-1.97,1.95-3.94,3.89-5.93,5.86
		c1.53,1.51,1.94,0.03,2.47-0.73c0.64-0.9,1.1-1.92,1.73-2.83c0.54-0.79,1.22-1.49,1.84-2.23l0,0c0.94-1.01,1.88-2.01,2.82-3.02
		C117.97,639.63,117.88,639.49,117.8,639.34z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M81.07,447.24c0.97,0.16,1.63,0.26,2.29,0.37c-0.01,0.11-0.03,0.22-0.04,0.32c-0.37,0.16-0.74,0.31-1.11,0.47
		c0.06,0.22,0.12,0.43,0.18,0.65c2.82-0.48,5.63-0.95,8.61-1.46C87.62,445,84.49,445.94,81.07,447.24z"
            />
            <path
                fill={moonColor}
                d="M75.04,451.04c1.74-0.62,3.72-0.62,5.57-1c0.46-0.09,0.8-0.63,1.4-1.14c-3.84,0.41-7.16,0.76-10.46,1.11
		C72.7,450.3,73.42,451.62,75.04,451.04z"
            />
            <path
                fill={moonColor}
                d="M67.68,450.15c1.4,0.37,2.8,0.75,4.5,1.2c-0.34-0.61-0.54-0.98-0.74-1.33c-1.26-0.04-2.52-0.07-3.78-0.11
		C67.66,449.99,67.67,450.07,67.68,450.15z"
            />
            <path
                fill={moonColor}
                d="M28.83,441.13c0.12,0.14,0.77-0.13,1.16-0.2c-3.2-2.73-6.17-5.26-9.14-7.79
		C22.07,437.15,26.39,438.26,28.83,441.13z"
            />
            <path
                fill={moonColor}
                d="M8.5,412.99c-0.87,0.15-1.41,0.24-1.98,0.34c0.47,0.87,0.94,1.76,1.41,2.65c0.18-0.05,0.37-0.1,0.55-0.15
		C8.48,414.88,8.49,413.93,8.5,412.99z"
            />
            <path
                fill={moonColor}
                d="M8.35,416.95c0.79,1.46,1.58,2.93,2.53,4.69c0.04-1.22,0.07-2.01,0.1-2.66c-0.86-0.79-1.63-1.5-2.4-2.22
		C8.5,416.82,8.43,416.88,8.35,416.95z"
            />
            <path
                fill={moonColor}
                d="M11.77,422.25c-0.15,0.13-0.31,0.26-0.46,0.39c1.4,1.39,2.79,2.78,4.19,4.17l0.01-0.01c-0.01,0-0.01,0-0.01,0
		c-0.21,2.27,0.93,3.68,3.48,4.03c-1.2-1.44-2.29-2.75-3.39-4.07C14.49,425.12,13.97,422.98,11.77,422.25z"
            />
            <path
                fill={moonColor}
                d="M71.43,450.02c0,0,0.01,0,0.01,0l0,0c0.03,0,0.07-0.01,0.1-0.01c-0.04-0.01-0.09-0.02-0.13-0.03
		C71.42,450,71.43,450.01,71.43,450.02z"
            />
            <path
                fill={moonColor}
                d="M124.89,422.52c-1.27-0.02-2.54-0.28-3.8-0.32c-0.92-0.03-1.85,0.31-2.75,0.18
		c-6.23-0.93-12.47-1.84-18.67-2.96c-2.33-0.42-4.78-1.04-6.81-2.24c-1.37-0.81-2.75-1.37-4.16-2.01c-2.93-1.33-5.79-2.86-8.66-4.36
		c-0.97-0.5-1.9-1.1-2.79-1.74c-4.34-3.14-8.87-6.09-12.22-10.41c-2.66-3.44-5.32-6.92-7.53-10.65c-0.96-1.62-1.75-3.33-2.51-5.07
		c-0.53-0.92-0.96-1.89-1.29-2.91c-0.41-0.9-0.84-1.78-1.32-2.65c-2.38-4.35-2.39-9.12-3.34-13.71c-0.4-1.94-0.24-4.03-0.08-6.02
		c0.02-0.19,0.03-0.38,0.04-0.57c-0.01-0.01-0.02-0.01-0.04-0.02c-0.48-0.18-0.87-1.16-0.92-1.8c-0.2-2.84-0.39-5.69-0.57-8.53
		c1.88-7.92,3.09-12.96-0.79-12.79c-0.02-0.32-0.04-0.64-0.06-0.96c-0.04-0.73-0.3-1.63,0.15-2.2c1.3-1.63,2.51-2.71,3.1-4.79
		c-0.37,0.09-0.74,0.18-1.12,0.25c-2.32,0.45-4.56,1.61-6.58,2.83c-5.39,3.27-10.67,6.72-15.95,10.15
		c-0.76,0.49-1.23,1.37-1.94,1.96c-0.97,0.81-2.1,1.46-3.06,2.28c-1.18,1.01-2.31,2.09-3.39,3.21c-1.12,1.16-2.14,2.4-3.4,3.83
		c0.84,0.06,1.19,0.09,2,0.15c-0.05,0.08-0.09,0.16-0.14,0.23c-0.93,0.74-1.57,1.25-2.27,1.8c0.06,0.2,0.37,0.71,0.23,0.91
		c-1.6,2.26-3.28,4.48-4.94,6.7c-0.06,0.08-0.2,0.11-0.3,0.16c-0.95,0.5-1.6,1.05-2.05,2.19c-2.79,7.08-4.68,14.33-5,21.97
		c-0.09,2.07-0.32,4.13-0.38,6.2c-0.1,3.6-0.17,7.2,1.74,10.55c0.05,0.08-0.19,0.28-0.23,0.44c-0.49,1.63,0.56,4.88,2.08,6.06
		c-0.33-2.25-2.35-4.38-0.06-6.81c0.58,1.31,1.02,2.44,1.56,3.52c1,2,2.05,3.98,3.12,5.94c0.09,0.16,0.5,0.17,0.76,0.25
		c-0.51-1.33-0.99-2.67-1.53-3.98c-1.53-3.7-2.45-7.53-2.66-11.45c0.54,0.45,1.05,0.96,1.52,1.5c0.84,6.36,2.68,12.59,5.55,18.36
		c-0.11,0.04-0.22,0.07-0.33,0.11c-0.85-1.51-1.7-3.01-2.55-4.52c-0.24,0.67-0.48,1.33-0.72,2c1.74,0.42,1.56,1.94,1.87,3.17
		c1.59-0.68,1.55,0.54,1.48,1.17c-0.26,2.44,1.35,3.56,2.71,4.25c0.05,0.09,0.1,0.17,0.15,0.25c0.02,0.11,0.05,0.21,0.07,0.32
		c1.61,8.53,3.9,11.47,11.86,12.96c0.6,0.56,1.2,1.13,1.81,1.69c0.12-0.08,0.25-0.16,0.37-0.24c-0.05-0.36-0.09-0.73-0.14-1.11
		c0.07,0.01,0.14,0.02,0.21,0.03c2.08,1.59,4.25,3,6.5,4.23c0.03,0.06,0.04,0.12,0.02,0.15c-0.96,1.39,0.38,1.79,1.15,2.27
		c1.56,0.99,3.27,1.77,4.82,2.78c2.99,1.95,6.2,3.26,9.72,3.94c2.18,0.42,4.33,1.08,6.53,1.39c2.4,0.35,4.84,0.42,7.26,0.62
		c0-0.17,0.01-0.34,0.01-0.51c-1.31-0.15-2.66-0.15-3.93-0.47c-2.5-0.63-5.28-0.36-7.45-2.1c-2.23-1.79-4.78-2.75-7.58-3.3
		c-1.24-0.24-2.41-0.88-3.61-1.33c-0.55-0.21-1.11-0.39-1.82-0.65c0.02-0.12,0.02-0.23,0.02-0.33c2.8,1.16,5.68,2.08,8.62,2.76
		c3.96,1.14,8.09,1.82,12.46,1.99c12.13,0.45,23.56-2.08,34.29-6.48c0,0.05,0,0.11,0,0.16c-0.3,0.2-0.61,0.4-0.91,0.61
		c0.64,0.06,1.04-0.2,1.47-0.42c0.97-0.5,1.96-1.39,2.94-1.36c1.6,0.05,2.57-0.81,3.75-1.42c3-1.55,5.99-3.12,9.13-4.75
		c0.11,0.56,0.17,0.86,0.24,1.16c0.16,0.05,0.31,0.09,0.47,0.14c0.57-1.05,1.14-2.1,1.73-3.19c3-2.76,6.04-5.58,8.83-8.64
		C124.9,422.51,124.89,422.52,124.89,422.52z M53,388.06c0.83,0.8,1.89,1.46,2.45,2.41c2.58,4.44,6.98,7.2,9.89,11.27
		c1.62,2.26,4.01,3.85,5.06,6.67c0.47,1.28,2.12,2.2,3.34,3.16c2.54,1.99,5.15,3.9,7.58,6.01c-11.68-7.52-20.92-17.56-28.88-28.93
		C52.62,388.46,52.81,388.26,53,388.06z M50.77,384.36c0.16-0.03,0.32-0.07,0.49-0.1c-0.17,0.32-0.35,0.64-0.52,0.96
		c-0.17-0.08-0.33-0.1-0.36-0.16c-1.6-4.06-3.22-8.11-4.74-12.19c-0.13-0.34,0.39-0.9,0.64-1.42
		C48.67,375.7,48.99,380.28,50.77,384.36z M45.68,354.82c0.06,2.58,0.02,5.15,0.09,7.73c0.06,2.34,0.25,4.69,0.31,7.03
		c0.01,0.45-0.26,0.9-0.51,1.7c-0.64-1.6-1.1-3.75-1.4-6.19c0.31-3.62,0.86-7.08,1.5-10.29C45.68,354.8,45.68,354.81,45.68,354.82z
		 M10.3,369.39c0.3-0.6,0.51-1.08,0.77-1.53c-0.39,1.07-0.75,2.15-1.08,3.24C9.3,370.21,8.51,369.26,10.3,369.39z M6.05,383.42
		c0.14-2.75,0.58-5.48,1.69-8.15c-0.69-1.97,0.32-3.02,2.11-3.72c-1.07,3.64-1.8,7.39-2.18,11.18
		C7.18,382.99,6.64,383.22,6.05,383.42z"
            />
            <path
                fill={moonColor}
                d="M105.46,440.73c0.36,0.45,0.87,0.8,0.84,1.1c-0.04,0.39-0.47,0.75-0.79,1.08c-0.38,0.39-0.82,0.74-1.23,1.11
		c0.18,0.17,0.36,0.34,0.55,0.52c1.29-1.12,2.57-2.24,4.05-3.53c-0.83-0.22-1.31-0.35-1.79-0.48c-0.1-0.2-0.21-0.4-0.31-0.6
		c3.51-0.38,5.54-2.45,7.17-4.98c-2.54,1.4-5,2.9-7.39,4.49C106.04,439.77,105.73,440.4,105.46,440.73z"
            />
            <path
                fill={moonColor}
                d="M102.41,446.08c-0.5-0.05-1.14-0.32-1.47-0.12c-0.89,0.53-1.64,1.26-2.45,1.91c-2.64,0.83-5.28,1.67-7.95,2.51
		c0.68,2.04,1.71,0.91,2.53,0.48c0.98-0.51,1.85-1.21,2.83-1.73c0.84-0.45,1.77-0.77,2.66-1.14l0,0c1.29-0.47,2.59-0.93,3.88-1.4
		C102.43,446.42,102.42,446.25,102.41,446.08z"
            />
            <polygon
                fill={moonColor}
                points="10.58,410.79 10.57,410.78 10.57,410.79 10.58,410.8 10.58,410.79 	"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M163.58,260.63c0.17,0.06,0.61-0.42,0.91-0.65c-3.97-0.88-7.65-1.7-11.33-2.51
		C155.99,260.21,160.21,259.31,163.58,260.63z"
            />
            <path
                fill={moonColor}
                d="M164.97,179.7c0.23,2.62,1.05,5.01,2.15,7.3c5.21-5.05,1.97-10.19-2.41-15.6c-0.06,0.25-0.12,0.5-0.14,0.73
		c-0.05,0.52,0,1.04,0,1.73c-1.79,0.71-0.2,1.19,0.83,1.68C163.98,176.96,163.41,178.31,164.97,179.7z"
            />
            <path
                fill={moonColor}
                d="M183.13,207.3C183.13,207.3,183.13,207.3,183.13,207.3C183.13,207.3,183.13,207.3,183.13,207.3
		c1.41,0.87,2.8,1.74,4.2,2.61c0.11-0.1,0.22-0.21,0.33-0.31c-0.07-0.09-0.14-0.19-0.21-0.29c-1.23-0.92-2.57-1.92-4.02-3.1
		c-0.44-3.96-5.38-8.53-10.76-9.68c1.92,3.09,4.4,5.81,7.36,8.18C181.09,205.55,182.1,206.43,183.13,207.3
		C183.13,207.3,183.13,207.3,183.13,207.3L183.13,207.3z"
            />
            <path
                fill={moonColor}
                d="M140.53,252.41c-0.07,0.17-0.15,0.34-0.22,0.51c1.82,0.55,3.64,1.09,5.45,1.64l0.01-0.01
		c0,0-0.01,0.01-0.01,0.01c0.83,1.93,2.43,2.59,4.78,1.82c-1.67-0.67-3.2-1.28-4.72-1.89C144.14,253.61,142.75,252.09,140.53,252.41
		z"
            />
            <path
                fill={moonColor}
                d="M132.05,247.37c0.79,0.51,1.59,1.04,2.39,1.56c0.14-0.12,0.27-0.23,0.41-0.35c-0.41-0.77-0.83-1.54-1.24-2.31
		C132.92,246.75,132.5,247.05,132.05,247.37z"
            />
            <path
                fill={moonColor}
                d="M145.8,143.11c2.91,0.05,5.19-1.46,7.8-2.08c4.7-1.12,8.75-3.94,13.67-4.65c0.75-0.11,1.46-0.49,2.17-0.85
		c0.2-0.32,0.4-0.64,0.6-0.96c-0.02-0.05-0.05-0.09-0.07-0.14c-13.07,2.98-25.39,7.49-36.3,14.9c2.77-1.36,5.41-2.94,8.1-4.43
		C143.06,144.17,144.48,143.08,145.8,143.11z"
            />
            <path
                fill={moonColor}
                d="M135.34,249.3c-0.04,0.08-0.08,0.16-0.12,0.24c1.33,0.86,2.65,1.72,4.25,2.75c-0.5-1.01-0.83-1.66-1.09-2.19
		C137.3,249.81,136.32,249.55,135.34,249.3z"
            />
            <path
                fill={moonColor}
                d="M223.43,219.09c0.28,0.15,0.49,0.39,0.62,0.74c0.72-0.09,1.29-0.17,1.86-0.24c1.24-0.14,2.53-0.1,3.7-0.43
		c1.58-0.43,3.15-0.7,4.73-0.74c0-0.2,0.01-0.39,0.01-0.59C230.76,218.63,227.13,219.04,223.43,219.09z"
            />
            <path
                fill={moonColor}
                d="M196.25,253.62c-2.43,0.53-4.71,1.91-7.35,1.4c-0.61-0.12-1.22-0.19-1.82-0.23
		c13.59-3.76,25.2-10.49,28.81-17.07c2.21-1.98,4.31-4.07,6.3-6.26c0.02,0.04,0.05,0.09,0.07,0.13c-0.17,0.29-0.35,0.58-0.52,0.87
		c0.58-0.22,0.81-0.6,1.08-0.95c0.62-0.81,1.07-1.94,1.93-2.32c1.4-0.62,1.86-1.73,2.61-2.71c1.26-1.66,2.51-3.32,3.79-5.02
		c-1.75,0.16-3.51,0.15-5.29-0.02c-1.78-0.17-3.6-0.09-5.39-0.25c-0.88-0.08-2.21,0.16-1.99-1.37c0.02-0.15-0.64-0.36-1-0.56
		c0,0,0.04-0.11,0.08-0.11c0.51-0.04,1.03-0.09,1.54-0.13c-1.32-0.07-2.64-0.18-3.98-0.32c-1.35-0.15-2.86-0.16-4.12,0.25
		c-0.1,0.03-0.19,0.06-0.28,0.09c0.21,0.29,0.41,0.57,0.61,0.85c-0.07,0.12-0.14,0.23-0.22,0.35c-0.77-0.2-1.54-0.41-2.3-0.62
		c-7.53,2.1-10.81,0.67-15.96-5.57c-0.06-0.08-0.13-0.15-0.2-0.23c-0.08-0.05-0.16-0.09-0.24-0.14c-1.48,0-3.36-0.24-4.22-2.32
		c-0.22-0.54-0.72-1.54-1.79-0.33c-0.81-0.86-1.33-2.18-3.01-1.79c-0.09-0.64-0.18-1.28-0.27-1.92c0,0-0.01,0-0.01-0.01c0,0,0,0,0,0
		c-0.26,0.05-0.62,0.21-0.77,0.12c-1.79-1.15-3.57-2.32-5.32-3.52c-0.95-0.65-1.83-1.38-2.9-2.2c-0.9,2.92,1.78,3.81,3.06,5.49
		c-1.83-0.32-4.17-2.52-4.47-4.04c-0.03-0.15,0.08-0.41,0.01-0.46c-3.13-1.92-4.67-4.87-6.17-7.83c-0.86-1.7-1.58-3.47-2.42-5.18
		c-2.41-4.91-3.9-10.02-4.78-15.29c-0.04-2.54,0.08-5,0.28-7.35c0.04-0.04,0.07-0.08,0.07-0.13c0.45-2.49,0.91-4.98,1.29-7.48
		c0.02-0.14-0.18-0.31-0.37-0.45c0.05-0.33,0.1-0.66,0.15-0.98c0.23-0.47,0.47-0.97,0.78-1.6c0-0.08,0.01-0.17,0.02-0.25
		c-0.21,0.08-0.38,0.15-0.54,0.21c0.38-2.43,0.7-4.58,0.81-6.36c0.21-0.56,0.43-1.12,0.67-1.67c0.47-1.07,1.15-2.06,1.63-3.12
		c0.35-0.77,0.37-1.68,0.81-2.39c0.73-1.2,1.47-2.4,2.2-3.6c-3.6,0.66-7.15,1.61-10.67,2.59c-5.09,1.41-9.34,4.27-13.71,6.91
		c-0.89,0.54-1.76,1.14-2.56,1.79c-2.36,1.94-4.75,3.85-6.99,5.9c-1.07,0.98-2.06,1.97-3.28,2.86c-1.8,1.32-3.22,3.2-4.42,5.03
		c-3.21,4.89-6.23,9.88-9.27,14.86c-0.44,0.72-0.46,1.62-0.81,2.39c-0.48,1.06-1.16,2.05-1.63,3.12c-0.57,1.31-1.07,2.66-1.5,4.01
		c-0.45,1.41-0.79,2.84-1.24,4.52c0.75-0.3,1.07-0.42,1.79-0.71c-0.01,0.08-0.01,0.16-0.02,0.25c-0.48,0.99-0.81,1.67-1.16,2.4
		c0.14,0.14,0.63,0.42,0.6,0.64c-0.38,2.5-0.84,4.99-1.29,7.48c-0.02,0.09-0.12,0.17-0.18,0.26c-0.6,0.8-0.91,1.52-0.79,2.63
		c0.73,6.9,2.31,13.56,5.41,19.88c0.84,1.71,1.55,3.48,2.42,5.18c1.5,2.96,3.04,5.91,6.17,7.83c0.08,0.05-0.03,0.31-0.01,0.46
		c0.3,1.52,2.64,3.72,4.47,4.04c-1.28-1.68-3.96-2.57-3.06-5.49c1.08,0.82,1.96,1.55,2.9,2.2c0.2,0.14,0.4,0.27,0.6,0.4
		c3.25,3.6,7.01,6.85,11.28,9.63c2.62,2.1,5.62,3.71,8.88,4.89c1.85,1.24,3.74,1.77,6.07,1.67c5.38,1.07,11.21,1.23,17.07,0.7
		c1.64,0.11,3.3,0.06,4.94,0.22c3.44,0.34,6.79,0.07,10.12-0.85c2.07-0.57,4.21-0.93,6.24-1.58c2.22-0.72,4.36-1.67,6.53-2.52
		c-0.07-0.14-0.14-0.28-0.22-0.42C198.65,252.8,197.49,253.35,196.25,253.62z M213.2,223.66c-0.3,0.22-0.75,0.71-0.91,0.65
		c-3.37-1.31-7.59-0.42-10.42-3.16C205.55,221.96,209.23,222.78,213.2,223.66z M189.23,216.09c2.22-0.32,3.61,1.2,5.29,2.08
		c1.53,0.61,3.06,1.22,4.72,1.89c-2.35,0.77-3.95,0.11-4.78-1.82c0,0,0,0,0.01-0.01l-0.01,0.01c-1.82-0.55-3.64-1.09-5.45-1.64
		C189.08,216.43,189.15,216.26,189.23,216.09z M184.05,212.97c0.98,0.26,1.96,0.51,3.05,0.8c0.26,0.53,0.59,1.19,1.09,2.19
		c-1.6-1.03-2.93-1.89-4.25-2.75C183.97,213.13,184.01,213.05,184.05,212.97z M182.31,209.95c0.41,0.77,0.83,1.54,1.24,2.31
		c-0.14,0.12-0.27,0.23-0.41,0.35c-0.8-0.52-1.6-1.04-2.39-1.56C181.2,210.73,181.63,210.43,182.31,209.95z"
            />
            <path
                fill={moonColor}
                d="M207.82,249.45c1.22-1.22,2.93-2.05,4.36-3.13c0.35-0.27,0.41-0.84,0.7-1.5c-3.13,1.93-5.84,3.59-8.53,5.25
		C205.46,249.82,206.67,250.59,207.82,249.45z"
            />
            <path
                fill={moonColor}
                d="M211.33,243.86c0.9-0.28,1.52-0.47,2.14-0.65c0.04,0.09,0.07,0.19,0.11,0.28c-0.25,0.28-0.5,0.56-0.75,0.84
		c0.15,0.15,0.3,0.3,0.44,0.45c2.22-1.56,4.44-3.11,6.78-4.76C215.99,239.31,213.71,241.38,211.33,243.86z"
            />
            <path
                fill={moonColor}
                d="M204.25,250.12C204.26,250.12,204.26,250.12,204.25,250.12L204.25,250.12c0.04-0.02,0.06-0.04,0.09-0.05
		c-0.04,0.01-0.09,0.02-0.13,0.03C204.23,250.1,204.24,250.11,204.25,250.12z"
            />
            <path
                fill={moonColor}
                d="M234.13,221c-0.17,0.04-0.35,0.07-0.52,0.11c-1.33,1.91-2.58,3.85-3.76,5.82c-0.29,0.49-0.28,1.13-0.37,1.51
		c0.51,0.21,1.1,0.29,1.21,0.54c0.14,0.33-0.07,0.8-0.2,1.2c-0.15,0.48-0.38,0.94-0.57,1.41c0.23,0.06,0.47,0.13,0.7,0.19
		c0.61-1.44,1.23-2.89,1.93-4.55c-0.82,0.17-1.29,0.26-1.76,0.36c-0.18-0.12-0.36-0.24-0.53-0.35
		C232.84,225.64,233.77,223.43,234.13,221z"
            />
            <path
                fill={moonColor}
                d="M229.21,234.05c-0.45,0.16-1.12,0.21-1.32,0.51c-0.53,0.8-0.86,1.7-1.26,2.57c-1.91,1.77-3.82,3.55-5.75,5.34
		c1.49,1.37,1.88,0.02,2.4-0.66c0.62-0.82,1.06-1.75,1.67-2.58c0.53-0.72,1.18-1.36,1.78-2.03l0,0c0.91-0.92,1.82-1.83,2.73-2.75
		C229.39,234.31,229.3,234.18,229.21,234.05z"
            />
            <path
                fill={moonColor}
                d="M201.07,251.79c1.37-0.28,2.74-0.56,4.41-0.9c-0.57-0.36-0.9-0.57-1.23-0.77c-1.1,0.49-2.2,0.99-3.31,1.48
		C200.99,251.66,201.03,251.72,201.07,251.79z"
            />
        </g>
        <g>
            <polygon
                fill={moonColor}
                points="338.38,15.66 338.38,15.65 338.38,15.65 338.37,15.65 338.38,15.66 	"
            />
            <path
                fill={moonColor}
                d="M326.87,16.22c-0.12-0.15-0.24-0.29-0.37-0.44c-1.46,1.21-2.92,2.42-4.37,3.64l0,0.01c0-0.01,0-0.01,0-0.01
		c-2.26-0.29-3.72,0.68-4.2,2.99c1.49-1.03,2.86-1.97,4.23-2.91C323.87,18.57,326.03,18.2,326.87,16.22z"
            />
            <path
                fill={moonColor}
                d="M315.54,24.02c-4.06,0.93-5.39,4.83-8.38,6.93c-0.15,0.1,0.09,0.71,0.14,1.07
		C310.18,29.22,312.86,26.62,315.54,24.02z"
            />
            <path
                fill={moonColor}
                d="M296.14,69.49c0,0,0,0.01,0,0.01c0,0.03,0,0.06,0.01,0.09c0.01-0.04,0.03-0.08,0.04-0.12
		C296.17,69.48,296.16,69.49,296.14,69.49z"
            />
            <path
                fill={moonColor}
                d="M296.2,66.05c-0.44,1.26-0.89,2.52-1.42,4.05c0.63-0.28,1-0.45,1.37-0.62c0.1-1.15,0.2-2.3,0.3-3.45
		C296.36,66.05,296.28,66.05,296.2,66.05z"
            />
            <path
                fill={moonColor}
                d="M336.28,13.65c-0.11-0.8-0.17-1.3-0.24-1.83c-0.9,0.39-1.81,0.78-2.71,1.17c0.04,0.17,0.08,0.34,0.12,0.51
		C334.39,13.55,335.34,13.6,336.28,13.65z"
            />
            <path
                fill={moonColor}
                d="M412.69,115.22c-1.72,1.35-3.39,2.76-5.11,4.09c-1.97,1.52-4,2.96-5.98,4.48c-0.43,0.33-0.72,0.8-1.09,1.2
		c-0.25,0.27-0.47,0.62-0.78,0.77c-2.97,1.39-5.96,2.74-8.91,4.14c3.23-2.68,6.45-5.37,9.66-8.07c0.47-0.4,0.99-1.24,0.84-1.68
		c0-0.01-0.01-0.02-0.01-0.04c0.16-0.1,0.32-0.2,0.48-0.3c1.65-1.02,3.38-2.1,4.65-3.49c0.93-1.03,1.93-2,2.93-2.98
		c-0.83-0.47-1.67-0.92-2.5-1.39c-0.06,0-0.11-0.01-0.17-0.01c-0.49-0.02-1,0.09-1.5,0.39c-4.13-0.96-8.26-2.06-12.14-3.7
		c-0.5-0.25-1.04-0.49-1.61-0.72c-1.76-0.83-3.46-1.78-5.07-2.9c-5.57-3.87-10.12-8.78-14.48-13.59c-1.06-0.59-2.08-1.16-3.1-1.73
		c0.05-0.14,0.11-0.28,0.16-0.42c0.3-0.04,0.61-0.08,1.17-0.16c-1.48-2.95-2.88-5.76-4.29-8.57c-0.55-1.11-1.36-2.04-1.23-3.5
		c0.08-0.89-0.76-1.83-1.21-2.75c-0.2-0.4-0.44-0.78-0.35-1.37c0.19,0.29,0.37,0.57,0.56,0.86c0.05,0,0.11,0,0.16,0.01
		c-3.86-10.01-5.81-20.58-4.76-31.64c0.67-7.06,2.93-13.36,6.48-19.22c-0.29,0.15-0.62,0.21-1.03,0.13
		c-0.29,0.64-0.5,1.14-0.74,1.64c-0.51,1.08-1.2,2.12-1.51,3.24c-0.68,2.54-1.77,4.83-3.67,6.78c-1.85,1.9-1.72,4.45-2.47,6.71
		c-0.38,1.15-0.45,2.38-0.67,3.57c-0.17-0.01-0.34-0.02-0.51-0.03c0.32-2.21,0.51-4.43,0.98-6.61c0.43-1.99,1.19-3.93,1.72-5.9
		c0.85-3.18,2.32-6.07,4.42-8.71c1.09-1.37,1.95-2.9,3.02-4.28c0.53-0.68,0.99-1.88,2.33-0.94c0.13,0.09,0.68-0.34,1.04-0.54
		c0,0,0.09,0.08,0.07,0.13c-0.22,0.44-0.45,0.88-0.67,1.32c0.48-0.68,0.99-1.35,1.51-2.01c-1.51-0.97-2.68-2.45-3.37-4.67
		c1.96,0.18,3.9,0.51,5.79,1.09c0.14-0.33,0.25-0.66,0.31-0.99c0.02-0.09,0.04-0.18,0.06-0.28c-0.39,0.03-0.75,0.05-1.12,0.08
		c-0.07-0.12-0.15-0.24-0.22-0.35c0.59-0.53,1.18-1.05,1.78-1.57c0.05-0.21,0.11-0.39,0.17-0.59c-1.13-0.24-2.28-0.46-3.43-0.64
		c-0.93,0.91-1.87,1.82-2.84,2.76c-0.06-0.36-0.29-0.97-0.14-1.07c0.75-0.52,1.39-1.16,2-1.84c-1.11-0.16-2.23-0.29-3.36-0.38
		c-2.06-0.17-4.11-0.48-6.17-0.63c-3.59-0.26-7.18-0.49-10.62,1.11c-0.09,0.04-0.27-0.18-0.43-0.23c-1.6-0.52-4.9,0.29-6.15,1.62
		c2.26-0.2,4.49-1.94,6.8,0.26c-1.34,0.47-2.48,0.82-3.59,1.27c-2.05,0.82-4.07,1.69-6.09,2.58c-0.17,0.07-0.2,0.45-0.29,0.69
		c1.35-0.4,2.71-0.78,4.06-1.22c3.78-1.23,7.64-1.9,11.57-1.91c-3.87,3.82-10.77,5.56-14.76,3.93c-1.87,0.59-3.51,1.19-5.02,1.73
		c-0.13,0.01-0.25,0.02-0.38,0.02c-0.04-0.14-0.08-0.28-0.13-0.43c1.55-0.71,3.09-1.41,4.64-2.12c-0.65-0.25-1.31-0.5-1.96-0.75
		c-0.5,1.58-2.02,1.34-3.26,1.56c0.6,1.49-0.61,1.39-1.24,1.3c-2.42-0.35-3.62,1.07-4.38,2.28c-0.09,0.04-0.17,0.08-0.26,0.13
		c-0.11,0.02-0.21,0.03-0.32,0.05c-8.6,1.08-11.65,3.04-13.54,10.25c-0.59,0.52-1.19,1.05-1.78,1.57c0.07,0.12,0.15,0.24,0.22,0.35
		c0.37-0.02,0.73-0.05,1.12-0.08c-0.02,0.09-0.04,0.18-0.06,0.28c-0.26,1.24-1.05,2.47-1.89,3.5c-0.83,1.02-1.61,2.05-2.36,3.09
		c0.23-0.44,0.45-0.88,0.67-1.32c0.02-0.04-0.07-0.13-0.07-0.13c-0.37,0.2-0.91,0.63-1.04,0.54c-1.34-0.94-1.8,0.26-2.33,0.94
		c-1.07,1.38-1.93,2.91-3.02,4.28c-2.1,2.64-3.57,5.52-4.42,8.71c-0.53,1.98-1.29,3.91-1.72,5.9c-0.47,2.18-0.67,4.41-0.98,6.61
		c0.17,0.01,0.34,0.02,0.51,0.03c0.21-1.19,0.28-2.42,0.67-3.57c0.75-2.26,0.62-4.81,2.47-6.71c1.9-1.95,2.99-4.24,3.67-6.78
		c0.3-1.12,1-2.16,1.51-3.24c0.23-0.5,0.45-1,0.74-1.64c0.4,0.08,0.74,0.02,1.03-0.13c-3.04,5.01-5.13,10.35-6.09,16.2
		c-1.79,3.8-3.14,7.75-4.05,11.78c-0.02-0.36-0.04-0.71-0.06-1.07c-0.35,1.04-1.7,1.64-1.21,3.15c0.25,0.77,0.36,1.58,0.44,2.4
		c-0.58,3.96-0.74,7.96-0.51,11.93c-0.01-0.04-0.02-0.08-0.03-0.12c-2.07,0.53-0.99,1.52-0.61,2.29c0.28,0.56,0.63,1.09,0.98,1.63
		c2.91,23.91,20.24,45.93,49.64,50.27c7.18,1.16,16.96-0.65,26.21-4.02c6.44,3.29,7.76,2.16,11.09-0.38
		c0.56-0.43,1.27-0.7,1.81-1.15c1.18-0.97,2.36-1.95,3.54-2.93c0.43-0.11,0.86-0.23,1.29-0.34c0-0.17,0.01-0.34,0.01-0.51
		c-0.06-0.02-0.12-0.05-0.18-0.08c0.27-0.22,0.53-0.44,0.8-0.66c-0.05,0.05-0.1,0.11-0.15,0.16c0.14,0.11,0.27,0.23,0.41,0.34
		c6.04-1.03,19.13-9.71,23.94-15.84C413.31,115.57,413,115.4,412.69,115.22z M355.33,46.31c0.08,0,0.16-0.01,0.24-0.01
		c-0.1,1.15-0.2,2.3-0.3,3.45c0.01-0.01,0.03-0.01,0.04-0.02c-0.01,0.04-0.03,0.08-0.04,0.12c0.18,3.04,0.37,6.08,0.58,9.62
		c-0.47-0.57-0.99-0.91-1.07-1.33c-0.29-1.71-0.19-3.52-0.72-5.14c-0.5-1.51,0.86-2.11,1.21-3.15c0-0.03,0-0.06-0.01-0.09
		c0,0,0-0.01,0-0.01c-0.36,0.16-0.74,0.33-1.37,0.62C354.44,48.84,354.89,47.57,355.33,46.31z M357.66,78.25
		c-0.17,0-0.34,0.01-0.51,0.01c-0.4-1.21-0.8-2.41-1.2-3.62h0c-0.33-0.83-0.6-1.69-1.01-2.48c-0.47-0.92-1.13-1.74-1.59-2.67
		c-0.38-0.77-1.46-1.77,0.61-2.29c0.71,2.48,1.41,4.93,2.11,7.39c0.61,0.77,1.3,1.49,1.79,2.32
		C358.04,77.23,357.74,77.8,357.66,78.25z M356.72,67.76c-0.35-2.75-0.69-5.34-1.02-7.94c0.22-0.05,0.44-0.09,0.66-0.14
		c0.14,0.34,0.28,0.69,0.41,1.03c0.11,0.02,0.21,0.03,0.32,0.05c0.14-0.6,0.28-1.2,0.48-2.08C358.7,61.88,359.48,64.79,356.72,67.76
		z M363.59,82.53c-0.2,0.09-0.41,0.17-0.61,0.26c-0.15,0.43-0.3,0.86-0.57,1.62c-1.21-1.41-2.27-2.64-3.32-3.87
		c0.18-0.16,0.36-0.32,0.54-0.48c0.35,0.39,0.67,0.81,1.04,1.18c0.31,0.3,0.65,0.72,1.04,0.77c0.3,0.04,0.68-0.41,1.14-0.72
		c0.32,0.26,0.93,0.57,1.25,1.06c1.46,2.26,2.85,4.57,4.11,6.96C365.76,87.7,363.8,85.75,363.59,82.53z"
            />
            <path
                fill={moonColor}
                d="M332.51,13.55c-0.06-0.07-0.11-0.14-0.17-0.22c-1.5,0.65-3,1.31-4.81,2.1c1.21,0.1,2.01,0.16,2.65,0.21
		C331.01,14.89,331.76,14.22,332.51,13.55z"
            />
        </g>
        <g>
            <path
                fill={moonColor}
                d="M531.55,117.08c-0.04,0.08-0.07,0.16-0.11,0.25c1.27,0.87,2.53,1.74,4.05,2.79c-0.48-1.02-0.79-1.69-1.04-2.22
		C533.42,117.6,532.49,117.34,531.55,117.08z"
            />
            <path
                fill={moonColor}
                d="M536.49,120.24c-0.07,0.17-0.14,0.34-0.21,0.51c1.73,0.55,3.47,1.11,5.2,1.66l0,0
		c0.79,1.96,2.32,2.63,4.56,1.85c-1.59-0.68-3.05-1.3-4.5-1.91C539.94,121.45,538.61,119.92,536.49,120.24z"
            />
            <path
                fill={moonColor}
                d="M558.47,128.57c0.16,0.07,0.58-0.43,0.87-0.66c-3.78-0.89-7.29-1.72-10.8-2.55
		C551.24,128.15,555.26,127.24,558.47,128.57z"
            />
            <path
                fill={moonColor}
                d="M600.64,117.23c1.17-1.24,2.8-2.08,4.15-3.17c0.33-0.27,0.39-0.86,0.67-1.52c-2.99,1.95-5.56,3.64-8.13,5.32
		C598.39,117.61,599.55,118.39,600.64,117.23z"
            />
            <path
                fill={moonColor}
                d="M594.21,119.6c1.31-0.28,2.61-0.57,4.2-0.91c-0.54-0.36-0.86-0.57-1.17-0.78c-1.05,0.5-2.1,1-3.15,1.5
		C594.13,119.48,594.17,119.54,594.21,119.6z"
            />
            <path
                fill={moonColor}
                d="M541.52,9.38c2.78,0.05,4.95-1.48,7.44-2.11c4.48-1.13,8.34-3.99,13.03-4.71c1.01-0.16,1.93-0.85,2.9-1.29
		c-0.11-0.23-0.21-0.46-0.32-0.69c-12.46,3.02-24.21,7.59-34.6,15.11c2.64-1.38,5.15-2.98,7.72-4.5
		C538.91,10.46,540.26,9.36,541.52,9.38z"
            />
            <path
                fill={moonColor}
                d="M589.26,3.63c0.75,0.27,1.5,0.55,2.25,0.84c0.01-0.09,0.03-0.17,0.04-0.26c0.03-0.22-0.44-0.51-0.57-0.65
		c0.15-0.33,0.3-0.66,0.46-1c-3.66-1.25-7.14-2.05-9.62-2.03c0.6,0.48,0.9,0.87,1.29,1.01C585.15,2.27,587.23,2.9,589.26,3.63z"
            />
            <path
                fill={moonColor}
                d="M528.41,115.13c0.75,0.52,1.52,1.05,2.28,1.58c0.13-0.12,0.26-0.24,0.39-0.35c-0.39-0.78-0.79-1.56-1.18-2.34
		C529.25,114.5,528.84,114.8,528.41,115.13z"
            />
            <path
                fill={moonColor}
                d="M597.24,117.91C597.24,117.91,597.25,117.91,597.24,117.91L597.24,117.91c0.03-0.02,0.06-0.04,0.09-0.05
		c-0.04,0.01-0.08,0.02-0.12,0.03C597.21,117.9,597.23,117.91,597.24,117.91z"
            />
            <path
                fill={moonColor}
                d="M568.81,0.59c4.11-0.15,8.16,1.11,12.62,0.48c-0.36-0.39-0.67-1.02-1.01-1.03c-4.03-0.06-8.05-0.04-12.08-0.02
		c-0.07,0-0.14,0.13-0.26,0.25c0.21,0.25,0.43,0.51,0.65,0.77C568.76,0.89,568.78,0.74,568.81,0.59z"
            />
            <path
                fill={moonColor}
                d="M619.78,102.13c-0.5,0.81-0.82,1.73-1.2,2.6c-1.82,1.8-3.64,3.6-5.48,5.42c1.42,1.39,1.79,0.02,2.28-0.67
		c0.59-0.83,1.01-1.78,1.59-2.62c0.5-0.73,1.13-1.38,1.7-2.06v0c0.87-0.93,1.74-1.86,2.6-2.79c-0.08-0.13-0.16-0.27-0.25-0.4
		C620.6,101.78,619.97,101.83,619.78,102.13z"
            />
            <path
                fill={moonColor}
                d="M603.99,111.57c0.86-0.28,1.45-0.47,2.04-0.66c0.03,0.09,0.07,0.19,0.1,0.28c-0.24,0.28-0.47,0.57-0.71,0.85
		c0.14,0.15,0.28,0.3,0.42,0.46c2.11-1.58,4.23-3.16,6.46-4.83C608.43,106.95,606.25,109.05,603.99,111.57z"
            />
            <polygon
                fill={moonColor}
                points="530.68,111.32 530.67,111.32 530.67,111.32 530.68,111.33 530.68,111.32 	"
            />
            <path
                fill={moonColor}
                d="M631.01,66.11c-2.94-0.26-5.2-1.85-7.89-5.03c-0.28-0.7-0.59-1.38-0.97-2.03c-1.98-1.14-3.9-2.41-5.74-3.8
		c0.08,0.05,0.16,0.1,0.23,0.15c0.11-0.11,0.21-0.21,0.32-0.32c-0.06-0.1-0.13-0.19-0.2-0.29c-1.17-0.93-2.45-1.95-3.83-3.14
		c-0.4-3.84-4.72-8.23-9.57-9.63c-2.28-3.1-4.28-6.41-5.97-9.85c4.95-5.12,1.87-10.33-2.3-15.81c-0.06,0.25-0.11,0.51-0.13,0.74
		c-0.04,0.53,0,1.05,0,1.75c-1.71,0.73-0.19,1.2,0.79,1.7c-1.37,1.43-1.91,2.8-0.42,4.21c0.21,2.59,0.96,4.96,1.97,7.23
		c-3.09-6.35-5.1-13.18-5.71-20.08c-0.41-0.14-0.81-0.26-1.22-0.4c0.23-1.38,0.47-2.77,0.69-4.16c-1.25-0.58-2.54-1.09-3.87-1.32
		c-4.27-0.73-8.38-2.32-12.92-1.74c-0.9,0.12-1.81,0.19-2.72,0.24c-0.99-0.06-1.97-0.01-2.94,0.13c-1.75,0.07-3.49,0.17-5.2,0.45
		c-2.84,0.47-5.66,1.18-8.45,1.96C534.1,9.8,521.62,23.53,516.4,40.65c-1.01,1.76-2.02,3.51-3.03,5.27
		c-0.42,0.73-0.44,1.65-0.77,2.43c-0.46,1.08-1.11,2.08-1.55,3.16c-0.55,1.33-1.02,2.69-1.43,4.07c-0.43,1.43-0.75,2.88-1.18,4.58
		c0.71-0.3,1.02-0.43,1.71-0.72c-0.01,0.08-0.01,0.17-0.02,0.25c-0.45,1-0.77,1.69-1.11,2.43c0.13,0.14,0.6,0.42,0.57,0.65
		c-0.36,2.53-0.8,5.06-1.23,7.59c-0.02,0.09-0.11,0.17-0.18,0.26c-0.57,0.81-0.87,1.54-0.76,2.67c0.69,6.99,2.2,13.75,5.16,20.16
		c0.8,1.74,1.48,3.53,2.3,5.25c1.43,3,2.89,5.99,5.88,7.94c0.07,0.05-0.03,0.31-0.01,0.46c0.28,1.54,2.51,3.77,4.26,4.1
		c-1.22-1.71-3.77-2.61-2.92-5.57c1.03,0.83,1.87,1.57,2.77,2.23c1.66,1.22,3.36,2.4,5.07,3.57c0.14,0.1,0.48-0.07,0.73-0.12
		c-0.98-0.88-1.94-1.78-2.94-2.63c-2.82-2.4-5.19-5.15-7.02-8.29c2.33,0.53,4.57,1.78,6.36,3.36c0.95,1.28,1.94,2.51,2.98,3.68
		c0.49,0.93,0.81,1.87,0.91,2.78c1.38,1.19,2.66,2.21,3.83,3.14c0.07,0.1,0.13,0.2,0.2,0.29c-0.11,0.11-0.21,0.21-0.32,0.32
		c-1.33-0.88-2.66-1.76-4-2.64c0.08,0.65,0.17,1.3,0.25,1.94c1.61-0.39,2.1,0.94,2.87,1.81c1.02-1.23,1.5-0.21,1.71,0.33
		c0.82,2.11,2.61,2.35,4.02,2.35c0.08,0.05,0.15,0.1,0.23,0.14c0.06,0.08,0.13,0.15,0.19,0.23c4.92,6.34,8.04,7.78,15.22,5.65
		c0.73,0.21,1.46,0.42,2.2,0.63c0.07-0.12,0.14-0.24,0.21-0.35c-0.19-0.28-0.38-0.56-0.58-0.86c0.09-0.03,0.18-0.06,0.27-0.09
		c0.52-0.18,1.1-0.28,1.68-0.32c1.28,0.13,2.59,0.21,3.92,0.24c0.71,0.06,1.41,0.12,2.12,0.16c-0.49,0.04-0.98,0.09-1.47,0.13
		c-0.05,0-0.08,0.12-0.08,0.12c0.34,0.19,0.97,0.41,0.95,0.56c-0.2,1.54,1.06,1.31,1.9,1.39c1.7,0.16,3.44,0.07,5.13,0.25
		c3.28,0.34,6.47,0.07,9.65-0.86c1.97-0.58,4.01-0.94,5.95-1.61c2.12-0.73,4.16-1.69,6.23-2.55c-0.07-0.14-0.14-0.28-0.21-0.42
		c-1.14,0.43-2.25,1-3.43,1.27c-2.32,0.54-4.49,1.93-7,1.42c-2.59-0.53-5.09-0.25-7.62,0.49c-1.12,0.33-2.35,0.29-3.53,0.43
		c-0.54,0.06-1.08,0.14-1.77,0.24c-0.12-0.36-0.32-0.6-0.59-0.75c6.76-0.11,13.31-1.49,19.79-4.52
		c10.16-4.75,18.49-11.64,25.45-19.78c0.02,0.04,0.05,0.09,0.07,0.13c-0.16,0.29-0.33,0.59-0.49,0.88c0.55-0.22,0.77-0.61,1.03-0.97
		c0.59-0.82,1.02-1.97,1.84-2.36c1.33-0.63,1.77-1.75,2.49-2.75c1.81-2.54,3.61-5.09,5.51-7.76c0.33,0.41,0.5,0.63,0.68,0.85
		c0.15-0.03,0.29-0.05,0.44-0.08c0.03-1.1,0.05-2.2,0.08-3.35c1.96-5.3,3.91-10.76,4.88-16.46c-0.12-0.11-0.24-0.21-0.35-0.33
		c0.13,0.03,0.26,0.06,0.39,0.09C630.93,66.63,630.97,66.37,631.01,66.11z M515.42,90.74c-1.05-2.32-1.83-4.75-2.05-7.41
		c-1.49-1.42-0.95-2.79,0.42-4.21c-0.98-0.5-2.5-0.98-0.79-1.7c0-0.7-0.05-1.23,0-1.75c0.02-0.23,0.07-0.49,0.13-0.74
		c0.91,1.2,1.76,2.38,2.5,3.55c0.62,2.62,1.37,5.2,2.26,7.73C517.64,87.73,516.88,89.24,515.42,90.74z M624.21,65.12
		c-0.06-0.38-0.13-0.75-0.21-1.12c1.29,0.55,2.59,1.1,3.99,1.7C626.34,66.27,625.08,66.06,624.21,65.12z"
            />
            <path
                fill={moonColor}
                d="M625.82,87.59c-1.5,2.22-2.88,4.49-4.18,6.8c-0.28,0.5-0.27,1.15-0.35,1.53c0.48,0.21,1.05,0.29,1.15,0.55
		c0.13,0.33-0.07,0.82-0.19,1.22c-0.15,0.49-0.36,0.95-0.55,1.43c0.22,0.06,0.44,0.13,0.67,0.19c0.58-1.46,1.17-2.93,1.84-4.61
		c-0.78,0.17-1.23,0.27-1.67,0.36c-0.17-0.12-0.34-0.24-0.51-0.36C624.75,92.92,625.55,90.36,625.82,87.59z"
            />
        </g>
        <path
            fill={backgroundColor}
            d="M195.96,433.51c0,0.02,0.08,0.04,0.29,0.14c0.04-0.43,0.08-0.8,0.11-1.17c0.05,0,0.1,0,0.15,0
	c0.29,0.83,0.58,1.65,0.87,2.48c0.08-0.01,0.16-0.02,0.24-0.02c-0.22-1.88-1.68-3.66-0.8-5.62c-0.12,0.01-0.25,0.01-0.37,0.02
	c0,0.29,0,0.58,0,0.87c-0.1,0.01-0.2,0.01-0.3,0.02c-0.08-0.61-0.22-1.22-0.21-1.83c0.01-0.35,0.28-0.7,0.41-1.06
	c0.18-0.49,0.36-0.98,0.49-1.47c0.03-0.1-0.16-0.22-0.24-0.33c-0.08-0.11-0.23-0.24-0.21-0.34c0.22-0.91,0.45-1.82,0.74-2.72
	c0.04-0.11,0.43-0.17,0.66-0.25c0.06,0.04,0.12,0.08,0.18,0.12c0.26-0.53,0.52-1.07,0.78-1.6c-0.07-0.02-0.13-0.05-0.2-0.07
	c-0.26,0.21-0.51,0.42-0.83,0.69c-0.38-0.7,0.61-1.01,0.73-1.54c-0.29,0.11-0.54,0.25-0.7,0.42c-0.52,0.58-0.98,1.18-1.52,1.75
	c-0.16,0.17-0.49,0.27-0.74,0.41c-0.08-0.03-0.17-0.07-0.25-0.1c-0.11,0.51-0.32,1.02-0.3,1.53c0.06,1.63,0.28,3.25,0.3,4.88
	C195.3,430.33,196.41,431.87,195.96,433.51z"
        />
        <path
            fill={backgroundColor}
            d="M196.49,435.63c-0.11,0.01-0.22,0.02-0.34,0.03c0.13,0.77,0.27,1.54,0.4,2.3c0.1,0,0.2,0,0.3,0
	c0.05-0.2,0.11-0.39,0.18-0.65c0.22,0.16,0.37,0.26,0.52,0.37c0.07-0.02,0.14-0.03,0.22-0.05c-0.06-0.14-0.07-0.37-0.18-0.39
	c-0.94-0.17-1.02-0.64-0.99-1.17C196.61,435.92,196.53,435.78,196.49,435.63z"
        />
        <path
            fill={textColor}
            d="M377.12,504.34c-0.71-0.52-1.41-1.04-2.12-1.56c-0.06,0.05-0.13,0.1-0.19,0.16c1.24,1.43,3.45,2.08,3.82,4.19
	c0.1-0.08,0.2-0.15,0.29-0.23c-0.16-0.24-0.33-0.48-0.49-0.72c0.08-0.06,0.16-0.12,0.24-0.18c0.41,0.46,0.87,0.88,1.2,1.39
	c0.19,0.3,0.16,0.74,0.25,1.11c0.13,0.51,0.25,1.01,0.42,1.49c0.03,0.1,0.25,0.09,0.38,0.14c0.13,0.05,0.33,0.07,0.36,0.17
	c0.33,0.88,0.65,1.76,0.92,2.66c0.03,0.11-0.26,0.38-0.4,0.58c-0.07,0-0.14,0-0.22,0c0.08,0.59,0.17,1.18,0.25,1.76
	c0.07-0.02,0.13-0.04,0.2-0.05c0.09-0.32,0.18-0.64,0.3-1.04c0.71,0.36,0.06,1.18,0.27,1.69c0.18-0.25,0.31-0.51,0.34-0.74
	c0.1-0.77,0.15-1.53,0.27-2.31c0.04-0.23,0.25-0.5,0.38-0.75c0.09-0.02,0.18-0.04,0.26-0.06c-0.2-0.48-0.31-1.03-0.61-1.43
	c-0.97-1.31-2.06-2.53-3-3.86c-0.93-1.32-2.71-1.97-3.27-3.57c-0.01-0.02-0.09,0.01-0.32,0.05c0.21,0.38,0.39,0.71,0.57,1.03
	C377.2,504.28,377.16,504.31,377.12,504.34z"
        />
        <path
            fill={textColor}
            d="M368.21,494.58c0.17,0.21,0.4,0.44,0.38,0.72c-0.06,1.01,0.85,1.17,1.53,1.58c0.22-0.45,0.38-0.81,0.47-1.12
	c0.08,0.14,0.17,0.28,0.25,0.43c0.11-0.07,0.22-0.15,0.32-0.22c-0.16-0.24-0.33-0.48-0.49-0.72c-0.01-0.44-0.31-0.65-0.95-0.7
	c-0.97-0.07-1.36-0.58-1.78-1.12c-1.08-1.39-2.16-2.78-3.24-4.17c-0.09,0.06-0.18,0.11-0.26,0.17c0.3,0.54,0.54,1.13,0.91,1.61
	C366.26,492.25,367.25,493.4,368.21,494.58z"
        />
        <path
            fill={textColor}
            d="M365.2,488.27c-0.82-0.8-1.67-1.62-2.51-2.44c-0.07,0.04-0.14,0.08-0.21,0.13c0.3,0.51,0.6,1.03,0.93,1.59
	c-0.29,0.07-0.47,0.11-0.66,0.16C363.1,488.34,364.04,488.53,365.2,488.27z"
        />
        <path
            fill={textColor}
            d="M325.75,462.68c-0.37-0.37-1.06-0.48-1.62-0.71c0.12,0.17,0.23,0.41,0.41,0.58c0.2,0.19,0.51,0.28,0.71,0.47
	c0.4,0.36,0.17,1.31-0.38,1.63c0.15,0.17,0.3,0.33,0.54,0.6c0.06-0.45,0.1-0.74,0.14-1.07c0.29,0.04,0.51,0.07,0.86,0.13
	C326.18,463.67,326.12,463.05,325.75,462.68z"
        />
        <path
            fill={textColor}
            d="M319.98,453.53c-0.1,0.09-0.2,0.19-0.3,0.28c0.46,0.67,0.91,1.33,1.38,2.02c0.02-0.15,0.05-0.31,0.09-0.54
	c0.14,0.09,0.2,0.13,0.24,0.15c-0.25-0.5-0.48-1.05-0.79-1.55C320.48,453.72,320.19,453.65,319.98,453.53z"
        />
        <path
            fill={textColor}
            d="M335.15,445.29c-0.19,0.13-0.39,0.27-0.66,0.45c-0.25-0.56-0.51-1.15-0.78-1.73c-0.05,0.02-0.09,0.05-0.14,0.07
	c0.51,1.32,1.02,2.64,1.54,3.97C335.44,447.03,334.1,446.63,335.15,445.29z"
        />
        <path
            fill={textColor}
            d="M332.48,442.07c0.33,0.49,0.67,0.98,0.99,1.45c0.2-0.14,0.49-0.33,0.75-0.51C334.58,441.79,333.01,442.48,332.48,442.07z"
        />
        <path
            fill={textColor}
            d="M373.75,499.99c0.07,0.19,0.13,0.38,0.22,0.64c-0.27-0.01-0.45-0.01-0.63-0.01c-0.05,0.06-0.1,0.11-0.15,0.17
	c0.12,0.08,0.27,0.27,0.37,0.22c0.87-0.39,1.2-0.05,1.47,0.41c0.07,0.12,0.22,0.2,0.34,0.3c0.09-0.07,0.17-0.14,0.26-0.22
	c-0.54-0.56-1.09-1.12-1.63-1.68C373.92,499.88,373.83,499.94,373.75,499.99z"
        />
        <path
            fill={textColor}
            d="M361.7,484.31c0.13-0.07,0.25-0.15,0.38-0.22c-0.31-0.57-0.61-1.13-0.92-1.69c-0.09,0.05-0.17,0.1-0.26,0.15
	C360.47,483.52,361.58,483.64,361.7,484.31z"
        />
        <path
            fill={textColor}
            d="M372.67,498.33c-0.62-0.41-1.25-0.82-1.84-1.21C371.5,498.29,371.89,498.55,372.67,498.33z"
        />
        <path
            fill={textColor}
            d="M218.06,510.37c0-0.02-0.08-0.04-0.29-0.14c-0.04,0.43-0.08,0.8-0.11,1.17c-0.05,0-0.1,0-0.15,0
	c-0.29-0.83-0.58-1.65-0.87-2.48c-0.08,0.01-0.16,0.02-0.24,0.02c0.22,1.88,1.68,3.66,0.8,5.62c0.12-0.01,0.25-0.01,0.37-0.02
	c0-0.29,0-0.58,0-0.87c0.1-0.01,0.2-0.01,0.3-0.02c0.08,0.61,0.22,1.22,0.21,1.83c-0.01,0.35-0.28,0.7-0.41,1.06
	c-0.18,0.49-0.36,0.98-0.49,1.47c-0.03,0.1,0.16,0.22,0.24,0.33c0.08,0.11,0.23,0.24,0.21,0.34c-0.22,0.91-0.45,1.82-0.74,2.72
	c-0.04,0.11-0.43,0.17-0.66,0.25c-0.06-0.04-0.12-0.08-0.18-0.12c-0.26,0.53-0.52,1.07-0.78,1.6c0.07,0.02,0.13,0.05,0.2,0.07
	c0.26-0.21,0.51-0.42,0.83-0.69c0.38,0.7-0.61,1.01-0.73,1.54c0.29-0.11,0.54-0.25,0.7-0.42c0.52-0.58,0.98-1.18,1.52-1.75
	c0.16-0.17,0.49-0.27,0.74-0.41c0.08,0.03,0.17,0.07,0.25,0.1c0.11-0.51,0.32-1.02,0.3-1.53c-0.06-1.63-0.28-3.25-0.3-4.88
	C218.72,513.55,217.61,512.01,218.06,510.37z"
        />
        <path
            fill={textColor}
            d="M216.43,506.64c0.94,0.17,1.02,0.64,0.99,1.17c-0.01,0.14,0.07,0.29,0.11,0.44c0.11-0.01,0.22-0.02,0.34-0.03
	c-0.13-0.77-0.27-1.54-0.4-2.3c-0.1,0-0.2,0-0.3,0c-0.05,0.2-0.11,0.39-0.18,0.65c-0.22-0.16-0.37-0.26-0.52-0.37
	c-0.07,0.02-0.14,0.03-0.22,0.05C216.31,506.38,216.33,506.62,216.43,506.64z"
        />
        <path
            fill={textColor}
            d="M217.21,503.93c-0.28-0.69-0.57-1.38-0.84-2.04C216.27,503.23,216.44,503.67,217.21,503.93z"
        />
        <path
            fill={textColor}
            d="M235.63,562.58c0,0.02,0.08,0.04,0.29,0.14c0.04-0.43,0.08-0.8,0.11-1.17c0.05,0,0.1,0,0.15,0
	c0.29,0.83,0.58,1.65,0.87,2.48c0.08-0.01,0.16-0.02,0.24-0.02c-0.22-1.88-1.68-3.66-0.8-5.62c-0.12,0.01-0.25,0.01-0.37,0.02
	c0,0.29,0,0.58,0,0.87c-0.1,0.01-0.2,0.01-0.3,0.02c-0.08-0.61-0.22-1.22-0.21-1.83c0.01-0.35,0.28-0.7,0.41-1.06
	c0.18-0.49,0.36-0.98,0.49-1.47c0.03-0.1-0.16-0.22-0.24-0.33c-0.08-0.11-0.23-0.24-0.21-0.34c0.22-0.91,0.45-1.82,0.74-2.72
	c0.04-0.11,0.43-0.17,0.66-0.25c0.06,0.04,0.12,0.08,0.18,0.12c0.26-0.53,0.52-1.07,0.78-1.6c-0.07-0.02-0.13-0.05-0.2-0.07
	c-0.26,0.21-0.51,0.42-0.83,0.69c-0.38-0.7,0.61-1.01,0.73-1.54c-0.29,0.11-0.54,0.25-0.7,0.42c-0.52,0.58-0.98,1.18-1.52,1.75
	c-0.16,0.17-0.49,0.27-0.74,0.41c-0.08-0.03-0.17-0.07-0.25-0.1c-0.11,0.51-0.32,1.02-0.3,1.53c0.06,1.63,0.28,3.25,0.3,4.88
	C234.97,559.4,236.07,560.94,235.63,562.58z"
        />
        <path
            fill={textColor}
            d="M237.77,571.65c-0.43,0.24-0.77,0.46-1.01,0.66c0.01-0.17,0.02-0.33,0.03-0.5c-0.13,0-0.26,0-0.39,0c0,0.29,0,0.58,0,0.87
	c-0.24,0.37-0.11,0.71,0.39,1.11c0.76,0.6,0.8,1.25,0.84,1.93c0.11,1.76,0.22,3.51,0.33,5.27c0.11,0,0.21,0.01,0.31,0.01
	c0.06-0.62,0.19-1.23,0.16-1.85c-0.09-1.51-0.26-3.02-0.39-4.54c-0.02-0.27-0.08-0.59,0.09-0.81
	C238.76,573.02,238.1,572.37,237.77,571.65z"
        />
        <path
            fill={textColor}
            d="M238.05,583.14c0.28,0.11,0.45,0.17,0.64,0.24c0.06-0.72-0.6-1.4-1.71-1.85c0.23,1.12,0.46,2.28,0.7,3.44
	c0.08,0,0.16,0.01,0.24,0.01C237.96,584.39,238,583.79,238.05,583.14z"
        />
        <path
            fill={backgroundColor}
            d="M256.96,623.78c-0.03-0.22-0.06-0.44-0.11-0.8c-0.31,0.34-0.5,0.56-0.72,0.8c-0.22-0.2-0.38-0.35-0.64-0.59
	c-0.16,0.66-0.46,1.21-0.36,1.73s0.61,0.99,0.94,1.5c0-0.21,0.04-0.47-0.01-0.71c-0.06-0.27-0.26-0.52-0.33-0.79
	C255.61,624.39,256.33,623.73,256.96,623.78z"
        />
        <path
            fill={textColor}
            d="M236.15,564.7c-0.11,0.01-0.22,0.02-0.34,0.03c0.13,0.77,0.27,1.54,0.4,2.3c0.1,0,0.2,0,0.3,0c0.05-0.2,0.11-0.39,0.18-0.65
	c0.22,0.16,0.37,0.26,0.52,0.37c0.07-0.02,0.14-0.03,0.22-0.05c-0.06-0.14-0.07-0.37-0.18-0.39c-0.94-0.17-1.02-0.64-0.99-1.17
	C236.27,564.99,236.19,564.85,236.15,564.7z"
        />
        <path
            fill={textColor}
            d="M237.2,586.75c-0.06,0.64-0.13,1.28-0.19,1.92c0.1,0.01,0.2,0.01,0.3,0.02c0.89-0.57,0.05-1.29,0.33-1.91
	C237.49,586.77,237.35,586.76,237.2,586.75z"
        />
        <path
            fill={textColor}
            d="M236.47,569.01c0.28,0.69,0.57,1.38,0.84,2.04C237.42,569.71,237.24,569.27,236.47,569.01z"
        />
        <path
            fill={textColor}
            d="M237.27,607.54c0.14-0.03,0.27-0.05,0.41-0.08c-0.19-0.42-0.37-0.84-0.56-1.27c-0.13,0.02-0.26,0.05-0.39,0.07
	C236.42,606.79,237.18,607.1,237.27,607.54z"
        />
        <path
            fill={textColor}
            d="M237.74,612.43c-0.7,0.15-1.22,0.25-1.89,0.39c0.4,0.21,0.63,0.33,0.81,0.42C237.01,612.98,237.3,612.76,237.74,612.43z"
        />
        <path
            fill={textColor}
            d="M766.98,631.32c0.05,0.56,0.1,1.12,0.17,1.68c0.11,0.9,0.26,1.79,0.39,2.66c0.29-0.18,0.52-0.32,0.7-0.46
	c0.19-0.14,0.45-0.31,0.47-0.44c0.06-0.32,0.03-0.62,0-0.93c-0.02-0.18,0.05-0.45-0.17-0.51c-1.06-0.26-0.38-0.91-0.32-1.42
	c0.15,0.14,0.3,0.28,0.53,0.5c0.69-0.94-0.43-1.33-0.4-2.02c-0.24,0.11-0.36,0.17-0.49,0.23c-0.07-0.02-0.14-0.03-0.2-0.05
	c0.24-0.2,0.48-0.41,0.78-0.66c-0.04-0.2-0.1-0.49-0.15-0.77c-0.03,0.01-0.06,0.02-0.09,0.02c-0.06,0.22-0.13,0.44-0.19,0.67
	c-0.11,0.01-0.23,0.03-0.35,0.04c-0.46-0.68,0.21-1.54,0.75-2.37c-0.08,0-0.15,0.01-0.23,0.01c-0.19,0.14-0.37,0.27-0.57,0.42
	c-0.57-0.59-0.43-1.03,0.42-1.31c-0.35-0.44-0.69-0.89-1.05-1.34c-0.06,0.54-0.12,1.06-0.17,1.57c-0.01,0.1-0.05,0.21-0.05,0.3
	c0.01,0.39-0.14,0.84,0.09,1.16c0.29,0.41,0.5,0.81,0.14,1.35c-0.06,0.09-0.12,0.19-0.11,0.27c0.04,0.46,0.08,0.91,0.12,1.37
	c-0.01-0.01-0.03-0.02-0.04-0.03c0,0.03,0.01,0.05,0.01,0.08C766.96,631.34,766.97,631.33,766.98,631.32z"
        />
        <path
            fill={textColor}
            d="M732.51,579.65c0.46,0.45,0.92,0.9,1.38,1.35c0.08-0.03,0.15-0.05,0.23-0.08c-0.62-1.13-2.42-1.9-2-3.36
	c-0.12,0.04-0.23,0.07-0.35,0.11c0.06,0.18,0.13,0.37,0.19,0.55c-0.09,0.03-0.19,0.06-0.28,0.08c-0.21-0.37-0.48-0.72-0.6-1.1
	c-0.07-0.23,0.12-0.51,0.16-0.77c0.07-0.35,0.13-0.71,0.15-1.05c0-0.07-0.2-0.1-0.3-0.15c-0.1-0.05-0.27-0.09-0.27-0.17
	c0.01-0.63,0.03-1.26,0.11-1.91c0.01-0.08,0.37-0.21,0.57-0.32c0.07,0.01,0.13,0.02,0.2,0.03c0.13-0.4,0.27-0.81,0.4-1.21
	c-0.07,0-0.14,0-0.2,0c-0.2,0.2-0.4,0.39-0.65,0.64c-0.52-0.35,0.36-0.79,0.36-1.16c-0.26,0.14-0.46,0.29-0.57,0.44
	c-0.37,0.5-0.68,0.99-1.07,1.49c-0.12,0.15-0.41,0.29-0.62,0.44c-0.09,0-0.18,0-0.26,0c0.01,0.35-0.08,0.73,0.05,1.04
	c0.42,1.01,0.98,1.99,1.37,3.01c0.38,1.02,1.78,1.72,1.72,2.87c0,0.01,0.09,0.01,0.31,0.02c-0.06-0.29-0.1-0.53-0.15-0.77
	C732.42,579.68,732.46,579.67,732.51,579.65z"
        />
        <path
            fill={textColor}
            d="M734.84,582.6c-0.08-0.07-0.15-0.22-0.26-0.2c-0.94,0.12-1.12-0.15-1.2-0.49c-0.02-0.09-0.13-0.17-0.2-0.25
	c-0.11,0.03-0.21,0.07-0.31,0.1c0.3,0.45,0.59,0.91,0.89,1.36c0.1-0.03,0.19-0.05,0.29-0.08c0.01-0.14,0.02-0.27,0.03-0.46
	c0.24,0.04,0.41,0.08,0.58,0.11C734.71,582.66,734.77,582.63,734.84,582.6z"
        />
        <path
            fill={textColor}
            d="M734.44,584.31c0.42,0.37,0.85,0.73,1.25,1.08C735.49,584.52,735.23,584.28,734.44,584.31z"
        />
        <path
            fill={textColor}
            d="M742.89,607.82c-0.19,0.41,0.61,0.41,0.8,0.67c0.13-0.05,0.25-0.1,0.38-0.15c-0.27-0.22-0.54-0.44-0.82-0.66
	C743.13,607.73,743.01,607.77,742.89,607.82z"
        />
        <path
            fill={textColor}
            d="M743.48,612.19c0.43,0.03,0.68,0.05,0.87,0.07c0.28-0.26,0.51-0.47,0.85-0.79C744.57,611.74,744.09,611.94,743.48,612.19z"
        />
        <path
            fill={textColor}
            d="M751.91,647.85c-0.53,0.38-1.1,0.58-1.36,0.99c-0.26,0.41-0.21,1.04-0.32,1.59c0.14-0.15,0.34-0.29,0.47-0.5
	c0.13-0.22,0.16-0.52,0.29-0.74c0.25-0.43,1.2-0.48,1.62-0.08c0.12-0.18,0.24-0.35,0.44-0.64c-0.44,0.07-0.72,0.12-1.04,0.16
	C751.98,648.37,751.95,648.17,751.91,647.85z"
        />
        <path
            fill={textColor}
            d="M744.21,654.32c0.07-0.14,0.1-0.2,0.12-0.23c-0.46,0.29-0.98,0.58-1.45,0.91c-0.17,0.12-0.22,0.39-0.32,0.59
	c0.1,0.08,0.2,0.16,0.29,0.23c0.64-0.49,1.26-0.99,1.89-1.51C744.59,654.32,744.44,654.32,744.21,654.32z"
        />
        <path
            fill={textColor}
            d="M747.62,615.94c-0.1,0.4-0.21,0.8-0.32,1.2c0.1,0.02,0.19,0.04,0.29,0.06c0.91-0.23,0.14-0.82,0.45-1.18
	C747.9,615.99,747.76,615.97,747.62,615.94z"
        />
        <path
            fill={textColor}
            d="M743.94,627.95c-0.43,0.2,0.17,0.66,0.12,0.95c0.13,0.04,0.26,0.07,0.39,0.11c-0.04-0.32-0.09-0.65-0.14-0.97
	C744.18,628.02,744.06,627.99,743.94,627.95z"
        />
        <path
            fill={textColor}
            d="M741.71,631.99c0.39,0,0.72,0,1.2-0.01c-0.64-0.21-1.13-0.37-1.75-0.58C741.43,631.69,741.59,631.86,741.71,631.99z"
        />
        <path
            fill={textColor}
            d="M755.14,576.42c-0.13,0.36-0.26,0.73-0.4,1.13c-0.25-0.14-0.4-0.23-0.56-0.33c-0.16,0.45,0.37,1.07,1.36,1.63
	c-0.08-0.77-0.14-1.56-0.18-2.36C755.29,576.47,755.22,576.44,755.14,576.42z"
        />
        <path
            fill={textColor}
            d="M756.11,575.53c0.17-0.38,0.35-0.75,0.54-1.12c-0.09-0.04-0.18-0.08-0.27-0.11c-0.93,0.05-0.29,0.78-0.66,1.08
	C755.84,575.42,755.97,575.47,756.11,575.53z"
        />
        <path
            fill={textColor}
            d="M761.69,563.29c-0.02,0.33-0.03,0.65-0.04,0.97c0.11,0.05,0.23,0.11,0.34,0.16c0.46-0.11-0.04-0.68,0.06-0.96
	C761.93,563.41,761.81,563.35,761.69,563.29z"
        />
        <path
            fill={textColor}
            d="M763.76,560.66c0.59,0.33,1.04,0.58,1.61,0.9c-0.21-0.33-0.33-0.53-0.43-0.68C764.55,560.81,764.23,560.75,763.76,560.66z"
        />
        <path
            fill={textColor}
            d="M491.75,653.11c-0.14,0.83-0.28,1.66-0.4,2.49c-0.2,1.35-0.35,2.7-0.51,4c0.33-0.16,0.6-0.26,0.82-0.4
	c0.23-0.14,0.53-0.28,0.6-0.46c0.17-0.44,0.23-0.89,0.31-1.34c0.04-0.27,0.2-0.64,0-0.8c-0.93-0.78-0.07-1.46,0.16-2.18
	c0.1,0.26,0.19,0.52,0.34,0.92c0.97-1.1,0.03-2.08,0.29-3.07c-0.27,0.07-0.41,0.11-0.54,0.14c-0.06-0.05-0.12-0.1-0.18-0.15
	c0.3-0.21,0.6-0.41,0.96-0.66c0.03-0.31,0.07-0.74,0.11-1.17c-0.03,0-0.06,0-0.09,0c-0.14,0.3-0.27,0.59-0.41,0.89
	c-0.12-0.02-0.23-0.04-0.35-0.06c-0.21-1.16,0.71-2.15,1.51-3.15c-0.08-0.02-0.15-0.05-0.23-0.07c-0.22,0.13-0.45,0.25-0.69,0.39
	c-0.35-1.06-0.07-1.65,0.84-1.74c-0.18-0.77-0.37-1.55-0.56-2.34c-0.24,0.76-0.46,1.49-0.69,2.21c-0.04,0.14-0.12,0.28-0.15,0.42
	c-0.12,0.57-0.41,1.16-0.3,1.72c0.14,0.71,0.2,1.37-0.31,2.01c-0.09,0.11-0.18,0.23-0.2,0.35c-0.12,0.68-0.23,1.35-0.35,2.03
	c-0.01-0.02-0.02-0.04-0.03-0.05c-0.01,0.04-0.01,0.08-0.02,0.12C491.72,653.12,491.74,653.11,491.75,653.11z"
        />
        <path
            fill={textColor}
            d="M475.8,565.28c0.05,0,0.1,0,0.15,0c0.29,0.83,0.58,1.65,0.87,2.48c0.08-0.01,0.16-0.02,0.24-0.02
	c-0.22-1.88-1.68-3.66-0.8-5.62c-0.12,0.01-0.25,0.01-0.37,0.02c0,0.29,0,0.58,0,0.87c-0.1,0.01-0.2,0.01-0.3,0.02
	c-0.08-0.61-0.22-1.22-0.21-1.83c0.01-0.35,0.28-0.7,0.41-1.06c0.18-0.49,0.36-0.98,0.49-1.47c0.03-0.1-0.16-0.22-0.24-0.33
	c-0.08-0.11-0.23-0.24-0.21-0.34c0.22-0.91,0.45-1.82,0.74-2.72c0.04-0.11,0.43-0.17,0.66-0.25c0.06,0.04,0.12,0.08,0.18,0.12
	c0.26-0.53,0.52-1.07,0.78-1.6c-0.07-0.02-0.13-0.05-0.2-0.07c-0.26,0.21-0.51,0.42-0.83,0.69c-0.38-0.7,0.61-1.01,0.73-1.54
	c-0.29,0.11-0.54,0.25-0.7,0.42c-0.52,0.58-0.98,1.18-1.52,1.75c-0.16,0.17-0.49,0.27-0.74,0.41c-0.08-0.03-0.17-0.07-0.25-0.1
	c-0.11,0.51-0.32,1.02-0.3,1.53c0.06,1.63,0.28,3.25,0.3,4.88c0.03,1.62,1.13,3.16,0.69,4.8c0,0.02,0.08,0.04,0.29,0.14
	C475.73,566.02,475.77,565.65,475.8,565.28z"
        />
        <path
            fill={textColor}
            d="M476.17,575.54c0,0.29,0,0.58,0,0.87c-0.24,0.37-0.11,0.71,0.39,1.11c0.76,0.6,0.8,1.25,0.84,1.93
	c0.11,1.76,0.22,3.51,0.33,5.27c0.1,0,0.21,0.01,0.31,0.01c0.06-0.62,0.19-1.23,0.16-1.85c-0.09-1.51-0.26-3.02-0.39-4.54
	c-0.02-0.27-0.08-0.59,0.09-0.81c0.62-0.8-0.04-1.45-0.37-2.17c-0.43,0.24-0.77,0.46-1.01,0.66c0.01-0.17,0.02-0.33,0.03-0.5
	C476.43,575.54,476.3,575.54,476.17,575.54z"
        />
        <path
            fill={textColor}
            d="M476.75,585.26c0.23,1.12,0.46,2.28,0.7,3.44c0.08,0,0.16,0.01,0.24,0.01c0.04-0.59,0.09-1.19,0.13-1.84
	c0.28,0.11,0.45,0.17,0.64,0.24C478.52,586.39,477.85,585.7,476.75,585.26z"
        />
        <path
            fill={textColor}
            d="M480.42,650.88c-0.18,0.01-0.35,0.02-0.43,0.03c-0.15,1.08-0.29,2.12-0.43,3.17c0.12,0,0.24,0.01,0.36,0.01
	c0.36-1.65,0.71-3.31,1.07-4.96c-0.13-0.01-0.26-0.02-0.39-0.04C480.05,649.64,480.54,650.28,480.42,650.88z"
        />
        <path
            fill={textColor}
            d="M496.74,627.51c-0.03-0.22-0.06-0.44-0.11-0.8c-0.31,0.34-0.5,0.56-0.72,0.8c-0.22-0.2-0.38-0.35-0.64-0.59
	c-0.16,0.66-0.46,1.21-0.36,1.73c0.1,0.51,0.61,0.99,0.94,1.5c0-0.21,0.04-0.47-0.01-0.71c-0.06-0.27-0.26-0.52-0.33-0.79
	C495.38,628.12,496.11,627.46,496.74,627.51z"
        />
        <path
            fill={textColor}
            d="M494.43,637.08c-0.08,0.55-0.19,1.14-0.22,1.72c-0.01,0.21,0.19,0.43,0.3,0.65c0.14-0.02,0.27-0.04,0.41-0.07
	c0-0.81,0-1.62,0-2.44c-0.11,0.11-0.22,0.23-0.37,0.39C494.48,637.19,494.45,637.12,494.43,637.08z"
        />
        <path
            fill={textColor}
            d="M477.96,639.59c0.32-1.38,0.65-2.76,0.97-4.14c-0.85,0.66,0.03,1.75-1.59,2.25c0.24,0,0.47,0,0.8,0
	c-0.11,0.6-0.22,1.24-0.33,1.87C477.86,639.58,477.91,639.58,477.96,639.59z"
        />
        <path
            fill={textColor}
            d="M477.72,641.87c0-0.59,0-1.19,0-1.75c-0.25,0-0.59,0-0.91,0C475.83,640.91,477.52,641.23,477.72,641.87z"
        />
        <path
            fill={textColor}
            d="M476.29,570.76c0.05-0.2,0.11-0.39,0.18-0.65c0.22,0.16,0.37,0.26,0.52,0.37c0.07-0.02,0.14-0.03,0.22-0.05
	c-0.06-0.14-0.07-0.37-0.18-0.39c-0.94-0.17-1.02-0.64-0.99-1.17c0.01-0.14-0.07-0.29-0.11-0.44c-0.11,0.01-0.22,0.02-0.34,0.03
	c0.13,0.77,0.27,1.54,0.4,2.3C476.09,570.76,476.19,570.76,476.29,570.76z"
        />
        <path
            fill={textColor}
            d="M477.41,590.51c-0.15-0.01-0.29-0.02-0.44-0.03c-0.06,0.64-0.13,1.28-0.19,1.92c0.1,0.01,0.2,0.01,0.3,0.02
	C477.98,591.85,477.13,591.13,477.41,590.51z"
        />
        <path
            fill={textColor}
            d="M476.51,610c-0.32,0.52,0.45,0.83,0.54,1.27c0.14-0.03,0.27-0.05,0.41-0.08c-0.19-0.42-0.37-0.84-0.56-1.27
	C476.77,609.95,476.64,609.97,476.51,610z"
        />
        <path
            fill={textColor}
            d="M475.62,616.55c0.4,0.21,0.63,0.33,0.81,0.42c0.35-0.27,0.64-0.49,1.08-0.82C476.81,616.3,476.29,616.41,475.62,616.55z"
        />
        <path
            fill={textColor}
            d="M566.08,595.64c-0.13-0.24-0.08-0.44,0.01-0.64c0.11-0.24,0.37-0.48,0.35-0.71c-0.11-0.96-0.32-1.91-0.45-2.87
	c-0.09-0.63-0.2-1.28-0.15-1.91c0.05-0.75,0.29-1.49,0.4-2.24c0.05-0.31-0.05-0.63-0.03-0.94c0.07-0.84,0.19-1.68,0.23-2.53
	c0.01-0.17-0.27-0.34-0.4-0.49c0.09-0.46,0.27-0.95,0.27-1.45c0-0.49-0.15-0.95-0.46-1.45c-0.35-0.55-0.06-1.29-0.03-1.94
	c0.03-0.85,0.13-1.7,0.11-2.55c-0.02-0.77-0.21-1.55-0.26-2.32c-0.11-1.71,0.03-3.42-0.54-5.12c-0.23-0.68,0-1.43-0.02-2.15
	c-0.01-0.3-0.09-0.61-0.19-0.9c-0.09-0.29-0.34-0.57-0.32-0.85c0.03-0.53,0.2-1.06,0.32-1.59c-0.05,0-0.1-0.01-0.16-0.01
	c-0.05,0.09-0.11,0.19-0.16,0.28c-0.18-0.51-0.15-0.99-0.19-1.48c-0.04-0.46-0.44-0.93,0.23-1.34c0.05-0.03,0.02-0.17-0.02-0.26
	c0.05,0.02,0.1,0.05,0.15,0.07c0.37-0.68,0.64-1.18,1-1.84c-0.43,0.15-0.62,0.21-0.65,0.23c-0.41-0.12-0.74-0.21-1.03-0.29
	c0.01-0.49,0.03-0.99,0.05-1.48c0.13-0.24,0.31-0.47,0.46-0.72c-0.42-0.15-0.39-0.15-0.28-0.59c0.01-0.04-0.05-0.11-0.13-0.16
	c0.05-2.25-0.01-4.48-0.67-6.64c-0.51-0.19-0.88-0.34-1.4-0.54c0,0.46,0.12,0.88-0.03,1.26c-0.23,0.62-0.66,1.21-0.96,1.82
	c-0.18,0.36-0.29,0.73-0.41,1.1c-0.04,0.13,0.07,0.35-0.02,0.39c-0.79,0.35-0.37,0.76-0.19,1.17c0.12,0.27,0.34,0.61,0.21,0.84
	c-0.04,0.08-0.08,0.15-0.11,0.23c-0.12,0.01-0.23,0.02-0.35,0.04c-0.11-1.34-0.23-2.66-0.29-3.98c-0.01-0.17,0.34-0.35,0.52-0.52
	c-0.07,0.01-0.14,0.01-0.21,0.02c-0.2-0.97-0.41-1.94-0.61-2.89c-0.79-0.2-1.2,0.08-1.41,0.53c-0.26,0.56-0.7,0.65-1.39,0.3
	c-0.12-0.06-0.28-0.08-0.52-0.13c-0.18,0.15-0.35,0.42-0.57,0.44c-1.29,0.12-2.12,0.76-2.77,1.35c-0.77,0.69-1.1,1.58-0.98,2.52
	c0.23,1.73,0.31,3.48,0.45,5.22c0.03,0.42-0.09,0.87,0.1,1.25c0.33,0.65,0.37,0.69-0.76,0.97c-0.03-0.22-0.08-0.42-0.09-0.62
	c-0.08-1.93-0.15-3.85-0.25-5.78c-0.06-1.03-0.14-2.07-0.31-3.1c-0.05-0.28-0.44-0.75-0.71-0.76c-0.77-0.03-1.68-0.41-2.34,0.19
	c-0.12-0.12-0.25-0.24-0.31-0.3c-0.23,0.67-0.44,1.34-0.69,2c-0.61,1.66-1.25,3.32-1.09,5.06c0.11,1.22,0.16,2.45,0.18,3.67
	c0.03,1.73-0.13,3.47,0.03,5.19c0.23,2.47,0.67,4.93,1.01,7.39c0.18,1.3,0.35,2.6,0.52,3.9c0.01,0.06,0,0.12-0.01,0.19
	c-0.06,0.87-0.16,1.74-0.19,2.61c-0.03,0.73,0.12,1.46,0.01,2.18c-0.16,1.1,0.51,2.17,0.14,3.29c-0.18,0.53,0.16,1.14,0.07,1.69
	c-0.19,1.21,0.52,2.41,0.04,3.61c-0.02,0.04,0.02,0.08,0.02,0.13c0,1.04,0.57,2.08-0.03,3.12c-0.11,0.18-0.04,0.42-0.02,0.63
	c0.05,0.9,0.13,1.81,0.17,2.71c0.04,0.83,0.06,1.65,0.06,2.48c-0.01,0.9-0.06,1.79-0.07,2.69c-0.01,0.45,0.08,0.89,0.06,1.34
	c-0.06,1.12-0.17,2.23-0.27,3.45c0.18-0.16,0.28-0.24,0.37-0.33c0.09,0.03,0.19,0.06,0.28,0.08c-0.14,0.27-0.28,0.53-0.42,0.8
	c-0.07,0.13-0.15,0.26-0.17,0.39c-0.26,1.73,0.19,3.47-0.16,5.2c-0.12,0.61,0.09,1.24,0.15,1.87c-0.23-0.1-0.47-0.21-0.7-0.31
	c-0.09-0.04-0.24-0.11-0.21-0.22c0.4-1.36-0.65-1.42-0.99-2.11c-0.06-0.13-0.44,0.03-0.67,0.05c-0.09,0.1-0.17,0.2-0.26,0.3
	c-0.16-0.53-0.32-1.07-0.49-1.6c-0.14-0.44-0.23-0.93-0.47-1.28c-0.39-0.56-0.89-1.02-1.33-1.53c-0.06-0.07-0.11-0.23-0.05-0.35
	c0.13-0.23,0.15-0.36,0.12-0.44c0.21,0.16,0.41,0.38,1.16-0.44c-0.48-0.35-0.94-0.68-1.4-1.02c-0.16-0.12-0.4-0.19-0.46-0.38
	c-0.42-1.23-1.85-1.49-2.06-2.95c-0.1-0.66-0.96-0.81-1.45-1.21c-0.22-0.18-0.42-0.38-0.59-0.62c-0.39-0.57-0.74-1.17-1.15-1.82
	c-0.47,1.43-0.27,2.09,0.33,2.07c-0.02,0.27-0.08,0.61-0.1,0.83c-0.21-0.49-0.43-0.97-0.68-1.41c-0.18-0.31-0.53-0.48-0.85-0.67
	c-0.24-0.15-0.53-0.23-0.81-0.34c0.06-0.08,0.13-0.16,0.19-0.23c0.14,0,0.28-0.01,0.43-0.01c-0.25-0.23-0.73-0.17-0.95-0.37
	c-0.51-0.44-0.89-1.01-1.34-1.51c-0.17-0.19-0.36-0.39-0.6-0.5c-0.14-0.07-0.42,0.02-0.64,0.04c0.22-0.3,0.43-0.59,0.8-1.09
	c-0.6-0.66-1.31-1.32-1.88-2.08c-0.27-0.36-0.65-0.83-0.53-1.33c0.2,0.04,0.39,0.07,0.6,0.11c-0.67,1.78,1.1,1.67,1.68,2.63
	c0.07-0.29,0.11-0.48,0.16-0.67c0.08-0.05,0.15-0.1,0.23-0.16c0.2,0.38,0.4,0.76,0.59,1.14c-0.06,0.06-0.12,0.11-0.18,0.17
	c-0.15-0.03-0.29-0.06-0.44-0.1c0.48,0.21,0.59,0.73,0.88,1.12c-0.34,0.69,0.15,0.68,0.75,0.62c0.33,0.37,0.51,0.92,0.81,1.38
	c0.17-0.26,0.37-0.58,0.52-0.81c0.08,0.27,0.15,0.51,0.23,0.75c0.23-0.34,0.36-0.61,0.4-0.8c0.13,0,0.25,0,0.38,0
	c0.06-0.08,0.11-0.15,0.17-0.23c-0.46-0.25-0.88-0.54-1.38-0.74c-0.66-0.27-0.88-0.82-0.79-1.72c0.06-0.57-0.18-1.22-0.66-1.25
	c-0.63-0.04-0.83-0.37-1-0.79c-0.14-0.33-0.17-0.77-0.42-0.99c-0.7-0.63-1.52-1.15-2.24-1.76c-0.13-0.11-0.02-0.43-0.02-0.58
	c-0.31-0.08-0.59-0.15-0.88-0.22c0.2-0.21,0.33-0.34,0.48-0.5c-1.33-0.05-1.44-0.19-0.79-0.95c-0.7-0.08-1.08-0.42-1.04-1.18
	c0.02-0.26-0.3-0.37-0.57-0.67c-0.03,0.43-0.05,0.62-0.06,0.81c-0.28-0.06-0.36-0.25-0.41-0.46c-0.06-0.26,0.03-0.64-0.14-0.78
	c-0.72-0.59-1.54-1.09-2.29-1.66c-0.5-0.38-1.02-0.74-1.39-1.23c-0.44-0.57-0.7-1.29-1.08-1.9c-0.16-0.26-0.46-0.4-0.64-0.64
	c-0.49-0.65-0.91-1.34-1.43-1.97c-0.1-0.12-0.47-0.03-0.7-0.03c-0.21-0.4-0.36-0.89-0.68-1.24c-0.32-0.35-0.77-0.56-1.38-0.66
	c-0.69-0.12-0.9-0.87-1.3-1.35c-0.52-0.63-0.98-1.31-1.56-1.89c-0.53-0.53-1.21-0.93-1.77-1.44c-1.23-1.12-2.2-2.45-3.88-3.22
	c-0.67-0.31-0.94-1.01-1.43-1.51c-0.21-0.21-0.49-0.36-0.77-0.5c-0.28-0.14-0.71-0.15-0.88-0.36c-0.32-0.4-0.49-0.9-0.72-1.36
	c-0.05,0.03-0.11,0.07-0.16,0.11c0.01,0.11,0.01,0.21,0.02,0.32c-0.51-0.23-0.8-0.6-1.16-0.91c-0.34-0.3-1.05-0.34-0.65-1.12
	c0.03-0.06-0.09-0.13-0.19-0.17c0.07-0.02,0.13-0.04,0.2-0.06c-0.07-0.76-0.13-1.31-0.19-2.03c-0.34,0.42-0.49,0.6-0.51,0.64
	c-0.5,0.22-0.89,0.39-1.23,0.54c-0.31-0.36-0.62-0.73-0.93-1.09c-0.02-0.26,0.01-0.56-0.01-0.84c-0.52,0.19-0.5,0.18-0.68-0.22
	c-0.02-0.04-0.12-0.04-0.24-0.02c-1.12-1.29-2.31-2.52-3.79-3.51c0.01-1.91-0.12-3.79-0.68-5.63c-0.51-0.19-0.88-0.34-1.4-0.54
	c0,0.46,0.12,0.88-0.03,1.26c-0.23,0.62-0.66,1.21-0.96,1.82c-0.18,0.36-0.29,0.73-0.41,1.1c-0.04,0.13,0.07,0.35-0.02,0.39
	c-0.79,0.35-0.37,0.76-0.19,1.17c0.12,0.27,0.34,0.61,0.21,0.84c-0.04,0.08-0.08,0.15-0.11,0.23c-0.12,0.01-0.23,0.02-0.35,0.04
	c-0.11-1.34-0.23-2.66-0.29-3.98c-0.01-0.17,0.34-0.35,0.52-0.52c-0.07,0.01-0.14,0.01-0.21,0.02c-0.2-0.97-0.41-1.94-0.61-2.89
	c-0.79-0.2-1.2,0.08-1.41,0.53c-0.26,0.56-0.7,0.65-1.39,0.3c-0.12-0.06-0.28-0.08-0.52-0.14c-0.18,0.15-0.35,0.42-0.57,0.44
	c-1.29,0.12-2.12,0.76-2.77,1.35c-0.77,0.69-1.1,1.58-0.98,2.52c0.23,1.74,0.31,3.48,0.45,5.22c0.03,0.42-0.09,0.87,0.1,1.25
	c0.33,0.65,0.37,0.69-0.76,0.97c-0.03-0.22-0.08-0.42-0.09-0.62c-0.08-1.93-0.15-3.85-0.25-5.78c-0.06-1.03-0.14-2.07-0.31-3.1
	c-0.05-0.28-0.44-0.75-0.71-0.76c-0.77-0.03-1.68-0.41-2.34,0.19c-0.12-0.12-0.25-0.24-0.31-0.3c-0.23,0.67-0.44,1.34-0.69,2
	c-0.61,1.66-1.25,3.32-1.09,5.06c0.11,1.22,0.16,2.45,0.18,3.67c0.03,1.73-0.13,3.47,0.03,5.19c0.04,0.46,0.1,0.91,0.15,1.37
	c-0.14,0.21-0.23,0.41-0.26,0.59c-0.13,0.81-0.19,1.6-0.33,2.42c-0.04,0.21-0.24,0.48-0.4,0.73c-0.13-0.12-0.29-0.21-0.51-0.28
	c0.06,0.16,0.13,0.31,0.19,0.47c-0.03,0.01-0.06,0.02-0.09,0.03c0.2,0.36,0.33,0.77,0.56,1.12c0.06,0.14,0.12,0.29,0.18,0.43
	c0.01-0.07,0.01-0.13,0.01-0.19c0.01,0.01,0.02,0.02,0.03,0.03c0.51,0.51,1.05,0.99,1.59,1.47c0.14,1.04,0.28,2.08,0.42,3.12
	c0.01,0.06,0,0.12-0.01,0.19c-0.06,0.87-0.16,1.74-0.19,2.61c-0.03,0.73,0.12,1.46,0.01,2.18c-0.16,1.1,0.51,2.17,0.14,3.29
	c-0.18,0.53,0.16,1.14,0.07,1.69c-0.19,1.21,0.52,2.41,0.04,3.61c-0.02,0.04,0.02,0.08,0.02,0.13c0,1.04,0.57,2.08-0.03,3.12
	c-0.11,0.18-0.04,0.42-0.02,0.63c0.05,0.9,0.13,1.81,0.17,2.71c0.04,0.83,0.06,1.65,0.06,2.48c-0.01,0.9-0.06,1.79-0.07,2.69
	c-0.01,0.45,0.08,0.89,0.06,1.34c-0.06,1.12-0.17,2.23-0.27,3.45c0.18-0.16,0.28-0.25,0.37-0.33c0.09,0.03,0.19,0.06,0.28,0.08
	c-0.14,0.27-0.28,0.53-0.42,0.8c-0.07,0.13-0.15,0.26-0.17,0.39c-0.26,1.73,0.19,3.47-0.16,5.2c-0.13,0.63,0.11,1.3,0.16,1.95
	c0.02,0.27,0,0.55-0.04,0.82c-0.11,0.82-0.24,1.63-0.36,2.44c-0.01,0.05,0.05,0.11,0.09,0.2c0.18-0.14,0.33-0.26,0.49-0.38
	c0.5,0.58,0.19,1.1-0.51,1.12c-0.73,1.09,1.03,1.9,0.68,2.95c0,0.01,0.09,0.04,0.29,0.13c0-0.44,0.12-0.83-0.02-1.18
	c-0.45-1.05-0.29-1.94,1.37-2.69c-0.25,1.01-0.47,1.92-0.69,2.83c-0.04,0.16-0.16,0.43-0.06,0.47c0.78,0.35,0.32,0.79,0.23,1.19
	c-0.06,0.25-0.37,0.57-0.23,0.72c0.64,0.71-0.39,1.27-0.12,1.92c-0.13,0.01-0.26,0.01-0.39,0.02c-0.24-0.74-0.48-1.48-0.69-2.13
	c-0.18,0.21-0.45,0.53-0.73,0.85c0.06,0,0.12,0,0.18-0.01c0,0.45,0,0.9,0,1.39c0.01,0,0.23,0,0.32,0.08
	c0.38,0.32,0.23,2.16-0.25,2.38c-0.89,0.41-0.72,0.96-0.54,1.54c0.18-0.21,0.35-0.42,0.53-0.63c0.09,0,0.18-0.01,0.27-0.01
	c0.15,0.55,0.32,1.11,0.41,1.66c0.01,0.05-0.16,0.12-0.39,0.22c-0.18-0.28-0.35-0.56-0.53-0.86c-0.12,0.86-0.22,1.63-0.32,2.41
	c0.09,0.02,0.19,0.03,0.28,0.05c0.48-0.45,0.96-0.9,1.48-1.39c0.32,1.02-0.02,3.09-0.6,3.23c0.19,0.75,0.73,1.56,0.47,2.22
	c-0.35,0.85-0.27,1.66-0.34,2.5c-0.03,0.39-0.34,0.77-0.41,1.17c-0.05,0.24,0.11,0.5,0.17,0.75c0.06,0.38-0.22,0.8-0.22,1.21
	c0,0.31,0.55,0.63,0.52,0.93c-0.07,0.82-0.28,1.64-1.22,2.29c-0.16-0.42-0.31-0.82-0.46-1.21c-0.05,0.52-0.15,0.97,0.22,1.5
	c0.4,0.56,0.07,1.35,0.06,2.04c-0.02,0.64-0.22,1.31,0.88,1.98c0.07-0.38,0.16-0.61,0.13-0.82c-0.07-0.63-0.64-1.3,0.47-1.79
	c0.09-0.04,0.09-0.19,0.1-0.3c0.08-0.54,0.1-1.09,0.23-1.62c0.05-0.2,0.31-0.38,0.48-0.57c0.07,0.02,0.14,0.04,0.21,0.07
	c0.06,0.22,0.12,0.44,0.18,0.66c0.36-0.48,0.42-0.89-0.62-1.03c0.28-0.2,0.46-0.34,0.67-0.49c0.11,0.13,0.18,0.21,0.28,0.32
	c0.14-0.22,0.26-0.39,0.47-0.71c0.06,0.39,0.09,0.61,0.12,0.83c0.09,0,0.18,0,0.27,0c0.19-1.37,0.34-2.74,0.58-4.11
	c0.08-0.47,0-0.87-0.23-1.33c-0.18-0.36-0.14-0.97,0.2-1.21c0.53-0.38,0.65-0.72,0.58-1.22c-0.16,0.28-0.29,0.51-0.5,0.87
	c-0.7-0.95,0.04-1.61,0.39-2.31c0.2,0.35,0.41,0.7,0.72,1.22c0.03-1.08-0.52-1.9-0.28-2.85c0.55-2.24,0.42-2.25,1.14-2.36
	c0,0.63-0.01,1.25,0,1.88c0.03,1.38,0.07,2.76,0.11,4.14c0,0.02,0,0.05,0.02,0.06c0.81,0.4,0.31,0.86,0.15,1.31
	c-0.07,0.19-0.1,0.4-0.15,0.62c0.36-0.04,0.61-0.07,0.86-0.1c0.08-0.79,0.17-1.57,0.25-2.36c0.09,0,0.19,0.01,0.28,0.01
	c0.14,0.27,1.26,0.4,0.21,0.84c-0.06,0.03-0.03,0.16-0.03,0.24c0.02,0.46,0.04,0.92,0.07,1.37c0.04,0.56,0.08,1.12-0.95,1.21
	c0.16,0.26,0.24,0.64,0.43,0.67c0.97,0.15,0.68,0.57,0.6,0.97c-0.08,0.38-0.15,0.75-0.2,1.13c-0.04,0.34-0.05,0.68-0.03,1.02
	c0.04,0.47,0.17,0.94,0.19,1.42c0.02,0.42-0.07,0.84-0.11,1.26c-0.12,0.15,0.02,0.32,0.22,0.45c1.24,0.86,1.08,1.91,0.77,2.93
	c-0.13,0.42-0.48,0.75-0.08,1.23c0.19,0.23-0.08,0.63-0.17,1.11c0.75,0.3-0.45,0.74-0.31,1.22c0.38-0.28,0.73-0.53,1.05-0.76
	c-0.51-0.73,0.04-1.44,0.24-2.34c0.13,0.3,0.18,0.43,0.24,0.56c0.68-0.29,0.73,0.21,0.74,0.36c0.02,0.51,0,1.05-0.2,1.54
	c-0.26,0.62-0.56,1.21-0.14,1.8c0.3,0,0.54,0,0.66,0c0.18-0.5,0.33-1.01,0.56-1.5c0.31-0.68,1.35-1.16,1.25-1.96
	c-0.03-0.2-0.02-0.42,0.08-0.61c0.2-0.37,0.42-0.34,0.6-0.05c0.1-0.67,0.19-1.27,0.28-1.86c0.09-0.62,0.29-1.25,0.23-1.87
	c-0.05-0.6-0.39-1.18-0.55-1.78c-0.04-0.16,0.1-0.34,0.2-0.51c0.15-0.25,0.37-0.48,0.49-0.73c0.13-0.27,0.17-0.56,0.25-0.84
	c-0.19-0.01-0.46-0.02-0.64-0.03c0.11-0.73,0.15-1.42,0.34-2.09c0.07-0.24,0.5-0.43,0.77-0.65c0.12-0.09,0.29-0.18,0.41-0.26
	c-0.16,0.21-0.17,0.47,0.01,0.69c0.14,0.18,0.24,0.38,0.35,0.55c0.06-0.05,0.13-0.12,0.33-0.29c0.43,0.8,0.8,1.48,1.17,2.17
	c0.11-0.04,0.22-0.07,0.32-0.11c-0.33-0.6-0.68-1.19-0.99-1.8c-0.08-0.17-0.11-0.4,0-0.54c0.59-0.76,0.68-1.56,0.37-2.39
	c-0.22,0.16-0.39,0.38-0.29,0.49c0.53,0.56-0.05,0.69-0.64,0.84c-0.2,0.05-0.36,0.13-0.49,0.23c0.01-0.01,0.01-0.03,0.01-0.04
	c0.04-0.56-0.01-1.13-0.08-1.69c-0.01-0.08-0.15-0.15-0.19-0.16c0.31-1.11,0.02-2.08,0-3.09c0.15,0.01,0.32,0.02,0.48,0.04
	c0.01-0.33,0.01-0.33-0.62-0.61c-0.08-0.6-0.26-1.07-0.17-1.51c0.1-0.47,0.47-0.92,0.74-1.42c0.17,0.16,0.27,0.25,0.37,0.35
	c0.1-0.02,0.2-0.04,0.3-0.07c-0.18-0.55-0.37-1.11-0.54-1.66c-0.03-0.1-0.08-0.26,0-0.31c0.98-0.65,0.39-1.49,0.57-2.23
	c0.03-0.14-0.28-0.31-0.43-0.47c-0.11,0.01-0.21,0.01-0.31,0.02c0.2-0.5,0.4-1,0.59-1.5c0.16-0.42,0.37-0.84,0.42-1.26
	c0.08-0.69,0.03-1.39,0.05-2.08c0-0.1,0.06-0.25,0.16-0.29c0.2-0.07,0.29-0.15,0.31-0.23c0.04,0.27,0.04,0.58,0.93,0.54
	c-0.09-0.61-0.18-1.19-0.27-1.78c-0.03-0.21-0.13-0.43-0.07-0.62c0.42-1.2-0.28-2.44,0.39-3.67c0.3-0.56-0.12-1.29-0.2-1.95
	c-0.03-0.29-0.04-0.59-0.01-0.88c0.08-0.7,0.2-1.4,0.31-2.18c-1.07,0.73-1.31,1.36-0.94,1.78c-0.16,0.19-0.38,0.39-0.52,0.54
	c0.14-0.51,0.27-1.02,0.37-1.54c0.07-0.36-0.06-0.74-0.14-1.1c-0.06-0.28-0.19-0.55-0.29-0.83c0.08-0.01,0.16-0.03,0.24-0.04
	c0.09,0.1,0.17,0.2,0.26,0.3c-0.03-0.35-0.34-0.65-0.37-0.95c-0.06-0.69,0.02-1.39,0.03-2.08c0-0.27,0-0.54-0.08-0.8
	c-0.05-0.15-0.26-0.28-0.4-0.42c0.29-0.07,0.58-0.14,1.07-0.26c0-0.92-0.06-1.91,0.02-2.9c0.04-0.46,0.07-1.09,0.42-1.38
	c0.1,0.17,0.19,0.33,0.29,0.51c-1.38,0.88-0.26,2.04-0.44,3.18c0.2-0.17,0.33-0.28,0.46-0.39c0.07,0.01,0.15,0.03,0.22,0.04
	c-0.09,0.43-0.18,0.85-0.27,1.28c-0.07,0-0.13,0-0.2,0c-0.07-0.13-0.14-0.26-0.21-0.39c0.17,0.5-0.05,0.97-0.09,1.47
	c-0.58,0.28-0.28,0.61,0.11,1c0,0.51-0.2,1.05-0.28,1.61c0.24-0.08,0.54-0.17,0.75-0.24c-0.1,0.26-0.19,0.49-0.28,0.73
	c0.33-0.09,0.55-0.19,0.68-0.31c0.08,0.09,0.15,0.18,0.23,0.27c0.07-0.02,0.15-0.03,0.22-0.05c-0.14-0.52-0.23-1.04-0.42-1.54
	c-0.25-0.68-0.08-1.24,0.47-1.86c0.35-0.39,0.56-1.06,0.29-1.42c-0.36-0.48-0.29-0.87-0.16-1.31c0.1-0.35,0.32-0.7,0.3-1.05
	c-0.08-0.98-0.27-1.95-0.37-2.93c-0.02-0.17,0.22-0.35,0.3-0.46c-0.14-0.28-0.27-0.53-0.4-0.79c0.24-0.02,0.38-0.04,0.55-0.05
	c-0.77-0.97-0.75-1.15,0.05-1.28c-0.37-0.55-0.41-1.08,0.03-1.63c0.15-0.19,0.02-0.49,0.02-0.92c-0.25,0.31-0.37,0.45-0.48,0.59
	c-0.13-0.24-0.08-0.44,0.01-0.64c0.11-0.24,0.37-0.48,0.35-0.71c-0.11-0.96-0.32-1.91-0.45-2.87c-0.09-0.63-0.2-1.28-0.15-1.91
	c0.02-0.29,0.07-0.57,0.13-0.86c0.92,0.86,1.85,1.73,2.77,2.59c0.1-0.08,0.2-0.16,0.31-0.24c-0.37-0.51-0.68-1.08-1.14-1.52
	c-0.58-0.55-1.18-1.07-1.78-1.6c0.04-0.2,0.08-0.41,0.11-0.61c0.05-0.31-0.05-0.63-0.03-0.94c0.01-0.11,0.02-0.22,0.03-0.33
	c0.05,0.04,0.08,0.1,0.12,0.15c0.54,0.71,1.06,1.43,1.63,2.11c0.48,0.57,1.13,1.01,1.53,1.63c0.61,0.96,1.99,1.23,2.42,2.37
	c0.2,0.54,0.94,0.73,1.24,1.22c0.66,1.06,2.16,1.39,2.55,2.69c0.01,0.04,0.08,0.05,0.11,0.08c0.72,0.78,1.97,1.1,2.13,2.37
	c0.03,0.23,0.26,0.35,0.41,0.49c0.68,0.64,1.38,1.25,2.04,1.9c0.61,0.59,1.2,1.19,1.77,1.82c0.62,0.68,1.19,1.4,1.8,2.09
	c0.3,0.34,0.69,0.6,0.98,0.96c0.72,0.89,1.4,1.82,2.15,2.82c0.04-0.22,0.07-0.35,0.09-0.47l0.48,0.51c0.01,0.06,0.02,0.11,0.03,0.17
	c0.03,0.15,0.04,0.33,0.11,0.44c0.97,1.53,2.58,2.44,3.47,4.05c0.33,0.59,1,0.88,1.5,1.33c0.21,0.19,0.38,0.41,0.53,0.65
	c0.47,0.71,0.92,1.44,1.38,2.15c0.03,0.05,0.11,0.04,0.21,0.07c0.06-0.27,0.11-0.48,0.16-0.72c0.07,0,0.13,0.01,0.19,0.02l0.52,0.56
	c-0.02,0.21-0.15,0.46-0.38,0.71c0.09,1.11,1.31,0.84,2.08,1.12l1.95,2.1c-0.27-0.13-0.53-0.25-0.78-0.37
	c-0.01,0.32-0.02,0.8-0.04,1.29c0.05-0.06,0.1-0.11,0.15-0.17c0.31,0.34,0.63,0.68,0.97,1.05c0.01,0,0.2-0.2,0.33-0.22
	c0.54-0.1,1.69,1.42,1.44,2.02c-0.47,1.11,0.05,1.37,0.61,1.65c0-0.32,0.01-0.64,0.01-0.95c0.07-0.08,0.14-0.17,0.22-0.25
	c0.51,0.28,1.04,0.55,1.5,0.88c0.05,0.03-0.05,0.24-0.17,0.52c-0.35-0.05-0.68-0.1-1.04-0.16c0.5,0.75,0.95,1.43,1.4,2.11
	c0.09-0.07,0.18-0.15,0.27-0.22c0.06-0.57,0.13-1.14,0.2-1.73l1.89,2.03c-0.01,0.11-0.03,0.2-0.08,0.28
	c0.18,0.1,0.38,0.19,0.58,0.27l1.9,2.05c0.28,0.39,0.61,0.73,0.9,1.12c0.25,0.32,0.26,0.89,0.47,1.26c0.13,0.22,0.43,0.28,0.66,0.4
	c0.32,0.23,0.37,0.8,0.66,1.12c0.22,0.23,0.88-0.04,1.07,0.21c0.51,0.68,0.91,1.5,0.6,2.87c-0.43-0.16-0.82-0.32-1.22-0.47
	c0.31,0.44,0.55,0.88,1.22,0.92c0.71,0.05,1,0.95,1.47,1.49c0.43,0.5,0.74,1.2,2.08,0.65c-0.1-0.17-0.18-0.32-0.24-0.44
	c0.13,0.15,0.26,0.31,0.25,0.46c-0.07,0.82-0.28,1.64-1.22,2.29c-0.16-0.42-0.31-0.82-0.46-1.21c-0.05,0.52-0.15,0.97,0.22,1.5
	c0.4,0.56,0.07,1.35,0.06,2.04c-0.02,0.64-0.22,1.31,0.88,1.98c0.07-0.38,0.16-0.61,0.13-0.82c-0.07-0.63-0.64-1.3,0.47-1.79
	c0.09-0.04,0.09-0.19,0.1-0.3c0.08-0.54,0.1-1.09,0.23-1.62c0.05-0.2,0.31-0.38,0.48-0.57c0.07,0.02,0.14,0.04,0.21,0.07
	c0.06,0.22,0.12,0.44,0.18,0.66c0.36-0.48,0.42-0.89-0.62-1.03c0.28-0.2,0.46-0.34,0.67-0.49c0.11,0.13,0.18,0.21,0.28,0.32
	c0.05-0.08,0.1-0.15,0.15-0.23l0.07,0.07l5.77,7.99c-0.04,0.12-0.07,0.24-0.1,0.37c0.75,0.3-0.45,0.74-0.31,1.22
	c0.37-0.27,0.71-0.52,1.02-0.74l0.72,0.99c-0.06,0.3-0.03,0.6,0.18,0.9c0.18,0,0.34,0,0.47,0l1.78,2.46l0.3-6.55
	c0.19-0.34,0.41-0.31,0.58-0.02c0.1-0.67,0.19-1.27,0.28-1.86c0.09-0.62,0.29-1.25,0.23-1.87c-0.05-0.6-0.39-1.18-0.55-1.78
	c-0.04-0.16,0.1-0.34,0.2-0.51c0.15-0.25,0.37-0.48,0.49-0.73c0.13-0.27,0.17-0.56,0.25-0.84c-0.19-0.01-0.46-0.02-0.64-0.03
	c0.11-0.73,0.15-1.42,0.34-2.09c0.07-0.24,0.5-0.43,0.77-0.65c0.11-0.09,0.29-0.18,0.41-0.26c-0.16,0.21-0.17,0.47,0.01,0.69
	c0.14,0.18,0.24,0.38,0.35,0.55c0.06-0.05,0.13-0.12,0.33-0.29c0.43,0.8,0.8,1.48,1.17,2.17c0.11-0.04,0.22-0.07,0.32-0.11
	c-0.33-0.6-0.68-1.19-0.99-1.8c-0.08-0.17-0.11-0.4,0-0.54c0.59-0.76,0.68-1.56,0.37-2.39c-0.22,0.16-0.39,0.38-0.29,0.49
	c0.53,0.56-0.05,0.69-0.64,0.84c-0.2,0.05-0.36,0.13-0.49,0.23c0.01-0.01,0.01-0.03,0.01-0.04c0.04-0.56-0.01-1.13-0.08-1.69
	c-0.01-0.08-0.15-0.15-0.19-0.16c0.14-0.51,0.16-0.99,0.13-1.46c0.15,0.22,0.3,0.44,0.45,0.66c0.09-0.27,0.18-0.49,0.25-0.7
	c0.03,0.19,0.19,0.39,0.29,0.59c0.14-0.02,0.27-0.04,0.41-0.07c0-0.81,0-1.62,0-2.44c-0.11,0.11-0.22,0.23-0.37,0.39
	c-0.07-0.15-0.1-0.22-0.11-0.25c-0.01,0.06-0.02,0.13-0.03,0.19c-0.15-0.35-0.34-0.66-0.53-0.97c-0.14-0.24-0.27-0.65-0.53-0.58
	c-0.09,0.02-0.17,0.04-0.25,0.05c-0.03-0.2-0.04-0.39,0-0.57c0.1-0.47,0.47-0.92,0.74-1.42c0.17,0.16,0.27,0.25,0.37,0.35
	c0.1-0.02,0.2-0.04,0.3-0.07c-0.18-0.55-0.37-1.11-0.54-1.66c-0.03-0.1-0.08-0.26,0-0.31c0.98-0.65,0.39-1.49,0.57-2.23
	c0.03-0.14-0.28-0.31-0.43-0.47c-0.11,0.01-0.21,0.01-0.32,0.02c0.2-0.5,0.4-1,0.59-1.5c0.16-0.42,0.37-0.84,0.42-1.26
	c0.08-0.69,0.03-1.39,0.05-2.08c0-0.1,0.06-0.25,0.16-0.29c0.2-0.07,0.29-0.15,0.31-0.23c0.04,0.27,0.04,0.58,0.93,0.54
	c-0.09-0.61-0.18-1.19-0.27-1.78c-0.03-0.21-0.13-0.43-0.07-0.62c0.42-1.2-0.28-2.44,0.39-3.67c0.3-0.56-0.12-1.29-0.2-1.95
	c-0.03-0.29-0.04-0.59-0.01-0.88c0.08-0.7,0.2-1.4,0.31-2.18c-1.07,0.73-1.31,1.36-0.94,1.78c-0.16,0.19-0.38,0.39-0.52,0.54
	c0.14-0.51,0.27-1.02,0.37-1.54c0.07-0.36-0.06-0.74-0.14-1.1c-0.06-0.28-0.19-0.55-0.29-0.83c0.08-0.01,0.16-0.03,0.24-0.04
	c0.09,0.1,0.17,0.2,0.26,0.3c-0.03-0.35-0.34-0.65-0.37-0.95c-0.06-0.69,0.02-1.39,0.03-2.08c0-0.27,0-0.54-0.08-0.8
	c-0.05-0.15-0.26-0.28-0.4-0.42c0.29-0.07,0.58-0.14,1.07-0.26c0-0.92-0.06-1.91,0.02-2.9c0.04-0.46,0.07-1.09,0.42-1.38
	c0.1,0.17,0.19,0.33,0.29,0.51c-1.38,0.88-0.26,2.04-0.44,3.18c0.2-0.17,0.33-0.28,0.46-0.39c0.07,0.01,0.15,0.03,0.22,0.04
	c-0.09,0.43-0.18,0.85-0.27,1.28c-0.07,0-0.13,0-0.2,0c-0.07-0.13-0.14-0.26-0.21-0.39c0.17,0.5-0.05,0.97-0.09,1.47
	c-0.58,0.28-0.28,0.61,0.11,1c-0.01,0.51-0.2,1.05-0.28,1.61c0.24-0.08,0.54-0.17,0.75-0.24c-0.1,0.26-0.19,0.49-0.28,0.73
	c0.33-0.09,0.55-0.19,0.68-0.31c0.08,0.09,0.15,0.18,0.23,0.27c0.07-0.02,0.15-0.03,0.22-0.05c-0.14-0.52-0.23-1.04-0.42-1.54
	c-0.25-0.68-0.08-1.24,0.47-1.86c0.35-0.39,0.56-1.06,0.29-1.42c-0.36-0.48-0.29-0.87-0.16-1.31c0.1-0.35,0.32-0.7,0.3-1.05
	c-0.08-0.98-0.27-1.95-0.37-2.93c-0.02-0.17,0.22-0.35,0.3-0.46c-0.14-0.28-0.27-0.53-0.4-0.79c0.24-0.02,0.38-0.04,0.55-0.05
	c-0.77-0.97-0.75-1.15,0.05-1.28c-0.37-0.55-0.41-1.08,0.03-1.63c0.15-0.19,0.02-0.49,0.02-0.92
	C566.31,595.37,566.2,595.51,566.08,595.64z M479.6,633.08c0-0.01,0-0.02,0-0.03c0.01,0.01,0.01,0.01,0.02,0.02
	C479.61,633.07,479.61,633.08,479.6,633.08z M490.54,550.9c0.17,0.27,0.48,0.53,0.5,0.8c0.06,1.05,0.02,2.09,0.02,3.14
	c-0.1,0-0.2,0-0.3,0c-0.12-1.27-0.25-2.55-0.37-3.82C490.44,550.98,490.49,550.94,490.54,550.9z M477.84,570.02
	c0.02,0.18,0.05,0.36,0.07,0.54C477.8,570.41,477.8,570.22,477.84,570.02z M480.42,620.78c-0.07,0-0.15,0.01-0.22,0.01
	c-0.13-0.33-0.26-0.67-0.38-0.98c1.01,0.06,0.36-0.45,0.51-0.66c0.19-0.27,0.27-0.58,0.4-0.88c0.1,0.01,0.2,0.03,0.3,0.04
	C480.83,619.14,480.62,619.96,480.42,620.78z M480.54,610c0.34,0.35,0.61,0.69,0.15,1.12c-0.13,0.12-0.04,0.36,0.03,0.54
	c0.15,0.43,0.34,0.85,0.42,1.3C479.7,611.36,479.57,610.61,480.54,610z M484.53,632.33c-0.98-0.82-0.1-1.52,0.16-2.25
	C484.63,630.83,484.58,631.58,484.53,632.33z M485.14,618.82c0.1-0.44,0.18-0.82,0.26-1.19c0.06,0,0.11,0,0.17,0
	c0.28,0.74,0.56,1.48,0.83,2.23c-0.15,0.02-0.29,0.04-0.44,0.06c-0.05-0.38-0.1-0.76-0.15-1.17
	C485.68,618.76,485.49,618.78,485.14,618.82z M485.73,620.46c0.87,0.19,0.97,0.5,0.55,1.64C486.07,621.49,485.91,621,485.73,620.46z
	 M486.54,634.72c0.04-0.47,0.08-0.95,0.12-1.42c0.14,0,0.29,0,0.43,0c-0.07,0.48-0.13,0.96-0.2,1.44
	C486.78,634.74,486.66,634.73,486.54,634.72z M486.89,645.95c0-1,0-2,0-3c0.06,0,0.13,0,0.19,0c0.05,0.29,0.15,0.58,0.14,0.86
	C487.21,644.53,487.13,645.24,486.89,645.95z M487.37,626.55c-0.18,0.73-0.54,1.45,0.07,2.17c-0.18-0.09-0.37-0.17-0.55-0.26
	c-0.05,0.21-0.18,0.42-0.14,0.62c0.02,0.13,0.28,0.24,0.46,0.38c-0.13,0.06-0.25,0.11-0.38,0.17c-0.49-0.52-0.71-1.02-0.07-1.54
	c0.08-0.07,0.12-0.2,0.08-0.28c-0.47-1.01-0.44-2,0.03-3.01c0.13-0.28,0.11-0.59,0.16-0.88c0.1-0.01,0.19-0.02,0.29-0.02
	c0.11,0.24,0.32,0.48,0.31,0.72C487.61,625.25,487.53,625.91,487.37,626.55z M494.69,616.76c0.06-0.23,0.13-0.45,0.19-0.68
	c0.72,0.65,0.14,1.32,0.17,1.98c0.01,0.13-0.01,0.27,0.08,0.37c0.7,0.79,0.54,1.65,0.28,2.45c-0.2,0.61-0.22,1.13,0.01,1.71
	c0.06,0.14-0.19,0.34-0.28,0.49c0.05,0.26,0.08,0.52,0.14,0.79c-0.05-0.09-0.14-0.19-0.2-0.28c-0.19-0.33-0.35-0.67-0.57-0.99
	c-0.11-0.16-0.32-0.3-0.47-0.45c-0.22-0.22-0.42-0.44-0.73-0.75c1.2-0.02,1.99-0.88,1.78-1.64c-0.13,0.14-0.24,0.25-0.4,0.42
	c-0.18-0.54-0.32-0.98-0.49-1.48c-0.21,0.15-0.31,0.23-0.41,0.31c-0.09-0.02-0.18-0.03-0.26-0.05c0.1-0.75,0.19-1.5,0.29-2.25
	c0.12,0.01,0.24,0.01,0.36,0.02c0,0.25,0,0.5,0,0.75c0.08,0.01,0.15,0.02,0.23,0.03C494.5,617.25,494.62,617.01,494.69,616.76z
	 M494.65,596.02c-0.02-0.38,0.03-0.76,0.05-1.15c0.09,0.02,0.18,0.03,0.27,0.05c0.08,0.38,0.16,0.77,0.26,1.25
	C494.96,596.11,494.65,596.07,494.65,596.02z M495.8,601.75c-0.06-0.24-0.14-0.47-0.2-0.7C495.95,601.26,495.87,601.51,495.8,601.75
	z M495.89,598.81c-0.01,0.33-0.15,0.66-0.22,0.98c-0.04,0-0.07,0-0.11,0c0.09-0.58,0.23-1.16,0.36-1.74
	C495.91,598.3,495.9,598.55,495.89,598.81z M495.93,595.26c0.02,0.34,0.02,0.68,0.02,1.02
	C495.68,595.94,495.75,595.62,495.93,595.26z M559.15,548.67c0.17,0.27,0.48,0.53,0.5,0.8c0.06,1.05,0.02,2.09,0.02,3.14
	c-0.1,0-0.2,0-0.3,0c-0.12-1.27-0.25-2.55-0.37-3.82C559.05,548.74,559.1,548.71,559.15,548.67z M548.94,616.92
	c0.19-0.27,0.27-0.58,0.4-0.88c0.1,0.01,0.2,0.03,0.3,0.04c-0.19,0.76-0.38,1.52-0.57,2.29c-0.16-0.11-0.32-0.21-0.46-0.31
	c-0.06-0.16-0.13-0.33-0.19-0.48C549.45,617.63,548.79,617.13,548.94,616.92z M549.15,607.77c0.34,0.35,0.61,0.69,0.15,1.12
	c-0.13,0.12-0.04,0.36,0.03,0.54c0.15,0.43,0.34,0.85,0.42,1.3C548.31,609.13,548.18,608.37,549.15,607.77z M536.91,603.4
	c0.16,0.18,0.33,0.36,0.49,0.54c0.07-0.06,0.15-0.12,0.22-0.18c-0.08-0.26-0.13-0.54-0.23-0.77c-0.09-0.22-0.19-0.43-0.28-0.65
	c1.06-0.16,0.99,0.82,1.45,1.27c0.09,0.09,0.17,0.2,0.32,0.19c1.13-0.05,1.56,0.71,1.85,1.5c0.22,0.61,0.55,1,1.13,1.2
	c0.14,0.05,0.06,0.41,0.08,0.6c0.21,0.15,0.41,0.3,0.64,0.44c-0.11-0.02-0.24-0.01-0.35-0.03c-0.38-0.07-0.74-0.16-1.14-0.2
	c-0.21-0.02-0.47,0.07-0.71,0.09c-0.33,0.04-0.66,0.06-1.13,0.1c1.03-1.06,1.15-2.38,0.47-2.73c-0.02,0.21-0.05,0.38-0.08,0.65
	c-0.51-0.23-0.93-0.41-1.4-0.63c-0.08,0.29-0.12,0.44-0.16,0.58c-0.09,0.07-0.17,0.13-0.26,0.2c-0.41-0.62-0.82-1.24-1.23-1.86
	C536.69,603.6,536.8,603.5,536.91,603.4z M528.53,591.34c-0.21-0.12-0.44-0.22-0.64-0.33C528.36,590.87,528.45,591.11,528.53,591.34
	z M526.69,589.17c0.21,0.24,0.3,0.59,0.44,0.88c-0.03,0.03-0.07,0.06-0.1,0.09c-0.3-0.49-0.55-1.02-0.81-1.54
	C526.37,588.8,526.53,588.99,526.69,589.17z M525.1,587.33c-0.48-0.02-0.62-0.31-0.69-0.71
	C524.65,586.85,524.88,587.08,525.1,587.33z M523.29,587.17c0.33,0.21,0.65,0.42,1.07,0.68c-0.29,0.17-0.61,0.39-0.64,0.36
	c-0.27-0.26-0.48-0.57-0.71-0.86C523.1,587.29,523.19,587.23,523.29,587.17z M547.64,642.55
	C547.64,642.55,547.64,642.55,547.64,642.55c-0.49-0.4-1.4-0.37-0.87-1.76l0.94,1.01c-0.06,0.19-0.12,0.39-0.12,0.58
	C547.59,642.43,547.61,642.49,547.64,642.55z M563.01,615.25c0.1-0.24,0.22-0.48,0.29-0.73c0.06-0.23,0.13-0.45,0.19-0.68
	c0.72,0.65,0.14,1.32,0.17,1.98c0.01,0.13-0.01,0.27,0.08,0.37c0.7,0.79,0.54,1.65,0.28,2.45c-0.2,0.61-0.22,1.13,0.01,1.71
	c0.06,0.14-0.19,0.34-0.28,0.49c0.05,0.26,0.08,0.52,0.14,0.79c-0.05-0.09-0.14-0.19-0.2-0.28c-0.19-0.33-0.35-0.67-0.57-0.99
	c-0.11-0.16-0.32-0.3-0.47-0.45c-0.13-0.13-0.25-0.26-0.39-0.41l0.05-0.39c0.97-0.17,1.58-0.93,1.39-1.6
	c-0.13,0.14-0.24,0.25-0.4,0.42c-0.18-0.54-0.32-0.98-0.49-1.48c-0.06,0.05-0.11,0.09-0.16,0.12l0.18-1.33
	C562.88,615.24,562.95,615.25,563.01,615.25z M554.58,617.67c-0.05-0.38-0.1-0.76-0.15-1.17c-0.14,0.02-0.33,0.04-0.68,0.09
	c0.1-0.44,0.18-0.82,0.26-1.19c0.06,0,0.11,0,0.17,0c0.24,0.64,0.48,1.28,0.72,1.93C554.76,617.46,554.65,617.58,554.58,617.67z
	 M563.26,593.78c-0.02-0.38,0.02-0.76,0.05-1.15c0.09,0.02,0.18,0.03,0.27,0.05c0.08,0.38,0.16,0.77,0.26,1.25
	C563.58,593.87,563.27,593.83,563.26,593.78z M564.36,560.07c-0.09-0.03-0.16-0.06-0.21-0.1C564.22,560,564.29,560.04,564.36,560.07
	z M564.41,599.51c-0.06-0.24-0.14-0.47-0.2-0.7C564.57,599.02,564.49,599.27,564.41,599.51z M564.51,596.57
	c-0.01,0.33-0.15,0.66-0.22,0.98c-0.04,0-0.07,0-0.11,0c0.09-0.58,0.23-1.16,0.36-1.74C564.52,596.06,564.51,596.32,564.51,596.57z
	 M564.55,593.03c0.02,0.34,0.02,0.68,0.02,1.02C564.3,593.7,564.37,593.38,564.55,593.03z"
        />
        <path
            fill={textColor}
            d="M560.36,650.87c-0.14,0.83-0.28,1.66-0.4,2.49c-0.2,1.35-0.35,2.7-0.51,4c0.33-0.16,0.6-0.26,0.82-0.4
	c0.23-0.14,0.53-0.28,0.6-0.46c0.17-0.44,0.23-0.89,0.31-1.34c0.04-0.27,0.2-0.64,0-0.8c-0.93-0.78-0.07-1.46,0.16-2.18
	c0.1,0.26,0.19,0.52,0.34,0.92c0.97-1.1,0.03-2.08,0.29-3.07c-0.27,0.07-0.41,0.11-0.54,0.14c-0.06-0.05-0.12-0.1-0.18-0.15
	c0.3-0.21,0.6-0.41,0.96-0.66c0.03-0.31,0.07-0.74,0.11-1.17c-0.03,0-0.06,0-0.09,0c-0.14,0.3-0.27,0.59-0.41,0.89
	c-0.12-0.02-0.23-0.04-0.35-0.06c-0.21-1.16,0.71-2.15,1.51-3.15c-0.08-0.02-0.15-0.05-0.23-0.07c-0.22,0.13-0.45,0.25-0.69,0.39
	c-0.35-1.06-0.07-1.65,0.84-1.74c-0.18-0.77-0.37-1.55-0.56-2.34c-0.24,0.76-0.46,1.49-0.69,2.21c-0.04,0.14-0.12,0.28-0.15,0.42
	c-0.12,0.57-0.41,1.16-0.3,1.71c0.14,0.71,0.2,1.37-0.31,2.01c-0.09,0.11-0.18,0.23-0.2,0.35c-0.12,0.68-0.23,1.35-0.35,2.03
	c-0.01-0.02-0.02-0.04-0.03-0.05c-0.01,0.04-0.01,0.08-0.02,0.12C560.34,650.89,560.35,650.88,560.36,650.87z"
        />
        <path
            fill={textColor}
            d="M546.83,551.3c-0.07-0.02-0.13-0.05-0.2-0.07c-0.26,0.21-0.51,0.42-0.83,0.69c-0.38-0.7,0.61-1.01,0.73-1.54
	c-0.29,0.11-0.54,0.25-0.7,0.42c-0.52,0.58-0.98,1.18-1.52,1.75c-0.16,0.17-0.49,0.27-0.74,0.41c-0.08-0.03-0.17-0.07-0.25-0.1
	c-0.11,0.51-0.32,1.02-0.3,1.53c0.06,1.63,0.28,3.25,0.3,4.88c0.03,1.62,1.13,3.16,0.69,4.8c0,0.02,0.08,0.04,0.29,0.14
	c0.04-0.43,0.08-0.8,0.11-1.17c0.05,0,0.1,0,0.15,0c0.29,0.83,0.58,1.65,0.87,2.48c0.08-0.01,0.16-0.02,0.24-0.02
	c-0.22-1.88-1.68-3.66-0.8-5.62c-0.12,0.01-0.25,0.01-0.37,0.02c0,0.29,0,0.58,0,0.87c-0.1,0.01-0.2,0.01-0.3,0.02
	c-0.08-0.61-0.22-1.22-0.21-1.83c0.01-0.35,0.28-0.7,0.41-1.06c0.18-0.49,0.36-0.98,0.49-1.47c0.03-0.1-0.16-0.22-0.24-0.33
	c-0.08-0.11-0.23-0.24-0.21-0.34c0.22-0.91,0.45-1.82,0.74-2.72c0.04-0.11,0.43-0.17,0.66-0.25c0.06,0.04,0.12,0.08,0.18,0.12
	C546.31,552.37,546.57,551.84,546.83,551.3z"
        />
        <path
            fill={textColor}
            d="M546.16,573.14c-0.43,0.24-0.77,0.46-1.01,0.66c0.01-0.16,0.02-0.33,0.03-0.5c-0.13,0-0.26,0-0.39,0c0,0.29,0,0.58,0,0.87
	c-0.24,0.37-0.11,0.71,0.39,1.11c0.76,0.6,0.8,1.25,0.84,1.93c0.11,1.76,0.22,3.51,0.33,5.27c0.11,0,0.21,0.01,0.32,0.01
	c0.06-0.62,0.19-1.23,0.16-1.85c-0.09-1.51-0.26-3.02-0.39-4.54c-0.02-0.27-0.08-0.59,0.09-0.81
	C547.15,574.51,546.48,573.86,546.16,573.14z"
        />
        <path
            fill={textColor}
            d="M545.36,583.02c0.23,1.12,0.46,2.28,0.7,3.44c0.08,0,0.16,0.01,0.24,0.01c0.04-0.59,0.09-1.19,0.13-1.84
	c0.28,0.11,0.45,0.17,0.64,0.24C547.14,584.16,546.47,583.47,545.36,583.02z"
        />
        <path
            fill={textColor}
            d="M549.04,648.64c-0.18,0.01-0.35,0.02-0.43,0.03c-0.15,1.08-0.29,2.12-0.43,3.17c0.12,0,0.24,0.01,0.36,0.01
	c0.36-1.65,0.71-3.31,1.07-4.96c-0.13-0.01-0.26-0.02-0.39-0.04C548.67,647.4,549.15,648.05,549.04,648.64z"
        />
        <path
            fill={textColor}
            d="M565.35,625.27c-0.03-0.22-0.06-0.44-0.11-0.8c-0.31,0.34-0.5,0.56-0.72,0.8c-0.22-0.2-0.38-0.35-0.64-0.59
	c-0.16,0.66-0.46,1.21-0.36,1.73c0.1,0.51,0.61,0.99,0.94,1.5c0-0.21,0.04-0.47-0.01-0.71c-0.06-0.27-0.26-0.52-0.33-0.79
	C563.99,625.89,564.72,625.22,565.35,625.27z"
        />
        <path
            fill={textColor}
            d="M544.54,566.19c-0.11,0.01-0.22,0.02-0.34,0.03c0.13,0.77,0.27,1.54,0.4,2.3c0.1,0,0.2,0,0.3,0
	c0.05-0.2,0.11-0.39,0.18-0.65c0.22,0.16,0.37,0.26,0.52,0.37c0.07-0.02,0.14-0.04,0.22-0.05c-0.06-0.14-0.07-0.37-0.18-0.39
	c-0.94-0.17-1.02-0.64-0.99-1.17C544.66,566.48,544.58,566.34,544.54,566.19z"
        />
        <path
            fill={textColor}
            d="M545.59,588.24c-0.06,0.64-0.13,1.28-0.19,1.92c0.1,0.01,0.2,0.01,0.3,0.02c0.89-0.57,0.05-1.29,0.33-1.91
	C545.88,588.26,545.73,588.25,545.59,588.24z"
        />
        <path
            fill={textColor}
            d="M544.86,570.51c0.28,0.69,0.57,1.38,0.84,2.04C545.81,571.21,545.63,570.76,544.86,570.51z"
        />
        <path
            fill={textColor}
            d="M541.01,424.9c0.14,1.28,0.2,2.55,0.23,3.81c0.03,1.78-0.16,3.55,0.04,5.35c0.28,2.57,0.83,5.15,1.25,7.71
	c0.22,1.35,0.43,2.7,0.64,4.05c0.01,0.06,0,0.13-0.01,0.19c-0.08,0.89-0.19,1.77-0.23,2.66c-0.03,0.74,0.14,1.5,0.02,2.23
	c-0.2,1.12,0.63,2.26,0.17,3.38c-0.22,0.54,0.2,1.17,0.09,1.74c-0.24,1.23,0.64,2.49,0.05,3.7c-0.02,0.04,0.02,0.09,0.02,0.13
	c0,1.06,0.7,2.14-0.04,3.19c-0.13,0.19-0.05,0.43-0.03,0.64c0.07,0.93,0.16,1.85,0.21,2.78c0.05,0.84,0.07,1.69,0.07,2.53
	c-0.01,0.92-0.08,1.83-0.09,2.75c-0.01,0.46,0.1,0.91,0.07,1.37c-0.08,1.14-0.21,2.29-0.33,3.53c0.23-0.17,0.35-0.25,0.46-0.34
	c0.12,0.03,0.23,0.05,0.35,0.08c-0.17,0.27-0.35,0.55-0.51,0.83c-0.08,0.13-0.19,0.27-0.22,0.4c-0.32,1.78,0.24,3.54-0.2,5.33
	c-0.16,0.65,0.14,1.33,0.2,1.99c0.03,0.28,0,0.56-0.05,0.84c-0.14,0.84-0.3,1.68-0.44,2.52c-0.01,0.05,0.06,0.11,0.11,0.2
	c0.22-0.16,0.4-0.28,0.61-0.42c0.62,0.57,0.24,1.11-0.64,1.17c-0.9,1.16,1.27,1.88,0.85,2.98c-0.01,0.01,0.11,0.03,0.36,0.11
	c0-0.45,0.15-0.86-0.03-1.2c-0.56-1.04-0.36-1.97,1.7-2.83c-0.31,1.04-0.59,1.98-0.86,2.92c-0.05,0.17-0.19,0.44-0.08,0.48
	c0.97,0.31,0.39,0.78,0.28,1.2c-0.07,0.26-0.45,0.6-0.28,0.75c0.8,0.68-0.48,1.32-0.14,1.97c-0.16,0.02-0.32,0.03-0.48,0.05
	c-0.3-0.74-0.59-1.48-0.85-2.12c-0.22,0.23-0.56,0.57-0.9,0.92c0.07-0.01,0.15-0.01,0.22-0.02c0,0.46,0,0.93,0,1.43
	c0.01,0,0.28-0.01,0.39,0.06c0.47,0.29,0.28,2.19-0.32,2.46c-1.1,0.5-0.89,1.05-0.67,1.63c0.22-0.23,0.43-0.46,0.65-0.69
	c0.11-0.01,0.22-0.02,0.33-0.04c0.18,0.55,0.39,1.1,0.51,1.66c0.01,0.05-0.2,0.14-0.48,0.26c-0.22-0.27-0.43-0.54-0.66-0.83
	c-0.14,0.9-0.27,1.7-0.4,2.5c0.12,0.01,0.23,0.01,0.35,0.02c0.59-0.51,1.18-1.02,1.83-1.57c0.4,1.01-0.02,3.16-0.74,3.36
	c0.23,0.75,0.91,1.51,0.58,2.22c-0.43,0.91-0.33,1.73-0.41,2.59c-0.04,0.4-0.42,0.83-0.51,1.25c-0.06,0.25,0.13,0.5,0.21,0.74
	c0.07,0.38-0.27,0.85-0.27,1.27c0,0.32,0.68,0.57,0.64,0.89c-0.08,0.85-0.35,1.71-1.51,2.52c-0.2-0.41-0.39-0.79-0.58-1.18
	c-0.07,0.54-0.19,1.02,0.27,1.51c0.49,0.52,0.09,1.38,0.07,2.09c-0.02,0.66-0.27,1.39,1.09,1.89c0.08-0.4,0.19-0.65,0.16-0.86
	c-0.09-0.63-0.79-1.23,0.59-1.9c0.11-0.05,0.11-0.21,0.12-0.32c0.09-0.57,0.13-1.13,0.29-1.69c0.06-0.21,0.39-0.44,0.59-0.65
	c0.09,0.01,0.17,0.02,0.26,0.04c0.07,0.22,0.15,0.43,0.22,0.65c0.45-0.54,0.52-0.97-0.77-0.96c0.34-0.25,0.57-0.41,0.82-0.59
	c0.14,0.11,0.23,0.19,0.34,0.29c0.18-0.24,0.32-0.43,0.58-0.79c0.07,0.39,0.11,0.61,0.15,0.82c0.11-0.01,0.22-0.03,0.34-0.04
	c0.23-1.42,0.43-2.83,0.72-4.24c0.1-0.49,0-0.88-0.29-1.31c-0.23-0.34-0.18-0.96,0.25-1.25c0.65-0.45,0.81-0.81,0.72-1.3
	c-0.2,0.3-0.36,0.55-0.62,0.94c-0.87-0.88,0.05-1.64,0.49-2.38c0.25,0.33,0.51,0.66,0.88,1.15c0.04-1.09-0.64-1.86-0.35-2.85
	c0.68-2.32,0.52-2.31,1.41-2.5c0,0.63-0.01,1.26,0,1.89c0.04,1.38,0.09,2.77,0.14,4.15c0,0.02,0,0.05,0.02,0.06
	c1,0.3,0.38,0.83,0.18,1.3c-0.08,0.2-0.12,0.41-0.18,0.64c0.44-0.09,0.75-0.15,1.06-0.21c0.1-0.8,0.2-1.6,0.31-2.39
	c0.12-0.01,0.23-0.02,0.35-0.02c0.17,0.25,1.56,0.25,0.26,0.81c-0.08,0.03-0.04,0.16-0.04,0.24c0.03,0.46,0.05,0.91,0.09,1.36
	c0.05,0.56,0.1,1.11-1.18,1.33c0.2,0.24,0.29,0.61,0.53,0.61c1.2,0.02,0.85,0.48,0.74,0.89c-0.1,0.39-0.19,0.77-0.24,1.16
	c-0.05,0.34-0.07,0.69-0.03,1.02c0.05,0.47,0.21,0.92,0.23,1.39c0.02,0.42-0.09,0.85-0.14,1.27c-0.14,0.17,0.03,0.31,0.27,0.42
	c1.54,0.67,1.33,1.74,0.95,2.8c-0.16,0.44-0.6,0.83-0.1,1.24c0.24,0.19-0.1,0.64-0.21,1.14c0.93,0.18-0.56,0.82-0.39,1.27
	c0.47-0.34,0.91-0.65,1.3-0.93c-0.63-0.63,0.05-1.44,0.3-2.37c0.16,0.28,0.22,0.39,0.29,0.51c0.84-0.4,0.9,0.09,0.91,0.23
	c0.02,0.5,0,1.04-0.25,1.56c-0.32,0.66-0.7,1.3-0.18,1.81c0.37-0.05,0.67-0.1,0.81-0.12c0.23-0.53,0.41-1.06,0.69-1.58
	c0.38-0.73,1.67-1.37,1.54-2.15c-0.03-0.19-0.03-0.41,0.09-0.61c0.25-0.39,0.52-0.4,0.74-0.14c0.13-0.67,0.24-1.27,0.35-1.87
	c0.11-0.62,0.36-1.27,0.29-1.87c-0.07-0.58-0.48-1.1-0.68-1.66c-0.05-0.15,0.12-0.35,0.24-0.52c0.18-0.26,0.45-0.52,0.6-0.79
	c0.16-0.28,0.21-0.57,0.31-0.86c-0.24,0.02-0.57,0.04-0.79,0.06c0.13-0.73,0.18-1.41,0.42-2.09c0.08-0.24,0.62-0.49,0.95-0.73
	c0.14-0.1,0.35-0.21,0.51-0.31c-0.19,0.23-0.21,0.48,0.01,0.67c0.18,0.16,0.29,0.34,0.43,0.49c0.07-0.06,0.16-0.13,0.4-0.32
	c0.54,0.72,0.99,1.34,1.45,1.95c0.13-0.05,0.27-0.1,0.4-0.15c-0.41-0.53-0.85-1.06-1.22-1.61c-0.1-0.15-0.14-0.37,0-0.53
	c0.73-0.82,0.84-1.6,0.46-2.37c-0.27,0.18-0.48,0.42-0.35,0.52c0.65,0.47-0.06,0.68-0.79,0.9c-0.25,0.07-0.45,0.17-0.6,0.29
	c0.01-0.01,0.02-0.03,0.02-0.04c0.05-0.55-0.02-1.1-0.1-1.64c-0.01-0.08-0.19-0.13-0.24-0.13c0.38-1.12,0.03-2.03,0-3.01
	c0.19-0.01,0.39-0.01,0.6-0.02c0.02-0.32,0.02-0.32-0.77-0.53c-0.1-0.58-0.33-1.02-0.21-1.46c0.13-0.47,0.58-0.94,0.92-1.46
	c0.21,0.14,0.33,0.22,0.46,0.3c0.12-0.03,0.25-0.06,0.37-0.09c-0.22-0.52-0.45-1.04-0.67-1.56c-0.04-0.1-0.1-0.24-0.01-0.3
	c1.22-0.73,0.49-1.48,0.7-2.22c0.04-0.14-0.34-0.28-0.53-0.42c-0.13,0.01-0.26,0.03-0.39,0.05c0.24-0.5,0.49-1.01,0.73-1.51
	c0.19-0.42,0.46-0.84,0.52-1.26c0.09-0.68,0.04-1.35,0.06-2.02c0-0.1,0.07-0.24,0.2-0.29c0.25-0.08,0.36-0.16,0.39-0.24
	c0.05,0.26,0.05,0.56,1.16,0.46c-0.11-0.58-0.22-1.14-0.33-1.7c-0.04-0.2-0.17-0.41-0.08-0.6c0.52-1.19-0.35-2.35,0.48-3.56
	c0.38-0.55-0.15-1.24-0.24-1.88c-0.04-0.28-0.05-0.57-0.01-0.85c0.1-0.68,0.24-1.36,0.38-2.12c-1.32,0.73-1.61,1.36-1.16,1.75
	c-0.2,0.19-0.47,0.39-0.64,0.54c0.17-0.5,0.34-1,0.46-1.5c0.08-0.35-0.07-0.71-0.17-1.06c-0.08-0.27-0.24-0.53-0.36-0.79
	c0.1-0.02,0.2-0.03,0.3-0.04c0.11,0.09,0.21,0.19,0.32,0.28c-0.03-0.34-0.42-0.62-0.45-0.92c-0.08-0.67,0.03-1.35,0.04-2.02
	c0-0.26,0-0.53-0.1-0.78c-0.06-0.15-0.32-0.27-0.5-0.4c0.36-0.07,0.72-0.14,1.32-0.26c0-0.89-0.07-1.85,0.03-2.81
	c0.05-0.44,0.08-1.05,0.52-1.34c0.12,0.16,0.24,0.32,0.36,0.49c-1.7,0.85-0.32,1.97-0.55,3.08c0.24-0.17,0.41-0.28,0.57-0.39
	c0.09,0.01,0.18,0.03,0.27,0.04c-0.11,0.41-0.22,0.82-0.33,1.24c-0.08,0-0.16,0-0.24,0.01c-0.09-0.12-0.17-0.25-0.26-0.37
	c0.21,0.48-0.06,0.94-0.12,1.42c-0.71,0.28-0.35,0.6,0.13,0.97c-0.01,0.49-0.25,1.02-0.34,1.57c0.3-0.08,0.67-0.18,0.93-0.25
	c-0.12,0.25-0.23,0.48-0.34,0.71c0.41-0.1,0.68-0.2,0.84-0.32c0.1,0.09,0.19,0.17,0.28,0.26c0.09-0.02,0.18-0.03,0.28-0.05
	c-0.17-0.49-0.29-0.99-0.52-1.48c-0.31-0.65-0.09-1.2,0.58-1.8c0.43-0.38,0.7-1.02,0.36-1.37c-0.44-0.46-0.36-0.83-0.2-1.26
	c0.12-0.34,0.4-0.68,0.37-1.01c-0.09-0.94-0.33-1.87-0.45-2.82c-0.02-0.16,0.28-0.33,0.37-0.44c-0.18-0.27-0.33-0.51-0.5-0.76
	c0.29-0.02,0.47-0.03,0.69-0.04c-0.95-0.94-0.93-1.11,0.06-1.23c-0.46-0.53-0.51-1.04,0.04-1.57c0.19-0.18,0.03-0.47,0.03-0.88
	c-0.31,0.29-0.45,0.42-0.59,0.55c-0.17-0.23-0.1-0.43,0.01-0.61c0.13-0.23,0.46-0.45,0.43-0.67c-0.13-0.92-0.39-1.85-0.56-2.77
	c-0.11-0.61-0.25-1.24-0.19-1.84c0.07-0.72,0.36-1.42,0.5-2.13c0.06-0.3-0.07-0.61-0.04-0.91c0.08-0.81,0.24-1.61,0.29-2.42
	c0.01-0.16-0.33-0.34-0.49-0.5c0.12-0.44,0.33-0.9,0.33-1.37c0-0.47-0.19-0.93-0.57-1.43c-0.43-0.56-0.07-1.24-0.04-1.87
	c0.04-0.82,0.16-1.63,0.13-2.45c-0.03-0.75-0.26-1.51-0.32-2.26c-0.14-1.66,0.04-3.29-0.66-5c-0.28-0.68,0-1.38-0.03-2.07
	c-0.01-0.29-0.12-0.6-0.23-0.89c-0.11-0.29-0.42-0.59-0.4-0.86c0.04-0.51,0.25-1,0.39-1.49c-0.06-0.01-0.13-0.02-0.19-0.03
	c-0.07,0.08-0.13,0.17-0.2,0.25c-0.22-0.52-0.18-0.98-0.23-1.45c-0.05-0.45-0.54-0.96,0.28-1.26c0.06-0.02,0.03-0.16-0.03-0.25
	c0.06,0.03,0.12,0.06,0.18,0.09c0.46-0.61,0.8-1.05,1.23-1.63c-0.54,0.08-0.77,0.11-0.81,0.12c-0.51-0.18-0.92-0.31-1.27-0.44
	c0.02-0.48,0.04-0.95,0.06-1.43c0.16-0.21,0.39-0.41,0.57-0.62c-0.51-0.21-0.49-0.21-0.35-0.62c0.01-0.04-0.06-0.11-0.16-0.18
	c0.07-2.17-0.01-4.33-0.82-6.55c-0.63-0.28-1.09-0.49-1.73-0.78c0,0.45,0.14,0.88-0.03,1.23c-0.29,0.56-0.81,1.06-1.19,1.61
	c-0.22,0.32-0.36,0.66-0.51,1.01c-0.05,0.12,0.09,0.36-0.03,0.38c-0.98,0.21-0.46,0.68-0.24,1.12c0.15,0.29,0.42,0.66,0.25,0.86
	c-0.05,0.07-0.1,0.13-0.14,0.2c-0.14-0.01-0.29-0.02-0.44-0.02c-0.13-1.33-0.29-2.65-0.36-3.96c-0.01-0.17,0.42-0.28,0.64-0.42
	c-0.09-0.01-0.18-0.01-0.27-0.02c-0.25-0.99-0.5-1.98-0.75-2.95c-0.98-0.35-1.49-0.14-1.75,0.27c-0.33,0.51-0.87,0.52-1.72,0.04
	c-0.14-0.08-0.35-0.13-0.64-0.23c-0.23,0.11-0.43,0.35-0.7,0.33c-1.6-0.11-2.62,0.38-3.43,0.85c-0.96,0.56-1.36,1.4-1.21,2.38
	c0.28,1.79,0.38,3.56,0.56,5.34c0.04,0.43-0.12,0.86,0.12,1.27c0.41,0.7,0.46,0.75-0.94,0.87c-0.04-0.22-0.1-0.44-0.11-0.64
	c-0.1-1.96-0.18-3.91-0.31-5.88c-0.07-1.06-0.17-2.12-0.39-3.19c-0.06-0.29-0.55-0.84-0.88-0.9c-0.95-0.16-2.07-0.72-2.9-0.23
	c-0.15-0.14-0.31-0.29-0.39-0.36c-0.28,0.64-0.55,1.29-0.85,1.93C541.6,421.48,540.81,423.08,541.01,424.9z M543.75,500.92
	c0-0.01,0-0.02,0.01-0.03c0.01,0,0.02,0.01,0.03,0.02C543.77,500.91,543.76,500.92,543.75,500.92z M563.79,469.05
	c-0.08-0.23-0.17-0.46-0.25-0.68C563.99,468.58,563.89,468.82,563.79,469.05z M563.96,462.78c0.02,0.33,0.03,0.66,0.03,0.99
	C563.65,463.43,563.74,463.12,563.96,462.78z M563.94,465.47c-0.01,0.25-0.02,0.49-0.03,0.74c-0.01,0.32-0.18,0.63-0.28,0.95
	c-0.05,0-0.09,0-0.14,0C563.61,466.59,563.78,466.03,563.94,465.47z M563.73,430.88c-0.11-0.04-0.19-0.08-0.25-0.13
	C563.56,430.8,563.64,430.84,563.73,430.88z M562.43,462.36c0.11,0.02,0.22,0.04,0.33,0.06c0.1,0.37,0.2,0.75,0.32,1.22
	c-0.33-0.08-0.71-0.12-0.72-0.17C562.35,463.1,562.4,462.73,562.43,462.36z M562.91,486.49c-0.16,0.14-0.29,0.25-0.5,0.43
	c-0.22-0.51-0.4-0.93-0.61-1.41c-0.25,0.16-0.38,0.24-0.51,0.32c-0.11-0.01-0.22-0.02-0.33-0.03c0.12-0.74,0.24-1.47,0.36-2.2
	c0.15,0,0.3,0,0.45,0c0,0.24,0,0.49,0,0.73c0.1,0.01,0.19,0.01,0.29,0.02c0.12-0.24,0.27-0.48,0.35-0.72
	c0.08-0.22,0.16-0.45,0.23-0.67c0.89,0.6,0.17,1.28,0.21,1.91c0.01,0.12-0.01,0.26,0.1,0.36c0.87,0.73,0.67,1.58,0.34,2.36
	c-0.25,0.6-0.27,1.11,0.02,1.65c0.07,0.13-0.23,0.34-0.35,0.49c0.06,0.25,0.1,0.5,0.17,0.75c-0.07-0.09-0.18-0.17-0.24-0.26
	c-0.23-0.31-0.44-0.62-0.71-0.92c-0.14-0.15-0.39-0.27-0.58-0.41c-0.27-0.2-0.53-0.4-0.9-0.69
	C562.19,488.1,563.17,487.22,562.91,486.49z M557.29,418.9c0.22,0.29,0.6,0.61,0.62,0.87c0.07,1.04,0.03,2.06,0.03,3.1
	c-0.12-0.02-0.25-0.03-0.37-0.05c-0.15-1.28-0.31-2.55-0.46-3.83C557.17,418.96,557.23,418.93,557.29,418.9z M552.34,501.89
	c0.05-0.48,0.1-0.95,0.15-1.43c0.18-0.01,0.35-0.02,0.53-0.04c-0.08,0.48-0.16,0.97-0.24,1.45
	C552.63,501.88,552.49,501.89,552.34,501.89z M552.78,513.06c0-1,0-2,0-2.99c0.08-0.01,0.16-0.02,0.24-0.02
	c0.06,0.28,0.19,0.55,0.18,0.84C553.17,511.6,553.08,512.31,552.78,513.06z M552.77,491.95c0.16-0.29,0.14-0.59,0.2-0.89
	c0.12-0.01,0.24-0.03,0.35-0.04c0.14,0.23,0.4,0.46,0.38,0.69c-0.05,0.65-0.14,1.31-0.34,1.96c-0.22,0.74-0.67,1.49,0.09,2.15
	c-0.23-0.07-0.46-0.14-0.69-0.21c-0.07,0.21-0.22,0.44-0.17,0.63c0.03,0.13,0.35,0.21,0.57,0.34c-0.16,0.07-0.31,0.13-0.47,0.2
	c-0.6-0.48-0.88-0.96-0.08-1.53c0.1-0.07,0.15-0.21,0.1-0.29C552.15,493.99,552.19,492.99,552.77,491.95z M552.02,489.31
	c-0.26-0.61-0.46-1.09-0.68-1.62C552.42,487.83,552.54,488.14,552.02,489.31z M550.93,484.87c0.07,0,0.14-0.01,0.21-0.01
	c0.34,0.73,0.69,1.46,1.03,2.19c-0.18,0.03-0.36,0.05-0.54,0.08c-0.06-0.38-0.12-0.76-0.19-1.17c-0.18,0.02-0.41,0.06-0.84,0.12
	C550.73,485.63,550.83,485.25,550.93,484.87z M550.04,497.42c-0.06,0.76-0.13,1.51-0.19,2.27
	C548.63,498.96,549.72,498.17,550.04,497.42z M544.92,477.31c0.42,0.36,0.76,0.69,0.19,1.14c-0.16,0.12-0.04,0.37,0.03,0.55
	c0.18,0.43,0.42,0.86,0.52,1.31C543.87,478.72,543.71,477.95,544.92,477.31z M544.65,486.65c0.24-0.29,0.34-0.61,0.5-0.92
	c0.12,0.01,0.25,0.02,0.37,0.02c-0.25,0.85-0.51,1.7-0.76,2.55c-0.09,0.01-0.18,0.01-0.27,0.02c-0.16-0.33-0.32-0.67-0.47-0.98
	C545.28,487.35,544.47,486.87,544.65,486.65z"
        />
        <path
            fill={textColor}
            d="M558.78,519.4c-0.17,0.84-0.34,1.68-0.49,2.52c-0.25,1.36-0.43,2.71-0.63,4.02c0.41-0.22,0.74-0.37,1.02-0.54
	c0.28-0.18,0.66-0.37,0.74-0.56c0.21-0.46,0.29-0.92,0.38-1.37c0.05-0.27,0.24-0.66,0-0.79c-1.15-0.6-0.08-1.42,0.2-2.16
	c0.12,0.24,0.24,0.47,0.42,0.84c1.2-1.25,0.04-2.04,0.36-3.05c-0.34,0.11-0.5,0.17-0.67,0.23c-0.07-0.04-0.15-0.08-0.22-0.11
	c0.37-0.25,0.74-0.5,1.19-0.8c0.04-0.31,0.09-0.73,0.14-1.16c-0.04,0.01-0.08,0.01-0.12,0.02c-0.17,0.31-0.34,0.62-0.5,0.93
	c-0.14,0-0.28-0.01-0.43-0.01c-0.26-1.1,0.88-2.21,1.86-3.3c-0.09-0.01-0.19-0.02-0.28-0.03c-0.28,0.16-0.55,0.31-0.85,0.48
	c-0.43-0.99-0.08-1.6,1.04-1.82c-0.23-0.73-0.46-1.45-0.69-2.2c-0.29,0.77-0.57,1.51-0.85,2.25c-0.05,0.14-0.15,0.29-0.18,0.43
	c-0.15,0.58-0.51,1.2-0.37,1.72c0.18,0.67,0.25,1.3-0.39,2.02c-0.11,0.12-0.22,0.25-0.24,0.37c-0.14,0.68-0.29,1.36-0.43,2.04
	c-0.01-0.02-0.02-0.03-0.04-0.05c-0.01,0.04-0.01,0.08-0.02,0.12C558.75,519.42,558.77,519.41,558.78,519.4z"
        />
        <path
            fill={textColor}
            d="M538.56,432.1c-0.01,0.02,0.1,0.06,0.36,0.19c0.05-0.44,0.09-0.82,0.14-1.2c0.06,0.01,0.13,0.01,0.19,0.02
	c0.36,0.89,0.72,1.78,1.08,2.67c0.1,0,0.2,0,0.3,0.01c-0.27-1.96-2.08-4.01-0.99-5.9c-0.15-0.01-0.31-0.02-0.46-0.03
	c0,0.3,0,0.6,0,0.9c-0.12-0.01-0.25-0.02-0.37-0.03c-0.1-0.64-0.28-1.29-0.26-1.92c0.01-0.37,0.35-0.69,0.51-1.03
	c0.22-0.48,0.44-0.96,0.61-1.45c0.03-0.1-0.19-0.25-0.29-0.38c-0.1-0.13-0.29-0.28-0.26-0.39c0.27-0.91,0.56-1.81,0.92-2.69
	c0.04-0.11,0.53-0.1,0.81-0.15c0.07,0.05,0.15,0.11,0.22,0.16c0.32-0.51,0.65-1.01,0.97-1.51c-0.08-0.03-0.16-0.07-0.24-0.11
	c-0.32,0.17-0.63,0.34-1.03,0.56c-0.47-0.79,0.76-0.93,0.9-1.46c-0.36,0.06-0.67,0.16-0.86,0.31c-0.64,0.51-1.22,1.05-1.88,1.55
	c-0.2,0.15-0.6,0.2-0.92,0.3c-0.1-0.05-0.21-0.1-0.31-0.14c-0.13,0.51-0.4,1.01-0.37,1.54c0.08,1.7,0.34,3.42,0.38,5.12
	C537.74,428.71,539.11,430.46,538.56,432.1z"
        />
        <path
            fill={textColor}
            d="M541.21,441.72c-0.54,0.21-0.95,0.4-1.26,0.58c0.01-0.17,0.03-0.34,0.04-0.51c-0.16-0.01-0.32-0.03-0.48-0.04
	c0,0.3,0,0.6,0,0.9c-0.3,0.36-0.14,0.73,0.49,1.19c0.94,0.69,0.99,1.36,1.04,2.07c0.14,1.82,0.27,3.64,0.41,5.45
	c0.13,0.01,0.26,0.02,0.39,0.03c0.07-0.63,0.24-1.25,0.19-1.89c-0.11-1.56-0.33-3.13-0.48-4.69c-0.03-0.28-0.1-0.61,0.11-0.83
	C542.44,443.22,541.61,442.49,541.21,441.72z"
        />
        <path
            fill={textColor}
            d="M541.55,453.56c0.35,0.13,0.55,0.2,0.79,0.29c0.08-0.73-0.75-1.48-2.12-2.01c0.28,1.17,0.57,2.38,0.86,3.58
	c0.1,0.01,0.2,0.02,0.3,0.03C541.44,454.84,541.49,454.23,541.55,453.56z"
        />
        <path
            fill={textColor}
            d="M544.23,519.09c-0.18,1.13-0.35,2.21-0.53,3.3c0.15-0.02,0.3-0.03,0.45-0.05c0.44-1.75,0.88-3.49,1.32-5.22
	c-0.16,0.01-0.32,0.01-0.49,0.02c-0.67,0.64-0.07,1.22-0.22,1.85C544.55,519.03,544.34,519.07,544.23,519.09z"
        />
        <path
            fill={textColor}
            d="M564.96,493.9c-0.04-0.21-0.08-0.42-0.14-0.77c-0.38,0.35-0.62,0.58-0.89,0.83c-0.27-0.18-0.47-0.31-0.79-0.52
	c-0.2,0.65-0.57,1.21-0.45,1.7c0.12,0.49,0.75,0.91,1.16,1.37c0-0.2,0.06-0.45-0.01-0.68c-0.08-0.25-0.32-0.48-0.4-0.74
	C563.28,494.6,564.18,493.9,564.96,493.9z"
        />
        <path
            fill={textColor}
            d="M562.1,503.38c-0.09,0.55-0.24,1.13-0.27,1.7c-0.01,0.21,0.24,0.4,0.37,0.59c0.17-0.04,0.33-0.08,0.5-0.11
	c0-0.78,0-1.57,0-2.37c-0.13,0.12-0.27,0.25-0.46,0.42C562.16,503.48,562.12,503.41,562.1,503.38z"
        />
        <path
            fill={textColor}
            d="M541.53,507.77c0.07,0,0.13,0,0.2,0c0.4-1.46,0.8-2.91,1.2-4.36c-1.05,0.76,0.04,1.78-1.97,2.49
	c0.29-0.03,0.58-0.05,0.99-0.09C541.81,506.44,541.67,507.1,541.53,507.77z"
        />
        <path
            fill={textColor}
            d="M541.43,510.15c0-0.61,0-1.22,0-1.8c-0.31,0.03-0.73,0.07-1.12,0.11C539.09,509.39,541.18,509.51,541.43,510.15z"
        />
        <path
            fill={textColor}
            d="M539.21,434.37c-0.14,0-0.28-0.01-0.42-0.01c0.17,0.81,0.33,1.62,0.5,2.43c0.12,0.01,0.25,0.02,0.37,0.03
	c0.07-0.2,0.13-0.39,0.22-0.66c0.27,0.19,0.45,0.31,0.64,0.44c0.09-0.01,0.18-0.02,0.27-0.03c-0.07-0.15-0.09-0.39-0.22-0.43
	c-1.16-0.29-1.26-0.78-1.22-1.32C539.36,434.68,539.26,434.52,539.21,434.37z"
        />
        <path
            fill={textColor}
            d="M541.04,457.29c-0.18-0.02-0.36-0.03-0.54-0.05c-0.08,0.66-0.16,1.31-0.24,1.97c0.12,0.01,0.25,0.02,0.37,0.03
	C541.74,458.69,540.7,457.92,541.04,457.29z"
        />
        <path
            fill={textColor}
            d="M539.61,438.87c0.35,0.74,0.7,1.48,1.04,2.19C540.77,439.69,540.55,439.22,539.61,438.87z"
        />
        <path
            fill={textColor}
            d="M539.93,477.37c-0.4,0.54,0.55,0.85,0.67,1.3c0.17-0.03,0.34-0.06,0.51-0.09c-0.23-0.43-0.46-0.86-0.69-1.29
	C540.25,477.32,540.09,477.35,539.93,477.37z"
        />
        <path
            fill={textColor}
            d="M541.16,483.7c-0.86,0.18-1.51,0.31-2.34,0.48c0.49,0.2,0.79,0.32,1.01,0.41C540.26,484.3,540.63,484.06,541.16,483.7z"
        />
        <path
            fill={textColor}
            d="M657.11,449.9c0.1,0.09,0.19,0.17,0.29,0.26c0.44-0.46,0.95-0.85,1.3-1.38c0.86-1.3,1.64-2.68,2.48-4.01
	c0.15-0.24,0.29-0.55,0.59-0.59c1.08-0.14,0.86-1.22,1.02-2.07c-0.55-0.16-1-0.26-1.35-0.3c0.11-0.13,0.23-0.25,0.34-0.38
	c-0.12-0.11-0.24-0.22-0.36-0.32c-0.18,0.24-0.37,0.48-0.55,0.72c-0.45,0.11-0.55,0.49-0.34,1.24c0.32,1.12-0.04,1.68-0.43,2.27
	C659.11,446.86,658.11,448.38,657.11,449.9z"
        />
        <path
            fill={textColor}
            d="M656.3,452.42c0.51-0.53,0.31-1.63-0.46-2.88c-0.49,1.1-0.99,2.24-1.5,3.37c0.07,0.07,0.15,0.13,0.22,0.2
	c0.41-0.45,0.82-0.9,1.28-1.4C656.05,452.02,656.17,452.21,656.3,452.42z"
        />
        <path
            fill={textColor}
            d="M648.72,496.49c-0.55,0.4-1.19,0.61-1.39,1.08c-0.2,0.46,0.04,1.18,0.09,1.8c0.12-0.16,0.32-0.33,0.4-0.55
	c0.09-0.25,0.04-0.58,0.13-0.83c0.17-0.49,1.31-0.5,1.93-0.02c0.1-0.19,0.2-0.38,0.36-0.69c-0.51,0.05-0.84,0.08-1.21,0.12
	C648.92,497.08,648.84,496.86,648.72,496.49z"
        />
        <path
            fill={textColor}
            d="M623.01,494.83c1.2-0.91,2.4-1.82,3.6-2.73c-1.3-0.03-1.06,1.44-3.08,0.77c0.25,0.16,0.49,0.31,0.84,0.53
	c-0.49,0.42-1,0.86-1.52,1.3C622.9,494.75,622.95,494.79,623.01,494.83z"
        />
        <path
            fill={textColor}
            d="M622.42,495.1c-0.26-0.16-0.62-0.39-0.96-0.6c-1.53,0,0.04,1.37-0.14,2.03C621.69,496.05,622.06,495.57,622.42,495.1z"
        />
        <path
            fill={textColor}
            d="M653.18,454.36c-0.13-0.12-0.26-0.25-0.4-0.37c-0.46,0.47-0.93,0.95-1.39,1.42c0.09,0.08,0.18,0.17,0.28,0.25
	C652.88,455.9,652.53,454.64,653.18,454.36z"
        />
        <path
            fill={textColor}
            d="M640.27,471.29c0.08-0.48,0.16-0.97,0.24-1.45c-0.14-0.08-0.29-0.15-0.43-0.23c-0.65,0.19-0.08,1.01-0.26,1.44
	C639.96,471.13,640.12,471.21,640.27,471.29z"
        />
        <path
            fill={textColor}
            d="M637.21,475.39c-0.79-0.38-1.39-0.67-2.15-1.04c0.27,0.46,0.43,0.73,0.55,0.93C636.13,475.31,636.56,475.34,637.21,475.39z"
        />
        <path
            fill={textColor}
            d="M502.46,594.71c-0.44-0.59-1.55-0.58-2.92-0.04c1,0.67,2.03,1.36,3.06,2.05c0.08-0.06,0.16-0.12,0.24-0.18
	c-0.37-0.48-0.75-0.97-1.16-1.5C502.03,594.89,502.23,594.81,502.46,594.71z"
        />
        <path
            fill={textColor}
            d="M545.41,611.27c0.42,0.28,1.17,0.16,1.79,0.22c-0.14-0.15-0.27-0.37-0.47-0.49c-0.23-0.13-0.56-0.14-0.8-0.27
	c-0.45-0.25-0.26-1.38,0.31-1.91c-0.1-0.08-0.21-0.16-0.33-0.25c-0.13-0.3-0.26-0.59-0.39-0.89c-0.13,0.02-0.26,0.05-0.39,0.07
	c-0.27,0.44,0.23,0.73,0.45,1.08c-0.02,0.26-0.04,0.49-0.05,0.73c-0.32,0.05-0.55,0.09-0.94,0.15
	C544.88,610.33,544.98,611,545.41,611.27z"
        />
        <path
            fill={textColor}
            d="M538.56,634.77c-0.69-1.34-1.39-2.68-2.07-4.02c-0.25,1.27,1.24,1.29,0.24,3.17c0.2-0.22,0.39-0.43,0.67-0.74
	c0.33,0.55,0.68,1.14,1.03,1.72C538.46,634.85,538.51,634.81,538.56,634.77z"
        />
        <path
            fill={textColor}
            d="M537.98,636.23c-0.26,1.51,1.36,0.19,1.97,0.48c-0.41-0.45-0.83-0.9-1.22-1.33C538.52,635.62,538.24,635.94,537.98,636.23z"
        />
        <path
            fill={textColor}
            d="M503.83,598.12c-0.14,0.11-0.29,0.22-0.43,0.33c0.39,0.54,0.77,1.07,1.16,1.61c0.1-0.08,0.2-0.15,0.3-0.23
	C505.3,598.68,504.01,598.82,503.83,598.12z"
        />
        <path
            fill={textColor}
            d="M516.63,613.64c0.08,0.67,0.98,0.25,1.37,0.5c0.1-0.14,0.21-0.27,0.31-0.41c-0.46-0.16-0.92-0.32-1.39-0.48
	C516.83,613.38,516.73,613.51,516.63,613.64z"
        />
        <path
            fill={textColor}
            d="M521.45,619.01c0.12-0.51,0.23-0.93,0.38-1.56c-0.51,0.71-0.9,1.25-1.39,1.94C520.93,619.21,521.22,619.1,521.45,619.01z"
        />
        <path
            fill={textColor}
            d="M235.16,461.96c0.37,0.91,0.92,0.77,1.51,0.62c-0.2-0.19-0.4-0.37-0.6-0.56c0-0.09,0-0.18,0-0.27
	c0.56-0.12,1.12-0.26,1.68-0.33c0.05-0.01,0.11,0.17,0.2,0.4c-0.29,0.16-0.57,0.32-0.88,0.49c0.86,0.16,1.62,0.3,2.39,0.44
	c0.02-0.09,0.04-0.18,0.06-0.28c-0.43-0.5-0.85-1-1.32-1.54c1.04-0.27,3.08,0.17,3.2,0.75c0.76-0.15,1.59-0.66,2.24-0.36
	c0.83,0.39,1.65,0.35,2.48,0.46c0.39,0.05,0.75,0.37,1.14,0.47c0.24,0.06,0.5-0.08,0.76-0.13c0.38-0.04,0.79,0.26,1.2,0.28
	c0.31,0.01,0.66-0.52,0.96-0.48c0.81,0.11,1.62,0.36,2.22,1.33c-0.43,0.14-0.83,0.27-1.23,0.41c0.51,0.08,0.96,0.2,1.51-0.15
	c0.58-0.37,1.35-0.01,2.04,0.04c0.64,0.05,1.3,0.28,2.02-0.78c-0.38-0.08-0.6-0.18-0.81-0.17c-0.63,0.04-1.33,0.57-1.76-0.56
	c-0.04-0.09-0.19-0.1-0.29-0.12c-0.54-0.1-1.08-0.16-1.61-0.31c-0.2-0.06-0.37-0.33-0.55-0.51c0.03-0.07,0.05-0.14,0.08-0.2
	c0.22-0.05,0.44-0.1,0.67-0.15c-0.46-0.38-0.87-0.46-1.06,0.57c-0.19-0.28-0.32-0.48-0.46-0.69c0.13-0.1,0.22-0.17,0.34-0.26
	c-0.21-0.15-0.38-0.28-0.69-0.5c0.39-0.04,0.61-0.06,0.83-0.08c0-0.09,0.01-0.18,0.01-0.27c-1.36-0.25-2.72-0.48-4.07-0.78
	c-0.47-0.1-0.87-0.04-1.34,0.17c-0.37,0.17-0.97,0.1-1.2-0.26c-0.36-0.55-0.69-0.69-1.19-0.64c0.27,0.17,0.49,0.32,0.84,0.54
	c-0.99,0.65-1.61-0.12-2.29-0.5c0.36-0.19,0.72-0.38,1.25-0.66c-1.08-0.08-1.93,0.42-2.86,0.14c-2.21-0.66-2.23-0.53-2.3-1.25
	c0.63,0.03,1.25,0.07,1.87,0.09c1.38,0.04,2.76,0.06,4.14,0.09c0.02,0,0.05,0,0.06-0.02c0.44-0.79,0.87-0.26,1.31-0.08
	c0.19,0.08,0.39,0.11,0.61,0.18c-0.02-0.36-0.04-0.61-0.06-0.86c-0.79-0.12-1.56-0.24-2.34-0.36c0.01-0.09,0.01-0.19,0.02-0.28
	c0.27-0.13,0.46-1.24,0.85-0.17c0.02,0.06,0.16,0.04,0.24,0.04c0.46,0,0.92,0.01,1.38-0.01c0.57-0.02,1.12-0.02,1.16,1.01
	c0.27-0.15,0.65-0.21,0.69-0.4c0.19-0.96,0.6-0.66,1-0.55c0.37,0.1,0.74,0.19,1.12,0.25c0.34,0.05,0.67,0.09,1.01,0.07
	c0.48-0.02,0.95-0.13,1.43-0.12c0.42,0.01,0.83,0.11,1.25,0.18c0.14,0.12,0.32-0.01,0.46-0.19c0.92-1.2,1.96-0.98,2.96-0.63
	c0.42,0.15,0.73,0.52,1.22,0.14c0.23-0.18,0.63,0.11,1.1,0.22c0.34-0.73,0.72,0.49,1.2,0.37c-0.26-0.4-0.49-0.76-0.7-1.08
	c-0.75,0.48-1.43-0.11-2.33-0.36c0.31-0.11,0.44-0.16,0.57-0.21c-0.25-0.69,0.25-0.72,0.4-0.72c0.51,0.01,1.05,0.05,1.52,0.28
	c0.61,0.29,1.18,0.62,1.79,0.23c0.01-0.3,0.03-0.54,0.03-0.66c-0.49-0.21-0.99-0.38-1.47-0.63c-0.67-0.34-1.09-1.4-1.9-1.34
	c-0.2,0.02-0.42,0-0.6-0.1c-0.36-0.22-0.32-0.44-0.02-0.6c-0.66-0.13-1.26-0.26-1.85-0.37c-0.62-0.12-1.23-0.35-1.85-0.32
	c-0.6,0.02-1.2,0.33-1.8,0.46c-0.16,0.03-0.34-0.12-0.5-0.22c-0.24-0.16-0.46-0.39-0.71-0.52c-0.26-0.14-0.55-0.2-0.83-0.29
	c-0.02,0.19-0.05,0.46-0.06,0.64c-0.72-0.14-1.41-0.21-2.07-0.44c-0.24-0.08-0.41-0.52-0.61-0.8c-0.08-0.12-0.16-0.29-0.24-0.42
	c0.2,0.17,0.46,0.19,0.69,0.02c0.19-0.13,0.39-0.22,0.57-0.32c-0.05-0.06-0.11-0.14-0.27-0.34c0.82-0.39,1.52-0.73,2.22-1.06
	c-0.03-0.11-0.06-0.22-0.09-0.33c-0.61,0.3-1.23,0.62-1.85,0.9c-0.17,0.07-0.4,0.09-0.54-0.02c-0.73-0.62-1.52-0.75-2.37-0.49
	c0.15,0.23,0.37,0.41,0.48,0.31c0.58-0.5,0.69,0.08,0.81,0.68c0.04,0.2,0.11,0.37,0.21,0.5c-0.01-0.01-0.03-0.01-0.04-0.02
	c-0.56-0.07-1.13-0.04-1.69,0c-0.08,0.01-0.16,0.15-0.17,0.18c-1.09-0.36-2.07-0.13-3.09-0.15c0.02-0.15,0.04-0.32,0.06-0.48
	c-0.33-0.03-0.33-0.03-0.64,0.59c-0.6,0.05-1.08,0.21-1.52,0.1c-0.47-0.12-0.89-0.51-1.38-0.81c0.17-0.16,0.27-0.26,0.36-0.35
	c-0.02-0.1-0.03-0.2-0.05-0.3c-0.56,0.15-1.12,0.31-1.68,0.46c-0.1,0.03-0.26,0.07-0.31-0.01c-0.61-1.01-1.47-0.47-2.2-0.67
	c-0.14-0.04-0.32,0.26-0.49,0.4c0,0.11,0,0.21,0,0.32c-0.49-0.22-0.98-0.45-1.47-0.66c-0.41-0.18-0.82-0.41-1.24-0.48
	c-0.69-0.11-1.38-0.1-2.08-0.15c-0.1-0.01-0.24-0.07-0.28-0.18c-0.06-0.2-0.14-0.3-0.21-0.32c0.27-0.03,0.58-0.01,0.59-0.91
	c-0.61,0.06-1.2,0.12-1.79,0.18c-0.21,0.02-0.43,0.11-0.62,0.04c-1.18-0.48-2.45,0.16-3.64-0.56c-0.54-0.33-1.3,0.06-1.96,0.1
	c-0.29,0.02-0.59,0.01-0.88-0.04c-0.7-0.12-1.39-0.27-2.16-0.42c0.67,1.1,1.3,1.37,1.73,1.02c0.18,0.17,0.37,0.4,0.51,0.54
	c-0.5-0.17-1.01-0.32-1.52-0.44c-0.35-0.08-0.74,0.02-1.11,0.09c-0.26,0.05-0.52,0.15-0.78,0.23c-0.01-0.09-0.02-0.18-0.03-0.27
	c0.08-0.07,0.17-0.13,0.25-0.2c-0.09,0-0.18,0.03-0.27,0.06c-0.04-0.34-0.09-0.68-0.13-1.01c0.15,0.07,0.3,0.13,0.45,0.2
	c-0.07-0.33-0.17-0.56-0.28-0.69c0.09-0.07,0.19-0.14,0.28-0.22c-0.01-0.08-0.02-0.15-0.04-0.23c-0.16,0.03-0.32,0.06-0.48,0.09
	c-0.07-1.58,0.01-3.16-0.52-4.73c-0.23-0.68,0-1.43-0.02-2.15c-0.01-0.3-0.09-0.61-0.19-0.9c-0.09-0.29-0.34-0.57-0.32-0.85
	c0.03-0.53,0.2-1.06,0.32-1.59c-0.05,0-0.1-0.01-0.16-0.01c-0.05,0.09-0.11,0.19-0.16,0.28c-0.18-0.51-0.15-0.99-0.19-1.48
	c-0.04-0.46-0.44-0.93,0.23-1.34c0.05-0.03,0.02-0.17-0.02-0.26c0.05,0.02,0.1,0.05,0.15,0.07c0.37-0.68,0.64-1.18,1-1.84
	c-0.43,0.15-0.62,0.21-0.65,0.23c-0.41-0.12-0.74-0.21-1.03-0.29c0.01-0.49,0.03-0.99,0.05-1.48c0.13-0.24,0.31-0.47,0.46-0.72
	c-0.42-0.15-0.39-0.15-0.28-0.59c0.01-0.04-0.05-0.11-0.13-0.16c0.05-2.25-0.01-4.48-0.67-6.64c-0.51-0.19-0.88-0.34-1.4-0.54
	c0,0.46,0.12,0.88-0.03,1.26c-0.23,0.62-0.66,1.21-0.96,1.82c-0.18,0.36-0.29,0.73-0.41,1.1c-0.04,0.13,0.07,0.35-0.02,0.39
	c-0.79,0.35-0.37,0.76-0.19,1.17c0.12,0.27,0.34,0.61,0.21,0.84c-0.04,0.08-0.08,0.15-0.11,0.23c-0.12,0.01-0.23,0.02-0.35,0.04
	c-0.11-1.34-0.23-2.66-0.29-3.98c-0.01-0.17,0.34-0.35,0.52-0.52c-0.07,0.01-0.14,0.01-0.21,0.02c-0.2-0.97-0.41-1.94-0.61-2.89
	c-0.79-0.2-1.2,0.08-1.41,0.53c-0.26,0.56-0.7,0.65-1.39,0.3c-0.12-0.06-0.28-0.08-0.52-0.14c-0.18,0.15-0.35,0.42-0.57,0.44
	c-1.29,0.12-2.12,0.76-2.77,1.35c0,0,0,0,0,0c-0.02-0.03-0.03-0.06-0.05-0.09c-0.3,0-0.54,0-0.66,0c-0.18,0.5-0.33,1.01-0.56,1.5
	c-0.08,0.19-0.22,0.36-0.38,0.52c-0.05-0.49-0.11-0.98-0.19-1.47c-0.05-0.28-0.44-0.75-0.71-0.76c-0.01,0-0.02,0-0.03,0
	c0.09-0.76,0.18-1.51,0.28-2.24c-0.33,0.16-0.6,0.26-0.82,0.4c-0.23,0.14-0.53,0.28-0.6,0.46c-0.16,0.41-0.22,0.83-0.29,1.25
	c-0.31,0.01-0.61,0.09-0.87,0.33c-0.12-0.12-0.25-0.24-0.31-0.3c-0.23,0.67-0.44,1.34-0.69,2c-0.61,1.66-1.25,3.32-1.09,5.06
	c0.11,1.22,0.16,2.45,0.18,3.67c0.01,0.41,0,0.82-0.01,1.23c0-0.01-0.01-0.02-0.01-0.03c-0.11,0.04-0.22,0.07-0.32,0.11
	c0.11,0.19,0.22,0.39,0.33,0.58c-0.02,1.1-0.05,2.2,0.05,3.3c0.1,1.04,0.23,2.08,0.38,3.12c0,0.49,0,0.98,0,1.48
	c0.06-0.06,0.12-0.13,0.19-0.2c0.1,0.68,0.2,1.35,0.3,2.03c-0.45,0.04-0.91,0.08-1.36,0.07c0.04-1.06-0.17-1.45-0.86-1.69
	c0.23,0.57,0.46,1.13,0.69,1.68c-0.07,0-0.13,0-0.2-0.01c-0.74-0.09-1.47-0.36-2.21-0.51c-0.31-0.06-0.63,0.02-0.94-0.02
	c-0.84-0.11-1.67-0.27-2.51-0.35c-0.17-0.02-0.35,0.25-0.51,0.37c-0.46-0.12-0.94-0.32-1.43-0.34c-0.49-0.02-0.96,0.1-1.47,0.39
	c-0.57,0.32-1.29,0-1.94-0.06c-0.85-0.07-1.69-0.21-2.54-0.23c-0.77-0.01-1.55,0.13-2.33,0.15c-1.71,0.03-3.42-0.2-5.14,0.29
	c-0.69,0.19-1.43-0.07-2.15-0.08c-0.3-0.01-0.61,0.06-0.91,0.14c-0.29,0.07-0.59,0.31-0.86,0.28c-0.53-0.06-1.05-0.25-1.57-0.39
	c-0.01,0.05-0.01,0.1-0.02,0.15c0.09,0.06,0.18,0.12,0.27,0.18c-0.52,0.15-1,0.1-1.48,0.12c-0.46,0.02-0.95,0.39-1.33-0.29
	c-0.03-0.05-0.16-0.03-0.26,0.01c0.03-0.05,0.05-0.1,0.08-0.14c-0.66-0.4-1.15-0.7-1.79-1.09c0.13,0.44,0.18,0.63,0.19,0.66
	c-0.14,0.41-0.25,0.73-0.34,1.01c-0.49-0.04-0.99-0.08-1.48-0.12c-0.23-0.14-0.46-0.33-0.7-0.49c-0.17,0.41-0.17,0.39-0.61,0.25
	c-0.04-0.01-0.11,0.04-0.17,0.12c-2.25-0.16-4.47-0.21-6.66,0.34c-0.22,0.5-0.38,0.87-0.6,1.37c0.46,0.02,0.89-0.07,1.26,0.09
	c0.61,0.26,1.17,0.71,1.77,1.05c0.35,0.2,0.72,0.33,1.08,0.46c0.13,0.05,0.35-0.06,0.39,0.04c0.31,0.81,0.74,0.41,1.16,0.25
	c0.28-0.11,0.63-0.31,0.85-0.16c0.07,0.05,0.15,0.09,0.22,0.12c0.01,0.12,0.01,0.23,0.02,0.36c-1.34,0.04-2.67,0.11-3.99,0.1
	c-0.17,0-0.33-0.35-0.5-0.54c0,0.07,0.01,0.14,0.01,0.22c-0.98,0.16-1.96,0.31-2.92,0.47c-0.24,0.78,0.02,1.2,0.46,1.44
	c0.55,0.29,0.61,0.73,0.23,1.4c-0.07,0.11-0.09,0.28-0.16,0.51c0.14,0.19,0.4,0.37,0.41,0.59c0.06,1.29,0.66,2.15,1.21,2.83
	c0.66,0.81,1.52,1.18,2.47,1.1c1.74-0.14,3.49-0.14,5.23-0.2c0.42-0.01,0.86,0.14,1.25-0.04c0.67-0.3,0.71-0.34,0.93,0.81
	c-0.22,0.02-0.42,0.06-0.63,0.06c-1.93-0.01-3.85-0.04-5.78-0.03c-1.04,0.01-2.08,0.04-3.11,0.16c-0.28,0.03-0.77,0.41-0.8,0.67
	c-0.06,0.77-0.49,1.65,0.07,2.35c-0.13,0.12-0.25,0.24-0.31,0.3c0.65,0.26,1.31,0.51,1.96,0.79c1.63,0.69,3.25,1.41,5,1.33
	c1.23-0.05,2.45-0.04,3.68,0c1.73,0.06,3.46,0.29,5.19,0.22c2.48-0.11,4.96-0.43,7.43-0.65c1.31-0.12,2.62-0.22,3.92-0.33
	c0.06-0.01,0.12,0.01,0.19,0.02c0.87,0.11,1.73,0.24,2.6,0.31c0.72,0.06,1.46-0.05,2.18,0.09c1.09,0.21,2.19-0.4,3.29,0.02
	c0.53,0.2,1.14-0.1,1.7,0.01c1.12,0.23,2.27-0.31,3.39,0.06c0.05,0.17,0.09,0.35,0.11,0.52c0.03,0.29,0.04,0.59,0.01,0.88
	c-0.08,0.7-0.2,1.4-0.31,2.18c1.07-0.73,1.31-1.36,0.94-1.78c0.16-0.19,0.38-0.39,0.52-0.54c-0.14,0.51-0.27,1.02-0.37,1.54
	c-0.07,0.36,0.06,0.74,0.14,1.1c0.06,0.28,0.19,0.55,0.29,0.83c-0.08,0.01-0.16,0.03-0.24,0.04c-0.09-0.1-0.17-0.2-0.26-0.3
	c0.03,0.35,0.34,0.65,0.37,0.95c0.06,0.69-0.02,1.39-0.03,2.08c0,0.27,0,0.54,0.08,0.8c0.05,0.15,0.26,0.28,0.4,0.42
	c-0.29,0.07-0.58,0.14-1.07,0.26c0,0.92,0.06,1.91-0.02,2.9c-0.04,0.46-0.07,1.09-0.42,1.38c-0.1-0.17-0.19-0.33-0.29-0.51
	c1.38-0.88,0.26-2.04,0.44-3.18c-0.2,0.17-0.33,0.28-0.46,0.39c-0.07-0.01-0.15-0.03-0.22-0.04c0.09-0.43,0.18-0.85,0.27-1.28
	c0.07,0,0.13,0,0.2,0c0.07,0.13,0.14,0.26,0.21,0.39c-0.17-0.5,0.05-0.97,0.09-1.47c0.58-0.28,0.28-0.61-0.11-1
	c0-0.51,0.2-1.05,0.28-1.61c-0.24,0.08-0.54,0.17-0.75,0.24c0.1-0.26,0.19-0.49,0.28-0.73c-0.33,0.09-0.55,0.19-0.68,0.31
	c-0.08-0.09-0.15-0.18-0.23-0.27c-0.07,0.02-0.15,0.03-0.22,0.05c0.14,0.52,0.23,1.04,0.42,1.54c0.25,0.68,0.08,1.24-0.47,1.86
	c-0.35,0.39-0.56,1.06-0.29,1.42c0.36,0.48,0.29,0.87,0.16,1.31c-0.1,0.35-0.32,0.7-0.3,1.05c0.07,0.98,0.27,1.95,0.37,2.93
	c0.02,0.17-0.22,0.35-0.3,0.46c0.14,0.28,0.27,0.53,0.4,0.79c-0.24,0.02-0.38,0.04-0.55,0.05c0.77,0.97,0.75,1.15-0.05,1.28
	c0.37,0.55,0.41,1.08-0.03,1.63c-0.15,0.19-0.02,0.49-0.02,0.92c0.25-0.31,0.37-0.45,0.48-0.59c0.13,0.24,0.08,0.44-0.01,0.64
	c-0.11,0.24-0.37,0.48-0.35,0.71c0.11,0.96,0.32,1.91,0.45,2.87c0.04,0.25,0.07,0.49,0.1,0.74c-0.01,0-0.01,0-0.02,0
	c0.01,0,0.01,0.01,0.02,0.01c0.05,0.39,0.08,0.77,0.05,1.15c-0.05,0.75-0.29,1.49-0.4,2.24c-0.05,0.31,0.05,0.63,0.03,0.94
	c-0.07,0.84-0.19,1.68-0.23,2.53c-0.01,0.17,0.27,0.34,0.4,0.49c-0.09,0.46-0.27,0.95-0.27,1.45c0,0.49,0.15,0.95,0.46,1.45
	c0.35,0.55,0.06,1.29,0.03,1.94c-0.03,0.85-0.13,1.7-0.11,2.55c0.02,0.77,0.21,1.55,0.26,2.32c0.11,1.71-0.03,3.42,0.54,5.12
	c0.23,0.68,0,1.43,0.02,2.15c0.01,0.3,0.09,0.61,0.19,0.9c0.09,0.29,0.34,0.57,0.32,0.85c-0.03,0.53-0.2,1.06-0.32,1.59
	c0.05,0,0.1,0.01,0.16,0.01c0.05-0.09,0.11-0.19,0.16-0.28c0.18,0.51,0.15,0.99,0.19,1.48c0.04,0.46,0.44,0.93-0.23,1.34
	c-0.05,0.03-0.02,0.17,0.02,0.26c-0.05-0.02-0.1-0.05-0.15-0.07c-0.37,0.68-0.64,1.18-1,1.84c0.43-0.15,0.62-0.21,0.65-0.23
	c0.41,0.12,0.74,0.21,1.03,0.29c-0.01,0.49-0.03,0.99-0.05,1.48c-0.13,0.24-0.31,0.47-0.46,0.72c0.42,0.15,0.39,0.15,0.28,0.59
	c-0.01,0.04,0.05,0.11,0.13,0.16c-0.05,2.25,0.01,4.48,0.67,6.64c0.51,0.19,0.88,0.34,1.4,0.54c0-0.46-0.12-0.88,0.03-1.26
	c0.23-0.62,0.66-1.21,0.96-1.82c0.18-0.36,0.29-0.73,0.41-1.1c0.04-0.13-0.07-0.35,0.02-0.39c0.79-0.35,0.37-0.76,0.19-1.17
	c-0.12-0.27-0.34-0.61-0.21-0.84c0.04-0.08,0.08-0.15,0.11-0.23c0.12-0.01,0.23-0.02,0.35-0.04c0.11,1.34,0.23,2.66,0.29,3.98
	c0.01,0.17-0.34,0.35-0.52,0.52c0.07-0.01,0.14-0.01,0.21-0.02c0.2,0.97,0.41,1.94,0.61,2.89c0.79,0.2,1.2-0.08,1.41-0.53
	c0.26-0.56,0.7-0.65,1.39-0.3c0.12,0.06,0.28,0.08,0.52,0.14c0.18-0.15,0.35-0.42,0.57-0.44c1.29-0.12,2.12-0.76,2.77-1.35
	c0,0,0,0,0,0c0.02,0.03,0.03,0.06,0.05,0.09c0.3,0,0.54,0,0.66,0c0.18-0.5,0.33-1.01,0.56-1.5c0.08-0.19,0.22-0.36,0.38-0.52
	c0.05,0.49,0.11,0.98,0.19,1.47c0.05,0.28,0.44,0.75,0.71,0.76c0.01,0,0.02,0,0.03,0c-0.09,0.76-0.18,1.51-0.28,2.24
	c0.33-0.16,0.6-0.26,0.82-0.4c0.23-0.14,0.53-0.28,0.6-0.46c0.16-0.41,0.22-0.83,0.29-1.25c0.31-0.01,0.61-0.09,0.87-0.33
	c0.12,0.12,0.25,0.24,0.31,0.3c0.23-0.67,0.44-1.34,0.69-2c0.61-1.66,1.25-3.32,1.09-5.06c-0.11-1.22-0.16-2.45-0.18-3.67
	c-0.01-0.41,0-0.82,0.01-1.23c0,0.01,0.01,0.02,0.01,0.03c0.11-0.04,0.22-0.07,0.32-0.11c-0.11-0.19-0.22-0.39-0.33-0.58
	c0.02-1.1,0.05-2.2-0.05-3.3c-0.1-1.04-0.23-2.08-0.38-3.12c0-0.49,0-0.98,0-1.48c-0.06,0.06-0.12,0.13-0.19,0.2
	c-0.15-1-0.3-1.99-0.44-2.99c0-0.02,0-0.04-0.01-0.05c0.07-0.02,0.14-0.03,0.22-0.05c-0.13-0.39-0.25-0.77-0.38-1.16
	c-0.04-0.28-0.08-0.57-0.11-0.85c0.9-0.65,0.34-1.46,0.51-2.19c0.03-0.14-0.28-0.31-0.43-0.47c-0.09,0.01-0.17,0.01-0.26,0.02
	c0-0.06,0.01-0.11,0.01-0.17c0.18-0.44,0.35-0.89,0.52-1.33c0.12-0.32,0.27-0.63,0.36-0.95c0.09,1.39,0.24,2.77,0.36,4.16
	c0.02,0.27,0.08,0.59-0.09,0.81c-0.62,0.8,0.04,1.45,0.37,2.17c0.43-0.24,0.77-0.46,1.01-0.66c-0.01,0.17-0.02,0.33-0.03,0.5
	c0.13,0,0.26,0,0.39,0c0-0.29,0-0.58,0-0.87c0.24-0.37,0.11-0.71-0.39-1.11c-0.76-0.6-0.8-1.25-0.84-1.93
	c-0.01-0.13-0.02-0.25-0.02-0.38c0.13,0.17,0.26,0.34,0.38,0.51c0-0.21,0.04-0.47-0.01-0.71c-0.06-0.27-0.26-0.52-0.33-0.79
	c-0.13-0.52,0.6-1.18,1.23-1.13c-0.03-0.22-0.06-0.44-0.11-0.8c-0.31,0.34-0.5,0.56-0.72,0.8c-0.21-0.19-0.37-0.34-0.61-0.56
	c-0.05-0.74-0.09-1.47-0.14-2.21c-0.11,0-0.21-0.01-0.31-0.01c0,0.05-0.01,0.1-0.02,0.15c0-0.11,0-0.21,0-0.32
	c0-0.1,0.06-0.25,0.16-0.29c0.2-0.07,0.29-0.15,0.31-0.23c0.04,0.27,0.04,0.58,0.93,0.54c-0.09-0.61-0.18-1.19-0.27-1.78
	c-0.03-0.21-0.13-0.43-0.07-0.62c0.42-1.2-0.28-2.44,0.39-3.67c0.3-0.56-0.12-1.29-0.2-1.95c-0.03-0.29-0.04-0.59-0.01-0.88
	c0.08-0.7,0.2-1.4,0.31-2.18c-1.07,0.73-1.31,1.36-0.94,1.78c-0.16,0.19-0.38,0.39-0.52,0.54c0.14-0.51,0.27-1.02,0.37-1.54
	c0.07-0.36-0.06-0.74-0.14-1.1c-0.06-0.28-0.19-0.55-0.29-0.83c0.08-0.01,0.16-0.03,0.24-0.04c0.09,0.1,0.17,0.2,0.26,0.3
	c-0.03-0.35-0.34-0.65-0.37-0.95c-0.06-0.69,0.02-1.39,0.03-2.08c0-0.27,0-0.54-0.08-0.8c-0.05-0.15-0.26-0.28-0.4-0.42
	c0.29-0.07,0.58-0.14,1.07-0.26c0-0.92-0.06-1.91,0.02-2.9c0.04-0.46,0.07-1.09,0.42-1.38c0.1,0.17,0.19,0.33,0.29,0.51
	c-1.38,0.88-0.26,2.04-0.44,3.18c0.2-0.17,0.33-0.28,0.46-0.39c0.07,0.01,0.15,0.03,0.22,0.04c-0.09,0.43-0.18,0.85-0.27,1.28
	c-0.07,0-0.13,0-0.2,0c-0.07-0.13-0.14-0.26-0.21-0.39c0.17,0.5-0.05,0.97-0.09,1.47c-0.58,0.28-0.28,0.61,0.11,1
	c0,0.51-0.2,1.05-0.28,1.61c0.24-0.08,0.54-0.17,0.75-0.24c-0.1,0.26-0.19,0.49-0.28,0.73c0.33-0.09,0.55-0.19,0.68-0.31
	c0.08,0.09,0.15,0.18,0.23,0.27c0.07-0.02,0.15-0.03,0.22-0.05c-0.14-0.52-0.23-1.04-0.42-1.54c-0.25-0.68-0.08-1.24,0.47-1.86
	c0.35-0.39,0.56-1.06,0.29-1.42c-0.36-0.48-0.29-0.87-0.16-1.31c0.1-0.35,0.32-0.7,0.3-1.05c-0.08-0.98-0.27-1.95-0.37-2.93
	c-0.02-0.17,0.22-0.35,0.3-0.46c-0.14-0.28-0.27-0.53-0.4-0.79c0.24-0.02,0.38-0.04,0.55-0.05c-0.77-0.97-0.75-1.15,0.05-1.28
	c-0.37-0.55-0.41-1.08,0.03-1.63c0.15-0.19,0.02-0.49,0.02-0.92c-0.25,0.31-0.37,0.45-0.48,0.59c-0.13-0.24-0.08-0.44,0.01-0.64
	c0.11-0.24,0.37-0.48,0.35-0.71c-0.11-0.96-0.32-1.91-0.45-2.87c-0.01-0.05-0.01-0.1-0.02-0.15c0.74,0.01,1.49,0.05,2.22,0.23
	c0.63,0.16,1.3-0.05,1.96-0.07c0.27-0.01,0.54,0.03,0.81,0.08c0.81,0.15,1.62,0.32,2.42,0.47c0.05,0.01,0.11-0.04,0.2-0.08
	c-0.13-0.19-0.24-0.34-0.36-0.51c0.6-0.47,1.1-0.14,1.09,0.57c1.05,0.78,1.95-0.94,2.98-0.54c0.01,0,0.04-0.09,0.14-0.29
	c-0.44-0.02-0.83-0.16-1.18-0.03c-1.07,0.4-1.95,0.2-2.62-1.5c1,0.3,1.89,0.57,2.79,0.83c0.16,0.05,0.42,0.18,0.46,0.09
	c0.39-0.76,0.8-0.28,1.2-0.17c0.24,0.07,0.55,0.39,0.71,0.26c0.74-0.61,1.25,0.45,1.91,0.21c0,0.13,0,0.26,0,0.39
	c-0.75,0.2-1.51,0.41-2.16,0.58c0.2,0.19,0.51,0.48,0.81,0.77c0-0.06,0-0.12,0-0.18c0.45,0.02,0.9,0.04,1.39,0.07
	c0-0.01,0.02-0.23,0.09-0.31C233.13,461.23,234.96,461.46,235.16,461.96z M247.95,454.2c0.29-0.04,0.58-0.12,0.87-0.1
	c0.71,0.06,1.42,0.17,2.12,0.44c-1-0.05-2-0.1-3-0.15C247.94,454.33,247.95,454.27,247.95,454.2z M238.32,453.74
	c0.48,0.09,0.95,0.18,1.43,0.27c-0.01,0.12-0.02,0.23-0.04,0.35c-0.47-0.06-0.94-0.13-1.41-0.19
	C238.3,454.02,238.31,453.88,238.32,453.74z M237.72,461.18c0,0.01,0.01,0.02,0.01,0.03c-0.01,0-0.02,0-0.03-0.01
	C237.71,461.2,237.71,461.19,237.72,461.18z M160.12,446.27c-1.28,0.06-2.56,0.12-3.84,0.19c-0.04-0.05-0.07-0.1-0.11-0.15
	c0.27-0.16,0.55-0.46,0.82-0.46c1.05-0.01,2.09,0.08,3.14,0.13C160.13,446.07,160.13,446.17,160.12,446.27z M167.71,441.86
	c0.04-0.07,0.07-0.13,0.11-0.2C167.79,441.74,167.75,441.81,167.71,441.86z M199.71,459.51c-0.02,0.15-0.04,0.31-0.06,0.46
	c-0.12-0.01-0.24-0.01-0.36-0.02c0-0.15,0-0.3,0-0.45C199.43,459.5,199.57,459.5,199.71,459.51z M216.3,429.51
	c-0.09-0.03-0.16-0.06-0.21-0.1C216.17,429.45,216.23,429.48,216.3,429.51z M211.1,418.11c0.17,0.27,0.48,0.53,0.5,0.8
	c0.06,1.05,0.02,2.09,0.02,3.14c-0.1,0-0.2,0-0.3,0c-0.12-1.27-0.25-2.55-0.37-3.82C211,418.19,211.05,418.15,211.1,418.11z
	 M198.77,459.91c-0.06,0.23-0.13,0.45-0.19,0.68c-0.35-0.32-0.39-0.65-0.34-0.97c0.22-0.02,0.44-0.05,0.67-0.08
	C198.85,459.66,198.81,459.79,198.77,459.91z M198.81,480.65c0.02,0.38-0.02,0.76-0.05,1.15c-0.09-0.02-0.18-0.03-0.27-0.05
	c-0.08-0.38-0.16-0.77-0.26-1.25C198.49,480.57,198.81,480.61,198.81,480.65z M197.59,478.42c-0.02,0.07-0.03,0.14-0.05,0.2
	c0-0.1,0.01-0.2,0.01-0.3C197.57,478.36,197.58,478.39,197.59,478.42z M197.52,481.41c-0.02-0.34-0.02-0.68-0.02-1.02
	C197.77,480.73,197.7,481.05,197.52,481.41z M197.74,477.76c-0.04-0.09-0.08-0.17-0.12-0.26c0.05-0.21,0.12-0.41,0.17-0.62
	c0.04,0,0.07,0,0.11,0C197.86,477.18,197.8,477.47,197.74,477.76z M197.66,474.93c0.06,0.24,0.14,0.47,0.2,0.7
	C197.51,475.41,197.59,475.16,197.66,474.93z M197.71,514.36c0.09,0.03,0.16,0.06,0.21,0.1
	C197.85,514.43,197.78,514.4,197.71,514.36z M202.92,525.77c-0.17-0.27-0.48-0.53-0.5-0.8c-0.06-1.05-0.02-2.09-0.02-3.14
	c0.1,0,0.2,0,0.3,0c0.12,1.27,0.25,2.55,0.37,3.82C203.02,525.69,202.97,525.73,202.92,525.77z M215.66,490.82
	c0.04,0.04,0.1,0.07,0.15,0.11c0.01,0.05,0.01,0.09,0.02,0.14C215.79,490.98,215.72,490.9,215.66,490.82z M215.25,483.96
	c0.06-0.23,0.13-0.45,0.19-0.68c0.72,0.65,0.14,1.32,0.17,1.98c0.01,0.13-0.01,0.27,0.08,0.37c0.67,0.75,0.55,1.58,0.31,2.34
	c-0.08,0-0.15-0.01-0.23-0.01c-0.04,0.59-0.09,1.19-0.13,1.84c-0.28-0.11-0.45-0.17-0.64-0.24c0,0.05,0,0.11,0.01,0.16
	c-0.12-0.13-0.28-0.25-0.41-0.38c-0.07-0.07-0.14-0.14-0.21-0.22c0.05-0.2,0.04-0.4,0.01-0.61c0.89-0.22,1.42-0.93,1.24-1.57
	c-0.13,0.14-0.24,0.25-0.4,0.42c-0.18-0.54-0.32-0.98-0.49-1.48c-0.21,0.16-0.31,0.23-0.41,0.31c-0.03-0.01-0.06-0.01-0.08-0.02
	c-0.03-0.22-0.07-0.43-0.1-0.65c0.07-0.54,0.14-1.09,0.21-1.63c0.12,0.01,0.24,0.01,0.36,0.02c0,0.25,0,0.5,0,0.75
	c0.08,0.01,0.15,0.02,0.23,0.03C215.06,484.45,215.18,484.21,215.25,483.96z M215.21,463.22c0-0.05,0-0.1,0-0.14
	c0.15-0.11,0.29-0.25,0.45-0.33c0.04,0.19,0.08,0.4,0.13,0.63C215.52,463.31,215.21,463.27,215.21,463.22z M215.23,462.49
	c0.01-0.14,0.02-0.28,0.03-0.41c0.09,0.02,0.18,0.03,0.27,0.05c0.02,0.08,0.03,0.15,0.05,0.23
	C215.46,462.4,215.34,462.45,215.23,462.49z M216.43,465.45c0.02-0.07,0.03-0.14,0.05-0.2c0,0.1-0.01,0.2-0.01,0.3
	C216.45,465.52,216.44,465.49,216.43,465.45z M216.49,462.47c0.02,0.34,0.02,0.68,0.02,1.02
	C216.24,463.15,216.31,462.82,216.49,462.47z M216.28,466.12c0.04,0.09,0.08,0.17,0.12,0.26c-0.05,0.21-0.12,0.41-0.17,0.62
	c-0.04,0-0.07,0-0.11,0C216.16,466.7,216.22,466.41,216.28,466.12z M216.36,468.95c-0.06-0.24-0.14-0.47-0.2-0.7
	C216.51,468.46,216.43,468.72,216.36,468.95z M222.58,454.66c0-0.06,0-0.11,0.01-0.17c0.75-0.24,1.51-0.48,2.26-0.72
	c0.01,0.15,0.02,0.29,0.04,0.44c-0.38,0.03-0.76,0.06-1.18,0.09c0.01,0.14,0.03,0.33,0.05,0.68
	C223.32,454.86,222.95,454.76,222.58,454.66z M225.48,460.01c-0.34,0.11-0.68,0.22-1,0.33c0.11-1.01-0.43-0.38-0.63-0.54
	c-0.26-0.21-0.57-0.3-0.86-0.45c0.02-0.1,0.03-0.2,0.05-0.3c0.81,0.24,1.62,0.49,2.44,0.73
	C225.48,459.87,225.48,459.94,225.48,460.01z M225.42,454.47c0.23-0.86,0.54-0.95,1.67-0.47
	C226.46,454.18,225.97,454.32,225.42,454.47z M227.98,445.81c-0.17,0.1-0.32,0.3-0.48,0.45c-0.23,0.21-0.46,0.4-0.79,0.69
	c0.04-1.19-0.79-2.03-1.55-1.85c0.13,0.14,0.23,0.25,0.4,0.42c-0.54,0.15-0.99,0.28-1.5,0.42c0.14,0.21,0.22,0.32,0.29,0.43
	c-0.02,0.09-0.04,0.17-0.06,0.26c-0.74-0.13-1.49-0.27-2.23-0.4c0.01-0.12,0.02-0.24,0.04-0.36c0.25,0.01,0.5,0.02,0.75,0.04
	c0.01-0.08,0.02-0.15,0.04-0.23c-0.24-0.11-0.47-0.24-0.72-0.32c-0.22-0.07-0.45-0.15-0.67-0.22c0.68-0.68,1.33-0.08,1.99-0.07
	c0.13,0,0.27,0.02,0.38-0.06c0.82-0.66,1.68-0.46,2.46-0.16c0.59,0.23,1.12,0.27,1.71,0.07c0.14-0.05,0.33,0.2,0.47,0.3
	c0.26-0.03,0.53-0.06,0.79-0.1c-0.09,0.05-0.19,0.13-0.29,0.18C228.65,445.46,228.31,445.61,227.98,445.81z M233.09,453.8
	c-0.06-0.08-0.19-0.13-0.28-0.1c-1.03,0.42-2.02,0.34-3-0.18c-0.27-0.14-0.58-0.14-0.87-0.2c0-0.1-0.01-0.19-0.01-0.29
	c0.24-0.1,0.49-0.3,0.73-0.27c0.65,0.07,1.3,0.18,1.93,0.37c0.72,0.22,1.42,0.61,2.17,0.03c-0.1,0.18-0.19,0.36-0.29,0.54
	c0.21,0.06,0.41,0.2,0.62,0.17c0.13-0.02,0.25-0.27,0.4-0.45c0.05,0.13,0.1,0.25,0.15,0.38
	C234.08,454.27,233.57,454.47,233.09,453.8z M237.22,456.25c-0.86,0.94-1.52,0.03-2.24-0.26
	C235.73,456.07,236.47,456.16,237.22,456.25z"
        />
        <path
            fill={textColor}
            d="M258.32,450.04c0.82,0.18,1.65,0.36,2.47,0.52c1.34,0.26,2.68,0.48,3.97,0.7c-0.14-0.34-0.23-0.61-0.36-0.84
	c-0.12-0.23-0.26-0.55-0.43-0.62c-0.43-0.19-0.88-0.28-1.33-0.37c-0.27-0.06-0.63-0.23-0.8-0.04c-0.82,0.89-1.46-0.01-2.17-0.27
	c0.26-0.08,0.53-0.17,0.93-0.29c-1.06-1.03-2.08-0.13-3.06-0.44c0.06,0.27,0.09,0.41,0.12,0.55c-0.05,0.06-0.1,0.11-0.15,0.17
	c-0.19-0.31-0.38-0.62-0.61-1c-0.31-0.04-0.74-0.11-1.17-0.17c0,0.03,0,0.06,0,0.09c0.29,0.15,0.58,0.3,0.87,0.45
	c-0.03,0.11-0.05,0.23-0.08,0.34c-1.17,0.15-2.11-0.81-3.08-1.66c-0.03,0.07-0.05,0.15-0.08,0.22c0.12,0.23,0.23,0.46,0.36,0.71
	c-1.08,0.3-1.65-0.01-1.7-0.92c-0.78,0.15-1.56,0.29-2.36,0.44c0.75,0.27,1.46,0.53,2.18,0.8c0.14,0.05,0.27,0.13,0.41,0.17
	c0.57,0.15,1.14,0.47,1.7,0.38c0.72-0.11,1.37-0.14,1.99,0.41c0.1,0.09,0.22,0.19,0.34,0.21c0.67,0.15,1.34,0.3,2.01,0.44
	c-0.02,0.01-0.04,0.02-0.05,0.03c0.04,0.01,0.08,0.02,0.11,0.02C258.34,450.07,258.33,450.05,258.32,450.04z"
        />
        <path
            fill={backgroundColor}
            d="M172.35,460.81c0-0.08-0.01-0.16-0.01-0.25c-1.88,0.13-3.74,1.5-5.65,0.52c0,0.12,0,0.25,0,0.37
	c0.29,0.01,0.58,0.03,0.87,0.04c0,0.1,0,0.2,0,0.3c-0.61,0.05-1.23,0.16-1.83,0.12c-0.35-0.03-0.69-0.32-1.04-0.46
	c-0.48-0.2-0.96-0.41-1.45-0.56c-0.1-0.03-0.23,0.15-0.35,0.22c-0.12,0.07-0.25,0.22-0.35,0.19c-0.9-0.26-1.79-0.54-2.68-0.87
	c-0.11-0.04-0.15-0.44-0.22-0.67c0.04-0.06,0.09-0.11,0.13-0.17c-0.52-0.29-1.04-0.57-1.56-0.86c-0.03,0.06-0.05,0.13-0.08,0.19
	c0.2,0.27,0.4,0.53,0.65,0.87c-0.72,0.35-0.98-0.66-1.5-0.8c0.09,0.3,0.22,0.55,0.39,0.72c0.55,0.55,1.13,1.04,1.68,1.6
	c0.16,0.17,0.25,0.5,0.37,0.76c-0.04,0.08-0.07,0.17-0.11,0.25c0.5,0.13,1.01,0.37,1.51,0.37c1.63,0.02,3.26-0.12,4.89-0.07
	c1.62,0.05,3.21-0.98,4.83-0.45c0.02,0,0.05-0.08,0.16-0.28c-0.43-0.06-0.8-0.12-1.17-0.17c0-0.05,0-0.1,0.01-0.15
	C170.67,461.31,171.51,461.06,172.35,460.81z"
        />
        <path
            fill={backgroundColor}
            d="M182.98,460.33c-0.27,0.01-0.59,0.05-0.81-0.13c-0.77-0.66-1.45-0.03-2.18,0.26c0.22,0.45,0.42,0.79,0.61,1.05
	c-0.16-0.02-0.33-0.04-0.49-0.06c-0.01,0.13-0.01,0.26-0.02,0.39c0.29,0.01,0.58,0.03,0.87,0.04c0.36,0.26,0.71,0.14,1.13-0.34
	c0.64-0.73,1.28-0.74,1.97-0.75c1.76-0.03,3.52-0.05,5.28-0.07c0.01-0.1,0.02-0.21,0.02-0.31c-0.61-0.09-1.22-0.25-1.84-0.25
	C186.02,460.18,184.5,460.28,182.98,460.33z"
        />
        <path
            fill={backgroundColor}
            d="M191.76,460.12c-0.71-0.1-1.43,0.54-1.93,1.62c1.13-0.17,2.3-0.35,3.47-0.53c0.01-0.08,0.02-0.16,0.02-0.24
	c-0.59-0.07-1.18-0.14-1.83-0.22C191.61,460.47,191.68,460.3,191.76,460.12z"
        />
        <path
            fill={textColor}
            d="M253.77,460.98c0.52,0.57,1.19,0.12,1.78,0.26c0,0.18,0.01,0.35,0.01,0.43c1.07,0.2,2.11,0.39,3.14,0.58
	c0.01-0.12,0.02-0.24,0.03-0.36c-1.63-0.43-3.27-0.87-4.9-1.3C253.81,460.72,253.79,460.85,253.77,460.98z"
        />
        <path
            fill={textColor}
            d="M232.33,445.26c0.65,0.19,1.19,0.52,1.71,0.45c0.52-0.08,1.02-0.56,1.54-0.87c-0.21-0.01-0.46-0.07-0.71-0.02
	c-0.27,0.05-0.53,0.23-0.8,0.29c-0.52,0.1-1.15-0.65-1.07-1.28c-0.23,0.02-0.45,0.04-0.81,0.07c0.33,0.32,0.54,0.53,0.77,0.75
	C232.74,444.85,232.59,445.01,232.33,445.26z"
        />
        <path
            fill={textColor}
            d="M242.44,446.59c0.55,0.1,1.13,0.25,1.71,0.3c0.21,0.02,0.44-0.17,0.66-0.27c-0.02-0.14-0.03-0.27-0.05-0.41
	c-0.81-0.04-1.61-0.08-2.44-0.12c0.11,0.11,0.22,0.23,0.38,0.39C242.55,446.54,242.48,446.57,242.44,446.59z"
        />
        <path
            fill={textColor}
            d="M242.23,463.69c0.01-0.24,0.02-0.47,0.04-0.8c0.6,0.14,1.22,0.28,1.85,0.42c0.01-0.05,0.02-0.1,0.03-0.16
	c-1.36-0.39-2.73-0.78-4.09-1.17C240.68,462.87,241.81,462.04,242.23,463.69z"
        />
        <path
            fill={textColor}
            d="M244.63,464.33c0.75,1.02,1.15-0.65,1.8-0.82c-0.59-0.03-1.18-0.06-1.75-0.08C244.66,463.67,244.64,464.01,244.63,464.33z"
        />
        <path
            fill={backgroundColor}
            d="M175.07,460.79c-0.01-0.07-0.03-0.15-0.04-0.22c-0.14,0.05-0.38,0.05-0.4,0.16c-0.22,0.93-0.69,0.99-1.21,0.93
	c-0.14-0.02-0.29,0.06-0.44,0.09c0.01,0.11,0.01,0.23,0.02,0.34c0.77-0.1,1.55-0.19,2.32-0.29c0-0.1,0.01-0.2,0.01-0.3
	c-0.19-0.06-0.39-0.13-0.65-0.21C174.84,461.07,174.96,460.93,175.07,460.79z"
        />
        <path
            fill={backgroundColor}
            d="M197,461.75c-0.53-0.92-1.29-0.11-1.89-0.42c-0.02,0.14-0.03,0.29-0.05,0.43c0.64,0.1,1.27,0.19,1.91,0.29
	C196.97,461.95,196.98,461.85,197,461.75z"
        />
        <path
            fill={backgroundColor}
            d="M177.3,461.63c0.7-0.25,1.4-0.5,2.08-0.74C178.05,460.72,177.6,460.88,177.3,461.63z"
        />
        <path
            fill={textColor}
            d="M221.03,464.39c0.23-0.39,0.36-0.62,0.46-0.79c-0.25-0.36-0.46-0.67-0.77-1.12C220.84,463.18,220.92,463.71,221.03,464.39z"
        />
        <path
            fill={textColor}
            d="M313.03,638.05c-0.09-0.23-0.18-0.55-0.3-0.62c-0.3-0.19-0.62-0.28-0.94-0.37c-0.19-0.06-0.44-0.23-0.57-0.04
	c-0.04,0.06-0.07,0.1-0.11,0.15c-0.26-0.43-0.57-0.72-0.91-0.88c-0.69-0.74-1.37,0.02-2.02-0.27c0.01,0.09,0.03,0.17,0.04,0.24
	c-0.09,0.01-0.18,0.01-0.27,0.01c-0.07-0.16-0.15-0.34-0.23-0.53c-0.22-0.04-0.52-0.11-0.82-0.17c0,0.03,0,0.06,0,0.09
	c0.21,0.15,0.41,0.3,0.62,0.45c-0.01,0.06-0.02,0.12-0.03,0.17c-0.25,0.01-0.49,0.02-0.74,0.03c-0.3-0.17-0.58-0.45-0.86-0.77
	c0.94,0.01,1.88,0.02,2.82,0.02c0.73-0.01,1.47-0.04,2.2-0.16c0.2-0.03,0.55-0.41,0.56-0.67c0.05-0.77,0.35-1.65-0.05-2.35
	c0.09-0.12,0.18-0.24,0.22-0.3c-0.46-0.26-0.93-0.51-1.39-0.79c-1.15-0.69-2.3-1.41-3.53-1.33c-0.87,0.05-1.73,0.04-2.6,0
	c-1.22-0.06-2.44-0.29-3.66-0.22c-1.75,0.11-3.5,0.43-5.25,0.65c-0.92,0.12-1.85,0.22-2.77,0.33c-0.04,0.01-0.09-0.01-0.13-0.02
	c-0.61-0.11-1.22-0.24-1.84-0.31c-0.51-0.06-1.03,0.05-1.54-0.09c-0.77-0.21-1.55,0.4-2.33-0.02c-0.37-0.2-0.81,0.1-1.2-0.01
	c-0.85-0.25-1.72,0.4-2.55-0.13c-0.03-0.02-0.06,0.02-0.09,0.01c-0.73-0.05-1.48,0.47-2.2-0.18c-0.13-0.12-0.29-0.06-0.44-0.06
	c-0.64,0.01-1.28,0.04-1.92,0.04c-0.11,0-0.22,0-0.32-0.01c-0.27-0.35-0.71-0.58-0.97-0.34c-0.35,0.33-0.62,0.25-0.93,0.1
	c-0.24-0.12-0.49-0.36-0.73-0.35c-0.69,0.03-1.38,0.18-2.08,0.22c-0.12,0.01-0.24-0.24-0.32-0.32c-0.13,0.08-0.24,0.15-0.36,0.23
	c-0.07-0.01-0.15-0.03-0.22-0.04c0-0.14-0.01-0.26-0.01-0.38c-0.13,0.13-0.24,0.23-0.33,0.32c-0.17-0.03-0.35-0.06-0.53-0.1
	c-0.02-0.09-0.03-0.19-0.04-0.33c-0.4,0.34-0.77,0.36-1.15-0.11c-0.13-0.16-0.34-0.05-0.65-0.07c0.13,0.17,0.22,0.27,0.28,0.36
	c-0.16-0.02-0.33-0.03-0.49-0.04c-0.11-0.12-0.22-0.24-0.33-0.23c-0.41,0.04-0.82,0.11-1.23,0.18c-0.31-0.03-0.63-0.09-0.94-0.2
	c-0.44-0.16-0.92,0.05-1.38,0.07c-0.19,0.01-0.38-0.03-0.57-0.08c-0.57-0.15-1.14-0.32-1.71-0.47c-0.04-0.01-0.08,0.04-0.14,0.08
	c0.04,0.08,0.08,0.15,0.11,0.22c-0.21-0.04-0.41-0.09-0.62-0.13c0-0.05-0.01-0.1-0.01-0.15c-0.31-0.32-0.59-0.22-0.88-0.01
	c-0.01,0-0.02,0-0.03,0c-0.08-0.01-0.16,0.1-0.24,0.22c-0.06,0.05-0.11,0.09-0.17,0.14c-0.31-0.12-0.63-0.3-0.96-0.32
	c-0.35-0.02-0.68,0.1-1.04,0.39c-0.18,0.14-0.38,0.15-0.59,0.12c0.32-0.12,0.63-0.24,0.91-0.35c-0.14-0.19-0.36-0.48-0.57-0.77
	c0,0.06,0,0.12,0,0.18c-0.32-0.02-0.64-0.04-0.98-0.07c0,0.01-0.01,0.23-0.07,0.31c-0.24,0.36-1.53,0.12-1.67-0.37
	c-0.26-0.91-0.65-0.76-1.07-0.61c0.14,0.19,0.28,0.37,0.43,0.56c0,0.09,0,0.18,0,0.27c-0.4,0.12-0.79,0.26-1.19,0.33
	c-0.02,0-0.05-0.06-0.08-0.16c0.21-0.18,0.31-0.38,0.36-0.58c0.06-0.05,0.13-0.1,0.19-0.15c-0.06-0.01-0.11-0.03-0.16-0.04
	c0.04-0.44-0.11-0.89-0.01-1.32c0.03-0.14-0.28-0.31-0.43-0.47c-0.11,0.01-0.21,0.01-0.31,0.02c0.2-0.5,0.4-1,0.59-1.5
	c0.16-0.42,0.37-0.84,0.42-1.26c0.08-0.69,0.03-1.39,0.05-2.08c0-0.1,0.06-0.25,0.16-0.29c0.2-0.07,0.29-0.15,0.31-0.23
	c0.04,0.27,0.04,0.58,0.93,0.54c-0.09-0.61-0.18-1.19-0.27-1.78c-0.03-0.21-0.13-0.43-0.07-0.62c0.42-1.2-0.28-2.44,0.39-3.67
	c0.3-0.56-0.12-1.29-0.2-1.95c-0.03-0.29-0.04-0.59-0.01-0.88c0.08-0.7,0.2-1.4,0.31-2.18c-1.07,0.73-1.31,1.36-0.94,1.78
	c-0.16,0.19-0.38,0.39-0.52,0.54c0.14-0.51,0.27-1.02,0.37-1.54c0.07-0.36-0.06-0.74-0.14-1.1c-0.06-0.28-0.19-0.55-0.29-0.83
	c0.08-0.01,0.16-0.03,0.24-0.04c0.09,0.1,0.17,0.2,0.26,0.3c-0.03-0.35-0.34-0.65-0.37-0.95c-0.06-0.69,0.02-1.39,0.03-2.08
	c0-0.27,0-0.54-0.08-0.8c-0.05-0.15-0.26-0.28-0.4-0.42c0.29-0.07,0.58-0.14,1.07-0.26c0-0.92-0.06-1.91,0.02-2.9
	c0.04-0.46,0.07-1.09,0.42-1.38c0.1,0.17,0.19,0.33,0.29,0.51c-1.38,0.88-0.26,2.04-0.44,3.18c0.2-0.17,0.33-0.28,0.46-0.39
	c0.07,0.01,0.15,0.03,0.22,0.04c-0.09,0.43-0.18,0.85-0.27,1.28c-0.07,0-0.13,0-0.2,0c-0.07-0.13-0.14-0.26-0.21-0.39
	c0.17,0.5-0.05,0.97-0.09,1.47c-0.58,0.28-0.28,0.61,0.11,1c0,0.51-0.2,1.05-0.28,1.61c0.24-0.08,0.54-0.17,0.75-0.24
	c-0.1,0.26-0.19,0.49-0.28,0.73c0.33-0.09,0.55-0.19,0.68-0.31c0.08,0.09,0.15,0.18,0.23,0.27c0.07-0.02,0.15-0.03,0.22-0.05
	c-0.14-0.51-0.23-1.04-0.42-1.54c-0.25-0.68-0.08-1.24,0.47-1.86c0.35-0.39,0.56-1.06,0.29-1.42c-0.36-0.48-0.29-0.87-0.16-1.31
	c0.1-0.35,0.32-0.7,0.3-1.05c-0.08-0.98-0.27-1.95-0.37-2.93c-0.02-0.17,0.22-0.35,0.3-0.46c-0.14-0.28-0.27-0.53-0.4-0.79
	c0.24-0.02,0.38-0.04,0.55-0.05c-0.77-0.97-0.75-1.15,0.05-1.28c-0.37-0.55-0.41-1.08,0.03-1.63c0.15-0.19,0.02-0.49,0.02-0.92
	c-0.25,0.31-0.37,0.45-0.48,0.59c-0.13-0.24-0.08-0.44,0.01-0.64c0.11-0.24,0.37-0.48,0.35-0.71c-0.11-0.96-0.32-1.91-0.45-2.87
	c-0.09-0.63-0.2-1.28-0.15-1.91c0.05-0.75,0.29-1.49,0.4-2.24c0.05-0.31-0.05-0.63-0.03-0.94c0.07-0.84,0.19-1.68,0.23-2.53
	c0.01-0.17-0.27-0.34-0.4-0.49c0.09-0.46,0.27-0.95,0.27-1.45c0-0.49-0.15-0.95-0.46-1.45c-0.35-0.55-0.06-1.29-0.03-1.94
	c0.03-0.85,0.13-1.7,0.11-2.55c-0.02-0.77-0.21-1.55-0.26-2.32c-0.11-1.71,0.03-3.42-0.54-5.12c-0.23-0.68,0-1.43-0.02-2.15
	c-0.01-0.3-0.09-0.61-0.19-0.9c-0.09-0.29-0.34-0.57-0.32-0.85c0.03-0.53,0.2-1.06,0.32-1.59c-0.05,0-0.1-0.01-0.16-0.01
	c-0.05,0.09-0.11,0.19-0.16,0.28c-0.18-0.51-0.15-0.99-0.19-1.48c-0.04-0.46-0.44-0.93,0.23-1.34c0.05-0.03,0.02-0.17-0.02-0.26
	c0.05,0.02,0.1,0.05,0.15,0.07c0.37-0.68,0.64-1.18,1-1.84c-0.43,0.15-0.62,0.21-0.65,0.23c-0.41-0.12-0.74-0.21-1.03-0.29
	c0.01-0.49,0.03-0.99,0.05-1.48c0.13-0.24,0.31-0.47,0.46-0.72c-0.42-0.15-0.39-0.15-0.28-0.59c0.01-0.04-0.05-0.11-0.13-0.16
	c0.05-2.25-0.01-4.48-0.67-6.64c-0.51-0.19-0.88-0.34-1.4-0.54c0,0.46,0.12,0.88-0.03,1.26c-0.23,0.62-0.66,1.21-0.96,1.82
	c-0.18,0.36-0.29,0.73-0.41,1.1c-0.04,0.13,0.07,0.35-0.02,0.39c-0.79,0.35-0.37,0.76-0.19,1.17c0.12,0.27,0.34,0.61,0.21,0.84
	c-0.04,0.08-0.08,0.15-0.11,0.23c-0.12,0.01-0.23,0.02-0.35,0.04c-0.11-1.34-0.23-2.66-0.29-3.98c-0.01-0.17,0.34-0.35,0.52-0.52
	c-0.07,0.01-0.14,0.01-0.21,0.02c-0.2-0.97-0.41-1.94-0.61-2.89c-0.79-0.2-1.2,0.08-1.41,0.53c-0.26,0.56-0.7,0.65-1.39,0.3
	c-0.12-0.06-0.28-0.08-0.52-0.14c-0.18,0.15-0.35,0.42-0.57,0.44c-1.29,0.12-2.12,0.76-2.77,1.35c-0.77,0.69-1.1,1.58-0.98,2.52
	c0.23,1.74,0.31,3.48,0.45,5.22c0.03,0.42-0.09,0.87,0.1,1.25c0.33,0.65,0.37,0.69-0.76,0.97c-0.03-0.22-0.08-0.42-0.09-0.62
	c-0.09-1.93-0.15-3.85-0.25-5.78c-0.06-1.03-0.14-2.07-0.31-3.1c-0.05-0.28-0.44-0.75-0.71-0.76c-0.77-0.03-1.68-0.41-2.34,0.19
	c-0.12-0.12-0.25-0.24-0.31-0.3c-0.23,0.67-0.44,1.34-0.69,2c-0.61,1.66-1.25,3.32-1.09,5.06c0.11,1.22,0.16,2.45,0.18,3.67
	c0.03,1.73-0.13,3.47,0.03,5.19c0.23,2.47,0.67,4.93,1.01,7.39c0.18,1.3,0.35,2.6,0.52,3.9c0.01,0.06,0,0.12-0.01,0.19
	c-0.06,0.87-0.16,1.74-0.19,2.61c-0.03,0.73,0.12,1.46,0.01,2.18c-0.16,1.1,0.51,2.17,0.14,3.29c-0.18,0.53,0.16,1.14,0.07,1.69
	c-0.19,1.21,0.52,2.41,0.04,3.61c-0.02,0.04,0.02,0.08,0.02,0.13c0,1.04,0.57,2.08-0.03,3.12c-0.11,0.18-0.04,0.42-0.02,0.63
	c0.05,0.9,0.13,1.81,0.17,2.71c0.04,0.83,0.06,1.65,0.06,2.48c-0.01,0.9-0.06,1.79-0.07,2.69c-0.01,0.45,0.08,0.89,0.06,1.34
	c-0.06,1.12-0.17,2.23-0.27,3.45c0.18-0.16,0.28-0.24,0.37-0.33c0.09,0.03,0.19,0.06,0.28,0.08c-0.14,0.27-0.28,0.53-0.42,0.8
	c-0.07,0.13-0.15,0.26-0.17,0.39c-0.26,1.73,0.19,3.47-0.16,5.2c-0.13,0.63,0.11,1.3,0.16,1.95c0.02,0.27,0,0.55-0.04,0.82
	c-0.11,0.82-0.24,1.63-0.36,2.44c-0.01,0.05,0.05,0.11,0.09,0.2c0.18-0.14,0.33-0.26,0.49-0.38c0.5,0.58,0.19,1.1-0.51,1.12
	c-0.73,1.09,1.03,1.9,0.68,2.95c0,0.01,0.09,0.04,0.29,0.13c0-0.44,0.12-0.83-0.02-1.18c-0.45-1.05-0.29-1.94,1.37-2.69
	c-0.25,1.01-0.47,1.92-0.69,2.83c-0.04,0.16-0.16,0.43-0.06,0.47c0.78,0.35,0.32,0.79,0.23,1.19c-0.06,0.25-0.37,0.57-0.23,0.72
	c0.64,0.71-0.39,1.27-0.12,1.92c-0.13,0.01-0.26,0.01-0.39,0.02c-0.24-0.74-0.48-1.48-0.69-2.13c-0.18,0.21-0.45,0.53-0.73,0.85
	c0.06,0,0.12,0,0.18-0.01c0,0.45,0,0.9,0,1.39c0.01,0,0.23,0.01,0.32,0.08c0.38,0.32,0.23,2.16-0.25,2.38
	c-0.89,0.41-0.72,0.96-0.54,1.54c0.18-0.21,0.35-0.42,0.53-0.63c0.09,0,0.18-0.01,0.27-0.01c0.12,0.47,0.26,0.94,0.36,1.41
	c-0.2,0-0.41,0.01-0.61,0.02c-0.08-0.13-0.17-0.27-0.26-0.41c-0.02,0.15-0.04,0.29-0.06,0.43c-0.6,0.06-1.21,0.19-1.8,0.4
	c-0.15,0.5-0.27,0.87-0.43,1.37c0.33,0.02,0.63-0.07,0.89,0.09c0.43,0.26,0.83,0.71,1.25,1.05c0.08,0.06,0.16,0.11,0.24,0.16
	c-0.11,0.32-0.1,0.68-0.25,1c-0.06,0-0.12,0-0.18,0c-0.12,0-0.23-0.35-0.35-0.54c0,0.07,0.01,0.14,0.01,0.22
	c-0.69,0.16-1.38,0.31-2.06,0.47c-0.17,0.78,0.02,1.2,0.33,1.44c0.39,0.29,0.43,0.73,0.16,1.4c-0.05,0.11-0.06,0.28-0.11,0.51
	c0.1,0.19,0.28,0.37,0.29,0.59c0.02,0.61,0.13,1.12,0.27,1.56c-0.51-0.13-1.03-0.25-1.52-0.37c0.1,0.34,0.16,0.61,0.25,0.84
	c0.09,0.23,0.18,0.55,0.3,0.62c0.3,0.19,0.62,0.28,0.94,0.37c0.19,0.06,0.44,0.23,0.57,0.04c0.04-0.06,0.07-0.1,0.11-0.15
	c0.26,0.43,0.57,0.72,0.91,0.88c0.37,0.4,0.73,0.37,1.09,0.29c0.11,0.13,0.2,0.26,0.19,0.39c0,0.02,0,0.03,0,0.05
	c-0.66,0.01-1.32,0.05-1.98,0.16c-0.2,0.03-0.55,0.41-0.56,0.67c-0.05,0.77-0.35,1.65,0.05,2.35c-0.09,0.12-0.18,0.24-0.22,0.3
	c0.45,0.25,0.89,0.49,1.34,0.76c-0.01,0.11-0.03,0.23-0.03,0.34c0,0.04,0,0.08-0.01,0.12c-0.08-0.07-0.17-0.13-0.25-0.2
	c-0.02,0.06-0.04,0.13-0.06,0.19c0.09,0.18,0.19,0.36,0.29,0.56c0.01,0.12,0.02,0.23,0.06,0.35c-0.43,0.14-0.61-0.71-0.96-0.85
	c0.07,0.3,0.16,0.55,0.27,0.72c0.39,0.55,0.8,1.04,1.19,1.6c0.12,0.17,0.18,0.5,0.26,0.76c-0.03,0.08-0.05,0.17-0.08,0.25
	c0.26,0.09,0.51,0.24,0.76,0.32c0,0.03-0.01,0.06-0.01,0.09c0.12,0,0.24,0.01,0.36,0.01c0-0.02,0.01-0.03,0.01-0.05
	c1.13,0.01,2.27-0.12,3.39-0.07c1.14,0.05,2.26-0.98,3.41-0.45c0.01,0.01,0.03-0.08,0.11-0.28c-0.31-0.06-0.56-0.12-0.82-0.17
	c0-0.05,0-0.1,0.01-0.15c0.4-0.17,0.81-0.34,1.21-0.51c0,0.01,0,0.02-0.01,0.03c-0.13,0.42-0.48,0.75-0.08,1.23
	c0.19,0.23-0.08,0.63-0.17,1.11c0.75,0.3-0.45,0.74-0.31,1.22c0.38-0.28,0.73-0.53,1.05-0.76c-0.51-0.72,0.04-1.44,0.24-2.34
	c0.13,0.3,0.18,0.43,0.24,0.56c0.02-0.01,0.04-0.01,0.07-0.02c0,0,0,0.01,0,0.01c0.12-0.02,0.25-0.04,0.37-0.07
	c0.26,0.05,0.29,0.33,0.3,0.44c0.02,0.51,0,1.05-0.2,1.54c-0.26,0.62-0.56,1.21-0.14,1.8c0.3,0,0.54,0,0.66,0
	c0.18-0.5,0.33-1.01,0.56-1.5c0.31-0.68,1.35-1.16,1.25-1.96c-0.03-0.2-0.02-0.42,0.08-0.61c0.08-0.14,0.16-0.22,0.24-0.25
	c-0.02,0.05-0.04,0.11-0.06,0.17c0.09-0.05,0.18-0.09,0.27-0.13c0.05,0.04,0.1,0.1,0.15,0.17c0.01-0.09,0.03-0.18,0.04-0.27
	c0.02-0.01,0.03-0.02,0.05-0.02c-0.02,0.12-0.04,0.23-0.06,0.35c-0.01-0.02-0.02-0.04-0.03-0.05c-0.01,0.04-0.01,0.08-0.02,0.12
	c0.02-0.01,0.03-0.02,0.04-0.03c-0.14,0.83-0.28,1.66-0.4,2.49c-0.2,1.35-0.35,2.7-0.51,4c0.33-0.16,0.6-0.26,0.82-0.4
	c0.23-0.14,0.53-0.28,0.6-0.46c0.17-0.44,0.23-0.89,0.31-1.34c0.04-0.27,0.2-0.64,0-0.8c-0.93-0.78-0.07-1.46,0.16-2.18
	c0.1,0.26,0.19,0.52,0.34,0.92c0.67-0.75,0.44-1.45,0.31-2.13c0.17,0.01,0.34,0.02,0.51,0.04c0.25,0.26,0.5,0.14,0.8-0.34
	c0.45-0.73,0.91-0.74,1.39-0.75c1.24-0.03,2.49-0.05,3.73-0.07c0.01-0.1,0.01-0.21,0.02-0.31c-0.43-0.09-0.86-0.25-1.3-0.25
	c-1.07,0.02-2.14,0.12-3.21,0.17c-0.19,0.01-0.42,0.05-0.57-0.13c-0.4-0.49-0.76-0.27-1.13-0.01c0.02-0.24,0.05-0.54,0.08-0.85
	c0.76-0.09,1.52-0.18,2.28-0.27c0.04-0.01,0.09,0.01,0.13,0.02c0.61,0.11,1.22,0.24,1.84,0.31c0.51,0.06,1.03-0.05,1.54,0.09
	c0.77,0.21,1.55-0.4,2.33,0.02c0.37,0.2,0.81-0.1,1.2,0.01c0.85,0.25,1.72-0.4,2.55,0.13c0.03,0.02,0.06-0.02,0.09-0.01
	c0.73,0.05,1.48-0.47,2.2,0.18c0.13,0.12,0.29,0.06,0.44,0.06c0.64-0.01,1.28-0.04,1.92-0.04c0.11,0,0.22,0,0.32,0.01
	c0.27,0.35,0.71,0.58,0.97,0.34c0.35-0.33,0.62-0.25,0.93-0.1c0.24,0.12,0.49,0.36,0.73,0.35c0.69-0.03,1.38-0.18,2.08-0.22
	c0.12-0.01,0.24,0.24,0.32,0.32c0.13-0.08,0.24-0.15,0.36-0.23c0.07,0.01,0.15,0.03,0.22,0.04c0,0.14,0.01,0.26,0.01,0.38
	c0.13-0.13,0.24-0.23,0.33-0.32c0.17,0.03,0.35,0.06,0.53,0.1c0.02,0.09,0.03,0.19,0.04,0.33c0.4-0.34,0.77-0.36,1.15,0.11
	c0.13,0.16,0.34,0.05,0.65,0.07c-0.13-0.17-0.22-0.27-0.28-0.36c0.16,0.02,0.33,0.03,0.49,0.04c0.11,0.12,0.22,0.24,0.33,0.23
	c0.41-0.04,0.82-0.11,1.23-0.18c0.31,0.03,0.63,0.09,0.94,0.2c0.44,0.16,0.92-0.05,1.38-0.07c0.19-0.01,0.38,0.03,0.57,0.08
	c0.57,0.15,1.14,0.32,1.71,0.48c0.04,0.01,0.08-0.04,0.14-0.08c-0.04-0.08-0.08-0.15-0.11-0.22c0.21,0.04,0.41,0.09,0.62,0.13
	c0,0.05,0.01,0.1,0.01,0.15c0.31,0.32,0.59,0.22,0.88,0.01c0.01,0,0.02,0,0.03,0c0.08,0.01,0.16-0.1,0.24-0.22
	c0.06-0.05,0.11-0.09,0.17-0.14c0.31,0.12,0.63,0.3,0.96,0.32c0.35,0.02,0.68-0.1,1.04-0.39c0.18-0.14,0.38-0.15,0.59-0.12
	c-0.32,0.12-0.63,0.24-0.91,0.35c0.14,0.19,0.36,0.48,0.57,0.77c0-0.06,0-0.12,0-0.18c0.32,0.02,0.64,0.04,0.98,0.07
	c0-0.01,0.01-0.23,0.07-0.31c0.24-0.36,1.53-0.12,1.67,0.37c0.26,0.91,0.65,0.76,1.07,0.61c-0.14-0.19-0.28-0.37-0.43-0.56
	c0-0.09,0-0.18,0-0.27c0.4-0.12,0.79-0.26,1.19-0.33c0.04-0.01,0.08,0.17,0.14,0.4c-0.21,0.16-0.4,0.32-0.62,0.49
	c0.6,0.16,1.15,0.3,1.69,0.44c0.01-0.09,0.03-0.18,0.04-0.28c-0.26-0.42-0.52-0.86-0.79-1.31c0.56,0.01,1.11,0.04,1.67,0.02
	c0.24,0.14,0.42,0.31,0.45,0.5c0.53-0.15,1.12-0.66,1.58-0.36c0.59,0.39,1.16,0.35,1.75,0.46c0.28,0.05,0.53,0.37,0.81,0.47
	c0.17,0.06,0.35-0.08,0.53-0.13c0.27-0.04,0.55,0.26,0.85,0.28c0.22,0.01,0.46-0.52,0.68-0.48c0.57,0.11,1.15,0.36,1.57,1.33
	c-0.3,0.14-0.59,0.27-0.87,0.41c0.36,0.08,0.68,0.2,1.06-0.15c0.41-0.37,0.96-0.01,1.44,0.04c0.45,0.05,0.92,0.28,1.43-0.78
	c-0.27-0.08-0.42-0.19-0.57-0.17c-0.44,0.04-0.94,0.57-1.24-0.56c-0.03-0.09-0.13-0.1-0.2-0.12c-0.24-0.06-0.48-0.11-0.73-0.18
	c-0.05-0.26-0.08-0.38-0.08-0.41c0.1-0.41,0.17-0.73,0.24-1.01c0.35,0.04,0.7,0.08,1.04,0.12c0.16,0.14,0.32,0.33,0.49,0.49
	c0.12-0.41,0.12-0.39,0.43-0.25c0.03,0.01,0.08-0.04,0.12-0.12c1.59,0.16,3.16,0.21,4.7-0.34c0.15-0.5,0.27-0.87,0.43-1.37
	c-0.33-0.02-0.63,0.07-0.89-0.09c-0.43-0.26-0.83-0.71-1.25-1.05c-0.25-0.2-0.51-0.33-0.76-0.46c-0.09-0.05-0.25,0.06-0.28-0.04
	c-0.22-0.81-0.52-0.41-0.82-0.25c-0.2,0.11-0.44,0.31-0.6,0.16c-0.05-0.05-0.1-0.09-0.15-0.12c0-0.12-0.01-0.23-0.01-0.36
	c0.95-0.04,1.88-0.11,2.82-0.1c0.12,0,0.23,0.35,0.35,0.54c0-0.07-0.01-0.14-0.01-0.22c0.69-0.16,1.38-0.31,2.06-0.47
	c0.17-0.78-0.02-1.2-0.33-1.44c-0.39-0.29-0.43-0.73-0.16-1.4c0.05-0.11,0.06-0.28,0.11-0.51c-0.1-0.19-0.28-0.37-0.29-0.59
	c-0.02-0.61-0.13-1.12-0.27-1.56c0.51,0.13,1.03,0.25,1.52,0.37C313.18,638.55,313.12,638.28,313.03,638.05z M256.02,598.02
	c-0.06-0.24-0.14-0.47-0.2-0.7C256.18,597.53,256.1,597.78,256.02,598.02z M256.16,591.53c0.02,0.34,0.02,0.68,0.02,1.02
	C255.91,592.21,255.98,591.89,256.16,591.53z M256.14,594.32c-0.01,0.25-0.02,0.51-0.02,0.76c-0.01,0.33-0.15,0.66-0.22,0.98
	c-0.04,0-0.07,0-0.11,0C255.87,595.48,256.01,594.9,256.14,594.32z M255.97,558.58c-0.09-0.03-0.16-0.06-0.21-0.1
	C255.83,558.51,255.9,558.55,255.97,558.58z M254.93,591.14c0.09,0.02,0.18,0.03,0.27,0.05c0.08,0.38,0.16,0.77,0.26,1.25
	c-0.27-0.07-0.58-0.11-0.58-0.16C254.86,591.91,254.9,591.53,254.93,591.14z M240.64,617.05c-0.07,0-0.15,0.01-0.22,0.01
	c-0.13-0.33-0.26-0.67-0.38-0.98c1.01,0.06,0.36-0.45,0.51-0.66c0.19-0.27,0.27-0.58,0.4-0.88c0.1,0.01,0.2,0.03,0.3,0.04
	C241.05,615.41,240.85,616.23,240.64,617.05z M240.77,606.27c0.34,0.35,0.61,0.69,0.15,1.12c-0.13,0.12-0.03,0.36,0.03,0.54
	c0.15,0.43,0.34,0.85,0.42,1.3C239.92,607.63,239.8,606.88,240.77,606.27z M304.27,636.11c0.06,0.04,0.12,0.08,0.18,0.1
	c0.13,0.05,0.26,0.12,0.39,0.18C304.47,636.62,304.37,636.68,304.27,636.11z M303.89,647.93c-0.03,0.07-0.05,0.13-0.08,0.2
	C303.83,648.04,303.86,647.98,303.89,647.93z M305.42,635.53c0.02,0.06,0.04,0.13,0.07,0.19c-0.49,0.19-0.83,0.13-1.02-0.19
	c0.06-0.01,0.11-0.02,0.17-0.02C304.89,635.52,305.16,635.53,305.42,635.53z M244.2,641.2c-0.13-0.05-0.26-0.12-0.39-0.18
	c0.37-0.24,0.47-0.29,0.57,0.28C244.32,641.26,244.26,641.22,244.2,641.2z M244.83,627.54c-0.12-0.09-0.24-0.21-0.35-0.35
	c0.14-0.28,0.33-0.56,0.43-0.84C244.88,626.75,244.85,627.14,244.83,627.54z M239.41,633.89c-0.26,0.02-0.51,0.04-0.77,0.05
	c0.03-0.14,0.06-0.27,0.09-0.4c0.23,0.02,0.46,0.04,0.68,0.05C239.41,633.69,239.41,633.79,239.41,633.89z M242.38,641.47
	c0.13,0.12,0.25,0.26,0.38,0.41c-0.14,0-0.29,0-0.43,0C242.34,641.74,242.36,641.6,242.38,641.47z M243.23,641.88
	c-0.02-0.06-0.04-0.13-0.07-0.19c0.49-0.19,0.83-0.13,1.02,0.19c-0.06,0.01-0.11,0.02-0.17,0.02
	C243.76,641.89,243.49,641.88,243.23,641.88z M278.6,646.57c-0.14-0.02-0.28-0.03-0.42-0.05c0.04-0.03,0.07-0.06,0.11-0.08
	C278.39,646.49,278.5,646.53,278.6,646.57z M270.05,630.84c0.14,0.02,0.28,0.03,0.42,0.05c-0.04,0.03-0.07,0.06-0.11,0.08
	C270.26,630.92,270.15,630.88,270.05,630.84z M254.47,628.58c0,0.01-0.01,0.01-0.01,0.02c0-0.01-0.01-0.02-0.01-0.03
	C254.46,628.58,254.47,628.58,254.47,628.58z M255.31,616.01c-0.13,0.14-0.24,0.25-0.4,0.42c-0.18-0.54-0.32-0.98-0.49-1.48
	c-0.21,0.16-0.31,0.23-0.41,0.31c-0.09-0.02-0.18-0.03-0.26-0.05c0.1-0.75,0.19-1.5,0.29-2.25c0.12,0.01,0.24,0.01,0.36,0.02
	c0,0.25,0,0.5,0,0.75c0.08,0.01,0.15,0.02,0.23,0.03c0.1-0.24,0.22-0.48,0.29-0.73c0.06-0.23,0.13-0.45,0.19-0.68
	c0.72,0.65,0.14,1.32,0.17,1.98c0.01,0.13-0.01,0.27,0.08,0.37c0.7,0.79,0.54,1.65,0.28,2.45c-0.2,0.61-0.22,1.13,0.01,1.71
	c0.06,0.14-0.19,0.34-0.28,0.49c0.05,0.26,0.08,0.52,0.14,0.79c-0.05-0.09-0.14-0.19-0.2-0.28c-0.19-0.33-0.35-0.67-0.57-0.99
	c-0.11-0.16-0.32-0.3-0.47-0.45c-0.22-0.22-0.42-0.44-0.73-0.75C254.73,617.64,255.52,616.77,255.31,616.01z M250.77,547.18
	c0.17,0.27,0.48,0.53,0.5,0.8c0.06,1.05,0.02,2.09,0.02,3.14c-0.1,0-0.2,0-0.3,0c-0.12-1.27-0.25-2.55-0.37-3.82
	C250.67,547.25,250.72,547.21,250.77,547.18z M247.28,620.18c0.1-0.01,0.19-0.02,0.29-0.02c0.11,0.24,0.32,0.48,0.31,0.72
	c-0.04,0.65-0.12,1.3-0.27,1.95c-0.18,0.73-0.54,1.45,0.07,2.17c-0.18-0.09-0.37-0.17-0.55-0.26c-0.05,0.21-0.18,0.42-0.14,0.62
	c0.02,0.13,0.28,0.24,0.46,0.38c-0.13,0.06-0.25,0.11-0.38,0.17c-0.49-0.52-0.71-1.02-0.07-1.54c0.08-0.07,0.12-0.2,0.08-0.28
	c-0.47-1.01-0.44-2,0.03-3.01C247.25,620.79,247.23,620.48,247.28,620.18z M246.51,618.38c-0.21-0.62-0.37-1.11-0.55-1.64
	C246.83,616.92,246.93,617.23,246.51,618.38z M245.63,613.9c0.06,0,0.11,0,0.17,0c0.28,0.74,0.56,1.48,0.83,2.23
	c-0.15,0.02-0.29,0.04-0.44,0.06c-0.05-0.38-0.1-0.76-0.15-1.17c-0.14,0.02-0.33,0.04-0.68,0.09
	C245.46,614.65,245.55,614.28,245.63,613.9z M239,632.38c0.1,0.06,0.21,0.11,0.31,0.17c0.09,0.05,0.25-0.06,0.28,0.04
	c0.04,0.15,0.09,0.25,0.14,0.33c-0.06,0.06-0.12,0.11-0.19,0.12c0.01,0.06,0.04,0.12,0.05,0.18c-0.26,0.01-0.52,0.01-0.79,0.02
	C238.87,632.95,238.94,632.67,239,632.38z M236.62,633.92c0.19-0.16,0.39-0.46,0.58-0.46c0.39,0,0.78,0.02,1.17,0.05
	c-0.16,0.18-0.41,0.34-0.8,0.46c0.2,0,0.4,0,0.65,0c-0.51,0.03-1.01,0.07-1.52,0.1C236.67,634.02,236.65,633.97,236.62,633.92z
	 M239.27,648.07c0.04-0.19,0.08-0.34,0.06-0.48c-0.04-0.34-0.23-0.7-0.21-1.03c0.41,0.24,0.82,0.45,1.24,0.61
	c-0.05,0-0.11,0.01-0.14,0.01c-0.06,0.43-0.11,0.85-0.17,1.28C239.78,648.34,239.52,648.21,239.27,648.07z M244.04,648.71
	c0,0.12,0,0.25,0,0.37c0.2,0.01,0.41,0.03,0.61,0.04c0,0.1,0,0.2,0,0.3c-0.43,0.05-0.87,0.16-1.29,0.12
	c-0.25-0.03-0.49-0.32-0.73-0.46c-0.34-0.2-0.68-0.41-1.02-0.56c-0.07-0.03-0.16,0.15-0.24,0.22c-0.08,0.07-0.18,0.22-0.25,0.19
	c-0.2-0.08-0.4-0.17-0.6-0.26c0.1-0.45,0.19-0.9,0.29-1.35c0.37,0.1,0.74,0.16,1.12,0.14c0.87-0.05,1.73-0.04,2.6,0
	c1.05,0.05,2.1,0.22,3.15,0.22c-0.01,0.2-0.05,0.4-0.1,0.6C246.4,648.6,245.23,649.58,244.04,648.71z M252.07,648.51
	c0.06-0.37,0.11-0.73,0.16-1.08c0.01-0.08,0.03-0.17,0.04-0.25c0.04-0.01,0.08-0.01,0.11-0.02c-0.03,0.05-0.05,0.1-0.06,0.15
	c-0.07,0.39-0.13,0.78-0.2,1.18C252.11,648.5,252.09,648.51,252.07,648.51z M253.55,649.08c-0.01-0.18-0.01-0.37,0.04-0.55
	c-0.27,0.07-0.41,0.11-0.54,0.14c-0.05-0.04-0.09-0.08-0.14-0.11c0.03-0.01,0.06-0.03,0.09-0.04c-0.03-0.01-0.06-0.01-0.09-0.01
	c0.17-0.12,0.35-0.24,0.54-0.37c0.15,0.42,0.29,0.76,0.42,1C253.76,649.11,253.66,649.1,253.55,649.08z M253.45,647.58
	c-0.12-0.02-0.23-0.04-0.35-0.06c-0.03-0.15-0.03-0.3-0.03-0.45c0.12-0.02,0.24-0.03,0.35-0.05c0.1-0.01,0.19-0.02,0.29-0.03
	C253.63,647.19,253.54,647.38,253.45,647.58z M294.18,648.82c0-0.01,0.01-0.01,0.01-0.02c0,0.01,0.01,0.02,0.01,0.03
	C294.19,648.83,294.18,648.83,294.18,648.82z M303.6,648.41c0.04,0,0.08-0.01,0.12-0.03c-0.02,0.05-0.04,0.1-0.06,0.14
	c0.08,0.07,0.15,0.13,0.22,0.19c-0.01,0.07-0.03,0.14-0.04,0.22C303.75,648.73,303.67,648.57,303.6,648.41z M304.46,649.22
	c-0.05-0.02-0.1-0.07-0.14-0.14c0.06,0.05,0.11,0.1,0.17,0.15C304.47,649.23,304.46,649.23,304.46,649.22z M312.03,643.49
	c-0.19,0.16-0.39,0.46-0.58,0.46c-0.74,0.01-1.48-0.08-2.22-0.13c0-0.1,0.01-0.2,0.01-0.3c0.9-0.06,1.81-0.12,2.71-0.19
	C311.98,643.39,312,643.44,312.03,643.49z"
        />
        <path
            fill={backgroundColor}
            d="M261.74,647.74c-0.5-0.1-1.01,0.54-1.36,1.62c0.8-0.17,1.62-0.35,2.45-0.53c0.01-0.08,0.01-0.16,0.02-0.24
	c-0.42-0.07-0.83-0.14-1.29-0.22C261.63,648.09,261.68,647.93,261.74,647.74z"
        />
        <path
            fill={textColor}
            d="M305.52,648.61c0.37,0.57,0.84,0.12,1.25,0.26c0,0.18,0,0.35,0.01,0.43c0.76,0.2,1.49,0.39,2.22,0.58
	c0.01-0.12,0.01-0.24,0.02-0.36c-1.15-0.43-2.31-0.87-3.46-1.3C305.55,648.34,305.54,648.48,305.52,648.61z"
        />
        <path
            fill={textColor}
            d="M297.38,651.31c0.01-0.24,0.02-0.47,0.03-0.8c0.42,0.14,0.86,0.28,1.31,0.42c0.01-0.05,0.01-0.11,0.02-0.16
	c-0.96-0.39-1.92-0.78-2.89-1.17C296.28,650.49,297.08,649.66,297.38,651.31z"
        />
        <path
            fill={textColor}
            d="M299.07,651.95c0.53,1.02,0.81-0.65,1.27-0.82c-0.42-0.03-0.84-0.06-1.24-0.08C299.09,651.29,299.08,651.63,299.07,651.95z"
        />
        <path
            fill={backgroundColor}
            d="M264.1,648.95c-0.01,0.14-0.02,0.29-0.03,0.43c0.45,0.1,0.9,0.19,1.35,0.29c0.01-0.1,0.02-0.2,0.03-0.3
	C265.07,648.45,264.53,649.26,264.1,648.95z"
        />
        <path
            fill={backgroundColor}
            d="M277.77,650.41c0.01,0.13,0.03,0.26,0.04,0.39c0.36,0.34,0.6-0.41,0.91-0.48c-0.01-0.14-0.03-0.28-0.04-0.41
	C278.38,650.07,278.08,650.24,277.77,650.41z"
        />
        <path
            fill={backgroundColor}
            d="M282.41,652.01c0.16-0.39,0.25-0.62,0.33-0.79c-0.18-0.36-0.32-0.67-0.54-1.12
	C282.27,650.8,282.33,651.33,282.41,652.01z"
        />
        <path
            fill={textColor}
            d="M300.62,628.98c0,0.08,0.01,0.16,0.01,0.25c1.33-0.13,2.64-1.5,3.99-0.52c0-0.12,0-0.25,0-0.37
	c-0.2-0.01-0.41-0.03-0.61-0.04c0-0.1,0-0.2,0-0.3c0.43-0.05,0.87-0.16,1.29-0.12c0.25,0.03,0.49,0.32,0.73,0.46
	c0.34,0.2,0.68,0.41,1.02,0.56c0.07,0.03,0.16-0.15,0.24-0.22c0.08-0.07,0.18-0.22,0.25-0.19c0.63,0.26,1.27,0.54,1.89,0.87
	c0.08,0.04,0.11,0.44,0.16,0.67c-0.03,0.06-0.06,0.11-0.09,0.17c0.37,0.29,0.73,0.57,1.1,0.86c0.02-0.06,0.04-0.13,0.06-0.19
	c-0.14-0.27-0.28-0.53-0.46-0.87c0.51-0.35,0.69,0.66,1.06,0.8c-0.07-0.3-0.16-0.55-0.27-0.72c-0.39-0.55-0.8-1.04-1.18-1.61
	c-0.12-0.17-0.18-0.5-0.26-0.76c0.03-0.08,0.05-0.17,0.08-0.25c-0.36-0.13-0.71-0.37-1.07-0.37c-1.15-0.02-2.3,0.12-3.45,0.07
	c-1.14-0.05-2.26,0.98-3.41,0.45c-0.01-0.01-0.03,0.08-0.11,0.28c0.3,0.06,0.56,0.12,0.82,0.17c0,0.05,0,0.1-0.01,0.15
	C301.8,628.48,301.21,628.73,300.62,628.98z"
        />
        <path
            fill={textColor}
            d="M293.1,629.45c0.19-0.01,0.42-0.05,0.57,0.13c0.54,0.66,1.02,0.03,1.54-0.26c-0.16-0.45-0.3-0.79-0.43-1.05
	c0.12,0.02,0.23,0.04,0.35,0.06c0-0.13,0.01-0.26,0.01-0.39c-0.21-0.01-0.41-0.03-0.62-0.04c-0.25-0.26-0.5-0.14-0.8,0.34
	c-0.45,0.73-0.91,0.74-1.39,0.75c-1.24,0.03-2.49,0.05-3.73,0.07c-0.01,0.1-0.01,0.21-0.02,0.31c0.43,0.09,0.86,0.25,1.3,0.25
	C290.96,629.6,292.03,629.5,293.1,629.45z"
        />
        <path
            fill={textColor}
            d="M286.91,629.67c0.5,0.1,1.01-0.54,1.36-1.62c-0.8,0.17-1.62,0.35-2.45,0.53c-0.01,0.08-0.01,0.16-0.02,0.24
	c0.42,0.07,0.83,0.14,1.29,0.22C287.02,629.32,286.97,629.48,286.91,629.67z"
        />
        <path
            fill={textColor}
            d="M298.72,629.22c0.1-0.05,0.27-0.05,0.28-0.16c0.15-0.93,0.48-0.99,0.86-0.93c0.1,0.02,0.21-0.06,0.31-0.09
	c0-0.11-0.01-0.22-0.01-0.34c-0.55,0.1-1.09,0.19-1.64,0.29c0,0.1,0,0.2-0.01,0.3c0.14,0.06,0.27,0.13,0.46,0.21
	c-0.12,0.21-0.2,0.35-0.28,0.5C298.7,629.08,298.71,629.15,298.72,629.22z"
        />
        <path
            fill={textColor}
            d="M284.55,628.45c0.01-0.14,0.02-0.29,0.03-0.43c-0.45-0.1-0.9-0.19-1.35-0.29c-0.01,0.1-0.02,0.2-0.03,0.3
	C283.58,628.96,284.12,628.14,284.55,628.45z"
        />
        <path
            fill={textColor}
            d="M297.12,628.15c-0.5,0.25-0.99,0.5-1.47,0.74C296.59,629.06,296.91,628.91,297.12,628.15z"
        />
        <path
            fill={backgroundColor}
            d="M270.88,627c-0.01-0.13-0.03-0.26-0.04-0.39c-0.36-0.34-0.6,0.41-0.91,0.48c0.01,0.14,0.03,0.28,0.04,0.41
	C270.27,627.34,270.57,627.17,270.88,627z"
        />
        <path
            fill={backgroundColor}
            d="M266.24,625.4c-0.16,0.39-0.25,0.62-0.33,0.79c0.18,0.36,0.32,0.67,0.54,1.12
	C266.38,626.61,266.32,626.08,266.24,625.4z"
        />
        <path
            fill={textColor}
            d="M432.86,586.13c-0.8-0.11-0.81-0.26-0.05-1.05c-0.17-0.01-0.32-0.02-0.55-0.04c0.13-0.21,0.26-0.41,0.4-0.64
	c-0.08-0.09-0.32-0.24-0.3-0.38c0.1-0.8,0.29-1.6,0.37-2.4c0.03-0.28-0.19-0.57-0.3-0.86c-0.13-0.37-0.2-0.68,0.16-1.08
	c0.27-0.3,0.05-0.84-0.29-1.16c-0.55-0.5-0.72-0.97-0.47-1.52c0.19-0.42,0.28-0.84,0.42-1.27c-0.07-0.01-0.15-0.03-0.22-0.04
	c-0.08,0.08-0.15,0.15-0.23,0.22c-0.13-0.1-0.35-0.18-0.68-0.26c0.09,0.19,0.18,0.38,0.28,0.59c-0.21-0.05-0.51-0.13-0.75-0.19
	c0.07,0.46,0.27,0.91,0.28,1.32c-0.39,0.32-0.68,0.6-0.11,0.82c0.04,0.4,0.27,0.8,0.09,1.2c0.07-0.11,0.14-0.21,0.21-0.32
	c0.07,0,0.13,0,0.2,0c0.09,0.35,0.18,0.7,0.27,1.05c-0.07,0.01-0.15,0.02-0.22,0.04c-0.13-0.09-0.26-0.19-0.46-0.32
	c0.18,0.94-0.93,1.88,0.44,2.61c-0.1,0.15-0.2,0.28-0.29,0.42c-0.35-0.24-0.38-0.76-0.42-1.13c-0.08-0.81-0.02-1.62-0.02-2.38
	c-0.49-0.1-0.78-0.15-1.07-0.21c0.14-0.11,0.35-0.22,0.4-0.35c0.08-0.21,0.09-0.44,0.08-0.66c-0.01-0.57-0.09-1.14-0.03-1.71
	c0.03-0.25,0.34-0.49,0.37-0.78c-0.09,0.08-0.17,0.16-0.26,0.24c-0.08-0.01-0.16-0.02-0.24-0.03c0.1-0.23,0.23-0.45,0.29-0.68
	c0.08-0.3,0.2-0.61,0.14-0.9c-0.1-0.42-0.23-0.84-0.37-1.26c0.14,0.12,0.36,0.29,0.52,0.44c-0.37,0.34-0.13,0.86,0.94,1.46
	c-0.11-0.64-0.23-1.21-0.31-1.79c-0.03-0.24-0.03-0.48,0.01-0.72c0.07-0.54,0.5-1.14,0.2-1.6c-0.67-1.01,0.03-2.02-0.39-3.01
	c-0.07-0.16,0.04-0.34,0.07-0.51c0.09-0.48,0.18-0.96,0.27-1.46c-0.9-0.03-0.9,0.23-0.93,0.44c-0.02-0.06-0.11-0.13-0.31-0.19
	c-0.1-0.03-0.16-0.15-0.16-0.23c-0.02-0.57,0.02-1.14-0.05-1.71c-0.05-0.35-0.26-0.69-0.42-1.04c-0.19-0.41-0.39-0.82-0.59-1.23
	c0.11,0.01,0.21,0.01,0.32,0.02c0.15-0.13,0.46-0.27,0.43-0.38c-0.17-0.61,0.42-1.29-0.57-1.83c-0.08-0.04-0.03-0.17,0-0.25
	c0.17-0.45,0.36-0.91,0.54-1.36c-0.1-0.02-0.2-0.04-0.3-0.05c-0.1,0.08-0.2,0.15-0.37,0.29c-0.28-0.41-0.64-0.78-0.74-1.17
	c-0.1-0.36,0.09-0.75,0.17-1.24c0.63-0.23,0.63-0.23,0.62-0.5c-0.16,0.01-0.33,0.02-0.48,0.03c0.02-0.83,0.31-1.63,0-2.53
	c0.04-0.01,0.18-0.06,0.19-0.13c0.07-0.46,0.12-0.93,0.08-1.39c0-0.01-0.01-0.02-0.01-0.03c0.12,0.08,0.29,0.15,0.49,0.19
	c0.59,0.12,1.16,0.23,0.64,0.69c-0.1,0.09,0.07,0.28,0.29,0.41c0.3-0.68,0.21-1.33-0.37-1.96c-0.11-0.12-0.08-0.31,0-0.45
	c0.3-0.5,0.65-0.98,0.99-1.48c-0.11-0.03-0.22-0.06-0.32-0.09c-0.37,0.56-0.74,1.12-1.17,1.78c-0.19-0.14-0.27-0.19-0.33-0.23
	c-0.11,0.15-0.21,0.31-0.35,0.45c-0.18,0.18-0.17,0.39-0.01,0.57c-0.13-0.07-0.3-0.14-0.41-0.22c-0.27-0.17-0.7-0.33-0.77-0.53
	c-0.19-0.55-0.23-1.11-0.34-1.71c0.18-0.01,0.45-0.02,0.64-0.03c-0.08-0.23-0.12-0.47-0.25-0.69c-0.12-0.21-0.34-0.4-0.49-0.6
	c-0.1-0.13-0.24-0.29-0.2-0.41c0.16-0.49,0.5-0.97,0.55-1.46c0.05-0.51-0.14-1.02-0.23-1.53c-0.09-0.49-0.18-0.98-0.28-1.53
	c-0.18,0.24-0.4,0.26-0.6-0.04c-0.1-0.15-0.1-0.33-0.08-0.5c0.1-0.66-0.94-1.05-1.25-1.61c-0.22-0.4-0.37-0.82-0.56-1.23
	c-0.11,0-0.36,0-0.66,0c-0.42,0.48-0.11,0.97,0.14,1.48c0.2,0.4,0.22,0.84,0.2,1.26c-0.01,0.12-0.06,0.53-0.74,0.3
	c-0.05,0.1-0.11,0.21-0.24,0.46c-0.2-0.74-0.76-1.33-0.24-1.92c-0.31-0.19-0.66-0.39-1.05-0.62c-0.14,0.39,1.06,0.75,0.31,1
	c0.09,0.4,0.36,0.73,0.17,0.91c-0.41,0.39-0.05,0.66,0.08,1.01c0.31,0.83,0.47,1.7-0.77,2.4c-0.19,0.11-0.33,0.25-0.22,0.37
	c0.04,0.34,0.13,0.69,0.11,1.03c-0.02,0.39-0.15,0.77-0.19,1.16c-0.03,0.28-0.01,0.56,0.03,0.83c0.04,0.31,0.12,0.62,0.2,0.93
	c0.09,0.33,0.37,0.68-0.6,0.8c-0.19,0.02-0.27,0.33-0.43,0.55c1.03,0.07,1,0.53,0.95,0.99c-0.03,0.38-0.05,0.75-0.07,1.13
	c0,0.07-0.03,0.18,0.03,0.2c1.05,0.36-0.07,0.47-0.21,0.69c-0.09,0-0.19,0-0.28,0.01c-0.08-0.64-0.16-1.28-0.25-1.93
	c-0.25-0.02-0.5-0.05-0.86-0.08c0.05,0.18,0.08,0.35,0.15,0.5c0.16,0.37,0.66,0.75-0.15,1.07c-0.02,0.01-0.02,0.03-0.02,0.05
	c-0.04,1.13-0.08,2.26-0.11,3.39c-0.01,0.51,0,1.02,0,1.54c-0.72-0.09-0.59-0.1-1.14-1.94c-0.23-0.78,0.31-1.45,0.28-2.34
	c-0.31,0.43-0.51,0.71-0.72,1c-0.36-0.58-1.09-1.11-0.39-1.9c0.21,0.3,0.34,0.48,0.5,0.71c0.07-0.41-0.05-0.68-0.58-1
	c-0.34-0.2-0.38-0.7-0.2-1c0.23-0.37,0.31-0.7,0.23-1.09c-0.24-1.12-0.39-2.24-0.58-3.37c-0.09,0-0.18,0-0.27,0
	c-0.03,0.18-0.07,0.36-0.12,0.68c-0.21-0.26-0.33-0.4-0.47-0.58c-0.09,0.09-0.17,0.16-0.28,0.27c-0.2-0.12-0.39-0.24-0.67-0.4
	c1.04-0.11,0.98-0.45,0.62-0.85c-0.06,0.18-0.12,0.36-0.18,0.54c-0.07,0.02-0.14,0.04-0.21,0.05c-0.17-0.16-0.43-0.3-0.48-0.47
	c-0.13-0.44-0.16-0.89-0.23-1.33c-0.01-0.08-0.01-0.21-0.1-0.24c-1.11-0.4-0.55-0.95-0.47-1.47c0.03-0.18-0.06-0.36-0.13-0.67
	c-1.1,0.54-0.89,1.1-0.88,1.62c0.02,0.57,0.34,1.21-0.06,1.68c-0.37,0.43-0.27,0.8-0.22,1.23c0.15-0.32,0.3-0.65,0.46-0.99
	c0.94,0.53,1.15,1.2,1.22,1.87c0.03,0.25-0.52,0.51-0.52,0.77c0,0.34,0.28,0.68,0.22,0.99c-0.06,0.21-0.22,0.42-0.17,0.61
	c0.08,0.32,0.38,0.63,0.41,0.96c0.07,0.68-0.01,1.35,0.34,2.05c0.27,0.54-0.28,1.21-0.47,1.82c0.58,0.11,0.92,1.81,0.6,2.65
	c-0.52-0.4-1-0.77-1.48-1.14c-0.09,0.01-0.19,0.03-0.28,0.04c0.1,0.63,0.21,1.27,0.32,1.97c0.19-0.25,0.36-0.47,0.53-0.7
	c0.23,0.08,0.4,0.14,0.39,0.18c-0.1,0.46-0.27,0.91-0.41,1.36c-0.09,0-0.18-0.01-0.27-0.01c-0.18-0.17-0.35-0.34-0.53-0.52
	c-0.18,0.48-0.35,0.92,0.54,1.26c0.48,0.18,0.63,1.69,0.25,1.95c-0.09,0.06-0.31,0.06-0.32,0.06c0,0.4,0,0.77,0,1.14
	c-0.06,0-0.12,0-0.18,0c0.27,0.26,0.55,0.53,0.73,0.7c0.21-0.53,0.45-1.14,0.69-1.74c0.13,0.01,0.26,0.01,0.39,0.02
	c-0.27,0.54,0.76,0.99,0.12,1.57c-0.14,0.12,0.17,0.39,0.23,0.59c0.09,0.33,0.55,0.69-0.23,0.98c-0.09,0.03,0.03,0.25,0.06,0.38
	c0.22,0.74,0.44,1.49,0.69,2.32c-1.66-0.62-1.82-1.35-1.37-2.21c0.15-0.28,0.02-0.61,0.02-0.97c-0.2,0.07-0.3,0.09-0.29,0.1
	c0.34,0.86-1.41,1.53-0.68,2.42c0.71,0.02,1.01,0.44,0.51,0.92c-0.16-0.1-0.31-0.2-0.49-0.32c-0.04,0.07-0.1,0.12-0.09,0.16
	c0.12,0.67,0.25,1.34,0.36,2c0.04,0.22,0.06,0.45,0.04,0.67c-0.05,0.53-0.29,1.08-0.16,1.6c0.35,1.42-0.1,2.85,0.16,4.27
	c0.02,0.11,0.11,0.21,0.17,0.32c0.14,0.22,0.28,0.44,0.42,0.66c-0.09,0.02-0.19,0.05-0.28,0.07c-0.09-0.07-0.19-0.14-0.37-0.27
	c0.1,1,0.2,1.91,0.27,2.83c0.03,0.36-0.06,0.73-0.06,1.1c0.01,0.74,0.07,1.47,0.07,2.21c0.01,0.68-0.02,1.35-0.06,2.03
	c-0.04,0.74-0.12,1.48-0.17,2.22c-0.01,0.17-0.08,0.37,0.02,0.52c0.6,0.85,0.03,1.71,0.03,2.56c0,0.03-0.03,0.07-0.02,0.1
	c0.48,0.99-0.23,1.97-0.04,2.96c0.09,0.46-0.25,0.95-0.07,1.39c0.37,0.92-0.3,1.79-0.14,2.7c0.1,0.59-0.04,1.19-0.01,1.79
	c0.03,0.72,0.12,1.43,0.19,2.14c0,0.05,0.02,0.1,0.01,0.15c-0.17,1.07-0.34,2.13-0.52,3.2c-0.34,2.02-0.78,4.04-1.01,6.06
	c-0.12,1.12-0.06,2.24-0.04,3.36c-0.58,0.97-1.21,1.9-1.88,2.79c-0.13-0.21-0.28-0.42-0.29-0.63c-0.01-0.5,0.13-1,0.21-1.5
	c0.1,0,0.2,0.01,0.3,0.01c0,0.24,0,0.47,0,0.71c0.12,0.01,0.25,0.01,0.37,0.02c-0.88-1.6,0.58-3.07,0.8-4.61
	c-0.08-0.01-0.16-0.01-0.24-0.02c-0.29,0.68-0.58,1.35-0.87,2.03c-0.05,0-0.1,0-0.15,0c-0.03-0.3-0.07-0.61-0.11-0.96
	c-0.21,0.08-0.3,0.1-0.29,0.12c0.44,1.35-0.66,2.61-0.69,3.93c0,0.12-0.01,0.24-0.02,0.36c-0.04,0.09-0.08,0.17-0.12,0.26
	c0.04-0.04,0.08-0.07,0.11-0.1c-0.03,0.59-0.08,1.17-0.14,1.76c-0.63,0.72-1.29,1.41-1.99,2.07c-0.04,0.04-0.09,0.07-0.14,0.1
	c-0.66,0.43-1.34,0.81-2,1.2c-0.56,0.33-1.07,0.75-1.66,0.99c-0.91,0.36-1.55,1.23-2.57,1.37c-0.49,0.07-0.88,0.5-1.37,0.61
	c-1.05,0.25-1.91,1.08-3.02,1.02c-0.04,0-0.07,0.03-0.1,0.04c-0.88,0.24-1.7,0.86-2.68,0.59c-0.17-0.05-0.37,0.04-0.55,0.08
	c-0.78,0.17-1.56,0.34-2.35,0.45c-0.72,0.1-1.45,0.17-2.17,0.2c-0.79,0.03-1.58-0.01-2.36-0.04c-0.39-0.01-0.79,0.04-1.17-0.01
	c-0.97-0.1-1.93-0.27-2.96-0.49c0.11,0.15,0.17,0.23,0.23,0.31c-0.04,0.07-0.07,0.13-0.11,0.2c-0.21-0.13-0.42-0.27-0.63-0.41
	c-0.1-0.07-0.2-0.15-0.31-0.18c-1.43-0.44-2.99-0.45-4.32-1.13c-0.48-0.25-1.09-0.26-1.63-0.41c-0.22-0.06-0.44-0.16-0.64-0.27
	c-0.61-0.33-1.21-0.68-1.79-1.05c-0.04-0.02-0.1-0.01-0.18-0.01c0.04,0.17,0.08,0.31,0.12,0.46c-0.63,0.13-0.92-0.27-0.67-0.75
	c-0.55-0.89-1.85-0.07-2.47-0.75c-0.01-0.01-0.07,0.04-0.22,0.13c0.32,0.19,0.57,0.44,0.89,0.49c0.97,0.15,1.57,0.58,1.57,1.97
	c-0.73-0.55-1.35-1.06-1.95-1.59c-0.11-0.09-0.26-0.28-0.33-0.24c-0.59,0.34-0.73-0.15-0.99-0.39c-0.16-0.15-0.25-0.49-0.43-0.48
	c-0.81,0.06-0.73-0.84-1.32-1.02c0.06-0.08,0.12-0.16,0.17-0.24c0.63,0.23,1.26,0.45,1.81,0.61c-0.07-0.21-0.17-0.52-0.26-0.84
	c-0.03,0.04-0.05,0.08-0.08,0.11c-0.31-0.21-0.62-0.43-0.95-0.67c0,0-0.11,0.13-0.21,0.15c-0.4,0.06-1.54-1-1.43-1.39
	c0.2-0.72-0.21-0.92-0.66-1.15c0.03,0.21,0.06,0.43,0.1,0.64c-0.05,0.05-0.09,0.1-0.14,0.15c-0.42-0.23-0.86-0.46-1.25-0.75
	c-0.04-0.03,0.02-0.16,0.09-0.34c0.27,0.08,0.53,0.15,0.81,0.22c-0.45-0.56-0.84-1.07-1.21-1.6c-0.06,0.04-0.13,0.07-0.19,0.11
	c-0.03,0.52-0.05,1.04-0.05,1.61c-0.57-0.33-1.21-1.2-1.44-1.81c0.06,0,0.11,0,0.17,0c-0.07-0.02-0.13-0.04-0.2-0.06
	c-0.08-0.22-0.09-0.41-0.02-0.5c-0.29-0.23-0.66-0.44-0.96-0.69c-0.06-0.22-0.15-0.43-0.42-0.6c0.02,0.07,0.04,0.14,0.06,0.21
	c-0.05-0.08-0.09-0.17-0.12-0.26c-0.12-0.46-0.33-0.85-0.54-1.22c0.38,0.29,0.74,0.57,1.1,0.85c0.09-0.01,0.19-0.03,0.28-0.04
	c-0.08-0.52-0.17-1.04-0.26-1.6c0.07,0.11,0.14,0.23,0.21,0.34c0.16-0.1,0.39-0.24,0.59-0.38c0.17-0.55-0.18-0.63-0.57-0.67
	c0.18-0.47,0.33-0.91-0.55-1.25c-0.48-0.18-0.63-1.69-0.25-1.95c0.09-0.06,0.31-0.06,0.32-0.07c0-0.4,0-0.77,0-1.14
	c0.06,0,0.12,0,0.18,0c-0.28-0.26-0.55-0.53-0.73-0.7c-0.21,0.53-0.45,1.14-0.69,1.74c-0.13-0.01-0.26-0.01-0.39-0.02
	c0.27-0.54-0.76-0.99-0.12-1.57c0.14-0.12-0.17-0.39-0.23-0.59c-0.09-0.33-0.55-0.69,0.23-0.98c0.09-0.03-0.03-0.25-0.06-0.38
	c-0.22-0.74-0.44-1.49-0.69-2.32c1.66,0.62,1.82,1.35,1.37,2.21c-0.15,0.28-0.02,0.61-0.02,0.97c0.2-0.07,0.3-0.09,0.29-0.1
	c-0.34-0.86,1.41-1.53,0.68-2.42c-0.71-0.02-1.01-0.44-0.51-0.92c0.16,0.1,0.31,0.2,0.49,0.32c0.04-0.07,0.1-0.12,0.09-0.16
	c-0.12-0.67-0.25-1.34-0.36-2c-0.04-0.22-0.06-0.45-0.04-0.67c0.05-0.53,0.29-1.08,0.16-1.6c-0.35-1.42,0.1-2.85-0.16-4.27
	c-0.02-0.11-0.11-0.22-0.17-0.32c-0.14-0.22-0.28-0.44-0.42-0.66c0.09-0.02,0.19-0.05,0.28-0.07c0.09,0.07,0.19,0.14,0.37,0.27
	c-0.1-1-0.2-1.91-0.27-2.83c-0.03-0.36,0.06-0.73,0.06-1.1c-0.01-0.74-0.07-1.47-0.07-2.21c-0.01-0.68,0.02-1.35,0.06-2.03
	c0.04-0.74,0.12-1.48,0.17-2.22c0.01-0.17,0.08-0.37-0.02-0.52c-0.6-0.85-0.03-1.71-0.03-2.56c0-0.03,0.03-0.07,0.02-0.1
	c-0.48-0.99,0.23-1.97,0.04-2.96c-0.09-0.46,0.25-0.95,0.07-1.39c-0.37-0.92,0.3-1.79,0.14-2.7c-0.1-0.59,0.04-1.19,0.01-1.79
	c-0.03-0.72-0.12-1.43-0.19-2.14c0-0.05-0.02-0.1-0.01-0.15c0.17-1.07,0.34-2.13,0.52-3.2c0.34-2.02,0.78-4.04,1.01-6.06
	c0.16-1.41,0-2.84,0.03-4.26c0.02-1,0.07-2.01,0.18-3.01c0.16-1.43-0.47-2.79-1.09-4.15c-0.25-0.55-0.46-1.09-0.69-1.64
	c-0.06,0.05-0.19,0.15-0.31,0.25c-0.67-0.49-1.57-0.18-2.34-0.15c-0.27,0.01-0.66,0.39-0.71,0.63c-0.17,0.84-0.26,1.69-0.31,2.54
	c-0.1,1.58-0.17,3.16-0.25,4.74c-0.01,0.17-0.06,0.34-0.09,0.51c-1.13-0.23-1.09-0.26-0.76-0.79c0.19-0.31,0.07-0.68,0.1-1.02
	c0.14-1.43,0.22-2.86,0.45-4.28c0.12-0.78-0.2-1.5-0.98-2.07c-0.65-0.48-1.48-1-2.77-1.1c-0.22-0.02-0.38-0.24-0.57-0.36
	c-0.24,0.05-0.4,0.06-0.52,0.11c-0.69,0.29-1.13,0.21-1.39-0.25c-0.21-0.37-0.62-0.6-1.41-0.44c-0.2,0.78-0.41,1.58-0.61,2.37
	c-0.07-0.01-0.14-0.01-0.21-0.02c0.18,0.14,0.52,0.29,0.52,0.43c-0.06,1.08-0.19,2.17-0.29,3.26c-0.12-0.01-0.24-0.02-0.35-0.03
	c-0.03-0.06-0.07-0.12-0.11-0.18c-0.13-0.19,0.08-0.46,0.21-0.69c0.18-0.34,0.6-0.67-0.19-0.96c-0.09-0.03,0.02-0.22-0.02-0.32
	c-0.12-0.3-0.23-0.61-0.41-0.9c-0.3-0.5-0.73-0.98-0.96-1.49c-0.14-0.31-0.03-0.66-0.03-1.03c-0.51,0.16-0.89,0.28-1.4,0.44
	c-0.66,1.77-0.72,3.6-0.67,5.44c-0.08,0.05-0.14,0.1-0.13,0.13c0.11,0.36,0.13,0.36-0.28,0.49c0.15,0.2,0.33,0.4,0.46,0.59
	c0.02,0.4,0.03,0.81,0.05,1.22c-0.28,0.07-0.61,0.14-1.03,0.24c-0.03-0.01-0.22-0.06-0.65-0.19c0.35,0.54,0.63,0.95,1,1.51
	c0.05-0.02,0.1-0.04,0.15-0.06c-0.04,0.07-0.07,0.18-0.02,0.21c0.66,0.34,0.27,0.72,0.23,1.1c-0.04,0.4-0.01,0.79-0.19,1.21
	c-0.05-0.08-0.11-0.15-0.16-0.23c-0.05,0-0.1,0.01-0.16,0.01c0.11,0.43,0.29,0.87,0.32,1.3c0.02,0.23-0.23,0.46-0.32,0.7
	c-0.09,0.24-0.18,0.49-0.19,0.74c-0.02,0.59,0.2,1.2-0.02,1.76c-0.57,1.39-0.42,2.8-0.54,4.2c-0.05,0.64-0.24,1.27-0.26,1.9
	c-0.02,0.7,0.07,1.39,0.11,2.09c0.03,0.54,0.31,1.14-0.03,1.59c-0.31,0.41-0.46,0.79-0.46,1.19c0,0.4,0.18,0.81,0.27,1.19
	c-0.13,0.13-0.4,0.27-0.4,0.4c0.04,0.69,0.16,1.38,0.23,2.07c0.03,0.26-0.08,0.52-0.03,0.77c0.11,0.61,0.35,1.22,0.4,1.83
	c0.05,0.52-0.06,1.05-0.15,1.57c-0.14,0.79-0.34,1.57-0.45,2.35c-0.03,0.19,0.24,0.39,0.35,0.58c0.09,0.16,0.14,0.33,0.01,0.52
	c-0.11-0.11-0.22-0.23-0.48-0.48c0,0.35-0.13,0.59,0.02,0.75c0.44,0.46,0.4,0.89,0.03,1.34c0.8,0.11,0.81,0.26,0.05,1.05
	c0.17,0.01,0.32,0.02,0.55,0.04c-0.13,0.21-0.26,0.41-0.4,0.64c0.08,0.09,0.32,0.24,0.3,0.38c-0.1,0.8-0.29,1.6-0.37,2.4
	c-0.03,0.28,0.19,0.57,0.3,0.86c0.13,0.37,0.2,0.68-0.16,1.08c-0.27,0.3-0.05,0.84,0.29,1.16c0.55,0.5,0.72,0.97,0.47,1.52
	c-0.19,0.42-0.28,0.84-0.42,1.27c0.07,0.01,0.15,0.03,0.22,0.04c0.08-0.08,0.15-0.15,0.23-0.22c0.13,0.1,0.35,0.18,0.68,0.26
	c-0.09-0.19-0.18-0.38-0.28-0.59c0.21,0.05,0.51,0.13,0.75,0.19c-0.07-0.46-0.27-0.91-0.28-1.32c0.39-0.32,0.68-0.6,0.11-0.82
	c-0.04-0.4-0.27-0.8-0.09-1.2c-0.07,0.11-0.14,0.21-0.21,0.32c-0.07,0-0.13,0-0.2,0c-0.09-0.35-0.18-0.7-0.27-1.05
	c0.07-0.01,0.15-0.02,0.22-0.04c0.13,0.09,0.26,0.19,0.46,0.32c-0.18-0.94,0.93-1.88-0.44-2.61c0.1-0.15,0.2-0.28,0.29-0.42
	c0.35,0.24,0.38,0.76,0.42,1.13c0.08,0.81,0.02,1.62,0.02,2.38c0.49,0.1,0.78,0.15,1.07,0.21c-0.14,0.11-0.35,0.22-0.4,0.35
	c-0.08,0.21-0.09,0.44-0.08,0.66c0.01,0.57,0.09,1.14,0.03,1.71c-0.03,0.25-0.34,0.49-0.37,0.78c0.09-0.08,0.17-0.16,0.26-0.24
	c0.08,0.01,0.16,0.02,0.24,0.03c-0.1,0.23-0.23,0.45-0.29,0.68c-0.08,0.3-0.2,0.61-0.14,0.9c0.1,0.42,0.23,0.84,0.37,1.26
	c-0.14-0.12-0.36-0.29-0.52-0.44c0.37-0.34,0.13-0.86-0.94-1.46c0.11,0.64,0.23,1.21,0.31,1.79c0.03,0.24,0.03,0.48-0.01,0.72
	c-0.07,0.54-0.5,1.14-0.2,1.6c0.67,1.01-0.03,2.02,0.39,3.01c0.07,0.16-0.04,0.34-0.07,0.51c-0.09,0.48-0.18,0.96-0.27,1.46
	c0.9,0.03,0.9-0.23,0.93-0.44c0.02,0.06,0.11,0.13,0.31,0.19c0.1,0.03,0.16,0.15,0.16,0.23c0.02,0.57-0.02,1.14,0.05,1.71
	c0.05,0.35,0.26,0.69,0.42,1.04c0.12,0.26,0.24,0.51,0.37,0.77c0,0.08-0.01,0.16-0.02,0.24c0,0.06-0.01,0.14-0.02,0.2
	c-0.02,0-0.04,0-0.06,0c-0.15,0.13-0.46,0.27-0.43,0.38c0.17,0.61-0.42,1.29,0.57,1.83c0.08,0.04,0.03,0.17,0,0.25
	c-0.06,0.16-0.12,0.31-0.19,0.47c-0.03-0.09-0.05-0.17-0.08-0.27c-0.13,0.34-0.19,0.67-0.2,0.99c-0.02,0.06-0.05,0.11-0.07,0.17
	c0.02,0,0.04,0.01,0.07,0.01c0.02,1.04,0.52,1.98,0.55,2.95c0.19-0.13,0.28-0.19,0.38-0.25c0.04,0.03,0.07,0.07,0.11,0.1
	c0,0.06-0.01,0.13-0.01,0.19c-0.15,0.26-0.31,0.52-0.49,0.84c0.06,0.4,0.15,0.96,0.25,1.51c0.02-0.01,0.04-0.01,0.07-0.02
	c-0.06,0.45-0.12,0.89-0.07,1.34c0,0.01,0.01,0.02,0.01,0.03c-0.12-0.08-0.29-0.15-0.49-0.19c-0.59-0.12-1.16-0.23-0.64-0.69
	c0.1-0.09-0.07-0.28-0.29-0.41c-0.3,0.68-0.21,1.33,0.37,1.96c0.11,0.12,0.08,0.31,0,0.45c-0.3,0.5-0.65,0.98-0.99,1.48
	c0.11,0.03,0.22,0.06,0.32,0.09c0.37-0.56,0.74-1.12,1.17-1.78c0.19,0.14,0.27,0.19,0.33,0.23c0.11-0.15,0.21-0.31,0.35-0.45
	c0.18-0.18,0.17-0.39,0.01-0.57c0.11,0.06,0.25,0.13,0.36,0.19c-0.06,0.44-0.14,0.89-0.21,1.33c0.06,0.01,0.13,0.02,0.19,0.03
	c0.12-0.2,0.23-0.4,0.36-0.62c0.35,0.65,0.52,1.15,0.52,1.53c-0.12,0.01-0.25,0.01-0.35,0.01c0.06,0.17,0.11,0.34,0.17,0.51
	c-0.07,0.08-0.15,0.16-0.25,0.21c0.27,0.49,0.54,0.97,0.83,1.44c-0.15,0.33-0.32,0.66-0.36,0.99c-0.13-0.14-0.26-0.29-0.38-0.43
	c0-0.02,0.01-0.03,0.01-0.05c0.11-0.45-0.18-0.94-0.3-1.41c-0.03-0.12-0.1-0.23-0.15-0.34c-0.23-0.59-0.45-1.19-0.69-1.81
	c-0.19,0.65-0.37,1.28-0.56,1.92c0.91,0.08,1.19,0.55,0.84,1.43c-0.24-0.11-0.47-0.22-0.69-0.32c-0.08,0.02-0.15,0.04-0.23,0.06
	c0.8,0.82,1.72,1.63,1.51,2.58c-0.12,0.02-0.23,0.04-0.35,0.05c-0.14-0.24-0.27-0.49-0.41-0.73c-0.03,0-0.06,0-0.09,0
	c0.04,0.36,0.08,0.71,0.11,0.96c0.37,0.21,0.67,0.38,0.96,0.54c-0.06,0.04-0.12,0.08-0.18,0.12c-0.13-0.03-0.27-0.06-0.54-0.12
	c0.26,0.81-0.68,1.62,0.29,2.52c0.15-0.33,0.24-0.54,0.34-0.75c0.23,0.59,1.09,1.15,0.16,1.79c-0.2,0.13-0.04,0.44,0,0.66
	c0.07,0.37,0.14,0.74,0.31,1.1c0.07,0.14,0.37,0.26,0.6,0.38c0.22,0.11,0.49,0.19,0.82,0.33c-0.16-1.06-0.31-2.17-0.51-3.28
	c-0.12-0.68-0.26-1.36-0.4-2.05c0.01,0.01,0.02,0.01,0.04,0.03c-0.01-0.03-0.01-0.06-0.02-0.09c-0.01,0.01-0.02,0.03-0.03,0.04
	c-0.11-0.55-0.23-1.11-0.35-1.66c-0.02-0.1-0.11-0.2-0.2-0.29c-0.4-0.41-0.45-0.83-0.39-1.27c0.15,0.16,0.3,0.33,0.45,0.49
	c0.04,0.38,0.16,0.77,0.23,1.16c0.09,0.49,0.18,0.98,0.28,1.53c0.18-0.24,0.4-0.26,0.6,0.04c0.1,0.15,0.1,0.33,0.08,0.5
	c-0.1,0.66,0.94,1.05,1.25,1.61c0.22,0.41,0.37,0.82,0.56,1.23c0.11,0,0.35,0,0.63,0c0.39,0.56,0.78,1.11,1.2,1.65
	c-0.01-0.15-0.03-0.31-0.05-0.53c0.15,0.11,0.22,0.16,0.25,0.19c-0.35-0.55-0.68-1.15-1.06-1.73c-0.03-0.05-0.08-0.1-0.12-0.14
	c-0.02-0.29-0.19-0.59-0.35-0.9c-0.14-0.29-0.19-0.59-0.2-0.9c0.06,0.07,0.19,0.08,0.22,0.08c0.48,1.22,1.31,2.04,2.04,2.96
	c-0.11,0.06-0.22,0.12-0.33,0.18c0.22,0.31,0.23,0.31,0.83,0.28c0.48,0.5,0.94,0.83,1.21,1.25c0.29,0.45,0.41,1.01,0.65,1.56
	c-0.23-0.05-0.36-0.08-0.5-0.11c-0.04,0.07-0.09,0.14-0.13,0.2c0.55,0.38,1.1,0.75,1.66,1.1c0.1,0.06,0.26,0.16,0.26,0.24
	c-0.01,1.03,1.04,1.37,1.61,2.01c0.11,0.12,0.42,0.08,0.64,0.1c0.05-0.06,0.1-0.13,0.15-0.19c0.34,0.47,0.69,0.93,1.06,1.39
	c0.31,0.38,0.6,0.78,0.98,1.09c0.62,0.5,1.33,0.91,2.01,1.34c0.1,0.06,0.22,0.18,0.21,0.27c-0.02,0.17,0.02,0.27,0.09,0.33
	c-0.29-0.13-0.6-0.32-0.97,0.25c0.66,0.32,1.3,0.6,1.95,0.88c0.23,0.1,0.5,0.15,0.68,0.3c1.09,0.93,2.69,1.07,3.83,2.07
	c0.52,0.45,1.49,0.47,2.26,0.68c0.34,0.09,0.68,0.2,1.01,0.33c0.78,0.31,1.56,0.63,2.44,0.94c-0.58-0.97-1.22-1.35-1.8-1.23
	c-0.17-0.18-0.33-0.4-0.46-0.55c0.53,0.28,1.07,0.55,1.63,0.78c0.39,0.16,0.86,0.19,1.3,0.23c0.34,0.04,0.68,0.02,1.02,0.02
	c0,0.06-0.01,0.12-0.01,0.18c-0.13,0.04-0.27,0.07-0.4,0.11c0.41,0.08,0.82-0.07,1.19-0.02c0.81,0.12,1.61,0.33,2.42,0.46
	c0.31,0.05,0.64,0.09,0.96,0.07c0.19-0.01,0.37-0.15,0.56-0.23c0.04,0.22,0.08,0.44,0.15,0.82c1.1,0.13,2.29,0.19,3.48,0.3
	c0.55,0.05,1.3,0.09,1.64,0.36c-0.21,0.07-0.41,0.14-0.64,0.21c-0.98-1.04-2.46-0.3-3.81-0.57c0.18,0.17,0.3,0.27,0.42,0.38
	c-0.03,0.05-0.06,0.11-0.08,0.16c-0.51-0.12-1.01-0.24-1.51-0.37c0.01-0.05,0.02-0.1,0.03-0.14c0.17-0.03,0.33-0.06,0.49-0.1
	c-0.62,0.06-1.16-0.18-1.74-0.3c-0.23-0.47-0.67-0.32-1.21-0.12c-0.6-0.11-1.2-0.38-1.84-0.58c0.03,0.19,0.08,0.43,0.11,0.6
	c-0.28-0.14-0.54-0.26-0.79-0.39c0.03,0.26,0.1,0.45,0.21,0.57c-0.13,0.03-0.25,0.06-0.38,0.09c0,0.06,0,0.12,0,0.17
	c0.65,0.05,1.29,0.11,1.94,0.09c0.86-0.02,1.5,0.21,2.16,0.72c0.42,0.32,1.21,0.58,1.7,0.44c0.65-0.2,1.11-0.1,1.64,0.03
	c0.42,0.11,0.84,0.3,1.27,0.3c1.22,0.01,2.45-0.12,3.67-0.22c0.21-0.02,0.43,0.16,0.57,0.21c0.35-0.12,0.66-0.22,0.98-0.34
	c0.02,0.17,0.04,0.28,0.06,0.41c1.22-0.63,1.42-0.65,1.61-0.07c0.68-0.33,1.32-0.43,2.04-0.19c0.25,0.08,0.61-0.06,1.14-0.14
	c-0.41-0.13-0.58-0.19-0.76-0.25c0.28-0.14,0.54-0.13,0.79-0.11c0.3,0.03,0.62,0.18,0.9,0.12c1.18-0.28,2.33-0.67,3.47-1.05
	c0.76-0.25,1.51-0.54,2.27-0.73c0.9-0.22,1.83-0.35,2.73-0.58c0.38-0.1,0.73-0.31,1.1-0.43c1-0.33,2.01-0.66,2.98-1.09
	c0.19-0.08,0.31-0.36,0.44-0.54c0.55-0.19,1.15-0.35,1.7-0.64c0.55-0.29,1.01-0.68,1.43-1.19c0.48-0.58,1.36-0.88,2.05-1.32
	c0.9-0.57,1.81-1.14,2.65-1.82c0.76-0.62,1.43-1.36,2.13-2.05c1.56-1.52,3.15-3.05,4.22-5.05c0.43-0.8,1.13-1.47,1.65-2.25
	c0.22-0.33,0.39-0.7,0.55-1.07c0.15-0.36,0.21-0.78,0.41-1.09c0.38-0.58,0.84-1.12,1.24-1.69c-0.03-0.03-0.06-0.05-0.09-0.08
	c-0.09,0.09-0.18,0.17-0.28,0.26c0.22-0.67,0.54-1.23,0.8-1.83c0.25-0.57,0.26-1.29,0.91-1.57c0.05-0.02,0.11-0.2,0.13-0.33
	c0.02,0.05,0.04,0.1,0.06,0.14c0.18-0.21,0.34-0.41,0.5-0.61c-0.01,0.16-0.04,0.33-0.04,0.5c0.51-0.16,0.89-0.28,1.4-0.44
	c0.66-1.77,0.72-3.6,0.67-5.44c0.08-0.05,0.14-0.1,0.13-0.13c-0.11-0.36-0.13-0.36,0.28-0.49c-0.15-0.2-0.33-0.39-0.46-0.59
	c-0.02-0.4-0.03-0.81-0.05-1.22c0.28-0.07,0.61-0.14,1.03-0.24c0.03,0.01,0.22,0.06,0.65,0.19c-0.35-0.54-0.63-0.95-1-1.51
	c-0.05,0.02-0.1,0.04-0.15,0.06c0.04-0.07,0.07-0.18,0.02-0.21c-0.19-0.1-0.29-0.2-0.34-0.31c0.04-0.24,0.07-0.48,0.1-0.73
	c0-0.02,0.01-0.04,0.01-0.06c0-0.02,0-0.04,0-0.07c0.04-0.29,0.07-0.58,0.09-0.86c0.02-0.09,0.05-0.18,0.09-0.28
	c0.05,0.08,0.11,0.15,0.16,0.23c0.05,0,0.1-0.01,0.15-0.01c-0.11-0.42-0.27-0.84-0.31-1.26c0-0.03,0-0.07,0-0.1
	c0.02-0.21,0.23-0.42,0.31-0.64c0.09-0.24,0.18-0.49,0.19-0.74c0.02-0.59-0.2-1.2,0.02-1.76c0.57-1.39,0.42-2.8,0.54-4.2
	c0.05-0.64,0.24-1.27,0.26-1.9c0.02-0.7-0.07-1.39-0.11-2.09c-0.03-0.54-0.31-1.14,0.03-1.59c0.31-0.41,0.46-0.79,0.46-1.19
	c0-0.4-0.18-0.81-0.27-1.19c0.13-0.13,0.4-0.27,0.4-0.4c-0.04-0.69-0.16-1.38-0.23-2.07c-0.03-0.26,0.08-0.52,0.03-0.77
	c-0.11-0.61-0.35-1.22-0.4-1.83c-0.05-0.52,0.06-1.05,0.15-1.57c0.14-0.79,0.35-1.57,0.45-2.35c0.03-0.19-0.24-0.39-0.35-0.58
	c-0.09-0.16-0.14-0.33-0.01-0.52c0.11,0.11,0.22,0.23,0.48,0.48c0-0.35,0.13-0.59-0.02-0.75
	C432.45,587.01,432.49,586.58,432.86,586.13z M414.57,558.89c0-0.01,0-0.02,0-0.03c0.01,0,0.02,0.01,0.03,0.01
	C414.58,558.88,414.58,558.89,414.57,558.89z M360.9,612.6c0.09,0,0.18,0.01,0.27,0.01c0.17,0.17,0.34,0.33,0.51,0.5
	c-0.28-0.03-0.59-0.05-0.76-0.21c0.13,0.23,0.26,0.45,0.39,0.67c-0.15,0.2-0.29,0.38-0.44,0.58c-0.23-0.08-0.4-0.14-0.39-0.18
	C360.59,613.51,360.76,613.05,360.9,612.6z M359.68,603.86c-0.07,0-0.15,0-0.22-0.01c-0.2-0.67-0.41-1.35-0.61-2.02
	c0.1-0.01,0.2-0.02,0.3-0.03c0.13,0.24,0.21,0.5,0.4,0.72c0.15,0.17-0.51,0.58,0.51,0.54C359.94,603.32,359.81,603.59,359.68,603.86
	z M359.19,595.93c-0.46-0.35-0.18-0.63,0.15-0.92c0.97,0.5,0.84,1.11-0.6,2.42c0.08-0.36,0.27-0.71,0.42-1.06
	C359.22,596.23,359.31,596.03,359.19,595.93z M343.95,582.93c0.18,0.29,0.25,0.56-0.02,0.84
	C343.92,583.49,343.93,583.21,343.95,582.93z M344.08,588.24c-0.08-0.19-0.16-0.4,0.2-0.57
	C344.22,587.85,344.15,588.05,344.08,588.24z M344.21,586.64c-0.08-0.27-0.22-0.54-0.22-0.81c-0.01-0.21-0.01-0.42-0.02-0.63
	c0.13,0.48,0.27,0.95,0.36,1.43C344.29,586.64,344.25,586.64,344.21,586.64z M344.14,555.9c0.07-0.03,0.14-0.05,0.21-0.08
	C344.29,555.85,344.23,555.88,344.14,555.9z M344.65,583.68c0.1-0.4,0.18-0.71,0.26-1.03c0.09-0.01,0.18-0.03,0.27-0.04
	c0.02,0.31,0.07,0.63,0.05,0.94C345.23,583.59,344.92,583.62,344.65,583.68z M346.04,614.8c-0.03-0.08-0.05-0.15-0.08-0.23
	c0.03-0.02,0.05-0.04,0.08-0.06c0.01,0.01,0.02,0.02,0.02,0.03C346.06,614.62,346.05,614.71,346.04,614.8z M346.26,602.36
	c-0.05,0.01-0.11,0.02-0.16,0.02c-0.1-0.06-0.21-0.13-0.41-0.26c-0.17,0.41-0.31,0.77-0.49,1.21c-0.16-0.14-0.27-0.23-0.4-0.34
	c-0.14,0.4,0.15,0.84,0.7,1.11c0.05,0.33,0.13,0.64,0.22,0.95c-0.13,0.09-0.26,0.18-0.34,0.28c-0.22,0.26-0.38,0.54-0.57,0.81
	c-0.05,0.08-0.14,0.15-0.2,0.23c0.06-0.22,0.09-0.43,0.14-0.65c-0.09-0.12-0.34-0.28-0.28-0.4c0.23-0.47,0.21-0.9,0.01-1.4
	c-0.26-0.65-0.42-1.36,0.28-2.01c0.09-0.08,0.08-0.2,0.08-0.3c0.03-0.54-0.55-1.09,0.17-1.62c0.06,0.19,0.13,0.37,0.19,0.56
	c0.07,0.2,0.19,0.4,0.29,0.6c0.08-0.01,0.15-0.01,0.23-0.02c0-0.21,0-0.41,0-0.61c0.05,0,0.1,0,0.16-0.01
	c0.01,0.04,0.02,0.09,0.05,0.12c0.12,0.16,0.2,0.32,0.26,0.48c0.04,0.28,0.09,0.56,0.13,0.84
	C346.3,602.09,346.28,602.23,346.26,602.36z M346.3,620.04c0.01-0.38,0.03-0.76,0.05-1.15c0,0,0,0,0,0
	c-0.01,0.33,0.02,0.66,0.14,1.02C346.45,619.92,346.31,619.98,346.3,620.04z M349.12,549.78c-0.1,0-0.2,0-0.3,0
	c0-0.86-0.03-1.72,0.02-2.58c0.02-0.22,0.32-0.44,0.5-0.65c0.05,0.03,0.1,0.06,0.15,0.1C349.36,547.69,349.24,548.73,349.12,549.78z
	 M357.01,618.39c-0.01,0.02-0.01,0.05-0.01,0.08c-0.05,0.03-0.1,0.06-0.14,0.09c-0.09-0.06-0.26-0.05-0.41-0.06
	c-0.01-0.1-0.04-0.2-0.07-0.29c0.11-0.16,0.21-0.29,0.3-0.42C356.79,617.99,356.9,618.19,357.01,618.39z M356.43,615.96
	c-0.05,0.04-0.09,0.07-0.14,0.11c-0.02-0.27-0.05-0.54-0.06-0.8C356.28,615.46,356.35,615.69,356.43,615.96z M356.35,617.17
	c0.02,0.03,0.03,0.07,0.05,0.1c-0.05,0.19-0.11,0.39-0.15,0.59c-0.08-0.2-0.16-0.39-0.17-0.6
	C356.19,617.23,356.29,617.19,356.35,617.17z M355.95,617.44c-0.2-0.3-0.4-0.59-0.59-0.89c-0.02-0.02-0.03-0.05-0.05-0.07
	c0.01-0.25,0.02-0.5,0.02-0.75c0-0.18,0-0.37,0-0.55c0.02-0.02,0.04-0.04,0.07-0.06c-0.02-0.02-0.05-0.04-0.07-0.06
	c0-0.29,0-0.58,0-0.88c0.28,0.04,0.43,0.06,0.54,0.18C355.86,615.41,355.88,616.43,355.95,617.44z M355.36,613.32
	c-0.05-0.61-0.1-1.23-0.16-1.84C355.46,612.08,356.34,612.65,355.36,613.32z M354.31,601.27c0.06,0,0.11,0,0.17,0
	c0.08,0.31,0.17,0.62,0.26,0.98c-0.35-0.04-0.53-0.06-0.68-0.07c-0.05,0.34-0.1,0.65-0.15,0.96c-0.15-0.02-0.29-0.03-0.44-0.05
	C353.75,602.49,354.03,601.88,354.31,601.27z M354.15,603.59c-0.18,0.44-0.34,0.84-0.55,1.35
	C353.17,604,353.27,603.75,354.15,603.59z M352.99,624.26c-0.05-0.21-0.1-0.43-0.13-0.65c0.04,0.1,0.09,0.2,0.13,0.31
	C352.99,624.03,352.99,624.15,352.99,624.26z M353.04,611.11c-0.07-0.03-0.14-0.05-0.21-0.08c-0.02-0.04-0.04-0.08-0.06-0.12
	c0.16-0.09,0.34-0.16,0.36-0.25c0.04-0.16-0.08-0.34-0.14-0.51c-0.15,0.06-0.3,0.12-0.46,0.18c-0.01-0.02-0.01-0.04-0.02-0.06
	c0.49-0.56,0.16-1.12-0.01-1.68c-0.16-0.53-0.24-1.06-0.27-1.6c-0.01-0.19,0.2-0.39,0.31-0.59c0.1,0.01,0.19,0.01,0.29,0.02
	c0.05,0.24,0.03,0.5,0.16,0.72c0.47,0.82,0.5,1.64,0.03,2.47c-0.04,0.07,0,0.18,0.08,0.23
	C353.75,610.27,353.53,610.68,353.04,611.11z M354.05,626.58c-0.17-0.25-0.38-0.49-0.51-0.77c0.02-0.15,0.05-0.3,0.08-0.46
	c0.18,0.39,0.37,0.78,0.56,1.16C354.14,626.54,354.09,626.56,354.05,626.58z M354.8,625.85c-0.74,0.19-0.74-0.3-0.86-0.71
	c-0.07-0.25-0.15-0.5-0.22-0.74c-0.01-0.13-0.02-0.26-0.03-0.39c-0.02-0.13-0.04-0.26-0.07-0.4c0.03,0,0.05,0,0.08,0
	c0.48,0.5,0.94,0.83,1.21,1.25c0.29,0.45,0.41,1.01,0.65,1.56c-0.09-0.02-0.16-0.04-0.23-0.05c0.03-0.02,0.05-0.05,0.09-0.07
	C355.17,626.13,354.94,625.82,354.8,625.85z M356.86,629.74c-0.24-0.16-1.06,0.18-0.61-0.62c0.03-0.05-0.07-0.15-0.11-0.22
	c-0.26-0.38-0.52-0.78-0.78-1.17c-0.25-0.37-0.47-0.75-0.27-1.11c0.36,0.25,0.72,0.48,1.08,0.72c-0.08,0.07-0.16,0.13-0.24,0.18
	c0.35,0.72,0.72,1.42,1.11,2.1C356.98,629.67,356.92,629.7,356.86,629.74z M356.68,627.7c0-0.01-0.01-0.02-0.01-0.02
	c0.09,0.06,0.18,0.13,0.18,0.19c0,0.32,0.1,0.58,0.26,0.79C356.75,628.49,356.78,628.05,356.68,627.7z M370.91,629.81
	c-0.48,0.5-0.83,0.45-1.67-0.34C369.87,629.61,370.36,629.71,370.91,629.81z M371.78,637c-0.27-0.09-0.55-0.19-0.82-0.29
	c-0.01,0.02-0.02,0.03-0.03,0.05c-0.21-0.18-0.42-0.36-0.63-0.54c-0.11-0.09-0.26-0.28-0.33-0.24c-0.16,0.09-0.29,0.12-0.39,0.11
	c-0.06-0.12-0.09-0.21-0.13-0.28c0.05-0.05,0.1-0.1,0.14-0.16c0.76,0.39,1.53,0.76,2.32,1.1C371.87,636.84,371.83,636.92,371.78,637
	z M372.25,637.71c0,0.01,0,0.01,0,0.02c-0.03,0.01-0.05,0.01-0.08,0.01c-0.12-0.09-0.23-0.18-0.35-0.27
	C371.96,637.56,372.1,637.63,372.25,637.71z M370.91,641c-0.48,0.5-0.83,0.45-1.67-0.34C369.87,640.8,370.36,640.9,370.91,641z
	 M364.26,619.66c0.01,0.01,0.01,0.01,0.02,0.02c-0.01,0-0.02,0-0.02,0C364.26,619.67,364.26,619.66,364.26,619.66z M363.96,626.65
	c0.01,0.09,0.1,0.19,0.19,0.21c1.04,0.32,1.84,0.87,2.49,1.69c0.18,0.23,0.46,0.37,0.69,0.55c-0.03,0.06-0.07,0.13-0.1,0.19
	c-0.26-0.05-0.56-0.04-0.76-0.17c-0.55-0.36-1.08-0.77-1.54-1.23c-0.53-0.52-0.92-1.15-1.81-1.24c0.16-0.05,0.33-0.11,0.49-0.16
	c-0.14-0.16-0.25-0.36-0.42-0.46c-0.11-0.07-0.33,0.01-0.53,0.03c0.02-0.11,0.04-0.21,0.07-0.32
	C363.38,625.8,363.87,625.97,363.96,626.65z M363.5,624.25c-0.52-0.49-1.03-0.99-1.52-1.51C363.1,622.75,363.1,623.66,363.5,624.25z
	 M360.28,613.92c0,0.01,0,0.02,0,0.03c-0.01,0-0.02-0.01-0.03-0.01C360.26,613.93,360.27,613.92,360.28,613.92z M359.84,634.58
	c-0.3-0.38-0.59-0.76-0.88-1.15c0.07-0.05,0.15-0.1,0.22-0.16c0.29,0.37,0.6,0.74,0.91,1.09
	C360.01,634.44,359.92,634.51,359.84,634.58z M361.25,633.22c-0.27-0.28-0.53-0.57-0.8-0.86c0.1,0,0.2,0.01,0.33,0.01
	c-0.56-0.54-0.91-1.09-1.44-1.4c-0.06-0.03-0.13-0.06-0.19-0.09c-0.36-0.43-0.71-0.88-1.06-1.34c0.13,0.1,0.25,0.21,0.36,0.33
	c0.11,0.12,0.42,0.08,0.64,0.1c0.05-0.06,0.1-0.13,0.15-0.19c0.17,0.24,0.35,0.48,0.53,0.72c0.06,0.22,0.12,0.44,0.17,0.66
	c0.11,0,0.23-0.01,0.34-0.01c0,0.01,0.01,0.01,0.01,0.02c0.22,0.26,0.42,0.54,0.66,0.79C361.48,632.85,361.52,632.87,361.25,633.22z
	 M361.25,622.04c-0.19-0.19-0.37-0.39-0.55-0.59c-0.11-0.2-0.24-0.4-0.26-0.61c-0.03-0.37-0.03-0.73-0.07-1.09
	c0.01,0.02,0.03,0.04,0.04,0.07C361.44,621.68,361.59,621.59,361.25,622.04z M362.82,634.23c-0.06,0.06-0.12,0.13-0.19,0.23
	c0.17,0.08,0.34,0.16,0.51,0.24c0.12,0.25,0.21,0.52,0.37,0.74c-0.52-0.49-1.03-0.99-1.52-1.51
	C362.38,633.93,362.63,634.05,362.82,634.23z M363.12,637.85c0.16-0.05,0.33-0.11,0.49-0.16c-0.14-0.16-0.25-0.36-0.42-0.46
	c-0.11-0.07-0.33,0.01-0.53,0.03c0.02-0.11,0.04-0.21,0.07-0.32c0.66,0.05,1.15,0.22,1.25,0.9c0.01,0.09,0.1,0.19,0.19,0.21
	c1.04,0.32,1.84,0.87,2.49,1.69c0.18,0.23,0.46,0.37,0.69,0.55c-0.03,0.06-0.07,0.13-0.1,0.19c-0.26-0.05-0.56-0.04-0.76-0.17
	c-0.55-0.36-1.08-0.77-1.54-1.23C364.4,638.57,364.01,637.94,363.12,637.85z M370.03,648.25c-0.14-0.05-0.3-0.12-0.43-0.1
	c-1.1,0.13-2-0.39-2.74-0.96c-0.57-0.44-1.11-0.72-1.8-0.89c-0.17-0.04-0.27-0.31-0.38-0.45c-0.29-0.12-0.57-0.25-0.86-0.37
	c0.12,0.02,0.25,0.02,0.37,0.04c0.42,0.07,0.83,0.15,1.25,0.19c0.21,0.02,0.44-0.04,0.66-0.06c0.31-0.03,0.62-0.04,1.06-0.09
	c-0.46,0.79,0.15,1.75,1.04,1.96c-0.1-0.15-0.18-0.27-0.3-0.46c0.64,0.14,1.16,0.24,1.75,0.34c-0.1-0.21-0.14-0.31-0.19-0.41
	c0.05-0.05,0.1-0.1,0.14-0.16c0.76,0.39,1.53,0.76,2.32,1.1c-0.04,0.08-0.09,0.16-0.13,0.24c-0.27-0.09-0.55-0.19-0.82-0.29
	c-0.03,0.05-0.07,0.1-0.1,0.15c0.23,0.16,0.46,0.34,0.71,0.48c0.23,0.13,0.46,0.26,0.7,0.38
	C371.33,649.17,370.75,648.51,370.03,648.25z M373.54,641.94c-0.77-0.06-1.55-0.14-2.32-0.25c0.03-0.11,0.07-0.21,0.1-0.32
	c0.36,0.12,0.73,0.23,1.12,0.35c0.03-0.1,0.07-0.24,0.15-0.49c0.37,0.23,0.68,0.41,1,0.6C373.57,641.86,373.56,641.9,373.54,641.94z
	 M373.54,630.76c-0.77-0.06-1.55-0.14-2.32-0.25c0.03-0.11,0.07-0.21,0.1-0.32c0.36,0.12,0.73,0.23,1.12,0.35
	c0.03-0.1,0.07-0.24,0.15-0.49c0.37,0.23,0.68,0.41,1,0.6C373.57,630.68,373.56,630.72,373.54,630.76z M374.37,627.41
	c-0.62-0.4-1.22-0.83-1.8-1.26c0.02-0.05,0.05-0.1,0.07-0.15c0.31,0.04,0.62,0.07,0.91,0.09c-0.39,0.67,0.24,0.39,0.36,0.56
	c0.16,0.22,0.39,0.38,0.59,0.56C374.45,627.28,374.41,627.35,374.37,627.41z M380.62,640.11c-0.08-0.11-0.31-0.09-0.48-0.07
	c-0.2,0.02-0.4,0.04-0.6,0.05c-0.06-0.08-0.13-0.13-0.21-0.16c0.29-0.11,0.54-0.2,0.76-0.26c0.18,0.02,0.36,0.03,0.54,0.02
	c0.1-0.01,0.2-0.05,0.3-0.1c0.11,0.02,0.21,0.05,0.29,0.1c0.03,0.16,0.06,0.34,0.11,0.59c0.02,0,0.03,0,0.05,0.01
	C381.11,640.4,380.85,640.42,380.62,640.11z M380.62,628.92c-0.08-0.11-0.31-0.09-0.48-0.07c-0.41,0.03-0.82,0.09-1.22,0.05
	c1.71-0.71,2.32-0.72,2.71,0.08C381.26,629.18,380.91,629.32,380.62,628.92z M388.98,651.89c0.29-0.06,0.58-0.13,0.85-0.19
	C389.57,651.98,389.26,651.93,388.98,651.89z M392.53,651.69c-0.4,0.04-0.8-0.02-1.19-0.05c0-0.03,0-0.06,0-0.08
	c0.7,0.01,1.41,0.03,2.12,0.04C393.15,651.63,392.84,651.66,392.53,651.69z M395.57,651.31c0.39-0.27,0.78-0.29,1.22-0.25
	C396.39,651.16,395.98,651.24,395.57,651.31z M397.08,650.27c-0.44,0.15-0.88,0.3-1.45,0.48c0.06-0.21,0.07-0.45,0.13-0.46
	c0.44-0.09,0.9-0.15,1.34-0.23C397.1,650.13,397.09,650.2,397.08,650.27z M415.17,568.95c0.07,0,0.15,0,0.22,0.01
	c0.2,0.67,0.41,1.35,0.61,2.02c-0.1,0.01-0.2,0.02-0.3,0.03c-0.13-0.24-0.21-0.5-0.4-0.72c-0.15-0.17,0.51-0.58-0.51-0.54
	C414.91,569.5,415.04,569.22,415.17,568.95z M415.66,576.88c0.46,0.35,0.18,0.63-0.15,0.92c-0.97-0.5-0.84-1.11,0.6-2.42
	c-0.08,0.36-0.27,0.71-0.42,1.06C415.63,576.58,415.54,576.78,415.66,576.88z M419.49,559.49c0.05,0.61,0.1,1.23,0.16,1.84
	C419.39,560.73,418.51,560.16,419.49,559.49z M421.86,548.32c0.24,0.58,0.31,1.17,0.34,1.75c0.01,0.24-0.09,0.47-0.14,0.71
	c-0.07,0-0.13,0-0.2,0C421.86,549.96,421.86,549.14,421.86,548.32z M421.86,557.51c0.07,0.39,0.13,0.79,0.2,1.18
	c-0.14,0-0.29,0-0.43,0c-0.04-0.39-0.08-0.78-0.12-1.17C421.63,557.52,421.74,557.51,421.86,557.51z M420.54,571.54
	c-0.06,0-0.11,0-0.17,0c-0.08-0.31-0.17-0.62-0.26-0.98c0.35,0.04,0.53,0.06,0.68,0.07c0.05-0.34,0.1-0.65,0.15-0.96
	c0.15,0.02,0.29,0.03,0.44,0.05C421.1,570.33,420.82,570.94,420.54,571.54z M420.7,569.22c0.18-0.44,0.34-0.84,0.55-1.35
	C421.67,568.81,421.57,569.07,420.7,569.22z M422.31,566.41c-0.1-0.01-0.19-0.01-0.29-0.02c-0.05-0.24-0.03-0.5-0.16-0.72
	c-0.47-0.82-0.5-1.64-0.03-2.47c0.04-0.07,0-0.18-0.08-0.23c-0.65-0.42-0.42-0.83,0.07-1.26c0.13,0.05,0.25,0.09,0.38,0.14
	c-0.18,0.11-0.44,0.2-0.46,0.31c-0.04,0.16,0.08,0.34,0.14,0.51c0.18-0.07,0.37-0.14,0.55-0.21c-0.62,0.59-0.25,1.18-0.07,1.78
	c0.16,0.53,0.24,1.06,0.27,1.6C422.63,566.02,422.42,566.21,422.31,566.41z M429.14,571.68c0,0.21,0,0.41,0,0.61
	c-0.12,0-0.24,0.01-0.36,0.01c-0.1-0.61-0.19-1.23-0.29-1.84c0.09-0.01,0.18-0.03,0.26-0.04c0.1,0.06,0.21,0.13,0.41,0.26
	c0.17-0.41,0.31-0.77,0.49-1.21c0.16,0.14,0.27,0.23,0.4,0.34c0.21-0.62-0.58-1.33-1.78-1.35c0.3-0.26,0.51-0.44,0.73-0.62
	c0.15-0.13,0.36-0.24,0.47-0.37c0.22-0.26,0.38-0.54,0.57-0.81c0.05-0.08,0.14-0.15,0.2-0.23c-0.06,0.22-0.09,0.43-0.14,0.65
	c0.09,0.12,0.34,0.28,0.28,0.4c-0.23,0.47-0.21,0.9-0.01,1.4c0.26,0.65,0.42,1.36-0.28,2.01c-0.09,0.08-0.08,0.2-0.08,0.3
	c-0.03,0.54,0.55,1.09-0.17,1.62c-0.06-0.19-0.13-0.37-0.19-0.56c-0.07-0.2-0.19-0.4-0.29-0.6
	C429.29,571.66,429.21,571.67,429.14,571.68z M429.93,590.16c-0.09,0.01-0.18,0.03-0.27,0.04c-0.02-0.31-0.07-0.63-0.05-0.94
	c0-0.04,0.31-0.07,0.58-0.13C430.09,589.53,430.01,589.85,429.93,590.16z M430.76,584.57c0.08,0.19,0.16,0.4-0.2,0.57
	C430.63,584.96,430.7,584.77,430.76,584.57z M430.71,616.91c-0.06,0.02-0.12,0.05-0.18,0.07c0,0,0,0,0,0
	C430.58,616.95,430.63,616.93,430.71,616.91z M430.52,586.17c0.04,0,0.07,0,0.11,0c0.08,0.27,0.22,0.54,0.22,0.81
	c0.01,0.21,0.01,0.42,0.02,0.63C430.75,587.13,430.61,586.65,430.52,586.17z M430.9,589.89c-0.18-0.29-0.25-0.56,0.02-0.84
	C430.92,589.33,430.92,589.61,430.9,589.89z"
        />
        <path
            fill={textColor}
            d="M427.07,544.14c0.02,0.1,0.11,0.2,0.2,0.29c0.52,0.53,0.45,1.07,0.31,1.65c-0.11,0.45,0.18,0.94,0.3,1.41
	c0.03,0.12,0.1,0.23,0.15,0.34c0.23,0.59,0.45,1.19,0.69,1.81c0.19-0.65,0.37-1.28,0.56-1.92c-0.91-0.08-1.19-0.55-0.84-1.43
	c0.24,0.11,0.47,0.22,0.69,0.32c0.08-0.02,0.15-0.04,0.23-0.06c-0.8-0.82-1.72-1.63-1.51-2.58c0.12-0.02,0.23-0.04,0.35-0.05
	c0.14,0.24,0.27,0.49,0.41,0.73c0.03,0,0.06,0,0.09,0c-0.04-0.36-0.08-0.71-0.11-0.96c-0.37-0.21-0.67-0.38-0.96-0.54
	c0.06-0.04,0.12-0.08,0.18-0.12c0.13,0.03,0.27,0.06,0.54,0.12c-0.26-0.81,0.68-1.62-0.29-2.52c-0.15,0.33-0.24,0.54-0.34,0.75
	c-0.23-0.59-1.09-1.15-0.16-1.79c0.19-0.14,0.04-0.44,0-0.66c-0.07-0.37-0.14-0.74-0.31-1.1c-0.07-0.14-0.37-0.26-0.6-0.38
	c-0.22-0.11-0.49-0.2-0.82-0.33c0.16,1.06,0.31,2.17,0.51,3.28c0.12,0.68,0.26,1.36,0.4,2.05c-0.01-0.01-0.02-0.01-0.04-0.03
	c0.01,0.03,0.01,0.06,0.02,0.09c0.01-0.01,0.02-0.03,0.03-0.04C426.83,543.03,426.95,543.58,427.07,544.14z"
        />
        <path
            fill={textColor}
            d="M413.17,600.03c0.04-0.5-0.1-1.01-0.16-1.52c-0.1,0-0.21,0-0.31,0.01c-0.11,1.44-0.21,2.88-0.33,4.32
	c-0.04,0.56-0.08,1.09-0.84,1.58c-0.5,0.33-0.63,0.61-0.39,0.91c0,0.24,0,0.48,0,0.72c0.13,0,0.26,0,0.39,0
	c-0.01-0.14-0.02-0.27-0.03-0.41c0.24,0.17,0.58,0.34,1.01,0.54c0.32-0.59,0.99-1.12,0.37-1.78c-0.17-0.18-0.11-0.44-0.09-0.67
	C412.91,602.51,413.09,601.27,413.17,600.03z"
        />
        <path
            fill={textColor}
            d="M412.79,596.77c-0.05-0.54-0.09-1.02-0.13-1.51c-0.08,0-0.16,0.01-0.24,0.01c-0.23,0.95-0.47,1.9-0.7,2.82
	c1.11-0.36,1.77-0.93,1.71-1.52C413.24,596.63,413.07,596.68,412.79,596.77z"
        />
        <path
            fill={textColor}
            d="M415.39,544.27c0.11,0.49-0.37,1.02,0.17,1.46c0.13-0.01,0.26-0.02,0.39-0.03c-0.36-1.36-0.71-2.71-1.07-4.07
	c-0.12,0-0.24,0.01-0.36,0.01c0.14,0.85,0.28,1.71,0.43,2.6C415.04,544.25,415.21,544.26,415.39,544.27z"
        />
        <path
            fill={textColor}
            d="M430.8,561.86c0.06-0.2,0.01-0.41,0.01-0.58c-0.33,0.41-0.84,0.81-0.94,1.23c-0.1,0.42,0.21,0.87,0.36,1.42
	c0.26-0.2,0.42-0.32,0.64-0.48c0.22,0.2,0.41,0.38,0.72,0.66c0.05-0.29,0.08-0.48,0.11-0.66c-0.63,0.04-1.36-0.51-1.23-0.93
	C430.54,562.29,430.74,562.08,430.8,561.86z"
        />
        <path
            fill={textColor}
            d="M429.89,553.7c-0.14-0.02-0.27-0.04-0.41-0.05c-0.11,0.18-0.31,0.36-0.3,0.53c0.03,0.48,0.14,0.96,0.22,1.41
	c0.02-0.03,0.05-0.09,0.11-0.21c0.16,0.13,0.27,0.23,0.37,0.32C429.89,555.03,429.89,554.36,429.89,553.7z"
        />
        <path
            fill={textColor}
            d="M413.9,556.93c-0.32-1.13-0.65-2.26-0.97-3.4c-0.05,0.01-0.11,0.01-0.16,0.02c0.11,0.52,0.23,1.04,0.33,1.53
	c-0.33,0-0.57,0-0.8,0C413.93,555.5,413.05,556.39,413.9,556.93z"
        />
        <path
            fill={textColor}
            d="M411.78,553.1c0.32,0,0.66,0,0.91,0c0-0.47,0-0.95,0-1.44C412.49,552.19,410.8,552.45,411.78,553.1z"
        />
        <path
            fill={textColor}
            d="M411,611.53c-0.03-0.43,0.05-0.82,0.99-0.96c0.1-0.02,0.12-0.21,0.18-0.32c-0.07-0.01-0.14-0.03-0.22-0.04
	c-0.15,0.09-0.3,0.18-0.52,0.3c-0.07-0.22-0.13-0.38-0.18-0.54c-0.1,0-0.2,0-0.3,0c-0.13,0.63-0.27,1.26-0.4,1.89
	c0.11,0.01,0.22,0.02,0.34,0.03C410.93,611.77,411.01,611.65,411,611.53z"
        />
        <path
            fill={textColor}
            d="M412.05,592.22c-0.1,0.01-0.2,0.01-0.3,0.02c0.06,0.52,0.13,1.05,0.19,1.57c0.15-0.01,0.29-0.02,0.44-0.02
	C412.1,593.27,412.94,592.68,412.05,592.22z"
        />
        <path
            fill={textColor}
            d="M412.06,606.68c-0.27,0.54-0.56,1.11-0.84,1.67C411.98,608.14,412.16,607.78,412.06,606.68z"
        />
        <path
            fill={textColor}
            d="M411.48,577.8c0.13,0.02,0.26,0.04,0.39,0.06c0.19-0.35,0.37-0.69,0.56-1.04c-0.14-0.02-0.27-0.04-0.41-0.06
	C411.92,577.12,411.16,577.37,411.48,577.8z"
        />
        <path
            fill={textColor}
            d="M411.4,572.08c-0.18,0.08-0.41,0.18-0.81,0.35c0.67,0.11,1.2,0.2,1.89,0.32C412.04,572.48,411.75,572.3,411.4,572.08z"
        />
        <path
            fill={textColor}
            d="M365.47,551.25c0.02-0.42-0.19-0.84-0.3-1.26c-0.08,0.03-0.17,0.05-0.25,0.08c-0.25-0.11-0.58-0.19-0.74-0.33
	c-0.54-0.47-1-0.96-1.52-1.44c-0.16-0.14-0.4-0.26-0.7-0.34c0.12,0.44,1.11,0.69,0.73,1.26c-0.32-0.22-0.58-0.39-0.83-0.56
	c-0.07,0.02-0.13,0.04-0.2,0.06c0.26,0.44,0.52,0.88,0.78,1.31c0.06-0.03,0.12-0.07,0.18-0.1c0.23,0.07,0.62,0.12,0.66,0.21
	c0.29,0.74,0.52,1.48,0.74,2.23c0.03,0.09-0.13,0.19-0.21,0.28c-0.08,0.09-0.26,0.19-0.24,0.27c0.13,0.41,0.31,0.81,0.49,1.21
	c0.13,0.29,0.41,0.58,0.41,0.87c0.01,0.5-0.13,1-0.21,1.5c-0.1,0-0.2-0.01-0.3-0.01c0-0.24,0-0.47,0-0.71
	c-0.12-0.01-0.25-0.01-0.37-0.02c0.88,1.6-0.58,3.07-0.8,4.61c0.08,0.01,0.16,0.01,0.24,0.02c0.29-0.68,0.58-1.35,0.87-2.03
	c0.05,0,0.1,0,0.15,0c0.03,0.3,0.07,0.61,0.11,0.96c0.21-0.08,0.3-0.1,0.29-0.12c-0.44-1.35,0.66-2.61,0.69-3.93
	C365.19,553.91,365.41,552.58,365.47,551.25z"
        />
        <path
            fill={textColor}
            d="M361.67,572.78c-0.04,0.5,0.1,1.01,0.16,1.52c0.11,0,0.21,0,0.31-0.01c0.11-1.44,0.21-2.88,0.33-4.32
	c0.04-0.56,0.08-1.09,0.84-1.58c0.5-0.33,0.63-0.61,0.39-0.91c0-0.24,0-0.48,0-0.72c-0.13,0-0.26,0-0.39,0
	c0.01,0.14,0.02,0.27,0.03,0.41c-0.24-0.17-0.58-0.34-1.01-0.54c-0.32,0.59-0.99,1.12-0.37,1.78c0.17,0.18,0.11,0.44,0.09,0.67
	C361.94,570.3,361.76,571.54,361.67,572.78z"
        />
        <path
            fill={textColor}
            d="M362.06,576.04c0.05,0.54,0.09,1.02,0.13,1.51c0.08,0,0.16-0.01,0.24-0.01c0.23-0.95,0.47-1.9,0.7-2.82
	c-1.11,0.36-1.77,0.93-1.71,1.52C361.61,576.18,361.78,576.13,362.06,576.04z"
        />
        <path
            fill={textColor}
            d="M344.61,608.89c-0.26,0.2-0.42,0.32-0.64,0.48c-0.22-0.2-0.41-0.38-0.72-0.66c-0.05,0.29-0.08,0.48-0.11,0.66
	c0.63-0.04,1.36,0.51,1.23,0.93c-0.07,0.22-0.26,0.43-0.33,0.65c-0.06,0.2-0.01,0.41-0.01,0.58c0.33-0.41,0.84-0.81,0.94-1.23
	C345.07,609.88,344.77,609.43,344.61,608.89z"
        />
        <path
            fill={textColor}
            d="M344.96,619.11c0.14,0.02,0.27,0.04,0.41,0.05c0.11-0.18,0.31-0.36,0.3-0.53c-0.03-0.48-0.14-0.96-0.22-1.41
	c-0.02,0.03-0.05,0.09-0.11,0.21c-0.16-0.14-0.27-0.23-0.37-0.32C344.96,617.79,344.96,618.45,344.96,619.11z"
        />
        <path
            fill={textColor}
            d="M363.95,560.92c-0.04,0.12-0.12,0.24-0.11,0.36c0.03,0.43-0.05,0.82-0.99,0.96c-0.1,0.02-0.12,0.21-0.18,0.32
	c0.07,0.01,0.14,0.03,0.22,0.04c0.15-0.09,0.3-0.18,0.52-0.3c0.07,0.22,0.13,0.38,0.18,0.54c0.1,0,0.2,0,0.3,0
	c0.13-0.63,0.27-1.26,0.4-1.89C364.18,560.94,364.07,560.93,363.95,560.92z"
        />
        <path
            fill={textColor}
            d="M362.8,580.6c0.1-0.01,0.2-0.01,0.3-0.02c-0.06-0.52-0.13-1.05-0.19-1.57c-0.15,0.01-0.29,0.02-0.44,0.02
	C362.75,579.54,361.9,580.13,362.8,580.6z"
        />
        <path
            fill={textColor}
            d="M362.79,566.13c0.27-0.54,0.56-1.11,0.84-1.67C362.87,564.67,362.69,565.03,362.79,566.13z"
        />
        <path
            fill={textColor}
            d="M363.37,595.01c-0.13-0.02-0.26-0.04-0.39-0.06c-0.19,0.35-0.37,0.69-0.56,1.04c0.14,0.02,0.27,0.04,0.41,0.06
	C362.92,595.69,363.69,595.44,363.37,595.01z"
        />
        <path
            fill={textColor}
            d="M364.26,600.39c-0.67-0.11-1.2-0.2-1.89-0.32c0.43,0.27,0.73,0.45,1.08,0.67C363.63,600.66,363.87,600.56,364.26,600.39z"
        />
        <path
            fill={textColor}
            d="M359.34,642.16c-0.53-0.31-1.23-0.38-1.87-0.58c0.19,0.16,0.4,0.38,0.65,0.53c0.28,0.17,0.62,0.24,0.9,0.4
	c0.55,0.3,0.81,1.18,0.45,1.51c0.23,0.14,0.46,0.28,0.84,0.49c-0.19-0.41-0.3-0.68-0.43-0.97c0.3,0.01,0.52,0.02,0.89,0.03
	C360.23,643.02,359.87,642.47,359.34,642.16z"
        />
        <path
            fill={textColor}
            d="M404.46,623.15c1.1-0.73,2.12-1.59,3.1-2.48c0.18-0.16,0.36-0.37,0.59-0.4c0.83-0.09,0.95-0.86,1.25-1.48
	c-0.37-0.09-0.67-0.15-0.92-0.16c0.11-0.09,0.22-0.18,0.33-0.27c-0.06-0.07-0.13-0.14-0.19-0.21c-0.18,0.17-0.36,0.34-0.55,0.5
	c-0.35,0.08-0.5,0.33-0.53,0.84c-0.04,0.76-0.47,1.14-0.92,1.52c-1.17,0.97-2.41,1.84-3.71,2.62c0.03,0.07,0.07,0.14,0.1,0.21
	C403.49,623.63,404.01,623.45,404.46,623.15z"
        />
        <path
            fill={textColor}
            d="M401.27,624.49c0,0.23,0,0.36,0,0.51c0.59-0.21,0.92-0.92,0.88-1.82c-0.76,0.58-1.58,1.14-2.43,1.66
	c0.02,0.06,0.04,0.11,0.06,0.17C400.27,624.86,400.75,624.69,401.27,624.49z"
        />
        <path
            fill={textColor}
            d="M362.57,616c-0.23-0.45-0.47-0.93-0.69-1.42c-0.04,0.02-0.07,0.04-0.11,0.05c0.43,1.1,0.93,2.17,1.51,3.21
	c0.14-0.84-0.97-1.14-0.21-2.21C362.92,615.75,362.78,615.85,362.57,616z"
        />
        <path
            fill={textColor}
            d="M412.45,613.32c-0.07-0.04-0.14-0.08-0.21-0.12c-0.3,0.62-0.61,1.23-0.95,1.82c0.06,0.05,0.11,0.09,0.17,0.14
	c0.14-0.11,0.27-0.22,0.45-0.37c0.04,0.21,0.07,0.36,0.09,0.5c0.05,0.02,0.1,0.04,0.15,0.07c0.04-0.12,0.16-0.3,0.11-0.36
	c-0.44-0.56-0.25-0.92,0.03-1.3C412.37,613.59,412.4,613.44,412.45,613.32z"
        />
        <path
            fill={textColor}
            d="M398.27,625.35c-0.03-0.1-0.05-0.21-0.08-0.31c-0.52,0.13-1.05,0.24-1.58,0.34c0.01,0.07,0.03,0.15,0.04,0.22
	C397.3,626.11,397.71,625.33,398.27,625.35z"
        />
        <path
            fill={textColor}
            d="M381.13,626.25c0,0.1,0,0.21-0.01,0.31c0.38-0.07,0.75-0.15,1.12-0.23c0-0.1,0-0.2,0-0.29
	C381.87,625.74,381.5,626.25,381.13,626.25z"
        />
        <path
            fill={textColor}
            d="M377.35,624.15c-0.26,0.23-0.42,0.36-0.54,0.46c0.1,0.31,0.19,0.58,0.33,0.97C377.21,625.06,377.27,624.66,377.35,624.15z"
        />
        <path
            fill={textColor}
            d="M310.48,526.09c-0.14,0.83-0.28,1.66-0.4,2.49c-0.2,1.35-0.35,2.7-0.51,4c0.33-0.16,0.6-0.26,0.82-0.4
	c0.23-0.14,0.53-0.28,0.6-0.46c0.17-0.44,0.23-0.89,0.31-1.34c0.04-0.27,0.2-0.64,0-0.8c-0.93-0.78-0.07-1.46,0.16-2.18
	c0.1,0.26,0.19,0.52,0.34,0.92c0.97-1.1,0.03-2.08,0.29-3.07c-0.27,0.07-0.41,0.11-0.54,0.14c-0.06-0.05-0.12-0.1-0.18-0.15
	c0.3-0.21,0.6-0.41,0.96-0.66c0.03-0.31,0.07-0.74,0.11-1.17c-0.03,0-0.06,0-0.09,0c-0.14,0.3-0.27,0.59-0.41,0.89
	c-0.12-0.02-0.23-0.04-0.35-0.06c-0.21-1.16,0.71-2.15,1.51-3.15c-0.08-0.02-0.15-0.05-0.23-0.07c-0.22,0.13-0.45,0.25-0.69,0.39
	c-0.35-1.06-0.07-1.65,0.84-1.74c-0.18-0.77-0.37-1.55-0.56-2.34c-0.24,0.76-0.46,1.49-0.69,2.21c-0.04,0.14-0.12,0.28-0.15,0.42
	c-0.12,0.57-0.41,1.16-0.3,1.72c0.14,0.71,0.2,1.37-0.31,2.01c-0.09,0.11-0.18,0.23-0.2,0.35c-0.12,0.68-0.23,1.35-0.35,2.03
	c-0.01-0.02-0.02-0.04-0.03-0.05c-0.01,0.04-0.01,0.08-0.02,0.12C310.46,526.1,310.47,526.09,310.48,526.09z"
        />
        <path
            fill={textColor}
            d="M296.28,448.36c-0.43,0.24-0.77,0.46-1.01,0.66c0.01-0.17,0.02-0.33,0.03-0.5c-0.13,0-0.26,0-0.39,0c0,0.29,0,0.58,0,0.87
	c-0.24,0.37-0.11,0.71,0.39,1.11c0.76,0.6,0.8,1.25,0.84,1.93c0.11,1.76,0.22,3.51,0.33,5.27c0.11,0,0.21,0.01,0.31,0.01
	c0.06-0.62,0.19-1.23,0.16-1.85c-0.09-1.51-0.26-3.02-0.39-4.54c-0.02-0.27-0.08-0.59,0.09-0.81
	C297.27,449.73,296.6,449.08,296.28,448.36z"
        />
        <path
            fill={textColor}
            d="M295.48,458.24c0.23,1.12,0.46,2.28,0.7,3.44c0.08,0,0.16,0.01,0.24,0.01c0.04-0.59,0.09-1.19,0.13-1.84
	c0.28,0.11,0.45,0.17,0.64,0.24C297.26,459.37,296.59,458.68,295.48,458.24z"
        />
        <path
            fill={textColor}
            d="M298.72,523.89c-0.15,1.08-0.29,2.12-0.43,3.17c0.12,0,0.24,0.01,0.36,0.01c0.36-1.65,0.71-3.31,1.07-4.96
	c-0.13-0.01-0.26-0.02-0.39-0.04c-0.54,0.55-0.06,1.19-0.17,1.79C298.98,523.87,298.81,523.88,298.72,523.89z"
        />
        <path
            fill={textColor}
            d="M296.7,512.57c0.32-1.38,0.65-2.76,0.97-4.14c-0.85,0.66,0.03,1.75-1.59,2.25c0.24,0,0.47,0,0.8,0
	c-0.11,0.6-0.22,1.24-0.33,1.87C296.59,512.55,296.65,512.56,296.7,512.57z"
        />
        <path
            fill={textColor}
            d="M296.46,514.85c0-0.59,0-1.19,0-1.75c-0.25,0-0.59,0-0.91,0C294.57,513.89,296.25,514.21,296.46,514.85z"
        />
        <path
            fill={textColor}
            d="M294.66,441.41c-0.11,0.01-0.22,0.02-0.34,0.03c0.13,0.77,0.27,1.54,0.4,2.3c0.1,0,0.2,0,0.3,0
	c0.05-0.2,0.11-0.39,0.18-0.65c0.22,0.16,0.37,0.26,0.52,0.37c0.07-0.02,0.14-0.03,0.22-0.05c-0.06-0.14-0.07-0.37-0.18-0.39
	c-0.94-0.17-1.02-0.64-0.99-1.17C294.78,441.7,294.7,441.55,294.66,441.41z"
        />
        <path
            fill={textColor}
            d="M295.71,463.46c-0.06,0.64-0.13,1.28-0.19,1.92c0.1,0.01,0.2,0.01,0.3,0.02c0.89-0.57,0.05-1.29,0.33-1.91
	C296,463.48,295.85,463.47,295.71,463.46z"
        />
        <path
            fill={textColor}
            d="M294.98,445.72c0.28,0.69,0.57,1.38,0.84,2.04C295.93,446.42,295.75,445.98,294.98,445.72z"
        />
        <path
            fill={textColor}
            d="M295.78,484.25c0.14-0.03,0.27-0.05,0.41-0.08c-0.19-0.42-0.37-0.84-0.56-1.27c-0.13,0.02-0.26,0.05-0.39,0.07
	C294.92,483.5,295.69,483.81,295.78,484.25z"
        />
        <path
            fill={textColor}
            d="M295.16,489.96c0.35-0.27,0.64-0.49,1.08-0.82c-0.7,0.14-1.22,0.25-1.89,0.39C294.75,489.74,294.99,489.86,295.16,489.96z"
        />
        <path
            fill={textColor}
            d="M369.62,423.39c0.63,0.09,1.25,0.18,1.88,0.26c1.01,0.12,2.02,0.2,2.99,0.29c-0.15-0.32-0.26-0.58-0.38-0.8
	c-0.12-0.22-0.26-0.51-0.4-0.57c-0.34-0.14-0.68-0.18-1.02-0.23c-0.2-0.03-0.49-0.16-0.59,0.04c-0.48,0.96-1.07,0.15-1.62-0.04
	c0.18-0.11,0.36-0.22,0.64-0.39c-0.91-0.91-1.53,0.08-2.29-0.12c0.08,0.27,0.12,0.4,0.16,0.53c-0.03,0.06-0.06,0.12-0.09,0.19
	c-0.18-0.29-0.36-0.57-0.59-0.92c-0.23-0.01-0.55-0.03-0.88-0.04c0,0.03,0.01,0.06,0.01,0.09c0.23,0.12,0.47,0.24,0.7,0.36
	c0,0.12-0.01,0.23-0.01,0.35c-0.83,0.27-1.65-0.59-2.47-1.32c-0.01,0.08-0.02,0.15-0.03,0.23c0.12,0.21,0.23,0.43,0.36,0.66
	c-0.75,0.41-1.2,0.16-1.37-0.74c-0.55,0.23-1.1,0.45-1.66,0.68c0.58,0.19,1.14,0.38,1.7,0.57c0.11,0.04,0.22,0.1,0.32,0.12
	c0.43,0.09,0.9,0.35,1.29,0.21c0.51-0.18,0.98-0.28,1.51,0.2c0.09,0.08,0.19,0.16,0.28,0.18c0.51,0.08,1.02,0.16,1.53,0.23
	c-0.01,0.01-0.02,0.02-0.04,0.03c0.03,0,0.06,0.01,0.09,0.01C369.63,423.42,369.62,423.4,369.62,423.39z"
        />
        <path
            fill={textColor}
            d="M322.37,440.89c0.05-0.28,0.08-0.46,0.11-0.65c-0.53-0.02-0.97,0.68-1.18,1.8c0.8-0.29,1.63-0.59,2.45-0.88
	c-0.01-0.08-0.01-0.16-0.02-0.24C323.3,440.91,322.86,440.9,322.37,440.89z"
        />
        <path
            fill={textColor}
            d="M349.99,421.34c0.5,0.12,0.94,0.39,1.31,0.27c0.37-0.13,0.67-0.66,1-1.02c-0.16,0.01-0.35-0.02-0.52,0.05
	c-0.19,0.08-0.36,0.29-0.55,0.37c-0.37,0.15-0.93-0.53-0.96-1.16c-0.16,0.04-0.32,0.09-0.58,0.15c0.28,0.28,0.46,0.47,0.67,0.67
	C350.24,420.89,350.14,421.06,349.99,421.34z"
        />
        <path
            fill={textColor}
            d="M325.09,441.1c0.01,0.14,0.02,0.29,0.02,0.43c0.48,0.03,0.95,0.06,1.43,0.09c-0.01-0.1-0.01-0.2-0.02-0.3
	C326.02,440.46,325.58,441.34,325.09,441.1z"
        />
        <path
            fill={textColor}
            d="M340.39,440.31c-0.03-0.13-0.07-0.27-0.1-0.4c-0.29,0.21-0.58,0.42-0.87,0.63c0.03,0.13,0.06,0.25,0.09,0.38
	C339.93,441.2,340.08,440.43,340.39,440.31z"
        />
        <path
            fill={textColor}
            d="M345.39,441.37c0.96-0.31,0.77-1.23,0.91-2c-0.5-0.04-0.9-0.05-1.21-0.02c0.1-0.13,0.2-0.26,0.3-0.39
	c-0.11-0.07-0.22-0.15-0.32-0.22c-0.16,0.24-0.33,0.48-0.49,0.73c-0.23,0.1-0.35,0.27-0.38,0.5c-0.08-0.12-0.17-0.24-0.26-0.38
	c0.17,0.64,0.3,1.13,0.45,1.73c-0.01,0.53-0.24,0.94-0.49,1.37c-0.89,1.52-1.78,3.04-2.67,4.56c0.09,0.06,0.17,0.12,0.26,0.18
	c0.39-0.48,0.85-0.92,1.16-1.45c0.77-1.31,1.47-2.66,2.21-3.98C345,441.76,345.13,441.46,345.39,441.37z"
        />
        <path
            fill={textColor}
            d="M338.79,450.37c0.06,0.05,0.13,0.1,0.19,0.14c0.37-0.47,0.73-0.94,1.14-1.45c0.17,0.24,0.28,0.39,0.39,0.55
	c0.45-0.56,0.28-1.5-0.39-2.49C339.69,448.18,339.24,449.28,338.79,450.37z"
        />
        <path
            fill={textColor}
            d="M332.25,492.03c-0.5,0.46-1.06,0.75-1.27,1.23c-0.2,0.48-0.05,1.16-0.05,1.77c0.12-0.18,0.3-0.36,0.39-0.59
	c0.1-0.26,0.08-0.58,0.17-0.84c0.18-0.5,1.16-0.65,1.65-0.26c0.1-0.2,0.2-0.4,0.36-0.73c-0.44,0.11-0.73,0.19-1.04,0.27
	C332.39,492.59,332.33,492.37,332.25,492.03z"
        />
        <path
            fill={textColor}
            d="M325.88,499.99c-0.37,0.42-0.79,0.84-1.14,1.31c-0.13,0.17-0.08,0.47-0.11,0.7c0.12,0.06,0.25,0.11,0.37,0.17
	c0.45-0.67,0.9-1.34,1.36-2.03c-0.15,0.03-0.31,0.07-0.53,0.12C325.87,500.11,325.88,500.03,325.88,499.99z"
        />
        <path
            fill={textColor}
            d="M336.17,453.07c0.08,0.06,0.16,0.12,0.24,0.19c1.06,0.03,0.76-1.04,1.34-1.4c-0.12-0.09-0.23-0.18-0.35-0.27
	C336.99,452.08,336.58,452.57,336.17,453.07z"
        />
        <path
            fill={textColor}
            d="M325.86,468.87c0.13,0.05,0.26,0.11,0.38,0.16c0.08-0.45,0.16-0.91,0.24-1.36c-0.12-0.05-0.24-0.1-0.36-0.16
	C325.57,467.77,326.03,468.46,325.86,468.87z"
        />
        <path
            fill={textColor}
            d="M322.16,473.27c0.44-0.03,0.81-0.05,1.35-0.08c-0.66-0.27-1.16-0.47-1.79-0.73C321.94,472.86,322.07,473.09,322.16,473.27z"
        />
        <path
            fill={textColor}
            d="M344.64,494.45c0.38,0.65,0.6,1.39,0.93,2.07c0.14,0.28,0.4,0.49,0.56,0.76c0.42,0.73,0.79,1.5,1.23,2.22
	c0.09,0.14,0.41,0.13,0.6,0.18c0.18,0.43,0.31,0.94,0.59,1.35c0.28,0.41,0.66,0.7,1.2,0.94c0.6,0.26,0.77,1.03,1.12,1.59
	c0.45,0.72,0.85,1.48,1.35,2.17c0.46,0.63,1.04,1.16,1.52,1.77c1.06,1.35,1.91,2.85,3.33,3.93c0.57,0.44,0.81,1.18,1.23,1.76
	c0.18,0.24,0.42,0.45,0.66,0.64c0.23,0.19,0.6,0.28,0.75,0.52c0.27,0.46,0.43,0.99,0.63,1.49c0.05-0.03,0.09-0.05,0.14-0.08
	c-0.01-0.11-0.02-0.21-0.02-0.32c0.43,0.32,0.68,0.74,0.99,1.11c0.29,0.36,0.88,0.52,0.57,1.23c-0.02,0.05,0.07,0.15,0.16,0.2
	c-0.05,0.01-0.11,0.02-0.16,0.02c0.08,0.77,0.14,1.34,0.21,2.08c0.27-0.37,0.39-0.53,0.41-0.55c0.41-0.14,0.73-0.24,1.01-0.34
	c0.27,0.42,0.53,0.83,0.8,1.25c0.03,0.27,0.01,0.57,0.03,0.85c0.43-0.11,0.41-0.1,0.57,0.33c0.02,0.04,0.1,0.06,0.2,0.06
	c1.22,1.89,2.53,3.7,4.29,5.11c0.53-0.12,0.92-0.22,1.46-0.34c-0.26-0.38-0.59-0.66-0.69-1.06c-0.16-0.64-0.14-1.37-0.23-2.04
	c-0.05-0.4-0.17-0.77-0.28-1.14c-0.04-0.13-0.26-0.25-0.2-0.34c0.45-0.74-0.12-0.84-0.5-1.08c-0.26-0.16-0.63-0.31-0.64-0.58
	c-0.01-0.09-0.02-0.17-0.04-0.25c0.09-0.08,0.18-0.15,0.27-0.23c0.84,1.04,1.69,2.06,2.49,3.12c0.1,0.14-0.08,0.48-0.13,0.72
	c0.05-0.05,0.11-0.09,0.17-0.14c0.72,0.69,1.43,1.37,2.13,2.05c0.77-0.28,0.95-0.74,0.87-1.24c-0.1-0.61,0.21-0.93,0.98-1.03
	c0.13-0.02,0.28-0.1,0.51-0.18c0.07-0.23,0.05-0.54,0.22-0.68c1-0.83,1.32-1.82,1.53-2.67c0.25-1.01,0.02-1.92-0.62-2.64
	c-1.16-1.31-2.22-2.7-3.31-4.06c-0.26-0.33-0.41-0.77-0.79-0.98c-0.64-0.35-0.7-0.36,0.08-1.23c0.15,0.16,0.3,0.3,0.42,0.47
	c1.16,1.54,2.29,3.1,3.46,4.63c0.63,0.82,1.28,1.63,2.01,2.38c0.2,0.21,0.79,0.37,1.02,0.23c0.65-0.41,1.62-0.61,1.83-1.48
	c0.17,0.03,0.34,0.06,0.43,0.07c-0.19-0.68-0.39-1.35-0.56-2.04c-0.43-1.72-0.84-3.44-1.95-4.79c-0.78-0.95-1.51-1.93-2.22-2.93
	c-1-1.41-1.85-2.93-2.95-4.27c-1.58-1.91-3.33-3.69-5-5.54c-0.88-0.97-1.75-1.95-2.63-2.93c-0.04-0.05-0.07-0.1-0.1-0.16
	c-0.44-0.76-0.85-1.53-1.32-2.27c-0.39-0.61-0.92-1.14-1.24-1.79c-0.49-1-1.65-1.51-1.97-2.64c-0.15-0.54-0.77-0.85-1.01-1.36
	c-0.52-1.11-1.79-1.69-2.07-2.96c-0.01-0.04-0.06-0.06-0.09-0.09c-0.58-0.86-1.64-1.39-1.73-2.59c-0.02-0.21-0.21-0.37-0.33-0.53
	c-0.55-0.72-1.13-1.42-1.67-2.14c-0.5-0.66-0.98-1.33-1.44-2.01c-0.5-0.74-0.96-1.52-1.46-2.26c-0.25-0.37-0.57-0.69-0.8-1.07
	c-0.52-0.86-1.01-1.74-1.54-2.67c0.15-0.15,0.34-0.27,0.6-0.36c0.24-0.08,0.29-0.39,0.53-0.75c-0.39,0.12-0.56,0.17-0.73,0.22
	c0.02-0.27,0.18-0.41,0.36-0.53c0.22-0.14,0.58-0.19,0.68-0.4c0.45-0.86,0.8-1.76,1.23-2.64c0.28-0.58,0.55-1.17,0.94-1.67
	c0.46-0.59,1.07-1.07,1.58-1.63c0.21-0.23,0.31-0.55,0.5-0.8c0.53-0.66,1.1-1.29,1.6-1.97c0.1-0.13-0.03-0.43-0.05-0.63
	c0.34-0.33,0.76-0.64,1.03-1.05c0.27-0.41,0.41-0.88,0.43-1.46c0.02-0.65,0.67-1.1,1.06-1.63c0.5-0.69,1.06-1.34,1.51-2.06
	c0.41-0.66,0.69-1.4,1.08-2.07c0.86-1.48,1.94-2.82,2.41-4.55c0.19-0.69,0.79-1.19,1.18-1.8c0.16-0.26,0.26-0.55,0.35-0.85
	c0.09-0.29,0.04-0.66,0.21-0.88c0.32-0.42,0.76-0.76,1.15-1.14c-0.04-0.03-0.08-0.07-0.12-0.1c-0.1,0.05-0.19,0.09-0.29,0.14
	c0.14-0.52,0.43-0.91,0.67-1.33c0.22-0.4,0.15-1.01,0.94-0.98c0.06,0,0.11-0.13,0.12-0.22c0.03,0.05,0.05,0.09,0.08,0.14
	c0.69-0.36,1.19-0.62,1.85-0.97c-0.44-0.12-0.63-0.17-0.67-0.18c-0.28-0.33-0.49-0.58-0.68-0.81c0.3-0.06,0.59-0.26,0.82-0.96
	c-0.05-0.01-0.08-0.02-0.12-0.02c0.05-0.08,0.11-0.15,0.16-0.23c0.24-0.12,0.52-0.22,0.78-0.34c-0.05-0.07-0.09-0.13-0.12-0.18
	c0.22,0.02,0.44,0.05,0.66,0.07c-0.01-0.12-0.02-0.24-0.03-0.36c-0.17-0.03-0.33-0.07-0.5-0.1c0.03-0.02,0.05-0.05,0.09-0.08
	c0.03-0.03,0.02-0.12-0.02-0.21c1.3-1.84,2.49-3.72,3.15-5.88c-0.31-0.44-0.54-0.77-0.86-1.23c-0.26,0.38-0.4,0.8-0.73,1.03
	c-0.54,0.38-1.22,0.64-1.81,0.97c-0.35,0.2-0.65,0.44-0.95,0.68c-0.11,0.08-0.14,0.33-0.24,0.31c-0.85-0.15-0.73,0.42-0.81,0.87
	c-0.05,0.3-0.06,0.7-0.3,0.81c-0.08,0.04-0.15,0.08-0.22,0.12c-0.1-0.06-0.21-0.11-0.31-0.17c0.66-1.17,1.29-2.34,1.98-3.47
	c0.09-0.15,0.47-0.1,0.72-0.14c-0.06-0.03-0.13-0.07-0.19-0.1c0.09-0.21,0.17-0.42,0.26-0.64c0.15,0.04,0.32,0.12,0.51,0.16
	c0.15-0.76,0.59,0.41,0.93,0.24c-0.24-0.37-0.47-0.7-0.66-1c-0.19,0.22-0.4,0.27-0.62,0.24c0.17-0.43,0.35-0.85,0.52-1.28
	c0.03,0.01,0.05,0.02,0.08,0.03c0.48,0.22,0.95,0.49,1.34,0.04c-0.03-0.29-0.06-0.54-0.07-0.65c-0.39-0.15-0.78-0.28-1.16-0.47
	c-0.53-0.27-0.99-1.28-1.57-1.13c-0.14,0.04-0.31,0.05-0.45-0.04c-0.29-0.18-0.29-0.4-0.1-0.59c-0.5-0.06-0.95-0.12-1.4-0.18
	c-0.47-0.06-0.95-0.22-1.4-0.13c-0.43,0.09-0.83,0.45-1.25,0.64c-0.11,0.05-0.26-0.08-0.39-0.17c-0.2-0.13-0.39-0.34-0.59-0.44
	c-0.21-0.11-0.43-0.14-0.64-0.2c0.01,0.19,0.03,0.46,0.04,0.64c-0.33-0.04-0.64-0.06-0.95-0.1c0,0,0-0.01,0.01-0.01
	c0.12-0.26,0.05-0.87-0.16-1.03c-0.19-0.14-0.37-0.3-0.55-0.47c0.06-0.05,0.11-0.1,0.17-0.16c-0.04-0.05-0.1-0.13-0.24-0.31
	c0.54-0.47,1.01-0.88,1.47-1.28c-0.04-0.11-0.08-0.21-0.11-0.32c-0.41,0.36-0.81,0.75-1.22,1.08c-0.11,0.09-0.28,0.14-0.4,0.03
	c-0.62-0.54-1.22-0.59-1.8-0.24c0.09,0.14,0.21,0.25,0.29,0.28c-0.48,0.36-0.95,0.73-1.44,1.08c-0.16,0.12-0.33,0.24-0.49,0.36
	c-0.23,0.05-0.46,0.1-0.7,0.13c0-0.09-0.01-0.19-0.01-0.28c0.36-0.21,0.73-0.42,1.09-0.63c0.14-0.03,0.27-0.21,0.41-0.32
	c-0.03-0.13-0.06-0.27-0.09-0.4c-0.34,0.03-0.68,0.05-1.03,0.08c0.35-0.18,0.88-0.13,1.14-0.38c-0.3-0.08-0.58-0.1-0.81-0.04
	c-0.75,0.19-1.48,0.43-2.24,0.61c-0.23,0.05-0.56-0.05-0.84-0.08c-0.05-0.07-0.1-0.15-0.16-0.22c-0.37,0.36-0.84,0.67-1.1,1.1
	c-0.07,0.11-0.13,0.22-0.19,0.32c-0.01-0.01-0.03-0.01-0.04-0.03c-0.58-0.94-1.14-0.31-1.7-0.44c-0.1-0.02-0.2,0.29-0.3,0.45
	c0.02,0.1,0.03,0.21,0.05,0.31c-0.39-0.17-0.78-0.34-1.16-0.5c-0.32-0.13-0.65-0.32-0.97-0.35c-0.52-0.04-1.02,0.05-1.54,0.06
	c-0.07,0-0.19-0.05-0.23-0.15c-0.07-0.2-0.14-0.28-0.2-0.3c0.19-0.05,0.42-0.07,0.3-0.96c-0.44,0.12-0.86,0.25-1.28,0.36
	c-0.15,0.04-0.3,0.16-0.45,0.1c-0.93-0.35-1.76,0.41-2.74-0.18c-0.44-0.27-0.94,0.19-1.41,0.3c-0.21,0.05-0.43,0.07-0.65,0.06
	c-0.52-0.04-1.05-0.12-1.64-0.19c0.65,1.02,1.14,1.22,1.4,0.83c0.15,0.15,0.33,0.36,0.45,0.49c-0.39-0.11-0.78-0.22-1.17-0.28
	c-0.27-0.05-0.54,0.1-0.8,0.2c-0.2,0.08-0.39,0.22-0.58,0.34c-0.02-0.08-0.04-0.16-0.05-0.24c0.06-0.09,0.13-0.18,0.19-0.27
	c-0.25,0.05-0.44,0.37-0.66,0.42c-0.5,0.1-1.02,0.05-1.53,0.08c-0.2,0.01-0.4,0.03-0.58,0.13c-0.11,0.06-0.18,0.28-0.27,0.42
	c-0.08-0.28-0.16-0.57-0.3-1.05c-0.68,0.05-1.4,0.16-2.14,0.14c-0.34-0.01-0.81-0.01-1.06-0.34c0.11-0.1,0.22-0.21,0.34-0.32
	c0.79,1.32,1.53,0.15,2.38,0.26c-0.14-0.19-0.24-0.31-0.34-0.44c0-0.07,0.01-0.15,0.01-0.22c0.32,0.07,0.65,0.13,0.97,0.2
	c0.01,0.07,0.02,0.13,0.02,0.2c-0.09,0.08-0.17,0.15-0.26,0.23c0.35-0.2,0.72,0,1.09,0.01c0.26,0.56,0.48,0.25,0.73-0.16
	c0.38-0.02,0.8,0.14,1.22,0.19c-0.08-0.24-0.18-0.53-0.25-0.74c0.2,0.08,0.38,0.16,0.56,0.24c-0.1-0.32-0.2-0.54-0.3-0.66
	c0.06-0.08,0.12-0.16,0.18-0.24c-0.02-0.07-0.04-0.15-0.06-0.22c-0.36,0.16-0.74,0.29-1.09,0.5c-0.47,0.28-0.91,0.14-1.41-0.37
	c-0.32-0.33-0.84-0.5-1.07-0.21c-0.32,0.38-0.61,0.33-0.95,0.23c-0.27-0.08-0.55-0.28-0.8-0.24c-0.71,0.13-1.41,0.38-2.11,0.52
	c-0.12,0.03-0.28-0.2-0.37-0.28c-0.19,0.16-0.36,0.3-0.54,0.44c-0.04-0.23-0.07-0.38-0.1-0.55c-0.63,0.81-0.76,0.81-0.95,0.02
	c-0.36,0.4-0.75,0.47-1.2,0.06c-0.16-0.14-0.36,0-0.68,0.03c0.26,0.24,0.37,0.34,0.48,0.44c-0.16,0.15-0.32,0.1-0.47,0.03
	c-0.19-0.09-0.39-0.34-0.56-0.3c-0.69,0.16-1.37,0.42-2.06,0.61c-0.46,0.12-0.92,0.27-1.39,0.26c-0.56-0.01-1.13-0.21-1.69-0.28
	c-0.23-0.03-0.46,0.09-0.69,0.08c-0.63-0.02-1.26-0.1-1.88-0.09c-0.12,0-0.22,0.28-0.32,0.42c-0.35-0.07-0.73-0.22-1.09-0.19
	c-0.36,0.03-0.69,0.2-1.02,0.54c-0.37,0.37-0.94,0.13-1.43,0.14c-0.63,0.01-1.26-0.04-1.89,0.03c-0.57,0.07-1.12,0.29-1.68,0.39
	c-0.97,0.16-1.96,0.17-2.91,0.48c-0.41-0.02-0.68,0.13-0.86,0.36c-0.47,0.22-1.02,0.06-1.52,0.12c-0.22,0.03-0.44,0.13-0.64,0.23
	c-0.2,0.1-0.38,0.37-0.59,0.37c-0.39,0-0.8-0.14-1.2-0.23c0,0.05,0,0.1,0.01,0.15c0.07,0.05,0.15,0.1,0.22,0.15
	c-0.36,0.21-0.71,0.2-1.06,0.27c-0.33,0.06-0.64,0.49-1.01-0.15c-0.03-0.05-0.12-0.01-0.19,0.03c0.01-0.05,0.02-0.1,0.04-0.15
	c-0.54-0.33-0.94-0.57-1.46-0.89c0.16,0.42,0.22,0.6,0.23,0.64c-0.04,0.42-0.08,0.75-0.11,1.04c-0.36,0.01-0.73,0.02-1.09,0.03
	c-0.19-0.12-0.38-0.28-0.58-0.42c-0.07,0.42-0.07,0.4-0.41,0.31c-0.03-0.01-0.07,0.05-0.11,0.14c-1.66,0.07-3.29,0.25-4.81,1.03
	c-0.09,0.51-0.16,0.9-0.25,1.42c0.33-0.03,0.63-0.16,0.92-0.05c-0.15,0.17-0.29,0.34-0.44,0.51c-0.16,0.17-0.49,0.27-0.74,0.41
	c-0.08-0.03-0.17-0.07-0.25-0.1c-0.11,0.51-0.32,1.02-0.3,1.53c0.06,1.63,0.28,3.25,0.3,4.88c0.03,1.62,1.13,3.16,0.69,4.8
	c0,0.02,0.08,0.04,0.29,0.14c0.04-0.43,0.08-0.8,0.11-1.17c0.05,0,0.1,0,0.15,0c0.29,0.83,0.58,1.65,0.87,2.48
	c0.08-0.01,0.16-0.02,0.24-0.02c-0.22-1.88-1.68-3.66-0.8-5.62c-0.12,0.01-0.25,0.01-0.37,0.02c0,0.29,0,0.58,0,0.87
	c-0.1,0.01-0.2,0.01-0.3,0.02c-0.08-0.61-0.22-1.22-0.21-1.83c0.01-0.35,0.28-0.7,0.41-1.06c0.07-0.18,0.13-0.36,0.2-0.54
	c-0.02,0.12-0.02,0.27-0.04,0.49c0.13,0.18,0.34,0.32,0.38,0.54c0.22,1.22,0.73,1.99,1.22,2.6c0,1.57-0.11,3.14,0.03,4.7
	c0.23,2.47,0.67,4.93,1.01,7.39c0.18,1.3,0.35,2.6,0.52,3.9c0.01,0.06,0,0.12-0.01,0.19c-0.07,0.87-0.16,1.74-0.19,2.61
	c-0.03,0.73,0.12,1.46,0.01,2.18c-0.16,1.1,0.51,2.17,0.14,3.29c-0.18,0.53,0.16,1.14,0.07,1.69c-0.19,1.21,0.52,2.41,0.04,3.61
	c-0.01,0.04,0.02,0.08,0.02,0.13c0,1.04,0.57,2.08-0.03,3.12c-0.11,0.18-0.04,0.42-0.02,0.63c0.05,0.9,0.13,1.81,0.17,2.71
	c0.04,0.83,0.06,1.65,0.06,2.48c-0.01,0.9-0.06,1.79-0.07,2.69c-0.01,0.45,0.08,0.89,0.06,1.34c-0.06,1.12-0.17,2.23-0.27,3.45
	c0.18-0.16,0.28-0.25,0.37-0.33c0.09,0.03,0.19,0.06,0.28,0.08c-0.14,0.27-0.28,0.53-0.42,0.8c-0.07,0.13-0.15,0.26-0.17,0.39
	c-0.26,1.73,0.19,3.47-0.16,5.2c-0.13,0.63,0.11,1.3,0.16,1.95c0.02,0.27,0,0.55-0.04,0.82c-0.11,0.82-0.24,1.63-0.36,2.44
	c-0.01,0.05,0.05,0.11,0.09,0.2c0.18-0.14,0.33-0.26,0.49-0.38c0.5,0.58,0.19,1.1-0.51,1.12c-0.73,1.09,1.03,1.9,0.68,2.95
	c0,0.01,0.09,0.04,0.29,0.13c0-0.44,0.12-0.83-0.02-1.18c-0.45-1.05-0.29-1.94,1.37-2.69c-0.25,1.01-0.47,1.92-0.69,2.83
	c-0.04,0.16-0.16,0.43-0.06,0.47c0.78,0.35,0.32,0.79,0.23,1.19c-0.06,0.25-0.37,0.57-0.23,0.72c0.64,0.71-0.39,1.27-0.12,1.92
	c-0.13,0.01-0.26,0.01-0.39,0.02c-0.24-0.74-0.48-1.48-0.69-2.13c-0.18,0.21-0.45,0.53-0.73,0.85c0.06,0,0.12,0,0.18-0.01
	c0,0.45,0,0.9,0,1.39c0.01,0,0.23,0.01,0.32,0.08c0.38,0.32,0.23,2.16-0.25,2.38c-0.89,0.41-0.72,0.96-0.54,1.54
	c0.18-0.21,0.35-0.42,0.53-0.63c0.09,0,0.18-0.01,0.27-0.01c0.15,0.55,0.32,1.11,0.41,1.66c0.01,0.05-0.16,0.12-0.39,0.22
	c-0.18-0.29-0.35-0.56-0.53-0.86c-0.12,0.86-0.22,1.63-0.32,2.41c0.09,0.02,0.19,0.03,0.28,0.05c0.48-0.45,0.96-0.9,1.48-1.39
	c0.32,1.02-0.02,3.09-0.6,3.23c0.19,0.75,0.73,1.56,0.47,2.22c-0.35,0.85-0.27,1.66-0.34,2.5c-0.03,0.39-0.34,0.77-0.41,1.17
	c-0.05,0.24,0.11,0.5,0.17,0.75c0.06,0.38-0.22,0.8-0.22,1.21c0,0.31,0.55,0.63,0.52,0.93c-0.07,0.82-0.28,1.64-1.22,2.29
	c-0.16-0.42-0.31-0.82-0.46-1.21c-0.05,0.52-0.15,0.97,0.22,1.5c0.4,0.56,0.07,1.35,0.06,2.04c-0.02,0.64-0.22,1.31,0.88,1.98
	c0.07-0.38,0.16-0.61,0.13-0.82c-0.07-0.62-0.64-1.3,0.47-1.79c0.09-0.04,0.09-0.19,0.1-0.3c0.08-0.54,0.1-1.09,0.23-1.62
	c0.05-0.2,0.31-0.38,0.48-0.57c0.07,0.02,0.14,0.04,0.21,0.07c0.06,0.22,0.12,0.44,0.18,0.66c0.36-0.48,0.42-0.89-0.62-1.03
	c0.28-0.2,0.46-0.34,0.67-0.49c0.11,0.13,0.18,0.21,0.28,0.32c0.14-0.22,0.26-0.39,0.47-0.71c0.06,0.39,0.09,0.61,0.12,0.83
	c0.09,0,0.18,0,0.27,0c0.19-1.37,0.34-2.74,0.58-4.11c0.08-0.47,0-0.87-0.23-1.33c-0.18-0.36-0.14-0.97,0.2-1.21
	c0.53-0.38,0.65-0.72,0.58-1.22c-0.16,0.28-0.29,0.51-0.5,0.87c-0.7-0.95,0.04-1.61,0.39-2.31c0.2,0.35,0.41,0.7,0.72,1.22
	c0.03-1.08-0.52-1.9-0.28-2.85c0.55-2.24,0.42-2.25,1.14-2.36c0,0.63-0.01,1.25,0,1.88c0.03,1.38,0.07,2.76,0.11,4.14
	c0,0.02,0,0.05,0.02,0.06c0.81,0.4,0.31,0.86,0.15,1.31c-0.07,0.19-0.1,0.4-0.15,0.62c0.36-0.04,0.61-0.07,0.86-0.1
	c0.08-0.79,0.17-1.57,0.25-2.36c0.09,0,0.19,0.01,0.28,0.01c0.14,0.27,1.26,0.4,0.21,0.84c-0.06,0.03-0.03,0.16-0.03,0.24
	c0.02,0.46,0.04,0.92,0.07,1.37c0.04,0.56,0.08,1.12-0.95,1.21c0.16,0.26,0.24,0.64,0.43,0.67c0.97,0.15,0.68,0.57,0.6,0.97
	c-0.08,0.38-0.15,0.75-0.2,1.13c-0.04,0.34-0.05,0.68-0.03,1.02c0.04,0.47,0.17,0.94,0.19,1.42c0.02,0.42-0.07,0.84-0.11,1.26
	c-0.12,0.15,0.02,0.32,0.22,0.45c1.24,0.86,1.08,1.91,0.77,2.93c-0.13,0.42-0.48,0.75-0.08,1.23c0.19,0.23-0.08,0.63-0.17,1.11
	c0.75,0.3-0.45,0.74-0.31,1.22c0.38-0.28,0.73-0.53,1.05-0.76c-0.51-0.73,0.04-1.44,0.24-2.34c0.13,0.3,0.18,0.43,0.24,0.56
	c0.68-0.29,0.73,0.21,0.74,0.36c0.02,0.51,0,1.05-0.2,1.54c-0.26,0.62-0.56,1.21-0.14,1.8c0.3,0,0.54,0,0.66,0
	c0.18-0.5,0.33-1.01,0.56-1.5c0.31-0.68,1.35-1.16,1.25-1.96c-0.03-0.2-0.02-0.42,0.08-0.61c0.2-0.37,0.42-0.34,0.6-0.05
	c0.1-0.67,0.19-1.27,0.28-1.86c0.09-0.62,0.29-1.25,0.23-1.87c-0.05-0.6-0.39-1.18-0.55-1.78c-0.04-0.16,0.1-0.34,0.2-0.51
	c0.15-0.25,0.37-0.48,0.49-0.73c0.13-0.27,0.17-0.56,0.25-0.84c-0.19-0.01-0.46-0.02-0.64-0.03c0.08-0.56,0.12-1.09,0.23-1.61
	c0.1,0.02,0.21,0.03,0.3,0.03c0.26,0.01,0.6,0.06,0.75-0.05c0.16-0.12,0.31-0.25,0.45-0.38c0.05,0.08,0.1,0.17,0.14,0.25
	c0.06-0.05,0.13-0.12,0.33-0.29c0.43,0.8,0.8,1.48,1.17,2.17c0.11-0.04,0.22-0.07,0.32-0.11c-0.33-0.6-0.68-1.19-0.99-1.8
	c-0.08-0.17-0.11-0.4,0-0.54c0.52-0.67,0.65-1.37,0.46-2.09c0.29-0.19,0.65-0.32,0.92-0.53c-0.07,0.27-0.13,0.54-0.23,0.95
	c1.42-0.37,1.19-1.71,1.96-2.39c-0.27-0.09-0.4-0.14-0.53-0.18c-0.02-0.07-0.04-0.15-0.07-0.22c0.36,0,0.72-0.01,1.17-0.01
	c0.2-0.24,0.47-0.58,0.75-0.91c-0.03-0.02-0.05-0.03-0.08-0.05c-0.28,0.17-0.56,0.34-0.84,0.51c-0.08-0.08-0.17-0.16-0.25-0.25
	c0.47-1.08,1.79-1.39,3.01-1.77c-0.05-0.06-0.1-0.12-0.15-0.18c-0.26-0.02-0.51-0.04-0.79-0.06c0.3-1.08,0.86-1.4,1.67-0.98
	c0.28-0.75,0.56-1.49,0.84-2.25c-0.62,0.5-1.21,0.98-1.81,1.45c-0.12,0.09-0.25,0.16-0.36,0.27c-0.42,0.41-0.99,0.73-1.21,1.26
	c-0.28,0.67-0.59,1.25-1.38,1.5c-0.13,0.04-0.27,0.09-0.36,0.18c-0.47,0.5-0.95,0.99-1.42,1.49c0-0.02,0-0.04,0-0.06
	c-0.03,0.03-0.05,0.06-0.08,0.09c0.02,0,0.04,0,0.05,0c-0.58,0.61-1.16,1.23-1.72,1.85c-0.2,0.22-0.4,0.45-0.59,0.67
	c-0.02-0.27-0.05-0.55-0.08-0.82c-0.01-0.08-0.15-0.15-0.19-0.16c0.07-0.25,0.11-0.5,0.13-0.74c0.32-0.08,0.66-0.13,0.96-0.23
	c0.01,0.02,0.03,0.04,0.04,0.07c0.14-0.02,0.27-0.04,0.41-0.07c0-0.09,0-0.17,0-0.26c0.07-0.06,0.12-0.14,0.17-0.23
	c0.09-0.18,0.21-0.36,0.4-0.46c0.37-0.19,0.54-0.04,0.52,0.29c0.46-0.5,0.87-0.94,1.27-1.39c0.42-0.47,0.94-0.88,1.24-1.42
	c0.29-0.52,0.33-1.2,0.54-1.78c0.05-0.15,0.27-0.23,0.45-0.31c0.26-0.12,0.57-0.19,0.81-0.34c0.26-0.15,0.45-0.37,0.68-0.56
	c-0.15-0.11-0.37-0.28-0.51-0.39c0.5-0.55,0.91-1.1,1.44-1.54c0.19-0.16,0.66-0.08,1-0.11c0.15-0.01,0.34,0.01,0.49,0.01
	c-0.25,0.09-0.4,0.29-0.38,0.58c0.02,0.23-0.01,0.44-0.02,0.65c0.07-0.01,0.17-0.02,0.43-0.05c-0.09,0.91-0.16,1.68-0.24,2.45
	c0.11,0.03,0.22,0.06,0.33,0.09c0.06-0.68,0.1-1.37,0.19-2.04c0.02-0.18,0.13-0.39,0.3-0.45c0.91-0.31,1.43-0.91,1.64-1.77
	c-0.27,0.01-0.54,0.1-0.51,0.25c0.13,0.75-0.43,0.55-1,0.34c-0.19-0.07-0.37-0.09-0.53-0.08c0.01-0.01,0.03-0.01,0.03-0.03
	c0.35-0.44,0.62-0.94,0.88-1.45c0.04-0.07-0.04-0.21-0.07-0.24c0.87-0.75,1.18-1.71,1.72-2.56c0.12,0.1,0.25,0.2,0.38,0.3
	c0.19-0.27,0.19-0.27-0.17-0.85c0.27-0.54,0.38-1.03,0.7-1.35c0.35-0.34,0.9-0.5,1.41-0.77c0.05,0.23,0.08,0.36,0.11,0.49
	c0.1,0.04,0.19,0.08,0.29,0.11c0.16-0.56,0.31-1.12,0.48-1.68c0.03-0.1,0.08-0.26,0.17-0.26c1.18,0,1.16-1.02,1.72-1.54
	c0.1-0.1-0.06-0.41-0.09-0.63c-0.09-0.05-0.18-0.11-0.27-0.16c0.44-0.31,0.89-0.61,1.32-0.92c0.36-0.26,0.77-0.49,1.05-0.81
	c0.45-0.53,0.8-1.14,1.2-1.7c0.06-0.08,0.19-0.17,0.29-0.15c0.21,0.05,0.32,0.04,0.39-0.01c-0.12,0.24-0.29,0.5,0.47,0.97
	c0.26-0.55,0.51-1.09,0.77-1.62c0.09-0.19,0.13-0.43,0.29-0.55c1.02-0.76,1.13-2.18,2.37-2.83c0.14-0.07,0.25-0.18,0.34-0.31
	c0.12,0.13,0.24,0.27,0.35,0.41c0.11,0.13,0.01,0.41,0.01,0.55c0.28,0.15,0.52,0.28,0.77,0.42c-0.18,0.15-0.3,0.24-0.43,0.36
	c1.18,0.37,1.27,0.52,0.68,1.09c0.61,0.24,0.95,0.66,0.9,1.37c-0.02,0.25,0.26,0.42,0.5,0.77c0.03-0.4,0.05-0.58,0.06-0.76
	c0.24,0.12,0.32,0.32,0.35,0.53c0.05,0.26-0.04,0.61,0.12,0.78c0.63,0.73,1.34,1.4,1.99,2.12
	C343.87,493.43,344.32,493.9,344.64,494.45z M370.77,521.3c0.82,0.98,1.64,1.97,2.46,2.95c-0.02,0.06-0.04,0.12-0.06,0.18
	c-0.29-0.12-0.7-0.16-0.86-0.38c-0.64-0.83-1.2-1.72-1.79-2.58C370.61,521.41,370.69,521.36,370.77,521.3z M370.55,426.32
	c0.06,0,0.12-0.01,0.19-0.01c0,0.32,0.11,0.71-0.03,0.94c-0.03,0.06-0.08,0.11-0.11,0.17c-0.17-0.06-0.35-0.13-0.53-0.19
	C370.23,426.92,370.39,426.62,370.55,426.32z M369.67,427.95c0.16,0.07,0.31,0.17,0.47,0.19c-0.38,0.58-0.78,1.15-1.16,1.73
	c-0.08-0.06-0.17-0.11-0.25-0.17C369.04,429.12,369.36,428.53,369.67,427.95z M361.11,421.3c-0.11-0.01-0.22-0.02-0.34,0
	c-0.04-0.17-0.07-0.34-0.09-0.42c-0.01,0.01-0.03,0.02-0.04,0.03C360.86,420.68,360.99,420.93,361.11,421.3z M360.38,429.57
	C360.39,429.57,360.39,429.57,360.38,429.57c-0.09,0.19-0.2,0.37-0.3,0.55c-0.09-0.01-0.18-0.01-0.27-0.02
	c-0.01-0.09-0.02-0.19-0.02-0.28C359.97,429.67,359.95,428.55,360.38,429.57z M358.37,431.41c0.35-0.04,0.69-0.08,1.04-0.12
	c0,0.02-0.01,0.03-0.02,0.05c-0.09,0.72-0.08,0.78-1.17,0.38C358.28,431.62,358.32,431.51,358.37,431.41z M356.73,422.16
	c0.09-0.07,0.45,0.1,0.69,0.16c0.02,0.05,0.04,0.1,0.05,0.14c-0.02,0.09-0.05,0.2-0.08,0.34c-0.43,0.11-0.76,0.32-1.09,0.25
	c-0.14-0.03-0.28-0.1-0.42-0.19C356.16,422.62,356.44,422.38,356.73,422.16z M296.25,430.04c-0.5,0.1-1,0.21-1.5,0.31
	c0.04-0.16,0.08-0.31,0.12-0.47c0.08-0.1,0.17-0.19,0.26-0.2c0.4-0.06,0.81-0.1,1.22-0.12
	C296.31,429.72,296.28,429.88,296.25,430.04z M296.43,429.28c-0.04,0.01-0.08,0.01-0.13,0.02c-0.12,0.02-0.29-0.31-0.44-0.49
	c0.01,0.07,0.03,0.14,0.04,0.21c-0.3,0.11-0.59,0.22-0.89,0.33c0.1-0.37,0.21-0.74,0.33-1.11c0.04-0.11,0.43-0.17,0.66-0.25
	c0.06,0.04,0.12,0.08,0.18,0.12c0.01-0.02,0.02-0.05,0.04-0.07c0.04,0.03,0.08,0.05,0.13,0.08c0.13,0.07,0.26,0.13,0.4,0.18
	C296.62,428.63,296.52,428.96,296.43,429.28z M299.15,493.76c-0.07,0-0.15,0.01-0.22,0.01c-0.13-0.33-0.26-0.67-0.38-0.98
	c1.01,0.06,0.36-0.45,0.51-0.66c0.19-0.27,0.27-0.58,0.4-0.88c0.1,0.01,0.2,0.03,0.3,0.04
	C299.56,492.11,299.36,492.94,299.15,493.76z M299.28,482.98c0.34,0.35,0.61,0.69,0.15,1.12c-0.13,0.12-0.04,0.36,0.03,0.54
	c0.15,0.43,0.34,0.85,0.42,1.3C298.43,484.34,298.3,483.59,299.28,482.98z M298.33,506.06c0-0.01,0-0.02,0-0.03
	c0.01,0.01,0.01,0.01,0.02,0.02C298.35,506.05,298.34,506.06,298.33,506.06z M303.26,505.31c-0.98-0.82-0.1-1.52,0.16-2.25
	C303.36,503.81,303.31,504.56,303.26,505.31z M303.88,491.8c0.1-0.44,0.18-0.82,0.26-1.19c0.06,0,0.11,0,0.17,0
	c0.28,0.74,0.56,1.48,0.83,2.23c-0.15,0.02-0.29,0.04-0.44,0.06c-0.05-0.38-0.1-0.76-0.15-1.17
	C304.41,491.73,304.22,491.76,303.88,491.8z M304.47,493.44c0.87,0.19,0.97,0.5,0.55,1.64
	C304.81,494.46,304.65,493.98,304.47,493.44z M305.28,507.7c0.04-0.47,0.08-0.95,0.12-1.42c0.14,0,0.29,0,0.43,0
	c-0.07,0.48-0.13,0.96-0.2,1.44C305.51,507.72,305.39,507.71,305.28,507.7z M305.5,502.51c0.04-0.1,0.1-0.19,0.17-0.26
	c0.09,0.06,0.2,0.12,0.29,0.19c-0.13,0.06-0.25,0.11-0.38,0.17C305.55,502.57,305.52,502.54,305.5,502.51z M305.63,518.93
	c0-1,0-2,0-3c0.07,0,0.13,0,0.19,0c0.05,0.29,0.15,0.58,0.14,0.86C305.94,517.51,305.87,518.22,305.63,518.93z M306.11,499.53
	c-0.1,0.41-0.26,0.82-0.26,1.23c-0.21,0.29-0.49,0.56-0.71,0.84c0.04-0.18,0.15-0.35,0.38-0.53c0.08-0.06,0.12-0.2,0.08-0.28
	c-0.47-1.01-0.44-2,0.03-3.01c0.13-0.28,0.11-0.59,0.16-0.88c0.1-0.01,0.19-0.02,0.29-0.02c0.11,0.24,0.32,0.48,0.31,0.72
	C306.35,498.23,306.27,498.89,306.11,499.53z M313.57,514.23c0.27,0.33,0.12,0.5-0.17,0.61
	C313.38,514.58,313.44,514.39,313.57,514.23z M344.71,490.56c-0.28-0.27-0.57-0.54-0.92-0.89c0.26-0.09,0.54-0.23,0.57-0.2
	c0.23,0.31,0.41,0.64,0.61,0.97C344.87,490.49,344.79,490.53,344.71,490.56z M347.72,469.74c-0.03-0.02-0.06-0.04-0.09-0.06
	c0.19-0.21,0.39-0.41,0.6-0.61c0.02,0.06,0.04,0.12,0.05,0.19C348.1,469.43,347.89,469.58,347.72,469.74z M348.84,468.5
	c0,0-0.01-0.01-0.01-0.01c0.02-0.02,0.04-0.04,0.06-0.06C348.88,468.45,348.86,468.48,348.84,468.5z M349.37,466.49
	c-0.18-0.21-0.42-0.41-0.39-0.45c0.2-0.33,0.45-0.62,0.68-0.92c0.06,0.06,0.13,0.13,0.19,0.19
	C349.7,465.67,349.56,466.04,349.37,466.49z M349.91,466.99c-0.03-0.44,0.2-0.67,0.55-0.86
	C350.29,466.42,350.1,466.71,349.91,466.99z M351.26,429.39c0.31,0.05,0.61-0.01,0.87-0.38c-0.04,0.19-0.09,0.38-0.13,0.57
	c0.16,0.04,0.33,0.15,0.47,0.1c0.09-0.03,0.14-0.29,0.23-0.48c0.05,0.12,0.11,0.24,0.16,0.36c-0.33,0.51-0.67,0.77-1.12,0.15
	c-0.06-0.08-0.16-0.11-0.21-0.07c-0.15,0.11-0.3,0.19-0.45,0.26C351.14,429.73,351.2,429.56,351.26,429.39z M350.45,430.06
	c0.01-0.02,0.03-0.03,0.04-0.05c0.02,0.01,0.04,0.03,0.06,0.04C350.51,430.05,350.48,430.06,350.45,430.06z M349.09,429.04
	c0.48,0,0.97,0.04,1.46,0.16c-0.12,0.3-0.27,0.58-0.45,0.84c-0.26-0.04-0.52-0.12-0.79-0.26c-0.22-0.11-0.44-0.08-0.67-0.11
	c-0.02-0.09-0.03-0.19-0.05-0.28C348.76,429.26,348.91,429.04,349.09,429.04z M348.99,436.19c-0.16,0.33-0.31,0.65-0.47,0.98
	c-0.51,0.06-1-0.32-1.52-1.47C347.71,435.87,348.35,436.03,348.99,436.19z M345.1,431.85c-0.33-0.07-0.62-0.13-0.9-0.2
	c-0.01-0.06-0.01-0.11-0.02-0.17c0.52-0.32,1.03-0.63,1.55-0.95c0.03,0.14,0.06,0.29,0.09,0.43c-0.27,0.07-0.55,0.14-0.85,0.21
	C345,431.32,345.03,431.51,345.1,431.85z M347.04,436.44c0.01,0.07,0.02,0.14,0.03,0.22c-0.06,0.04-0.12,0.08-0.18,0.11
	c-0.05-0.1-0.11-0.2-0.19-0.31c-0.03,0.17-0.07,0.33-0.1,0.5c-0.07,0.04-0.14,0.09-0.21,0.13c-0.06-1.01-0.37-0.33-0.54-0.47
	c-0.22-0.18-0.46-0.24-0.69-0.35c0-0.1,0-0.2,0-0.3C345.78,436.12,346.41,436.28,347.04,436.44z M346.24,431.17
	c0.04-0.88,0.26-0.99,1.15-0.64C346.96,430.77,346.62,430.96,346.24,431.17z M343.5,422.05c0.09-0.01,0.2-0.01,0.26-0.1
	c0.5-0.74,1.16-0.63,1.77-0.41c0.47,0.17,0.85,0.15,1.25-0.11c0.1-0.06,0.27,0.17,0.39,0.25c0.19-0.06,0.38-0.11,0.56-0.18
	c-0.06,0.06-0.12,0.15-0.18,0.21c-0.22,0.21-0.45,0.39-0.67,0.62c-0.11,0.12-0.19,0.33-0.28,0.49c-0.14,0.23-0.28,0.45-0.48,0.77
	c-0.14-1.19-0.86-1.93-1.39-1.67c0.11,0.12,0.21,0.22,0.35,0.38c-0.38,0.21-0.69,0.38-1.04,0.57c0.14,0.2,0.2,0.29,0.27,0.39
	c0,0.09,0,0.18-0.01,0.26c-0.56-0.05-1.12-0.11-1.68-0.16c-0.01-0.12-0.02-0.24-0.02-0.36c0.18-0.01,0.37-0.03,0.55-0.04
	c0-0.08,0-0.15,0-0.23c-0.19-0.08-0.38-0.19-0.57-0.24c-0.17-0.05-0.35-0.1-0.52-0.15C342.47,421.58,343.02,422.11,343.5,422.05z
	 M339.9,436.69c0.1,0.12,0.27,0.01,0.39-0.06c0.3-0.17,0.59-0.38,0.91-0.49c-1.02,1.52-1.56,1.69-2.11,0.76
	C339.32,436.55,339.54,436.26,339.9,436.69z M331.43,422.2c-0.17,0.07-0.33,0.16-0.49,0.24
	C331.06,422.07,331.25,422.14,331.43,422.2z M329.26,422.27c0.24-0.01,0.5,0.11,0.75,0.17c0,0.04,0.01,0.07,0.01,0.11
	c-0.44-0.06-0.88-0.16-1.32-0.26C328.89,422.28,329.07,422.27,329.26,422.27z M327.4,422.34c-0.22,0.29-0.47,0.24-0.75,0.08
	C326.9,422.38,327.15,422.36,327.4,422.34z M326.5,423.4c0.27-0.1,0.55-0.2,0.89-0.33c-0.02,0.27-0.02,0.58-0.06,0.58
	c-0.28,0.04-0.56,0.02-0.85,0.01C326.5,423.58,326.5,423.49,326.5,423.4z M314.4,472.77c-0.04,0-0.07,0-0.11,0
	c0.09-0.58,0.23-1.16,0.36-1.74c-0.01,0.25-0.02,0.51-0.02,0.76C314.62,472.12,314.48,472.44,314.4,472.77z M314.53,474.72
	c-0.06-0.24-0.14-0.47-0.2-0.7C314.69,474.24,314.61,474.49,314.53,474.72z M313.79,489.27c0.41,0.58-0.04,1.18-0.01,1.77
	c0,0.08,0,0.16,0.02,0.24c-0.11,0.04-0.21,0.04-0.29,0.01c-0.13,0.37-0.2,0.8-0.31,1.19c-0.09-0.27-0.18-0.54-0.27-0.83
	c-0.21,0.16-0.31,0.23-0.41,0.31c-0.09-0.02-0.18-0.03-0.26-0.05c0.02-0.14,0.04-0.28,0.05-0.42c0.54-0.5,1.09-1.01,1.63-1.51
	c-0.34,0.02-0.55,0.18-0.75,0.36c0.09-0.2,0.18-0.41,0.24-0.61c0.06-0.22,0.12-0.43,0.18-0.65
	C313.67,489.15,313.73,489.21,313.79,489.27z M313.61,489.08c0-0.01,0-0.01,0.01-0.02c0,0,0,0,0.01,0.01
	C313.61,489.07,313.61,489.07,313.61,489.08z M314.01,496.84c-0.05-0.09-0.14-0.19-0.2-0.28c-0.06-0.1-0.11-0.2-0.16-0.29
	c0.08-0.13,0.19-0.25,0.31-0.33c-0.03,0.04-0.07,0.08-0.09,0.12C313.92,496.31,313.95,496.57,314.01,496.84z M313.07,490.46
	c-0.2,0.21-0.4,0.43-0.7,0.54c0.06-0.44,0.11-0.89,0.17-1.33c0.12,0.01,0.24,0.01,0.36,0.02c0,0.25,0,0.5,0,0.75
	C312.96,490.45,313.02,490.46,313.07,490.46z M313.43,467.85c0.09,0.02,0.18,0.03,0.27,0.05c0.08,0.38,0.16,0.77,0.26,1.25
	c-0.27-0.07-0.58-0.11-0.58-0.16C313.37,468.62,313.41,468.23,313.43,467.85z M314.67,468.24c0.02,0.34,0.02,0.68,0.02,1.02
	C314.42,468.92,314.49,468.6,314.67,468.24z M311.81,508.3c0-0.04,0-0.09,0.01-0.13c0.1-0.46,0.44-0.89,0.72-1.38
	C312.53,507.42,312.22,507.89,311.81,508.3z M314.5,502.2c-0.08-0.19-0.21-0.39-0.25-0.58c-0.13-0.51,0.58-1.16,1.2-1.13
	c0.45,0.52,0.05,0.7-0.22,0.96C314.97,501.69,314.73,501.94,314.5,502.2z M316.15,501.57c-0.42,0.58-0.88,1.13-1.47,1.58
	c0.56-0.83,1.12-1.66,1.67-2.49c0.05,0.04,0.11,0.07,0.16,0.11C316.4,501.04,316.32,501.33,316.15,501.57z M315.81,488.95
	c-0.12,0.42-0.44,0.92-0.8,1.35c-0.07-0.25-0.14-0.51-0.17-0.75c-0.02-0.15-0.03-0.29-0.03-0.44
	C315.13,489.06,315.46,489.01,315.81,488.95z M315.8,488.4c0.01-0.01,0.01-0.02,0.02-0.02c0,0.01,0.01,0.02,0.01,0.03
	C315.82,488.4,315.81,488.4,315.8,488.4z M320.32,490.52c-0.36-1.22,0.76-1.32,1.38-1.78C321.24,489.34,320.78,489.93,320.32,490.52
	z M320.93,493.85c-0.09-0.07-0.19-0.14-0.28-0.21c0.3-0.37,0.59-0.74,0.89-1.11c0.12,0.08,0.24,0.16,0.35,0.24
	C321.58,493.13,321.26,493.49,320.93,493.85z M327.22,485.86c-0.39,0.52-0.82,1.02-1.31,1.46c-0.56,0.51-1.26,0.9-1.15,1.84
	c-0.11-0.17-0.21-0.35-0.32-0.53c-0.16,0.14-0.38,0.25-0.46,0.44c-0.05,0.12,0.1,0.35,0.18,0.57c-0.14-0.02-0.27-0.05-0.4-0.07
	c-0.11-0.71-0.02-1.24,0.8-1.31c0.1-0.01,0.21-0.1,0.22-0.19c0.17-1.1,0.76-1.91,1.71-2.48c0.26-0.16,0.42-0.43,0.63-0.64
	c0.08,0.05,0.17,0.09,0.25,0.14C327.32,485.35,327.36,485.67,327.22,485.86z M323.35,478.65c-0.06-0.04-0.12-0.08-0.19-0.11
	c0.08-0.35,0.16-0.7,0.23-1.02c0.81,0.61,0.55-0.17,0.79-0.26c0.31-0.12,0.55-0.33,0.83-0.5c0.08,0.07,0.15,0.13,0.23,0.2
	C324.61,477.51,323.98,478.08,323.35,478.65z M327.48,483.02c0.18-0.63,0.31-1.13,0.46-1.67
	C328.56,481.99,328.47,482.31,327.48,483.02z M328.84,481.22c-0.13-0.07-0.26-0.13-0.4-0.2c0.17-0.34,0.34-0.69,0.53-1.06
	c-0.13-0.06-0.3-0.15-0.61-0.31c0.33-0.31,0.6-0.58,0.88-0.84c0.05,0.03,0.09,0.06,0.14,0.09
	C329.2,479.68,329.02,480.45,328.84,481.22z M328.97,470.79c-0.17,0.03-0.23,0.28-0.28,0.46c-0.12,0.44-0.2,0.9-0.38,1.31
	c-0.31-2.13,0.01-2.82,1.15-2.79C329.55,470.26,329.59,470.69,328.97,470.79z M337.01,484.55c-0.07,0.11-0.16,0.22-0.14,0.35
	c0.14,1.04-0.48,1.67-1.14,2.18c-0.5,0.39-0.81,0.82-0.94,1.43c-0.03,0.15-0.35,0.18-0.51,0.25c-0.11,0.24-0.23,0.48-0.33,0.73
	c0.01-0.11-0.01-0.23-0.01-0.34c0.03-0.38,0.08-0.75,0.08-1.14c0-0.2-0.1-0.43-0.14-0.64c-0.06-0.3-0.11-0.6-0.18-1.03
	c1,0.65,2.14,0.38,2.39-0.37c-0.18,0.04-0.33,0.07-0.57,0.12c0.15-0.55,0.28-0.99,0.42-1.5c-0.26,0.01-0.39,0.02-0.52,0.03
	c-0.06-0.06-0.13-0.12-0.19-0.19c0.37-0.42,0.74-0.84,1.11-1.26c0.04,0.13,0.09,0.25,0.17,0.35c0.22,0.27,0.45,0.53,0.68,0.79
	C337.13,484.38,337.06,484.46,337.01,484.55z M338.35,482.6c-0.01-0.03-0.02-0.06-0.03-0.09c0.01-0.01,0.02-0.02,0.04-0.03
	c0.02,0.04,0.04,0.07,0.06,0.11C338.39,482.59,338.37,482.6,338.35,482.6z M338.78,483.84c-0.01,0-0.01-0.01-0.02-0.01
	c0.01-0.02,0.02-0.04,0.03-0.05C338.78,483.8,338.78,483.82,338.78,483.84z M340.18,485.4c0.19,0.16,0.38,0.31,0.56,0.47
	C340.32,485.89,340.25,485.63,340.18,485.4z M341.75,487.88c-0.18-0.28-0.25-0.62-0.37-0.94c0.03-0.02,0.06-0.04,0.09-0.06
	c0.25,0.53,0.47,1.09,0.69,1.64C342.03,488.3,341.89,488.09,341.75,487.88z M340.82,450.28c-0.45,0.34-0.5,1.03-0.89,1.45
	c-0.83,0.9-0.91,2.29-1.98,3.02c-0.03,0.02-0.03,0.08-0.05,0.12c-0.58,0.86-0.69,2.04-1.77,2.57c-0.19,0.09-0.27,0.33-0.37,0.51
	c-0.46,0.78-0.9,1.57-1.37,2.35c-0.43,0.71-0.87,1.4-1.34,2.09c-0.51,0.74-1.05,1.45-1.56,2.19c-0.25,0.37-0.43,0.79-0.7,1.14
	c-0.68,0.89-1.39,1.76-2.15,2.71c0.24-0.03,0.37-0.05,0.49-0.06c0.06,0.08,0.12,0.15,0.19,0.23c-0.26,0.14-0.53,0.29-0.79,0.43
	c-0.13,0.07-0.27,0.13-0.36,0.23c-1.18,1.29-1.78,2.98-3.04,4.23c-0.46,0.45-0.63,1.14-0.95,1.71c-0.13,0.24-0.3,0.45-0.49,0.66
	c-0.55,0.61-1.11,1.22-1.66,1.83c-0.03,0.04-0.02,0.11-0.04,0.21c0.23-0.02,0.41-0.03,0.62-0.05c0.09,0.76-0.45,1.02-1.05,0.64
	c-1.21,0.5-0.21,2.15-1.08,2.83c-0.01,0.01,0.05,0.08,0.17,0.27c0.24-0.36,0.57-0.62,0.64-0.99c0.21-1.12,0.84-1.77,2.64-1.47
	c-0.77,0.7-1.46,1.33-2.15,1.96c-0.12,0.11-0.37,0.27-0.31,0.35c0.45,0.73-0.17,0.83-0.48,1.11c-0.19,0.17-0.62,0.27-0.59,0.47
	c0.14,0.95-1.03,0.83-1.17,1.53c-0.11-0.07-0.22-0.13-0.33-0.2c0.22-0.75,0.43-1.5,0.62-2.15c-0.27,0.07-0.67,0.19-1.08,0.3
	c0.05,0.03,0.1,0.06,0.15,0.1c-0.25,0.37-0.5,0.75-0.78,1.15c0.01,0.01,0.19,0.13,0.22,0.24c0.14,0.47-1.02,1.92-1.54,1.83
	c-0.97-0.15-1.13,0.39-1.31,0.97c0.26-0.08,0.53-0.15,0.79-0.23c0.08,0.05,0.15,0.09,0.23,0.14c-0.19,0.54-0.35,1.09-0.59,1.61
	c-0.02,0.05-0.2,0.01-0.44-0.03c0.01-0.34,0.02-0.66,0.04-1.01c-0.08,0.09-0.15,0.17-0.23,0.26c0.05-0.33,0.1-0.67,0.15-1.03
	c-1.07,0.73-1.31,1.36-0.94,1.78c-0.16,0.19-0.38,0.39-0.52,0.54c0.14-0.51,0.27-1.02,0.37-1.54c0.07-0.36-0.06-0.74-0.14-1.1
	c-0.06-0.28-0.19-0.55-0.29-0.83c0.08-0.01,0.16-0.03,0.24-0.04c0.09,0.1,0.17,0.2,0.26,0.3c-0.03-0.35-0.34-0.65-0.37-0.95
	c-0.06-0.69,0.02-1.39,0.03-2.08c0-0.27,0-0.54-0.08-0.8c-0.05-0.15-0.26-0.28-0.4-0.42c0.29-0.07,0.58-0.14,1.07-0.26
	c0-0.92-0.06-1.91,0.02-2.9c0.04-0.46,0.07-1.09,0.42-1.38c0.1,0.17,0.19,0.33,0.29,0.51c-1.38,0.88-0.26,2.04-0.44,3.18
	c0.2-0.17,0.33-0.28,0.46-0.39c0.07,0.01,0.15,0.03,0.22,0.04c-0.09,0.43-0.18,0.85-0.27,1.28c-0.07,0-0.13,0-0.2,0
	c-0.07-0.13-0.14-0.26-0.21-0.39c0.17,0.5-0.05,0.97-0.09,1.47c-0.58,0.28-0.28,0.61,0.11,1c0,0.51-0.2,1.05-0.28,1.61
	c0.24-0.08,0.54-0.17,0.75-0.24c-0.1,0.26-0.19,0.49-0.28,0.73c0.33-0.09,0.55-0.19,0.68-0.31c0.08,0.09,0.15,0.18,0.23,0.27
	c0.07-0.02,0.15-0.03,0.22-0.05c-0.14-0.52-0.23-1.04-0.42-1.54c-0.25-0.68-0.08-1.24,0.47-1.86c0.35-0.39,0.56-1.06,0.29-1.42
	c-0.36-0.48-0.29-0.87-0.16-1.31c0.1-0.35,0.32-0.7,0.3-1.05c-0.07-0.98-0.27-1.95-0.37-2.93c-0.02-0.17,0.22-0.35,0.3-0.46
	c-0.14-0.28-0.27-0.53-0.4-0.79c0.24-0.02,0.38-0.04,0.55-0.05c-0.77-0.97-0.75-1.15,0.05-1.28c-0.37-0.55-0.41-1.08,0.03-1.63
	c0.15-0.19,0.02-0.49,0.02-0.92c-0.25,0.31-0.37,0.45-0.48,0.59c-0.13-0.24-0.08-0.44,0.01-0.64c0.11-0.24,0.37-0.48,0.35-0.71
	c-0.11-0.96-0.32-1.91-0.45-2.87c-0.09-0.63-0.2-1.28-0.15-1.91c0.05-0.75,0.29-1.49,0.4-2.24c0.05-0.31-0.05-0.63-0.03-0.94
	c0.07-0.84,0.19-1.68,0.23-2.53c0.01-0.17-0.27-0.34-0.4-0.49c0.09-0.46,0.27-0.95,0.27-1.45c0-0.49-0.15-0.95-0.46-1.45
	c-0.35-0.55-0.06-1.29-0.03-1.94c0.03-0.85,0.13-1.7,0.11-2.55c-0.02-0.77-0.21-1.55-0.26-2.32c-0.07-1-0.05-2-0.13-3
	c0.05-0.09,0.09-0.18,0.14-0.28c0.89,0.68,0.98,1.32,0.29,1.91c0.59,0.54,1.18,1.07,1.78,1.62c-0.23-0.76-0.46-1.49-0.68-2.22
	c-0.04-0.14-0.06-0.3-0.11-0.43c-0.22-0.54-0.31-1.19-0.72-1.59c-0.52-0.51-0.94-1.01-0.88-1.84c0-0.02,0-0.03,0-0.05
	c0.06-0.08,0.11-0.17,0.17-0.29c0.36-0.79,0.83-0.86,1.33-0.94c1.28-0.21,2.56-0.41,3.84-0.62c-0.01-0.1-0.02-0.21-0.03-0.31
	c-0.46-0.02-0.93-0.12-1.38-0.05c-1.1,0.17-2.2,0.43-3.29,0.63c-0.2,0.04-0.43,0.11-0.61-0.05c-0.12-0.11-0.24-0.16-0.34-0.19
	c-0.03-0.16-0.08-0.31-0.13-0.47c-0.02-0.07-0.05-0.14-0.09-0.21c0.55-0.14,1.11-0.29,1.66-0.43c0.04-0.01,0.09,0,0.14,0
	c0.65,0.02,1.3,0.06,1.94,0.04c0.54-0.01,1.06-0.2,1.6-0.13c0.83,0.1,1.54-0.63,2.41-0.32c0.41,0.15,0.82-0.22,1.24-0.16
	c0.91,0.12,1.72-0.65,2.65-0.24c0.03,0.01,0.06-0.02,0.09-0.03c0.76-0.05,1.47-0.68,2.3-0.14c0.15,0.1,0.31,0.01,0.47-0.01
	c0.66-0.1,1.32-0.23,1.98-0.32c0.6-0.08,1.21-0.15,1.81-0.19c0.66-0.04,1.33-0.04,1.99-0.07c0.33-0.02,0.65-0.13,0.98-0.13
	c0.83,0,1.66,0.05,2.56,0.08c-0.14-0.17-0.21-0.26-0.28-0.35c0.01-0.09,0.02-0.19,0.03-0.28c0.21,0.12,0.42,0.25,0.63,0.37
	c0.1,0.06,0.21,0.14,0.31,0.15c1.3,0.16,2.53-0.38,3.84-0.13c0.48,0.09,0.94-0.18,1.42-0.27c0.2-0.04,0.4-0.03,0.6-0.01
	c0.36,0.04,0.71,0.08,1.07,0.13c-0.04,0.21-0.09,0.43-0.13,0.63c0.18-0.22,0.32-0.42,0.43-0.6c0.16,0.02,0.31,0.04,0.47,0.06
	c0.04,0,0.07-0.05,0.14-0.1c-0.12-0.17-0.22-0.31-0.33-0.46c0.37-0.53,0.79-0.25,0.88,0.45c0.09,0.07,0.18,0.11,0.26,0.13
	c-0.29,0.61-0.58,1.22-0.87,1.82c-0.58,1.18-1.16,2.35-1.75,3.53c-0.03,0.06-0.07,0.1-0.11,0.15c-0.54,0.69-1.1,1.36-1.61,2.07
	c-0.43,0.59-0.72,1.28-1.2,1.81C341.79,448.3,341.75,449.56,340.82,450.28z M343.72,490.83c-0.21-0.27-0.4-0.55-0.6-0.83
	C343.54,490.13,343.66,490.43,343.72,490.83z"
        />
        <path
            fill={textColor}
            d="M421.54,427.34c-0.24-0.15-0.49-0.44-0.72-0.43c-0.9,0.02-1.79,0.14-2.68,0.22c0.01,0.1,0.01,0.2,0.02,0.3
	c1.09,0.03,2.19,0.05,3.29,0.08C421.48,427.45,421.51,427.39,421.54,427.34z"
        />
        <path
            fill={textColor}
            d="M419.41,426.74c0.15-0.01,0.27-0.36,0.41-0.56c0,0.07,0,0.14,0,0.22c0.84,0.13,1.68,0.26,2.51,0.38
	c0.23,0.78,0.03,1.2-0.34,1.45c-0.46,0.31-0.5,0.75-0.15,1.41c0.06,0.11,0.09,0.28,0.16,0.51c-0.11,0.2-0.33,0.38-0.33,0.6
	c-0.01,1.3-0.49,2.17-0.94,2.87c-0.27,0.41-0.59,0.72-0.94,0.91c1.09,0.1,2.19,0.2,3.28,0.28c0.54,0.04,1.09,0.06,1.63,0.08
	c-0.14,0.72-0.14,0.59-2.13,1.03c-0.63,0.14-1.17-0.15-1.79-0.31c0.2,0.13,0.39,0.34,0.41,0.51c0.08,0.77,0.49,1.64,0.02,2.35
	c0.11,0.11,0.23,0.23,0.29,0.29c-0.43,0.22-0.87,0.43-1.3,0.65c0.4,0.14,0.81,0.41,1.21,0.55c0.16-0.57,2-0.81,2.88-0.45
	c-0.46,0.5-0.89,0.95-1.32,1.41c0.01,0.09,0.02,0.19,0.02,0.28c0.69-0.07,1.38-0.14,2.14-0.22c-0.25-0.2-0.48-0.38-0.72-0.57
	c0.1-0.22,0.17-0.39,0.22-0.38c0.48,0.12,0.96,0.31,1.44,0.48c-0.01,0.09-0.02,0.18-0.03,0.26c-0.2,0.17-0.39,0.33-0.59,0.5
	c0.5,0.2,0.97,0.39,1.39-0.48c0.22-0.47,1.85-0.55,2.11-0.17c0.06,0.09,0.05,0.31,0.05,0.32c0.43,0.02,0.83,0.03,1.22,0.05
	c0,0.06-0.01,0.12-0.01,0.18c0.3-0.26,0.59-0.53,0.78-0.7c-0.56-0.23-1.2-0.49-1.84-0.76c0.01-0.13,0.03-0.26,0.04-0.39
	c0.56,0.29,1.1-0.72,1.69-0.05c0.13,0.14,0.42-0.16,0.64-0.21c0.36-0.08,0.76-0.53,1.03,0.26c0.03,0.09,0.27-0.02,0.41-0.05
	c0.81-0.19,1.61-0.39,2.51-0.62c-0.73,1.64-1.52,1.78-2.42,1.3c-0.3-0.16-0.65-0.04-1.04-0.06c0.07,0.2,0.09,0.3,0.1,0.3
	c0.94-0.3,1.58,1.47,2.57,0.76c0.05-0.71,0.51-1,1-0.49c-0.12,0.16-0.22,0.3-0.36,0.48c0.08,0.04,0.12,0.1,0.17,0.09
	c0.72-0.09,1.44-0.21,2.16-0.3c0.24-0.03,0.48-0.05,0.72-0.02c0.57,0.06,1.15,0.31,1.71,0.19c1.54-0.32,3.05,0.15,4.58-0.1
	c0.12-0.02,0.23-0.11,0.35-0.17c0.24-0.13,0.48-0.27,0.71-0.41c0.02,0.09,0.05,0.19,0.07,0.28c-0.07,0.09-0.15,0.19-0.3,0.37
	c1.07-0.09,2.05-0.19,3.04-0.25c0.39-0.03,0.78,0.06,1.17,0.06c0.79-0.01,1.58-0.07,2.36-0.08c0.72-0.01,1.45,0.01,2.17,0.04
	c0.79,0.03,1.59,0.1,2.38,0.14c0.19,0.01,0.39,0.08,0.55-0.03c0.91-0.62,1.83-0.06,2.74-0.08c0.04,0,0.08,0.03,0.11,0.02
	c1.05-0.5,2.11,0.19,3.17-0.03c0.49-0.1,1.02,0.22,1.49,0.03c0.98-0.4,1.93,0.24,2.89,0.04c0.63-0.13,1.27-0.01,1.91-0.06
	c0.76-0.06,1.52-0.18,2.29-0.28c0.05-0.01,0.11-0.02,0.16-0.01c1.14,0.12,2.29,0.23,3.44,0.36c2.17,0.23,4.35,0.55,6.53,0.64
	c1.52,0.06,3.03-0.2,4.55-0.28c1.07-0.06,2.15-0.09,3.23-0.06c1.54,0.04,2.94-0.71,4.35-1.45c0.56-0.3,1.13-0.56,1.69-0.84
	c-0.06-0.06-0.17-0.17-0.29-0.29c0.47-0.71,0.06-1.59-0.02-2.35c-0.03-0.26-0.47-0.62-0.72-0.65c-0.9-0.09-1.81-0.1-2.71-0.08
	c-1.67,0.04-3.35,0.11-5.02,0.15c-0.18,0-0.36-0.03-0.55-0.05c0.17-1.15,0.21-1.11,0.79-0.82c0.34,0.17,0.72,0.01,1.09,0.02
	c1.51,0.02,3.03-0.02,4.54,0.08c0.83,0.05,1.57-0.34,2.11-1.17c0.46-0.7,0.94-1.57,0.94-2.87c0-0.22,0.22-0.4,0.33-0.6
	c-0.07-0.23-0.1-0.4-0.16-0.51c-0.35-0.66-0.31-1.1,0.15-1.41c0.37-0.25,0.58-0.68,0.34-1.45c-0.83-0.12-1.67-0.25-2.51-0.38
	c0-0.07,0-0.14,0-0.22c-0.13,0.19-0.26,0.55-0.41,0.56c-1.13,0.04-2.26,0.01-3.41,0c0-0.12,0.01-0.24,0.01-0.36
	c0.06-0.04,0.12-0.08,0.18-0.13c0.19-0.15,0.49,0.04,0.73,0.15c0.36,0.15,0.74,0.54,0.98-0.28c0.03-0.1,0.23,0,0.33-0.05
	c0.31-0.14,0.61-0.29,0.91-0.49c0.5-0.35,0.96-0.81,1.47-1.1c0.31-0.17,0.67-0.09,1.07-0.13c-0.21-0.5-0.36-0.86-0.56-1.35
	c-1.88-0.49-3.76-0.38-5.66-0.17c-0.05-0.08-0.11-0.13-0.15-0.12c-0.37,0.14-0.36,0.16-0.52-0.24c-0.2,0.16-0.39,0.36-0.58,0.51
	c-0.42,0.05-0.83,0.1-1.25,0.14c-0.09-0.28-0.18-0.6-0.31-1c0.01-0.03,0.05-0.22,0.15-0.67c-0.53,0.4-0.94,0.7-1.49,1.11
	c0.02,0.05,0.05,0.1,0.07,0.14c-0.08-0.04-0.19-0.05-0.22,0c-0.31,0.69-0.73,0.32-1.12,0.31c-0.41-0.01-0.82,0.05-1.26-0.1
	c0.08-0.06,0.15-0.12,0.23-0.18c-0.01-0.05-0.01-0.1-0.02-0.15c-0.44,0.14-0.88,0.35-1.32,0.41c-0.24,0.03-0.49-0.2-0.73-0.28
	c-0.26-0.08-0.52-0.14-0.77-0.14c-0.61,0.02-1.23,0.28-1.82,0.09c-1.46-0.48-2.9-0.26-4.35-0.3c-0.66-0.02-1.31-0.17-1.97-0.16
	c-0.72,0.01-1.43,0.14-2.15,0.2c-0.55,0.05-1.17,0.36-1.64,0.04c-0.42-0.29-0.82-0.43-1.23-0.41c-0.42,0.02-0.83,0.21-1.22,0.31
	c-0.13-0.13-0.28-0.39-0.42-0.38c-0.71,0.06-1.42,0.21-2.13,0.3c-0.26,0.03-0.54-0.06-0.8-0.01c-0.63,0.13-1.26,0.38-1.89,0.45
	c-0.54,0.06-1.08-0.04-1.62-0.12c-0.81-0.12-1.62-0.31-2.43-0.41c-0.19-0.02-0.4,0.24-0.6,0.35c-0.17,0.09-0.34,0.15-0.54,0.01
	c0.12-0.11,0.23-0.23,0.5-0.48c-0.36,0-0.61-0.12-0.78,0.03c-0.47,0.45-0.92,0.41-1.38,0.04c-0.12,0.8-0.28,0.82-1.09,0.06
	c-0.02,0.17-0.03,0.32-0.05,0.55c-0.22-0.13-0.43-0.25-0.66-0.4c-0.1,0.08-0.25,0.32-0.4,0.3c-0.83-0.09-1.65-0.28-2.48-0.36
	c-0.29-0.03-0.6,0.19-0.89,0.29c-0.38,0.13-0.71,0.19-1.11-0.16c-0.3-0.27-0.87-0.06-1.21,0.29c-0.53,0.54-1.02,0.71-1.59,0.46
	c-0.43-0.19-0.87-0.29-1.3-0.43c-0.02,0.07-0.03,0.15-0.05,0.22c0.08,0.08,0.15,0.15,0.23,0.23c-0.11,0.12-0.2,0.35-0.29,0.67
	c0.2-0.09,0.4-0.17,0.62-0.27c-0.06,0.21-0.15,0.51-0.22,0.75c0.48-0.07,0.95-0.26,1.38-0.26c0.32,0.39,0.6,0.69,0.85,0.11
	c0.42-0.04,0.83-0.26,1.25-0.09c-0.11-0.07-0.22-0.14-0.32-0.21c0-0.07,0-0.13,0.01-0.2c0.36-0.09,0.73-0.18,1.09-0.27
	c0.01,0.07,0.02,0.15,0.03,0.22c-0.1,0.13-0.2,0.26-0.34,0.46c0.98-0.18,1.94,0.94,2.71-0.44c0.15,0.1,0.29,0.2,0.43,0.29
	c-0.26,0.35-0.79,0.38-1.18,0.42c-0.84,0.08-1.68,0.02-2.47,0.02c-0.11,0.49-0.18,0.78-0.24,1.07c-0.12-0.14-0.22-0.35-0.35-0.4
	c-0.22-0.08-0.45-0.09-0.68-0.09c-0.59,0-1.19,0.08-1.77,0.01c-0.26-0.03-0.5-0.34-0.8-0.37c0.08,0.09,0.16,0.17,0.25,0.26
	c-0.01,0.08-0.03,0.16-0.04,0.24c-0.23-0.1-0.46-0.24-0.7-0.3c-0.31-0.09-0.63-0.21-0.93-0.15c-0.44,0.09-0.88,0.21-1.32,0.35
	c0.13-0.13,0.31-0.35,0.48-0.51c0.34,0.37,0.89,0.14,1.54-0.91c-0.66,0.1-1.26,0.21-1.86,0.28c-0.25,0.03-0.5,0.02-0.75-0.02
	c-0.56-0.08-1.16-0.52-1.65-0.23c-1.07,0.64-2.09-0.09-3.14,0.31c-0.17,0.06-0.35-0.05-0.52-0.08c-0.49-0.1-0.99-0.21-1.5-0.32
	c-0.07,0.9,0.19,0.9,0.42,0.95c-0.07,0.02-0.14,0.11-0.21,0.31c-0.04,0.1-0.17,0.15-0.25,0.15c-0.59,0-1.19-0.07-1.78-0.01
	c-0.37,0.03-0.73,0.23-1.1,0.38c-0.44,0.17-0.88,0.36-1.31,0.53c0.01-0.1,0.02-0.21,0.03-0.31c-0.13-0.16-0.26-0.47-0.37-0.44
	c-0.64,0.14-1.32-0.47-1.94,0.48c-0.05,0.07-0.18,0.02-0.26-0.02c-0.46-0.19-0.92-0.4-1.38-0.61c-0.03,0.1-0.05,0.2-0.08,0.3
	c0.07,0.1,0.15,0.21,0.27,0.38c-0.45,0.25-0.85,0.6-1.27,0.68c-0.39,0.08-0.78-0.13-1.28-0.24c-0.2-0.64-0.2-0.64-0.48-0.65
	c0,0.16,0,0.33,0,0.48c-0.87-0.07-1.67-0.4-2.64-0.15c0-0.03-0.04-0.14-0.1-0.18c-0.01,0.01-0.02,0.02-0.03,0.02
	c-0.29,0.2-0.6,0.35-0.91,0.49c-0.11,0.05-0.3-0.05-0.33,0.05c-0.24,0.8-0.61,0.44-0.97,0.28c-0.09,0.12-0.18,0.22-0.28,0.25
	c-0.14,0.04-0.29,0.06-0.43,0.09C417.3,426.76,418.36,426.78,419.41,426.74z M424.65,439.85c0-0.01,0.01-0.02,0.01-0.03
	c0,0.01,0.01,0.01,0.01,0.02C424.67,439.85,424.66,439.85,424.65,439.85z M495.08,426.91c0.23-0.01,0.48,0.28,0.72,0.43
	c-0.03,0.05-0.06,0.1-0.09,0.16c-1.1-0.02-2.19-0.05-3.29-0.08c-0.01-0.1-0.01-0.2-0.02-0.3
	C493.29,427.05,494.18,426.93,495.08,426.91z M485.86,423.16c-0.04-0.05-0.07-0.11-0.1-0.2C485.8,423.03,485.83,423.1,485.86,423.16
	z M458.17,425.27c-0.32,0.03-0.65,0.08-0.98,0.07c-0.04,0-0.08-0.31-0.13-0.58c0.41,0.1,0.74,0.17,1.06,0.25
	C458.14,425.1,458.15,425.18,458.17,425.27z M457.84,424.04c-0.3,0.18-0.58,0.26-0.87-0.01
	C457.26,424.03,457.55,424.03,457.84,424.04z M454.82,424.13c0.22-0.01,0.43-0.02,0.65-0.03c-0.49,0.14-0.99,0.28-1.48,0.38
	c0-0.04,0-0.07,0-0.11C454.27,424.28,454.55,424.14,454.82,424.13z M452.92,424.44c-0.19-0.06-0.39-0.13-0.59-0.19
	C452.53,424.17,452.74,424.09,452.92,424.44z M443.51,439.29c0.15,0.06,0.37,0.16,0.47,0.03c0.38-0.45,0.67-0.18,0.98,0.16
	c-0.55,0.97-1.22,0.83-2.57-0.63C442.77,438.94,443.14,439.14,443.51,439.29z M434.59,433.55c1.01-0.39,1.28-0.29,1.41,0.59
	C435.54,433.95,435.12,433.77,434.59,433.55z M436.93,439.57c-0.19,0.14-0.61-0.52-0.6,0.49c-0.27-0.13-0.56-0.26-0.85-0.4
	c0.01-0.07,0.01-0.15,0.02-0.22c0.73-0.19,1.46-0.37,2.19-0.56c0.01,0.1,0.01,0.2,0.02,0.3
	C437.45,439.3,437.17,439.38,436.93,439.57z M438.44,434.52c-0.33,0.08-0.66,0.15-1.05,0.24c0.05-0.35,0.08-0.53,0.1-0.67
	c-0.36-0.06-0.69-0.12-1.01-0.17c0.02-0.15,0.05-0.29,0.07-0.44c0.63,0.29,1.27,0.59,1.9,0.88
	C438.45,434.41,438.44,434.47,438.44,434.52z M434.15,424.68c0.13-0.09,0.31-0.33,0.43-0.27c0.48,0.24,0.93,0.24,1.45,0.05
	c0.69-0.24,1.43-0.39,2.07,0.33c0.08,0.09,0.21,0.08,0.31,0.09c0.56,0.04,1.15-0.52,1.68,0.2c-0.2,0.06-0.39,0.12-0.59,0.18
	c-0.21,0.06-0.42,0.18-0.63,0.27c0,0.08,0.01,0.15,0.01,0.23c0.21,0,0.43,0.01,0.64,0.01c0,0.12,0,0.24,0,0.36
	c-0.64,0.08-1.29,0.17-1.93,0.25c-0.01-0.09-0.02-0.18-0.03-0.26c0.07-0.1,0.14-0.2,0.28-0.41c-0.42-0.18-0.79-0.33-1.24-0.52
	c0.15-0.16,0.25-0.26,0.37-0.39c-0.64-0.23-1.41,0.54-1.48,1.74c-0.26-0.31-0.44-0.52-0.61-0.75c-0.12-0.16-0.23-0.37-0.37-0.48
	c-0.26-0.22-0.54-0.4-0.82-0.6c-0.08-0.06-0.15-0.15-0.23-0.2C433.71,424.59,433.93,424.63,434.15,424.68z M428.26,432.39
	c0.11,0.19,0.19,0.45,0.3,0.48c0.17,0.04,0.36-0.07,0.55-0.12c-0.07-0.19-0.13-0.38-0.2-0.56c0.59,0.64,1.23,0.3,1.87,0.15
	c0.56-0.14,1.13-0.2,1.7-0.21c0.2-0.01,0.4,0.21,0.6,0.33c-0.01,0.1-0.02,0.19-0.03,0.29c-0.26,0.04-0.53,0.01-0.77,0.14
	c-0.89,0.44-1.76,0.44-2.61-0.06c-0.07-0.04-0.19-0.01-0.25,0.07c-0.48,0.63-0.9,0.39-1.33-0.12
	C428.15,432.63,428.21,432.51,428.26,432.39z M427.57,434.9c-0.65,0.23-1.31,1.08-1.97,0.07
	C426.26,434.94,426.92,434.92,427.57,434.9z M423.66,432.5c0.42-0.04,0.84-0.09,1.26-0.13c-0.01,0.14-0.01,0.29-0.02,0.43
	c-0.41,0.02-0.83,0.04-1.24,0.06C423.65,432.73,423.66,432.61,423.66,432.5z"
        />
        <path
            fill={textColor}
            d="M421.54,506.46c-0.24-0.15-0.49-0.44-0.72-0.43c-0.9,0.02-1.79,0.14-2.68,0.22c0.01,0.1,0.01,0.2,0.02,0.3
	c1.09,0.03,2.19,0.05,3.29,0.08C421.48,506.57,421.51,506.52,421.54,506.46z"
        />
        <path
            fill={textColor}
            d="M481.91,520.53c1.52,0.06,3.03-0.2,4.55-0.28c1.07-0.06,2.15-0.09,3.23-0.06c1.39,0.04,2.67-0.58,3.95-1.25h1.69v-0.86
	c0.13-0.07,0.27-0.13,0.4-0.2c-0.06-0.06-0.17-0.17-0.29-0.29c0.47-0.71,0.06-1.59-0.02-2.35c-0.01-0.07-0.04-0.14-0.09-0.22v-2.91
	c0.32-0.59,0.58-1.31,0.58-2.26c0-0.22,0.22-0.4,0.33-0.6c-0.07-0.23-0.1-0.4-0.16-0.51c-0.35-0.66-0.31-1.1,0.15-1.41
	c0.37-0.25,0.58-0.68,0.34-1.45c-0.41-0.06-0.83-0.12-1.24-0.19v-2.22c0.19-0.01,0.39,0,0.6-0.02c-0.21-0.5-0.36-0.86-0.56-1.35
	c-1.88-0.49-3.76-0.38-5.66-0.17c-0.05-0.08-0.11-0.13-0.15-0.12c-0.37,0.14-0.36,0.16-0.52-0.24c-0.2,0.16-0.39,0.36-0.58,0.51
	c-0.42,0.05-0.83,0.1-1.25,0.14c-0.09-0.28-0.18-0.6-0.31-1c0.01-0.03,0.05-0.22,0.15-0.67c-0.53,0.4-0.94,0.7-1.49,1.11
	c0.02,0.05,0.05,0.1,0.07,0.14c-0.08-0.04-0.19-0.05-0.22,0c-0.31,0.69-0.73,0.32-1.12,0.31c-0.41-0.01-0.82,0.05-1.26-0.1
	c0.08-0.06,0.15-0.12,0.23-0.18c-0.01-0.05-0.01-0.1-0.02-0.15c-0.44,0.14-0.88,0.35-1.32,0.41c-0.24,0.03-0.49-0.2-0.73-0.28
	c-0.26-0.08-0.52-0.14-0.77-0.14c-0.61,0.02-1.23,0.28-1.82,0.09c-1.46-0.48-2.9-0.26-4.35-0.3c-0.66-0.02-1.31-0.17-1.97-0.16
	c-0.72,0.01-1.43,0.14-2.15,0.2c-0.55,0.05-1.17,0.36-1.64,0.04c-0.42-0.29-0.82-0.43-1.23-0.41c-0.42,0.02-0.83,0.21-1.22,0.31
	c-0.13-0.13-0.28-0.39-0.42-0.38c-0.71,0.06-1.42,0.21-2.13,0.3c-0.26,0.03-0.54-0.06-0.8-0.01c-0.63,0.13-1.26,0.38-1.89,0.45
	c-0.54,0.06-1.08-0.04-1.62-0.12c-0.81-0.12-1.62-0.31-2.43-0.41c-0.19-0.02-0.4,0.24-0.6,0.35c-0.17,0.09-0.34,0.15-0.54,0.01
	c0.12-0.11,0.23-0.23,0.5-0.48c-0.36,0-0.61-0.12-0.78,0.03c-0.47,0.45-0.92,0.41-1.38,0.04c-0.12,0.8-0.28,0.82-1.09,0.06
	c-0.02,0.17-0.03,0.32-0.05,0.55c-0.22-0.13-0.43-0.25-0.66-0.4c-0.1,0.08-0.25,0.32-0.4,0.3c-0.83-0.09-1.65-0.28-2.48-0.36
	c-0.29-0.03-0.6,0.19-0.89,0.29c-0.38,0.13-0.71,0.19-1.11-0.16c-0.3-0.27-0.87-0.06-1.21,0.29c-0.53,0.54-1.02,0.71-1.59,0.46
	c-0.43-0.19-0.87-0.29-1.3-0.43c-0.02,0.07-0.03,0.15-0.05,0.22c0.08,0.08,0.15,0.15,0.23,0.23c-0.11,0.12-0.2,0.35-0.29,0.67
	c0.2-0.09,0.4-0.17,0.62-0.27c-0.06,0.21-0.15,0.51-0.22,0.75c0.48-0.07,0.95-0.26,1.38-0.26c0.32,0.39,0.6,0.69,0.85,0.11
	c0.42-0.04,0.83-0.26,1.25-0.09c-0.11-0.07-0.22-0.14-0.32-0.21c0-0.07,0-0.13,0.01-0.2c0.36-0.09,0.73-0.18,1.09-0.27
	c0.01,0.07,0.02,0.15,0.03,0.22c-0.1,0.13-0.2,0.26-0.34,0.46c0.98-0.18,1.94,0.94,2.71-0.44c0.15,0.1,0.29,0.2,0.43,0.29
	c-0.26,0.35-0.79,0.38-1.18,0.42c-0.84,0.08-1.68,0.02-2.47,0.02c-0.03,0.11-0.05,0.21-0.07,0.3h-3.53
	c-0.08-0.04-0.16-0.08-0.25-0.09c0.03,0.03,0.05,0.06,0.08,0.09h-0.97c-0.18-0.05-0.36-0.08-0.54-0.04
	c-0.06,0.01-0.11,0.03-0.17,0.04h-0.87c0.06-0.07,0.13-0.14,0.19-0.2c0.34,0.37,0.89,0.14,1.54-0.91c-0.66,0.1-1.26,0.21-1.86,0.28
	c-0.25,0.03-0.5,0.02-0.75-0.02c-0.56-0.08-1.16-0.52-1.65-0.23c-1.07,0.64-2.09-0.09-3.14,0.31c-0.17,0.06-0.35-0.05-0.52-0.08
	c-0.49-0.1-0.99-0.21-1.5-0.32c-0.07,0.9,0.19,0.9,0.42,0.95c-0.06,0.02-0.12,0.09-0.18,0.23h-9.77v1.59
	c-0.3-0.03-0.61-0.17-0.99-0.25c-0.2-0.64-0.2-0.64-0.48-0.65c0,0.16,0,0.33,0,0.48c-0.87-0.07-1.67-0.4-2.64-0.15
	c0-0.03-0.04-0.14-0.1-0.18c-0.01,0.01-0.02,0.02-0.03,0.02c-0.29,0.2-0.6,0.35-0.91,0.49c-0.11,0.05-0.3-0.05-0.33,0.05
	c-0.24,0.8-0.61,0.44-0.97,0.28c-0.09,0.12-0.18,0.22-0.28,0.25c-0.14,0.04-0.29,0.06-0.43,0.09c1.07,0.01,2.13,0.04,3.18,0
	c0.15-0.01,0.27-0.36,0.41-0.56c0,0.07,0,0.14,0,0.22c0.84,0.13,1.68,0.26,2.51,0.38c0.23,0.78,0.03,1.2-0.34,1.45
	c-0.46,0.31-0.5,0.75-0.15,1.41c0.06,0.11,0.09,0.28,0.16,0.51c-0.11,0.2-0.33,0.38-0.33,0.6c-0.01,1.3-0.49,2.17-0.94,2.87
	c-0.27,0.41-0.59,0.72-0.94,0.91c1.09,0.1,2.19,0.2,3.28,0.28c0.11,0.01,0.22,0.01,0.33,0.02v0.92c-0.23,0.05-0.5,0.11-0.83,0.18
	c-0.63,0.14-1.17-0.15-1.79-0.31c0.2,0.13,0.39,0.34,0.41,0.51c0.08,0.77,0.49,1.64,0.02,2.35c0.11,0.11,0.23,0.23,0.29,0.29
	c-0.43,0.22-0.87,0.43-1.3,0.65c0.4,0.14,0.81,0.41,1.21,0.55c0.12-0.42,1.12-0.65,1.99-0.61v0.98h0.13c-0.19,0.2-0.37,0.4-0.56,0.6
	c0.01,0.09,0.02,0.19,0.02,0.28c0.69-0.07,1.38-0.14,2.14-0.22c-0.25-0.2-0.48-0.38-0.72-0.57c0.02-0.03,0.03-0.06,0.04-0.09h1.06
	c0.18,0.07,0.37,0.13,0.55,0.2c-0.01,0.09-0.02,0.18-0.03,0.26c-0.2,0.17-0.39,0.33-0.59,0.5c0.5,0.2,0.97,0.39,1.39-0.48
	c0.22-0.47,1.85-0.55,2.11-0.17c0.06,0.09,0.05,0.31,0.05,0.32c0.43,0.02,0.83,0.03,1.22,0.05c0,0.06-0.01,0.12-0.01,0.18
	c0.3-0.26,0.59-0.53,0.78-0.7c-0.13-0.05-0.27-0.11-0.4-0.16h2.21c0.62,0.34,1.16,1.18,1.89,0.66c0.02-0.32,0.13-0.55,0.29-0.66
	h0.49c0.07,0.04,0.15,0.1,0.22,0.18c-0.12,0.16-0.22,0.3-0.36,0.48c0.08,0.04,0.12,0.1,0.17,0.09c0.72-0.09,1.44-0.21,2.16-0.3
	c0.24-0.03,0.48-0.05,0.72-0.02c0.57,0.06,1.15,0.31,1.71,0.19c1.54-0.32,3.05,0.15,4.58-0.1c0.12-0.02,0.23-0.11,0.35-0.17
	c0.2-0.11,0.41-0.23,0.61-0.35h0.12c0.02,0.07,0.04,0.15,0.05,0.22c-0.07,0.09-0.15,0.19-0.3,0.37c1.07-0.09,2.05-0.19,3.04-0.25
	c0.39-0.03,0.78,0.06,1.17,0.06c0.79-0.01,1.58-0.07,2.36-0.08c0.72-0.01,1.45,0.01,2.17,0.04c0.79,0.03,1.59,0.1,2.38,0.14
	c0.19,0.01,0.39,0.08,0.55-0.03c0.91-0.62,1.83-0.06,2.74-0.08c0.04,0,0.08,0.03,0.11,0.02c1.05-0.5,2.11,0.19,3.17-0.03
	c0.49-0.1,1.02,0.22,1.49,0.03c0.98-0.4,1.93,0.24,2.89,0.04c0.63-0.13,1.27-0.01,1.91-0.06c0.76-0.06,1.52-0.18,2.29-0.28
	c0.05-0.01,0.11-0.02,0.16-0.01c1.14,0.12,2.29,0.23,3.44,0.36C477.56,520.13,479.74,520.44,481.91,520.53z M495.34,506.13
	c0.15,0.1,0.31,0.24,0.46,0.34c-0.03,0.05-0.06,0.1-0.09,0.16c-0.12,0-0.25-0.01-0.37-0.01V506.13z M485.86,502.28
	c-0.04-0.05-0.07-0.11-0.1-0.2C485.8,502.15,485.83,502.22,485.86,502.28z M433.71,503.84c-0.07-0.06-0.15-0.14-0.22-0.19
	c0.22,0.06,0.44,0.11,0.66,0.16c0.13-0.09,0.31-0.33,0.43-0.27c0.48,0.24,0.93,0.24,1.45,0.05c0.66-0.23,1.37-0.36,1.99,0.25H433.71
	z M452.33,503.37c0.2-0.08,0.41-0.16,0.59,0.19C452.73,503.5,452.53,503.43,452.33,503.37z M453.99,503.59c0-0.04,0-0.07,0-0.11
	c0.28-0.08,0.56-0.22,0.84-0.23c0.22-0.01,0.43-0.02,0.65-0.03C454.98,503.36,454.48,503.5,453.99,503.59z M456.97,503.16
	c0.29,0,0.58,0,0.87,0.01C457.53,503.35,457.26,503.42,456.97,503.16z"
        />
        <path
            fill={textColor}
            d="M493.74,482.33c0.44,0.25,0.62,0.51,0.68,0.79c0.01,0,0.02,0,0.04,0c0-0.01,0-0.03,0-0.04c-0.04-0.39-0.07-0.93-0.42-1.18
	C493.93,482.04,493.84,482.18,493.74,482.33z"
        />
        <path
            fill={textColor}
            d="M434.23,464.26c0.07,0.63-0.46,1.38-0.9,1.28c-0.23-0.05-0.45-0.24-0.68-0.29c-0.21-0.04-0.42,0.01-0.6,0.02
	c0.44,0.31,0.86,0.79,1.3,0.87c0.44,0.08,0.89-0.26,1.44-0.45c-0.22-0.25-0.35-0.41-0.52-0.61c0.2-0.23,0.37-0.43,0.65-0.75
	C434.61,464.3,434.43,464.28,434.23,464.26z"
        />
        <path
            fill={textColor}
            d="M419.25,482.46c-0.04-0.02-0.06-0.05-0.11-0.07c-0.07,0.06-0.14,0.13-0.2,0.19C419.05,482.54,419.15,482.5,419.25,482.46z"
        />
        <path
            fill={textColor}
            d="M424.31,466.65c-0.01,0.14-0.03,0.27-0.04,0.41c0.19,0.1,0.38,0.29,0.56,0.27c0.49-0.05,0.98-0.2,1.44-0.3
	c-0.03-0.01-0.09-0.04-0.22-0.1c0.13-0.16,0.23-0.28,0.32-0.39C425.67,466.57,424.99,466.61,424.31,466.65z"
        />
        <path
            fill={textColor}
            d="M418.91,484.09c-0.02-0.01-0.03-0.01-0.05-0.02c0,0.01-0.01,0.02-0.01,0.03C418.87,484.09,418.89,484.09,418.91,484.09z"
        />
        <path
            fill={textColor}
            d="M424.43,484.77c-0.01-0.32-0.03-0.66-0.04-0.91c-0.48,0.03-0.98,0.06-1.48,0.08C423.46,484.12,423.8,485.79,424.43,484.77z"
        />
        <path
            fill={textColor}
            d="M494.27,480.07c0.02-0.01,0.04-0.01,0.06-0.02c0.05-0.17,0.11-0.34,0.17-0.51C494.18,479.71,494.21,479.89,494.27,480.07z"
        />
        <path
            fill={textColor}
            d="M494.98,474.68c-0.05,0.22-0.1,0.46-0.17,0.73c0.27-0.06,0.58-0.09,0.58-0.13c0.01-0.2-0.01-0.39-0.03-0.59
	c-0.11,0-0.23,0-0.34-0.01C495.01,474.68,495,474.68,494.98,474.68z"
        />
        <path
            fill={textColor}
            d="M494.12,474.68c-0.01,0-0.02,0-0.02,0c-0.01,0.27-0.01,0.55-0.01,0.82C494.34,475.23,494.28,474.97,494.12,474.68z"
        />
        <path
            fill={textColor}
            d="M494.15,477c0.01,0.22,0.02,0.43,0.03,0.65c0.01,0.28,0.15,0.56,0.23,0.84c0.04,0,0.07,0,0.11,0
	C494.43,477.99,494.29,477.49,494.15,477z"
        />
        <path
            fill={textColor}
            d="M482.86,482.23c0.65,0.1,1.3,0.19,1.96,0.29c0-0.11,0.01-0.23,0.01-0.34c-0.12-0.03-0.25-0.11-0.37-0.09
	c-0.45,0.06-0.84,0-1.02-0.93c-0.02-0.1-0.22-0.11-0.34-0.16c-0.01,0.07-0.02,0.15-0.04,0.22c0.1,0.14,0.19,0.29,0.33,0.5
	c-0.22,0.08-0.38,0.15-0.54,0.21C482.85,482.03,482.85,482.13,482.86,482.23z"
        />
        <path
            fill={textColor}
            d="M492.19,478.51c0.37-0.46,0.4-0.91-0.04-1.38c-0.16-0.17-0.03-0.41-0.03-0.78c0.26,0.27,0.37,0.38,0.48,0.5
	c0.13-0.2,0.08-0.37-0.01-0.54c-0.11-0.2-0.38-0.41-0.35-0.6c0.04-0.34,0.1-0.68,0.16-1.02c-0.75,0.01-1.49,0.02-2.24,0.02
	c-0.17,0-0.35-0.04-0.53-0.06c0.19-1.14,0.22-1.11,0.78-0.81c0.33,0.18,0.7,0.03,1.06,0.04c0.36,0.01,0.71,0.02,1.07,0.03
	c0.04-0.21,0.08-0.43,0.11-0.64c0.08-0.54,0.18-1.08,0.12-1.62c-0.07-0.63-0.32-1.26-0.45-1.89c-0.05-0.26,0.04-0.53,0.01-0.8
	c-0.09-0.71-0.23-1.42-0.3-2.13c-0.01-0.14,0.26-0.29,0.38-0.42c-0.11-0.39-0.3-0.8-0.31-1.22c-0.02-0.41,0.12-0.81,0.41-1.23
	c0.33-0.48,0.01-1.09-0.04-1.64c-0.02-0.2-0.04-0.4-0.07-0.6c-0.18,0.15-0.35,0.32-0.53,0.44c-0.41,0.04-0.83,0.08-1.25,0.12
	c-0.08-0.28-0.17-0.6-0.29-1.01c0.01-0.03,0.05-0.22,0.16-0.66c-0.54,0.39-0.95,0.68-1.51,1.09c0.02,0.05,0.04,0.1,0.07,0.14
	c-0.08-0.04-0.19-0.06-0.22-0.01c-0.32,0.68-0.73,0.31-1.12,0.29c-0.41-0.02-0.81,0.04-1.25-0.12c0.08-0.06,0.15-0.12,0.23-0.18
	c-0.01-0.05-0.01-0.1-0.02-0.15c-0.44,0.14-0.88,0.34-1.32,0.39c-0.23,0.03-0.48-0.21-0.73-0.28c-0.25-0.08-0.51-0.15-0.77-0.14
	c-0.6,0.01-1.22,0.27-1.81,0.08c-1.45-0.48-2.89-0.26-4.34-0.29c-0.65-0.01-1.31-0.16-1.97-0.15c-0.72,0.02-1.43,0.16-2.14,0.23
	c-0.55,0.06-1.16,0.38-1.64,0.06c-0.43-0.28-0.83-0.41-1.24-0.39c-0.41,0.02-0.82,0.22-1.21,0.34c-0.13-0.12-0.29-0.39-0.43-0.37
	c-0.71,0.08-1.41,0.25-2.12,0.35c-0.26,0.04-0.54-0.05-0.79,0.02c-0.62,0.15-1.24,0.42-1.87,0.51c-0.53,0.08-1.08,0-1.61-0.06
	c-0.81-0.09-1.62-0.25-2.44-0.31c-0.19-0.01-0.39,0.26-0.58,0.38c-0.16,0.1-0.33,0.16-0.54,0.04c0.11-0.12,0.22-0.24,0.48-0.51
	c-0.36,0.02-0.61-0.09-0.77,0.07c-0.45,0.47-0.9,0.45-1.37,0.11c-0.08,0.81-0.24,0.83-1.08,0.11c-0.01,0.17-0.01,0.32-0.02,0.56
	c-0.22-0.12-0.44-0.23-0.68-0.36c-0.09,0.08-0.23,0.33-0.38,0.32c-0.83-0.05-1.65-0.2-2.48-0.22c-0.29-0.01-0.58,0.23-0.87,0.35
	c-0.37,0.15-0.69,0.24-1.11-0.1c-0.32-0.25-0.87,0-1.18,0.36c-0.49,0.58-0.97,0.78-1.54,0.56c-0.43-0.16-0.88-0.23-1.32-0.34
	c-0.01,0.08-0.02,0.15-0.03,0.23c0.08,0.07,0.16,0.14,0.24,0.22c-0.1,0.13-0.17,0.36-0.24,0.69c0.19-0.1,0.39-0.2,0.6-0.31
	c-0.05,0.21-0.11,0.52-0.17,0.76c0.47-0.1,0.92-0.32,1.35-0.35c0.34,0.37,0.64,0.65,0.85,0.06c0.41-0.07,0.81-0.31,1.23-0.17
	c-0.11-0.06-0.22-0.13-0.33-0.19c0-0.07,0-0.13-0.01-0.2c0.36-0.11,0.71-0.22,1.07-0.33c0.02,0.07,0.03,0.15,0.05,0.22
	c-0.09,0.14-0.18,0.27-0.31,0.48c0.95-0.24,1.97,0.82,2.66-0.59c0.15,0.09,0.3,0.18,0.44,0.27c-0.23,0.36-0.76,0.42-1.15,0.48
	c-0.83,0.13-1.67,0.12-2.44,0.16c-0.08,0.5-0.13,0.79-0.17,1.08c-0.12-0.13-0.24-0.34-0.37-0.38c-0.22-0.07-0.45-0.06-0.68-0.04
	c-0.58,0.04-1.17,0.16-1.75,0.13c-0.26-0.01-0.52-0.31-0.82-0.32c0.09,0.08,0.17,0.16,0.26,0.24c-0.01,0.08-0.02,0.16-0.02,0.24
	c-0.24-0.09-0.47-0.2-0.71-0.25c-0.31-0.06-0.63-0.17-0.93-0.09c-0.43,0.12-0.85,0.28-1.28,0.44c0.12-0.14,0.28-0.37,0.43-0.54
	c0.36,0.35,0.89,0.08,1.46-1.02c-0.65,0.15-1.24,0.3-1.82,0.42c-0.25,0.05-0.5,0.05-0.74,0.04c-0.56-0.04-1.19-0.43-1.65-0.1
	c-1.01,0.73-2.08,0.09-3.07,0.56c-0.16,0.08-0.35-0.02-0.52-0.04c-0.5-0.06-0.99-0.12-1.51-0.18c0.01,0.9,0.27,0.88,0.49,0.91
	c-0.06,0.03-0.13,0.12-0.18,0.32c-0.03,0.1-0.15,0.17-0.23,0.18c-0.58,0.06-1.17,0.04-1.75,0.15c-0.36,0.07-0.7,0.3-1.05,0.48
	c-0.41,0.21-0.83,0.44-1.24,0.66c0-0.11,0-0.21,0-0.32c-0.14-0.14-0.3-0.44-0.41-0.4c-0.62,0.21-1.35-0.34-1.86,0.67
	c-0.04,0.08-0.17,0.04-0.26,0.01c-0.47-0.15-0.95-0.3-1.42-0.46c-0.01,0.1-0.03,0.2-0.04,0.3c0.08,0.1,0.17,0.19,0.31,0.35
	c-0.41,0.3-0.77,0.69-1.17,0.81c-0.37,0.12-0.77-0.05-1.28-0.1c-0.26-0.62-0.26-0.62-0.54-0.59c0.02,0.16,0.03,0.33,0.05,0.48
	c-0.85,0.02-1.68-0.21-2.6,0.15c-0.01-0.04-0.07-0.18-0.14-0.18c-0.48-0.04-0.96-0.07-1.43,0c-0.01,0-0.02,0.01-0.03,0.02
	c0.08-0.13,0.14-0.3,0.17-0.5c0.1-0.6,0.19-1.18,0.68-0.68c0.1,0.1,0.28-0.08,0.4-0.31c-0.71-0.26-1.38-0.13-2,0.49
	c-0.12,0.12-0.31,0.1-0.46,0.02c-0.17-0.09-0.33-0.19-0.5-0.28c0.02,0.14,0.06,0.28,0.12,0.43c0.2,0.11,0.4,0.23,0.62,0.35
	c-0.14,0.2-0.19,0.28-0.23,0.34c0.15,0.1,0.32,0.19,0.48,0.32c0.2,0.17,0.41,0.14,0.58-0.02c-0.07,0.13-0.13,0.31-0.21,0.42
	c-0.17,0.28-0.31,0.72-0.51,0.8c-0.28,0.11-0.56,0.19-0.85,0.25c0.05,0.16,0.11,0.31,0.21,0.47c0.11,0.16,0.04,0.37,0.03,0.55
	c-0.04,0.79-0.11,1.59-0.14,2.38c-0.02,0.51-0.03,1.02-0.04,1.53c0.08-0.01,0.17-0.02,0.25-0.03v0.31c-0.08,0-0.17,0.01-0.25,0.01
	c0,0.12,0,0.23,0,0.35c0,0.07,0.01,0.14,0.01,0.21c0.08-0.02,0.16-0.05,0.24-0.07v4.14c-0.06,0.01-0.11,0.03-0.17,0.04
	c0.06,0.73,0.13,1.47,0.2,2.25c-0.18-0.15-0.28-0.22-0.37-0.3c-0.09,0.02-0.19,0.05-0.28,0.07c0.14,0.24,0.28,0.47,0.41,0.71
	c0.06,0.11,0.15,0.23,0.17,0.35c0.01,0.05,0.01,0.1,0.02,0.15c0.01,0,0.01,0,0.02,0c0.35-0.02,0.69-0.32,1.01-0.28
	c0.21,0.05,0.44,0.19,0.64,0.13c0.33-0.1,0.64-0.42,0.96-0.47c0.7-0.11,1.39-0.07,2.09-0.46c0.55-0.3,1.25,0.21,1.89,0.36
	c0.06-0.4,0.88-0.73,1.68-0.81h0.72c0.11,0.01,0.21,0.03,0.3,0.06c-0.39,0.54-0.75,1.04-1.11,1.54c0.02,0.09,0.03,0.18,0.05,0.28
	c0.65-0.14,1.29-0.28,2.01-0.44c-0.26-0.17-0.5-0.33-0.74-0.49c0.07-0.23,0.13-0.4,0.17-0.4c0.47,0.07,0.95,0.21,1.42,0.33
	c0,0.09,0,0.18,0,0.27c-0.17,0.19-0.34,0.37-0.51,0.56c0.5,0.15,0.96,0.29,1.27-0.62c0.17-0.49,1.71-0.73,2-0.37
	c0.07,0.08,0.08,0.31,0.08,0.31c0.41-0.02,0.79-0.05,1.17-0.07c0,0.06,0,0.12,0,0.18c0.26-0.29,0.52-0.58,0.69-0.77
	c-0.36-0.12-0.77-0.25-1.18-0.38h2.23c0,0.01,0.01,0.01,0.01,0.01c0.01-0.01,0.02-0.01,0.04-0.01h0.49
	c0.66,0.22,1.28,1.17,1.98,0.55c0-0.23,0.04-0.42,0.12-0.55h0.79c-0.1,0.16-0.18,0.31-0.29,0.49c0.08,0.04,0.13,0.09,0.17,0.08
	c0.68-0.15,1.36-0.32,2.04-0.47c0.23-0.05,0.46-0.08,0.69-0.08c0.55,0.02,1.12,0.23,1.65,0.07c0.12-0.04,0.24-0.06,0.36-0.08h22.96
	c-0.24,0.03-0.48,0.07-0.72,0.1c0.01,0.08,0.01,0.16,0.02,0.24c0.98,0.18,1.97,0.36,2.92,0.53c-0.13-0.34-0.28-0.63-0.45-0.87h6
	c0.33,0.08,0.65,0.25,0.97,0.68c0.36,0.48,0.65,0.59,0.95,0.34c0.25-0.01,0.49-0.03,0.74-0.04c-0.01-0.13-0.01-0.26-0.02-0.39
	c-0.14,0.02-0.28,0.04-0.42,0.06c0.11-0.17,0.22-0.39,0.34-0.65h0.93c-0.08,0.01-0.15,0.02-0.23,0.03c0.57,0.24,1.16,0.49,1.75,0.74
	c-0.23-0.68-0.57-0.87-1.46-0.78v-1.36c1.82,0.21,3.64,0.47,5.46,0.56c1.46,0.07,2.91-0.16,4.37-0.22c0.96-0.04,1.91-0.04,2.87,0
	c0.13-0.22,0.25-0.42,0.37-0.63c-0.24-0.02-0.38-0.03-0.55-0.05C493,478.79,492.99,478.64,492.19,478.51z M430.24,481.65
	c0-0.01,0.01-0.02,0.01-0.03c0.01,0.01,0.01,0.01,0.02,0.02C430.26,481.65,430.25,481.65,430.24,481.65z M442.79,466.34
	c0.21-0.01,0.42-0.02,0.63-0.04c0.01,0.12,0.02,0.24,0.03,0.36c-0.63,0.13-1.25,0.27-1.88,0.4c-0.02-0.09-0.03-0.17-0.05-0.26
	c0.06-0.11,0.12-0.21,0.25-0.43c-0.43-0.14-0.81-0.27-1.26-0.42c0.14-0.17,0.23-0.28,0.33-0.42c-0.65-0.18-1.35,0.66-1.31,1.85
	c-0.28-0.29-0.47-0.49-0.66-0.69c-0.14-0.15-0.26-0.34-0.4-0.45c-0.28-0.2-0.57-0.35-0.85-0.52c-0.08-0.05-0.16-0.13-0.24-0.18
	c0.22,0.04,0.45,0.07,0.67,0.1c0.12-0.1,0.28-0.35,0.4-0.3c0.5,0.2,0.94,0.16,1.44-0.07c0.66-0.3,1.38-0.5,2.07,0.16
	c0.09,0.08,0.21,0.07,0.32,0.06c0.55,0,1.1-0.61,1.67,0.07c-0.19,0.07-0.38,0.15-0.57,0.22c-0.21,0.08-0.4,0.21-0.6,0.32
	C442.77,466.19,442.78,466.26,442.79,466.34z M455.96,463.95c0.19-0.09,0.4-0.18,0.6,0.16C456.37,464.07,456.17,464,455.96,463.95z
	 M457.62,464.1c0-0.04,0-0.07-0.01-0.11c0.27-0.09,0.54-0.25,0.82-0.27c0.21-0.02,0.43-0.04,0.64-0.06
	C458.59,463.82,458.11,463.98,457.62,464.1z M461.42,463.5c-0.29,0.2-0.56,0.28-0.86,0.03C460.84,463.51,461.13,463.5,461.42,463.5z
	 M460.83,464.82c-0.04,0-0.09-0.31-0.16-0.57c0.41,0.08,0.74,0.14,1.06,0.2c0.02,0.09,0.04,0.18,0.05,0.27
	C461.47,464.76,461.15,464.82,460.83,464.82z M489.17,462.1c0.03,0.07,0.06,0.13,0.09,0.2C489.23,462.25,489.2,462.18,489.17,462.1z
	"
        />
        <path
            fill={textColor}
            d="M486.63,482.61c1.36-0.52,2.71,0.51,4.07,0.45c0.55-0.02,1.11-0.01,1.66,0.01c0.05-0.52,0.14-1.03,0.23-1.54
	c-0.26,0.12-0.52,0.24-0.78,0.37c-0.29,0.15-0.58,0.44-0.87,0.46c-0.51,0.04-1.03-0.07-1.55-0.12c0-0.1,0-0.2,0-0.3
	c0.24-0.01,0.49-0.03,0.73-0.04c0-0.12,0-0.25,0-0.37c-1.61,0.98-3.17-0.4-4.76-0.52c0,0.08-0.01,0.16-0.01,0.25
	c0.71,0.25,1.41,0.5,2.12,0.75c0,0.05,0,0.1,0.01,0.15c-0.31,0.05-0.62,0.11-0.98,0.17C486.59,482.53,486.62,482.61,486.63,482.61z"
        />
        <path
            fill={textColor}
            d="M444.57,482.92c-0.26,0.45-0.44,0.75-0.64,1.12c0.09,0.17,0.2,0.4,0.39,0.79C444.41,484.15,444.48,483.62,444.57,482.92z"
        />
        <path
            fill={textColor}
            d="M424.85,483.75c0.53-0.14,1.06-0.29,1.56-0.42c0.01,0.33,0.02,0.57,0.03,0.8c0.36-1.65,1.31-0.82,1.83-1.7
	c-1.15,0.39-2.3,0.78-3.45,1.17C424.84,483.65,424.84,483.7,424.85,483.75z"
        />
        <path
            fill={textColor}
            d="M449.8,483.62c0.02-0.13,0.03-0.26,0.05-0.39c-0.36-0.17-0.73-0.33-1.09-0.5c-0.02,0.14-0.03,0.28-0.05,0.41
	C449.09,483.21,449.38,483.96,449.8,483.62z"
        />
        <path
            fill={textColor}
            d="M464.58,482.19c0.01,0.1,0.02,0.2,0.03,0.3c0.54-0.1,1.07-0.19,1.61-0.29c-0.01-0.14-0.03-0.29-0.04-0.43
	C465.66,482.08,465.02,481.27,464.58,482.19z"
        />
        <path
            fill={textColor}
            d="M613.97,554.96c-0.24-0.15-0.49-0.44-0.72-0.43c-0.9,0.02-1.79,0.14-2.68,0.22c0.01,0.1,0.01,0.2,0.02,0.3
	c1.09,0.03,2.19,0.05,3.29,0.08C613.91,555.06,613.94,555.01,613.97,554.96z"
        />
        <path
            fill={textColor}
            d="M686.5,554.02c0-0.07,0-0.14,0-0.22c-0.13,0.19-0.26,0.55-0.41,0.56c-1.13,0.04-2.26,0.01-3.41,0
	c0-0.12,0.01-0.24,0.01-0.36c0.06-0.04,0.12-0.08,0.18-0.13c0.19-0.15,0.49,0.04,0.73,0.15c0.36,0.15,0.74,0.54,0.98-0.28
	c0.03-0.1,0.23,0,0.33-0.05c0.31-0.14,0.61-0.29,0.91-0.49c0.5-0.35,0.96-0.81,1.47-1.1c0.31-0.17,0.67-0.09,1.07-0.13
	c-0.21-0.5-0.36-0.86-0.56-1.35c-1.88-0.49-3.76-0.38-5.66-0.17c-0.05-0.08-0.11-0.13-0.15-0.12c-0.37,0.14-0.36,0.16-0.52-0.24
	c-0.2,0.16-0.39,0.36-0.58,0.51c-0.42,0.05-0.83,0.1-1.25,0.14c-0.09-0.28-0.18-0.6-0.31-1c0.01-0.03,0.05-0.22,0.15-0.67
	c-0.53,0.4-0.94,0.7-1.49,1.11c0.02,0.05,0.05,0.1,0.07,0.14c-0.08-0.04-0.19-0.05-0.22,0c-0.31,0.69-0.73,0.32-1.12,0.31
	c-0.41-0.01-0.82,0.05-1.26-0.1c0.08-0.06,0.15-0.12,0.23-0.18c-0.01-0.05-0.01-0.1-0.02-0.15c-0.44,0.14-0.88,0.35-1.32,0.41
	c-0.24,0.03-0.49-0.2-0.73-0.28c-0.26-0.08-0.52-0.14-0.77-0.14c-0.61,0.02-1.23,0.28-1.82,0.09c-1.46-0.48-2.9-0.26-4.35-0.3
	c-0.66-0.02-1.31-0.17-1.97-0.16c-0.72,0.01-1.43,0.14-2.15,0.2c-0.55,0.05-1.17,0.36-1.64,0.04c-0.42-0.29-0.82-0.43-1.23-0.41
	c-0.42,0.02-0.83,0.21-1.22,0.31c-0.13-0.13-0.28-0.39-0.42-0.38c-0.71,0.06-1.42,0.21-2.13,0.3c-0.26,0.03-0.54-0.06-0.8-0.01
	c-0.63,0.13-1.26,0.38-1.89,0.45c-0.54,0.06-1.08-0.04-1.62-0.12c-0.81-0.12-1.62-0.31-2.43-0.41c-0.19-0.02-0.4,0.24-0.6,0.35
	c-0.17,0.09-0.34,0.15-0.54,0.01c0.12-0.11,0.23-0.23,0.5-0.48c-0.36,0-0.61-0.12-0.78,0.03c-0.47,0.45-0.92,0.41-1.38,0.04
	c-0.12,0.8-0.28,0.82-1.09,0.06c-0.02,0.17-0.03,0.32-0.05,0.55c-0.22-0.13-0.43-0.25-0.66-0.4c-0.1,0.08-0.25,0.32-0.4,0.3
	c-0.83-0.09-1.65-0.28-2.48-0.36c-0.29-0.03-0.6,0.19-0.9,0.29c-0.38,0.13-0.71,0.19-1.11-0.16c-0.3-0.27-0.87-0.06-1.21,0.29
	c-0.53,0.54-1.02,0.71-1.59,0.46c-0.43-0.19-0.87-0.29-1.3-0.43c-0.02,0.07-0.03,0.15-0.05,0.22c0.08,0.08,0.15,0.15,0.22,0.23
	c-0.11,0.12-0.2,0.35-0.29,0.67c0.2-0.09,0.4-0.17,0.62-0.27c-0.06,0.21-0.15,0.51-0.22,0.75c0.48-0.07,0.95-0.26,1.38-0.26
	c0.32,0.39,0.6,0.69,0.85,0.11c0.42-0.04,0.83-0.26,1.25-0.09c-0.11-0.07-0.22-0.14-0.32-0.21c0-0.07,0-0.13,0.01-0.2
	c0.36-0.09,0.73-0.18,1.09-0.27c0.01,0.07,0.02,0.15,0.03,0.22c-0.1,0.13-0.2,0.26-0.34,0.46c0.98-0.18,1.94,0.94,2.71-0.44
	c0.15,0.1,0.29,0.2,0.43,0.29c-0.26,0.35-0.79,0.38-1.18,0.42c-0.84,0.08-1.68,0.02-2.47,0.02c-0.11,0.49-0.18,0.78-0.24,1.07
	c-0.12-0.14-0.22-0.35-0.35-0.4c-0.22-0.08-0.45-0.09-0.68-0.09c-0.59,0-1.19,0.08-1.77,0.01c-0.26-0.03-0.5-0.34-0.8-0.37
	c0.08,0.09,0.16,0.17,0.25,0.26c-0.01,0.08-0.03,0.16-0.04,0.24c-0.23-0.1-0.46-0.24-0.7-0.3c-0.31-0.09-0.63-0.21-0.93-0.15
	c-0.44,0.09-0.88,0.21-1.32,0.35c0.13-0.13,0.31-0.35,0.48-0.51c0.34,0.37,0.89,0.14,1.54-0.91c-0.66,0.1-1.26,0.21-1.86,0.28
	c-0.25,0.03-0.5,0.02-0.75-0.02c-0.56-0.08-1.16-0.52-1.65-0.23c-1.07,0.64-2.09-0.09-3.14,0.31c-0.17,0.06-0.35-0.05-0.52-0.08
	c-0.49-0.1-0.99-0.21-1.5-0.32c-0.07,0.9,0.19,0.9,0.42,0.95c-0.07,0.02-0.14,0.11-0.21,0.31c-0.04,0.1-0.17,0.15-0.25,0.15
	c-0.59,0-1.19-0.07-1.78-0.01c-0.37,0.03-0.73,0.23-1.1,0.38c-0.44,0.17-0.88,0.36-1.31,0.53c0.01-0.1,0.02-0.21,0.03-0.31
	c-0.13-0.16-0.26-0.47-0.37-0.44c-0.64,0.14-1.32-0.47-1.94,0.48c-0.05,0.07-0.18,0.02-0.26-0.02c-0.46-0.19-0.92-0.4-1.38-0.61
	c-0.03,0.1-0.05,0.2-0.08,0.3c0.07,0.1,0.15,0.21,0.27,0.38c-0.45,0.25-0.85,0.6-1.27,0.68c-0.39,0.08-0.78-0.13-1.28-0.24
	c-0.2-0.64-0.2-0.64-0.48-0.65c0,0.16,0,0.33,0,0.48c-0.87-0.07-1.67-0.4-2.64-0.15c0-0.03-0.04-0.14-0.1-0.18
	c-0.01,0.01-0.02,0.02-0.03,0.02c-0.29,0.2-0.6,0.35-0.91,0.49c-0.11,0.05-0.3-0.05-0.33,0.05c-0.24,0.8-0.61,0.44-0.97,0.28
	c-0.09,0.12-0.18,0.22-0.28,0.25c-0.14,0.04-0.29,0.06-0.43,0.09c1.07,0.01,2.13,0.04,3.18,0c0.15-0.01,0.27-0.36,0.41-0.56
	c0,0.07,0,0.14,0,0.22c0.84,0.13,1.68,0.26,2.51,0.38c0.23,0.78,0.03,1.2-0.34,1.45c-0.46,0.31-0.5,0.75-0.15,1.41
	c0.06,0.11,0.09,0.28,0.16,0.51c-0.11,0.2-0.33,0.38-0.33,0.6c-0.01,1.3-0.49,2.17-0.94,2.87c-0.27,0.41-0.59,0.72-0.94,0.91
	c1.09,0.1,2.19,0.2,3.28,0.28c0.54,0.04,1.09,0.06,1.63,0.08c-0.14,0.72-0.14,0.59-2.13,1.03c-0.63,0.14-1.17-0.15-1.79-0.31
	c0.2,0.13,0.39,0.34,0.41,0.51c0.08,0.77,0.49,1.64,0.02,2.35c0.11,0.11,0.23,0.23,0.29,0.29c-0.43,0.22-0.87,0.43-1.3,0.65
	c0.4,0.14,0.81,0.41,1.21,0.55c0.16-0.57,2-0.81,2.88-0.45c-0.46,0.5-0.89,0.95-1.32,1.41c0.01,0.09,0.02,0.19,0.02,0.28
	c0.69-0.07,1.38-0.14,2.14-0.22c-0.25-0.2-0.48-0.38-0.72-0.57c0.1-0.22,0.17-0.39,0.22-0.38c0.48,0.12,0.96,0.31,1.44,0.48
	c-0.01,0.09-0.02,0.18-0.03,0.26c-0.2,0.17-0.39,0.33-0.59,0.5c0.5,0.2,0.97,0.39,1.39-0.48c0.22-0.47,1.85-0.55,2.11-0.17
	c0.06,0.09,0.05,0.31,0.05,0.32c0.43,0.02,0.83,0.03,1.22,0.05c0,0.06-0.01,0.12-0.01,0.18c0.3-0.26,0.59-0.53,0.78-0.7
	c-0.56-0.23-1.2-0.49-1.84-0.76c0.01-0.13,0.03-0.26,0.04-0.39c0.56,0.29,1.1-0.72,1.69-0.05c0.13,0.14,0.42-0.16,0.64-0.21
	c0.36-0.08,0.76-0.53,1.03,0.26c0.03,0.09,0.27-0.02,0.41-0.05c0.81-0.19,1.61-0.39,2.51-0.62c-0.73,1.64-1.52,1.78-2.42,1.3
	c-0.3-0.16-0.65-0.04-1.04-0.06c0.07,0.2,0.09,0.3,0.1,0.3c0.94-0.3,1.58,1.47,2.57,0.76c0.05-0.71,0.51-1,1-0.49
	c-0.12,0.16-0.22,0.3-0.36,0.48c0.08,0.04,0.12,0.1,0.17,0.09c0.72-0.09,1.44-0.21,2.16-0.3c0.24-0.03,0.48-0.05,0.72-0.02
	c0.57,0.06,1.15,0.31,1.71,0.19c1.54-0.32,3.05,0.15,4.58-0.1c0.12-0.02,0.23-0.11,0.35-0.17c0.24-0.13,0.48-0.27,0.71-0.41
	c0.02,0.09,0.05,0.19,0.07,0.28c-0.07,0.09-0.15,0.19-0.3,0.37c1.07-0.09,2.05-0.19,3.04-0.25c0.39-0.03,0.78,0.06,1.17,0.06
	c0.79-0.01,1.58-0.07,2.36-0.08c0.72-0.01,1.45,0.01,2.17,0.04c0.79,0.03,1.59,0.1,2.38,0.14c0.19,0.01,0.39,0.08,0.55-0.03
	c0.91-0.62,1.83-0.06,2.74-0.08c0.04,0,0.08,0.03,0.11,0.02c1.05-0.5,2.11,0.19,3.17-0.03c0.49-0.1,1.02,0.22,1.49,0.03
	c0.98-0.4,1.93,0.24,2.89,0.04c0.63-0.13,1.27-0.01,1.91-0.06c0.76-0.06,1.52-0.18,2.29-0.28c0.05-0.01,0.11-0.02,0.16-0.01
	c1.14,0.12,2.29,0.23,3.44,0.36c2.17,0.23,4.35,0.55,6.53,0.64c1.52,0.06,3.03-0.2,4.55-0.28c1.07-0.06,2.15-0.09,3.23-0.06
	c1.54,0.04,2.94-0.71,4.35-1.45c0.56-0.3,1.13-0.56,1.69-0.84c-0.06-0.06-0.17-0.17-0.29-0.29c0.47-0.71,0.06-1.59-0.02-2.35
	c-0.03-0.26-0.47-0.62-0.72-0.65c-0.9-0.09-1.81-0.1-2.71-0.08c-1.67,0.04-3.35,0.11-5.02,0.15c-0.18,0-0.36-0.03-0.55-0.05
	c0.17-1.15,0.21-1.11,0.79-0.82c0.34,0.17,0.72,0.01,1.09,0.02c1.51,0.02,3.03-0.02,4.54,0.08c0.83,0.05,1.57-0.34,2.11-1.17
	c0.46-0.7,0.94-1.57,0.94-2.87c0-0.22,0.22-0.4,0.33-0.6c-0.07-0.23-0.1-0.4-0.16-0.51c-0.35-0.66-0.31-1.1,0.15-1.41
	c0.37-0.25,0.58-0.68,0.34-1.45C688.18,554.28,687.34,554.15,686.5,554.02z M617.08,567.47c0-0.01,0.01-0.02,0.01-0.03
	c0,0.01,0.01,0.01,0.01,0.02C617.1,567.46,617.09,567.47,617.08,567.47z M617.33,560.41c-0.41,0.02-0.83,0.04-1.24,0.06
	c0-0.12,0-0.23,0.01-0.35c0.42-0.04,0.84-0.09,1.26-0.13C617.34,560.12,617.33,560.27,617.33,560.41z M618.04,562.58
	c0.66-0.02,1.31-0.04,1.97-0.07C619.36,562.74,618.7,563.59,618.04,562.58z M625.49,560.35c-0.26,0.04-0.53,0.01-0.77,0.14
	c-0.89,0.44-1.76,0.44-2.61-0.06c-0.07-0.04-0.19-0.01-0.25,0.07c-0.48,0.63-0.9,0.39-1.33-0.12c0.06-0.12,0.11-0.25,0.16-0.37
	c0.11,0.19,0.19,0.45,0.3,0.48c0.17,0.04,0.36-0.07,0.55-0.12c-0.07-0.19-0.13-0.38-0.2-0.56c0.59,0.64,1.23,0.3,1.87,0.15
	c0.56-0.14,1.13-0.2,1.7-0.21c0.2-0.01,0.4,0.21,0.6,0.33C625.51,560.16,625.5,560.25,625.49,560.35z M627.02,561.17
	c1.01-0.39,1.28-0.29,1.41,0.59C627.97,561.56,627.55,561.39,627.02,561.17z M629.36,567.18c-0.19,0.14-0.61-0.52-0.6,0.49
	c-0.27-0.13-0.56-0.26-0.85-0.4c0.01-0.07,0.01-0.15,0.02-0.22c0.73-0.19,1.46-0.37,2.19-0.56c0.01,0.1,0.01,0.2,0.02,0.3
	C629.88,566.92,629.61,567,629.36,567.18z M630.87,562.14c-0.33,0.08-0.66,0.15-1.05,0.24c0.05-0.35,0.08-0.53,0.1-0.67
	c-0.36-0.06-0.69-0.12-1.01-0.17c0.02-0.15,0.05-0.29,0.07-0.44c0.63,0.29,1.27,0.59,1.9,0.88
	C630.88,562.03,630.88,562.09,630.87,562.14z M631.95,552.88c-0.21,0.06-0.42,0.18-0.63,0.27c0,0.08,0.01,0.15,0.01,0.23
	c0.21,0,0.43,0.01,0.64,0.01c0,0.12,0,0.24,0,0.36c-0.64,0.08-1.29,0.17-1.93,0.25c-0.01-0.09-0.02-0.18-0.03-0.26
	c0.07-0.1,0.14-0.2,0.28-0.41c-0.42-0.18-0.79-0.33-1.24-0.52c0.15-0.16,0.25-0.26,0.37-0.39c-0.64-0.23-1.41,0.54-1.48,1.74
	c-0.26-0.31-0.44-0.52-0.61-0.75c-0.12-0.16-0.23-0.37-0.37-0.48c-0.26-0.22-0.54-0.4-0.82-0.6c-0.08-0.06-0.15-0.15-0.23-0.2
	c0.22,0.06,0.44,0.11,0.66,0.16c0.13-0.09,0.31-0.33,0.43-0.27c0.48,0.24,0.93,0.24,1.45,0.05c0.69-0.24,1.43-0.39,2.07,0.33
	c0.08,0.09,0.21,0.08,0.31,0.09c0.56,0.04,1.15-0.52,1.68,0.2C632.34,552.77,632.15,552.83,631.95,552.88z M634.82,566.48
	c0.38,0.09,0.75,0.28,1.13,0.43c0.15,0.06,0.37,0.16,0.47,0.03c0.38-0.45,0.67-0.18,0.98,0.16
	C636.84,568.07,636.18,567.94,634.82,566.48z M644.76,551.86c0.2-0.08,0.41-0.16,0.59,0.19C645.16,552,644.97,551.92,644.76,551.86z
	 M646.42,552.09c0-0.04,0-0.07,0-0.11c0.28-0.08,0.56-0.22,0.84-0.23c0.22-0.01,0.43-0.02,0.65-0.03
	C647.41,551.85,646.92,552,646.42,552.09z M650.27,551.66c-0.3,0.18-0.58,0.26-0.87-0.01C649.69,551.65,649.98,551.65,650.27,551.66
	z M649.63,552.96c-0.04,0-0.08-0.31-0.13-0.58c0.41,0.1,0.74,0.17,1.06,0.25c0.01,0.09,0.03,0.18,0.04,0.27
	C650.28,552.92,649.95,552.97,649.63,552.96z M678.2,550.58c0.03,0.07,0.06,0.13,0.1,0.2C678.26,550.73,678.23,550.67,678.2,550.58z
	 M688.14,555.12c-1.1-0.02-2.19-0.05-3.29-0.08c-0.01-0.1-0.01-0.2-0.02-0.3c0.89-0.08,1.78-0.19,2.68-0.22
	c0.23-0.01,0.48,0.28,0.72,0.43C688.2,555.01,688.17,555.06,688.14,555.12z"
        />
        <path
            fill={textColor}
            d="M613.97,634.08c-0.24-0.15-0.49-0.44-0.72-0.43c-0.9,0.02-1.79,0.14-2.68,0.22c0.01,0.1,0.01,0.2,0.02,0.3
	c1.09,0.03,2.19,0.05,3.29,0.08C613.91,634.19,613.94,634.13,613.97,634.08z"
        />
        <path
            fill={textColor}
            d="M687.77,633.33v-2.22c0.19-0.01,0.39,0,0.6-0.02c-0.21-0.5-0.36-0.86-0.56-1.35c-1.88-0.49-3.76-0.38-5.66-0.17
	c-0.05-0.08-0.11-0.13-0.15-0.12c-0.37,0.14-0.36,0.16-0.52-0.24c-0.2,0.16-0.39,0.36-0.58,0.51c-0.42,0.05-0.83,0.1-1.25,0.14
	c-0.09-0.28-0.18-0.6-0.31-1c0.01-0.03,0.05-0.22,0.15-0.67c-0.53,0.4-0.94,0.7-1.49,1.11c0.02,0.05,0.05,0.1,0.07,0.14
	c-0.08-0.04-0.19-0.05-0.22,0c-0.31,0.69-0.73,0.32-1.12,0.31c-0.41-0.01-0.82,0.05-1.26-0.1c0.08-0.06,0.15-0.12,0.23-0.18
	c-0.01-0.05-0.01-0.1-0.02-0.15c-0.44,0.14-0.88,0.35-1.32,0.41c-0.24,0.03-0.49-0.2-0.73-0.28c-0.26-0.08-0.52-0.14-0.77-0.14
	c-0.61,0.02-1.23,0.28-1.82,0.09c-1.46-0.48-2.9-0.26-4.35-0.3c-0.66-0.02-1.31-0.17-1.97-0.16c-0.72,0.01-1.43,0.14-2.15,0.2
	c-0.55,0.05-1.17,0.36-1.64,0.04c-0.42-0.29-0.82-0.43-1.23-0.41c-0.42,0.02-0.83,0.21-1.22,0.31c-0.13-0.13-0.28-0.39-0.42-0.38
	c-0.71,0.06-1.42,0.21-2.13,0.3c-0.26,0.03-0.54-0.06-0.8-0.01c-0.63,0.13-1.26,0.38-1.89,0.45c-0.54,0.06-1.08-0.04-1.62-0.12
	c-0.81-0.12-1.62-0.31-2.43-0.41c-0.19-0.02-0.4,0.24-0.6,0.35c-0.17,0.09-0.34,0.15-0.54,0.01c0.12-0.11,0.23-0.23,0.5-0.48
	c-0.36,0-0.61-0.12-0.78,0.03c-0.47,0.45-0.92,0.41-1.38,0.04c-0.12,0.8-0.28,0.82-1.09,0.06c-0.02,0.17-0.03,0.32-0.05,0.55
	c-0.22-0.13-0.43-0.25-0.66-0.4c-0.1,0.08-0.25,0.32-0.4,0.3c-0.83-0.09-1.65-0.28-2.48-0.36c-0.29-0.03-0.6,0.19-0.9,0.29
	c-0.38,0.13-0.71,0.19-1.11-0.16c-0.3-0.27-0.87-0.06-1.21,0.29c-0.53,0.54-1.02,0.71-1.59,0.46c-0.43-0.19-0.87-0.29-1.3-0.43
	c-0.02,0.07-0.03,0.15-0.05,0.22c0.08,0.08,0.15,0.15,0.22,0.23c-0.11,0.12-0.2,0.35-0.29,0.67c0.2-0.09,0.4-0.17,0.62-0.27
	c-0.06,0.21-0.15,0.51-0.22,0.75c0.48-0.07,0.95-0.26,1.38-0.26c0.32,0.39,0.6,0.69,0.85,0.11c0.42-0.04,0.83-0.26,1.25-0.09
	c-0.11-0.07-0.22-0.14-0.32-0.21c0-0.07,0-0.13,0.01-0.2c0.36-0.09,0.73-0.18,1.09-0.27c0.01,0.07,0.02,0.15,0.03,0.22
	c-0.1,0.13-0.2,0.26-0.34,0.46c0.98-0.18,1.94,0.94,2.71-0.44c0.15,0.1,0.29,0.2,0.43,0.29c-0.26,0.35-0.79,0.38-1.18,0.42
	c-0.84,0.08-1.68,0.02-2.47,0.02c-0.03,0.11-0.05,0.21-0.07,0.3h-3.53c-0.08-0.04-0.16-0.08-0.25-0.09
	c0.03,0.03,0.05,0.06,0.08,0.09h-0.97c-0.18-0.05-0.36-0.08-0.54-0.04c-0.06,0.01-0.11,0.03-0.17,0.04h-0.87
	c0.06-0.07,0.13-0.14,0.19-0.2c0.34,0.37,0.89,0.14,1.54-0.91c-0.66,0.1-1.26,0.21-1.86,0.28c-0.25,0.03-0.5,0.02-0.75-0.02
	c-0.56-0.08-1.16-0.52-1.65-0.23c-1.07,0.64-2.09-0.09-3.14,0.31c-0.17,0.06-0.35-0.05-0.52-0.08c-0.49-0.1-0.99-0.21-1.5-0.32
	c-0.07,0.9,0.19,0.9,0.42,0.95c-0.06,0.02-0.12,0.09-0.18,0.23h-9.77v1.59c-0.3-0.03-0.61-0.17-0.99-0.25
	c-0.2-0.64-0.2-0.64-0.48-0.65c0,0.16,0,0.33,0,0.48c-0.87-0.07-1.67-0.4-2.64-0.15c0-0.03-0.04-0.14-0.1-0.18
	c-0.01,0.01-0.02,0.02-0.03,0.02c-0.29,0.2-0.6,0.35-0.91,0.49c-0.11,0.05-0.3-0.05-0.33,0.05c-0.24,0.8-0.61,0.44-0.97,0.28
	c-0.09,0.12-0.18,0.22-0.28,0.25c-0.14,0.04-0.29,0.06-0.43,0.09c1.07,0.01,2.13,0.04,3.18,0c0.15-0.01,0.27-0.36,0.41-0.56
	c0,0.07,0,0.14,0,0.22c0.84,0.13,1.68,0.26,2.51,0.38c0.23,0.78,0.03,1.2-0.34,1.45c-0.46,0.31-0.5,0.75-0.15,1.41
	c0.06,0.11,0.09,0.28,0.16,0.51c-0.11,0.2-0.33,0.38-0.33,0.6c-0.01,1.3-0.49,2.17-0.94,2.87c-0.27,0.41-0.59,0.72-0.94,0.91
	c1.09,0.1,2.19,0.2,3.28,0.28c0.11,0.01,0.22,0.01,0.33,0.02v0.92c-0.23,0.05-0.5,0.11-0.83,0.18c-0.63,0.14-1.17-0.15-1.79-0.31
	c0.2,0.13,0.39,0.34,0.41,0.51c0.08,0.77,0.49,1.64,0.02,2.35c0.11,0.11,0.23,0.23,0.29,0.29c-0.43,0.22-0.87,0.43-1.3,0.65
	c0.4,0.14,0.81,0.41,1.21,0.55c0.12-0.42,1.12-0.65,1.99-0.61v0.98h0.13c-0.19,0.2-0.37,0.4-0.56,0.6c0.01,0.09,0.02,0.19,0.02,0.28
	c0.69-0.07,1.38-0.14,2.14-0.22c-0.25-0.2-0.48-0.38-0.72-0.57c0.02-0.03,0.03-0.06,0.04-0.09h1.06c0.18,0.07,0.37,0.13,0.55,0.2
	c-0.01,0.09-0.02,0.18-0.03,0.26c-0.2,0.17-0.39,0.33-0.59,0.5c0.5,0.2,0.97,0.39,1.39-0.48c0.22-0.47,1.85-0.55,2.11-0.17
	c0.06,0.09,0.05,0.31,0.05,0.32c0.43,0.02,0.83,0.03,1.22,0.05c0,0.06-0.01,0.12-0.01,0.18c0.3-0.26,0.59-0.53,0.78-0.7
	c-0.13-0.05-0.27-0.11-0.4-0.16h2.21c0.61,0.34,1.16,1.18,1.89,0.66c0.02-0.32,0.13-0.55,0.29-0.66h0.49
	c0.07,0.04,0.15,0.1,0.22,0.18c-0.12,0.16-0.22,0.3-0.36,0.48c0.08,0.04,0.12,0.1,0.17,0.09c0.72-0.09,1.44-0.21,2.16-0.3
	c0.24-0.03,0.48-0.05,0.72-0.02c0.57,0.06,1.15,0.31,1.71,0.19c1.54-0.32,3.05,0.15,4.58-0.1c0.12-0.02,0.23-0.11,0.35-0.17
	c0.2-0.11,0.41-0.23,0.61-0.35h0.12c0.02,0.07,0.04,0.15,0.05,0.22c-0.07,0.09-0.15,0.19-0.3,0.37c1.07-0.09,2.05-0.19,3.04-0.25
	c0.39-0.03,0.78,0.06,1.17,0.06c0.79-0.01,1.58-0.07,2.36-0.08c0.72-0.01,1.45,0.01,2.17,0.04c0.79,0.03,1.59,0.1,2.38,0.14
	c0.19,0.01,0.39,0.08,0.55-0.03c0.91-0.62,1.83-0.06,2.74-0.08c0.04,0,0.08,0.03,0.11,0.02c1.05-0.5,2.11,0.19,3.17-0.03
	c0.49-0.1,1.02,0.22,1.49,0.03c0.98-0.4,1.93,0.24,2.89,0.04c0.63-0.13,1.27-0.01,1.91-0.06c0.76-0.06,1.52-0.18,2.29-0.28
	c0.05-0.01,0.11-0.02,0.16-0.01c1.14,0.12,2.29,0.23,3.44,0.36c2.17,0.23,4.35,0.55,6.53,0.64c1.52,0.06,3.03-0.2,4.55-0.28
	c1.07-0.06,2.15-0.09,3.23-0.06c1.39,0.04,2.67-0.58,3.95-1.25h1.69v-0.86c0.13-0.07,0.27-0.13,0.4-0.2
	c-0.06-0.06-0.17-0.17-0.29-0.29c0.47-0.71,0.06-1.59-0.02-2.35c-0.01-0.07-0.04-0.14-0.09-0.22v-2.91
	c0.32-0.59,0.58-1.31,0.58-2.26c0-0.22,0.22-0.4,0.33-0.6c-0.07-0.23-0.1-0.4-0.16-0.51c-0.35-0.66-0.31-1.1,0.15-1.41
	c0.37-0.25,0.58-0.68,0.34-1.45C688.6,633.46,688.18,633.39,687.77,633.33z M626.14,631.46c-0.07-0.06-0.15-0.14-0.22-0.19
	c0.22,0.06,0.44,0.11,0.66,0.16c0.13-0.09,0.31-0.33,0.43-0.27c0.48,0.24,0.93,0.24,1.45,0.05c0.66-0.23,1.37-0.36,1.99,0.25H626.14
	z M644.76,630.99c0.2-0.08,0.41-0.16,0.59,0.19C645.16,631.12,644.97,631.05,644.76,630.99z M646.42,631.21c0-0.04,0-0.07,0-0.11
	c0.28-0.08,0.56-0.22,0.84-0.23c0.22-0.01,0.43-0.02,0.65-0.03C647.41,630.98,646.92,631.12,646.42,631.21z M649.4,630.78
	c0.29,0,0.58,0,0.87,0.01C649.97,630.97,649.69,631.04,649.4,630.78z M678.2,629.7c0.03,0.07,0.06,0.13,0.1,0.2
	C678.26,629.86,678.23,629.79,678.2,629.7z M688.14,634.24c-0.12,0-0.25-0.01-0.37-0.01v-0.49c0.15,0.1,0.31,0.24,0.46,0.34
	C688.2,634.13,688.17,634.19,688.14,634.24z"
        />
        <path
            fill={textColor}
            d="M686.17,609.95c0.44,0.25,0.62,0.51,0.68,0.79c0.01,0,0.02,0,0.04,0c0-0.01,0-0.03,0-0.04c-0.04-0.39-0.07-0.93-0.42-1.18
	C686.37,609.66,686.27,609.8,686.17,609.95z"
        />
        <path
            fill={textColor}
            d="M626.67,591.88c0.07,0.63-0.46,1.38-0.91,1.28c-0.23-0.05-0.45-0.24-0.68-0.29c-0.21-0.04-0.42,0.01-0.6,0.02
	c0.44,0.31,0.86,0.79,1.3,0.87c0.44,0.08,0.89-0.26,1.44-0.45c-0.22-0.25-0.35-0.41-0.52-0.61c0.2-0.23,0.37-0.43,0.65-0.75
	C627.04,591.92,626.86,591.9,626.67,591.88z"
        />
        <path
            fill={textColor}
            d="M611.69,610.08c-0.04-0.02-0.06-0.05-0.1-0.07c-0.07,0.06-0.14,0.13-0.2,0.19C611.48,610.15,611.59,610.11,611.69,610.08z"
        />
        <path
            fill={textColor}
            d="M616.74,594.27c-0.01,0.14-0.03,0.27-0.04,0.41c0.19,0.1,0.38,0.29,0.56,0.27c0.49-0.05,0.98-0.2,1.44-0.3
	c-0.03-0.01-0.09-0.04-0.22-0.1c0.13-0.16,0.23-0.28,0.32-0.39C618.1,594.19,617.42,594.23,616.74,594.27z"
        />
        <path
            fill={textColor}
            d="M611.34,611.7c-0.02-0.01-0.03-0.01-0.05-0.02c0,0.01-0.01,0.02-0.01,0.03C611.3,611.71,611.32,611.71,611.34,611.7z"
        />
        <path
            fill={textColor}
            d="M616.86,612.39c-0.01-0.32-0.03-0.66-0.04-0.91c-0.48,0.03-0.98,0.06-1.48,0.08C615.89,611.74,616.23,613.41,616.86,612.39z
	"
        />
        <path
            fill={textColor}
            d="M686.71,607.69c0.02-0.01,0.04-0.01,0.06-0.02c0.05-0.17,0.11-0.34,0.17-0.51C686.61,607.33,686.64,607.51,686.71,607.69z"
        />
        <path
            fill={textColor}
            d="M687.8,602.3c-0.11,0-0.23,0-0.34-0.01c-0.01,0-0.03,0-0.04,0c-0.05,0.22-0.1,0.46-0.17,0.73c0.27-0.06,0.58-0.09,0.58-0.13
	C687.83,602.7,687.82,602.5,687.8,602.3z"
        />
        <path
            fill={textColor}
            d="M686.53,602.3c-0.01,0.27-0.01,0.55-0.01,0.82c0.25-0.28,0.2-0.54,0.03-0.82C686.55,602.3,686.54,602.3,686.53,602.3z"
        />
        <path
            fill={textColor}
            d="M686.58,604.62c0.01,0.22,0.02,0.43,0.03,0.65c0.01,0.28,0.15,0.56,0.23,0.84c0.04,0,0.07,0,0.11,0
	C686.87,605.6,686.72,605.11,686.58,604.62z"
        />
        <path
            fill={textColor}
            d="M675.29,609.85c0.65,0.1,1.3,0.19,1.96,0.29c0-0.11,0.01-0.23,0.01-0.34c-0.12-0.03-0.25-0.11-0.37-0.09
	c-0.45,0.06-0.84,0-1.02-0.93c-0.02-0.1-0.22-0.11-0.34-0.16c-0.01,0.07-0.02,0.15-0.04,0.22c0.1,0.14,0.19,0.29,0.33,0.5
	c-0.22,0.08-0.38,0.15-0.54,0.21C675.28,609.65,675.29,609.75,675.29,609.85z"
        />
        <path
            fill={textColor}
            d="M612.37,609.23c0.14,0.24,0.28,0.47,0.41,0.71c0.06,0.11,0.15,0.23,0.17,0.35c0.01,0.05,0.01,0.1,0.02,0.15
	c0.01,0,0.01,0,0.02,0c0.35-0.02,0.69-0.32,1.01-0.28c0.21,0.05,0.44,0.19,0.64,0.13c0.33-0.1,0.64-0.42,0.96-0.47
	c0.7-0.11,1.39-0.07,2.09-0.46c0.55-0.3,1.25,0.21,1.89,0.36c0.06-0.4,0.88-0.73,1.68-0.81h0.72c0.11,0.01,0.21,0.03,0.3,0.06
	c-0.39,0.54-0.75,1.04-1.11,1.54c0.02,0.09,0.03,0.18,0.05,0.28c0.65-0.14,1.29-0.28,2.01-0.44c-0.26-0.17-0.5-0.33-0.74-0.49
	c0.07-0.23,0.13-0.4,0.17-0.4c0.47,0.07,0.95,0.21,1.42,0.33c0,0.09,0,0.18,0,0.27c-0.17,0.19-0.34,0.37-0.51,0.56
	c0.5,0.15,0.96,0.29,1.27-0.62c0.17-0.49,1.71-0.73,2-0.37c0.07,0.08,0.08,0.31,0.08,0.31c0.41-0.02,0.79-0.05,1.17-0.07
	c0,0.06,0,0.12,0,0.18c0.26-0.29,0.52-0.58,0.69-0.77c-0.36-0.12-0.77-0.25-1.18-0.38h2.23c0,0.01,0.01,0.01,0.01,0.01
	c0.01-0.01,0.02-0.01,0.04-0.01h0.49c0.66,0.22,1.28,1.17,1.98,0.55c0-0.23,0.04-0.42,0.12-0.55h0.79c-0.1,0.16-0.18,0.31-0.29,0.49
	c0.08,0.04,0.13,0.09,0.17,0.08c0.68-0.15,1.36-0.32,2.04-0.47c0.23-0.05,0.46-0.08,0.69-0.08c0.55,0.02,1.12,0.23,1.65,0.07
	c0.12-0.04,0.24-0.06,0.36-0.08h22.96c-0.24,0.03-0.48,0.07-0.72,0.1c0.01,0.08,0.01,0.16,0.02,0.24c0.98,0.18,1.97,0.36,2.92,0.53
	c-0.13-0.34-0.28-0.63-0.45-0.87h6c0.33,0.08,0.65,0.25,0.97,0.68c0.36,0.48,0.65,0.59,0.95,0.34c0.25-0.01,0.49-0.03,0.74-0.04
	c-0.01-0.13-0.01-0.26-0.02-0.39c-0.14,0.02-0.28,0.04-0.42,0.06c0.11-0.17,0.22-0.39,0.34-0.65h0.93
	c-0.08,0.01-0.15,0.02-0.23,0.03c0.57,0.24,1.16,0.49,1.75,0.74c-0.23-0.68-0.57-0.87-1.46-0.78v-1.36
	c1.82,0.21,3.64,0.47,5.46,0.56c1.46,0.07,2.91-0.16,4.37-0.22c0.96-0.04,1.91-0.04,2.87,0c0.13-0.22,0.25-0.42,0.37-0.63
	c-0.24-0.02-0.38-0.03-0.55-0.05c0.76-0.81,0.75-0.96-0.06-1.09c0.37-0.46,0.4-0.91-0.04-1.38c-0.16-0.17-0.03-0.41-0.03-0.78
	c0.26,0.27,0.37,0.38,0.48,0.5c0.13-0.2,0.08-0.37-0.01-0.54c-0.11-0.2-0.38-0.41-0.35-0.6c0.04-0.34,0.1-0.68,0.16-1.02
	c-0.75,0.01-1.49,0.02-2.24,0.02c-0.17,0-0.35-0.04-0.53-0.06c0.19-1.14,0.22-1.11,0.78-0.81c0.33,0.18,0.7,0.03,1.06,0.04
	c0.36,0.01,0.71,0.02,1.07,0.03c0.04-0.21,0.08-0.43,0.11-0.64c0.08-0.54,0.18-1.08,0.12-1.62c-0.07-0.63-0.32-1.26-0.45-1.89
	c-0.05-0.26,0.04-0.53,0.01-0.8c-0.09-0.71-0.23-1.42-0.3-2.13c-0.01-0.14,0.26-0.29,0.38-0.42c-0.11-0.39-0.3-0.8-0.31-1.22
	c-0.02-0.41,0.12-0.81,0.41-1.23c0.33-0.48,0.01-1.09-0.04-1.64c-0.02-0.2-0.04-0.4-0.07-0.6c-0.18,0.15-0.35,0.32-0.53,0.44
	c-0.41,0.04-0.83,0.08-1.25,0.12c-0.08-0.28-0.17-0.6-0.29-1.01c0.01-0.03,0.05-0.22,0.16-0.66c-0.54,0.39-0.95,0.68-1.51,1.09
	c0.02,0.05,0.04,0.1,0.07,0.14c-0.08-0.04-0.19-0.06-0.22-0.01c-0.32,0.68-0.73,0.31-1.12,0.29c-0.41-0.02-0.81,0.04-1.25-0.12
	c0.08-0.06,0.15-0.12,0.23-0.18c-0.01-0.05-0.01-0.1-0.02-0.15c-0.44,0.14-0.88,0.34-1.32,0.39c-0.23,0.03-0.48-0.21-0.73-0.28
	c-0.25-0.08-0.51-0.15-0.77-0.14c-0.6,0.01-1.22,0.27-1.81,0.08c-1.45-0.48-2.89-0.26-4.34-0.29c-0.65-0.01-1.31-0.16-1.97-0.15
	c-0.72,0.02-1.43,0.16-2.14,0.23c-0.55,0.06-1.16,0.38-1.64,0.06c-0.43-0.28-0.83-0.41-1.24-0.39c-0.41,0.02-0.82,0.22-1.21,0.34
	c-0.13-0.12-0.29-0.39-0.43-0.37c-0.71,0.08-1.41,0.25-2.12,0.35c-0.26,0.04-0.54-0.05-0.79,0.02c-0.62,0.15-1.24,0.42-1.87,0.51
	c-0.53,0.08-1.08,0-1.61-0.06c-0.81-0.09-1.62-0.25-2.44-0.31c-0.19-0.01-0.39,0.26-0.58,0.38c-0.16,0.1-0.33,0.16-0.54,0.04
	c0.11-0.12,0.22-0.24,0.48-0.51c-0.36,0.02-0.61-0.09-0.77,0.07c-0.45,0.47-0.9,0.45-1.37,0.11c-0.08,0.81-0.24,0.83-1.08,0.11
	c-0.01,0.17-0.01,0.32-0.02,0.56c-0.22-0.12-0.44-0.23-0.68-0.36c-0.09,0.08-0.23,0.33-0.38,0.32c-0.83-0.05-1.65-0.2-2.48-0.22
	c-0.29-0.01-0.58,0.23-0.87,0.35c-0.37,0.15-0.69,0.24-1.11-0.1c-0.32-0.25-0.87,0-1.18,0.36c-0.49,0.58-0.97,0.78-1.54,0.56
	c-0.43-0.16-0.88-0.23-1.32-0.34c-0.01,0.08-0.02,0.15-0.03,0.23c0.08,0.07,0.16,0.14,0.24,0.22c-0.1,0.13-0.17,0.36-0.24,0.69
	c0.19-0.1,0.39-0.2,0.6-0.31c-0.05,0.21-0.11,0.52-0.17,0.76c0.47-0.1,0.92-0.32,1.35-0.35c0.34,0.37,0.64,0.65,0.85,0.06
	c0.41-0.07,0.81-0.31,1.23-0.17c-0.11-0.06-0.22-0.13-0.33-0.19c0-0.07,0-0.13-0.01-0.2c0.36-0.11,0.71-0.22,1.07-0.33
	c0.02,0.07,0.03,0.15,0.05,0.22c-0.09,0.14-0.18,0.27-0.31,0.48c0.95-0.24,1.97,0.82,2.66-0.59c0.15,0.09,0.3,0.18,0.44,0.27
	c-0.23,0.36-0.76,0.42-1.15,0.48c-0.83,0.13-1.67,0.12-2.44,0.16c-0.08,0.5-0.13,0.79-0.17,1.08c-0.12-0.13-0.24-0.34-0.37-0.38
	c-0.22-0.07-0.45-0.06-0.68-0.04c-0.58,0.04-1.17,0.16-1.75,0.13c-0.26-0.01-0.52-0.31-0.82-0.32c0.09,0.08,0.17,0.16,0.26,0.24
	c-0.01,0.08-0.02,0.16-0.02,0.24c-0.24-0.09-0.47-0.2-0.71-0.25c-0.31-0.06-0.63-0.17-0.93-0.09c-0.43,0.12-0.85,0.28-1.28,0.44
	c0.12-0.14,0.28-0.37,0.43-0.54c0.36,0.35,0.89,0.08,1.46-1.02c-0.65,0.15-1.24,0.3-1.82,0.42c-0.25,0.05-0.5,0.05-0.74,0.04
	c-0.56-0.04-1.19-0.43-1.65-0.1c-1.01,0.73-2.08,0.09-3.07,0.56c-0.16,0.08-0.35-0.02-0.52-0.04c-0.5-0.06-0.99-0.12-1.51-0.18
	c0.01,0.9,0.27,0.88,0.49,0.91c-0.07,0.03-0.13,0.12-0.18,0.32c-0.03,0.1-0.15,0.17-0.23,0.18c-0.58,0.06-1.17,0.04-1.75,0.15
	c-0.36,0.07-0.7,0.3-1.05,0.48c-0.41,0.21-0.83,0.44-1.24,0.66c0-0.11,0-0.21,0-0.32c-0.14-0.14-0.3-0.44-0.41-0.4
	c-0.62,0.21-1.35-0.34-1.86,0.67c-0.04,0.08-0.17,0.04-0.26,0.01c-0.47-0.15-0.95-0.3-1.42-0.46c-0.01,0.1-0.03,0.2-0.04,0.3
	c0.08,0.1,0.17,0.19,0.31,0.35c-0.41,0.3-0.77,0.69-1.17,0.81c-0.37,0.12-0.77-0.05-1.28-0.1c-0.26-0.62-0.26-0.62-0.54-0.59
	c0.02,0.16,0.03,0.33,0.05,0.48c-0.85,0.02-1.68-0.21-2.6,0.15c-0.01-0.04-0.07-0.18-0.14-0.18c-0.48-0.04-0.96-0.07-1.43,0
	c-0.01,0-0.02,0.01-0.03,0.02c0.08-0.13,0.14-0.3,0.17-0.5c0.1-0.6,0.19-1.18,0.68-0.68c0.1,0.1,0.28-0.08,0.4-0.31
	c-0.71-0.26-1.38-0.13-2,0.49c-0.12,0.12-0.31,0.1-0.46,0.02c-0.17-0.09-0.33-0.19-0.5-0.28c0.02,0.14,0.06,0.28,0.12,0.43
	c0.2,0.11,0.4,0.23,0.62,0.35c-0.14,0.2-0.19,0.28-0.23,0.34c0.15,0.1,0.32,0.19,0.48,0.32c0.2,0.17,0.41,0.14,0.58-0.02
	c-0.07,0.13-0.13,0.31-0.21,0.42c-0.17,0.28-0.31,0.72-0.51,0.8c-0.28,0.11-0.56,0.19-0.85,0.25c0.05,0.16,0.11,0.31,0.21,0.47
	c0.11,0.16,0.04,0.37,0.03,0.55c-0.04,0.79-0.11,1.59-0.14,2.38c-0.02,0.51-0.03,1.02-0.04,1.53c0.08-0.01,0.17-0.02,0.25-0.03v0.31
	c-0.08,0-0.17,0.01-0.25,0.01c0,0.12,0,0.23,0,0.35c0,0.07,0.01,0.14,0.01,0.21c0.08-0.02,0.16-0.05,0.24-0.07v4.14
	c-0.06,0.01-0.11,0.03-0.17,0.04c0.06,0.73,0.13,1.47,0.2,2.25c-0.18-0.15-0.28-0.22-0.37-0.3
	C612.56,609.18,612.47,609.2,612.37,609.23z M681.7,589.92c-0.03-0.05-0.07-0.11-0.09-0.2C681.64,589.78,681.67,589.85,681.7,589.92
	z M654.23,592.33c-0.32,0.04-0.64,0.1-0.96,0.1c-0.04,0-0.09-0.31-0.16-0.57c0.41,0.08,0.74,0.14,1.06,0.2
	C654.19,592.16,654.21,592.25,654.23,592.33z M653.85,591.12c-0.29,0.2-0.56,0.28-0.86,0.03
	C653.27,591.13,653.56,591.12,653.85,591.12z M650.87,591.33c0.21-0.02,0.43-0.04,0.64-0.06c-0.48,0.16-0.97,0.33-1.45,0.44
	c0-0.04,0-0.07-0.01-0.11C650.32,591.51,650.59,591.36,650.87,591.33z M648.99,591.74c-0.19-0.05-0.39-0.11-0.6-0.16
	C648.59,591.48,648.8,591.39,648.99,591.74z M630.47,593.26c0.12-0.1,0.28-0.35,0.4-0.3c0.5,0.2,0.94,0.16,1.44-0.07
	c0.66-0.3,1.38-0.5,2.07,0.16c0.09,0.08,0.21,0.07,0.32,0.06c0.55,0,1.1-0.61,1.67,0.07c-0.19,0.07-0.38,0.15-0.57,0.22
	c-0.21,0.08-0.4,0.21-0.6,0.32c0.01,0.08,0.02,0.15,0.03,0.23c0.21-0.01,0.42-0.02,0.63-0.04c0.01,0.12,0.02,0.24,0.03,0.36
	c-0.63,0.13-1.25,0.27-1.88,0.4c-0.02-0.09-0.03-0.17-0.05-0.26c0.06-0.11,0.12-0.21,0.25-0.43c-0.43-0.14-0.81-0.27-1.26-0.42
	c0.14-0.17,0.23-0.28,0.34-0.42c-0.65-0.18-1.35,0.66-1.31,1.85c-0.28-0.29-0.47-0.48-0.66-0.69c-0.14-0.15-0.26-0.34-0.4-0.45
	c-0.28-0.2-0.57-0.35-0.85-0.52c-0.08-0.05-0.16-0.13-0.24-0.18C630.02,593.21,630.25,593.23,630.47,593.26z M622.68,609.24
	c0.01,0.01,0.01,0.01,0.02,0.02c-0.01,0-0.02,0-0.03,0.01C622.67,609.26,622.68,609.25,622.68,609.24z"
        />
        <path
            fill={textColor}
            d="M679.07,610.23c1.36-0.52,2.71,0.51,4.07,0.45c0.55-0.02,1.11-0.01,1.66,0.01c0.05-0.52,0.14-1.03,0.23-1.54
	c-0.26,0.12-0.52,0.24-0.78,0.37c-0.29,0.15-0.58,0.44-0.87,0.46c-0.51,0.04-1.03-0.07-1.55-0.12c0-0.1,0-0.2,0-0.3
	c0.24-0.01,0.49-0.03,0.73-0.04c0-0.12,0-0.25,0-0.37c-1.61,0.98-3.17-0.4-4.76-0.52c0,0.08-0.01,0.16-0.01,0.25
	c0.71,0.25,1.41,0.5,2.12,0.75c0,0.05,0,0.1,0.01,0.15c-0.31,0.05-0.62,0.11-0.98,0.17C679.03,610.14,679.05,610.23,679.07,610.23z"
        />
        <path
            fill={textColor}
            d="M637.01,610.54c-0.26,0.45-0.44,0.75-0.64,1.12c0.09,0.17,0.2,0.4,0.39,0.79C636.84,611.77,636.91,611.24,637.01,610.54z"
        />
        <path
            fill={textColor}
            d="M617.28,611.37c0.53-0.14,1.06-0.29,1.56-0.42c0.01,0.33,0.02,0.57,0.03,0.8c0.36-1.65,1.31-0.82,1.83-1.7
	c-1.15,0.39-2.3,0.78-3.45,1.17C617.27,611.27,617.28,611.32,617.28,611.37z"
        />
        <path
            fill={textColor}
            d="M642.24,611.23c0.02-0.13,0.03-0.26,0.05-0.39c-0.36-0.17-0.73-0.33-1.09-0.5c-0.02,0.14-0.03,0.28-0.05,0.41
	C641.52,610.83,641.81,611.58,642.24,611.23z"
        />
        <path
            fill={textColor}
            d="M657.01,609.81c0.01,0.1,0.02,0.2,0.03,0.3c0.54-0.1,1.07-0.19,1.61-0.29c-0.01-0.14-0.03-0.29-0.04-0.43
	C658.1,609.7,657.46,608.89,657.01,609.81z"
        />
        <path
            fill={textColor}
            d="M736.85,640.66c-0.13,0.11-0.21,0.28-0.28,0.46c0.23-0.01,0.47-0.01,0.7-0.02c0.01,0.01,0.02,0.02,0.04,0.03
	c-0.04-0.1-0.07-0.21-0.1-0.32C737.19,640.7,737.01,640.68,736.85,640.66z"
        />
        <path
            fill={backgroundColor}
            d="M808.56,515.29c0.24,0.15,0.49,0.44,0.72,0.43c0.9-0.02,1.79-0.14,2.68-0.22c-0.01-0.1-0.01-0.2-0.02-0.3
	c-1.09-0.03-2.19-0.05-3.29-0.08C808.62,515.18,808.59,515.24,808.56,515.29z"
        />
        <path
            fill={textColor}
            d="M810.69,515.89c-0.15,0.01-0.27,0.36-0.41,0.56c0-0.07,0-0.14,0-0.22c-0.84-0.13-1.68-0.26-2.51-0.38
	c-0.23-0.78-0.03-1.2,0.34-1.45c0.46-0.31,0.5-0.75,0.15-1.41c-0.06-0.11-0.09-0.28-0.16-0.51c0.11-0.2,0.33-0.38,0.33-0.6
	c0.01-1.3,0.49-2.17,0.94-2.87c0.27-0.41,0.59-0.72,0.94-0.91c-1.09-0.1-2.19-0.2-3.28-0.28c-0.54-0.04-1.09-0.06-1.63-0.08
	c0.14-0.72,0.14-0.59,2.13-1.03c0.63-0.14,1.17,0.15,1.79,0.31c-0.2-0.13-0.39-0.34-0.41-0.51c-0.08-0.77-0.49-1.64-0.02-2.35
	c-0.11-0.11-0.23-0.23-0.29-0.29c0.43-0.22,0.87-0.43,1.3-0.65c-0.4-0.14-0.81-0.41-1.21-0.55c-0.16,0.57-2,0.81-2.88,0.45
	c0.46-0.5,0.89-0.95,1.32-1.41c-0.01-0.09-0.02-0.19-0.02-0.28c-0.69,0.07-1.38,0.14-2.14,0.22c0.25,0.2,0.48,0.38,0.72,0.57
	c-0.1,0.22-0.17,0.39-0.22,0.38c-0.48-0.12-0.96-0.31-1.44-0.48c0.01-0.09,0.02-0.18,0.03-0.26c0.2-0.17,0.39-0.33,0.59-0.5
	c-0.5-0.2-0.97-0.39-1.39,0.48c-0.22,0.47-1.85,0.55-2.11,0.17c-0.06-0.09-0.05-0.31-0.05-0.32c-0.43-0.02-0.83-0.03-1.22-0.05
	c0-0.06,0.01-0.12,0.01-0.18c-0.3,0.26-0.59,0.53-0.78,0.7c0.56,0.23,1.2,0.49,1.84,0.76c-0.01,0.13-0.03,0.26-0.04,0.39
	c-0.56-0.29-1.1,0.72-1.69,0.05c-0.13-0.14-0.42,0.16-0.64,0.21c-0.36,0.08-0.76,0.53-1.03-0.26c-0.03-0.09-0.27,0.02-0.41,0.05
	c-0.81,0.19-1.61,0.39-2.51,0.62c0.73-1.64,1.52-1.78,2.42-1.3c0.3,0.16,0.65,0.04,1.04,0.06c-0.07-0.2-0.09-0.3-0.1-0.3
	c-0.94,0.3-1.58-1.47-2.57-0.76c-0.05,0.71-0.51,1-1,0.49c0.12-0.16,0.22-0.3,0.36-0.48c-0.08-0.04-0.12-0.1-0.17-0.09
	c-0.72,0.09-1.44,0.21-2.16,0.3c-0.24,0.03-0.48,0.05-0.72,0.02c-0.57-0.06-1.15-0.31-1.71-0.19c-1.54,0.32-3.05-0.15-4.58,0.1
	c-0.12,0.02-0.23,0.11-0.35,0.17c-0.24,0.13-0.48,0.27-0.71,0.41c-0.02-0.09-0.05-0.19-0.07-0.28c0.07-0.09,0.15-0.19,0.3-0.37
	c-1.07,0.09-2.05,0.19-3.04,0.25c-0.39,0.03-0.78-0.06-1.17-0.06c-0.79,0.01-1.58,0.07-2.36,0.08c-0.72,0.01-1.45-0.01-2.17-0.04
	c-0.79-0.03-1.59-0.1-2.38-0.14c-0.19-0.01-0.39-0.08-0.55,0.03c-0.91,0.62-1.83,0.06-2.74,0.08c-0.04,0-0.08-0.03-0.11-0.02
	c-1.05,0.5-2.11-0.19-3.17,0.03c-0.49,0.1-1.02-0.22-1.49-0.03c-0.98,0.4-1.93-0.24-2.89-0.04c-0.63,0.13-1.27,0.01-1.91,0.06
	c-0.76,0.06-1.52,0.18-2.29,0.28c-0.05,0.01-0.11,0.02-0.16,0.01c-1.14-0.12-2.29-0.23-3.44-0.36c-2.17-0.23-4.35-0.55-6.53-0.64
	c-1.52-0.06-3.03,0.2-4.55,0.28c-1.07,0.06-2.15,0.09-3.23,0.06c-1.54-0.04-2.94,0.71-4.35,1.45c-0.56,0.3-1.13,0.56-1.69,0.84
	c0.06,0.06,0.17,0.17,0.29,0.29c-0.47,0.71-0.06,1.59,0.02,2.35c0.03,0.26,0.47,0.62,0.72,0.65c0.9,0.09,1.81,0.1,2.71,0.08
	c1.67-0.04,3.35-0.11,5.02-0.15c0.18,0,0.36,0.03,0.55,0.05c-0.17,1.15-0.21,1.11-0.79,0.82c-0.34-0.17-0.72-0.01-1.09-0.02
	c-1.51-0.02-3.03,0.02-4.54-0.08c-0.83-0.05-1.57,0.34-2.11,1.17c-0.46,0.7-0.94,1.57-0.94,2.87c0,0.22-0.22,0.4-0.33,0.6
	c0.07,0.23,0.1,0.4,0.16,0.51c0.35,0.66,0.31,1.1-0.15,1.41c-0.37,0.25-0.58,0.68-0.34,1.45c0.83,0.12,1.67,0.25,2.51,0.38
	c0,0.07,0,0.14,0,0.22c0.13-0.19,0.26-0.55,0.41-0.56c1.13-0.04,2.26-0.01,3.41,0c0,0.12-0.01,0.24-0.01,0.36
	c-0.06,0.04-0.12,0.08-0.18,0.13c-0.19,0.15-0.49-0.04-0.73-0.15c-0.36-0.15-0.74-0.54-0.98,0.28c-0.03,0.1-0.23,0-0.33,0.05
	c-0.31,0.14-0.61,0.29-0.91,0.49c-0.5,0.35-0.96,0.81-1.47,1.1c-0.31,0.17-0.67,0.09-1.07,0.13c0.21,0.5,0.36,0.86,0.56,1.35
	c1.88,0.49,3.76,0.38,5.66,0.17c0.05,0.08,0.11,0.13,0.15,0.12c0.37-0.14,0.36-0.16,0.52,0.24c0.2-0.16,0.39-0.36,0.58-0.51
	c0.42-0.05,0.83-0.1,1.25-0.14c0.09,0.28,0.18,0.6,0.31,1c-0.01,0.03-0.05,0.22-0.15,0.67c0.53-0.4,0.94-0.7,1.49-1.11
	c-0.02-0.05-0.05-0.1-0.07-0.14c0.08,0.04,0.19,0.05,0.22,0c0.31-0.69,0.73-0.32,1.12-0.31c0.41,0.01,0.82-0.05,1.26,0.1
	c-0.08,0.06-0.15,0.12-0.23,0.18c0.01,0.05,0.01,0.1,0.02,0.15c0.44-0.14,0.88-0.35,1.32-0.41c0.24-0.03,0.49,0.2,0.73,0.28
	c0.26,0.08,0.52,0.14,0.77,0.14c0.61-0.02,1.23-0.28,1.82-0.09c1.46,0.48,2.9,0.26,4.35,0.3c0.66,0.02,1.31,0.17,1.97,0.16
	c0.72-0.01,1.43-0.14,2.15-0.2c0.55-0.05,1.17-0.36,1.64-0.04c0.42,0.29,0.82,0.43,1.23,0.41c0.42-0.02,0.83-0.21,1.22-0.31
	c0.13,0.13,0.28,0.39,0.42,0.38c0.71-0.06,1.42-0.21,2.13-0.3c0.26-0.03,0.54,0.06,0.8,0.01c0.63-0.13,1.26-0.38,1.89-0.45
	c0.54-0.06,1.08,0.04,1.62,0.12c0.81,0.12,1.62,0.31,2.43,0.41c0.19,0.02,0.4-0.24,0.6-0.35c0.17-0.09,0.34-0.15,0.54-0.01
	c-0.12,0.11-0.23,0.23-0.5,0.48c0.36,0,0.61,0.12,0.78-0.03c0.47-0.45,0.92-0.41,1.38-0.04c0.12-0.8,0.28-0.82,1.09-0.06
	c0.02-0.17,0.03-0.32,0.05-0.55c0.22,0.13,0.43,0.25,0.66,0.4c0.1-0.08,0.25-0.32,0.4-0.3c0.83,0.09,1.65,0.28,2.48,0.36
	c0.29,0.03,0.6-0.19,0.9-0.29c0.38-0.13,0.71-0.19,1.11,0.16c0.3,0.27,0.87,0.06,1.21-0.29c0.53-0.54,1.02-0.71,1.59-0.46
	c0.43,0.19,0.87,0.29,1.3,0.43c0.02-0.07,0.03-0.15,0.05-0.22c-0.08-0.08-0.15-0.15-0.23-0.23c0.11-0.12,0.2-0.35,0.29-0.67
	c-0.2,0.09-0.4,0.17-0.62,0.27c0.06-0.21,0.15-0.51,0.22-0.75c-0.48,0.07-0.95,0.26-1.38,0.26c-0.32-0.39-0.6-0.69-0.85-0.11
	c-0.42,0.04-0.83,0.26-1.25,0.09c0.11,0.07,0.22,0.14,0.32,0.21c0,0.07,0,0.13-0.01,0.2c-0.36,0.09-0.73,0.18-1.09,0.27
	c-0.01-0.07-0.02-0.15-0.03-0.22c0.1-0.13,0.2-0.26,0.34-0.46c-0.98,0.18-1.94-0.94-2.71,0.44c-0.15-0.1-0.29-0.2-0.43-0.29
	c0.26-0.35,0.79-0.38,1.18-0.42c0.84-0.08,1.68-0.02,2.47-0.02c0.11-0.49,0.18-0.78,0.24-1.07c0.12,0.14,0.22,0.35,0.35,0.4
	c0.22,0.08,0.45,0.09,0.68,0.09c0.59,0,1.19-0.08,1.77-0.01c0.26,0.03,0.5,0.34,0.8,0.37c-0.08-0.09-0.16-0.17-0.25-0.26
	c0.01-0.08,0.03-0.16,0.04-0.24c0.23,0.1,0.46,0.24,0.7,0.3c0.31,0.09,0.63,0.21,0.93,0.15c0.44-0.09,0.88-0.21,1.32-0.35
	c-0.13,0.13-0.31,0.35-0.48,0.51c-0.34-0.37-0.89-0.14-1.54,0.91c0.66-0.1,1.26-0.21,1.86-0.28c0.25-0.03,0.5-0.02,0.75,0.02
	c0.56,0.08,1.16,0.52,1.65,0.23c1.07-0.64,2.09,0.09,3.14-0.31c0.17-0.06,0.35,0.05,0.52,0.08c0.49,0.1,0.99,0.21,1.5,0.32
	c0.07-0.9-0.19-0.9-0.42-0.95c0.07-0.02,0.14-0.11,0.21-0.31c0.04-0.1,0.17-0.15,0.25-0.15c0.59,0,1.19,0.07,1.78,0.01
	c0.37-0.03,0.73-0.23,1.1-0.38c0.44-0.17,0.88-0.36,1.31-0.53c-0.01,0.1-0.02,0.21-0.03,0.31c0.13,0.16,0.26,0.47,0.37,0.44
	c0.64-0.14,1.32,0.47,1.94-0.48c0.05-0.07,0.18-0.02,0.26,0.02c0.46,0.19,0.92,0.4,1.38,0.61c0.03-0.1,0.05-0.2,0.08-0.3
	c-0.07-0.1-0.15-0.21-0.27-0.38c0.45-0.25,0.85-0.6,1.27-0.68c0.39-0.08,0.78,0.13,1.28,0.24c0.2,0.64,0.2,0.64,0.48,0.65
	c0-0.16,0-0.33,0-0.48c0.87,0.07,1.67,0.4,2.64,0.15c0,0.03,0.04,0.14,0.1,0.18c0.01-0.01,0.02-0.02,0.03-0.02
	c0.29-0.2,0.6-0.35,0.91-0.49c0.11-0.05,0.3,0.05,0.33-0.05c0.24-0.8,0.61-0.44,0.97-0.28c0.09-0.12,0.18-0.22,0.28-0.25
	c0.14-0.04,0.29-0.06,0.43-0.09C812.8,515.87,811.74,515.85,810.69,515.89z M805.45,502.78c0,0.01-0.01,0.02-0.01,0.03
	c0-0.01-0.01-0.01-0.01-0.02C805.43,502.78,805.44,502.78,805.45,502.78z M735.02,515.72c-0.23,0.01-0.48-0.28-0.72-0.43
	c0.03-0.05,0.06-0.1,0.09-0.16c1.1,0.02,2.19,0.05,3.29,0.08c0.01,0.1,0.01,0.2,0.02,0.3C736.81,515.58,735.92,515.7,735.02,515.72z
	 M744.24,519.47c0.04,0.05,0.07,0.11,0.1,0.2C744.3,519.6,744.27,519.53,744.24,519.47z M771.93,517.36
	c0.32-0.03,0.65-0.08,0.98-0.07c0.04,0,0.08,0.31,0.13,0.58c-0.41-0.1-0.74-0.17-1.06-0.24
	C771.96,517.53,771.95,517.45,771.93,517.36z M772.26,518.58c0.3-0.18,0.58-0.26,0.87,0.01C772.84,518.6,772.55,518.6,772.26,518.58
	z M775.28,518.5c-0.22,0.01-0.43,0.02-0.65,0.03c0.49-0.14,0.99-0.28,1.48-0.38c0,0.04,0,0.07,0,0.11
	C775.83,518.35,775.55,518.49,775.28,518.5z M777.18,518.19c0.19,0.06,0.39,0.13,0.59,0.19
	C777.57,518.46,777.36,518.54,777.18,518.19z M786.59,503.34c-0.15-0.06-0.37-0.16-0.47-0.03c-0.38,0.45-0.67,0.18-0.98-0.16
	c0.55-0.97,1.22-0.83,2.57,0.63C787.33,503.69,786.96,503.49,786.59,503.34z M795.51,509.08c-1.01,0.39-1.28,0.29-1.41-0.59
	C794.56,508.68,794.98,508.86,795.51,509.08z M793.17,503.06c0.19-0.14,0.61,0.52,0.6-0.49c0.27,0.13,0.56,0.26,0.85,0.4
	c-0.01,0.07-0.01,0.15-0.02,0.22c-0.73,0.19-1.46,0.37-2.19,0.56c-0.01-0.1-0.01-0.2-0.02-0.3
	C792.65,503.33,792.93,503.25,793.17,503.06z M791.66,508.11c0.33-0.08,0.66-0.15,1.05-0.24c-0.05,0.35-0.08,0.53-0.1,0.67
	c0.36,0.06,0.69,0.12,1.01,0.17c-0.02,0.15-0.05,0.29-0.07,0.44c-0.63-0.29-1.27-0.59-1.9-0.88
	C791.65,508.22,791.66,508.16,791.66,508.11z M795.95,517.95c-0.13,0.09-0.31,0.33-0.43,0.27c-0.48-0.24-0.93-0.24-1.45-0.05
	c-0.69,0.24-1.43,0.39-2.07-0.33c-0.08-0.09-0.21-0.08-0.31-0.09c-0.56-0.04-1.15,0.52-1.68-0.2c0.2-0.06,0.39-0.12,0.59-0.18
	c0.21-0.06,0.42-0.18,0.63-0.27c0-0.08-0.01-0.15-0.01-0.23c-0.21,0-0.43-0.01-0.64-0.01c0-0.12,0-0.24,0-0.36
	c0.64-0.08,1.29-0.17,1.93-0.25c0.01,0.09,0.02,0.18,0.03,0.26c-0.07,0.1-0.14,0.2-0.28,0.41c0.42,0.18,0.79,0.33,1.24,0.52
	c-0.15,0.16-0.25,0.26-0.37,0.39c0.64,0.23,1.41-0.54,1.48-1.74c0.26,0.31,0.44,0.52,0.61,0.75c0.12,0.16,0.23,0.37,0.37,0.48
	c0.26,0.22,0.54,0.4,0.82,0.6c0.08,0.06,0.15,0.15,0.23,0.2C796.39,518.04,796.17,518,795.95,517.95z M801.84,510.24
	c-0.11-0.19-0.19-0.45-0.3-0.48c-0.17-0.04-0.36,0.07-0.55,0.12c0.07,0.19,0.13,0.38,0.2,0.56c-0.59-0.64-1.23-0.3-1.87-0.15
	c-0.56,0.14-1.13,0.2-1.7,0.21c-0.2,0.01-0.4-0.21-0.6-0.33c0.01-0.1,0.02-0.19,0.03-0.29c0.26-0.04,0.53-0.01,0.77-0.14
	c0.89-0.44,1.76-0.44,2.61,0.06c0.07,0.04,0.19,0.01,0.25-0.07c0.48-0.63,0.9-0.39,1.33,0.12
	C801.95,510,801.89,510.12,801.84,510.24z M802.52,507.73c0.65-0.23,1.31-1.08,1.97-0.07C803.83,507.69,803.18,507.71,802.52,507.73
	z M806.44,510.13c-0.42,0.04-0.84,0.09-1.26,0.13c0.01-0.14,0.01-0.29,0.02-0.43c0.41-0.02,0.83-0.04,1.24-0.06
	C806.45,509.9,806.44,510.02,806.44,510.13z"
        />
        <path
            fill={backgroundColor}
            d="M808.56,436.17c0.24,0.15,0.49,0.44,0.72,0.43c0.9-0.02,1.79-0.14,2.68-0.22c-0.01-0.1-0.01-0.2-0.02-0.3
	c-1.09-0.03-2.19-0.05-3.29-0.08C808.62,436.06,808.59,436.11,808.56,436.17z"
        />
        <path
            fill={textColor}
            d="M734.76,436.92v2.22c-0.19,0.01-0.39,0-0.6,0.02c0.21,0.5,0.36,0.86,0.56,1.35c1.88,0.49,3.76,0.38,5.66,0.17
	c0.05,0.08,0.11,0.13,0.15,0.12c0.37-0.14,0.36-0.16,0.52,0.24c0.2-0.16,0.39-0.36,0.58-0.51c0.42-0.05,0.83-0.1,1.25-0.14
	c0.09,0.28,0.18,0.6,0.31,1c-0.01,0.03-0.05,0.22-0.15,0.67c0.53-0.4,0.94-0.7,1.49-1.11c-0.02-0.05-0.05-0.1-0.07-0.14
	c0.08,0.04,0.19,0.05,0.22,0c0.31-0.69,0.73-0.32,1.12-0.31c0.41,0.01,0.82-0.05,1.26,0.1c-0.08,0.06-0.15,0.12-0.23,0.18
	c0.01,0.05,0.01,0.1,0.02,0.15c0.44-0.14,0.88-0.35,1.32-0.41c0.24-0.03,0.49,0.2,0.73,0.28c0.26,0.08,0.52,0.14,0.77,0.14
	c0.61-0.02,1.23-0.28,1.82-0.09c1.46,0.48,2.9,0.26,4.35,0.3c0.66,0.02,1.31,0.17,1.97,0.16c0.72-0.01,1.43-0.14,2.15-0.2
	c0.55-0.05,1.17-0.36,1.64-0.04c0.42,0.29,0.82,0.43,1.23,0.41c0.42-0.02,0.83-0.21,1.22-0.31c0.13,0.13,0.28,0.39,0.42,0.38
	c0.71-0.06,1.42-0.21,2.13-0.3c0.26-0.03,0.54,0.06,0.8,0.01c0.63-0.13,1.26-0.38,1.89-0.45c0.54-0.06,1.08,0.04,1.62,0.12
	c0.81,0.12,1.62,0.31,2.43,0.41c0.19,0.02,0.4-0.24,0.6-0.35c0.17-0.09,0.34-0.15,0.54-0.01c-0.12,0.11-0.23,0.23-0.5,0.48
	c0.36,0,0.61,0.12,0.78-0.03c0.47-0.45,0.92-0.41,1.38-0.04c0.12-0.8,0.28-0.82,1.09-0.06c0.02-0.17,0.03-0.32,0.05-0.55
	c0.22,0.13,0.43,0.25,0.66,0.4c0.1-0.08,0.25-0.32,0.4-0.3c0.83,0.09,1.65,0.28,2.48,0.36c0.29,0.03,0.6-0.19,0.9-0.29
	c0.38-0.13,0.71-0.19,1.11,0.16c0.3,0.27,0.87,0.06,1.21-0.29c0.53-0.54,1.02-0.71,1.59-0.46c0.43,0.19,0.87,0.29,1.3,0.43
	c0.02-0.07,0.03-0.15,0.05-0.22c-0.08-0.08-0.15-0.15-0.23-0.23c0.11-0.12,0.2-0.35,0.29-0.67c-0.2,0.09-0.4,0.17-0.62,0.27
	c0.06-0.21,0.15-0.51,0.22-0.75c-0.48,0.07-0.95,0.26-1.38,0.26c-0.32-0.39-0.6-0.69-0.85-0.11c-0.42,0.04-0.83,0.26-1.25,0.09
	c0.11,0.07,0.22,0.14,0.32,0.21c0,0.07,0,0.13-0.01,0.2c-0.36,0.09-0.73,0.18-1.09,0.27c-0.01-0.07-0.02-0.15-0.03-0.22
	c0.1-0.13,0.2-0.26,0.34-0.46c-0.98,0.18-1.94-0.94-2.71,0.44c-0.15-0.1-0.29-0.2-0.43-0.29c0.26-0.35,0.79-0.38,1.18-0.42
	c0.84-0.08,1.68-0.02,2.47-0.02c0.03-0.11,0.05-0.21,0.07-0.3h3.53c0.08,0.04,0.16,0.08,0.25,0.09c-0.03-0.03-0.05-0.06-0.08-0.09
	h0.97c0.18,0.05,0.36,0.08,0.54,0.04c0.06-0.01,0.11-0.03,0.17-0.04h0.87c-0.06,0.07-0.13,0.14-0.19,0.2
	c-0.34-0.37-0.89-0.14-1.54,0.91c0.66-0.1,1.26-0.21,1.86-0.28c0.25-0.03,0.5-0.02,0.75,0.02c0.56,0.08,1.16,0.52,1.65,0.23
	c1.07-0.64,2.09,0.09,3.14-0.31c0.17-0.06,0.35,0.05,0.52,0.08c0.49,0.1,0.99,0.21,1.5,0.32c0.07-0.9-0.19-0.9-0.42-0.95
	c0.06-0.02,0.12-0.09,0.18-0.23h9.77v-1.59c0.3,0.03,0.61,0.17,0.99,0.25c0.2,0.64,0.2,0.64,0.48,0.65c0-0.16,0-0.33,0-0.48
	c0.87,0.07,1.67,0.4,2.64,0.15c0,0.03,0.04,0.14,0.1,0.18c0.01-0.01,0.02-0.02,0.03-0.02c0.29-0.2,0.6-0.35,0.91-0.49
	c0.11-0.05,0.3,0.05,0.33-0.05c0.24-0.8,0.61-0.44,0.97-0.28c0.09-0.12,0.18-0.22,0.28-0.25c0.14-0.04,0.29-0.06,0.43-0.09
	c-1.07-0.01-2.13-0.04-3.18,0c-0.15,0.01-0.27,0.36-0.41,0.56c0-0.07,0-0.14,0-0.22c-0.84-0.13-1.68-0.26-2.51-0.38
	c-0.23-0.78-0.03-1.2,0.34-1.45c0.46-0.31,0.5-0.75,0.15-1.41c-0.06-0.11-0.09-0.28-0.16-0.51c0.11-0.2,0.33-0.38,0.33-0.6
	c0.01-1.3,0.49-2.17,0.94-2.87c0.27-0.41,0.59-0.72,0.94-0.91c-1.09-0.1-2.19-0.2-3.28-0.28c-0.11-0.01-0.22-0.01-0.33-0.02v-0.92
	c0.23-0.05,0.5-0.11,0.83-0.18c0.63-0.14,1.17,0.15,1.79,0.31c-0.2-0.13-0.39-0.34-0.41-0.51c-0.08-0.77-0.49-1.64-0.02-2.35
	c-0.11-0.11-0.23-0.23-0.29-0.29c0.43-0.22,0.87-0.43,1.3-0.65c-0.4-0.14-0.81-0.41-1.21-0.55c-0.12,0.42-1.12,0.65-1.99,0.61v-0.98
	h-0.13c0.19-0.2,0.37-0.4,0.56-0.6c-0.01-0.09-0.02-0.19-0.02-0.28c-0.69,0.07-1.38,0.14-2.14,0.22c0.25,0.2,0.48,0.38,0.72,0.57
	c-0.02,0.03-0.03,0.06-0.04,0.09h-1.06c-0.18-0.07-0.37-0.13-0.55-0.2c0.01-0.09,0.02-0.18,0.03-0.26c0.2-0.17,0.39-0.33,0.59-0.5
	c-0.5-0.2-0.97-0.39-1.39,0.48c-0.22,0.47-1.85,0.55-2.11,0.17c-0.06-0.09-0.05-0.31-0.05-0.32c-0.43-0.02-0.83-0.03-1.22-0.05
	c0-0.06,0.01-0.12,0.01-0.18c-0.3,0.26-0.59,0.53-0.78,0.7c0.13,0.05,0.27,0.11,0.4,0.16h-2.21c-0.62-0.34-1.16-1.18-1.89-0.66
	c-0.02,0.32-0.13,0.55-0.29,0.66h-0.49c-0.07-0.04-0.15-0.1-0.22-0.18c0.12-0.16,0.22-0.3,0.36-0.48c-0.08-0.04-0.12-0.1-0.17-0.09
	c-0.72,0.09-1.44,0.21-2.16,0.3c-0.24,0.03-0.48,0.05-0.72,0.02c-0.57-0.06-1.15-0.31-1.71-0.19c-1.54,0.32-3.05-0.15-4.58,0.1
	c-0.12,0.02-0.23,0.11-0.35,0.17c-0.2,0.11-0.41,0.23-0.61,0.35h-0.12c-0.02-0.07-0.04-0.15-0.05-0.22c0.07-0.09,0.15-0.19,0.3-0.37
	c-1.07,0.09-2.05,0.19-3.04,0.25c-0.39,0.03-0.78-0.06-1.17-0.06c-0.79,0.01-1.58,0.07-2.36,0.08c-0.72,0.01-1.45-0.01-2.17-0.04
	c-0.79-0.03-1.59-0.1-2.38-0.14c-0.19-0.01-0.39-0.08-0.55,0.03c-0.91,0.62-1.83,0.06-2.74,0.08c-0.04,0-0.08-0.03-0.11-0.02
	c-1.05,0.5-2.11-0.19-3.17,0.03c-0.49,0.1-1.02-0.22-1.49-0.03c-0.98,0.4-1.93-0.24-2.89-0.04c-0.63,0.13-1.27,0.01-1.91,0.06
	c-0.76,0.06-1.52,0.18-2.29,0.28c-0.05,0.01-0.11,0.02-0.16,0.01c-1.14-0.12-2.29-0.23-3.44-0.36c-2.17-0.23-4.35-0.55-6.53-0.64
	c-1.52-0.06-3.03,0.2-4.55,0.28c-1.07,0.06-2.15,0.09-3.23,0.06c-1.39-0.04-2.67,0.58-3.95,1.25h-1.69v0.86
	c-0.13,0.07-0.27,0.13-0.4,0.2c0.06,0.06,0.17,0.17,0.29,0.29c-0.47,0.71-0.06,1.59,0.02,2.35c0.01,0.07,0.04,0.14,0.09,0.22v2.91
	c-0.32,0.59-0.58,1.31-0.58,2.26c0,0.22-0.22,0.4-0.33,0.6c0.07,0.23,0.1,0.4,0.16,0.51c0.35,0.66,0.31,1.1-0.15,1.41
	c-0.37,0.25-0.58,0.68-0.34,1.45C733.93,436.79,734.35,436.85,734.76,436.92z M796.39,438.79c0.07,0.06,0.15,0.14,0.22,0.19
	c-0.22-0.06-0.44-0.11-0.66-0.16c-0.13,0.09-0.31,0.33-0.43,0.27c-0.48-0.24-0.93-0.24-1.45-0.05c-0.66,0.23-1.37,0.36-1.99-0.25
	H796.39z M777.77,439.26c-0.2,0.08-0.41,0.16-0.59-0.19C777.37,439.13,777.57,439.2,777.77,439.26z M776.11,439.03
	c0,0.04,0,0.07,0,0.11c-0.28,0.08-0.56,0.22-0.84,0.23c-0.22,0.01-0.43,0.02-0.65,0.03C775.12,439.27,775.62,439.13,776.11,439.03z
	 M773.13,439.47c-0.29,0-0.58,0-0.87-0.01C772.57,439.28,772.84,439.2,773.13,439.47z M744.34,440.55c-0.03-0.07-0.06-0.13-0.1-0.2
	C744.28,440.39,744.31,440.46,744.34,440.55z M734.39,436.01c0.12,0,0.25,0.01,0.37,0.01v0.49c-0.15-0.1-0.31-0.24-0.46-0.34
	C734.33,436.11,734.36,436.06,734.39,436.01z"
        />
        <path
            fill={textColor}
            d="M736.36,460.3c-0.44-0.25-0.62-0.51-0.68-0.79c-0.01,0-0.02,0-0.04,0c0,0.01,0,0.03,0,0.04c0.04,0.39,0.07,0.93,0.42,1.18
	C736.16,460.59,736.26,460.45,736.36,460.3z"
        />
        <path
            fill={backgroundColor}
            d="M795.86,478.37c-0.07-0.63,0.46-1.38,0.9-1.28c0.23,0.05,0.45,0.24,0.68,0.29c0.21,0.04,0.42-0.01,0.6-0.02
	c-0.44-0.31-0.86-0.79-1.3-0.87c-0.44-0.08-0.89,0.26-1.44,0.45c0.22,0.25,0.35,0.41,0.52,0.61c-0.2,0.23-0.37,0.43-0.65,0.75
	C795.49,478.33,795.67,478.35,795.86,478.37z"
        />
        <path
            fill={backgroundColor}
            d="M810.84,460.17c0.04,0.02,0.06,0.05,0.11,0.07c0.07-0.06,0.14-0.13,0.2-0.19
	C811.05,460.09,810.95,460.13,810.84,460.17z"
        />
        <path
            fill={backgroundColor}
            d="M805.79,475.98c0.01-0.14,0.03-0.27,0.04-0.41c-0.19-0.1-0.38-0.29-0.56-0.27c-0.49,0.05-0.98,0.2-1.44,0.3
	c0.03,0.01,0.09,0.04,0.22,0.1c-0.13,0.16-0.23,0.28-0.32,0.39C804.43,476.06,805.11,476.02,805.79,475.98z"
        />
        <path
            fill={backgroundColor}
            d="M811.19,458.54c0.02,0.01,0.03,0.01,0.05,0.02c0-0.01,0.01-0.02,0.01-0.03
	C811.23,458.54,811.21,458.54,811.19,458.54z"
        />
        <path
            fill={backgroundColor}
            d="M805.67,457.86c0.01,0.32,0.03,0.66,0.04,0.91c0.48-0.03,0.98-0.06,1.48-0.08
	C806.64,458.51,806.3,456.84,805.67,457.86z"
        />
        <path
            fill={textColor}
            d="M735.83,462.56c-0.02,0.01-0.04,0.01-0.06,0.02c-0.05,0.17-0.11,0.34-0.17,0.51C735.92,462.92,735.89,462.74,735.83,462.56z
	"
        />
        <path
            fill={textColor}
            d="M734.74,467.94c0.11,0,0.23,0,0.34,0.01c0.01,0,0.03,0,0.04,0c0.05-0.22,0.1-0.46,0.17-0.73c-0.27,0.06-0.58,0.09-0.58,0.13
	C734.7,467.55,734.72,467.75,734.74,467.94z"
        />
        <path
            fill={textColor}
            d="M736,467.95c0.01-0.27,0.01-0.55,0.01-0.82c-0.25,0.28-0.2,0.54-0.03,0.82C735.99,467.95,735.99,467.95,736,467.95z"
        />
        <path
            fill={textColor}
            d="M735.95,465.63c-0.01-0.22-0.02-0.43-0.03-0.65c-0.01-0.28-0.15-0.56-0.23-0.84c-0.04,0-0.07,0-0.11,0
	C735.67,464.64,735.81,465.14,735.95,465.63z"
        />
        <path
            fill={textColor}
            d="M747.24,460.4c-0.65-0.1-1.3-0.19-1.96-0.29c0,0.11-0.01,0.23-0.01,0.34c0.12,0.03,0.25,0.11,0.37,0.09
	c0.45-0.06,0.84,0,1.02,0.93c0.02,0.1,0.22,0.11,0.34,0.16c0.01-0.07,0.02-0.15,0.04-0.22c-0.1-0.14-0.19-0.29-0.33-0.5
	c0.22-0.08,0.38-0.15,0.54-0.21C747.25,460.6,747.25,460.5,747.24,460.4z"
        />
        <path
            fill={textColor}
            d="M810.16,461.02c-0.14-0.24-0.28-0.47-0.41-0.71c-0.06-0.11-0.15-0.23-0.17-0.35c-0.01-0.05-0.01-0.1-0.02-0.15
	c-0.01,0-0.01,0-0.02,0c-0.35,0.02-0.69,0.32-1.01,0.28c-0.21-0.05-0.44-0.19-0.64-0.13c-0.33,0.1-0.64,0.42-0.96,0.47
	c-0.7,0.11-1.39,0.07-2.09,0.46c-0.55,0.3-1.25-0.21-1.89-0.36c-0.06,0.4-0.88,0.73-1.68,0.81h-0.72c-0.11-0.01-0.21-0.03-0.3-0.06
	c0.39-0.54,0.75-1.04,1.11-1.54c-0.02-0.09-0.03-0.18-0.05-0.28c-0.65,0.14-1.29,0.28-2.01,0.44c0.26,0.17,0.5,0.33,0.74,0.49
	c-0.07,0.23-0.13,0.4-0.17,0.4c-0.47-0.07-0.95-0.21-1.42-0.33c0-0.09,0-0.18,0-0.27c0.17-0.19,0.34-0.37,0.51-0.56
	c-0.5-0.15-0.96-0.29-1.27,0.62c-0.17,0.49-1.71,0.73-2,0.37c-0.07-0.08-0.08-0.31-0.08-0.31c-0.41,0.02-0.79,0.05-1.17,0.07
	c0-0.06,0-0.12,0-0.18c-0.26,0.29-0.52,0.58-0.69,0.77c0.36,0.12,0.77,0.25,1.18,0.38h-2.23c0-0.01-0.01-0.01-0.01-0.01
	c-0.01,0.01-0.02,0.01-0.04,0.01h-0.49c-0.66-0.22-1.28-1.17-1.98-0.55c0,0.23-0.04,0.42-0.12,0.55h-0.79
	c0.1-0.16,0.18-0.31,0.29-0.49c-0.08-0.04-0.13-0.09-0.17-0.08c-0.68,0.15-1.36,0.32-2.04,0.47c-0.23,0.05-0.46,0.08-0.69,0.08
	c-0.55-0.02-1.12-0.23-1.65-0.07c-0.12,0.04-0.24,0.06-0.36,0.08h-22.96c0.24-0.03,0.48-0.07,0.72-0.1
	c-0.01-0.08-0.01-0.16-0.02-0.24c-0.98-0.18-1.97-0.36-2.92-0.53c0.13,0.34,0.28,0.63,0.45,0.87h-6c-0.33-0.08-0.65-0.25-0.97-0.68
	c-0.36-0.48-0.65-0.59-0.95-0.34c-0.25,0.01-0.49,0.03-0.74,0.04c0.01,0.13,0.01,0.26,0.02,0.39c0.14-0.02,0.28-0.04,0.42-0.06
	c-0.11,0.17-0.22,0.39-0.34,0.65h-0.93c0.08-0.01,0.15-0.02,0.23-0.03c-0.57-0.24-1.16-0.49-1.75-0.74
	c0.23,0.68,0.57,0.87,1.46,0.78v1.36c-1.82-0.21-3.64-0.47-5.46-0.56c-1.46-0.07-2.91,0.16-4.37,0.22c-0.96,0.04-1.91,0.04-2.87,0
	c-0.13,0.22-0.25,0.42-0.37,0.63c0.24,0.02,0.38,0.03,0.55,0.05c-0.76,0.81-0.75,0.96,0.06,1.09c-0.37,0.46-0.4,0.91,0.04,1.38
	c0.16,0.17,0.03,0.41,0.03,0.78c-0.26-0.27-0.37-0.38-0.48-0.5c-0.13,0.2-0.08,0.37,0.01,0.54c0.11,0.2,0.38,0.41,0.35,0.6
	c-0.04,0.34-0.1,0.68-0.16,1.02c0.75-0.01,1.49-0.02,2.24-0.02c0.17,0,0.35,0.04,0.53,0.06c-0.19,1.14-0.22,1.11-0.78,0.81
	c-0.33-0.18-0.7-0.03-1.06-0.04c-0.36-0.01-0.71-0.02-1.07-0.03c-0.04,0.21-0.08,0.43-0.11,0.64c-0.08,0.54-0.18,1.08-0.12,1.62
	c0.07,0.63,0.32,1.26,0.45,1.89c0.05,0.26-0.04,0.53-0.01,0.8c0.09,0.71,0.23,1.42,0.3,2.13c0.01,0.14-0.26,0.29-0.38,0.42
	c0.11,0.39,0.3,0.8,0.31,1.22c0.02,0.41-0.12,0.81-0.41,1.23c-0.33,0.48-0.01,1.09,0.04,1.64c0.02,0.2,0.04,0.4,0.07,0.6
	c0.18-0.15,0.35-0.32,0.53-0.44c0.41-0.04,0.83-0.08,1.25-0.12c0.08,0.28,0.17,0.6,0.29,1.01c-0.01,0.03-0.05,0.22-0.16,0.66
	c0.54-0.39,0.95-0.68,1.51-1.09c-0.02-0.05-0.04-0.1-0.07-0.14c0.08,0.04,0.19,0.06,0.22,0.01c0.32-0.68,0.73-0.31,1.12-0.29
	c0.41,0.02,0.81-0.04,1.25,0.12c-0.08,0.06-0.15,0.12-0.23,0.18c0.01,0.05,0.01,0.1,0.02,0.15c0.44-0.14,0.88-0.34,1.32-0.39
	c0.23-0.03,0.48,0.21,0.73,0.28c0.25,0.08,0.51,0.15,0.77,0.14c0.6-0.01,1.22-0.27,1.81-0.08c1.45,0.48,2.89,0.26,4.34,0.29
	c0.65,0.01,1.31,0.16,1.97,0.15c0.72-0.02,1.43-0.16,2.14-0.23c0.55-0.06,1.16-0.38,1.64-0.06c0.43,0.28,0.83,0.41,1.24,0.39
	c0.41-0.02,0.82-0.22,1.21-0.34c0.13,0.12,0.29,0.39,0.43,0.37c0.71-0.08,1.41-0.25,2.12-0.35c0.26-0.04,0.54,0.05,0.79-0.02
	c0.62-0.15,1.24-0.42,1.87-0.51c0.53-0.08,1.08,0,1.61,0.06c0.81,0.09,1.62,0.25,2.44,0.31c0.19,0.01,0.39-0.26,0.58-0.38
	c0.16-0.1,0.33-0.16,0.54-0.04c-0.11,0.12-0.22,0.24-0.48,0.51c0.36-0.02,0.61,0.09,0.77-0.07c0.45-0.47,0.9-0.45,1.37-0.11
	c0.08-0.81,0.23-0.83,1.08-0.11c0.01-0.17,0.01-0.32,0.02-0.56c0.22,0.12,0.44,0.23,0.68,0.36c0.09-0.08,0.23-0.33,0.38-0.32
	c0.83,0.05,1.65,0.2,2.48,0.22c0.29,0.01,0.58-0.23,0.87-0.35c0.37-0.15,0.69-0.24,1.11,0.1c0.32,0.25,0.87,0,1.18-0.36
	c0.49-0.58,0.97-0.78,1.54-0.56c0.43,0.16,0.88,0.23,1.32,0.34c0.01-0.08,0.02-0.15,0.03-0.23c-0.08-0.07-0.16-0.14-0.24-0.22
	c0.1-0.13,0.17-0.36,0.24-0.69c-0.19,0.1-0.39,0.2-0.6,0.31c0.05-0.21,0.11-0.52,0.17-0.76c-0.47,0.1-0.92,0.32-1.35,0.35
	c-0.34-0.37-0.64-0.65-0.85-0.06c-0.41,0.07-0.81,0.31-1.23,0.17c0.11,0.06,0.22,0.13,0.33,0.19c0,0.07,0,0.13,0.01,0.2
	c-0.36,0.11-0.71,0.22-1.07,0.33c-0.02-0.07-0.03-0.15-0.05-0.22c0.09-0.14,0.18-0.27,0.31-0.48c-0.95,0.24-1.97-0.82-2.66,0.59
	c-0.15-0.09-0.3-0.18-0.44-0.27c0.23-0.36,0.76-0.42,1.15-0.48c0.83-0.13,1.67-0.12,2.44-0.16c0.08-0.5,0.13-0.79,0.17-1.08
	c0.12,0.13,0.24,0.34,0.37,0.38c0.22,0.07,0.45,0.06,0.68,0.04c0.58-0.04,1.17-0.16,1.75-0.13c0.26,0.01,0.52,0.31,0.82,0.32
	c-0.09-0.08-0.17-0.16-0.26-0.24c0.01-0.08,0.02-0.16,0.02-0.24c0.24,0.09,0.47,0.2,0.71,0.25c0.31,0.06,0.63,0.17,0.93,0.09
	c0.43-0.12,0.85-0.28,1.28-0.44c-0.12,0.14-0.28,0.37-0.43,0.54c-0.36-0.35-0.89-0.08-1.46,1.02c0.65-0.15,1.24-0.3,1.82-0.42
	c0.24-0.05,0.5-0.05,0.74-0.04c0.56,0.04,1.19,0.43,1.65,0.1c1.01-0.73,2.08-0.09,3.07-0.56c0.16-0.08,0.35,0.02,0.52,0.04
	c0.5,0.06,0.99,0.12,1.51,0.18c-0.01-0.9-0.27-0.88-0.49-0.91c0.06-0.03,0.13-0.12,0.18-0.32c0.03-0.1,0.15-0.17,0.23-0.18
	c0.58-0.06,1.17-0.04,1.75-0.15c0.36-0.07,0.7-0.3,1.05-0.48c0.41-0.21,0.83-0.44,1.24-0.66c0,0.11,0,0.21,0,0.32
	c0.14,0.14,0.3,0.44,0.41,0.4c0.62-0.21,1.35,0.34,1.86-0.67c0.04-0.08,0.17-0.04,0.26-0.01c0.47,0.15,0.95,0.3,1.42,0.46
	c0.01-0.1,0.03-0.2,0.04-0.3c-0.08-0.1-0.17-0.19-0.31-0.35c0.41-0.3,0.77-0.69,1.17-0.81c0.37-0.12,0.77,0.05,1.28,0.1
	c0.26,0.62,0.26,0.62,0.54,0.59c-0.02-0.16-0.03-0.33-0.05-0.48c0.85-0.02,1.68,0.21,2.6-0.15c0.01,0.04,0.07,0.18,0.14,0.18
	c0.48,0.04,0.96,0.07,1.43,0c0.01,0,0.02-0.01,0.03-0.02c-0.08,0.13-0.14,0.3-0.17,0.5c-0.1,0.6-0.19,1.18-0.68,0.68
	c-0.1-0.1-0.28,0.08-0.4,0.31c0.71,0.26,1.38,0.13,2-0.49c0.12-0.12,0.31-0.1,0.46-0.02c0.17,0.09,0.33,0.19,0.5,0.28
	c-0.02-0.14-0.06-0.28-0.12-0.43c-0.2-0.11-0.4-0.23-0.62-0.35c0.14-0.2,0.19-0.28,0.23-0.34c-0.15-0.1-0.32-0.19-0.48-0.32
	c-0.2-0.17-0.41-0.14-0.58,0.02c0.07-0.13,0.13-0.31,0.21-0.42c0.17-0.28,0.31-0.72,0.51-0.8c0.28-0.11,0.56-0.19,0.85-0.25
	c-0.05-0.16-0.11-0.31-0.21-0.47c-0.11-0.16-0.04-0.37-0.03-0.55c0.04-0.79,0.11-1.59,0.14-2.38c0.02-0.51,0.03-1.02,0.04-1.53
	c-0.08,0.01-0.17,0.02-0.25,0.03v-0.31c0.08,0,0.17-0.01,0.25-0.01c0-0.12,0-0.23,0-0.35c0-0.07-0.01-0.14-0.01-0.21
	c-0.08,0.02-0.16,0.05-0.24,0.07v-4.14c0.06-0.01,0.11-0.03,0.17-0.04c-0.06-0.73-0.13-1.47-0.2-2.25c0.18,0.15,0.28,0.22,0.37,0.3
	C809.97,461.07,810.07,461.04,810.16,461.02z M740.84,480.33c0.03,0.05,0.07,0.11,0.09,0.2C740.9,480.47,740.87,480.4,740.84,480.33
	z M768.31,477.91c0.32-0.04,0.64-0.1,0.96-0.1c0.04,0,0.09,0.31,0.16,0.57c-0.41-0.08-0.74-0.14-1.06-0.2
	C768.34,478.09,768.32,478,768.31,477.91z M768.68,479.13c0.29-0.2,0.56-0.28,0.86-0.03C769.26,479.12,768.97,479.13,768.68,479.13z
	 M771.67,478.91c-0.21,0.02-0.43,0.04-0.64,0.06c0.48-0.16,0.97-0.33,1.45-0.44c0,0.04,0,0.07,0.01,0.11
	C772.21,478.74,771.94,478.89,771.67,478.91z M773.54,478.51c0.19,0.05,0.39,0.11,0.6,0.16
	C773.94,478.76,773.73,478.86,773.54,478.51z M792.06,476.98c-0.12,0.1-0.28,0.35-0.4,0.3c-0.5-0.2-0.94-0.16-1.44,0.07
	c-0.66,0.3-1.38,0.5-2.07-0.16c-0.09-0.08-0.21-0.07-0.32-0.06c-0.55,0-1.1,0.61-1.67-0.07c0.19-0.07,0.38-0.15,0.57-0.22
	c0.21-0.08,0.4-0.21,0.6-0.32c-0.01-0.08-0.02-0.15-0.03-0.23c-0.21,0.01-0.42,0.02-0.63,0.04c-0.01-0.12-0.02-0.24-0.03-0.36
	c0.63-0.13,1.25-0.27,1.88-0.4c0.02,0.09,0.03,0.17,0.05,0.26c-0.06,0.11-0.12,0.21-0.24,0.43c0.43,0.14,0.81,0.27,1.26,0.42
	c-0.14,0.17-0.23,0.28-0.33,0.42c0.65,0.18,1.35-0.66,1.31-1.85c0.28,0.29,0.47,0.49,0.66,0.69c0.14,0.15,0.26,0.34,0.4,0.45
	c0.28,0.2,0.57,0.35,0.85,0.52c0.08,0.05,0.16,0.13,0.24,0.18C792.51,477.04,792.29,477.02,792.06,476.98z M799.85,461.01
	c-0.01-0.01-0.01-0.01-0.02-0.02c0.01,0,0.02,0,0.03-0.01C799.86,460.99,799.85,461,799.85,461.01z"
        />
        <path
            fill={textColor}
            d="M743.47,460.02c-1.36,0.52-2.71-0.51-4.07-0.45c-0.55,0.02-1.11,0.01-1.66-0.01c-0.05,0.52-0.14,1.03-0.23,1.54
	c0.26-0.12,0.52-0.24,0.78-0.37c0.29-0.15,0.58-0.44,0.87-0.46c0.51-0.04,1.03,0.07,1.55,0.12c0,0.1,0,0.2,0,0.3
	c-0.24,0.01-0.49,0.03-0.73,0.04c0,0.12,0,0.25,0,0.37c1.61-0.98,3.17,0.4,4.76,0.52c0-0.08,0.01-0.16,0.01-0.25
	c-0.71-0.25-1.41-0.5-2.12-0.75c0-0.05,0-0.1-0.01-0.15c0.31-0.05,0.62-0.11,0.98-0.17C743.51,460.1,743.48,460.02,743.47,460.02z"
        />
        <path
            fill={backgroundColor}
            d="M785.53,459.71c0.26-0.45,0.44-0.75,0.64-1.12c-0.09-0.17-0.2-0.4-0.39-0.79
	C785.69,458.48,785.62,459.01,785.53,459.71z"
        />
        <path
            fill={backgroundColor}
            d="M805.25,458.87c-0.53,0.14-1.06,0.29-1.56,0.42c-0.01-0.33-0.02-0.57-0.03-0.8c-0.36,1.65-1.31,0.82-1.83,1.7
	c1.15-0.39,2.3-0.78,3.45-1.17C805.26,458.98,805.26,458.93,805.25,458.87z"
        />
        <path
            fill={backgroundColor}
            d="M780.3,459.01c-0.02,0.13-0.03,0.26-0.05,0.39c0.36,0.17,0.73,0.33,1.09,0.5c0.02-0.14,0.03-0.28,0.05-0.41
	C781.01,459.42,780.72,458.67,780.3,459.01z"
        />
        <path
            fill={textColor}
            d="M765.52,460.44c-0.01-0.1-0.02-0.2-0.03-0.3c-0.54,0.1-1.07,0.19-1.61,0.29c0.01,0.14,0.03,0.29,0.04,0.43
	C764.44,460.55,765.08,461.36,765.52,460.44z"
        />
        <path
            fill={textColor}
            d="M615.51,427.5c0.03-0.05,0.06-0.1,0.1-0.16c-0.26-0.15-0.53-0.44-0.78-0.43c-0.98,0.02-1.95,0.14-2.92,0.22
	c0.01,0.1,0.02,0.2,0.02,0.3C613.12,427.45,614.31,427.48,615.51,427.5z"
        />
        <path
            fill={textColor}
            d="M697.14,428.23c0.4-0.25,0.63-0.68,0.37-1.45c-0.9-0.12-1.82-0.25-2.74-0.38c0-0.07,0-0.14,0-0.22
	c-0.15,0.19-0.28,0.55-0.44,0.56c-1.23,0.04-2.47,0.01-3.72,0c0.01-0.12,0.01-0.24,0.01-0.36c0.07-0.04,0.13-0.08,0.2-0.13
	c0.2-0.15,0.53,0.04,0.8,0.15c0.4,0.15,0.81,0.54,1.07-0.28c0.03-0.1,0.25,0,0.36-0.05c0.33-0.14,0.67-0.29,0.99-0.49
	c0.54-0.35,1.05-0.81,1.6-1.1c0.34-0.17,0.74-0.09,1.16-0.13c-0.23-0.5-0.39-0.86-0.61-1.35c-2.05-0.49-4.1-0.38-6.17-0.17
	c-0.06-0.08-0.12-0.13-0.16-0.12c-0.4,0.14-0.4,0.16-0.57-0.24c-0.22,0.16-0.42,0.36-0.63,0.51c-0.45,0.05-0.91,0.1-1.36,0.14
	c-0.09-0.28-0.2-0.6-0.34-1c0.01-0.03,0.06-0.22,0.17-0.67c-0.58,0.4-1.02,0.7-1.63,1.11c0.03,0.05,0.05,0.1,0.08,0.14
	c-0.09-0.04-0.21-0.05-0.24,0c-0.34,0.69-0.8,0.32-1.22,0.31c-0.45-0.01-0.89,0.05-1.37-0.1c0.08-0.06,0.17-0.12,0.25-0.18
	c-0.01-0.05-0.01-0.1-0.02-0.15c-0.48,0.14-0.96,0.35-1.45,0.41c-0.26,0.03-0.53-0.2-0.8-0.28c-0.28-0.08-0.56-0.14-0.84-0.14
	c-0.66,0.02-1.34,0.28-1.98,0.09c-1.59-0.48-3.17-0.26-4.75-0.3c-0.72-0.02-1.43-0.17-2.15-0.16c-0.78,0.01-1.57,0.14-2.35,0.2
	c-0.6,0.05-1.27,0.36-1.79,0.04c-0.46-0.29-0.89-0.43-1.35-0.41c-0.45,0.02-0.9,0.21-1.33,0.31c-0.14-0.13-0.31-0.39-0.46-0.38
	c-0.78,0.06-1.55,0.21-2.33,0.3c-0.29,0.03-0.58-0.06-0.87-0.01c-0.69,0.13-1.37,0.38-2.06,0.45c-0.58,0.06-1.18-0.04-1.76-0.12
	c-0.89-0.12-1.77-0.31-2.65-0.41c-0.21-0.02-0.43,0.24-0.66,0.35c-0.18,0.09-0.37,0.15-0.59,0.01c0.13-0.11,0.26-0.23,0.55-0.48
	c-0.39,0-0.67-0.12-0.85,0.03c-0.52,0.45-1.01,0.41-1.51,0.04c-0.13,0.8-0.3,0.82-1.19,0.06c-0.02,0.17-0.03,0.32-0.05,0.55
	c-0.24-0.13-0.46-0.25-0.72-0.4c-0.11,0.08-0.28,0.32-0.43,0.3c-0.9-0.09-1.8-0.28-2.7-0.36c-0.32-0.03-0.65,0.19-0.98,0.29
	c-0.42,0.13-0.77,0.19-1.21-0.16c-0.33-0.27-0.95-0.06-1.32,0.29c-0.58,0.54-1.11,0.71-1.73,0.46c-0.46-0.19-0.94-0.29-1.42-0.43
	c-0.02,0.07-0.03,0.15-0.05,0.22c0.08,0.08,0.16,0.15,0.25,0.23c-0.12,0.12-0.22,0.35-0.31,0.67c0.22-0.09,0.44-0.17,0.68-0.27
	c-0.07,0.21-0.16,0.51-0.24,0.75c0.52-0.07,1.03-0.26,1.51-0.26c0.35,0.39,0.66,0.69,0.93,0.11c0.46-0.04,0.91-0.26,1.36-0.09
	c-0.12-0.07-0.24-0.14-0.35-0.21c0-0.07,0.01-0.13,0.01-0.2c0.4-0.09,0.8-0.18,1.19-0.27c0.01,0.07,0.02,0.15,0.04,0.22
	c-0.11,0.13-0.22,0.26-0.38,0.46c1.06-0.18,2.12,0.94,2.96-0.44c0.16,0.1,0.31,0.2,0.47,0.29c-0.28,0.35-0.86,0.38-1.29,0.42
	c-0.92,0.08-1.84,0.02-2.7,0.02c-0.12,0.49-0.19,0.78-0.26,1.07c-0.13-0.14-0.24-0.35-0.38-0.4c-0.24-0.08-0.49-0.09-0.74-0.09
	c-0.65,0-1.29,0.08-1.94,0.01c-0.29-0.03-0.55-0.34-0.88-0.37c0.09,0.09,0.18,0.17,0.27,0.26c-0.01,0.08-0.03,0.16-0.04,0.24
	c-0.25-0.1-0.5-0.24-0.76-0.3c-0.34-0.09-0.68-0.21-1.02-0.15c-0.48,0.09-0.96,0.21-1.44,0.35c0.14-0.13,0.34-0.35,0.52-0.51
	c0.37,0.37,0.97,0.14,1.68-0.91c-0.73,0.1-1.38,0.21-2.03,0.28c-0.27,0.03-0.55,0.02-0.82-0.02c-0.61-0.08-1.27-0.52-1.8-0.23
	c-1.17,0.64-2.28-0.09-3.42,0.31c-0.18,0.06-0.38-0.05-0.57-0.08c-0.54-0.1-1.08-0.21-1.64-0.32c-0.08,0.9,0.21,0.9,0.45,0.95
	c-0.07,0.02-0.15,0.11-0.23,0.31c-0.04,0.1-0.18,0.15-0.27,0.15c-0.65,0-1.29-0.07-1.94-0.01c-0.4,0.03-0.8,0.23-1.2,0.38
	c-0.48,0.17-0.96,0.36-1.44,0.53c0.01-0.1,0.03-0.21,0.04-0.31c-0.14-0.16-0.28-0.47-0.41-0.44c-0.7,0.14-1.44-0.47-2.12,0.48
	c-0.05,0.07-0.19,0.02-0.29-0.02c-0.51-0.19-1.01-0.4-1.51-0.61c-0.03,0.1-0.06,0.2-0.08,0.3c0.08,0.1,0.16,0.21,0.3,0.38
	c-0.49,0.25-0.93,0.6-1.38,0.68c-0.42,0.08-0.85-0.13-1.4-0.24c-0.22-0.64-0.22-0.64-0.52-0.65c0,0.16,0,0.33,0,0.48
	c-0.94-0.07-1.83-0.4-2.89-0.15c0-0.03-0.05-0.14-0.11-0.18c-0.01,0.01-0.02,0.02-0.03,0.02c-0.32,0.2-0.65,0.35-0.99,0.49
	c-0.12,0.05-0.33-0.05-0.36,0.05c-0.26,0.8-0.67,0.44-1.06,0.28c-0.1,0.12-0.2,0.22-0.3,0.25c-0.16,0.04-0.31,0.06-0.47,0.09
	c1.17,0.01,2.32,0.04,3.48,0c0.16-0.01,0.3-0.36,0.44-0.56c0,0.07,0,0.14,0,0.22c0.92,0.13,1.83,0.26,2.74,0.38
	c0.26,0.78,0.03,1.2-0.37,1.45c-0.5,0.31-0.55,0.75-0.16,1.41c0.07,0.11,0.09,0.28,0.17,0.51c-0.12,0.2-0.36,0.38-0.36,0.6
	c-0.01,1.3-0.53,2.17-1.03,2.87c-0.29,0.41-0.64,0.72-1.03,0.91c1.19,0.1,2.39,0.2,3.58,0.28c0.59,0.04,1.19,0.06,1.78,0.08
	c-0.16,0.72-0.16,0.59-2.33,1.03c-0.69,0.14-1.27-0.15-1.96-0.31c0.22,0.13,0.43,0.34,0.45,0.51c0.09,0.77,0.53,1.64,0.02,2.35
	c0.12,0.11,0.25,0.23,0.31,0.29c-0.47,0.22-0.95,0.43-1.42,0.65c0.44,0.14,0.89,0.41,1.33,0.55c0.18-0.57,2.18-0.81,3.14-0.45
	c-0.5,0.5-0.97,0.95-1.44,1.41c0.01,0.09,0.02,0.19,0.03,0.28c0.75-0.07,1.5-0.14,2.34-0.22c-0.28-0.2-0.53-0.38-0.79-0.57
	c0.11-0.22,0.19-0.39,0.24-0.38c0.53,0.12,1.05,0.31,1.57,0.48c-0.01,0.09-0.02,0.18-0.03,0.26c-0.21,0.17-0.43,0.33-0.64,0.5
	c0.55,0.2,1.06,0.39,1.51-0.48c0.24-0.47,2.02-0.55,2.31-0.17c0.07,0.09,0.06,0.31,0.06,0.32c0.47,0.02,0.9,0.03,1.34,0.05
	c0,0.06-0.01,0.12-0.02,0.18c0.32-0.26,0.65-0.53,0.86-0.7c-0.61-0.23-1.31-0.49-2-0.76c0.01-0.13,0.03-0.26,0.04-0.39
	c0.61,0.29,1.2-0.72,1.85-0.05c0.14,0.14,0.46-0.16,0.7-0.21c0.39-0.08,0.83-0.53,1.13,0.26c0.04,0.09,0.3-0.02,0.45-0.05
	c0.88-0.19,1.76-0.39,2.74-0.62c-0.79,1.64-1.66,1.78-2.64,1.3c-0.32-0.16-0.71-0.04-1.13-0.06c0.07,0.2,0.09,0.3,0.11,0.3
	c1.02-0.3,1.72,1.47,2.8,0.76c0.05-0.71,0.56-1,1.1-0.49c-0.13,0.16-0.25,0.3-0.39,0.48c0.09,0.04,0.14,0.1,0.19,0.09
	c0.79-0.09,1.58-0.21,2.36-0.3c0.26-0.03,0.53-0.05,0.78-0.02c0.62,0.06,1.25,0.31,1.87,0.19c1.68-0.32,3.33,0.15,5-0.1
	c0.13-0.02,0.25-0.11,0.38-0.17c0.26-0.13,0.52-0.27,0.78-0.41c0.02,0.09,0.05,0.19,0.07,0.28c-0.08,0.09-0.16,0.19-0.32,0.37
	c1.17-0.09,2.24-0.19,3.31-0.25c0.43-0.03,0.85,0.06,1.28,0.06c0.86-0.01,1.72-0.07,2.58-0.08c0.79-0.01,1.58,0.01,2.37,0.04
	c0.87,0.03,1.73,0.1,2.6,0.14c0.2,0.01,0.43,0.08,0.6-0.03c1-0.62,1.99-0.06,2.99-0.08c0.04,0,0.08,0.03,0.12,0.02
	c1.15-0.5,2.31,0.19,3.46-0.03c0.53-0.1,1.12,0.22,1.62,0.03c0.05-0.02,0.1-0.02,0.14-0.04c-0.04,0.18-0.08,0.37-0.12,0.54
	c0.21-0.23,0.38-0.43,0.51-0.62c0.56-0.06,1.12,0.08,1.68,0.16c-0.37,0.73-0.75,1.45-1.13,2.17c-0.64,1.21-1.3,2.41-1.95,3.6
	c-0.03,0.06-0.08,0.1-0.12,0.15c-0.6,0.65-1.23,1.29-1.8,1.97c-0.48,0.57-0.8,1.28-1.34,1.78c-0.84,0.77-0.87,2.17-1.92,2.79
	c-0.5,0.29-0.56,1.05-0.99,1.43c-0.94,0.83-1,2.37-2.21,2.97c-0.04,0.02-0.03,0.08-0.06,0.12c-0.65,0.84-0.74,2.13-1.97,2.51
	c-0.22,0.07-0.3,0.31-0.42,0.49c-0.51,0.78-1,1.57-1.52,2.34c-0.48,0.7-0.97,1.39-1.49,2.05c-0.56,0.72-1.18,1.41-1.75,2.13
	c-0.28,0.36-0.47,0.79-0.78,1.13c-0.76,0.86-1.56,1.69-2.41,2.6c0.28,0,0.43,0.01,0.57,0.01c0.07,0.09,0.15,0.18,0.23,0.27
	c-0.3,0.11-0.61,0.23-0.91,0.34c-0.15,0.06-0.31,0.1-0.42,0.19c-1.34,1.21-1.97,2.95-3.4,4.11c-0.52,0.42-0.7,1.14-1.05,1.7
	c-0.15,0.23-0.34,0.44-0.55,0.64c-0.62,0.58-1.26,1.15-1.89,1.73c-0.04,0.04-0.02,0.12-0.03,0.22c0.27,0.01,0.49,0.02,0.74,0.03
	c0.15,0.83-0.49,1.03-1.22,0.54c-1.42,0.37-0.13,2.27-1.14,2.88c-0.01,0.01,0.07,0.1,0.22,0.31c0.27-0.36,0.65-0.59,0.71-0.97
	c0.19-1.17,0.92-1.78,3.07-1.21c-0.88,0.64-1.67,1.21-2.46,1.8c-0.14,0.1-0.42,0.23-0.36,0.33c0.58,0.83-0.16,0.86-0.51,1.12
	c-0.21,0.16-0.73,0.2-0.68,0.42c0.22,1.02-1.19,0.76-1.31,1.47c-0.14-0.08-0.27-0.17-0.41-0.25c0.21-0.77,0.43-1.53,0.62-2.2
	c-0.31,0.04-0.8,0.11-1.28,0.18c0.06,0.04,0.13,0.08,0.19,0.12c-0.28,0.37-0.56,0.74-0.87,1.13c0.01,0.01,0.23,0.16,0.28,0.28
	c0.19,0.52-1.11,1.91-1.75,1.76c-1.17-0.27-1.34,0.29-1.52,0.88c0.31-0.05,0.63-0.1,0.94-0.15c0.09,0.06,0.19,0.11,0.28,0.17
	c-0.19,0.55-0.36,1.11-0.61,1.63c-0.02,0.05-0.24-0.01-0.54-0.08c-0.01-0.35-0.01-0.69-0.02-1.06c-0.66,0.62-1.25,1.18-1.84,1.74
	c0.09,0.08,0.17,0.15,0.26,0.23c0.78-0.05,1.56-0.09,2.4-0.14c-0.3,1.04-1.94,2.49-2.63,2.22c-0.27,0.73-0.2,1.75-0.89,2.11
	c-0.89,0.46-1.32,1.17-1.91,1.81c-0.28,0.3-0.84,0.41-1.16,0.68c-0.2,0.17-0.2,0.47-0.28,0.72c-0.17,0.35-0.73,0.51-0.99,0.84
	c-0.19,0.25,0.19,0.86-0.03,1.09c-0.58,0.62-1.32,1.15-2.73,1.08c0.09-0.45,0.17-0.86,0.26-1.28c-0.38,0.39-0.77,0.69-0.7,1.36
	c0.07,0.71-0.77,1.15-1.22,1.7c-0.33,0.4-0.79,0.75-0.6,1.48c-0.91-0.09-1.77-0.38-2.79-0.14c0-0.03-0.05-0.14-0.11-0.18
	c-0.01,0.01-0.02,0.02-0.03,0.02c-0.32,0.2-0.65,0.35-0.99,0.49c-0.12,0.05-0.33-0.05-0.36,0.05c-0.26,0.8-0.67,0.44-1.06,0.28
	c-0.1,0.12-0.2,0.22-0.3,0.25c-0.16,0.04-0.31,0.06-0.47,0.09c1.17,0.01,2.32,0.04,3.48,0c0.16-0.01,0.3-0.36,0.44-0.56
	c0,0.07,0,0.14,0,0.22c0.92,0.13,1.83,0.26,2.74,0.38c0.13,0.4,0.13,0.71,0.05,0.95c-0.28,0.26-0.56,0.53-0.83,0.79
	c0.03,0.02,0.05,0.03,0.08,0.05c-0.15,0.28-0.1,0.63,0.17,1.08c0.07,0.11,0.09,0.28,0.17,0.51c-0.12,0.2-0.36,0.38-0.36,0.6
	c-0.01,1.3-0.53,2.17-1.03,2.87c-0.29,0.41-0.64,0.72-1.03,0.91c1.19,0.1,2.39,0.2,3.58,0.28c0.12,0.01,0.24,0.01,0.36,0.02v0.92
	c-0.25,0.05-0.55,0.11-0.9,0.18c-0.69,0.14-1.27-0.15-1.96-0.31c0.22,0.13,0.43,0.34,0.45,0.51c0.09,0.77,0.53,1.64,0.02,2.35
	c0.12,0.11,0.25,0.23,0.31,0.29c-0.47,0.22-0.95,0.43-1.42,0.65c0.44,0.14,0.89,0.41,1.33,0.55c0.13-0.42,1.22-0.65,2.18-0.61v0.98
	h0.14c-0.2,0.2-0.41,0.4-0.61,0.6c0.01,0.09,0.02,0.19,0.03,0.28c0.75-0.07,1.5-0.14,2.34-0.22c-0.28-0.2-0.53-0.38-0.79-0.57
	c0.02-0.03,0.03-0.06,0.05-0.09h1.16c0.2,0.07,0.4,0.13,0.6,0.2c-0.01,0.09-0.02,0.18-0.03,0.26c-0.21,0.17-0.43,0.33-0.64,0.5
	c0.55,0.2,1.06,0.39,1.51-0.48c0.24-0.47,2.02-0.55,2.31-0.17c0.07,0.09,0.06,0.31,0.06,0.32c0.47,0.02,0.9,0.03,1.34,0.05
	c0,0.06-0.01,0.12-0.02,0.18c0.32-0.26,0.65-0.53,0.86-0.7c-0.14-0.05-0.29-0.11-0.44-0.16h2.41c0.67,0.34,1.26,1.18,2.07,0.66
	c0.02-0.32,0.14-0.55,0.31-0.66h0.54c0.08,0.04,0.16,0.1,0.24,0.18c-0.13,0.16-0.25,0.3-0.39,0.48c0.09,0.04,0.14,0.1,0.19,0.09
	c0.79-0.09,1.58-0.21,2.36-0.3c0.26-0.03,0.53-0.05,0.78-0.02c0.62,0.06,1.25,0.31,1.87,0.19c1.68-0.32,3.33,0.15,5-0.1
	c0.13-0.02,0.25-0.11,0.38-0.17c0.22-0.11,0.44-0.23,0.66-0.35h0.13c0.02,0.07,0.04,0.15,0.06,0.22c-0.08,0.09-0.16,0.19-0.32,0.37
	c1.17-0.09,2.24-0.19,3.31-0.25c0.43-0.03,0.85,0.06,1.28,0.06c0.86-0.01,1.72-0.07,2.58-0.08c0.79-0.01,1.58,0.01,2.37,0.04
	c0.87,0.03,1.73,0.1,2.6,0.14c0.2,0.01,0.43,0.08,0.6-0.03c1-0.62,1.99-0.06,2.99-0.08c0.04,0,0.08,0.03,0.12,0.02
	c1.15-0.5,2.31,0.19,3.46-0.03c0.53-0.1,1.12,0.22,1.62,0.03c1.06-0.4,2.1,0.24,3.15,0.04c0.69-0.13,1.39-0.01,2.08-0.06
	c0.83-0.06,1.66-0.18,2.5-0.28c0.06-0.01,0.12-0.02,0.18-0.01c1.25,0.12,2.5,0.23,3.75,0.36c2.37,0.23,4.75,0.55,7.12,0.64
	c1.66,0.06,3.31-0.2,4.97-0.28c1.17-0.06,2.35-0.09,3.53-0.06c1.52,0.04,2.92-0.58,4.31-1.25h1.85v-0.86
	c0.14-0.07,0.29-0.13,0.43-0.2c-0.06-0.06-0.19-0.17-0.31-0.29c0.51-0.71,0.07-1.59-0.02-2.35c-0.01-0.07-0.05-0.14-0.1-0.22v-2.91
	c0.35-0.59,0.63-1.31,0.63-2.26c0-0.22,0.24-0.4,0.36-0.6c-0.08-0.23-0.11-0.4-0.17-0.51c-0.39-0.66-0.34-1.1,0.16-1.41
	c0.4-0.25,0.63-0.68,0.37-1.45c-0.45-0.06-0.9-0.12-1.36-0.19v-2.22c0.21-0.01,0.43,0,0.66-0.02c-0.23-0.5-0.39-0.86-0.61-1.35
	c-2.05-0.49-4.1-0.38-6.17-0.17c-0.06-0.08-0.12-0.13-0.16-0.12c-0.4,0.14-0.4,0.16-0.57-0.24c-0.22,0.16-0.42,0.36-0.63,0.51
	c-0.45,0.05-0.91,0.1-1.36,0.14c-0.09-0.28-0.2-0.6-0.34-1c0.01-0.03,0.06-0.22,0.17-0.67c-0.58,0.4-1.02,0.7-1.63,1.11
	c0.03,0.05,0.05,0.1,0.08,0.14c-0.09-0.04-0.21-0.05-0.24,0c-0.34,0.69-0.8,0.32-1.22,0.31c-0.45-0.01-0.89,0.05-1.37-0.1
	c0.08-0.06,0.17-0.12,0.25-0.18c-0.01-0.05-0.01-0.1-0.02-0.15c-0.48,0.14-0.96,0.35-1.45,0.41c-0.26,0.03-0.53-0.2-0.8-0.28
	c-0.28-0.08-0.56-0.14-0.84-0.14c-0.66,0.02-1.34,0.28-1.98,0.09c-1.59-0.48-3.17-0.26-4.75-0.3c-0.72-0.02-1.43-0.17-2.15-0.16
	c-0.78,0.01-1.57,0.14-2.35,0.2c-0.6,0.05-1.27,0.36-1.79,0.04c-0.46-0.29-0.89-0.43-1.35-0.41c-0.45,0.02-0.9,0.21-1.33,0.31
	c-0.14-0.13-0.31-0.39-0.46-0.38c-0.78,0.06-1.55,0.21-2.33,0.3c-0.29,0.03-0.58-0.06-0.87-0.01c-0.69,0.13-1.37,0.38-2.06,0.45
	c-0.58,0.06-1.18-0.04-1.76-0.12c-0.89-0.12-1.77-0.31-2.65-0.41c-0.21-0.02-0.43,0.24-0.66,0.35c-0.18,0.09-0.37,0.15-0.59,0.01
	c0.13-0.11,0.26-0.23,0.55-0.48c-0.39,0-0.67-0.12-0.85,0.03c-0.52,0.45-1.01,0.41-1.51,0.04c-0.13,0.8-0.3,0.82-1.19,0.06
	c-0.02,0.17-0.03,0.32-0.05,0.55c-0.24-0.13-0.46-0.25-0.72-0.4c-0.11,0.08-0.28,0.32-0.43,0.3c-0.9-0.09-1.8-0.28-2.7-0.36
	c-0.32-0.03-0.65,0.19-0.98,0.29c-0.42,0.13-0.77,0.19-1.21-0.16c-0.33-0.27-0.95-0.06-1.32,0.29c-0.48,0.45-0.93,0.64-1.42,0.55
	c0.18-0.4,0.29-0.77,0.59-1c0.39-0.3,1.03-0.4,1.62-0.6c0.08,0.24,0.13,0.38,0.18,0.52c0.12,0.05,0.24,0.1,0.35,0.15
	c0.14-0.55,0.27-1.1,0.42-1.65c0.03-0.1,0.07-0.25,0.18-0.24c1.41,0.16,1.29-0.88,1.91-1.34c0.11-0.08-0.1-0.43-0.16-0.65
	c-0.11-0.07-0.22-0.13-0.34-0.2c0.5-0.25,1-0.5,1.49-0.75c0.41-0.22,0.88-0.39,1.18-0.68c0.49-0.48,0.85-1.05,1.28-1.57
	c0.06-0.07,0.21-0.15,0.33-0.11c0.25,0.08,0.38,0.09,0.45,0.04c-0.12,0.23-0.3,0.47,0.64,1.07c0.26-0.53,0.51-1.04,0.77-1.55
	c0.09-0.18,0.11-0.42,0.3-0.52c1.14-0.63,1.15-2.07,2.55-2.54c0.64-0.21,0.64-1.08,0.95-1.64c0.14-0.25,0.31-0.48,0.51-0.68
	c0.5-0.48,1.02-0.93,1.59-1.45c-1.49-0.22-2.11,0.1-1.99,0.68c-0.27,0.03-0.61,0.02-0.84,0.04c0.44-0.29,0.88-0.59,1.28-0.91
	c0.28-0.23,0.38-0.61,0.51-0.95c0.1-0.26,0.13-0.57,0.2-0.85c0.09,0.05,0.18,0.1,0.26,0.14c0.03,0.14,0.05,0.28,0.08,0.42
	c0.18-0.29,0.04-0.74,0.2-1c0.35-0.58,0.84-1.05,1.26-1.58c0.16-0.2,0.32-0.42,0.39-0.68c0.04-0.15-0.09-0.41-0.15-0.62
	c0.33,0.16,0.66,0.32,1.21,0.6c0.54-0.71,1.07-1.51,1.73-2.21c0.31-0.33,0.71-0.78,1.22-0.75c0,0.2-0.01,0.4-0.01,0.61
	c-1.87-0.36-1.45,1.37-2.3,2.11c0.29,0.02,0.49,0.03,0.69,0.04c0.06,0.07,0.13,0.13,0.19,0.2c-0.34,0.26-0.68,0.52-1.02,0.78
	c-0.07-0.05-0.13-0.1-0.2-0.14c0.01-0.15,0.01-0.3,0.02-0.45c-0.12,0.51-0.62,0.71-0.96,1.06c-0.73-0.21-0.64,0.26-0.48,0.85
	c-0.3,0.39-0.82,0.66-1.22,1.04c0.29,0.12,0.64,0.27,0.89,0.37c-0.25,0.12-0.48,0.24-0.7,0.35c0.38,0.17,0.66,0.26,0.86,0.26
	c0.02,0.13,0.05,0.25,0.07,0.38c0.08,0.04,0.17,0.08,0.25,0.13c0.17-0.49,0.38-0.96,0.49-1.49c0.15-0.7,0.66-1.01,1.56-1.07
	c0.58-0.04,1.17-0.39,1.12-0.87c-0.07-0.63,0.22-0.88,0.61-1.12c0.3-0.19,0.73-0.3,0.91-0.58c0.5-0.8,0.88-1.69,1.35-2.51
	c0.08-0.14,0.42-0.1,0.57-0.12c0.02-0.32,0.05-0.61,0.07-0.9c0.24,0.16,0.39,0.26,0.57,0.38c-0.18-1.32-0.06-1.45,0.8-0.94
	c-0.04-0.7,0.23-1.14,0.98-1.22c0.26-0.03,0.31-0.35,0.56-0.68c-0.43,0.04-0.62,0.06-0.81,0.08c0.01-0.28,0.18-0.4,0.38-0.48
	c0.24-0.1,0.64-0.08,0.75-0.27c0.46-0.81,0.81-1.7,1.24-2.54c0.28-0.55,0.55-1.13,0.97-1.58c0.49-0.53,1.15-0.91,1.69-1.39
	c0.23-0.2,0.32-0.52,0.52-0.74c0.56-0.59,1.17-1.13,1.7-1.74c0.1-0.12-0.05-0.47-0.09-0.69c0.36-0.28,0.81-0.51,1.1-0.89
	c0.29-0.37,0.42-0.85,0.42-1.48c0-0.7,0.7-1.03,1.11-1.51c0.53-0.62,1.12-1.19,1.59-1.86c0.43-0.61,0.71-1.35,1.12-1.99
	c0.9-1.4,2.03-2.59,2.51-4.37c0.19-0.71,0.84-1.1,1.24-1.66c0.17-0.24,0.27-0.54,0.36-0.85c0.09-0.3,0.03-0.73,0.21-0.93
	c0.34-0.38,0.81-0.64,1.22-0.95c-0.04-0.05-0.09-0.1-0.13-0.14c-0.1,0.03-0.21,0.05-0.31,0.08c0.14-0.54,0.45-0.89,0.7-1.29
	c0.23-0.38,0.16-1.09,0.99-0.83c0.06,0.02,0.12-0.11,0.13-0.21c0.03,0.06,0.06,0.12,0.09,0.18c0.73-0.2,1.27-0.35,1.97-0.54
	c-0.48-0.26-0.68-0.38-0.71-0.4c-0.3-0.45-0.54-0.81-0.74-1.12c0.3-0.37,0.61-0.73,0.92-1.1c0.26-0.07,0.55-0.09,0.83-0.15
	c-0.28-0.48-0.26-0.46,0.1-0.7c0.03-0.02,0.02-0.13-0.02-0.24c0.68-0.84,1.34-1.69,1.91-2.62c1.02-0.27,2.01-0.75,3-1.23
	c0.61-0.3,1.23-0.56,1.85-0.84c-0.06-0.06-0.19-0.17-0.31-0.29c0.51-0.71,0.07-1.59-0.02-2.35c-0.03-0.26-0.51-0.62-0.78-0.65
	c-0.99-0.09-1.97-0.1-2.96-0.08c-0.98,0.02-1.97,0.05-2.95,0.08c0.11-0.26,0.22-0.51,0.33-0.77c1.18,0,2.37,0,3.56,0.07
	c0.9,0.05,1.71-0.34,2.3-1.17c0.5-0.7,1.02-1.57,1.03-2.87c0-0.22,0.24-0.4,0.36-0.6c-0.08-0.23-0.11-0.4-0.17-0.51
	C696.59,428.98,696.64,428.54,697.14,428.23z M619,439.85c0.01-0.01,0.01-0.02,0.02-0.03c0,0.01,0.01,0.01,0.02,0.02
	C619.02,439.85,619.01,439.85,619,439.85z M619.27,432.79c-0.45,0.02-0.9,0.04-1.35,0.06c0-0.12,0-0.23,0.01-0.35
	c0.46-0.04,0.92-0.09,1.37-0.13C619.28,432.5,619.27,432.65,619.27,432.79z M620.05,434.96c0.72-0.02,1.43-0.04,2.15-0.07
	C621.49,435.13,620.76,435.97,620.05,434.96z M628.17,432.73c-0.28,0.04-0.57,0.01-0.84,0.14c-0.98,0.44-1.92,0.44-2.85-0.06
	c-0.08-0.04-0.2-0.01-0.27,0.07c-0.53,0.63-0.98,0.39-1.45-0.12c0.06-0.12,0.12-0.25,0.18-0.37c0.12,0.19,0.21,0.45,0.33,0.48
	c0.19,0.04,0.4-0.07,0.6-0.12c-0.07-0.19-0.14-0.38-0.21-0.56c0.64,0.64,1.35,0.3,2.05,0.15c0.62-0.14,1.24-0.2,1.85-0.21
	c0.22-0.01,0.44,0.21,0.66,0.33C628.2,432.54,628.19,432.63,628.17,432.73z M629.85,433.55c1.11-0.39,1.4-0.29,1.53,0.59
	C630.88,433.95,630.43,433.77,629.85,433.55z M632.4,439.57c-0.21,0.14-0.66-0.52-0.65,0.49c-0.29-0.13-0.61-0.26-0.92-0.4
	c0.01-0.07,0.01-0.15,0.02-0.22c0.8-0.19,1.59-0.37,2.39-0.56c0.01,0.1,0.02,0.2,0.02,0.3C632.97,439.3,632.67,439.38,632.4,439.57z
	 M634.05,434.52c-0.36,0.08-0.72,0.15-1.14,0.24c0.06-0.35,0.09-0.53,0.11-0.67c-0.39-0.06-0.75-0.12-1.11-0.17
	c0.02-0.15,0.05-0.29,0.07-0.44c0.69,0.29,1.38,0.59,2.07,0.88C634.06,434.41,634.06,434.47,634.05,434.52z M635.23,425.27
	c-0.23,0.06-0.46,0.18-0.69,0.27c0.01,0.08,0.01,0.15,0.02,0.23c0.23,0,0.47,0.01,0.7,0.01c0,0.12,0,0.24,0,0.36
	c-0.7,0.08-1.41,0.17-2.11,0.25c-0.01-0.09-0.02-0.18-0.03-0.26c0.08-0.1,0.15-0.2,0.31-0.41c-0.46-0.18-0.86-0.33-1.36-0.52
	c0.17-0.16,0.27-0.26,0.41-0.39c-0.7-0.23-1.54,0.54-1.61,1.74c-0.28-0.31-0.48-0.52-0.67-0.75c-0.14-0.16-0.25-0.37-0.4-0.48
	c-0.29-0.22-0.59-0.4-0.89-0.6c-0.08-0.06-0.17-0.15-0.25-0.2c0.24,0.06,0.49,0.11,0.73,0.16c0.14-0.09,0.34-0.33,0.47-0.27
	c0.53,0.24,1.02,0.24,1.59,0.05c0.75-0.24,1.56-0.39,2.26,0.33c0.09,0.09,0.23,0.08,0.34,0.09c0.61,0.04,1.26-0.52,1.84,0.2
	C635.66,425.15,635.44,425.21,635.23,425.27z M638.36,438.86c0.42,0.09,0.82,0.28,1.23,0.43c0.17,0.06,0.4,0.16,0.52,0.03
	c0.42-0.45,0.73-0.18,1.07,0.16C640.56,440.45,639.84,440.32,638.36,438.86z M649.21,424.25c0.22-0.08,0.45-0.16,0.65,0.19
	C649.65,424.38,649.43,424.31,649.21,424.25z M651.02,424.47c0-0.04,0-0.07,0-0.11c0.31-0.08,0.61-0.22,0.91-0.23
	c0.24-0.01,0.47-0.02,0.71-0.03C652.1,424.24,651.57,424.38,651.02,424.47z M655.22,424.04c-0.33,0.18-0.63,0.26-0.95-0.01
	C654.59,424.03,654.91,424.03,655.22,424.04z M654.52,425.34c-0.04,0-0.08-0.31-0.15-0.58c0.45,0.1,0.81,0.17,1.16,0.25
	c0.02,0.09,0.03,0.18,0.05,0.27C655.23,425.3,654.88,425.35,654.52,425.34z M628.77,490.63c0.01-0.01,0.02-0.01,0.02-0.02
	c0,0.01,0.01,0.02,0.01,0.03C628.8,490.63,628.79,490.63,628.77,490.63z M696.16,506.13c0.17,0.1,0.33,0.24,0.5,0.34
	c-0.03,0.05-0.06,0.1-0.1,0.16c-0.13,0-0.27-0.01-0.4-0.01V506.13z M685.81,502.28c-0.04-0.05-0.07-0.11-0.1-0.2
	C685.74,502.15,685.78,502.22,685.81,502.28z M655.22,503.17c-0.33,0.18-0.63,0.26-0.95-0.01
	C654.59,503.15,654.91,503.15,655.22,503.17z M651.94,503.25c0.24-0.01,0.47-0.02,0.71-0.03c-0.54,0.14-1.08,0.28-1.62,0.38
	c0-0.04,0-0.07,0-0.11C651.33,503.4,651.63,503.26,651.94,503.25z M649.86,503.56c-0.21-0.06-0.42-0.13-0.65-0.19
	C649.43,503.29,649.66,503.21,649.86,503.56z M644.07,472.6c0.12,0.54,0.18,1.01-0.54,1.02c-0.2,0-0.26,0.27-0.31,0.46
	c-0.12,0.46-0.19,0.94-0.38,1.35C642.38,473.09,642.73,472.37,644.07,472.6z M637.26,481.23c-0.08-0.05-0.15-0.1-0.23-0.15
	c0.08-0.36,0.15-0.72,0.23-1.06c0.99,0.77,0.64-0.11,0.92-0.17c0.37-0.08,0.64-0.28,0.95-0.42c0.09,0.08,0.19,0.16,0.28,0.25
	C638.7,480.19,637.98,480.71,637.26,481.23z M630.16,503.65c0.11,0.03,0.22,0.05,0.33,0.06c-0.02,0.04-0.03,0.09-0.05,0.13h-0.43
	C630.07,503.77,630.12,503.71,630.16,503.65z M629.37,503.8c0.04-0.03,0.09-0.06,0.13-0.1c-0.03,0.05-0.07,0.1-0.12,0.14h-0.5
	c-0.08-0.06-0.16-0.14-0.24-0.19C628.89,503.71,629.13,503.75,629.37,503.8z M636.43,495.87c-0.36,0.33-0.72,0.67-1.08,1
	c-0.12-0.08-0.24-0.17-0.35-0.25c0.33-0.35,0.66-0.7,0.99-1.05C636.14,495.68,636.29,495.78,636.43,495.87z M635.9,491.67
	c-0.51,0.56-1.02,1.12-1.54,1.69C633.84,492.04,635.19,492.07,635.9,491.67z M617.62,503.95v1.48c-0.25-0.03-0.52-0.11-0.83-0.19
	c0.04-0.04,0.07-0.08,0.1-0.13C617.12,504.71,617.08,504.12,617.62,503.95z M641.99,503.32c0.46-0.04,0.91-0.26,1.36-0.09
	c-0.12-0.07-0.24-0.14-0.35-0.21c0-0.07,0.01-0.13,0.01-0.2c0.4-0.09,0.8-0.18,1.19-0.27c0.01,0.07,0.02,0.15,0.04,0.22
	c-0.11,0.13-0.22,0.26-0.38,0.46c1.06-0.18,2.12,0.94,2.96-0.44c0.16,0.1,0.31,0.2,0.47,0.29c-0.28,0.35-0.86,0.38-1.29,0.42
	c-0.92,0.08-1.84,0.02-2.7,0.02c-0.03,0.11-0.05,0.21-0.07,0.3h-1.4c0-0.04,0.01-0.07,0.01-0.09c-0.03,0.03-0.07,0.06-0.1,0.09h-1.5
	c0.11-0.2,0.23-0.39,0.36-0.58c0.03-0.01,0.06-0.01,0.09-0.02c0.14,0.1,0.28,0.2,0.43,0.31c0.06-0.07,0.1-0.12,0.12-0.16
	C641.52,503.67,641.77,503.8,641.99,503.32z M642.29,489.38c-0.43,0.49-0.91,0.95-1.46,1.35c-0.63,0.45-1.44,0.77-1.24,1.76
	c-0.14-0.2-0.28-0.39-0.41-0.59c-0.18,0.13-0.44,0.21-0.52,0.4c-0.05,0.12,0.15,0.38,0.25,0.62c-0.16-0.04-0.32-0.08-0.49-0.13
	c-0.19-0.75-0.12-1.3,0.86-1.26c0.12,0,0.24-0.07,0.25-0.17c0.13-1.12,0.77-1.89,1.86-2.36c0.3-0.13,0.47-0.39,0.7-0.59
	c0.1,0.06,0.2,0.12,0.31,0.18C642.37,488.86,642.44,489.2,642.29,489.38z M642.41,486.44c0.17-0.64,0.3-1.14,0.44-1.69
	C643.62,485.52,643.54,485.83,642.41,486.44z M643.9,484.74c-0.16-0.09-0.32-0.18-0.48-0.27c0.18-0.34,0.37-0.67,0.56-1.04
	c-0.15-0.09-0.36-0.2-0.74-0.42c0.37-0.28,0.68-0.52,0.99-0.76c0.06,0.04,0.11,0.08,0.17,0.12
	C644.25,483.17,644.07,483.96,643.9,484.74z M653.71,489.51c-0.07,0.1-0.17,0.2-0.14,0.34c0.24,1.11-0.43,1.66-1.16,2.08
	c-0.56,0.32-0.89,0.72-0.99,1.32c-0.03,0.15-0.39,0.13-0.57,0.18c-0.11,0.23-0.23,0.46-0.32,0.7c0-0.11-0.03-0.24-0.03-0.35
	c0-0.39,0.03-0.76,0-1.16c-0.02-0.21-0.15-0.46-0.21-0.68c-0.09-0.32-0.17-0.64-0.29-1.1c1.22,0.83,2.54,0.73,2.77-0.01
	c-0.21,0.01-0.39,0.02-0.65,0.04c0.14-0.54,0.25-0.99,0.38-1.49c-0.3-0.03-0.45-0.04-0.6-0.05c-0.08-0.07-0.16-0.15-0.24-0.23
	c0.54-0.51,1.08-1.02,1.63-1.53c0.12,0.09,0.24,0.18,0.35,0.27c-0.15,0.19-0.3,0.39-0.44,0.58c0.07,0.06,0.15,0.12,0.22,0.19
	c0.24-0.12,0.51-0.22,0.72-0.36c0.2-0.13,0.4-0.26,0.59-0.39C655.05,488.89,654.07,488.99,653.71,489.51z M685.81,423.16
	c-0.04-0.05-0.07-0.11-0.1-0.2C685.74,423.03,685.78,423.1,685.81,423.16z M664.08,477.53c0.08-0.23,0.15-0.47,0.22-0.69
	C664.52,477.28,664.29,477.41,664.08,477.53z M665.9,475.35c-0.2,0.25-0.53,0.39-0.8,0.58c-0.04-0.03-0.07-0.06-0.11-0.09
	c0.43-0.38,0.91-0.72,1.38-1.06C666.21,474.97,666.06,475.16,665.9,475.35z M666.81,472.82c-0.22-0.26-0.49-0.53-0.47-0.57
	c0.21-0.31,0.48-0.57,0.72-0.84c0.08,0.08,0.15,0.17,0.23,0.25C667.15,472.01,667,472.37,666.81,472.82z M667.45,473.46
	c-0.06-0.48,0.2-0.67,0.58-0.8C667.84,472.94,667.65,473.2,667.45,473.46z M687.13,446.96c0.04,0.08,0.08,0.17,0.13,0.25
	C687.19,447.11,687.15,447.03,687.13,446.96z M689.39,434.85c-0.15,0.23-0.31,0.44-0.47,0.65c-0.25,0.01-0.49,0.01-0.74,0.02
	c0.16-0.27,0.32-0.54,0.48-0.82c0.26,0,0.52,0,0.77,0C689.43,434.76,689.42,434.82,689.39,434.85z M696.56,427.5
	c-1.2-0.02-2.39-0.05-3.59-0.08c-0.01-0.1-0.02-0.2-0.02-0.3c0.97-0.08,1.95-0.19,2.92-0.22c0.25-0.01,0.52,0.28,0.78,0.43
	C696.62,427.39,696.59,427.45,696.56,427.5z"
        />
        <path
            fill={textColor}
            d="M615.6,506.46c-0.26-0.15-0.53-0.44-0.78-0.43c-0.98,0.02-1.95,0.14-2.92,0.22c0.01,0.1,0.02,0.2,0.02,0.3
	c1.19,0.03,2.39,0.05,3.59,0.08C615.54,506.57,615.57,506.52,615.6,506.46z"
        />
        <path
            fill={textColor}
            d="M766.74,624.7c0.06,0.04,0.12,0.08,0.18,0.11c0.04-0.05,0.1-0.11,0.25-0.26c0.59,0.4,1.09,0.74,1.6,1.08
	c0.1-0.05,0.19-0.1,0.29-0.15c-0.45-0.3-0.92-0.59-1.34-0.89c-0.12-0.08-0.2-0.22-0.12-0.34c0.39-0.63,0.3-1.15-0.17-1.61
	c-0.17,0.15-0.29,0.34-0.16,0.38c0.62,0.22,0.12,0.45-0.41,0.68c0.02-0.58,0.01-1.17,0.02-1.75c0-0.02,0-0.04,0-0.06
	c0.04-0.02,0.09-0.03,0.13-0.05c-0.04-0.12-0.08-0.24-0.13-0.36c0.01-0.42,0.02-0.84,0.11-1.24c0.14-0.65,0.44-1.26,0.62-1.9
	c0.07-0.27,0-0.57,0.05-0.84c0.13-0.74,0.29-1.47,0.35-2.22c0.01-0.15-0.25-0.34-0.37-0.49c0.1-0.4,0.28-0.82,0.28-1.26
	c0-0.44-0.15-0.87-0.46-1.34c-0.35-0.52-0.1-1.15-0.11-1.73c-0.01-0.75,0.02-1.51-0.09-2.26c-0.1-0.69-0.37-1.38-0.53-2.07
	c-0.34-1.52-0.51-3.04-1.44-4.5c-0.37-0.58-0.33-1.25-0.54-1.87c-0.09-0.26-0.25-0.52-0.42-0.77c-0.16-0.24-0.48-0.46-0.55-0.7
	c-0.12-0.46-0.11-0.93-0.17-1.4c-0.05,0-0.1,0-0.15,0.01c-0.02,0.09-0.05,0.17-0.07,0.26c-0.33-0.41-0.45-0.83-0.65-1.24
	c-0.19-0.38-0.73-0.72-0.24-1.18c0.04-0.03-0.04-0.14-0.11-0.21c0.06,0.01,0.11,0.02,0.17,0.04c0.11-0.64,0.17-1.12,0.25-1.74
	c-0.35,0.21-0.5,0.29-0.53,0.31c-0.43-0.03-0.78-0.05-1.08-0.07c-0.18-0.42-0.36-0.84-0.55-1.25c0.02-0.22,0.09-0.46,0.13-0.69
	c-0.45-0.04-0.43-0.04-0.52-0.44c-0.01-0.04-0.09-0.08-0.19-0.11c-0.67-1.35-1.47-2.68-2.52-3.89c0-0.18-0.01-0.36-0.06-0.53
	c-0.11-0.31-0.35-0.57-0.76-0.8c-0.45-0.26-0.34-0.8-0.46-1.22c-0.16-0.55-0.25-1.11-0.46-1.64c-0.19-0.48-0.54-0.93-0.76-1.4
	c-0.2-0.43-0.36-0.87-0.54-1.31c0.02-0.28,0.03-0.57,0.05-0.85c-0.1-0.03-0.2-0.06-0.3-0.08c-0.02,0.08-0.05,0.15-0.08,0.23
	c-0.19-0.37-0.43-0.73-0.75-1.06c-0.03-0.44-0.06-0.87,0.23-1.23c0.25-0.3,0.03-0.79,0.21-1.12c0.38-0.73-0.04-1.73,0.65-2.33
	c0.02-0.02,0-0.06,0.01-0.09c0.23-0.66-0.05-1.53,0.75-1.95c0.14-0.07,0.14-0.25,0.18-0.38c0.18-0.58,0.36-1.17,0.58-1.74
	c0.2-0.52,0.43-1.03,0.7-1.53c0.29-0.54,0.63-1.04,0.95-1.57c0.16-0.26,0.24-0.56,0.42-0.81c0.45-0.62,0.96-1.21,1.52-1.84
	c-0.21,0-0.32-0.01-0.43-0.01c-0.06-0.06-0.13-0.13-0.19-0.19c0.22-0.08,0.44-0.16,0.66-0.24c0.1-0.04,0.23-0.06,0.3-0.13
	c0.91-0.83,1.3-2.02,2.36-2.73c0.39-0.26,0.51-0.75,0.79-1.11c0.11-0.15,0.26-0.28,0.42-0.4c0.48-0.35,0.97-0.68,1.47-1.01
	c0.03-0.02,0.02-0.08,0.04-0.15c-0.2-0.04-0.36-0.08-0.54-0.11c-0.05-0.49,0.26-0.61,0.65-0.42c-0.13-0.18-0.26-0.35-0.39-0.52
	c-0.34,0-0.79-0.11-1.38-0.35c0.28-0.14,0.55-0.27,0.81-0.4c-0.09-0.11-0.17-0.23-0.25-0.34c-0.49-0.62-1.02-1.2-1.51-1.82
	c-0.24-0.31-0.41-0.67-0.67-0.96c-0.65-0.74-1.33-1.45-2.06-2.24c0.23,0,0.35,0,0.48,0.01c0.05-0.06,0.09-0.12,0.14-0.18l-0.07-0.09
	c-0.22-0.09-0.43-0.18-0.65-0.27c-0.12-0.05-0.26-0.09-0.35-0.17c-1.13-1.05-1.68-2.54-2.88-3.56c-0.44-0.37-0.59-0.98-0.89-1.47
	c-0.04-0.07-0.09-0.13-0.14-0.19c-0.29,0.32-0.57,0.65-0.84,0.97c-0.13,0.15-0.21,0.35-0.39,0.43c-1.12,0.47-1.47,1.71-2.74,2.1
	c-0.58,0.17-0.75,0.93-1.11,1.42c-0.16,0.22-0.34,0.42-0.54,0.6c-0.49,0.43-1,0.84-1.54,1.31c1.23,0.12,1.77-0.16,1.75-0.66
	c0.23-0.03,0.52-0.03,0.71-0.05c-0.41,0.25-0.81,0.52-1.19,0.81c-0.26,0.21-0.39,0.54-0.54,0.84c-0.11,0.23-0.17,0.49-0.25,0.74
	c-0.07-0.04-0.14-0.07-0.21-0.11c-0.01-0.12-0.02-0.24-0.03-0.36c-0.18,0.26-0.11,0.64-0.25,0.88c-0.33,0.52-0.77,0.96-1.14,1.45
	c-0.14,0.19-0.28,0.39-0.35,0.62c-0.04,0.14,0.06,0.34,0.1,0.52c-0.27-0.11-0.53-0.21-0.98-0.39c-0.47,0.67-0.91,1.43-1.44,2.13
	c-0.25,0.33-0.56,0.78-0.98,0.83c0-0.17-0.01-0.35-0.01-0.53c1.54,0.02,1.18-1.41,1.89-2.15c-0.24,0.02-0.4,0.03-0.57,0.05
	c-0.05-0.05-0.1-0.1-0.16-0.15c0.29-0.27,0.58-0.54,0.87-0.8c0.05,0.03,0.1,0.07,0.16,0.1c-0.01,0.13-0.02,0.26-0.03,0.4
	c0.12-0.46,0.54-0.68,0.84-1.01c0.6,0.12,0.54-0.28,0.45-0.78c0.28-0.36,0.73-0.64,1.11-0.99c-0.23-0.09-0.51-0.19-0.71-0.27
	c0.22-0.13,0.42-0.24,0.62-0.35c-0.3-0.13-0.53-0.18-0.69-0.17c-0.01-0.11-0.01-0.22-0.02-0.33c-0.07-0.03-0.13-0.06-0.2-0.1
	c-0.2,0.45-0.42,0.88-0.56,1.35c-0.19,0.63-0.63,0.94-1.38,1.09c-0.48,0.1-1.01,0.48-0.98,0.9c0.03,0.55-0.22,0.81-0.54,1.08
	c-0.26,0.21-0.61,0.37-0.76,0.65c-0.42,0.79-0.71,1.64-1.06,2.46c-0.06,0.14-0.34,0.17-0.46,0.22c0,0.29,0,0.55,0,0.81
	c-0.21-0.09-0.34-0.15-0.49-0.22c0.24,1.13,0.16,1.26-0.58,1.01c0.09,0.61-0.08,1.05-0.67,1.31c-0.21,0.09-0.21,0.39-0.37,0.74
	c0.34-0.15,0.49-0.21,0.64-0.27c0.03,0.25-0.09,0.39-0.24,0.52c-0.18,0.15-0.5,0.23-0.56,0.43c-0.25,0.83-0.39,1.71-0.57,2.56
	c-0.12,0.56-0.21,1.14-0.43,1.66c-0.26,0.61-0.67,1.15-0.96,1.75c-0.12,0.25-0.11,0.56-0.2,0.82c-0.08,0.22-0.17,0.45-0.26,0.67
	c-0.13-0.24-0.26-0.48-0.41-0.72c-0.11-0.17-0.59-0.37-0.85-0.31c-0.74,0.17-1.7,0.16-2.2,0.7c-0.14-0.04-0.29-0.09-0.36-0.11
	c-0.07,0.48-0.13,0.96-0.22,1.44c-0.22,1.2-0.46,2.41,0.07,3.47c0.38,0.75,0.69,1.51,0.98,2.28c0.41,1.09,0.64,2.22,1.17,3.28
	c0.27,0.53,0.56,1.05,0.87,1.57c0.01,1.12,0.04,2.24,0.42,3.39c-0.22,0.17-0.39,0.31-0.52,0.44c-0.03-0.11-0.05-0.21-0.08-0.32
	c-0.12,0.03-0.25,0.06-0.37,0.1c0.06,0.18,0.13,0.37,0.19,0.55c-0.15,0.29,0.05,0.48,0.62,0.61c0.17,0.04,0.31,0.09,0.43,0.14
	c0,0.26-0.01,0.52,0.02,0.78c0.03,0.27,0.15,0.56,0.26,0.83c0.11,0.27,0.39,0.54,0.4,0.79c0.03,0.48-0.06,0.94-0.1,1.41
	c0.05,0.01,0.1,0.01,0.15,0.02c0.04-0.08,0.08-0.16,0.12-0.24c0.24,0.47,0.29,0.9,0.4,1.34c0.11,0.41,0.58,0.85,0.02,1.2
	c-0.04,0.03,0.01,0.15,0.07,0.23c-0.05-0.02-0.1-0.04-0.16-0.07c-0.23,0.61-0.38,1.06-0.57,1.66c0.38-0.14,0.54-0.19,0.57-0.2
	c0.42,0.11,0.75,0.19,1.04,0.27c0.1,0.44,0.2,0.89,0.3,1.33c-0.07,0.22-0.18,0.43-0.25,0.66c0.44,0.13,0.41,0.12,0.43,0.53
	c0,0.04,0.08,0.09,0.17,0.14c0.57,2.03,1.37,4.05,2.82,5.89c0.54,0.11,0.95,0.19,1.5,0.3c-0.18-0.4-0.45-0.76-0.46-1.1
	c-0.01-0.57,0.17-1.12,0.25-1.68c0.05-0.33,0.03-0.66,0.03-0.98c0-0.11-0.15-0.27-0.11-0.32c0.03,0.07,0.07,0.15,0.1,0.22
	c0.22,0.51,0.42,1.03,0.6,1.55c0.19,0.57,0.33,1.15,0.52,1.72c0.09,0.28,0.28,0.54,0.35,0.83c0.18,0.72,0.33,1.46,0.5,2.25
	c0.14-0.15,0.21-0.22,0.29-0.3c0.1-0.01,0.19-0.01,0.29-0.02c-0.07,0.2-0.15,0.41-0.22,0.61c-0.03,0.1-0.09,0.2-0.08,0.29
	c0.13,1.16,0.95,2.15,0.99,3.33c0.02,0.43,0.39,0.79,0.58,1.19c0.08,0.17,0.12,0.35,0.14,0.53c0.07,0.54,0.13,1.09,0.2,1.64
	c0,0.03,0.07,0.06,0.13,0.1c0.13-0.12,0.24-0.23,0.35-0.34c0.06,0.21,0.11,0.43,0.16,0.64c-0.1,0.06-0.22,0.12-0.38,0.16
	c-0.46,0.87,1.4,0.95,1.31,1.7c0,0.01,0.09,0,0.31,0.01c-0.1-0.28-0.06-0.56-0.28-0.74c-0.56-0.47-0.67-0.97,0.17-1.66
	c0,0,0.01,0,0.01,0c0,0,0-0.01,0-0.01c0.15-0.12,0.33-0.25,0.54-0.38c-0.02,0.7-0.03,1.33-0.04,1.96c0,0.11-0.06,0.31,0.04,0.31
	c0,0,0,0,0.01,0c-0.01,0.68,0.32,1.4-0.15,1.99c-0.01,0.01-0.01,0.03,0,0.04c-0.22-0.23-0.43-0.45-0.62-0.65
	c-0.13,0.18-0.32,0.45-0.51,0.72c0.06-0.02,0.11-0.03,0.17-0.05c0.1,0.29,0.2,0.57,0.31,0.88c0.01,0,0.22-0.05,0.32-0.03
	c0.15,0.04,0.29,0.22,0.37,0.45c-0.02,0.28-0.12,0.55-0.4,0.78c-0.13,0.1-0.09,0.27-0.11,0.41c-0.02,0.13-0.03,0.27-0.05,0.4
	c-0.02,0.05-0.02,0.11-0.02,0.16c-0.05,0.42-0.1,0.84-0.18,1.26c-0.1,0.55-0.23,1.09-0.4,1.63c-0.18,0.58-0.42,1.14-0.64,1.72
	c-0.11,0.28-0.13,0.6-0.26,0.88c-0.33,0.69-0.72,1.37-1.14,2.09c0.21-0.04,0.32-0.05,0.43-0.07c0.08,0.05,0.15,0.1,0.23,0.15
	c-0.2,0.12-0.4,0.24-0.6,0.36c-0.1,0.06-0.21,0.11-0.27,0.18c-0.74,0.98-0.9,2.23-1.8,3.13c-0.33,0.33-0.36,0.83-0.56,1.24
	c-0.08,0.17-0.2,0.32-0.34,0.47c-0.4,0.43-0.83,0.85-1.25,1.27c-0.03,0.03-0.01,0.08-0.01,0.16c0.21,0,0.37,0.01,0.56,0.01
	c0.17,0.58-0.29,0.73-0.88,0.4c-1.04,0.27,0.01,1.61-0.73,2.03c-0.01,0,0.05,0.07,0.17,0.22c0.2-0.25,0.48-0.41,0.51-0.69
	c0.11-0.83,0.6-1.26,2.27-0.91c-0.63,0.49-1.21,0.91-1.79,1.33c-0.1,0.07-0.32,0.17-0.26,0.24c0.45,0.6-0.11,0.62-0.37,0.8
	c-0.16,0.11-0.55,0.13-0.52,0.29c0.16,0.75-0.9,0.5-1.02,1.02c-0.1-0.07-0.2-0.13-0.3-0.2c0.18-0.54,0.35-1.08,0.49-1.55
	c-0.23,0.02-0.59,0.05-0.95,0.08c0.05,0.03,0.09,0.06,0.14,0.09c-0.21,0.25-0.42,0.49-0.66,0.76c0,0.01,0.17,0.13,0.2,0.22
	c0.13,0.38-0.9,1.29-1.37,1.13c-0.03-0.01-0.05-0.01-0.08-0.02c0.03,0.07,0.07,0.14,0.14,0.18c0.95,0.58,1,1.84,2.16,2.29
	c0.53,0.21,0.56,0.96,0.83,1.45c0.03,0.05,0.06,0.1,0.09,0.15c0.05-0.7,0.9-0.63,1.42-0.88c-0.42,0.37-0.84,0.74-1.27,1.1
	c0.06,0.08,0.13,0.16,0.2,0.24c0.42,0.43,0.87,0.84,1.36,1.3c-1.23,0.13-1.75-0.16-1.67-0.66c-0.23-0.03-0.51-0.04-0.7-0.06
	c0.37,0.27,0.74,0.54,1.08,0.83c0.14,0.12,0.23,0.29,0.3,0.46c0.06-0.05,0.12-0.09,0.18-0.14c0.1,0.09,0.19,0.18,0.29,0.27
	c-0.11,0.08-0.22,0.15-0.33,0.23c0,0.01,0,0.01,0.01,0.02c0.09,0.23,0.13,0.49,0.19,0.74c0.07-0.04,0.14-0.08,0.21-0.11
	c0.02-0.12,0.04-0.24,0.06-0.35c0.16,0.25,0.06,0.64,0.2,0.86c0.3,0.51,0.73,0.93,1.1,1.39c0.14,0.18,0.28,0.37,0.35,0.59
	c0.04,0.13-0.06,0.35-0.1,0.52c0.27-0.13,0.54-0.25,0.99-0.46c0.3,0.39,0.59,0.8,0.91,1.21c0.06-0.01,0.12-0.03,0.18-0.04
	c-0.14-0.34-0.27-0.7-0.55-0.94c0.24,0,0.41-0.01,0.57-0.01c0.05-0.05,0.1-0.11,0.15-0.16c-0.29-0.23-0.59-0.47-0.88-0.7
	c-0.05,0.04-0.11,0.08-0.16,0.12c0.01,0.13,0.02,0.26,0.03,0.38c-0.12-0.44-0.54-0.63-0.83-0.93c-0.61,0.16-0.54-0.25-0.43-0.74
	c-0.27-0.34-0.71-0.59-1.05-0.93c0.24-0.09,0.52-0.2,0.73-0.28c-0.21-0.12-0.4-0.22-0.6-0.33c0.31-0.13,0.54-0.19,0.71-0.19
	c0.02-0.11,0.03-0.21,0.05-0.32c0.07-0.03,0.14-0.07,0.21-0.1c0.15,0.43,0.34,0.84,0.45,1.29c0.15,0.6,0.58,0.89,1.33,0.97
	c0.48,0.06,1,0.38,0.97,0.78c-0.04,0.54,0.22,0.76,0.55,0.98c0.09,0.06,0.19,0.11,0.29,0.16c0.05-0.08,0.11-0.19,0.19-0.18
	c1.12,0.17,1.28-0.75,1.89-1.15c0.11-0.07,0.01-0.37,0-0.57c-0.08-0.06-0.16-0.12-0.24-0.17c0.46-0.22,0.93-0.44,1.38-0.68
	c0.38-0.2,0.8-0.37,1.09-0.64c0.48-0.44,0.87-0.96,1.29-1.45c0.06-0.07,0.19-0.15,0.29-0.12c0.2,0.06,0.31,0.05,0.37,0.01
	c-0.13,0.22-0.31,0.44,0.39,0.91c0.3-0.49,0.57-0.97,0.84-1.45c0.1-0.17,0.14-0.39,0.3-0.5c1.01-0.67,1.12-1.96,2.29-2.57
	c0.54-0.28,0.56-1.06,0.82-1.6c0.12-0.24,0.25-0.48,0.42-0.69c0.4-0.51,0.82-1.01,1.27-1.58c-1.23,0.11-1.71,0.49-1.6,0.98
	c-0.22,0.07-0.5,0.13-0.68,0.18c0.36-0.33,0.7-0.66,1.01-1.02c0.22-0.25,0.28-0.6,0.37-0.92c0.07-0.25,0.07-0.52,0.1-0.78
	c0.07,0.02,0.15,0.05,0.22,0.07c0.03,0.11,0.06,0.23,0.1,0.34c0.13-0.29-0.02-0.65,0.09-0.91c0.23-0.57,0.58-1.09,0.85-1.64
	c0.1-0.21,0.2-0.43,0.23-0.68c0.01-0.14-0.13-0.33-0.2-0.49c0.28,0.05,0.56,0.11,1.04,0.2c0.32-0.72,0.6-1.52,0.97-2.28
	c0,0.02,0.01,0.03,0.01,0.05c-0.27,0.54-0.14,1.29-0.51,1.85c0.23-0.06,0.39-0.11,0.55-0.15c0.06,0.04,0.12,0.08,0.18,0.12
	c-0.23,0.32-0.47,0.64-0.7,0.95c-0.06-0.02-0.12-0.05-0.17-0.07c-0.01-0.13-0.03-0.26-0.04-0.39c-0.03,0.47-0.4,0.77-0.63,1.15
	c-0.61,0-0.48,0.38-0.3,0.85c-0.21,0.41-0.6,0.76-0.9,1.18c0.24,0.04,0.54,0.09,0.75,0.13c-0.19,0.17-0.37,0.31-0.55,0.46
	c0.32,0.07,0.55,0.08,0.71,0.04c0.03,0.11,0.05,0.21,0.08,0.32c0.07,0.02,0.14,0.04,0.21,0.06c0.11-0.48,0.25-0.94,0.3-1.43
	c0.07-0.65,0.44-1.04,1.15-1.33c0.46-0.19,0.9-0.66,0.8-1.07c-0.03-0.1-0.03-0.18-0.03-0.27c0.06-0.06,0.13-0.12,0.19-0.17
	c-0.07-0.04-0.12-0.07-0.17-0.12c0.06-0.23,0.18-0.41,0.34-0.6c0.18-0.22,0.42-0.41,0.55-0.64c0.08,0.05,0.11,0.12,0.13,0.16
	c0.13,0.32,0.23,0.66,0.14,1.02c-0.11,0.46-0.27,0.91,0.26,1.17c0.28-0.07,0.52-0.13,0.63-0.16c0.06-0.36,0.1-0.72,0.2-1.09
	c0.14-0.51,1.04-1.07,0.76-1.55c-0.07-0.12-0.12-0.26-0.06-0.4c0.11-0.28,0.33-0.32,0.56-0.18c-0.05-0.45-0.09-0.85-0.14-1.25
	c-0.05-0.42,0-0.86-0.19-1.24c-0.18-0.36-0.63-0.65-0.92-0.99c-0.07-0.08,0.01-0.22,0.06-0.34c0.1,0,0.21,0.01,0.36,0.03
	c-0.06-0.18-0.1-0.35-0.1-0.51c0.02-0.05,0.04-0.09,0.06-0.14c0.03-0.11,0.04-0.22,0.05-0.33c0.08-0.16,0.22-0.31,0.41-0.44
	c0.19-0.13,0.13-0.42,0.22-0.79c-0.3,0.21-0.44,0.3-0.57,0.39c-0.07-0.24,0.02-0.4,0.14-0.55
	C766.44,624.96,766.64,624.84,766.74,624.7z M763.86,556.56c0.05,0.04,0.11,0.09,0.16,0.13c-0.08,0.25-0.16,0.5-0.23,0.73
	c-0.7-0.65-0.48,0.01-0.7,0.03c-0.28,0.02-0.49,0.14-0.74,0.21c-0.07-0.07-0.13-0.13-0.2-0.2
	C762.72,557.15,763.29,556.85,763.86,556.56z M760.52,552.13c-0.23,0.47-0.4,0.85-0.58,1.25
	C759.44,552.73,759.55,552.48,760.52,552.13z M758.74,562.31c-0.13-0.38-0.2-0.72,0.34-0.72c0.15,0,0.18-0.19,0.21-0.32
	c0.07-0.32,0.11-0.67,0.24-0.96C759.94,562.01,759.77,562.47,758.74,562.31z M759.12,553.3c0.11,0.08,0.23,0.16,0.34,0.24
	c-0.18,0.24-0.36,0.49-0.55,0.75c0.11,0.08,0.26,0.18,0.53,0.37c-0.31,0.19-0.57,0.35-0.84,0.51c-0.04-0.03-0.08-0.07-0.12-0.1
	C758.68,554.49,758.89,553.89,759.12,553.3z M740.13,565.26c0.1-0.28,0.21-0.56,0.32-0.83
	C740.58,564.82,740.41,565.05,740.13,565.26z M741.1,566.07c-0.07-0.05-0.15-0.1-0.22-0.15c0.05-0.34,0.1-0.68,0.18-1.11
	c0.21,0.17,0.47,0.33,0.46,0.37C741.41,565.49,741.24,565.78,741.1,566.07z M741.12,563.04c0.1-0.2,0.2-0.4,0.31-0.59
	c0.13-0.26,0.38-0.45,0.57-0.67c0.03,0.02,0.06,0.03,0.1,0.05C741.79,562.24,741.45,562.63,741.12,563.04z M742.57,560.83
	c-0.21-0.33-0.04-0.49,0.12-0.64C742.64,560.41,742.61,560.63,742.57,560.83z M746.43,607.15c0.03,0.15,0.22,0.29,0.56,0.45
	c0.07,0.03,0.13,0.07,0.19,0.1c0.03,0.04,0.07,0.08,0.11,0.12c0.18,0.17,0.37,0.34,0.53,0.52c0.02,0.05,0.04,0.1,0.06,0.16
	C746.55,608,746.12,607.61,746.43,607.15z M749.01,613.42c-0.09-0.11-0.22-0.23-0.39-0.34c0.31-0.16-0.14-0.28-0.07-0.42
	c0.07-0.12,0.1-0.24,0.13-0.35c0.04,0,0.08,0.01,0.12,0.01c0.01-0.11,0.03-0.22,0.05-0.33c0.06-0.01,0.13-0.02,0.19-0.03
	C749.04,612.45,749.03,612.93,749.01,613.42z M747.53,629.42c0.2,0.35,0.33,0.67-0.2,0.77c-0.15,0.03-0.14,0.22-0.15,0.35
	c-0.01,0.33,0.02,0.68-0.05,0.99C746.4,629.94,746.48,629.45,747.53,629.42z M743.57,636.03c-0.06-0.03-0.12-0.07-0.18-0.1
	c0.03-0.26,0.07-0.52,0.09-0.76c0.81,0.51,0.47-0.1,0.68-0.16c0.27-0.07,0.46-0.23,0.68-0.35c0.08,0.05,0.16,0.11,0.23,0.16
	C744.58,635.24,744.08,635.64,743.57,636.03z M747.5,642.08c-0.37,0.4-0.78,0.78-1.25,1.1c-0.53,0.36-1.19,0.6-1.16,1.4
	c-0.09-0.16-0.17-0.32-0.26-0.48c-0.16,0.1-0.37,0.16-0.46,0.3c-0.06,0.09,0.07,0.31,0.12,0.5c-0.12-0.04-0.24-0.08-0.37-0.12
	c-0.05-0.6,0.06-1.03,0.82-0.98c0.1,0.01,0.2-0.06,0.22-0.13c0.21-0.89,0.76-1.49,1.64-1.88c0.24-0.11,0.39-0.32,0.58-0.48
	c0.08,0.04,0.16,0.09,0.23,0.13C747.6,641.66,747.64,641.93,747.5,642.08z M747.69,639.75c0.13-0.51,0.23-0.91,0.33-1.34
	C748.64,638.96,748.58,639.22,747.69,639.75z M748.85,638.34c-0.13-0.06-0.26-0.12-0.38-0.17c0.13-0.27,0.26-0.55,0.4-0.84
	c-0.12-0.06-0.29-0.13-0.59-0.26c0.27-0.24,0.5-0.45,0.72-0.66c0.05,0.03,0.09,0.05,0.14,0.08
	C749.05,637.09,748.96,637.71,748.85,638.34z M751.97,550.62c-0.08-0.08-0.17-0.16-0.25-0.23c0.15-0.16,0.31-0.33,0.46-0.49
	c-0.05-0.05-0.1-0.11-0.15-0.16c-0.22,0.1-0.46,0.18-0.66,0.3c-0.18,0.11-0.37,0.22-0.55,0.33c-0.13-0.87,0.71-0.96,1.11-1.41
	c0.08-0.08,0.18-0.17,0.18-0.29c0.01-0.95,0.7-1.41,1.42-1.74c0.54-0.25,0.91-0.56,1.16-1.07c0.06-0.13,0.36-0.08,0.52-0.11
	c0.15-0.19,0.31-0.37,0.46-0.57c-0.03,0.09-0.04,0.21-0.07,0.3c-0.11,0.33-0.23,0.64-0.31,0.98c-0.04,0.17,0.01,0.39,0,0.59
	c0,0.28-0.01,0.55-0.01,0.94c-0.82-0.77-1.94-0.75-2.3-0.13c0.18,0,0.33,0,0.55-0.01c-0.23,0.45-0.41,0.83-0.61,1.25
	c0.24,0.03,0.36,0.05,0.49,0.06c0.05,0.07,0.1,0.13,0.15,0.2C753.03,549.77,752.49,550.19,751.97,550.62z M756.73,641.24
	c-0.06,0.1-0.14,0.2-0.12,0.32c0.17,0.94-0.43,1.52-1.06,1.98c-0.49,0.35-0.79,0.73-0.94,1.27c-0.04,0.13-0.34,0.15-0.49,0.21
	c-0.11,0.21-0.24,0.43-0.35,0.65c0.01-0.1,0-0.21,0.01-0.31c0.05-0.34,0.11-0.67,0.12-1.02c0.01-0.18-0.08-0.38-0.12-0.58
	c-0.05-0.27-0.09-0.54-0.16-0.92c0.95,0.6,2.05,0.37,2.28-0.31c-0.18,0.04-0.32,0.07-0.54,0.11c0.14-0.49,0.25-0.89,0.37-1.34
	c-0.24,0.02-0.37,0.02-0.49,0.03c-0.06-0.05-0.13-0.11-0.19-0.16c0.45-0.51,0.9-1.02,1.33-1.54c0.1,0.06,0.19,0.12,0.29,0.18
	c-0.12,0.19-0.24,0.38-0.36,0.57c0.06,0.04,0.12,0.09,0.18,0.13c0.2-0.14,0.41-0.26,0.59-0.42c0.16-0.14,0.32-0.29,0.48-0.43
	C757.84,640.48,757.03,640.73,756.73,641.24z"
        />
        <g>
            <path
                fill={textColor}
                d="M459.76,365.9c-0.04-0.12-0.09-0.27-0.15-0.31c-0.15-0.09-0.31-0.14-0.47-0.19c-0.09-0.03-0.22-0.11-0.28-0.02
		c-0.02,0.03-0.04,0.05-0.05,0.07c-0.13-0.21-0.29-0.36-0.45-0.44c-0.35-0.37-0.68,0.01-1.01-0.13c0.01,0.05,0.01,0.08,0.02,0.12
		c-0.04,0-0.09,0-0.13,0.01c-0.04-0.08-0.07-0.17-0.12-0.26c-0.11-0.02-0.26-0.05-0.41-0.08c0,0.02,0,0.03,0,0.05
		c0.1,0.08,0.21,0.15,0.31,0.23c0,0.03-0.01,0.06-0.01,0.09c-0.12,0-0.25,0.01-0.37,0.01c-0.15-0.08-0.29-0.22-0.43-0.39
		c0.47,0.01,0.94,0.01,1.41,0.01c0.37,0,0.73-0.02,1.1-0.08c0.1-0.02,0.27-0.2,0.28-0.34c0.02-0.38,0.17-0.83-0.03-1.18
		c0.04-0.06,0.09-0.12,0.11-0.15c-0.23-0.13-0.46-0.25-0.69-0.39c-0.58-0.35-1.15-0.7-1.76-0.67c-0.43,0.03-0.87,0.02-1.3,0
		c-0.61-0.03-1.22-0.15-1.83-0.11c-0.87,0.05-1.75,0.21-2.62,0.33c-0.46,0.06-0.92,0.11-1.38,0.16c-0.02,0-0.04,0-0.07-0.01
		c-0.31-0.05-0.61-0.12-0.92-0.16c-0.26-0.03-0.52,0.02-0.77-0.05c-0.39-0.11-0.77,0.2-1.16-0.01c-0.19-0.1-0.4,0.05-0.6-0.01
		c-0.42-0.12-0.86,0.2-1.27-0.07c-0.01-0.01-0.03,0.01-0.04,0.01c-0.37-0.03-0.74,0.23-1.1-0.09c-0.06-0.06-0.15-0.03-0.22-0.03
		c-0.32,0.01-0.64,0.02-0.96,0.02c-0.05,0-0.11,0-0.16,0c-0.13-0.18-0.36-0.29-0.49-0.17c-0.18,0.17-0.31,0.12-0.47,0.05
		c-0.12-0.06-0.24-0.18-0.37-0.17c-0.35,0.01-0.69,0.09-1.04,0.11c-0.06,0-0.12-0.12-0.16-0.16c-0.06,0.04-0.12,0.08-0.18,0.11
		c-0.04-0.01-0.07-0.01-0.11-0.02c0-0.07,0-0.13-0.01-0.19c-0.07,0.07-0.12,0.12-0.17,0.16c-0.09-0.02-0.17-0.03-0.26-0.05
		c-0.01-0.05-0.02-0.1-0.02-0.16c-0.2,0.17-0.39,0.18-0.58-0.05c-0.07-0.08-0.17-0.02-0.32-0.03c0.07,0.08,0.11,0.14,0.14,0.18
		c-0.08-0.01-0.16-0.02-0.25-0.02c-0.06-0.06-0.11-0.12-0.17-0.12c-0.21,0.02-0.41,0.06-0.61,0.09c-0.16-0.02-0.31-0.04-0.47-0.1
		c-0.22-0.08-0.46,0.02-0.69,0.03c-0.1,0-0.19-0.01-0.29-0.04c-0.29-0.08-0.57-0.16-0.86-0.24c-0.02,0-0.04,0.02-0.07,0.04
		c0.02,0.04,0.04,0.08,0.06,0.11c-0.1-0.02-0.21-0.04-0.31-0.07c0-0.03,0-0.05,0-0.08c-0.15-0.16-0.3-0.11-0.44-0.01
		c0,0-0.01,0-0.01,0c-0.04-0.01-0.08,0.05-0.12,0.11c-0.03,0.02-0.06,0.05-0.08,0.07c-0.15-0.06-0.32-0.15-0.48-0.16
		c-0.17-0.01-0.34,0.05-0.52,0.19c-0.09,0.07-0.19,0.08-0.3,0.06c0.16-0.06,0.32-0.12,0.46-0.17c-0.07-0.09-0.18-0.24-0.29-0.38
		c0,0.03,0,0.06,0,0.09c-0.16-0.01-0.32-0.02-0.49-0.03c0,0-0.01,0.11-0.03,0.16c-0.12,0.18-0.77,0.06-0.84-0.18
		c-0.13-0.45-0.32-0.38-0.53-0.31c0.07,0.09,0.14,0.19,0.21,0.28c0,0.04,0,0.09,0,0.13c-0.2,0.06-0.4,0.13-0.59,0.17
		c-0.01,0-0.02-0.03-0.04-0.08c0.1-0.09,0.16-0.19,0.18-0.29c0.03-0.03,0.06-0.05,0.1-0.08c-0.03-0.01-0.05-0.01-0.08-0.02
		c0.02-0.22-0.06-0.45-0.01-0.66c0.02-0.07-0.14-0.16-0.21-0.23c-0.05,0-0.1,0.01-0.16,0.01c0.1-0.25,0.2-0.5,0.29-0.75
		c0.08-0.21,0.19-0.42,0.21-0.63c0.04-0.35,0.01-0.69,0.03-1.04c0-0.05,0.03-0.12,0.08-0.14c0.1-0.04,0.14-0.07,0.16-0.11
		c0.02,0.13,0.02,0.29,0.47,0.27c-0.05-0.3-0.09-0.6-0.13-0.89c-0.02-0.1-0.07-0.21-0.03-0.31c0.21-0.6-0.14-1.22,0.19-1.83
		c0.15-0.28-0.06-0.65-0.1-0.98c-0.02-0.15-0.02-0.3,0-0.44c0.04-0.35,0.1-0.7,0.16-1.09c-0.53,0.36-0.65,0.68-0.47,0.89
		c-0.08,0.09-0.19,0.19-0.26,0.27c0.07-0.26,0.14-0.51,0.18-0.77c0.03-0.18-0.03-0.37-0.07-0.55c-0.03-0.14-0.1-0.28-0.15-0.41
		c0.04-0.01,0.08-0.01,0.12-0.02c0.04,0.05,0.09,0.1,0.13,0.15c-0.01-0.17-0.17-0.32-0.18-0.48c-0.03-0.35,0.01-0.69,0.02-1.04
		c0-0.13,0-0.27-0.04-0.4c-0.02-0.08-0.13-0.14-0.2-0.21c0.15-0.03,0.29-0.07,0.54-0.13c0-0.46-0.03-0.96,0.01-1.45
		c0.02-0.23,0.03-0.54,0.21-0.69c0.05,0.08,0.1,0.16,0.15,0.25c-0.69,0.44-0.13,1.02-0.22,1.59c0.1-0.08,0.16-0.14,0.23-0.2
		c0.04,0.01,0.07,0.01,0.11,0.02c-0.05,0.21-0.09,0.43-0.14,0.64c-0.03,0-0.07,0-0.1,0c-0.03-0.06-0.07-0.13-0.1-0.19
		c0.09,0.25-0.02,0.49-0.05,0.73c-0.29,0.14-0.14,0.31,0.05,0.5c0,0.25-0.1,0.53-0.14,0.81c0.12-0.04,0.27-0.08,0.38-0.12
		c-0.05,0.13-0.09,0.25-0.14,0.36c0.16-0.04,0.28-0.1,0.34-0.16c0.04,0.05,0.08,0.09,0.12,0.14c0.04-0.01,0.07-0.02,0.11-0.02
		c-0.07-0.26-0.12-0.52-0.21-0.77c-0.12-0.34-0.04-0.62,0.24-0.93c0.17-0.2,0.28-0.53,0.15-0.71c-0.18-0.24-0.14-0.43-0.08-0.66
		c0.05-0.18,0.16-0.35,0.15-0.53c-0.04-0.49-0.14-0.98-0.18-1.46c-0.01-0.08,0.11-0.17,0.15-0.23c-0.07-0.14-0.13-0.26-0.2-0.39
		c0.12-0.01,0.19-0.02,0.28-0.03c-0.38-0.48-0.38-0.57,0.02-0.64c-0.19-0.27-0.21-0.54,0.01-0.82c0.08-0.1,0.01-0.24,0.01-0.46
		c-0.13,0.16-0.18,0.23-0.24,0.29c-0.07-0.12-0.04-0.22,0-0.32c0.05-0.12,0.19-0.24,0.17-0.36c-0.05-0.48-0.16-0.96-0.23-1.44
		c-0.05-0.32-0.1-0.64-0.08-0.95c0.03-0.37,0.14-0.74,0.2-1.12c0.02-0.16-0.03-0.31-0.01-0.47c0.03-0.42,0.1-0.84,0.12-1.26
		c0-0.08-0.13-0.17-0.2-0.25c0.05-0.23,0.13-0.48,0.14-0.72c0-0.25-0.08-0.48-0.23-0.72c-0.17-0.28-0.03-0.64-0.02-0.97
		c0.02-0.43,0.07-0.85,0.05-1.28c-0.01-0.39-0.1-0.77-0.13-1.16c-0.06-0.86,0.02-1.71-0.27-2.56c-0.11-0.34,0-0.71-0.01-1.07
		c0-0.15-0.05-0.3-0.09-0.45c-0.04-0.14-0.17-0.28-0.16-0.42c0.02-0.27,0.1-0.53,0.16-0.79c-0.03,0-0.05,0-0.08-0.01
		c-0.03,0.05-0.05,0.09-0.08,0.14c-0.09-0.25-0.07-0.5-0.09-0.74c-0.02-0.23-0.22-0.46,0.11-0.67c0.02-0.01,0.01-0.08-0.01-0.13
		c0.02,0.01,0.05,0.02,0.07,0.04c0.19-0.34,0.32-0.59,0.5-0.92c-0.22,0.08-0.31,0.11-0.33,0.11c-0.21-0.06-0.37-0.11-0.51-0.15
		c0.01-0.25,0.02-0.49,0.02-0.74c0.07-0.12,0.16-0.24,0.23-0.36c-0.21-0.08-0.2-0.07-0.14-0.3c0.01-0.02-0.02-0.05-0.07-0.08
		c0.03-1.13,0-2.24-0.33-3.32c-0.25-0.1-0.44-0.17-0.7-0.27c0,0.23,0.06,0.44-0.01,0.63c-0.12,0.31-0.33,0.6-0.48,0.91
		c-0.09,0.18-0.15,0.37-0.21,0.55c-0.02,0.06,0.04,0.18-0.01,0.2c-0.39,0.18-0.19,0.38-0.1,0.59c0.06,0.14,0.17,0.31,0.1,0.42
		c-0.02,0.04-0.04,0.08-0.06,0.11c-0.06,0.01-0.12,0.01-0.18,0.02c-0.05-0.67-0.12-1.33-0.15-1.99c0-0.09,0.17-0.17,0.26-0.26
		c-0.04,0-0.07,0.01-0.11,0.01c-0.1-0.48-0.2-0.97-0.3-1.45c-0.4-0.1-0.6,0.04-0.71,0.27c-0.13,0.28-0.35,0.32-0.7,0.15
		c-0.06-0.03-0.14-0.04-0.26-0.07c-0.09,0.07-0.17,0.21-0.28,0.22c-0.64,0.06-1.06,0.38-1.39,0.67c-0.39,0.35-0.55,0.79-0.49,1.26
		c0.11,0.87,0.16,1.74,0.22,2.61c0.02,0.21-0.05,0.44,0.05,0.62c0.17,0.33,0.19,0.35-0.38,0.48c-0.02-0.11-0.04-0.21-0.04-0.31
		c-0.04-0.96-0.07-1.93-0.13-2.89c-0.03-0.52-0.07-1.04-0.16-1.55c-0.02-0.14-0.22-0.38-0.35-0.38c-0.39-0.01-0.84-0.2-1.17,0.09
		c-0.06-0.06-0.12-0.12-0.16-0.15c-0.11,0.33-0.22,0.67-0.35,1c-0.31,0.83-0.62,1.66-0.54,2.53c0.06,0.61,0.08,1.22,0.09,1.84
		c0.01,0.87-0.06,1.73,0.02,2.59c0.11,1.23,0.33,2.46,0.51,3.7c0.09,0.65,0.17,1.3,0.26,1.95c0,0.03,0,0.06,0,0.09
		c-0.03,0.44-0.08,0.87-0.09,1.31c-0.01,0.36,0.06,0.73,0.01,1.09c-0.08,0.55,0.25,1.09,0.07,1.65c-0.09,0.27,0.08,0.57,0.04,0.85
		c-0.1,0.6,0.26,1.2,0.02,1.81c-0.01,0.02,0.01,0.04,0.01,0.06c0,0.52,0.28,1.04-0.02,1.56c-0.05,0.09-0.02,0.21-0.01,0.32
		c0.03,0.45,0.07,0.9,0.09,1.36c0.02,0.41,0.03,0.83,0.03,1.24c0,0.45-0.03,0.9-0.04,1.35c0,0.22,0.04,0.45,0.03,0.67
		c-0.03,0.56-0.09,1.12-0.13,1.72c0.09-0.08,0.14-0.12,0.19-0.16c0.05,0.01,0.09,0.03,0.14,0.04c-0.07,0.13-0.14,0.27-0.21,0.4
		c-0.03,0.06-0.08,0.13-0.09,0.2c-0.13,0.86,0.1,1.73-0.08,2.6c-0.06,0.32,0.06,0.65,0.08,0.98c0.01,0.14,0,0.27-0.02,0.41
		c-0.06,0.41-0.12,0.81-0.18,1.22c0,0.03,0.02,0.05,0.04,0.1c0.09-0.07,0.16-0.13,0.24-0.19c0.25,0.29,0.1,0.55-0.26,0.56
		c-0.36,0.54,0.51,0.95,0.34,1.48c0,0.01,0.04,0.02,0.15,0.06c0-0.22,0.06-0.42-0.01-0.59c-0.22-0.52-0.14-0.97,0.69-1.35
		c-0.12,0.51-0.24,0.96-0.35,1.41c-0.02,0.08-0.08,0.21-0.03,0.23c0.39,0.18,0.16,0.39,0.11,0.59c-0.03,0.12-0.18,0.28-0.11,0.36
		c0.32,0.36-0.19,0.63-0.06,0.96c-0.06,0-0.13,0.01-0.19,0.01c-0.12-0.37-0.24-0.74-0.34-1.06c-0.09,0.1-0.23,0.26-0.36,0.42
		c0.03,0,0.06,0,0.09,0c0,0.23,0,0.45,0,0.69c0,0,0.11,0,0.16,0.04c0.19,0.16,0.11,1.08-0.13,1.19c-0.44,0.21-0.36,0.48-0.27,0.77
		c0.09-0.1,0.18-0.21,0.26-0.31c0.04,0,0.09,0,0.13-0.01c0.06,0.24,0.13,0.47,0.18,0.71c-0.1,0-0.2,0.01-0.3,0.01
		c-0.04-0.07-0.08-0.13-0.13-0.21c-0.01,0.07-0.02,0.15-0.03,0.22c-0.3,0.03-0.6,0.09-0.9,0.2c-0.08,0.25-0.13,0.43-0.21,0.68
		c0.16,0.01,0.31-0.04,0.44,0.04c0.21,0.13,0.41,0.36,0.62,0.52c0.04,0.03,0.08,0.06,0.12,0.08c-0.05,0.16-0.05,0.34-0.13,0.5
		c-0.03,0-0.06,0-0.09,0c-0.06,0-0.12-0.18-0.18-0.27c0,0.04,0,0.07,0,0.11c-0.35,0.08-0.69,0.16-1.03,0.23
		c-0.08,0.39,0.01,0.6,0.16,0.72c0.19,0.15,0.22,0.37,0.08,0.7c-0.02,0.06-0.03,0.14-0.06,0.26c0.05,0.1,0.14,0.18,0.14,0.29
		c0.01,0.3,0.06,0.56,0.14,0.78c-0.26-0.06-0.51-0.12-0.76-0.19c0.05,0.17,0.08,0.31,0.13,0.42c0.04,0.12,0.09,0.27,0.15,0.31
		c0.15,0.09,0.31,0.14,0.47,0.19c0.09,0.03,0.22,0.11,0.28,0.02c0.02-0.03,0.04-0.05,0.05-0.07c0.13,0.21,0.29,0.36,0.45,0.44
		c0.19,0.2,0.37,0.18,0.55,0.15c0.05,0.07,0.1,0.13,0.1,0.2c0,0.01,0,0.02,0,0.02c-0.33,0.01-0.66,0.02-0.99,0.08
		c-0.1,0.02-0.27,0.2-0.28,0.34c-0.02,0.38-0.17,0.83,0.03,1.18c-0.04,0.06-0.09,0.12-0.11,0.15c0.22,0.13,0.45,0.25,0.67,0.38
		c-0.01,0.06-0.01,0.11-0.01,0.17c0,0.02,0,0.04,0,0.06c-0.04-0.03-0.08-0.07-0.13-0.1c-0.01,0.03-0.02,0.06-0.03,0.1
		c0.05,0.09,0.1,0.18,0.15,0.28c0,0.06,0.01,0.12,0.03,0.18c-0.21,0.07-0.31-0.36-0.48-0.42c0.03,0.15,0.08,0.28,0.14,0.36
		c0.2,0.27,0.4,0.52,0.59,0.8c0.06,0.08,0.09,0.25,0.13,0.38c-0.01,0.04-0.03,0.08-0.04,0.12c0.13,0.05,0.25,0.12,0.38,0.16
		c0,0.02,0,0.03-0.01,0.05c0.06,0,0.12,0,0.18,0.01c0-0.01,0-0.02,0.01-0.03c0.57,0.01,1.13-0.06,1.7-0.03
		c0.57,0.03,1.13-0.49,1.7-0.23c0.01,0,0.02-0.04,0.06-0.14c-0.15-0.03-0.28-0.06-0.41-0.08c0-0.03,0-0.05,0-0.08
		c0.2-0.09,0.4-0.17,0.61-0.26c0,0,0,0.01,0,0.01c-0.06,0.21-0.24,0.38-0.04,0.61c0.1,0.11-0.04,0.31-0.09,0.56
		c0.37,0.15-0.23,0.37-0.16,0.61c0.19-0.14,0.37-0.26,0.52-0.38c-0.26-0.36,0.02-0.72,0.12-1.17c0.06,0.15,0.09,0.21,0.12,0.28
		c0.01-0.01,0.02-0.01,0.03-0.01c0,0,0,0,0,0.01c0.06-0.01,0.12-0.02,0.19-0.03c0.13,0.03,0.15,0.16,0.15,0.22
		c0.01,0.26,0,0.52-0.1,0.77c-0.13,0.31-0.28,0.61-0.07,0.9c0.15,0,0.27,0,0.33,0c0.09-0.25,0.17-0.5,0.28-0.75
		c0.15-0.34,0.67-0.58,0.62-0.98c-0.01-0.1-0.01-0.21,0.04-0.3c0.04-0.07,0.08-0.11,0.12-0.13c-0.01,0.03-0.02,0.05-0.03,0.08
		c0.05-0.02,0.09-0.04,0.13-0.07c0.03,0.02,0.05,0.05,0.08,0.09c0.01-0.05,0.01-0.09,0.02-0.13c0.01,0,0.02-0.01,0.02-0.01
		c-0.01,0.06-0.02,0.12-0.03,0.17c0-0.01-0.01-0.02-0.02-0.03c0,0.02-0.01,0.04-0.01,0.06c0.01-0.01,0.02-0.01,0.02-0.02
		c-0.07,0.42-0.14,0.83-0.2,1.25c-0.1,0.67-0.17,1.35-0.26,2c0.17-0.08,0.3-0.13,0.41-0.2c0.11-0.07,0.27-0.14,0.3-0.23
		c0.08-0.22,0.12-0.45,0.15-0.67c0.02-0.14,0.1-0.32,0-0.4c-0.46-0.39-0.03-0.73,0.08-1.09c0.05,0.13,0.1,0.26,0.17,0.46
		c0.33-0.38,0.22-0.73,0.15-1.07c0.09,0.01,0.17,0.01,0.26,0.02c0.13,0.13,0.25,0.07,0.4-0.17c0.23-0.36,0.45-0.37,0.7-0.37
		c0.62-0.01,1.24-0.02,1.86-0.04c0-0.05,0.01-0.1,0.01-0.16c-0.22-0.04-0.43-0.13-0.65-0.12c-0.54,0.01-1.07,0.06-1.61,0.08
		c-0.1,0-0.21,0.03-0.28-0.07c-0.2-0.24-0.38-0.14-0.57,0c0.01-0.12,0.03-0.27,0.04-0.43c0.38-0.05,0.76-0.09,1.14-0.14
		c0.02,0,0.04,0,0.07,0.01c0.31,0.05,0.61,0.12,0.92,0.16c0.26,0.03,0.52-0.02,0.77,0.05c0.39,0.11,0.77-0.2,1.16,0.01
		c0.19,0.1,0.4-0.05,0.6,0.01c0.42,0.12,0.86-0.2,1.27,0.07c0.01,0.01,0.03-0.01,0.04-0.01c0.37,0.03,0.74-0.23,1.1,0.09
		c0.06,0.06,0.15,0.03,0.22,0.03c0.32-0.01,0.64-0.02,0.96-0.02c0.05,0,0.11,0,0.16,0c0.13,0.18,0.36,0.29,0.49,0.17
		c0.18-0.17,0.31-0.12,0.47-0.05c0.12,0.06,0.24,0.18,0.37,0.17c0.35-0.01,0.69-0.09,1.04-0.11c0.06,0,0.12,0.12,0.16,0.16
		c0.06-0.04,0.12-0.08,0.18-0.11c0.04,0.01,0.07,0.01,0.11,0.02c0,0.07,0,0.13,0.01,0.19c0.07-0.07,0.12-0.12,0.17-0.16
		c0.09,0.02,0.17,0.03,0.26,0.05c0.01,0.05,0.02,0.1,0.02,0.16c0.2-0.17,0.39-0.18,0.58,0.05c0.07,0.08,0.17,0.02,0.32,0.03
		c-0.07-0.08-0.11-0.14-0.14-0.18c0.08,0.01,0.16,0.02,0.25,0.02c0.06,0.06,0.11,0.12,0.17,0.12c0.21-0.02,0.41-0.06,0.62-0.09
		c0.16,0.02,0.31,0.04,0.47,0.1c0.22,0.08,0.46-0.02,0.69-0.03c0.1,0,0.19,0.01,0.29,0.04c0.29,0.08,0.57,0.16,0.86,0.24
		c0.02,0,0.04-0.02,0.07-0.04c-0.02-0.04-0.04-0.08-0.06-0.11c0.1,0.02,0.21,0.04,0.31,0.07c0,0.03,0,0.05,0,0.08
		c0.15,0.16,0.3,0.11,0.44,0.01c0,0,0.01,0,0.01,0c0.04,0.01,0.08-0.05,0.12-0.11c0.03-0.02,0.06-0.05,0.08-0.07
		c0.15,0.06,0.32,0.15,0.48,0.16c0.17,0.01,0.34-0.05,0.52-0.19c0.09-0.07,0.19-0.08,0.3-0.06c-0.16,0.06-0.32,0.12-0.46,0.17
		c0.07,0.09,0.18,0.24,0.29,0.38c0-0.03,0-0.06,0-0.09c0.16,0.01,0.32,0.02,0.49,0.03c0,0,0.01-0.11,0.03-0.16
		c0.12-0.18,0.77-0.06,0.84,0.18c0.13,0.45,0.32,0.38,0.53,0.31c-0.07-0.09-0.14-0.19-0.21-0.28c0-0.04,0-0.09,0-0.13
		c0.2-0.06,0.4-0.13,0.59-0.17c0.02,0,0.04,0.08,0.07,0.2c-0.1,0.08-0.2,0.16-0.31,0.25c0.3,0.08,0.57,0.15,0.84,0.22
		c0.01-0.05,0.01-0.09,0.02-0.14c-0.13-0.21-0.26-0.43-0.39-0.65c0.28,0,0.56,0.02,0.83,0.01c0.12,0.07,0.21,0.16,0.22,0.25
		c0.27-0.07,0.56-0.33,0.79-0.18c0.29,0.19,0.58,0.18,0.87,0.23c0.14,0.02,0.27,0.19,0.4,0.23c0.08,0.03,0.18-0.04,0.27-0.07
		c0.14-0.02,0.28,0.13,0.42,0.14c0.11,0.01,0.23-0.26,0.34-0.24c0.29,0.05,0.57,0.18,0.79,0.66c-0.15,0.07-0.29,0.14-0.43,0.2
		c0.18,0.04,0.34,0.1,0.53-0.07c0.2-0.18,0.48,0,0.72,0.02c0.23,0.02,0.46,0.14,0.71-0.39c-0.13-0.04-0.21-0.09-0.29-0.09
		c-0.22,0.02-0.47,0.29-0.62-0.28c-0.01-0.05-0.07-0.05-0.1-0.06c-0.12-0.03-0.24-0.06-0.36-0.09c-0.03-0.13-0.04-0.19-0.04-0.2
		c0.05-0.2,0.09-0.36,0.12-0.5c0.17,0.02,0.35,0.04,0.52,0.06c0.08,0.07,0.16,0.17,0.25,0.25c0.06-0.2,0.06-0.19,0.21-0.13
		c0.01,0.01,0.04-0.02,0.06-0.06c0.79,0.08,1.58,0.1,2.35-0.17c0.08-0.25,0.13-0.43,0.21-0.68c-0.16-0.01-0.31,0.04-0.44-0.04
		c-0.21-0.13-0.41-0.36-0.62-0.52c-0.12-0.1-0.25-0.16-0.38-0.23c-0.05-0.02-0.12,0.03-0.14-0.02c-0.11-0.4-0.26-0.2-0.41-0.12
		c-0.1,0.05-0.22,0.15-0.3,0.08c-0.03-0.02-0.05-0.04-0.08-0.06c0-0.06,0-0.12-0.01-0.18c0.47-0.02,0.94-0.05,1.41-0.05
		c0.06,0,0.12,0.18,0.18,0.27c0-0.04,0-0.07,0-0.11c0.35-0.08,0.69-0.16,1.03-0.23c0.08-0.39-0.01-0.6-0.16-0.72
		c-0.19-0.15-0.22-0.37-0.08-0.7c0.02-0.06,0.03-0.14,0.06-0.26c-0.05-0.1-0.14-0.18-0.14-0.29c-0.01-0.31-0.06-0.56-0.14-0.78
		c0.26,0.06,0.51,0.12,0.76,0.19C459.83,366.15,459.8,366.01,459.76,365.9z M431.25,345.88c-0.03-0.12-0.07-0.24-0.1-0.35
		C431.33,345.64,431.29,345.77,431.25,345.88z M431.32,342.64c0.01,0.17,0.01,0.34,0.01,0.51
		C431.2,342.98,431.23,342.82,431.32,342.64z M431.31,344.03c0,0.13-0.01,0.25-0.01,0.38c0,0.16-0.07,0.33-0.11,0.49
		c-0.02,0-0.04,0-0.06,0C431.18,344.61,431.25,344.32,431.31,344.03z M431.23,326.16c-0.04-0.01-0.08-0.03-0.1-0.05
		C431.16,326.13,431.19,326.15,431.23,326.16z M430.71,342.45c0.04,0.01,0.09,0.02,0.13,0.03c0.04,0.19,0.08,0.38,0.13,0.63
		c-0.13-0.03-0.29-0.06-0.29-0.08C430.67,342.83,430.69,342.64,430.71,342.45z M423.57,355.4c-0.04,0-0.07,0-0.11,0
		c-0.06-0.17-0.13-0.33-0.19-0.49c0.51,0.03,0.18-0.22,0.25-0.33c0.1-0.14,0.14-0.29,0.2-0.44c0.05,0.01,0.1,0.01,0.15,0.02
		C423.77,354.58,423.67,354.99,423.57,355.4z M423.63,350.01c0.17,0.18,0.31,0.35,0.08,0.56c-0.06,0.06-0.02,0.18,0.01,0.27
		c0.07,0.22,0.17,0.43,0.21,0.65C423.21,350.69,423.14,350.32,423.63,350.01z M455.38,364.93c0.03,0.02,0.06,0.04,0.09,0.05
		c0.07,0.02,0.13,0.06,0.2,0.09C455.48,365.19,455.43,365.21,455.38,364.93z M455.19,370.84c-0.01,0.03-0.03,0.07-0.04,0.1
		C455.16,370.9,455.17,370.86,455.19,370.84z M455.95,364.64c0.01,0.03,0.02,0.06,0.03,0.1c-0.25,0.1-0.42,0.06-0.51-0.09
		c0.03,0,0.06-0.01,0.08-0.01C455.69,364.64,455.82,364.64,455.95,364.64z M425.35,367.48c-0.07-0.02-0.13-0.06-0.2-0.09
		c0.19-0.12,0.23-0.15,0.28,0.14C425.4,367.51,425.38,367.49,425.35,367.48z M425.66,360.64c-0.06-0.04-0.12-0.1-0.18-0.17
		c0.07-0.14,0.17-0.28,0.22-0.42C425.68,360.25,425.67,360.45,425.66,360.64z M422.95,363.82c-0.13,0.01-0.26,0.02-0.38,0.03
		c0.02-0.07,0.03-0.13,0.05-0.2c0.11,0.01,0.23,0.02,0.34,0.03C422.95,363.72,422.95,363.77,422.95,363.82z M424.43,367.61
		c0.06,0.06,0.13,0.13,0.19,0.21c-0.07,0-0.14,0-0.22,0C424.42,367.74,424.43,367.67,424.43,367.61z M424.86,367.81
		c-0.01-0.03-0.02-0.06-0.03-0.1c0.25-0.1,0.42-0.06,0.51,0.09c-0.03,0-0.06,0.01-0.08,0.01
		C425.12,367.82,424.99,367.82,424.86,367.81z M442.54,370.16c-0.07-0.01-0.14-0.02-0.21-0.02c0.02-0.02,0.04-0.03,0.05-0.04
		C442.44,370.12,442.49,370.14,442.54,370.16z M438.27,362.29c0.07,0.01,0.14,0.02,0.21,0.02c-0.02,0.02-0.04,0.03-0.05,0.04
		C438.37,362.33,438.32,362.31,438.27,362.29z M430.48,361.17c0,0,0,0.01-0.01,0.01c0,0,0-0.01,0-0.01
		C430.47,361.16,430.48,361.17,430.48,361.17z M430.9,354.88c-0.07,0.07-0.12,0.12-0.2,0.21c-0.09-0.27-0.16-0.49-0.25-0.74
		c-0.1,0.08-0.15,0.12-0.21,0.16c-0.04-0.01-0.09-0.02-0.13-0.02c0.05-0.37,0.1-0.75,0.15-1.12c0.06,0,0.12,0.01,0.18,0.01
		c0,0.12,0,0.25,0,0.37c0.04,0,0.08,0.01,0.12,0.01c0.05-0.12,0.11-0.24,0.14-0.37c0.03-0.11,0.06-0.23,0.09-0.34
		c0.36,0.32,0.07,0.66,0.08,0.99c0,0.06,0,0.14,0.04,0.19c0.35,0.39,0.27,0.83,0.14,1.22c-0.1,0.3-0.11,0.57,0.01,0.85
		c0.03,0.07-0.09,0.17-0.14,0.24c0.02,0.13,0.04,0.26,0.07,0.39c-0.03-0.05-0.07-0.09-0.1-0.14c-0.09-0.16-0.18-0.33-0.29-0.49
		c-0.06-0.08-0.16-0.15-0.24-0.23c-0.11-0.11-0.21-0.22-0.36-0.38C430.61,355.69,431.01,355.26,430.9,354.88z M428.63,320.46
		c0.09,0.13,0.24,0.26,0.25,0.4c0.03,0.52,0.01,1.05,0.01,1.57c-0.05,0-0.1,0-0.15,0c-0.06-0.64-0.12-1.27-0.19-1.91
		C428.58,320.5,428.6,320.48,428.63,320.46z M426.88,356.97c0.05,0,0.1-0.01,0.14-0.01c0.05,0.12,0.16,0.24,0.15,0.36
		c-0.02,0.33-0.06,0.65-0.14,0.97c-0.09,0.37-0.27,0.72,0.04,1.08c-0.09-0.04-0.18-0.09-0.28-0.13c-0.03,0.1-0.09,0.21-0.07,0.31
		c0.01,0.06,0.14,0.12,0.23,0.19c-0.06,0.03-0.13,0.05-0.19,0.08c-0.24-0.26-0.36-0.51-0.03-0.77c0.04-0.03,0.06-0.1,0.04-0.14
		c-0.23-0.5-0.22-1,0.02-1.5C426.87,357.27,426.86,357.11,426.88,356.97z M426.5,356.06c-0.1-0.31-0.19-0.56-0.27-0.82
		C426.66,355.33,426.71,355.49,426.5,356.06z M426.06,353.83c0.03,0,0.06,0,0.08,0c0.14,0.37,0.28,0.74,0.42,1.11
		c-0.07,0.01-0.15,0.02-0.22,0.03c-0.02-0.19-0.05-0.38-0.07-0.59c-0.07,0.01-0.17,0.02-0.34,0.04
		C425.98,354.2,426.02,354.01,426.06,353.83z M422.75,363.06c0.05,0.03,0.1,0.06,0.15,0.08c0.05,0.02,0.12-0.03,0.14,0.02
		c0.02,0.08,0.04,0.13,0.07,0.16c-0.03,0.03-0.06,0.05-0.09,0.06c0.01,0.03,0.02,0.06,0.03,0.09c-0.13,0.01-0.26,0.01-0.39,0.01
		C422.68,363.35,422.71,363.21,422.75,363.06z M421.55,363.84c0.1-0.08,0.19-0.23,0.29-0.23c0.19,0,0.39,0.01,0.58,0.02
		c-0.08,0.09-0.2,0.17-0.4,0.23c0.1,0,0.2,0,0.33,0c-0.25,0.02-0.51,0.03-0.76,0.05C421.58,363.89,421.57,363.86,421.55,363.84z
		 M422.88,370.91c0.02-0.1,0.04-0.17,0.03-0.24c-0.02-0.17-0.11-0.35-0.1-0.52c0.2,0.12,0.41,0.23,0.62,0.31c-0.03,0-0.05,0-0.07,0
		c-0.03,0.22-0.06,0.43-0.09,0.64C423.14,371.04,423.01,370.98,422.88,370.91z M425.26,371.23c0,0.06,0,0.12,0,0.19
		c0.1,0.01,0.2,0.01,0.31,0.02c0,0.05,0,0.1,0,0.15c-0.22,0.02-0.43,0.08-0.65,0.06c-0.12-0.01-0.24-0.16-0.37-0.23
		c-0.17-0.1-0.34-0.2-0.51-0.28c-0.03-0.02-0.08,0.07-0.12,0.11c-0.04,0.04-0.09,0.11-0.12,0.09c-0.1-0.04-0.2-0.09-0.3-0.13
		c0.05-0.22,0.1-0.45,0.14-0.67c0.18,0.05,0.37,0.08,0.56,0.07c0.43-0.03,0.87-0.02,1.3,0c0.53,0.02,1.05,0.11,1.58,0.11
		c-0.01,0.1-0.02,0.2-0.05,0.3C426.44,371.17,425.86,371.66,425.26,371.23z M429.28,371.13c0.03-0.18,0.06-0.36,0.08-0.54
		c0.01-0.04,0.01-0.08,0.02-0.12c0.02,0,0.04-0.01,0.06-0.01c-0.01,0.03-0.03,0.05-0.03,0.08c-0.03,0.2-0.07,0.39-0.1,0.59
		C429.3,371.12,429.29,371.13,429.28,371.13z M430.02,371.41c-0.01-0.09,0-0.18,0.02-0.28c-0.14,0.04-0.2,0.05-0.27,0.07
		c-0.02-0.02-0.05-0.04-0.07-0.06c0.01-0.01,0.03-0.01,0.04-0.02c-0.02,0-0.03,0-0.04-0.01c0.09-0.06,0.18-0.12,0.27-0.19
		c0.08,0.21,0.14,0.38,0.21,0.5C430.12,371.43,430.07,371.42,430.02,371.41z M429.97,370.67c-0.06-0.01-0.12-0.02-0.17-0.03
		c-0.01-0.08-0.02-0.15-0.01-0.22c0.06-0.01,0.12-0.02,0.18-0.02c0.05-0.01,0.1-0.01,0.14-0.02
		C430.06,370.47,430.01,370.57,429.97,370.67z M450.33,371.29c0,0,0-0.01,0.01-0.01c0,0,0,0.01,0,0.01
		C450.34,371.29,450.34,371.29,450.33,371.29z M455.04,371.08c0.02,0,0.04-0.01,0.06-0.02c-0.01,0.02-0.02,0.05-0.03,0.07
		c0.04,0.03,0.07,0.06,0.11,0.1c-0.01,0.04-0.01,0.07-0.02,0.11C455.12,371.24,455.08,371.16,455.04,371.08z M455.47,371.49
		c-0.03-0.01-0.05-0.04-0.07-0.07c0.03,0.02,0.06,0.05,0.08,0.07C455.48,371.49,455.48,371.49,455.47,371.49z M459.26,368.62
		c-0.1,0.08-0.19,0.23-0.29,0.23c-0.37,0-0.74-0.04-1.11-0.06c0-0.05,0-0.1,0.01-0.15c0.45-0.03,0.9-0.06,1.35-0.09
		C459.23,368.57,459.25,368.59,459.26,368.62z"
            />
            <path
                fill={textColor}
                d="M478.6,323.54c0.07,0,0.14-0.18,0.2-0.28c0,0.04,0,0.07,0,0.11c0.42,0.06,0.84,0.13,1.25,0.19
		c0.12,0.39,0.01,0.6-0.17,0.73c-0.23,0.15-0.25,0.38-0.07,0.71c0.03,0.06,0.04,0.14,0.08,0.25c-0.06,0.1-0.16,0.19-0.16,0.3
		c0,0.65-0.24,1.09-0.47,1.43c-0.13,0.21-0.29,0.36-0.47,0.46c0.55,0.05,1.09,0.1,1.64,0.14c0.27,0.02,0.54,0.03,0.82,0.04
		c-0.07,0.36-0.07,0.29-1.07,0.52c-0.31,0.07-0.58-0.07-0.9-0.16c0.1,0.07,0.2,0.17,0.21,0.26c0.04,0.38,0.24,0.82,0.01,1.18
		c0.06,0.06,0.11,0.11,0.14,0.14c-0.22,0.11-0.43,0.21-0.65,0.32c0.2,0.07,0.41,0.21,0.61,0.28c0.08-0.29,1-0.41,1.44-0.22
		c-0.23,0.25-0.44,0.48-0.66,0.71c0,0.05,0.01,0.09,0.01,0.14c0.34-0.03,0.69-0.07,1.07-0.11c-0.13-0.1-0.24-0.19-0.36-0.28
		c0.05-0.11,0.09-0.19,0.11-0.19c0.24,0.06,0.48,0.16,0.72,0.24c0,0.04-0.01,0.09-0.01,0.13c-0.1,0.08-0.2,0.17-0.29,0.25
		c0.25,0.1,0.49,0.2,0.69-0.24c0.11-0.24,0.93-0.28,1.06-0.08c0.03,0.05,0.03,0.16,0.03,0.16c0.21,0.01,0.41,0.02,0.61,0.02
		c0,0.03,0,0.06-0.01,0.09c0.15-0.13,0.3-0.26,0.39-0.35c-0.28-0.11-0.6-0.25-0.92-0.38c0.01-0.06,0.01-0.13,0.02-0.19
		c0.28,0.15,0.55-0.36,0.85-0.03c0.06,0.07,0.21-0.08,0.32-0.1c0.18-0.04,0.38-0.26,0.52,0.13c0.02,0.05,0.14-0.01,0.21-0.03
		c0.4-0.1,0.81-0.2,1.25-0.31c-0.36,0.82-0.76,0.89-1.21,0.65c-0.15-0.08-0.33-0.02-0.52-0.03c0.03,0.1,0.04,0.15,0.05,0.15
		c0.47-0.15,0.79,0.73,1.28,0.38c0.02-0.35,0.26-0.5,0.5-0.24c-0.06,0.08-0.11,0.15-0.18,0.24c0.04,0.02,0.06,0.05,0.09,0.05
		c0.36-0.05,0.72-0.1,1.08-0.15c0.12-0.02,0.24-0.02,0.36-0.01c0.29,0.03,0.57,0.16,0.86,0.1c0.77-0.16,1.53,0.08,2.29-0.05
		c0.06-0.01,0.12-0.05,0.17-0.09c0.12-0.07,0.24-0.14,0.36-0.21c0.01,0.05,0.02,0.09,0.03,0.14c-0.04,0.05-0.07,0.09-0.15,0.19
		c0.54-0.04,1.03-0.1,1.52-0.13c0.2-0.01,0.39,0.03,0.59,0.03c0.39-0.01,0.79-0.04,1.18-0.04c0.36,0,0.72,0,1.09,0.02
		c0.4,0.02,0.79,0.05,1.19,0.07c0.09,0,0.2,0.04,0.28-0.02c0.46-0.31,0.91-0.03,1.37-0.04c0.02,0,0.04,0.02,0.06,0.01
		c0.52-0.25,1.06,0.09,1.59-0.02c0.24-0.05,0.51,0.11,0.74,0.01c0.49-0.2,0.96,0.12,1.45,0.02c0.31-0.06,0.64,0,0.95-0.03
		c0.38-0.03,0.76-0.09,1.14-0.14c0.03,0,0.05-0.01,0.08-0.01c0.57,0.06,1.15,0.12,1.72,0.18c1.09,0.12,2.17,0.27,3.26,0.32
		c0.76,0.03,1.52-0.1,2.28-0.14c0.54-0.03,1.08-0.04,1.61-0.03c0.77,0.02,1.47-0.36,2.17-0.73c0.28-0.15,0.56-0.28,0.85-0.42
		c-0.03-0.03-0.09-0.09-0.14-0.14c0.23-0.36,0.03-0.79-0.01-1.18c-0.01-0.13-0.23-0.31-0.36-0.32c-0.45-0.05-0.9-0.05-1.35-0.04
		c-0.84,0.02-1.67,0.05-2.51,0.07c-0.09,0-0.18-0.02-0.27-0.02c0.09-0.57,0.1-0.55,0.4-0.41c0.17,0.08,0.36,0.01,0.54,0.01
		c0.76,0.01,1.51-0.01,2.27,0.04c0.41,0.03,0.78-0.17,1.05-0.58c0.23-0.35,0.47-0.79,0.47-1.43c0-0.11,0.11-0.2,0.16-0.3
		c-0.03-0.12-0.05-0.2-0.08-0.25c-0.18-0.33-0.16-0.55,0.07-0.71c0.18-0.12,0.29-0.34,0.17-0.73c-0.41-0.06-0.83-0.12-1.25-0.19
		c0-0.04,0-0.07,0-0.11c-0.07,0.1-0.13,0.28-0.2,0.28c-0.56,0.02-1.13,0.01-1.7,0c0-0.06,0-0.12,0-0.18
		c0.03-0.02,0.06-0.04,0.09-0.06c0.09-0.07,0.24,0.02,0.36,0.07c0.18,0.08,0.37,0.27,0.49-0.14c0.01-0.05,0.11,0,0.17-0.03
		c0.15-0.07,0.31-0.14,0.45-0.25c0.25-0.17,0.48-0.41,0.73-0.55c0.15-0.09,0.34-0.04,0.53-0.06c-0.1-0.25-0.18-0.43-0.28-0.67
		c-0.94-0.24-1.88-0.19-2.83-0.09c-0.03-0.04-0.06-0.07-0.07-0.06c-0.18,0.07-0.18,0.08-0.26-0.12c-0.1,0.08-0.19,0.18-0.29,0.25
		c-0.21,0.03-0.42,0.05-0.62,0.07c-0.04-0.14-0.09-0.3-0.15-0.5c0-0.02,0.03-0.11,0.08-0.33c-0.26,0.2-0.47,0.35-0.75,0.56
		c0.01,0.02,0.02,0.05,0.03,0.07c-0.04-0.02-0.1-0.03-0.11,0c-0.15,0.34-0.37,0.16-0.56,0.15c-0.2-0.01-0.41,0.02-0.63-0.05
		c0.04-0.03,0.08-0.06,0.11-0.09c0-0.03-0.01-0.05-0.01-0.08c-0.22,0.07-0.44,0.17-0.66,0.2c-0.12,0.02-0.24-0.1-0.37-0.14
		c-0.13-0.04-0.26-0.07-0.39-0.07c-0.3,0.01-0.61,0.14-0.91,0.04c-0.73-0.24-1.45-0.13-2.18-0.15c-0.33-0.01-0.66-0.09-0.98-0.08
		c-0.36,0-0.72,0.07-1.08,0.1c-0.28,0.02-0.58,0.18-0.82,0.02c-0.21-0.15-0.41-0.21-0.62-0.21c-0.21,0.01-0.41,0.1-0.61,0.16
		c-0.07-0.06-0.14-0.2-0.21-0.19c-0.36,0.03-0.71,0.1-1.07,0.15c-0.13,0.02-0.27-0.03-0.4,0c-0.32,0.06-0.63,0.19-0.94,0.22
		c-0.27,0.03-0.54-0.02-0.81-0.06c-0.41-0.06-0.81-0.16-1.22-0.21c-0.1-0.01-0.2,0.12-0.3,0.18c-0.08,0.05-0.17,0.07-0.27,0.01
		c0.06-0.06,0.12-0.11,0.25-0.24c-0.18,0-0.31-0.06-0.39,0.02c-0.24,0.22-0.46,0.21-0.69,0.02c-0.06,0.4-0.14,0.41-0.54,0.03
		c-0.01,0.09-0.01,0.16-0.02,0.28c-0.11-0.06-0.21-0.13-0.33-0.2c-0.05,0.04-0.13,0.16-0.2,0.15c-0.41-0.05-0.82-0.14-1.24-0.18
		c-0.15-0.01-0.3,0.1-0.45,0.15c-0.19,0.06-0.35,0.1-0.56-0.08c-0.15-0.13-0.44-0.03-0.61,0.14c-0.27,0.27-0.51,0.36-0.79,0.23
		c-0.21-0.09-0.43-0.15-0.65-0.22c-0.01,0.04-0.02,0.07-0.02,0.11c0.04,0.04,0.07,0.08,0.11,0.12c-0.05,0.06-0.1,0.17-0.14,0.34
		c0.1-0.04,0.2-0.09,0.31-0.13c-0.03,0.11-0.07,0.25-0.11,0.38c0.24-0.03,0.47-0.13,0.69-0.13c0.16,0.2,0.3,0.34,0.43,0.06
		c0.21-0.02,0.42-0.13,0.62-0.04c-0.05-0.04-0.11-0.07-0.16-0.11c0-0.03,0-0.07,0-0.1c0.18-0.04,0.36-0.09,0.55-0.13
		c0.01,0.04,0.01,0.07,0.02,0.11c-0.05,0.07-0.1,0.13-0.17,0.23c0.49-0.09,0.97,0.47,1.35-0.22c0.08,0.05,0.14,0.1,0.21,0.15
		c-0.13,0.17-0.39,0.19-0.59,0.21c-0.42,0.04-0.84,0.01-1.23,0.01c-0.05,0.24-0.09,0.39-0.12,0.53c-0.06-0.07-0.11-0.18-0.18-0.2
		c-0.11-0.04-0.23-0.04-0.34-0.04c-0.3,0-0.59,0.04-0.89,0.01c-0.13-0.02-0.25-0.17-0.4-0.19c0.04,0.04,0.08,0.09,0.12,0.13
		c-0.01,0.04-0.01,0.08-0.02,0.12c-0.12-0.05-0.23-0.12-0.35-0.15c-0.15-0.04-0.31-0.11-0.47-0.08c-0.22,0.04-0.44,0.11-0.66,0.17
		c0.07-0.07,0.16-0.18,0.24-0.25c0.17,0.19,0.45,0.07,0.77-0.46c-0.33,0.05-0.63,0.1-0.93,0.14c-0.13,0.02-0.25,0.01-0.38-0.01
		c-0.28-0.04-0.58-0.26-0.83-0.12c-0.53,0.32-1.05-0.04-1.57,0.15c-0.08,0.03-0.17-0.02-0.26-0.04c-0.25-0.05-0.49-0.1-0.75-0.16
		c-0.04,0.45,0.1,0.45,0.21,0.47c-0.03,0.01-0.07,0.05-0.1,0.15c-0.02,0.05-0.08,0.08-0.13,0.08c-0.3,0-0.59-0.03-0.89-0.01
		c-0.18,0.02-0.37,0.12-0.55,0.19c-0.22,0.08-0.44,0.18-0.66,0.27c0.01-0.05,0.01-0.1,0.02-0.16c-0.06-0.08-0.13-0.23-0.19-0.22
		c-0.32,0.07-0.66-0.24-0.97,0.24c-0.02,0.04-0.09,0.01-0.13-0.01c-0.23-0.1-0.46-0.2-0.69-0.3c-0.01,0.05-0.03,0.1-0.04,0.15
		c0.04,0.05,0.07,0.1,0.14,0.19c-0.22,0.13-0.43,0.3-0.63,0.34c-0.19,0.04-0.39-0.07-0.64-0.12c-0.1-0.32-0.1-0.32-0.24-0.32
		c0,0.08,0,0.16,0,0.24c-0.43-0.04-0.84-0.2-1.32-0.08c0-0.02-0.02-0.07-0.05-0.09c-0.01,0-0.01,0.01-0.02,0.01
		c-0.15,0.1-0.3,0.17-0.45,0.25c-0.05,0.03-0.15-0.02-0.17,0.03c-0.12,0.4-0.3,0.22-0.48,0.14c-0.05,0.06-0.09,0.11-0.14,0.12
		c-0.07,0.02-0.14,0.03-0.22,0.04C477.54,323.55,478.07,323.56,478.6,323.54z M481.22,330.09c0,0,0-0.01,0.01-0.01
		c0,0,0.01,0.01,0.01,0.01C481.23,330.09,481.22,330.09,481.22,330.09z M516.43,323.62c0.12,0,0.24,0.14,0.36,0.22
		c-0.01,0.03-0.03,0.05-0.04,0.08c-0.55-0.01-1.1-0.02-1.64-0.04c0-0.05-0.01-0.1-0.01-0.15
		C515.54,323.69,515.98,323.63,516.43,323.62z M511.82,321.75c-0.02-0.02-0.03-0.06-0.05-0.1
		C511.79,321.68,511.81,321.72,511.82,321.75z M497.98,322.8c-0.16,0.01-0.33,0.04-0.49,0.03c-0.02,0-0.04-0.16-0.07-0.29
		c0.21,0.05,0.37,0.09,0.53,0.12C497.96,322.72,497.97,322.76,497.98,322.8z M497.81,322.19c-0.15,0.09-0.29,0.13-0.43,0
		C497.52,322.18,497.67,322.18,497.81,322.19z M496.3,322.23c0.11,0,0.22-0.01,0.32-0.02c-0.25,0.07-0.49,0.14-0.74,0.19
		c0-0.02,0-0.04,0-0.06C496.03,322.31,496.17,322.24,496.3,322.23z M495.35,322.39c-0.1-0.03-0.19-0.07-0.3-0.1
		C495.16,322.25,495.26,322.21,495.35,322.39z M490.65,329.81c0.08,0.03,0.18,0.08,0.24,0.02c0.19-0.23,0.34-0.09,0.49,0.08
		c-0.28,0.48-0.61,0.42-1.29-0.31C490.28,329.64,490.46,329.74,490.65,329.81z M486.19,326.94c0.51-0.2,0.64-0.14,0.7,0.29
		C486.66,327.14,486.45,327.05,486.19,326.94z M487.36,329.95c-0.09,0.07-0.3-0.26-0.3,0.25c-0.13-0.06-0.28-0.13-0.42-0.2
		c0-0.04,0.01-0.07,0.01-0.11c0.37-0.09,0.73-0.19,1.09-0.28c0,0.05,0.01,0.1,0.01,0.15C487.62,329.82,487.48,329.86,487.36,329.95z
		 M488.11,327.43c-0.16,0.04-0.33,0.08-0.52,0.12c0.03-0.17,0.04-0.27,0.05-0.34c-0.18-0.03-0.34-0.06-0.51-0.09
		c0.01-0.07,0.02-0.15,0.03-0.22c0.32,0.15,0.63,0.29,0.95,0.44C488.12,327.37,488.11,327.4,488.11,327.43z M485.97,322.51
		c0.07-0.04,0.15-0.16,0.21-0.13c0.24,0.12,0.47,0.12,0.73,0.03c0.34-0.12,0.72-0.19,1.04,0.16c0.04,0.05,0.1,0.04,0.16,0.04
		c0.28,0.02,0.58-0.26,0.84,0.1c-0.1,0.03-0.2,0.06-0.29,0.09c-0.11,0.03-0.21,0.09-0.32,0.14c0,0.04,0,0.08,0.01,0.12
		c0.11,0,0.21,0,0.32,0.01c0,0.06,0,0.12,0,0.18c-0.32,0.04-0.64,0.08-0.97,0.12c-0.01-0.04-0.01-0.09-0.02-0.13
		c0.04-0.05,0.07-0.1,0.14-0.2c-0.21-0.09-0.4-0.17-0.62-0.26c0.08-0.08,0.13-0.13,0.19-0.2c-0.32-0.11-0.71,0.27-0.74,0.87
		c-0.13-0.16-0.22-0.26-0.31-0.37c-0.06-0.08-0.12-0.18-0.18-0.24c-0.13-0.11-0.27-0.2-0.41-0.3c-0.04-0.03-0.08-0.07-0.11-0.1
		C485.75,322.46,485.86,322.48,485.97,322.51z M483.02,326.36c0.06,0.09,0.1,0.22,0.15,0.24c0.09,0.02,0.18-0.03,0.27-0.06
		c-0.03-0.09-0.06-0.19-0.1-0.28c0.29,0.32,0.62,0.15,0.94,0.07c0.28-0.07,0.57-0.1,0.85-0.11c0.1,0,0.2,0.11,0.3,0.16
		c-0.01,0.05-0.01,0.1-0.02,0.14c-0.13,0.02-0.26,0.01-0.39,0.07c-0.45,0.22-0.88,0.22-1.31-0.03c-0.03-0.02-0.09,0-0.12,0.04
		c-0.24,0.31-0.45,0.19-0.66-0.06C482.97,326.48,483,326.42,483.02,326.36z M482.68,327.62c-0.32,0.12-0.66,0.54-0.98,0.03
		C482.02,327.64,482.35,327.63,482.68,327.62z M480.72,326.42c0.21-0.02,0.42-0.04,0.63-0.07c0,0.07-0.01,0.14-0.01,0.21
		c-0.21,0.01-0.41,0.02-0.62,0.03C480.72,326.53,480.72,326.47,480.72,326.42z"
            />
            <path
                fill={textColor}
                d="M516.56,363.03v-1.11c0.1,0,0.2,0,0.3-0.01c-0.1-0.25-0.18-0.43-0.28-0.67c-0.94-0.24-1.88-0.19-2.83-0.09
		c-0.03-0.04-0.06-0.07-0.07-0.06c-0.18,0.07-0.18,0.08-0.26-0.12c-0.1,0.08-0.19,0.18-0.29,0.25c-0.21,0.03-0.42,0.05-0.62,0.07
		c-0.04-0.14-0.09-0.3-0.15-0.5c0-0.02,0.03-0.11,0.08-0.33c-0.26,0.2-0.47,0.35-0.75,0.56c0.01,0.02,0.02,0.05,0.03,0.07
		c-0.04-0.02-0.1-0.03-0.11,0c-0.15,0.34-0.37,0.16-0.56,0.15c-0.2-0.01-0.41,0.02-0.63-0.05c0.04-0.03,0.08-0.06,0.11-0.09
		c0-0.03-0.01-0.05-0.01-0.08c-0.22,0.07-0.44,0.17-0.66,0.2c-0.12,0.02-0.24-0.1-0.37-0.14c-0.13-0.04-0.26-0.07-0.39-0.07
		c-0.3,0.01-0.61,0.14-0.91,0.04c-0.73-0.24-1.45-0.13-2.18-0.15c-0.33-0.01-0.66-0.09-0.98-0.08c-0.36,0-0.72,0.07-1.08,0.1
		c-0.28,0.02-0.58,0.18-0.82,0.02c-0.21-0.15-0.41-0.21-0.62-0.21c-0.21,0.01-0.41,0.1-0.61,0.16c-0.07-0.06-0.14-0.2-0.21-0.19
		c-0.36,0.03-0.71,0.1-1.07,0.15c-0.13,0.02-0.27-0.03-0.4,0c-0.32,0.06-0.63,0.19-0.94,0.22c-0.27,0.03-0.54-0.02-0.81-0.06
		c-0.41-0.06-0.81-0.16-1.22-0.21c-0.1-0.01-0.2,0.12-0.3,0.18c-0.08,0.05-0.17,0.07-0.27,0.01c0.06-0.06,0.12-0.11,0.25-0.24
		c-0.18,0-0.31-0.06-0.39,0.02c-0.24,0.22-0.46,0.21-0.69,0.02c-0.06,0.4-0.14,0.41-0.54,0.03c-0.01,0.09-0.01,0.16-0.02,0.28
		c-0.11-0.06-0.21-0.13-0.33-0.2c-0.05,0.04-0.13,0.16-0.2,0.15c-0.41-0.05-0.82-0.14-1.24-0.18c-0.15-0.01-0.3,0.1-0.45,0.15
		c-0.19,0.06-0.35,0.1-0.56-0.08c-0.15-0.13-0.44-0.03-0.61,0.14c-0.27,0.27-0.51,0.36-0.79,0.23c-0.21-0.09-0.43-0.15-0.65-0.22
		c-0.01,0.04-0.02,0.07-0.02,0.11c0.04,0.04,0.07,0.08,0.11,0.12c-0.05,0.06-0.1,0.17-0.14,0.34c0.1-0.04,0.2-0.09,0.31-0.13
		c-0.03,0.11-0.07,0.25-0.11,0.38c0.24-0.03,0.47-0.13,0.69-0.13c0.16,0.2,0.3,0.34,0.43,0.06c0.21-0.02,0.42-0.13,0.62-0.04
		c-0.05-0.04-0.11-0.07-0.16-0.11c0-0.03,0-0.07,0-0.1c0.18-0.04,0.36-0.09,0.55-0.13c0.01,0.04,0.01,0.07,0.02,0.11
		c-0.05,0.07-0.1,0.13-0.17,0.23c0.49-0.09,0.97,0.47,1.35-0.22c0.08,0.05,0.14,0.1,0.21,0.15c-0.13,0.17-0.39,0.19-0.59,0.21
		c-0.42,0.04-0.84,0.01-1.23,0.01c-0.01,0.06-0.02,0.11-0.03,0.15h-1.77c-0.04-0.02-0.08-0.04-0.12-0.04
		c0.01,0.01,0.03,0.03,0.04,0.04h-0.48c-0.09-0.02-0.18-0.04-0.27-0.02c-0.03,0.01-0.06,0.01-0.09,0.02h-0.43
		c0.03-0.04,0.07-0.07,0.1-0.1c0.17,0.19,0.45,0.07,0.77-0.46c-0.33,0.05-0.63,0.1-0.93,0.14c-0.13,0.02-0.25,0.01-0.38-0.01
		c-0.28-0.04-0.58-0.26-0.83-0.12c-0.53,0.32-1.05-0.04-1.57,0.15c-0.08,0.03-0.17-0.02-0.26-0.04c-0.25-0.05-0.49-0.1-0.75-0.16
		c-0.04,0.45,0.1,0.45,0.21,0.47c-0.03,0.01-0.06,0.04-0.09,0.12h-4.88v0.8c-0.15-0.02-0.3-0.08-0.49-0.12
		c-0.1-0.32-0.1-0.32-0.24-0.32c0,0.08,0,0.16,0,0.24c-0.43-0.04-0.84-0.2-1.32-0.08c0-0.02-0.02-0.07-0.05-0.09
		c-0.01,0-0.01,0.01-0.02,0.01c-0.15,0.1-0.3,0.17-0.45,0.25c-0.05,0.03-0.15-0.02-0.17,0.03c-0.12,0.4-0.3,0.22-0.48,0.14
		c-0.05,0.06-0.09,0.11-0.14,0.12c-0.07,0.02-0.14,0.03-0.22,0.04c0.53,0.01,1.06,0.02,1.59,0c0.07,0,0.14-0.18,0.2-0.28
		c0,0.04,0,0.07,0,0.11c0.42,0.06,0.84,0.13,1.25,0.19c0.12,0.39,0.01,0.6-0.17,0.73c-0.23,0.15-0.25,0.38-0.07,0.71
		c0.03,0.06,0.04,0.14,0.08,0.25c-0.06,0.1-0.16,0.19-0.16,0.3c0,0.65-0.24,1.09-0.47,1.43c-0.13,0.21-0.29,0.36-0.47,0.46
		c0.55,0.05,1.09,0.1,1.64,0.14c0.05,0,0.11,0.01,0.16,0.01v0.46c-0.11,0.02-0.25,0.05-0.41,0.09c-0.31,0.07-0.58-0.07-0.9-0.16
		c0.1,0.07,0.2,0.17,0.21,0.26c0.04,0.38,0.24,0.82,0.01,1.18c0.06,0.06,0.11,0.11,0.14,0.14c-0.22,0.11-0.43,0.21-0.65,0.32
		c0.2,0.07,0.41,0.21,0.61,0.28c0.06-0.21,0.56-0.33,1-0.31v0.49h0.06c-0.09,0.1-0.19,0.2-0.28,0.3c0,0.05,0.01,0.09,0.01,0.14
		c0.34-0.03,0.69-0.07,1.07-0.11c-0.13-0.1-0.24-0.19-0.36-0.28c0.01-0.02,0.01-0.03,0.02-0.05h0.53c0.09,0.03,0.18,0.07,0.28,0.1
		c0,0.04-0.01,0.09-0.01,0.13c-0.1,0.08-0.2,0.17-0.29,0.25c0.25,0.1,0.49,0.2,0.69-0.24c0.11-0.24,0.93-0.28,1.06-0.08
		c0.03,0.05,0.03,0.16,0.03,0.16c0.21,0.01,0.41,0.02,0.61,0.02c0,0.03,0,0.06-0.01,0.09c0.15-0.13,0.3-0.26,0.39-0.35
		c-0.06-0.03-0.13-0.05-0.2-0.08h1.1c0.31,0.17,0.58,0.59,0.95,0.33c0.01-0.16,0.07-0.27,0.14-0.33h0.25
		c0.04,0.02,0.07,0.05,0.11,0.09c-0.06,0.08-0.11,0.15-0.18,0.24c0.04,0.02,0.06,0.05,0.09,0.05c0.36-0.05,0.72-0.1,1.08-0.15
		c0.12-0.02,0.24-0.02,0.36-0.01c0.29,0.03,0.57,0.16,0.86,0.1c0.77-0.16,1.53,0.08,2.29-0.05c0.06-0.01,0.12-0.05,0.17-0.09
		c0.1-0.06,0.2-0.12,0.3-0.18h0.06c0.01,0.04,0.02,0.07,0.03,0.11c-0.04,0.05-0.07,0.09-0.15,0.19c0.54-0.04,1.03-0.1,1.52-0.13
		c0.2-0.01,0.39,0.03,0.59,0.03c0.39-0.01,0.79-0.04,1.18-0.04c0.36,0,0.72,0,1.09,0.02c0.4,0.02,0.79,0.05,1.19,0.07
		c0.09,0,0.2,0.04,0.28-0.02c0.46-0.31,0.91-0.03,1.37-0.04c0.02,0,0.04,0.02,0.06,0.01c0.52-0.25,1.06,0.09,1.59-0.02
		c0.24-0.05,0.51,0.11,0.74,0.01c0.49-0.2,0.96,0.12,1.45,0.02c0.31-0.06,0.64,0,0.95-0.03c0.38-0.03,0.76-0.09,1.14-0.14
		c0.03,0,0.05-0.01,0.08-0.01c0.57,0.06,1.15,0.12,1.72,0.18c1.09,0.12,2.17,0.27,3.26,0.32c0.76,0.03,1.52-0.1,2.28-0.14
		c0.54-0.03,1.08-0.04,1.61-0.03c0.7,0.02,1.34-0.29,1.97-0.62h0.85v-0.43c0.07-0.03,0.13-0.06,0.2-0.1
		c-0.03-0.03-0.09-0.09-0.14-0.14c0.23-0.36,0.03-0.79-0.01-1.18c0-0.03-0.02-0.07-0.05-0.11v-1.45c0.16-0.3,0.29-0.66,0.29-1.13
		c0-0.11,0.11-0.2,0.16-0.3c-0.03-0.12-0.05-0.2-0.08-0.25c-0.18-0.33-0.16-0.55,0.07-0.71c0.18-0.12,0.29-0.34,0.17-0.73
		C516.98,363.09,516.77,363.06,516.56,363.03z M485.75,362.09c-0.04-0.03-0.07-0.07-0.11-0.1c0.11,0.03,0.22,0.05,0.33,0.08
		c0.07-0.04,0.15-0.16,0.21-0.13c0.24,0.12,0.47,0.12,0.73,0.03c0.33-0.12,0.68-0.18,0.99,0.12H485.75z M495.06,361.85
		c0.1-0.04,0.21-0.08,0.3,0.1C495.26,361.92,495.16,361.88,495.06,361.85z M495.89,361.97c0-0.02,0-0.04,0-0.06
		c0.14-0.04,0.28-0.11,0.42-0.12c0.11,0,0.22-0.01,0.32-0.02C496.38,361.85,496.13,361.92,495.89,361.97z M497.38,361.75
		c0.14,0,0.29,0,0.43,0C497.66,361.84,497.52,361.88,497.38,361.75z M511.77,361.21c0.02,0.03,0.03,0.07,0.05,0.1
		C511.8,361.29,511.79,361.25,511.77,361.21z M516.75,363.48c-0.06,0-0.12,0-0.19,0v-0.24c0.08,0.05,0.15,0.12,0.23,0.17
		C516.77,363.43,516.76,363.45,516.75,363.48z"
            />
            <path
                fill={backgroundColor}
                d="M510.23,349.06c-0.03-0.31,0.23-0.69,0.45-0.64c0.11,0.03,0.23,0.12,0.34,0.14c0.1,0.02,0.21-0.01,0.3-0.01
		c-0.22-0.15-0.43-0.4-0.65-0.43c-0.22-0.04-0.44,0.13-0.72,0.22c0.11,0.13,0.17,0.2,0.26,0.31c-0.1,0.11-0.19,0.22-0.32,0.38
		C510.04,349.04,510.13,349.05,510.23,349.06z"
            />
            <path
                fill={textColor}
                d="M478.86,350.97c0.07,0.12,0.14,0.24,0.21,0.36c0.03,0.06,0.08,0.12,0.09,0.17c0,0.03,0.01,0.05,0.01,0.08
		c0,0,0.01,0,0.01,0c0.17-0.01,0.34-0.16,0.51-0.14c0.11,0.03,0.22,0.1,0.32,0.07c0.16-0.05,0.32-0.21,0.48-0.23
		c0.35-0.05,0.69-0.03,1.04-0.23c0.27-0.15,0.63,0.1,0.95,0.18c0.03-0.2,0.44-0.36,0.84-0.41h0.36c0.05,0.01,0.1,0.02,0.15,0.03
		c-0.19,0.27-0.37,0.52-0.55,0.77c0.01,0.05,0.02,0.09,0.03,0.14c0.32-0.07,0.65-0.14,1.01-0.22c-0.13-0.09-0.25-0.16-0.37-0.25
		c0.04-0.12,0.06-0.2,0.09-0.2c0.24,0.03,0.47,0.11,0.71,0.17c0,0.04,0,0.09,0,0.13c-0.08,0.09-0.17,0.19-0.25,0.28
		c0.25,0.08,0.48,0.15,0.64-0.31c0.08-0.25,0.86-0.37,1-0.18c0.03,0.04,0.04,0.15,0.04,0.16c0.2-0.01,0.39-0.02,0.59-0.03
		c0,0.03,0,0.06,0,0.09c0.13-0.15,0.26-0.29,0.34-0.38c-0.18-0.06-0.38-0.12-0.59-0.19h1.12c0,0,0,0.01,0,0.01
		c0.01,0,0.01,0,0.02-0.01h0.25c0.33,0.11,0.64,0.59,0.99,0.28c0-0.12,0.02-0.21,0.06-0.28h0.4c-0.05,0.08-0.09,0.15-0.15,0.25
		c0.04,0.02,0.06,0.04,0.09,0.04c0.34-0.08,0.68-0.16,1.02-0.24c0.11-0.03,0.23-0.04,0.34-0.04c0.28,0.01,0.56,0.11,0.82,0.03
		c0.06-0.02,0.12-0.03,0.18-0.04h11.48c-0.12,0.02-0.24,0.03-0.36,0.05c0,0.04,0.01,0.08,0.01,0.12c0.49,0.09,0.98,0.18,1.46,0.27
		c-0.07-0.17-0.14-0.32-0.22-0.44h3c0.16,0.04,0.32,0.13,0.48,0.34c0.18,0.24,0.32,0.3,0.48,0.17c0.12-0.01,0.25-0.01,0.37-0.02
		c0-0.06-0.01-0.13-0.01-0.19c-0.07,0.01-0.14,0.02-0.21,0.03c0.05-0.09,0.11-0.19,0.17-0.32h0.46c-0.04,0-0.07,0.01-0.12,0.01
		c0.28,0.12,0.58,0.25,0.88,0.37c-0.11-0.34-0.28-0.44-0.73-0.39v-0.68c0.91,0.11,1.82,0.23,2.73,0.28
		c0.73,0.04,1.46-0.08,2.19-0.11c0.48-0.02,0.96-0.02,1.44,0c0.07-0.11,0.13-0.21,0.19-0.31c-0.12-0.01-0.19-0.02-0.28-0.02
		c0.38-0.41,0.37-0.48-0.03-0.54c0.18-0.23,0.2-0.45-0.02-0.69c-0.08-0.08-0.01-0.21-0.02-0.39c0.13,0.13,0.19,0.19,0.24,0.25
		c0.07-0.1,0.04-0.19-0.01-0.27c-0.05-0.1-0.19-0.2-0.18-0.3c0.02-0.17,0.05-0.34,0.08-0.51c-0.37,0-0.75,0.01-1.12,0.01
		c-0.09,0-0.17-0.02-0.26-0.03c0.09-0.57,0.11-0.55,0.39-0.4c0.16,0.09,0.35,0.01,0.53,0.02c0.18,0.01,0.36,0.01,0.53,0.02
		c0.02-0.11,0.04-0.21,0.05-0.32c0.04-0.27,0.09-0.54,0.06-0.81c-0.03-0.32-0.16-0.63-0.22-0.94c-0.03-0.13,0.02-0.27,0-0.4
		c-0.04-0.36-0.12-0.71-0.15-1.07c-0.01-0.07,0.13-0.15,0.19-0.21c-0.05-0.19-0.15-0.4-0.16-0.61c-0.01-0.21,0.06-0.4,0.21-0.62
		c0.16-0.24,0.01-0.55-0.02-0.82c-0.01-0.1-0.02-0.2-0.03-0.3c-0.09,0.07-0.18,0.16-0.26,0.22c-0.21,0.02-0.42,0.04-0.62,0.06
		c-0.04-0.14-0.09-0.3-0.14-0.5c0-0.02,0.03-0.11,0.08-0.33c-0.27,0.19-0.47,0.34-0.75,0.54c0.01,0.02,0.02,0.05,0.03,0.07
		c-0.04-0.02-0.1-0.03-0.11,0c-0.16,0.34-0.37,0.15-0.56,0.15c-0.2-0.01-0.41,0.02-0.62-0.06c0.04-0.03,0.08-0.06,0.11-0.09
		c0-0.03-0.01-0.05-0.01-0.08c-0.22,0.07-0.44,0.17-0.66,0.2c-0.12,0.02-0.24-0.1-0.36-0.14c-0.13-0.04-0.26-0.07-0.38-0.07
		c-0.3,0.01-0.61,0.14-0.9,0.04c-0.73-0.24-1.45-0.13-2.17-0.14c-0.33-0.01-0.66-0.08-0.98-0.07c-0.36,0.01-0.71,0.08-1.07,0.12
		c-0.28,0.03-0.58,0.19-0.82,0.03c-0.21-0.14-0.41-0.21-0.62-0.19c-0.21,0.01-0.41,0.11-0.6,0.17c-0.07-0.06-0.15-0.19-0.22-0.19
		c-0.35,0.04-0.71,0.12-1.06,0.18c-0.13,0.02-0.27-0.02-0.4,0.01c-0.31,0.07-0.62,0.21-0.93,0.25c-0.27,0.04-0.54,0-0.81-0.03
		c-0.41-0.04-0.81-0.13-1.22-0.16c-0.1-0.01-0.19,0.13-0.29,0.19c-0.08,0.05-0.17,0.08-0.27,0.02c0.06-0.06,0.11-0.12,0.24-0.25
		c-0.18,0.01-0.31-0.05-0.39,0.03c-0.23,0.23-0.45,0.23-0.69,0.05c-0.04,0.4-0.12,0.41-0.54,0.05c0,0.09-0.01,0.16-0.01,0.28
		c-0.11-0.06-0.22-0.12-0.34-0.18c-0.05,0.04-0.12,0.17-0.19,0.16c-0.41-0.02-0.83-0.1-1.24-0.11c-0.15,0-0.29,0.11-0.44,0.17
		c-0.19,0.07-0.35,0.12-0.56-0.05c-0.16-0.12-0.43,0-0.59,0.18c-0.25,0.29-0.48,0.39-0.77,0.28c-0.22-0.08-0.44-0.12-0.66-0.17
		c-0.01,0.04-0.01,0.08-0.02,0.11c0.04,0.04,0.08,0.07,0.12,0.11c-0.05,0.07-0.09,0.18-0.12,0.35c0.1-0.05,0.19-0.1,0.3-0.16
		c-0.02,0.11-0.06,0.26-0.08,0.38c0.23-0.05,0.46-0.16,0.67-0.18c0.17,0.18,0.32,0.32,0.42,0.03c0.21-0.03,0.4-0.16,0.62-0.08
		c-0.06-0.03-0.11-0.06-0.17-0.09c0-0.03,0-0.07,0-0.1c0.18-0.06,0.36-0.11,0.53-0.17c0.01,0.04,0.02,0.07,0.02,0.11
		c-0.04,0.07-0.09,0.14-0.16,0.24c0.48-0.12,0.99,0.41,1.33-0.3c0.08,0.05,0.15,0.09,0.22,0.13c-0.12,0.18-0.38,0.21-0.57,0.24
		c-0.41,0.06-0.83,0.06-1.22,0.08c-0.04,0.25-0.06,0.39-0.09,0.54c-0.06-0.07-0.12-0.17-0.19-0.19c-0.11-0.04-0.23-0.03-0.34-0.02
		c-0.29,0.02-0.58,0.08-0.87,0.07c-0.13-0.01-0.26-0.15-0.41-0.16c0.04,0.04,0.09,0.08,0.13,0.12c0,0.04-0.01,0.08-0.01,0.12
		c-0.12-0.04-0.24-0.1-0.35-0.13c-0.16-0.03-0.32-0.08-0.47-0.04c-0.21,0.06-0.43,0.14-0.64,0.22c0.06-0.07,0.14-0.19,0.22-0.27
		c0.18,0.17,0.45,0.04,0.73-0.51c-0.33,0.08-0.62,0.15-0.91,0.21c-0.12,0.02-0.25,0.03-0.37,0.02c-0.28-0.02-0.6-0.22-0.83-0.05
		c-0.5,0.36-1.04,0.04-1.54,0.28c-0.08,0.04-0.17-0.01-0.26-0.02c-0.25-0.03-0.5-0.06-0.75-0.09c0,0.45,0.13,0.44,0.25,0.45
		c-0.03,0.01-0.06,0.06-0.09,0.16c-0.01,0.05-0.08,0.08-0.12,0.09c-0.29,0.03-0.59,0.02-0.88,0.08c-0.18,0.03-0.35,0.15-0.52,0.24
		c-0.21,0.11-0.41,0.22-0.62,0.33c0-0.05,0-0.11,0-0.16c-0.07-0.07-0.15-0.22-0.21-0.2c-0.31,0.1-0.67-0.17-0.93,0.34
		c-0.02,0.04-0.09,0.02-0.13,0c-0.24-0.07-0.47-0.15-0.71-0.23c-0.01,0.05-0.01,0.1-0.02,0.15c0.04,0.05,0.08,0.1,0.15,0.18
		c-0.21,0.15-0.39,0.34-0.58,0.41c-0.18,0.06-0.39-0.02-0.64-0.05c-0.13-0.31-0.13-0.31-0.27-0.29c0.01,0.08,0.02,0.16,0.03,0.24
		c-0.43,0.01-0.84-0.1-1.3,0.07c0-0.02-0.04-0.09-0.07-0.09c-0.24-0.02-0.48-0.03-0.71,0c-0.01,0-0.01,0-0.02,0.01
		c0.04-0.06,0.07-0.15,0.09-0.25c0.05-0.3,0.1-0.59,0.34-0.34c0.05,0.05,0.14-0.04,0.2-0.15c-0.36-0.13-0.69-0.07-1,0.24
		c-0.06,0.06-0.16,0.05-0.23,0.01c-0.08-0.04-0.17-0.09-0.25-0.14c0.01,0.07,0.03,0.14,0.06,0.21c0.1,0.06,0.2,0.11,0.31,0.17
		c-0.07,0.1-0.09,0.14-0.11,0.17c0.08,0.05,0.16,0.09,0.24,0.16c0.1,0.08,0.2,0.07,0.29-0.01c-0.03,0.07-0.07,0.15-0.1,0.21
		c-0.08,0.14-0.16,0.36-0.26,0.4c-0.14,0.06-0.28,0.09-0.43,0.12c0.02,0.08,0.05,0.16,0.11,0.23c0.05,0.08,0.02,0.18,0.02,0.28
		c-0.02,0.4-0.06,0.79-0.07,1.19c-0.01,0.26-0.02,0.51-0.02,0.77c0.04-0.01,0.08-0.01,0.12-0.02v0.16c-0.04,0-0.08,0-0.13,0.01
		c0,0.06,0,0.12,0,0.17c0,0.04,0,0.07,0,0.11c0.04-0.01,0.08-0.02,0.12-0.04v2.07c-0.03,0.01-0.06,0.01-0.09,0.02
		c0.03,0.36,0.07,0.73,0.1,1.13c-0.09-0.07-0.14-0.11-0.19-0.15C478.96,350.95,478.91,350.96,478.86,350.97z M513.52,341.32
		c-0.02-0.02-0.03-0.06-0.05-0.1C513.49,341.25,513.51,341.28,513.52,341.32z M499.79,342.53c-0.16,0.02-0.32,0.05-0.48,0.05
		c-0.02,0-0.04-0.15-0.08-0.29c0.21,0.04,0.37,0.07,0.53,0.1C499.77,342.44,499.78,342.48,499.79,342.53z M499.6,341.92
		c-0.15,0.1-0.28,0.14-0.43,0.01C499.31,341.92,499.46,341.92,499.6,341.92z M498.11,342.03c0.11-0.01,0.21-0.02,0.32-0.03
		c-0.24,0.08-0.48,0.16-0.73,0.22c0-0.02,0-0.04,0-0.06C497.84,342.11,497.97,342.04,498.11,342.03z M497.17,342.23
		c-0.1-0.03-0.2-0.06-0.3-0.08C496.97,342.1,497.08,342.05,497.17,342.23z M487.91,342.99c0.06-0.05,0.14-0.18,0.2-0.15
		c0.25,0.1,0.47,0.08,0.72-0.03c0.33-0.15,0.69-0.25,1.04,0.08c0.04,0.04,0.11,0.03,0.16,0.03c0.28,0,0.55-0.31,0.84,0.04
		c-0.09,0.04-0.19,0.07-0.28,0.11c-0.1,0.04-0.2,0.11-0.3,0.16c0.01,0.04,0.01,0.08,0.02,0.12c0.11-0.01,0.21-0.01,0.32-0.02
		c0,0.06,0.01,0.12,0.01,0.18c-0.31,0.07-0.63,0.13-0.94,0.2c-0.01-0.04-0.02-0.09-0.03-0.13c0.03-0.05,0.06-0.11,0.12-0.21
		c-0.21-0.07-0.4-0.13-0.63-0.21c0.07-0.09,0.11-0.14,0.17-0.21c-0.32-0.09-0.67,0.33-0.66,0.93c-0.14-0.14-0.24-0.24-0.33-0.35
		c-0.07-0.07-0.13-0.17-0.2-0.22c-0.14-0.1-0.28-0.17-0.43-0.26c-0.04-0.02-0.08-0.07-0.12-0.09
		C487.69,342.96,487.8,342.97,487.91,342.99z M484.02,350.98c0,0,0.01,0.01,0.01,0.01c0,0-0.01,0-0.01,0
		C484.01,350.99,484.02,350.98,484.02,350.98z"
            />
            <path
                fill={backgroundColor}
                d="M505.06,339.74c0.13-0.23,0.22-0.38,0.32-0.56c-0.04-0.09-0.1-0.2-0.19-0.4
		C505.14,339.12,505.11,339.38,505.06,339.74z"
            />
            <path
                fill={backgroundColor}
                d="M493.79,351.98c0.01-0.07,0.02-0.13,0.02-0.2c-0.18-0.08-0.36-0.17-0.54-0.25c-0.01,0.07-0.02,0.14-0.02,0.21
		C493.44,351.77,493.58,352.15,493.79,351.98z"
            />
            <path
                fill={textColor}
                d="M495.06,340.1c-0.01-0.05-0.01-0.1-0.02-0.15c-0.27,0.05-0.54,0.1-0.8,0.14c0.01,0.07,0.01,0.14,0.02,0.22
		C494.52,340.15,494.84,340.56,495.06,340.1z"
            />
            <path
                fill={textColor}
                d="M557.5,359.99c0.03,0.03,0.05,0.05,0.08,0.07c0.03-0.02,0.07-0.04,0.17-0.1c0.27,0.26,0.49,0.49,0.72,0.71
		c0.06-0.01,0.12-0.03,0.17-0.04c-0.2-0.2-0.42-0.39-0.61-0.59c-0.05-0.05-0.08-0.13-0.02-0.18c0.3-0.26,0.32-0.53,0.12-0.8
		c-0.11,0.05-0.2,0.13-0.14,0.17c0.31,0.18,0.01,0.23-0.31,0.28c0.09-0.28,0.16-0.57,0.24-0.85c0-0.01,0.01-0.02,0.01-0.03
		c0.03,0,0.05-0.01,0.08-0.01c-0.01-0.06-0.01-0.13-0.02-0.19c0.06-0.2,0.12-0.41,0.22-0.59c0.16-0.3,0.4-0.56,0.58-0.85
		c0.07-0.12,0.07-0.28,0.13-0.4c0.16-0.34,0.35-0.68,0.48-1.04c0.03-0.07-0.09-0.19-0.14-0.28c0.11-0.18,0.26-0.37,0.31-0.58
		c0.06-0.21,0.03-0.44-0.08-0.7c-0.12-0.3,0.1-0.57,0.17-0.85c0.09-0.37,0.21-0.73,0.25-1.11c0.04-0.35-0.02-0.71-0.01-1.07
		c0.01-0.78,0.12-1.54-0.19-2.35c-0.12-0.33-0.02-0.65-0.05-0.97c-0.01-0.14-0.07-0.28-0.13-0.42c-0.06-0.14-0.2-0.28-0.2-0.4
		c-0.01-0.24,0.06-0.47,0.09-0.7c-0.03,0-0.06-0.01-0.08-0.01c-0.02,0.04-0.05,0.08-0.07,0.12c-0.12-0.24-0.14-0.46-0.19-0.68
		c-0.05-0.21-0.3-0.44,0.02-0.6c0.02-0.01,0-0.07-0.03-0.12c0.03,0.01,0.06,0.02,0.08,0.04c0.14-0.3,0.24-0.52,0.36-0.82
		c-0.22,0.06-0.31,0.08-0.32,0.09c-0.23-0.06-0.41-0.11-0.57-0.16c-0.04-0.22-0.09-0.45-0.13-0.67c0.04-0.11,0.11-0.21,0.16-0.32
		c-0.24-0.07-0.23-0.07-0.22-0.27c0-0.02-0.04-0.05-0.09-0.08c-0.19-0.74-0.45-1.47-0.86-2.18c0.02-0.09,0.04-0.18,0.03-0.27
		c-0.02-0.16-0.12-0.32-0.31-0.48c-0.21-0.18-0.08-0.43-0.09-0.65c-0.01-0.28,0.01-0.57-0.03-0.85c-0.04-0.26-0.17-0.51-0.23-0.77
		c-0.05-0.23-0.08-0.47-0.12-0.7c0.05-0.14,0.09-0.27,0.14-0.41c-0.05-0.03-0.1-0.05-0.15-0.08c-0.02,0.03-0.05,0.07-0.07,0.1
		c-0.06-0.2-0.14-0.4-0.27-0.6c0.04-0.22,0.08-0.43,0.29-0.57c0.17-0.12,0.12-0.38,0.26-0.52c0.3-0.31,0.2-0.85,0.66-1.06
		c0.01-0.01,0.01-0.03,0.02-0.04c0.21-0.29,0.17-0.75,0.66-0.86c0.09-0.02,0.11-0.1,0.15-0.16c0.18-0.26,0.35-0.53,0.54-0.78
		c0.18-0.23,0.37-0.45,0.58-0.66c0.23-0.23,0.48-0.43,0.72-0.65c0.12-0.11,0.2-0.25,0.33-0.34c0.33-0.25,0.68-0.47,1.06-0.72
		c-0.11-0.03-0.17-0.04-0.23-0.05c-0.03-0.04-0.05-0.08-0.08-0.12c0.13-0.01,0.26-0.03,0.39-0.04c0.06-0.01,0.13,0,0.18-0.03
		c0.6-0.29,0.97-0.83,1.63-1.05c0.24-0.08,0.38-0.3,0.57-0.45c0.08-0.06,0.18-0.11,0.28-0.14c0.3-0.11,0.61-0.22,0.92-0.32
		c0.02-0.01,0.02-0.04,0.04-0.07c-0.1-0.04-0.19-0.08-0.28-0.12c0.04-0.24,0.22-0.27,0.41-0.13c-0.05-0.1-0.1-0.2-0.14-0.3
		c-0.19-0.04-0.41-0.14-0.7-0.33c0.17-0.04,0.33-0.07,0.49-0.1c-0.03-0.06-0.06-0.13-0.09-0.19c-0.18-0.36-0.39-0.7-0.58-1.06
		c-0.09-0.18-0.14-0.37-0.24-0.55c-0.26-0.44-0.53-0.86-0.82-1.33c0.13,0.03,0.19,0.04,0.26,0.06c0.03-0.02,0.07-0.05,0.1-0.07
		l-0.03-0.05c-0.11-0.07-0.21-0.14-0.32-0.21c-0.06-0.04-0.13-0.07-0.17-0.12c-0.47-0.65-0.58-1.43-1.09-2.07
		c-0.19-0.23-0.19-0.55-0.29-0.82c-0.01-0.04-0.03-0.07-0.05-0.11c-0.2,0.12-0.39,0.25-0.58,0.37c-0.09,0.06-0.16,0.15-0.27,0.16
		c-0.67,0.1-1.02,0.66-1.75,0.7c-0.34,0.02-0.53,0.37-0.78,0.56c-0.11,0.09-0.24,0.17-0.37,0.23c-0.32,0.15-0.65,0.29-1,0.46
		c0.65,0.2,0.98,0.13,1.03-0.12c0.13,0.01,0.28,0.05,0.39,0.06c-0.26,0.08-0.51,0.16-0.75,0.26c-0.17,0.07-0.28,0.22-0.4,0.34
		c-0.09,0.1-0.16,0.22-0.23,0.33c-0.03-0.03-0.06-0.05-0.1-0.08c0.01-0.06,0.02-0.12,0.03-0.18c-0.13,0.1-0.14,0.3-0.25,0.4
		c-0.25,0.21-0.54,0.38-0.81,0.57c-0.1,0.08-0.2,0.16-0.27,0.26c-0.04,0.06-0.01,0.18-0.01,0.27c-0.13-0.08-0.26-0.17-0.48-0.31
		c-0.34,0.27-0.68,0.59-1.06,0.87c-0.18,0.13-0.4,0.32-0.64,0.29c0.02-0.09,0.04-0.17,0.06-0.26c0.83,0.19,0.82-0.55,1.31-0.82
		c-0.13-0.02-0.22-0.03-0.31-0.04c-0.02-0.03-0.04-0.06-0.06-0.09c0.19-0.1,0.38-0.19,0.58-0.29c0.02,0.02,0.05,0.05,0.07,0.07
		c-0.02,0.06-0.05,0.13-0.07,0.19c0.12-0.21,0.38-0.27,0.58-0.4c0.31,0.13,0.33-0.07,0.35-0.33c0.2-0.14,0.48-0.22,0.73-0.35
		c-0.11-0.07-0.25-0.15-0.35-0.21c0.14-0.04,0.26-0.07,0.38-0.1c-0.15-0.1-0.26-0.15-0.35-0.16c0.01-0.05,0.02-0.11,0.03-0.16
		c-0.03-0.02-0.06-0.05-0.09-0.07c-0.17,0.19-0.34,0.38-0.48,0.59c-0.18,0.28-0.46,0.38-0.89,0.37c-0.27-0.01-0.61,0.12-0.65,0.32
		c-0.05,0.27-0.22,0.37-0.43,0.46c-0.17,0.07-0.38,0.11-0.49,0.23c-0.33,0.34-0.6,0.72-0.89,1.07c-0.05,0.06-0.21,0.04-0.28,0.05
		c-0.04,0.14-0.07,0.26-0.11,0.39c-0.1-0.07-0.16-0.11-0.24-0.17c-0.02,0.58-0.08,0.63-0.44,0.42c-0.03,0.31-0.18,0.5-0.54,0.56
		c-0.12,0.02-0.16,0.16-0.3,0.32c0.2-0.03,0.29-0.04,0.38-0.06c-0.02,0.12-0.1,0.18-0.2,0.22c-0.12,0.05-0.3,0.06-0.36,0.15
		c-0.24,0.38-0.43,0.79-0.64,1.18c-0.14,0.26-0.26,0.53-0.45,0.75c-0.22,0.26-0.51,0.48-0.75,0.74c-0.1,0.11-0.13,0.26-0.22,0.37
		c-0.07,0.1-0.15,0.2-0.23,0.3c-0.04-0.13-0.08-0.27-0.13-0.4c-0.04-0.09-0.27-0.25-0.42-0.25c-0.43,0-0.94-0.12-1.28,0.08
		c-0.07-0.04-0.15-0.08-0.18-0.1c-0.1,0.22-0.2,0.45-0.31,0.67c-0.28,0.56-0.57,1.12-0.41,1.7c0.11,0.41,0.18,0.82,0.23,1.22
		c0.08,0.58,0.06,1.16,0.21,1.73c0.08,0.29,0.17,0.58,0.26,0.86c-0.14,0.55-0.27,1.09-0.21,1.7c-0.14,0.06-0.25,0.11-0.34,0.15
		c0-0.06,0-0.11,0-0.17c-0.07,0-0.14,0-0.21,0c0.01,0.1,0.02,0.19,0.03,0.29c-0.12,0.13-0.03,0.24,0.26,0.37
		c0.09,0.04,0.16,0.08,0.22,0.12c-0.04,0.13-0.07,0.25-0.09,0.38c-0.02,0.14,0.01,0.29,0.03,0.44c0.03,0.14,0.14,0.31,0.12,0.43
		c-0.04,0.23-0.16,0.45-0.24,0.67c0.03,0.01,0.05,0.02,0.08,0.03c0.03-0.03,0.06-0.07,0.09-0.1c0.07,0.26,0.04,0.47,0.04,0.7
		c0.01,0.21,0.2,0.48-0.15,0.59c-0.03,0.01-0.01,0.07,0.01,0.12c-0.03-0.02-0.05-0.03-0.08-0.05c-0.2,0.27-0.35,0.47-0.53,0.74
		c0.22-0.02,0.32-0.03,0.34-0.03c0.21,0.1,0.38,0.18,0.53,0.25c-0.01,0.23-0.01,0.45-0.01,0.68c-0.06,0.1-0.15,0.19-0.22,0.29
		c0.22,0.11,0.21,0.11,0.16,0.31c0,0.02,0.03,0.05,0.07,0.09c0.04,1.05,0.21,2.13,0.76,3.19c0.28,0.12,0.49,0.2,0.77,0.32
		c-0.05-0.22-0.14-0.42-0.1-0.59c0.07-0.28,0.24-0.52,0.36-0.78c0.07-0.15,0.1-0.31,0.14-0.47c0.01-0.05-0.05-0.15-0.01-0.17
		c0.01,0.04,0.02,0.08,0.03,0.12c0.05,0.27,0.09,0.55,0.12,0.83c0.03,0.3,0.03,0.6,0.06,0.9c0.01,0.15,0.08,0.3,0.08,0.45
		c0.01,0.37-0.01,0.75-0.02,1.15c0.1-0.06,0.14-0.08,0.19-0.11c0.05,0.01,0.1,0.02,0.16,0.03c-0.07,0.09-0.13,0.18-0.2,0.27
		c-0.03,0.04-0.08,0.09-0.08,0.13c-0.08,0.58,0.23,1.15,0.1,1.74c-0.05,0.21,0.11,0.43,0.16,0.65c0.02,0.09,0.02,0.18,0.01,0.27
		c-0.03,0.27-0.07,0.55-0.11,0.82c0,0.02,0.03,0.04,0.06,0.07c0.09-0.04,0.16-0.08,0.23-0.12c0,0.11,0,0.22,0,0.33
		c-0.06,0.02-0.14,0.03-0.23,0.03c-0.36,0.37,0.64,0.62,0.48,0.98c0,0,0.05,0.01,0.17,0.04c-0.02-0.15,0.04-0.28-0.06-0.39
		c-0.24-0.29-0.24-0.55,0.31-0.79c0,0,0,0,0.01,0c0,0,0,0,0-0.01c0.1-0.04,0.21-0.08,0.34-0.12c-0.1,0.34-0.19,0.64-0.28,0.95
		c-0.02,0.05-0.07,0.14-0.02,0.16c0,0,0,0,0,0c-0.09,0.33-0.01,0.72-0.34,0.95c-0.01,0-0.01,0.01-0.01,0.02
		c-0.09-0.14-0.17-0.27-0.25-0.39c-0.09,0.07-0.23,0.18-0.37,0.29c0.03,0,0.07,0,0.1,0c0.02,0.15,0.03,0.3,0.05,0.46
		c0,0,0.13,0,0.18,0.02c0.08,0.04,0.13,0.14,0.14,0.26c-0.05,0.14-0.14,0.25-0.32,0.33c-0.08,0.03-0.08,0.12-0.11,0.19
		c-0.03,0.06-0.05,0.13-0.08,0.19c-0.02,0.02-0.03,0.05-0.03,0.08c-0.08,0.2-0.17,0.4-0.26,0.59c-0.13,0.26-0.27,0.51-0.43,0.75
		c-0.17,0.26-0.38,0.51-0.57,0.76c-0.09,0.13-0.15,0.28-0.26,0.39c-0.27,0.3-0.57,0.58-0.89,0.88c0.12,0.01,0.18,0.01,0.24,0.01
		c0.03,0.03,0.07,0.07,0.1,0.1c-0.12,0.03-0.25,0.07-0.37,0.1c-0.06,0.02-0.13,0.03-0.17,0.06c-0.53,0.39-0.78,0.98-1.38,1.31
		c-0.22,0.12-0.3,0.36-0.47,0.54c-0.07,0.07-0.15,0.13-0.24,0.19c-0.27,0.16-0.56,0.32-0.84,0.47c-0.02,0.01-0.01,0.04-0.02,0.08
		c0.11,0.03,0.2,0.05,0.3,0.07c0.02,0.3-0.25,0.32-0.53,0.09c-0.6,0.01-0.2,0.78-0.66,0.9c-0.01,0,0.02,0.04,0.06,0.13
		c0.14-0.1,0.31-0.15,0.37-0.27c0.17-0.39,0.49-0.54,1.34-0.18c-0.4,0.16-0.77,0.3-1.14,0.44c-0.06,0.02-0.19,0.04-0.17,0.09
		c0.17,0.35-0.14,0.29-0.31,0.35c-0.1,0.04-0.31,0-0.32,0.08c-0.01,0.38-0.55,0.14-0.68,0.38c-0.05-0.04-0.09-0.09-0.14-0.13
		c0.17-0.24,0.33-0.49,0.47-0.7c-0.13-0.02-0.33-0.04-0.53-0.07c0.02,0.02,0.04,0.04,0.06,0.06c-0.15,0.1-0.29,0.19-0.45,0.29
		c0,0,0.08,0.08,0.08,0.13c0.02,0.2-0.66,0.52-0.89,0.39c-0.01-0.01-0.03-0.01-0.04-0.02c0.01,0.04,0.02,0.08,0.05,0.11
		c0.44,0.4,0.3,1.01,0.87,1.37c0.26,0.16,0.18,0.53,0.26,0.8c0.01,0.03,0.02,0.06,0.03,0.08c0.12-0.33,0.57-0.2,0.88-0.26
		c-0.27,0.13-0.55,0.26-0.83,0.38c0.02,0.05,0.05,0.09,0.08,0.14c0.17,0.26,0.36,0.51,0.57,0.79c-0.68-0.08-0.93-0.28-0.82-0.52
		c-0.12-0.04-0.27-0.08-0.37-0.11c0.17,0.17,0.33,0.35,0.48,0.53c0.06,0.08,0.09,0.17,0.1,0.26c0.04-0.02,0.08-0.03,0.12-0.05
		c0.04,0.05,0.08,0.11,0.12,0.16c-0.07,0.02-0.14,0.05-0.21,0.07c0,0,0,0.01,0,0.01c0.02,0.12,0,0.25,0.01,0.38
		c0.04-0.01,0.09-0.02,0.13-0.03c0.03-0.06,0.05-0.11,0.08-0.17c0.05,0.14-0.05,0.32-0.01,0.44c0.1,0.28,0.28,0.54,0.41,0.81
		c0.05,0.1,0.1,0.21,0.11,0.33c0,0.07-0.08,0.16-0.12,0.24c0.16-0.03,0.32-0.06,0.6-0.11c0.11,0.22,0.22,0.46,0.33,0.69
		c0.03,0,0.07,0,0.1,0c-0.03-0.18-0.06-0.37-0.17-0.52c0.13,0.03,0.22,0.05,0.31,0.06c0.03-0.02,0.07-0.04,0.1-0.06
		c-0.13-0.15-0.26-0.3-0.38-0.44c-0.03,0.01-0.07,0.02-0.1,0.04c-0.01,0.06-0.02,0.13-0.03,0.19c-0.01-0.23-0.21-0.37-0.33-0.55
		c-0.35,0-0.26-0.18-0.14-0.41c-0.1-0.2-0.3-0.37-0.45-0.58c0.14-0.02,0.31-0.04,0.43-0.05c-0.1-0.08-0.19-0.16-0.28-0.23
		c0.19-0.03,0.32-0.03,0.41-0.01c0.02-0.05,0.04-0.1,0.07-0.15c0.04-0.01,0.08-0.02,0.12-0.02c0.03,0.23,0.08,0.45,0.08,0.68
		c0,0.31,0.2,0.5,0.59,0.63c0.25,0.08,0.49,0.3,0.42,0.49c-0.09,0.26,0.02,0.39,0.17,0.54c0.04,0.04,0.09,0.08,0.14,0.11
		c0.04-0.04,0.09-0.08,0.13-0.06c0.58,0.22,0.79-0.21,1.17-0.34c0.07-0.02,0.05-0.18,0.08-0.28c-0.04-0.04-0.07-0.07-0.11-0.11
		c0.28-0.05,0.56-0.11,0.83-0.17c0.23-0.05,0.48-0.09,0.67-0.18c0.32-0.16,0.59-0.37,0.89-0.55c0.04-0.03,0.12-0.05,0.17-0.02
		c0.1,0.05,0.16,0.06,0.2,0.05c-0.1,0.09-0.23,0.18,0.09,0.49c0.22-0.2,0.44-0.41,0.64-0.61c0.07-0.07,0.13-0.17,0.23-0.21
		c0.63-0.21,0.86-0.82,1.58-0.98c0.33-0.07,0.44-0.45,0.65-0.68c0.09-0.1,0.2-0.2,0.32-0.29c0.28-0.2,0.58-0.39,0.89-0.62
		c-0.68-0.09-0.99,0.04-0.99,0.29c-0.13,0.01-0.29,0-0.39,0.01c0.24-0.12,0.47-0.24,0.68-0.38c0.15-0.1,0.23-0.26,0.32-0.41
		c0.07-0.11,0.11-0.24,0.16-0.36c0.04,0.02,0.07,0.04,0.11,0.06c0,0.06,0,0.12,0.01,0.18c0.11-0.13,0.08-0.32,0.16-0.43
		c0.2-0.25,0.45-0.46,0.67-0.7c0.08-0.09,0.17-0.19,0.21-0.3c0.03-0.07-0.03-0.17-0.04-0.26c0.15,0.06,0.29,0.12,0.54,0.22
		c0.27-0.31,0.52-0.67,0.82-0.99c0,0.01,0,0.02,0,0.02c-0.22,0.23-0.24,0.61-0.52,0.84c0.14,0,0.23-0.01,0.32-0.01
		c0.03,0.03,0.05,0.05,0.08,0.08c-0.17,0.13-0.33,0.25-0.5,0.38c-0.03-0.02-0.06-0.04-0.08-0.06c0.01-0.07,0.02-0.13,0.03-0.2
		c-0.08,0.23-0.32,0.33-0.49,0.49c-0.33-0.07-0.31,0.13-0.27,0.38c-0.17,0.17-0.42,0.3-0.64,0.47c0.13,0.05,0.28,0.11,0.39,0.15
		c-0.13,0.06-0.24,0.11-0.36,0.16c0.16,0.07,0.29,0.1,0.38,0.1c0,0.05,0,0.11,0,0.16c0.04,0.02,0.07,0.04,0.11,0.05
		c0.12-0.22,0.26-0.43,0.35-0.66c0.12-0.31,0.37-0.45,0.8-0.51c0.27-0.04,0.57-0.22,0.57-0.43c0-0.05,0.01-0.09,0.02-0.13
		c0.04-0.02,0.09-0.04,0.13-0.06c-0.03-0.03-0.06-0.05-0.08-0.08c0.06-0.1,0.15-0.18,0.26-0.25c0.12-0.08,0.28-0.15,0.38-0.25
		c0.03,0.03,0.05,0.07,0.05,0.09c0.03,0.17,0.04,0.35-0.06,0.51c-0.12,0.21-0.27,0.41-0.01,0.6c0.16,0,0.3,0,0.36-0.01
		c0.08-0.17,0.15-0.34,0.25-0.51c0.14-0.23,0.7-0.4,0.61-0.67c-0.02-0.07-0.03-0.14,0.02-0.2c0.1-0.12,0.22-0.12,0.33-0.02
		c0.03-0.22,0.06-0.42,0.09-0.62c0.03-0.21,0.11-0.42,0.06-0.62c-0.05-0.2-0.26-0.39-0.37-0.59c-0.03-0.05,0.03-0.11,0.08-0.16
		c0.05,0.01,0.11,0.03,0.19,0.06c-0.01-0.09-0.01-0.18,0.01-0.26c0.02-0.02,0.03-0.04,0.05-0.06c0.03-0.05,0.05-0.1,0.07-0.16
		c0.07-0.07,0.16-0.12,0.28-0.17c0.12-0.04,0.13-0.19,0.22-0.36c-0.19,0.07-0.28,0.09-0.36,0.12c-0.01-0.13,0.06-0.19,0.15-0.25
		C557.3,360.08,557.43,360.05,557.5,359.99z M564.83,326.53c0.02,0.03,0.05,0.06,0.07,0.08c-0.08,0.11-0.15,0.22-0.22,0.33
		c-0.29-0.4-0.26-0.05-0.38-0.07c-0.15-0.02-0.28,0.01-0.43,0.02c-0.03-0.04-0.05-0.08-0.08-0.12
		C564.14,326.68,564.48,326.6,564.83,326.53z M563.6,323.99c-0.18,0.2-0.33,0.36-0.48,0.54
		C562.94,324.15,563.03,324.04,563.6,323.99z M561.31,328.73c-0.02-0.2-0.01-0.38,0.28-0.31c0.08,0.02,0.12-0.07,0.16-0.13
		c0.08-0.15,0.14-0.31,0.26-0.44C562,328.72,561.84,328.93,561.31,328.73z M562.69,324.39c0.05,0.05,0.1,0.11,0.15,0.16
		c-0.13,0.1-0.26,0.19-0.4,0.3c0.05,0.05,0.12,0.12,0.24,0.24c-0.19,0.05-0.36,0.1-0.52,0.15c-0.02-0.02-0.04-0.04-0.05-0.06
		C562.3,324.92,562.49,324.65,562.69,324.39z M550.86,327.98c0.09-0.13,0.18-0.25,0.28-0.37
		C551.16,327.82,551.04,327.91,550.86,327.98z M551.28,328.49c-0.03-0.03-0.07-0.07-0.1-0.1c0.07-0.16,0.15-0.32,0.24-0.52
		c0.09,0.11,0.21,0.21,0.2,0.23C551.52,328.24,551.4,328.36,551.28,328.49z M551.69,327.01c0.08-0.08,0.16-0.17,0.24-0.25
		c0.1-0.11,0.26-0.18,0.4-0.26c0.01,0.01,0.03,0.02,0.04,0.04C552.16,326.71,551.92,326.86,551.69,327.01z M552.76,326.11
		c-0.07-0.19,0.04-0.24,0.15-0.29C552.85,325.92,552.81,326.02,552.76,326.11z M548.81,349.08c0,0.08,0.08,0.17,0.24,0.29
		c0.03,0.02,0.06,0.05,0.09,0.07c0.01,0.02,0.03,0.05,0.04,0.07c0.07,0.11,0.16,0.21,0.22,0.32c0.01,0.03,0.01,0.06,0.01,0.08
		C548.76,349.5,548.58,349.27,548.81,349.08z M549.39,352.43c-0.04-0.07-0.09-0.14-0.16-0.21c0.19-0.04-0.04-0.15,0.02-0.21
		c0.05-0.05,0.09-0.1,0.11-0.16c0.02,0.01,0.04,0.01,0.06,0.02c0.02-0.05,0.05-0.1,0.07-0.15c0.04,0,0.07,0,0.11,0.01
		C549.53,351.96,549.46,352.19,549.39,352.43z M546.5,360.03c0.06,0.19,0.09,0.37-0.21,0.35c-0.08,0-0.11,0.09-0.13,0.15
		c-0.05,0.16-0.08,0.33-0.16,0.47C545.82,360.15,545.93,359.92,546.5,360.03z M543.5,362.78c-0.03-0.02-0.06-0.05-0.09-0.07
		c0.05-0.12,0.1-0.24,0.15-0.36c0.37,0.34,0.27,0.01,0.39,0c0.16,0,0.28-0.06,0.41-0.09c0.04,0.04,0.07,0.07,0.11,0.11
		C544.15,362.52,543.82,362.65,543.5,362.78z M544.83,366.18c-0.25,0.15-0.52,0.29-0.82,0.39c-0.34,0.11-0.72,0.15-0.81,0.55
		c-0.03-0.09-0.05-0.18-0.08-0.26c-0.1,0.03-0.22,0.03-0.29,0.09c-0.04,0.04,0,0.16,0,0.26c-0.06-0.03-0.12-0.07-0.18-0.1
		c0.05-0.3,0.16-0.49,0.57-0.38c0.05,0.01,0.11,0,0.13-0.04c0.23-0.41,0.6-0.63,1.13-0.72c0.15-0.02,0.25-0.11,0.38-0.17
		c0.04,0.03,0.07,0.06,0.11,0.09C544.94,365.99,544.93,366.13,544.83,366.18z M545.24,365.07c0.14-0.23,0.24-0.41,0.35-0.61
		C545.86,364.8,545.79,364.92,545.24,365.07z M546.05,364.52c-0.06-0.04-0.12-0.09-0.18-0.13c0.11-0.12,0.21-0.23,0.33-0.36
		c-0.06-0.04-0.14-0.1-0.29-0.2c0.18-0.09,0.33-0.16,0.48-0.24c0.02,0.02,0.04,0.04,0.07,0.05
		C546.32,363.94,546.19,364.23,546.05,364.52z M559.17,322.25c-0.04-0.05-0.07-0.1-0.11-0.14c0.1-0.06,0.21-0.12,0.31-0.18
		c-0.02-0.03-0.04-0.06-0.06-0.1c-0.13,0.02-0.27,0.03-0.39,0.07c-0.11,0.03-0.23,0.06-0.34,0.1c0.04-0.44,0.51-0.38,0.78-0.55
		c0.05-0.03,0.12-0.06,0.13-0.12c0.13-0.46,0.56-0.6,0.99-0.68c0.33-0.06,0.57-0.17,0.77-0.39c0.05-0.05,0.21,0,0.3,0.01
		c0.11-0.07,0.22-0.14,0.33-0.22c-0.03,0.04-0.05,0.1-0.08,0.14c-0.1,0.15-0.21,0.28-0.3,0.44c-0.04,0.08-0.05,0.19-0.07,0.29
		c-0.04,0.13-0.08,0.26-0.13,0.45c-0.34-0.47-0.95-0.59-1.22-0.33c0.1,0.02,0.18,0.04,0.3,0.06c-0.18,0.19-0.33,0.35-0.49,0.54
		c0.13,0.04,0.19,0.07,0.25,0.09c0.02,0.04,0.04,0.08,0.06,0.11C559.86,321.96,559.51,322.1,559.17,322.25z M549.93,366.86
		c-0.04,0.04-0.1,0.08-0.11,0.14c-0.03,0.47-0.43,0.69-0.83,0.84c-0.31,0.11-0.52,0.26-0.67,0.51c-0.04,0.06-0.2,0.03-0.29,0.04
		c-0.09,0.09-0.18,0.18-0.27,0.27c0.02-0.05,0.03-0.1,0.05-0.15c0.07-0.16,0.15-0.31,0.2-0.48c0.03-0.09,0.01-0.2,0.01-0.29
		c0.01-0.14,0.02-0.27,0.03-0.47c0.44,0.4,1.06,0.42,1.27,0.12c-0.1,0-0.18-0.01-0.31-0.01c0.14-0.22,0.25-0.4,0.37-0.61
		c-0.13-0.02-0.2-0.03-0.27-0.04c-0.03-0.03-0.05-0.07-0.08-0.1c0.31-0.19,0.62-0.39,0.92-0.59c0.04,0.04,0.09,0.08,0.13,0.12
		c-0.09,0.08-0.18,0.16-0.27,0.23c0.03,0.03,0.05,0.06,0.08,0.08c0.13-0.04,0.26-0.08,0.37-0.14c0.11-0.05,0.21-0.1,0.32-0.15
		C550.63,366.62,550.16,366.64,549.93,366.86z"
            />
        </g>
    </svg>
);

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
