/* eslint-disable react/jsx-indent */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../buttons/Normal';

import styles from '../../styles/scenes/question.module.scss';

const propTypes = {
    question: PropTypes.string,
    answers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),
    multiple: PropTypes.bool,
    multipleCount: PropTypes.number,
    onAnswerChange: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    question: 'Question',
    answers: [{ label: 'Réponse A', value: 'A' }],
    multiple: false,
    multipleCount: 2,
    onAnswerChange: null,
    className: null,
};

const QuestionScene = ({
    question,
    answers,
    multiple,
    multipleCount,
    onAnswerChange,
    className,
}) => {
    const [answer, setAnswer] = useState(null);

    const onClick = useCallback(
        (value) => {
            const newValue = multiple ? [...(answer || []), value].slice(0, multipleCount) : value;
            setAnswer(newValue);
            if (onAnswerChange !== null) {
                onAnswerChange(newValue);
            }
        },
        [multiple, multipleCount, answer, onAnswerChange],
    );

    const hasAnswered =
        (!multiple && answer !== null) ||
        (multiple && answer !== null && answer.length === multipleCount);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <h1 className={styles.question}>{question}</h1>
            {answers !== null ? (
                <div
                    className={classNames([
                        styles.answers,
                        {
                            [styles.done]: hasAnswered,
                        },
                    ])}
                >
                    <div className={styles.items}>
                        {answers.map(({ label, value, image = null }) => {
                            const isSelected =
                                answer !== null &&
                                ((multiple && answer.indexOf(value) !== -1) ||
                                    (!multiple && answer === value));
                            return (
                                <Button
                                    className={classNames([
                                        styles.button,
                                        {
                                            [styles.withImage]: image !== null,
                                            [styles.active]: isSelected,
                                        },
                                    ])}
                                    key={`button-${value}`}
                                    onClick={() => onClick(value)}
                                    disabled={hasAnswered || isSelected}
                                >
                                    <span className={styles.shape}>
                                        <span className={styles.inner}>
                                            <span className={styles.label}>{label}</span>
                                            <span className={styles.circle} />
                                            {image !== null ? (
                                                <div
                                                    className={styles.image}
                                                    style={{
                                                        backgroundImage: `url('${image}')`,
                                                    }}
                                                />
                                            ) : null}
                                        </span>
                                    </span>
                                </Button>
                            );
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

QuestionScene.propTypes = propTypes;
QuestionScene.defaultProps = defaultProps;

export default QuestionScene;
