/* globals JitsiMeetJS */
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import createDebug from 'debug';

import useLibJitsi from './useLibJitsi';

const debug = createDebug('app:jitsi:conference');

const useJitsiConference = (
    roomName,
    { type: participantType = 'guest', name = 'Participant', id: participantHandle } = {},
    { sendResolution = '360', receiveResolution = '1080' } = {},
) => {
    const [joined, setJoined] = useState(false);
    const [tracks, setTracks] = useState([]);
    const [participants, setParticipants] = useState([]);
    const currentTracks = useRef([]);
    const currentParticipants = useRef([]);
    const roomRef = useRef(null);

    const { connection, connected } = useLibJitsi();

    const updateParticipants = useCallback(() => {
        const { current: room } = roomRef;
        currentParticipants.current = room !== null ? room.getParticipants() : [];
        debug('Update participants %O', currentParticipants.current);
        setParticipants(currentParticipants.current);
    }, [setParticipants]);

    const updateTracks = useCallback(
        (newTracks) => {
            currentTracks.current = newTracks;
            setTracks(currentTracks.current);
        },
        [setTracks],
    );

    useEffect(() => {
        if (connection === null || !connected) {
            return () => {};
        }

        if (joined) {
            setJoined(false);
        }

        const onConferenceJoined = () => {
            debug('onConferenceJoined');
            setJoined(true);
        };

        const onConferenceLeft = () => {
            debug('onConferenceLeft');
            setJoined(false);
        };

        const onConferenceFailed = (e) => {
            debug('onConferenceFailed %O', e);
            setJoined(false);
        };

        const onUserJoined = (id, participant) => {
            const participantId = participant.getId();
            debug('onUserJoined %s %O', participantId, participant);
            updateParticipants([
                ...currentParticipants.current.filter((it) => it.getId() !== participantId),
                participant,
            ]);
        };

        const onUserLeft = (id, participant) => {
            const participantId = participant.getId();
            debug('onUserLeft %s %O', participantId, participant);
            updateParticipants(
                currentParticipants.current.filter((it) => it.getId() !== participantId),
            );
        };

        const onParticipantsUpdate = (participantId) => {
            debug('onParticipantsUpdate %s', participantId);
            updateParticipants();
        };

        const onRemoteTrackAdded = (track) => {
            const trackId = track.getId();
            debug('onRemoteTrackAdded %s %O', trackId, track);
            updateTracks([...currentTracks.current.filter((it) => it.getId() !== trackId), track]);
        };

        const onRemoteTrackRemoved = (track) => {
            const trackId = track.getId();
            debug('onRemoteTrackRemoved %s %O', trackId, track);
            updateTracks(currentTracks.current.filter((it) => it.getId() !== trackId));
        };

        const onRemoteTrackMuteChanged = (track) => {
            const trackId = track.getId();
            debug('onRemoteTrackMuteChanged %s %O', trackId, track);
            updateTracks([...currentTracks.current.filter((it) => it.getId() !== trackId), track]);
        };

        const room = connection.initJitsiConference(roomName, {
            startAudioMuted: true,
            startVideoMuted: false,
        });

        room.addEventListener(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
        room.addEventListener(JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
        room.addEventListener(JitsiMeetJS.events.conference.CONFERENCE_FAILED, onConferenceFailed);
        room.addEventListener(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
        room.addEventListener(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        room.addEventListener(
            JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
            onParticipantsUpdate,
        );
        room.addEventListener(
            JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
            onParticipantsUpdate,
        );
        room.addEventListener(
            JitsiMeetJS.events.conference.USER_ROLE_CHANGED,
            onParticipantsUpdate,
        );
        room.addEventListener(
            JitsiMeetJS.events.conference.USER_STATUS_CHANGED,
            onParticipantsUpdate,
        );
        room.addEventListener(
            JitsiMeetJS.events.conference.USER_STATUS_CHANGED,
            onParticipantsUpdate,
        );
        room.addEventListener(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrackAdded);
        room.addEventListener(JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoteTrackRemoved);
        room.addEventListener(
            JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
            onRemoteTrackMuteChanged,
        );

        room.setReceiverVideoConstraint(receiveResolution);
        room.setSenderVideoConstraint(sendResolution);

        debug('Join room %s', roomName);
        room.join();

        roomRef.current = room;
        return () => {
            debug('Leave room %s', roomName);

            room.removeEventListener(
                JitsiMeetJS.events.conference.CONFERENCE_JOINED,
                onConferenceJoined,
            );
            room.removeEventListener(
                JitsiMeetJS.events.conference.CONFERENCE_LEFT,
                onConferenceLeft,
            );
            room.removeEventListener(
                JitsiMeetJS.events.conference.CONFERENCE_FAILED,
                onConferenceFailed,
            );
            room.removeEventListener(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
            room.removeEventListener(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
            room.removeEventListener(
                JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
                onParticipantsUpdate,
            );
            room.removeEventListener(
                JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
                onParticipantsUpdate,
            );
            room.removeEventListener(
                JitsiMeetJS.events.conference.USER_ROLE_CHANGED,
                onParticipantsUpdate,
            );
            room.removeEventListener(
                JitsiMeetJS.events.conference.USER_STATUS_CHANGED,
                onParticipantsUpdate,
            );
            room.removeEventListener(
                JitsiMeetJS.events.conference.USER_STATUS_CHANGED,
                onParticipantsUpdate,
            );
            room.removeEventListener(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrackAdded);
            room.removeEventListener(
                JitsiMeetJS.events.conference.TRACK_REMOVED,
                onRemoteTrackRemoved,
            );
            room.removeEventListener(
                JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
                onRemoteTrackMuteChanged,
            );

            room.leave()
                .then(() => {
                    debug('Left %s', roomName);
                })
                .catch((e) => {
                    debug('Error leaving %s %O', roomName, e);
                });

            roomRef.current = null;
        };
    }, [
        roomName,
        connected,
        connection,
        setJoined,
        updateParticipants,
        updateTracks,
        receiveResolution,
        sendResolution,
    ]);

    // Set participant
    useEffect(() => {
        const { current: room = null } = roomRef;
        if (room === null || !joined) {
            return;
        }
        if (name !== null) {
            room.setDisplayName(name);
        }
        room.setLocalParticipantProperty('type', participantType || 'guest');
        room.setLocalParticipantProperty('handle', participantHandle);
    }, [joined, name, participantType, participantHandle]);

    // Get participants
    const finalParticipants = useMemo(
        () =>
            participants.map((participant) => {
                const participantId = participant.getId();
                const type = participant.getProperty('type') || null;
                const video =
                    tracks.find(
                        (track) =>
                            track.getType() === 'video' &&
                            track.getParticipantId() === participantId,
                    ) || null;
                const audio =
                    tracks.find(
                        (track) =>
                            track.getType() === 'audio' &&
                            track.getParticipantId() === participantId,
                    ) || null;
                return {
                    id: participantId,
                    handle: participant.getProperty('handle') || participantId,
                    participant,
                    type:
                        type ||
                        (participant.getRole() === 'moderator' ? 'presenter' : null) ||
                        'guest',
                    muted: audio !== null ? audio.isMuted() : true,
                    video,
                    audio,
                };
            }),
        [participants, tracks],
    );

    // Set presenter
    useEffect(() => {
        const { current: room = null } = roomRef;
        if (room === null || !joined) {
            return () => {};
        }
        const presenter = finalParticipants.find((it) => it.type === 'presenter') || null;
        if (presenter !== null) {
            room.selectParticipant(presenter.id);
        }
        return () => {
            room.selectParticipant(null);
        };
    }, [joined, finalParticipants]);

    return {
        connected,
        joined,
        participants: finalParticipants,
        room: roomRef.current,
    };
};

export default useJitsiConference;
