/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Scenes from './index';

import styles from '../../styles/scenes/scene.module.scss';

const propTypes = {
    type: PropTypes.string,
};

const defaultProps = {
    type: 'question',
};

const Scene = ({ type, ...props }) => {
    const SceneComponent = Scenes[type] ? Scenes[type] : null;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.visible]: true,
                },
            ])}
        >
            {SceneComponent !== null ? <SceneComponent {...props} /> : null}
        </div>
    );
};

Scene.propTypes = propTypes;
Scene.defaultProps = defaultProps;

export default Scene;
