import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const SecretsContext = React.createContext(null);

export const useSecrets = () => useContext(SecretsContext);

export const useJitsiConfig = () => {
    const { jitsi } = useSecrets();
    return jitsi || {};
};

export const usePubNubConfig = () => {
    const { pubnub } = useSecrets();
    return pubnub || {};
};

const propTypes = {
    children: PropTypes.node.isRequired,
    pubnub: PropTypes.object, // eslint-disable-line
    jitsi: PropTypes.objectOf(PropTypes.string),
};

const defaultProps = {
    pubnub: null,
    jitsi: null,
};

export const SecretsProvider = ({ children, pubnub, jitsi }) => (
    <SecretsContext.Provider value={{ pubnub, jitsi }}>{children}</SecretsContext.Provider>
);

SecretsProvider.propTypes = propTypes;
SecretsProvider.defaultProps = defaultProps;

export default SecretsContext;
