import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Clock from './Clock';

import styles from '../../styles/partials/header.module.scss';

const propTypes = {
    question: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    question: null,
    className: null,
};

const HeaderPartial = ({ question, className }) => {
    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <div className={styles.question}>{question}</div>
            <Clock className={styles.clock} />
        </div>
    );
};

HeaderPartial.propTypes = propTypes;
HeaderPartial.defaultProps = defaultProps;

export default HeaderPartial;
