import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/scenes/start.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const StartScene = ({ className }) => {
    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <h1>Start</h1>
        </div>
    );
};

StartScene.propTypes = propTypes;
StartScene.defaultProps = defaultProps;

export default StartScene;
