import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/partials/clock.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const ClockPartial = ({ className }) => {
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        const id = setInterval(() => {
            setDate(new Date());
        }, 1000);
        return () => {
            clearInterval(id);
        };
    }, [setDate]);

    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            {date !== null
                ? `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`
                : null}
        </div>
    );
};

ClockPartial.propTypes = propTypes;
ClockPartial.defaultProps = defaultProps;

export default ClockPartial;
