import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from '../../styles/partials/footer.module.scss';

const propTypes = {
    layout: PropTypes.string,
    muted: PropTypes.bool,
    preview: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    layout: null,
    muted: null,
    preview: null,
    className: null,
};

const FooterPartial = ({ layout, muted, preview, className }) => {
    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            {layout === 'full' ? (
                <FormattedMessage id="display.full" defaultMessage="Plein écran" />
            ) : null}
            {layout === 'tile_other' ? (
                <FormattedMessage id="display.tile_other" defaultMessage="Grille" />
            ) : null}
            <span className={styles.padded}> - </span>
            {muted ? (
                <FormattedMessage id="display.muted" defaultMessage="Son coupé" />
            ) : (
                <FormattedMessage id="display.unmuted" defaultMessage="Son actif" />
            )}
            <span className={styles.padded}> - </span>
            {preview ? (
                <FormattedMessage id="display.preview" defaultMessage="Vignette" />
            ) : (
                <FormattedMessage id="display.nopreview" defaultMessage="Pas de vignette" />
            )}
        </div>
    );
};

FooterPartial.propTypes = propTypes;
FooterPartial.defaultProps = defaultProps;

export default FooterPartial;
